import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import styles from './ExportChart.less'
import InlineSvg from 'app/common/InlineSvg'
import domtoimage from 'dom-to-image-more'
import saveFile from 'save-as-file'
import {useDispatch, useSelector} from 'react-redux'
import TextBox from 'app/common/TextBox'
import Button from 'app/common/Button'
import * as actions from 'app/common/charts/charts-actions'
import closeIconUrl from 'static/images/close-icon.svg'
import * as selectors from 'app/common/charts/charts-selectors'
import LoadingSpinner from 'app/common/loading-spinner'
import {Tooltip} from 'app/common/tooltip'

export default function ExportChart({chartKey}) {
  const dispatch = useDispatch()
  const [showExportChartOptions, setShowExportChartOptions] = useState(false)
  const [showSaveChartOption, setShowSaveChartOption] = useState(false)
  const [saveAsName, setSaveAsName] = useState('')
  const sectionRef = useSelector(selectors.getSectionRef)
  const askForSectionRef = useSelector(selectors.getAskForSectionRef)
  const isWaiting = useSelector(selectors.getIsWaiting)
  const currentChart = sectionRef[chartKey]

  useEffect(() => {
    if (currentChart) {
      if (['png', 'jpeg', 'svg'].includes(askForSectionRef)) {
        exportChart(askForSectionRef)
      } else if (askForSectionRef === 'forPublish') {
        saveChartAs()
      } else {
        return
      }
      dispatch(actions.setAskForSectionRef(null))
    }
  })

  const saveChartAs = e => {
    if (e) {
      e.preventDefault()
    }

    if (!currentChart) {
      dispatch(actions.setAskForSectionRef({chartKey, type: 'forPublish'}))
      return
    }

    domtoimage
      .toPng(currentChart, {
        bgcolor: '#ffffff',
        filter: node => node.className !== styles.exportOptions,
      })
      .then(dataUrl => {
        dispatch(
          actions.saveChart({
            chartFile: dataUrl,
            chartName: `${saveAsName}.png`,
          }),
        )
        setShowSaveChartOption(false)
        setSaveAsName('')
      })
  }

  const exportChartOptions =
    showExportChartOptions && !showSaveChartOption ? (
      <span className={classNames(styles.exportChartOptions)}>
        <span
          className={styles.downloadType}
          onClick={() => exportChart('png')}
        >
          PNG
        </span>
        <span
          className={styles.downloadType}
          onClick={() => exportChart('jpeg')}
        >
          JPEG
        </span>
        <span
          className={styles.downloadType}
          onClick={() => exportChart('svg')}
        >
          SVG
        </span>
        <span className={classNames(styles.downloadType, styles.cancel)}>
          <InlineSvg
            onClick={() => setShowExportChartOptions(false)}
            className={styles.closeIcon}
            src={closeIconUrl}
          />
        </span>
      </span>
    ) : !showSaveChartOption ? (
      <Tooltip label={'Download image as PNG, JPEG, or SVG.'}>
        <span
          className={styles.faIconWrapper}
          onClick={() => setShowExportChartOptions(true)}
        >
          <span>
            <i
              className={classNames(
                'fa icon-off fa-download fa-lg',
                styles.faIcon,
              )}
            />
          </span>
        </span>
      </Tooltip>
    ) : null

  const saveChartOption =
    showSaveChartOption && !showExportChartOptions ? (
      <form onSubmit={e => saveChartAs(e)}>
        <div className={classNames(styles.saveChartOption)}>
          <div className={styles.saveChartAs}>
            <TextBox
              autoFocus={true}
              className={styles.saveAsTextBox}
              placeholder="Save as..."
              onChange={e => setSaveAsName(e.target.value)}
            />
          </div>
          <div>
            <Button
              className={styles.saveAsButton}
              label={'Save'}
              disabled={saveAsName.length < 1}
            />
          </div>
          <div className={classNames(styles.saveChartAs, styles.cancel)}>
            <InlineSvg
              onClick={() => setShowSaveChartOption(false)}
              className={styles.closeIcon}
              src={closeIconUrl}
            />
          </div>
        </div>
      </form>
    ) : !showExportChartOptions ? (
      <Tooltip label={'Save image for use in Publish.'}>
        <span
          className={styles.faIconWrapper}
          onClick={() => setShowSaveChartOption(true)}
        >
          <span>
            <i
              className={classNames('fa icon-off fa-plus fa-lg', styles.faIcon)}
            />
          </span>
        </span>
      </Tooltip>
    ) : null

  const exportChart = saveAsType => {
    if (!currentChart) {
      dispatch(actions.setAskForSectionRef({chartKey, type: saveAsType}))
      return
    }

    const dateString = new Date().toISOString().split('.')[0]
    const fileName = `${chartKey.split('::')[0]}-${dateString}`

    try {
      if (saveAsType === 'png') {
        domtoimage
          .toPng(currentChart, {
            bgcolor: '#ffffff',
            filter: node => node.className !== styles.exportOptions,
          })
          .then(dataUrl => {
            saveFile(dataUrl, `${fileName}.${saveAsType}`)
          })
      }
      if (saveAsType === 'svg') {
        domtoimage
          .toSvg(currentChart, {
            filter: node => node.className !== styles.exportOptions,
          })
          .then(dataUrl => {
            saveFile(dataUrl, `${fileName}.${saveAsType}`)
          })
      }
      if (saveAsType === 'jpeg') {
        domtoimage
          .toJpeg(currentChart, {
            bgcolor: '#ffffff',
            filter: node => node.className !== styles.exportOptions,
          })
          .then(dataUrl => {
            saveFile(dataUrl, `${fileName}.${saveAsType}`)
          })
      }
    } catch (e) {
      return
    }
    setTimeout(() => {
      setShowExportChartOptions(false)
    }, 500)
  }

  const content = isWaiting ? (
    <LoadingSpinner className={styles.waiting} />
  ) : (
    <React.Fragment>
      {exportChartOptions}
      {saveChartOption}
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <div className={styles.exportOptions}>{content}</div>
    </React.Fragment>
  )
}
ExportChart.propTypes = {
  chartKey: PropTypes.string.isRequired,
}
