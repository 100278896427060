import {LOCATION_CHANGED, push, replace} from 'redux-little-router'
import {all, fork, put, select, spawn, takeEvery} from 'redux-saga/effects'

import * as addEntitiesModalSaga from 'app/reusable/entities/add-modal'
import * as companiesPage from 'app/companies-page'
import {saga as companyReportSaga} from 'app/overviews/company/report'
import * as companyOverview from 'app/overviews/company'
import * as dashboard from 'app/dashboard'
import * as global from 'app/global'
import * as login from 'app/login'
import * as industries from 'app/industries'
import {logPageVisit} from 'app/interaction-logging'
import overviewSaga from 'app/overviews/common/overview-saga'
import * as industryOverview from 'app/overviews/industry'
import * as industryReport from 'app/overviews/industry/report'
import interimPageSaga from 'app/interim-page/interim-page-saga'
import {saga as litigationSaga} from 'app/litigation'
import * as myCompanyStar from 'app/reusable/companies/my-company-star'
import * as myEntityStar from 'app/reusable/entities/my-entity-star'
import * as myIndustryStar from 'app/reusable/industries/my-industry-star'
import * as profileBuilder from 'app/profile-builder'
import * as shareArticleModal from 'app/share-article-modal'
import * as flagArticleModal from 'app/flag-article-modal'
import * as storyModal from 'app/story-modal'
import storylineModalSaga from 'app/storyline-modal/storyline-modal-saga'
import storylineViewSaga from 'app/storyline-view/storyline-view-saga'
import routes, {routeFromUrl, WINDOW_TITLES_BY_ROUTE} from 'app/routes'
import urls from 'app/urls'
import * as charts from 'app/common/charts'
import {DEFAULT_WINDOW_TITLE} from './constants'
import {getEntities} from './framework/entities-selectors'
import Orm from 'app/framework/Orm'
import Company from './models/Company'
import Industry from './models/Industry'
import Storyline from './models/Storyline'

function* handleHomePage() {
  const isLoggedIn = !!(yield select(login.selectors.getJwt))
  if (isLoggedIn) {
    // Redirect the home page to the My Companies page.
    yield put(replace(urls.dashboard()))
  }
  // If we're not logged in, we let the login saga take care of it.
}

function* handleNavigation(action) {
  const router = yield select(state => state.router)
  const nextRoute = routeFromUrl(action.payload.pathname || action.payload)

  if (
    router.route === nextRoute &&
    [routes.companyOverview, routes.companyOverviewPortal].includes(nextRoute)
  ) {
    // When moving between companies, persist query parameters.
    yield put(push(action.payload, {persistQuery: true}))
  } else {
    // For now, this just pushes the new location. In the future, this will
    // handle generic page loading events like progress bars.
    yield put(push(action.payload))
  }
}

function* handleLocationChanged() {
  yield logInteraction()
  yield updateDocumentTitle()
}

function* logInteraction() {
  if (process.env.NODE_ENV === 'production') {
    yield logPageVisit(window.location.href)
  }
}

function* updateDocumentTitle() {
  const router = yield select(state => state.router)
  let pageTitle
  if (WINDOW_TITLES_BY_ROUTE.hasOwnProperty(router.route)) {
    pageTitle = WINDOW_TITLES_BY_ROUTE[router.route]
    if (router.params.hasOwnProperty('industryId')) {
      const industry = yield select(state => {
        const orm = Orm.withEntities(getEntities(state))
        return orm.getById(Industry, parseInt(router.params.industryId))
      })
      if (industry) {
        pageTitle = `${industry.name} - ${pageTitle}`
      }
    } else if (router.params.hasOwnProperty('companyId')) {
      const company = yield select(state => {
        const orm = Orm.withEntities(getEntities(state))
        return orm.getById(Company, parseInt(router.params.companyId))
      })
      if (company) {
        pageTitle = `${company.name} - ${pageTitle}`
      }
    } else if (router.params.hasOwnProperty('storylineId')) {
      const storyline = yield select(state => {
        const orm = Orm.withEntities(getEntities(state))
        return orm.getById(Storyline, parseInt(router.params.storylineId))
      })
      if (storyline) {
        pageTitle = `${storyline.summary} - ${pageTitle}`
      }
    }
  }
  document.title = DEFAULT_WINDOW_TITLE
  if (pageTitle) {
    document.title = `${pageTitle} | ${document.title}`
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      action =>
        action.type == LOCATION_CHANGED && action.payload.route === routes.home,
      handleHomePage,
    ),
    takeEvery(global.actions.navigate, handleNavigation),
    takeEvery(LOCATION_CHANGED, handleLocationChanged),
    fork(addEntitiesModalSaga.saga),
    fork(companiesPage.companiesPageSaga),
    fork(companyOverview.saga),
    fork(companyReportSaga),
    fork(dashboard.saga),
    fork(industries.saga),
    fork(industryOverview.saga),
    fork(industryReport.saga),
    fork(interimPageSaga),
    fork(myCompanyStar.saga),
    fork(myEntityStar.saga),
    fork(myIndustryStar.saga),
    fork(overviewSaga),
    fork(profileBuilder.saga),
    fork(shareArticleModal.saga),
    fork(flagArticleModal.saga),
    fork(storyModal.saga),
    fork(storylineViewSaga),
    fork(storylineModalSaga),
    fork(charts.saga),
    fork(litigationSaga),
  ])
  // Login should run last since it initializes the current router location.
  // Also, it should be `spawn`ed instead of `fork`ed because it needs to be
  // resilient to uncaught errors thrown elsewhere (otherwise we have bugs like
  // an error being thrown before the user profile is loaded, which leads to a
  // blank screen).
  yield spawn(login.saga)

  if (process.env.USE_EMAIL_TEST_PAGES) {
    const emailServerSaga = require('email-server/local-dev').saga
    yield fork(emailServerSaga)
  }
}
