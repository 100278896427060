import {identity} from 'ramda'
import React from 'react'

import Cell from './Cell'

const Row = ({children, ...props}) => {
  if (!children) return null

  const directChildren = React.Children.map(children, identity)
  const isCell = child => child.type === Cell

  // If the children are not Cells, wrap them in one.
  if (!directChildren.every(isCell)) {
    if (directChildren.some(isCell)) {
      throw new Error(
        'Children of Row should be either all Cells, or all something else.',
      )
    }
    children = <Cell>{children}</Cell>
  }

  return <tr {...props}>{children}</tr>
}
export default Row
