import PropTypes from 'prop-types'
import React from 'react'

import Button from 'app/common/Button'
import InputBlock from 'app/common/InputBlock'
import {FEEDBACK_EMAIL} from 'app/constants'
import EntityInput from 'app/reusable/entities/entity-input'

import * as styles from './AddEntitiesModalSearch.less'

export default function AddEntitiesModalSearch({
  isLoading,
  canSubmit,
  entityType,
  onChange,
  onSubmit,
}) {
  const entitiesLabel = entityType === 'company' ? 'company' : 'industry'
  const entitiesLabelPlural =
    entityType === 'company' ? 'Companies' : 'Industries'
  const entitiesLabelPluralLower = entitiesLabelPlural.toLowerCase()
  const quantity = entityType === 'company' ? 'thousands of' : 'about 100'
  return (
    <div>
      <h1>Monitor More {entitiesLabelPlural}</h1>

      <p className={styles.description}>
        {process.env.BRAND_NAME} {process.env.PRODUCT_NAME} has applied its data
        analytics to {quantity} {entitiesLabelPluralLower}. Use the search box
        below to locate {process.env.PRODUCT_NAME}
        -enabled {entitiesLabelPluralLower} to add to your profile. If you do
        not see {entityType === 'company' ? 'a' : 'an'} {entitiesLabel} and
        would like to request it be added to {process.env.BRAND_NAME}
        {process.env.PRODUCT_NAME}, please email{' '}
        <a href={`mailto:${FEEDBACK_EMAIL}`}>{FEEDBACK_EMAIL}</a>.
      </p>

      <InputBlock
        label={`Search ${entitiesLabelPlural} to Monitor`}
        className={styles.inputBlock}
      >
        <EntityInput entityType={entityType} onChange={onChange} />
      </InputBlock>

      <Button
        label={isLoading ? 'Adding...' : 'Monitor'}
        isPrimary={true}
        isDisabled={isLoading || !canSubmit}
        onClick={() => canSubmit && onSubmit()}
        className={styles.monitorButton}
      />
    </div>
  )
}
AddEntitiesModalSearch.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  entityType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
