import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {PureComponent} from 'react'

import CloseButton from 'app/common/CloseButton'

import * as styles from './styles.less'

export default class Modal extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    shouldShowCloseButton: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
  }

  static defaultProps = {
    shouldShowCloseButton: true,
  }

  render() {
    const {
      children,
      shouldShowCloseButton,
      className,
      contentClassName,
    } = this.props
    return (
      <div className={styles.modal}>
        <div className={styles.mask} onClick={this.overlayClicked}>
          <div
            className={classNames(styles.box, className)}
            ref={ref => (this.overlayBox = ref)}
          >
            {shouldShowCloseButton && (
              <CloseButton
                className={styles.closeButton}
                onClick={this.closeModal}
              />
            )}
            <div className={classNames(styles.content, contentClassName)}>
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  }

  // Event handlers

  overlayClicked = event => {
    // Only close the modal if we clicked outside of it
    if (!this.overlayBox.contains(event.target)) {
      this.closeModal()
    }
  }

  closeModal = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }
}
