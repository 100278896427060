import classNames from 'classnames'
import {debounce} from 'debounce'
import PropTypes from 'prop-types'
import {prop, range} from 'ramda'
import React, {useEffect, useMemo, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import {createSelector} from 'reselect'

import HealthScore from 'app/common/HealthScore'
import MagnifyingGlass from 'app/common/icons/magnifying-glass'
import InsightSummary from 'app/common/insight-summary'
import {LoadingMessage} from 'app/common/loading-message'
import Link from 'app/common/NavigationLink'
import Table from 'app/common/Table'
import Section from 'app/common/Section'
import TextBox from 'app/common/TextBox'
import {CHART_COLORS} from 'app/constants'
import urls from 'app/urls'
import * as strings from 'app/strings'
import {coalesceUndefined, emptyObject} from 'app/utils'
import {DefaultMap} from 'app/utils/default-map'
import {parseQuarter} from 'app/utils/quarters'
import * as branding from 'branding'

import Filters from '../../common/OverviewFilters'
import * as selectors from '../company-overview-selectors'
import SaveConfirmationModal from 'app/common/charts/export-chart/SaveConfirmationModal'

import styles from './CompanyHistory.less'
import * as actions from '../company-overview-actions'

const COLORS = CHART_COLORS.slice(0, 3)
const HIGHLIGHT_COLOR = 'rgba(255, 180, 0, 0.25)'
// We map the numbers to objects with `id` props so that React doesn't complain
// about a lack of keys in the table.
const LOADING_DATA = range(0, 25).map(i => ({id: i}))

function StorylinesTable({storylines}) {
  const isLoading = !storylines
  return (
    <Table
      data={storylines || LOADING_DATA}
      defaultSort={{column: 'volume', direction: 'desc'}}
      className={classNames(styles.table, {[styles.loading]: isLoading})}
      cellClassName={styles.cell}
    >
      <Table.Column
        name="date"
        label="Date"
        baseWidth={120}
        maxWidth={220}
        growRatio={1}
        shrinkRatio={0}
        isSortable={true}
        sortBy={storyline => storyline.startDate}
        cellContents={storyline => {
          if (isLoading) {
            return <div className={styles.rect} />
          }
          /*
           * Wrap the dates in their own tags to prevent them from being
           * interrupted by a line break.
           * Example:
           *   September 12 -
           *   October 4
           * instead of:
           *   September 12 - October
           *   4
           */
          const dateRangeString = strings.formatDateRange(
            storyline.startDate,
            storyline.endDate,
            {
              showYear: false,
            },
          )
          const parts = dateRangeString.split('-')
          if (parts.length === 1) {
            return parts[0]
          }
          return (
            <span className={styles.dateRange}>
              <span className={styles.date}>{parts[0]}</span> &ndash;{' '}
              <span className={styles.date}>{parts[1]}</span>
            </span>
          )
        }}
      />
      <Table.Column
        name="summary"
        label="Summary"
        baseWidth={400}
        growRatio={1}
        shrinkRatio={1}
        /*
         * There isn't any real point in sorting by summary, but there also
         * isn't any reason to have this be the only column in the table that
         * doesn't allow sorting.
         */
        isSortable={true}
        sortBy={storyline => storyline.summary}
        cellContents={storyline =>
          isLoading ? (
            <div className={styles.rect} />
          ) : (
            <Link href={urls.storylineView(storyline.id)}>
              {storyline.summary}
            </Link>
          )
        }
        className={styles.summary}
      />
      <Table.Column
        name="insights-summary"
        label="Factor"
        baseWidth={430} // to give room for the longest possible summary
        growRatio={0}
        shrinkRatio={0}
        isSortable={true}
        sortBy={storyline => strings.factorDisplayName(storyline.factor)}
        cellContents={storyline =>
          isLoading ? (
            <div className={styles.rect} />
          ) : (
            <InsightSummary
              factor={storyline.factor}
              valence={storyline.valence}
              isLitigation={storyline.isLitigation}
              isRumor={storyline.isRumor}
              isOpinion={storyline.isOpinion}
            />
          )
        }
        className={styles.factor}
      />
      <Table.Column
        name="subfactor"
        label="Subfactor"
        baseWidth={120}
        maxWidth={200}
        growRatio={1}
        shrinkRatio={1}
        isSortable={true}
        sortBy={storyline => strings.subfactorDisplayName(storyline.subfactor)}
        cellContents={storyline =>
          isLoading ? (
            <div className={styles.rect} />
          ) : (
            strings.subfactorDisplayName(storyline.subfactor)
          )
        }
        className={styles.subfactor}
      />
      <Table.Column
        name="volume"
        label="Article Volume"
        baseWidth={125}
        minWidth={80}
        growRatio={0}
        shrinkRatio={1}
        isSortable={true}
        sortBy={storyline => storyline.articleCount}
        cellContents={storyline =>
          isLoading ? <div className={styles.rect} /> : storyline.articleCount
        }
        className={styles.volume}
      />
    </Table>
  )
}

function NoStories({companyName, quarter, areAnyFiltersActive}) {
  const andSelectedFilters = areAnyFiltersActive
    ? ' and the selected filters'
    : ''
  const orLooseningSearch = areAnyFiltersActive
    ? ' or loosening your search filters'
    : ''
  return (
    <div className={styles.noStories}>
      No {companyName} storylines found for {quarter.toString()}
      {andSelectedFilters}. Try selecting a different quarter{orLooseningSearch}
      .
    </div>
  )
}

function TooltipLineItem({label, score, volume, searchedVolume, color}) {
  let count
  if (volume) {
    if (searchedVolume === null) {
      count = volume
    } else {
      count = searchedVolume
    }
  } else {
    count = 'no'
  }
  let volumeText = `${strings.formatNumber(count)} article${
    volume === 1 ? '' : 's'
  }`
  if (searchedVolume !== null) {
    volumeText += ` out of ${strings.formatNumber(volume)} total`
  }
  return (
    <div className={styles.lineItem}>
      <div className={styles.lineColor} style={{backgroundColor: color}} />
      <div className={styles.label}>
        {`${label}: `}
        <HealthScore score={coalesceUndefined(score, null)} />{' '}
        <span className={styles.volume}>({volumeText})</span>
      </div>
    </div>
  )
}
TooltipLineItem.propTypes = {
  label: PropTypes.string.isRequired,
  score: PropTypes.number,
  volume: PropTypes.number,
  searchedVolume: PropTypes.number,
  color: PropTypes.string.isRequired,
}

function QuarterTooltip({
  quarter,
  dataPoint,
  searchedVolumes,
  factor,
  subfactor,
}) {
  const searchedVolumesForQuarter = (searchedVolumes || []).find(
    volumes => volumes.quarter === dataPoint.quarter,
  )
  const volume = key => dataPoint[`${key}:volume`]
  return (
    <div className={styles.tooltip}>
      <div className={styles.header}>{quarter.toString()} Health Score</div>

      <TooltipLineItem
        label="Overall"
        score={dataPoint.overall}
        volume={volume('overall')}
        searchedVolume={
          searchedVolumesForQuarter
            ? searchedVolumesForQuarter.overall || 0
            : null
        }
        color={CHART_COLORS[0]}
      />
      {factor && (
        <TooltipLineItem
          label={strings.factorDisplayName(factor)}
          score={dataPoint[`factor-${factor}`]}
          volume={volume(`factor-${factor}`)}
          searchedVolume={
            searchedVolumesForQuarter
              ? searchedVolumesForQuarter[`factor-${factor}`] || 0
              : null
          }
          color={CHART_COLORS[1]}
        />
      )}
      {subfactor && (
        <TooltipLineItem
          label={strings.subfactorDisplayName(subfactor)}
          score={dataPoint[`subfactor-${subfactor}`]}
          volume={volume(`subfactor-${subfactor}`)}
          searchedVolume={
            searchedVolumesForQuarter
              ? searchedVolumesForQuarter[`subfactor-${subfactor}`] || 0
              : null
          }
          color={CHART_COLORS[2]}
        />
      )}

      <div>Click to see storylines for this quarter.</div>
    </div>
  )
}
QuarterTooltip.propTypes = {
  quarter: PropTypes.string.isRequired,
  dataPoint: PropTypes.object.isRequired,
  searchedVolume: PropTypes.number,
  factor: PropTypes.string,
  subfactor: PropTypes.string,
}

/**
 * Overrides the normal x-axis ticks in order to angle them and add a click
 * handler to set the quarter.
 */
function XAxisTick({
  x,
  y,
  payload,
  selectedQuarter,
  selectQuarter,
  firstQuarterNumbersByYear,
}) {
  const quarter = parseQuarter(payload.value)
  const isFirstQuarterOfYear =
    firstQuarterNumbersByYear[quarter.year] === quarter.number
  const label = isFirstQuarterOfYear
    ? `${quarter.year} Q${quarter.number}`
    : `Q${quarter.number}`
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill={branding['text-color-general']}
        transform="rotate(-35)"
        fontWeight={
          payload.value === selectedQuarter.toString() ? 'bold' : 'normal'
        }
        cursor="pointer"
        onClick={() => selectQuarter(payload.value)}
      >
        {label}
      </text>
    </g>
  )
}

function CustomizedDot({
  cx,
  cy,
  payload,
  stroke,
  fill,
  dataKey,
  volumeRangeMin,
  volumeRangeMax,
  searchedVolumes,
  isActive = false,
}) {
  const MIN_RADIUS = 2
  const MAX_RADIUS = 4
  let volume = payload[`${dataKey}:volume`]
  if (searchedVolumes) {
    const searchedVolumesForQuarter = searchedVolumes.find(
      v => v.quarter === payload.quarter,
    )
    if (searchedVolumesForQuarter) {
      volume = searchedVolumesForQuarter[dataKey] || 0
    } else {
      volume = 0
    }
  }
  if (!volume) {
    return null
  }
  const ratio = (volume - volumeRangeMin) / (volumeRangeMax - volumeRangeMin)
  let radius = MIN_RADIUS + ratio * MAX_RADIUS
  if (isActive) {
    radius += 2
  }
  // Note: It's unclear why the prop with the correct color is inconsistent, but
  // it is.
  const color = isActive ? fill : stroke
  return <circle cx={cx} cy={cy} r={radius} fill={color} />
}

function StorylineSearch({value, onChange}) {
  const [realValue, setRealValue] = useState(value)
  useEffect(() => {
    // Any time the passed in value changes, we need to change the internal
    // representation.
    setRealValue(value)
  }, [value])
  const DEBOUNCE_THRESHOLD = 300 // in ms
  const debouncedOnChange = useMemo(() => {
    const debouncedHandler = debounce(value => {
      onChange(value)
    }, DEBOUNCE_THRESHOLD)
    // We have to handle `event` at the top level because of the way synthetic
    // events work in React. Otherwise, React may reuse the event object and
    // its `target` will be `null`.
    return event => {
      const {value} = event.target
      setRealValue(value)
      debouncedHandler(value)
    }
  }, [onChange])
  return (
    <label className={styles.search}>
      <MagnifyingGlass className={styles.icon} />
      <TextBox
        value={realValue}
        onChange={debouncedOnChange}
        className={styles.input}
        placeholder="Search storylines"
      />
    </label>
  )
}
StorylineSearch.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

function CompanyHistory({
  company,
  healthData,
  storylines,
  searchText,
  autoQuarterSearch,
  quarterlyArticleCounts,
  selectedFactor,
  selectedSubfactor,
  selectedGeography,
  selectedQuarter,
  selectQuarter,
  setStorylineSearchText,
}) {
  /*
   * This is kind of a hack, but there doesn't appear to be a clear way to tell
   * Recharts to show the pointer cursor when hovering over the inside of the
   * chart itself, as opposed to the axes or labels or something like that. So
   * instead, we keep track of mouse movements ourselves, and let Recharts tell
   * us if we are currently hovering over something with a value.
   */
  const dispatch = useDispatch()
  const [shouldShowPointer, setShouldShowPointer] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState(!autoQuarterSearch)
  const selectedQuarterString = `${selectedQuarter.year}-Q${selectedQuarter.number}`

  const sortedQuarterlyArticleCounts = quarterlyArticleCounts
    ? quarterlyArticleCounts.map(prop('quarter')).sort().reverse()
    : null

  const firstHitQuarter = sortedQuarterlyArticleCounts && sortedQuarterlyArticleCounts.length > 0
    ? sortedQuarterlyArticleCounts[0]
    : selectedQuarterString

  useEffect(() => {
    if (storylines && !storylines.length && autoQuarterSearch &&
      firstHitQuarter && selectedQuarterString) {
      if (selectedQuarterString === firstHitQuarter) {
        setShowEmptyState(true)
        dispatch(actions.setAutoQuarterSearch(false))
      } else {
        setShowEmptyState(false)
        selectQuarter(parseQuarter(firstHitQuarter))
      }
    }
  })

  if (!healthData) {
    return (
      <div className={classNames(styles.companyHistory, styles.loading)}>
        <LoadingMessage />
      </div>
    )
  }
  if (healthData && !healthData.length) {
    return (
      <div className={classNames(styles.companyHistory, styles.noData)}>
        Not enough data for {company.name}.
      </div>
    )
  }
  const volumeRangesByKey = healthData.reduce((ranges, point) => {
    const volumeEntries = Object.entries(point).filter(([key]) =>
      key.endsWith(':volume'),
    )
    for (let [key, volume] of volumeEntries) {
      key = key.replace(':volume', '')
      if (quarterlyArticleCounts) {
        const searchedPoint = quarterlyArticleCounts.find(
          p => p.quarter === point.quarter,
        )
        if (searchedPoint) {
          volume = searchedPoint[key] || 0
        } else {
          volume = 0
        }
      }
      if (isNaN(volume)) {
        continue
      }
      if (ranges.has(key)) {
        ranges.set(key, {
          min: Math.min(ranges.get(key).min, volume),
          max: Math.max(ranges.get(key).max, volume),
        })
      } else {
        ranges.set(key, {
          min: volume,
          max: volume,
        })
      }
    }
    return ranges
  }, new DefaultMap(emptyObject))
  const firstQuarterNumbersByYear = healthData.reduce(
    (quartersByYear, point) => {
      const quarter = parseQuarter(point.quarter)
      if (
        !quartersByYear.hasOwnProperty(quarter.year) ||
        quarter.number < quartersByYear[quarter.year]
      ) {
        quartersByYear[quarter.year] = quarter.number
      }
      return quartersByYear
    },
    {},
  )

  const quarterlyTrendChartKey = `quarterly-health-score-chart::${
    company.id
  }-${selectedQuarter}`

  return (
    <div className={styles.companyHistory}>
      <Filters
        selectedFactor={selectedFactor}
        selectedSubfactor={selectedSubfactor}
        selectedGeography={selectedGeography}
        shouldShowTimeFrame={false}
      />

      <Section
        title="Quarterly Health Score Trend"
        className={styles.healthScoreTrend}
        contentClassName={styles.content}
        chartKey={quarterlyTrendChartKey}
      >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={healthData}
            margin={{top: 2, right: 10, bottom: 35, left: 0}}
            onClick={info => {
              if (info) {
                selectQuarter(info.activeLabel)
              }
            }}
            onMouseMove={({activeLabel}) => {
              if (activeLabel) {
                setShouldShowPointer(true)
              } else {
                setShouldShowPointer(false)
              }
            }}
            cursor={shouldShowPointer ? 'pointer' : undefined}
          >
            <XAxis
              dataKey="quarter"
              tick={
                <XAxisTick
                  selectedQuarter={selectedQuarter}
                  selectQuarter={selectQuarter}
                  firstQuarterNumbersByYear={firstQuarterNumbersByYear}
                />
              }
              interval={0}
            />
            <YAxis
              domain={[-10, 10]}
              ticks={[-10, 0, 10]}
              tickFormatter={val => (val > 0 ? `+${val}` : val)}
            />
            <Legend
              verticalAlign="bottom"
              wrapperStyle={{bottom: 0}}
              content={({payload}) => (
                <ul className={styles.legend}>
                  {payload.map(({value, color}, index) => {
                    let label
                    if (value.startsWith('subfactor-')) {
                      label = strings.subfactorDisplayName(
                        value
                          .split('-')
                          .slice(1)
                          .join('-'),
                      )
                    } else if (value.startsWith('factor-')) {
                      label = strings.factorDisplayName(
                        value
                          .split('-')
                          .slice(1)
                          .join('-'),
                      )
                    } else if (value === 'overall') {
                      label = 'Overall Health'
                    } else {
                      // This should never happen.
                      label = value
                    }
                    return (
                      <li className={styles.legendItem} key={`item-${index}`}>
                        <span
                          style={{backgroundColor: color}}
                          className={styles.dot}
                        />
                        <span>{label}</span>
                      </li>
                    )
                  })}
                </ul>
              )}
            />
            <Tooltip
              content={info => {
                if (!info || !info.label) return null
                const dataPoint = healthData.find(
                  point => point.quarter == info.label,
                )
                // This is possible if you are hovering over an older quarter
                // and then switch geographies, and that quarter is not
                // available in the new geo. It's very rare, so we just don't
                // render the tooltip in that case, instead of fixing it
                // properly.
                if (!dataPoint) return null
                return (
                  <QuarterTooltip
                    quarter={info.label}
                    dataPoint={dataPoint}
                    searchedVolumes={quarterlyArticleCounts}
                    factor={selectedFactor}
                    subfactor={selectedSubfactor}
                  />
                )
              }}
            />
            {/* Current quarter */}
            <ReferenceLine
              x={selectedQuarter.toString()}
              stroke={HIGHLIGHT_COLOR}
              strokeWidth={10}
            />
            {/* Zero line */}
            <ReferenceLine
              y={0}
              stroke="#999999"
              strokeWidth={1}
              strokeDasharray="3 3"
            />
            {/* Add a faint line for each year */}
            {healthData
              .filter(data => parseQuarter(data.quarter).number === 1)
              .map(data => (
                <ReferenceLine
                  x={data.quarter}
                  stroke="#ececec"
                  strokeWidth={1}
                  key={data.quarter.toString()}
                />
              ))}
            {/* Overall health line */}
            <Line
              dataKey="overall"
              stroke={COLORS[0]}
              dot={
                <CustomizedDot
                  volumeRangeMin={volumeRangesByKey.get('overall').min}
                  volumeRangeMax={volumeRangesByKey.get('overall').max}
                  searchedVolumes={quarterlyArticleCounts}
                />
              }
              activeDot={
                <CustomizedDot
                  volumeRangeMin={volumeRangesByKey.get('overall').min}
                  volumeRangeMax={volumeRangesByKey.get('overall').max}
                  searchedVolumes={quarterlyArticleCounts}
                  isActive={true}
                />
              }
              strokeWidth={2}
              isAnimationActive={false}
            />
            {selectedFactor && (
              <Line
                dataKey={`factor-${selectedFactor}`}
                stroke={COLORS[1]}
                dot={
                  <CustomizedDot
                    volumeRangeMin={
                      volumeRangesByKey.get(`factor-${selectedFactor}`).min
                    }
                    volumeRangeMax={
                      volumeRangesByKey.get(`factor-${selectedFactor}`).max
                    }
                    searchedVolumes={quarterlyArticleCounts}
                  />
                }
                activeDot={
                  <CustomizedDot
                    volumeRangeMin={
                      volumeRangesByKey.get(`factor-${selectedFactor}`).min
                    }
                    volumeRangeMax={
                      volumeRangesByKey.get(`factor-${selectedFactor}`).max
                    }
                    searchedVolumes={quarterlyArticleCounts}
                    isActive={true}
                  />
                }
                strokeWidth={2}
                isAnimationActive={false}
              />
            )}
            {selectedSubfactor && (
              <Line
                dataKey={`subfactor-${selectedSubfactor}`}
                stroke={COLORS[2]}
                dot={
                  <CustomizedDot
                    volumeRangeMin={
                      volumeRangesByKey.get(`subfactor-${selectedSubfactor}`)
                        .min
                    }
                    volumeRangeMax={
                      volumeRangesByKey.get(`subfactor-${selectedSubfactor}`)
                        .max
                    }
                    searchedVolumes={quarterlyArticleCounts}
                  />
                }
                activeDot={
                  <CustomizedDot
                    volumeRangeMin={
                      volumeRangesByKey.get(`subfactor-${selectedSubfactor}`)
                        .min
                    }
                    volumeRangeMax={
                      volumeRangesByKey.get(`subfactor-${selectedSubfactor}`)
                        .max
                    }
                    searchedVolumes={quarterlyArticleCounts}
                    isActive={true}
                  />
                }
                strokeWidth={2}
                isAnimationActive={false}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </Section>

      <Section
        title={
          <div className={styles.storylinesSectionTitle}>
            <span>
              Largest Storylines for{' '}
              <span style={{backgroundColor: HIGHLIGHT_COLOR}}>
                {selectedQuarter.toString()}
              </span>
            </span>
            <StorylineSearch
              value={searchText}
              onChange={setStorylineSearchText}
            />
          </div>
        }
        className={styles.storylines}
        contentClassName={styles.content}
      >
        <StorylinesTable storylines={storylines} />

        {storylines && !storylines.length && showEmptyState && (
          <NoStories
            companyName={company.name}
            quarter={selectedQuarter}
            areAnyFiltersActive={
              !!(selectedFactor || selectedSubfactor || selectedGeography)
            }
          />
        )}

        {/*Ghost Table*/}
        {storylines && !storylines.length && !showEmptyState && (
          <Table
            data={LOADING_DATA}
            className={classNames(styles.table, styles.loading, styles.ghost)}
            cellClassName={styles.cell}
          >
            <Table.Column
              name="date"
              baseWidth={120}
              maxWidth={220}
              growRatio={1}
              shrinkRatio={0}
              cellContents={<div className={styles.rect} />}
            />
            <Table.Column
              name="summary"
              baseWidth={400}
              growRatio={1}
              shrinkRatio={1}
              cellContents={<div className={styles.rect} />}
              className={styles.summary}
            />
            <Table.Column
              name="insights-summary"
              baseWidth={430}
              growRatio={0}
              shrinkRatio={0}
              cellContents={<div className={styles.rect} />}
              className={styles.factor}
            />
            <Table.Column
              name="subfactor"
              baseWidth={120}
              maxWidth={200}
              growRatio={1}
              shrinkRatio={1}
              cellContents={<div className={styles.rect} />}
              className={styles.subfactor}
            />
            <Table.Column
              name="volume"
              baseWidth={125}
              minWidth={80}
              growRatio={0}
              shrinkRatio={1}
              cellContents={<div className={styles.rect} />}
              className={styles.volume}
            />
          </Table>
        )}
      </Section>
      <SaveConfirmationModal />
    </div>
  )
}
CompanyHistory.propTypes = {
  company: PropTypes.object.isRequired,
  healthData: PropTypes.arrayOf(PropTypes.object),
  storylines: PropTypes.arrayOf(PropTypes.object),
  searchText: PropTypes.string.isRequired,
  autoQuarterSearch: PropTypes.bool,
  quarterlyArticleCounts: PropTypes.arrayOf(PropTypes.object),
  selectedFactor: PropTypes.string,
  selectedSubfactor: PropTypes.string,
  selectedGeography: PropTypes.number.isRequired,
  selectedQuarter: PropTypes.object.isRequired,
  selectQuarter: PropTypes.func.isRequired,
}
export default connect(
  createSelector(
    /*
     * We need to grab these values here instead of having them passed down
     * because we don't want anything to do with the user's saved defaults.
     */
    [selectors.getFactor, selectors.getSubfactor, selectors.getGeography],
    (selectedFactor, selectedSubfactor, selectedGeography) => ({
      selectedFactor: selectedFactor === 'all' ? null : selectedFactor,
      selectedSubfactor: selectedSubfactor === 'all' ? null : selectedSubfactor,
      selectedGeography: selectedGeography || 0,
    }),
  ),
)(CompanyHistory)
