import {handleActions} from 'redux-actions'
import {LOCATION_CHANGED} from 'redux-little-router'

import routes from 'app/routes'

import * as actions from './storyline-view-actions'

const getInitialState = () => ({
  storylineId: null,
  storyIds: [],
  isLoading: true,
})

export default handleActions(
  {
    [LOCATION_CHANGED]: (state, action) => {
      if (
        ![routes.storylineView, routes.storylineViewPortal].includes(
          action.payload.route,
        )
      ) {
        return getInitialState()
      }
      return {
        ...getInitialState(),
        storylineId: parseInt(action.payload.params.storylineId, 10),
      }
    },
    [actions.setStoryIds]: (state, action) => ({
      ...state,
      storyIds: action.payload,
    }),
    [actions.setIsLoading]: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
  },
  getInitialState(),
)
