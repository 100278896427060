import dateFns from 'date-fns'
import {fromPairs, map, pipe, toPairs} from 'ramda'
import routes from './routes'

import {adjustDateForUtc} from 'app/utils'

import helpIconUrl from 'static/images/menu-bar/help.svg'
import flagIconUrl from 'static/images/menu-bar/flag.svg'
import publishIconUrl from 'static/images/menu-bar/publish.svg'
import separatorIconUrl from 'static/images/menu-bar/separator.svg'
import adminIconUrl from 'static/images/menu-bar/settings.svg'
import emailNotificationsIconUrl from 'static/images/menu-bar/email_notifications.svg'
import logoutIconUrl from 'static/images/menu-bar/logout.svg'
import hamburgerIconUrl from 'static/images/menu-bar/hamburger.svg'

export const ROOT_PATH = process.env.ROOT_PATH
export const ROOT_URL = process.env.ROOT_URL
export const API_URL = process.env.API_URL
export const GRAPHQL_URL = process.env.GRAPHQL_URL
export const BASE_APP_URL = process.env.BASE_APP_URL
export const STATIC_FILES_URL = process.env.STATIC_FILES_URL

export const JWT_LOCAL_STORAGE_KEY = 'jwt'
export const PYAPP_LOGIN_REDIRECT_QUERY_PARAM_NAME = 'next-with-token'

export const SYSTEM_DATE_FORMAT = 'YYYY-MM-DD'
export const USER_DATE_FORMAT_NO_YEAR = 'MMMM D'
export const USER_DATE_FORMAT = `${USER_DATE_FORMAT_NO_YEAR}, YYYY`

export const MAX_HEALTH_SCORE = 10
export const DEFAULT_TIME_FRAME_DAYS = 30
export const DEFAULT_INDUSTRY_TIME_FRAME_DAYS = 90
export const DATA_COUNT_ROTATE_THRESHOLD = 8

export const CORPORATE_ENTITY_TYPE = 'CORPORATION'

// These are exported for convenience as well as performance (so we're not
// re-creating these objects every time we need them).
export const now = adjustDateForUtc(new Date())
export const today = dateFns.startOfDay(now)

export const SORT_DIRECTIONS = {
  ASC: 'asc',
  DESC: 'desc',
}

// The types of objects that can have health data
export const ENTITY_TYPES = {
  COMPANY: 'company',
  INDUSTRY: 'industry',
}

export const VALENCES = {
  POSITIVE: 'POS',
  NEGATIVE: 'NEG',
  NEUTRAL: 'NEU',
}
export const ORDERED_VALENCES = [
  VALENCES.POSITIVE,
  VALENCES.NEUTRAL,
  VALENCES.NEGATIVE,
]

export const ASPECTS = {
  LITIGATION: 'litigation',
  OPINION: 'opinion',
  RUMOR: 'rumor',
}
export const ORDERED_ASPECTS = [
  ASPECTS.LITIGATION,
  ASPECTS.OPINION,
  ASPECTS.RUMOR,
]

// To be used in place of string literals for referencing factors by name
export const FACTORS = {
  DEALS: 'partners',
  EXECS: 'management',
  FINANCE: 'financials',
  GOVERNMENT: 'government',
  OPERATIONS: 'operations',
  PRODUCT: 'products',
}

export const FACTORS_BY_ID = {
  38: FACTORS.FINANCE,
  39: FACTORS.GOVERNMENT,
  40: FACTORS.DEALS,
  41: FACTORS.OPERATIONS,
  43: FACTORS.PRODUCT,
  44: FACTORS.EXECS,
}

export const FACTOR_IDS_BY_FACTOR = pipe(
  toPairs,
  // Flip it and convert the ID to an integer.
  map(([factorId, factor]) => [factor, parseInt(factorId, 10)]),
  fromPairs,
)(FACTORS_BY_ID)

export const ORDERED_FACTORS = [
  FACTORS.EXECS,
  FACTORS.FINANCE,
  FACTORS.OPERATIONS,
  FACTORS.PRODUCT,
  FACTORS.DEALS,
  FACTORS.GOVERNMENT,
]

export const SUBFACTORS = {
  ANALYST: 'analyst',
  ATTACK_DISASTER: 'attack-disaster',
  BANKRUPTCY: 'bankruptcy',
  COMPETITION: 'competition',
  CRIME_WHITE_COLLAR: 'crime-white',
  CYBER: 'cyber',
  EXECUTIVE_MOVEMENT: 'executive-movement',
  EXECUTIVES: 'executives',
  EXPAND_CONTRACT: 'expand-contract',
  FINANCES: 'finances',
  INSIDER_STOCK: 'insider-stock',
  IP: 'ip',
  LABOR: 'labor',
  LITIGATION: 'litigation',
  MERGERS_AND_ACQUISITIONS: 'mna',
  OPINION: 'opinion',
  PARTNERSHIP: 'partnership',
  POLITICS: 'politics',
  PRODUCT: 'product',
  PUBLICITY: 'publicity',
  RECALL: 'recall',
  REGULATION: 'regulation',
  RUMOR: 'rumor',
  SALES: 'sales',
  SHAREHOLDERS: 'shareholders',
  STOCK: 'stock',
  SUPPLY_CHAIN: 'supply-chain',
  TAXES: 'taxes',
}

export const SUBFACTORS_BY_ID = {
  2: SUBFACTORS.STOCK,
  3: SUBFACTORS.ANALYST,
  4: SUBFACTORS.TAXES,
  5: SUBFACTORS.IP,
  6: SUBFACTORS.MERGERS_AND_ACQUISITIONS,
  7: SUBFACTORS.PARTNERSHIP,
  8: SUBFACTORS.REGULATION,
  9: SUBFACTORS.EXPAND_CONTRACT,
  11: SUBFACTORS.SALES,
  12: SUBFACTORS.EXECUTIVE_MOVEMENT,
  14: SUBFACTORS.PRODUCT,
  15: SUBFACTORS.LABOR,
  21: SUBFACTORS.PUBLICITY,
  22: SUBFACTORS.SUPPLY_CHAIN,
  25: SUBFACTORS.ATTACK_DISASTER,
  26: SUBFACTORS.CRIME_WHITE_COLLAR,
  39: SUBFACTORS.SHAREHOLDERS,
  40: SUBFACTORS.FINANCES,
  41: SUBFACTORS.EXECUTIVES,
  45: SUBFACTORS.RECALL,
  47: SUBFACTORS.POLITICS,
  49: SUBFACTORS.BANKRUPTCY,
  50: SUBFACTORS.CYBER,
  51: SUBFACTORS.INSIDER_STOCK,
  52: SUBFACTORS.COMPETITION,
}

export const SUBFACTOR_IDS_BY_SUBFACTOR = pipe(
  toPairs,
  // Flip it and convert the ID to an integer.
  map(([subfactorId, subfactor]) => [subfactor, parseInt(subfactorId, 10)]),
  fromPairs,
)(SUBFACTORS_BY_ID)

export const FACTORS_BY_SUBFACTOR = {
  [SUBFACTORS.STOCK]: FACTORS.FINANCE,
  [SUBFACTORS.ANALYST]: FACTORS.FINANCE,
  [SUBFACTORS.TAXES]: FACTORS.GOVERNMENT,
  [SUBFACTORS.IP]: FACTORS.PRODUCT,
  [SUBFACTORS.MERGERS_AND_ACQUISITIONS]: FACTORS.DEALS,
  [SUBFACTORS.PARTNERSHIP]: FACTORS.DEALS,
  [SUBFACTORS.REGULATION]: FACTORS.GOVERNMENT,
  [SUBFACTORS.EXPAND_CONTRACT]: FACTORS.OPERATIONS,
  [SUBFACTORS.SALES]: FACTORS.PRODUCT,
  [SUBFACTORS.EXECUTIVE_MOVEMENT]: FACTORS.EXECS,
  [SUBFACTORS.PRODUCT]: FACTORS.PRODUCT,
  [SUBFACTORS.LABOR]: FACTORS.OPERATIONS,
  [SUBFACTORS.PUBLICITY]: FACTORS.PRODUCT,
  [SUBFACTORS.SUPPLY_CHAIN]: FACTORS.OPERATIONS,
  [SUBFACTORS.ATTACK_DISASTER]: FACTORS.OPERATIONS,
  [SUBFACTORS.CRIME_WHITE_COLLAR]: FACTORS.EXECS,
  [SUBFACTORS.SHAREHOLDERS]: FACTORS.EXECS,
  [SUBFACTORS.FINANCES]: FACTORS.FINANCE,
  [SUBFACTORS.EXECUTIVES]: FACTORS.EXECS,
  [SUBFACTORS.RECALL]: FACTORS.PRODUCT,
  [SUBFACTORS.POLITICS]: FACTORS.GOVERNMENT,
  [SUBFACTORS.BANKRUPTCY]: FACTORS.FINANCE,
  [SUBFACTORS.CYBER]: FACTORS.OPERATIONS,
  [SUBFACTORS.INSIDER_STOCK]: FACTORS.EXECS,
  [SUBFACTORS.COMPETITION]: FACTORS.DEALS,
}

export const ORDERED_SUBFACTORS_BY_FACTOR = {
  [FACTORS.EXECS]: [
    SUBFACTORS.EXECUTIVES,
    SUBFACTORS.EXECUTIVE_MOVEMENT,
    SUBFACTORS.CRIME_WHITE_COLLAR,
    SUBFACTORS.SHAREHOLDERS,
    SUBFACTORS.INSIDER_STOCK,
  ],
  [FACTORS.FINANCE]: [
    SUBFACTORS.FINANCES,
    SUBFACTORS.BANKRUPTCY,
    SUBFACTORS.STOCK,
    SUBFACTORS.ANALYST,
  ],
  [FACTORS.OPERATIONS]: [
    SUBFACTORS.EXPAND_CONTRACT,
    SUBFACTORS.LABOR,
    SUBFACTORS.SUPPLY_CHAIN,
    SUBFACTORS.ATTACK_DISASTER,
    SUBFACTORS.CYBER,
  ],
  [FACTORS.PRODUCT]: [
    SUBFACTORS.PRODUCT,
    SUBFACTORS.SALES,
    SUBFACTORS.PUBLICITY,
    SUBFACTORS.IP,
    SUBFACTORS.RECALL,
  ],
  [FACTORS.DEALS]: [
    SUBFACTORS.PARTNERSHIP,
    SUBFACTORS.MERGERS_AND_ACQUISITIONS,
    SUBFACTORS.COMPETITION,
  ],
  [FACTORS.GOVERNMENT]: [
    SUBFACTORS.REGULATION,
    SUBFACTORS.TAXES,
    SUBFACTORS.POLITICS,
  ],
}

// Geo perspectives

export const GEOGRAPHIES = {
  ALL: 0,
  UK: 7,
  CANADA: 8,
  AUSTRALIA: 9,
  EUROPE: 10,
  ASIA: 11,
  AFRICA: 12,
  LATAM: 13,
  MIDEAST: 14,
}
export const GEOGRAPHIES_ORDERED = [
  GEOGRAPHIES.ALL,
  GEOGRAPHIES.UK,
  GEOGRAPHIES.CANADA,
  GEOGRAPHIES.AUSTRALIA,
  GEOGRAPHIES.EUROPE,
  GEOGRAPHIES.ASIA,
  GEOGRAPHIES.AFRICA,
  GEOGRAPHIES.LATAM,
  GEOGRAPHIES.MIDEAST,
]

export const ESG_PILLARS = {
  GOVERNANCE: 'governance',
  PROSPERITY: 'prosperity',
  PEOPLE: 'people',
  PLANET: 'planet',
}

export const ESG_PILLARS_BY_ID = {
  8: ESG_PILLARS.PLANET,
  19: ESG_PILLARS.PEOPLE,
  22: ESG_PILLARS.GOVERNANCE,
  42: ESG_PILLARS.PROSPERITY,
}

export const ESG_PILLAR_IDS_BY_ESG_PILLAR = pipe(
  toPairs,
  // Flip it and convert the ID to an integer.
  map(([pillarId, pillar]) => [pillar, parseInt(pillarId, 10)]),
  fromPairs,
)(ESG_PILLARS_BY_ID)

export const CHART_COLORS = [
  // Main colors
  '#19A7C4',
  '#F1B330',
  '#654059',
  '#F0703F',
  '#5D6496',
  '#2BD8D7',
  '#222222',

  // Second shade
  '#4ACDE8',
  '#F3C053',
  '#895879',
  '#F48F67',
  '#8E94B7',
  '#64E3E3',
  '#474747',

  // Third shade
  '#6FD7EC',
  '#F6CE79',
  '#A06A8E',
  '#F6AB8D',
  '#B4B7CF',
  '#98ECEC',
  '#707070',

  // Fourth shade
  '#93E1F1',
  '#F8DCA0',
  '#B78FAB',
  '#F9C7B3',
  '#CDCFE0',
  '#BAF2F2',
  '#858585',
]

// Storylines with these subfactors don't get shown in the top/recent
// storylines lists.
export const IGNORED_SUBFACTORS = [SUBFACTORS.ANALYST, SUBFACTORS.STOCK]

export const SUPPORT_EMAIL = 'support@manzama.com'
export const FEEDBACK_EMAIL = SUPPORT_EMAIL

export const SENDGRID_TRACKING_PLACEHOLDER = '@@mz_send_id@@'

export const DEFAULT_WINDOW_TITLE = `${process.env.BRAND_NAME} ${
  process.env.PRODUCT_NAME
}`

export const MENU_ICONS = {
  help: helpIconUrl,
  flag: flagIconUrl,
  publish: publishIconUrl,
  separator: separatorIconUrl,
  admin: adminIconUrl,
  emailNotifications: emailNotificationsIconUrl,
  logout: logoutIconUrl,
  hamburger: hamburgerIconUrl
}

export const screenWidthForHamburgerMenu = 1170
export const screenWidthForFullHamburgerMenu = 820

const MAX_BADGE_COUNT = 99
