import PropTypes from 'prop-types'
import React from 'react'

import RangeChart from 'app/common/charts/range-chart'
import {calculateHealthRange} from 'app/utils/health'

const CompanyRangeChart = ({company, className}) => {
  const {min, max} = calculateHealthRange(company)
  return (
    <RangeChart
      value={company.healthData.healthScore}
      rangeMinValue={min}
      rangeMaxValue={max}
      className={className}
    />
  )
}
CompanyRangeChart.propTypes = {
  company: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default CompanyRangeChart
