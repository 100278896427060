import classNames from 'classnames'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import {prop, whereEq} from 'ramda'
import React from 'react'

import Table from 'app/common/Table'
import {hasHealthData} from 'app/utils/health'
import {localComponent} from 'app/utils/redux'

import IndustryDetailsModal from './IndustryDetailsModal'
import IndustriesTableLoading from './IndustriesTableLoading'
import * as actions from './industries-table-actions'
import {getColumns} from './industries-table-columns'
import * as constants from './industries-table-constants'
import * as selectors from './industries-table-selectors'

import * as styles from './IndustriesTable.less'

export const sortByFunctionsByColumn = {
  name: prop('name'),
  health: industry =>
    hasHealthData(industry) ? industry.healthData.healthScore : undefined,
  companyCount: industry => industry.companyCount,
  myCompanies: (industry, myCompanyIds) =>
    industry.companies.filter(({id}) => myCompanyIds.includes(id)).length,
}

class IndustriesTable extends React.PureComponent {
  static propTypes = {
    industries: PropTypes.arrayOf(PropTypes.object).isRequired,
    myCompanyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    sort: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    shouldAutoSort: PropTypes.bool,

    geography: PropTypes.number,

    detailsModalIndustryId: PropTypes.number,
    isHeadSticky: PropTypes.bool,

    // Actions
    changeSort: PropTypes.func.isRequired,
    showIndustryDetailsModal: PropTypes.func.isRequired,
    hideIndustryDetailsModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    shouldAutoSort: true,
    shouldShowCompanyDetails: true,
    shouldShowIndustries: true,
    showCompanyDetails() {},
    hideCompanyDetails() {},
    isHeadSticky: false,
  }

  render() {
    const {
      industries,
      myCompanyIds,
      sort: currentSort,

      geography,

      detailsModalIndustryId,
    } = this.props

    // Build indexes for faster lookups.
    const myCompanyIdsSet = new Set(myCompanyIds)

    if (this.props.isLoading) {
      return <IndustriesTableLoading rowCount={industries.length} />
    }

    const detailsModalIndustry = industries.find(
      whereEq({id: detailsModalIndustryId}),
    )

    return (
      <React.Fragment>
        <Table
          data={industries}
          columns={getColumns({
            geography,
            myCompanyIdsSet,
            onDetailsClick: bind(this.onDetailsClick, this),
          })}
          className={classNames(styles.table, this.props.className)}
          rowClassName={styles.row}
          cellClassName={styles.cell}
          sort={currentSort}
          onSortChange={bind(this.props.changeSort, null)}
          ignoreSort={true}
          isHeadSticky={this.props.isHeadSticky}
        />

        {detailsModalIndustry && (
          <IndustryDetailsModal
            industry={detailsModalIndustry}
            myCompanyIds={myCompanyIds}
            onClose={bind(this.onDetailsModalClose, this)}
          />
        )}
      </React.Fragment>
    )
  }

  onDetailsClick(industry) {
    this.props.showIndustryDetailsModal({industryId: industry.id})
  }

  onDetailsModalClose() {
    this.props.hideIndustryDetailsModal()
  }
}

export default localComponent({
  key: constants.STATE_PROP_NAME,
  initAction: actions.init,
  deinitAction: actions.deinit,
  actions: {
    showIndustryDetailsModal: actions.showIndustryDetailsModal,
    hideIndustryDetailsModal: actions.hideIndustryDetailsModal,
  },
  stateSelector: selectors.getByKey,
  mapStateToProps: state => ({
    detailsModalIndustryId: state.detailsModalIndustryId,
  }),
})(IndustriesTable)
