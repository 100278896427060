import React from 'react'
import Modal from 'app/common/Modal'

import * as styles from './Modals.less'
import Button from 'app/common/Button'
import { useDispatch } from 'react-redux'

import * as actions from 'app/litigation/litigations-actions'
import * as constants from 'app/litigation/litigations-constants'

export default function ConfirmSaveDeleteModal({ item, visible, onClose, onDelete, confirmationStatus }) {
  const dispatch = useDispatch()
  const isConfirming = confirmationStatus === constants.ADD_REMOVE_LITIGATION_SEARCH_STATES.IN_PROGRESS
  const isSuccessful = confirmationStatus === constants.ADD_REMOVE_LITIGATION_SEARCH_STATES.SUCCESS
  const isError = confirmationStatus === constants.ADD_REMOVE_LITIGATION_SEARCH_STATES.ERROR

  const _onClose = (...args) => {
    dispatch(actions.resetRemoveLitigationSavedSearch())
    onClose(...args)
  }

  return (
    <>
      {visible &&
        <Modal
          onClose={_onClose}
          shouldShowCloseButton={true}
          className={styles.modal}
        >
          <h2>Are you sure you want to remove this saved search?</h2>

          {isSuccessful ?
            <h3>Successfully removed the search.</h3>
            :
            isError ?
              <h3>Error while trying to remove saved search. Try again.</h3>
              :
              <h3>You will be able to recreate it using the Litigation Monitoring page.</h3>
          }

          <div className={styles.buttons}>
            <Button
              label='Close'
              onClick={_onClose}
              isPlainText
              className={styles.button}
            />

            {!isSuccessful &&
              <Button
                label={
                  isConfirming ? 'Removing...' : 'Remove'
                }
                onClick={() => onDelete(item)}
                isDestructive
                isDisabled={isConfirming}
              />}
          </div>
        </Modal>}
    </>
  )
}
