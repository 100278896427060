import {FACTORS_BY_ID, FACTOR_IDS_BY_FACTOR} from 'app/constants'
import {gql} from 'app/graphql'
import {graphqlRequest} from 'app/utils/sagas'

const storyChunk = `
  id
  articleCount
  summary
  date
  valence
  subfactorId
  litigation
  rumor
  opinion
  perspectiveId
  company {
    id
    name
    savedsearchId
    entityType
  }
  storylineId
`

export function* fetchSimCircData({companyId}) {
  return yield* graphqlRequest(
    gql`
      query($companyId: Int) {
        targetCompanyDate: simCircLastTargetCompanyDate(companyId: $companyId) {
          id # for debug purposes
          targetDate
          matches {
            id
            company {
              id
              name
              savedSearchId: savedsearchId
            }
            matchMonth
            monthlySubfactorScores {
              subfactorId
              targetHealthScores
              matchHealthScores
            }
          }
          predictionDetails {
            factorId
            predictionsByMonth {
              predictedScore
              confidenceIntervalMin
              confidenceIntervalMax
              confidenceLevelText
            }
          }
          leadingHealthScores {
            factorId
            healthScores
          }
        }
      }
    `,
    {variables: {companyId}},
  )
}

export function* fetchSimCircTimeline({companyId, factor, matchId = null}) {
  const factorId = FACTOR_IDS_BY_FACTOR[factor] || null
  return yield* graphqlRequest(
    gql`
      query($companyId: Int, $factorId: Int, $matchId: BigInt) {
        targetCompanyDate: simCircLastTargetCompanyDate(companyId: $companyId) {
          id # for debug purposes
          storyTimeline(factorId: $factorId, matchId: $matchId) {
            nodes {
              dayIndex
              bars {
                groupId
                valence
                volume
              }
              topStories {
                ${() => storyChunk}
              }
            }
          }
        }
      }
    `,
    {variables: {companyId, factorId, matchId}},
  )
}

export function* fetchSimCircTimelineStories({
  companyId,
  daysSinceMatch,
  factor = null,
  matchId = null,
}) {
  const factorId = FACTOR_IDS_BY_FACTOR[factor] || null
  return yield* graphqlRequest(
    gql`
      query($companyId: Int, $daysSinceMatch: Int, $factorId: Int, $matchId: BigInt) {
        targetCompanyDate: simCircLastTargetCompanyDate(companyId: $companyId) {
          id # for debug purposes
          topMatchStories(daysSinceMatch: $daysSinceMatch, first: 20, factorId: $factorId, matchId: $matchId) {
            nodes {
              ${() => storyChunk}
              storyline {
                id
                summary
                startDate
                endDate
                valence
                subfactorId
                litigation
                rumor
                opinion
                articleCount
                perspectiveId
              }
              topArticle {
                id
                companyId
                contentdirectorId
                publishedAt
                headline
                description
                url
                valence
                subfactorId
                perspectiveId
                feed {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
    {variables: {companyId, daysSinceMatch, factorId, matchId}},
  )
}

export function* fetchFactorMaxChangePredictions({companyId}) {
  return (yield* graphqlRequest(
    gql`
      query {
        targetCompanyDate: simCircLastTargetCompanyDate(companyId: ${companyId}) {
          id  # for debug purposes
          predictionDetails {
            factorId
            predictionsByMonth {
              predictedScoreChange
              confidenceLevelText
            }
          }
        }
      }
    `,
  )).then(
    response =>
      response.body.data.targetCompanyDate &&
      response.body.data.targetCompanyDate.predictionDetails
        .filter(prediction => prediction.factorId)
        .map(prediction => {
          return {
            factor: FACTORS_BY_ID[prediction.factorId],
            // Get the maximum absolute change, but keep the sign.
            maxPredictedHealthScoreChange: prediction.predictionsByMonth.reduce(
              (acc, {predictedScoreChange}) => {
                if (
                  acc === null ||
                  Math.abs(predictedScoreChange) > Math.abs(acc)
                ) {
                  return predictedScoreChange
                }
                return acc
              },
              null,
            ),
            hasConfidenceLevelText: prediction.predictionsByMonth.reduce(
              (acc, {confidenceLevelText}) => {
                if (confidenceLevelText) {
                  return true
                }
                return acc
              },
              false,
            ),
          }
        }),
  )
}
