if (process.env.NODE_ENV === 'production') {
  document.addEventListener('DOMContentLoaded', () => {
    (function(apiKey){
      (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];
        v=['initialize','identify','updateOptions','pageLoad'];for(w=0,x=v.length;w<x;++w)(function(m){
          o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');

      // Call this whenever information about your visitors becomes available
      // Please use Strings, Numbers, or Bools for value types.
      pendo.initialize({
        visitor: {
          // id:              'VISITOR-UNIQUE-ID'   // Required if user is logged in
          // email:        // Optional
          // role:         // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          // id:           'ACCOUNT-UNIQUE-ID' // Highly recommended
          // name:         // Optional
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        }
      })
    })('8820892f-9c3f-4d27-7b7c-e0966eab1ad7')
  })

  // Trigger the ProdPad widget when we click on the "Have Feedback?" link in
  // the Pendo widget.
  document.addEventListener('click', event => {
    if (event.target.id === 'have-feedback-link') {
      const prodPadButton = document.getElementById('pp-cfpiaw-trigger')
      const pendoButton =
        document.getElementsByClassName('_pendo-launcher-badge_')[0]
      if (prodPadButton) {
        prodPadButton.click()

        // Also hide the Pendo help menu when clicked.
        if (pendoButton) {
          pendoButton.click()
        }
      }
    }
  })
}

export function updatePendoUserId(user) {
  if (typeof pendo === 'undefined') return
  const visitor = {}
  const account = {}
  if (user) {
    const {id, name, role, isFirmAdmin, firm} = user
    visitor.id = id
    visitor.name = name
    visitor.role = role
    visitor.isFirmAdmin = isFirmAdmin
    visitor.country = firm.country
    account.id = firm.id
    account.name = firm.name
  }
  pendo.identify({visitor, account})
}
