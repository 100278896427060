import {always, append, without} from 'ramda'

import {SORT_DIRECTIONS} from 'app/constants'
import {handleLocalActions} from 'app/utils/redux'

import * as actions from './companies-table-actions'

const getInitialState = () => ({
  currentSort: {column: 'health', direction: SORT_DIRECTIONS.ASC},
  companyDetailsShownForId: null,
  industriesExpandedForIds: [],
})

export default handleLocalActions({
  [actions.init]: getInitialState,
  [actions.deinit]: always(undefined),
  [actions.setSort]: (state, action) => ({
    ...state,
    currentSort: action.payload,
  }),
  [actions.showCompanyDetails]: (state, action) => ({
    ...state,
    companyDetailsShownForId: action.payload.companyId,
  }),
  [actions.hideCompanyDetails]: (state, action) => ({
    ...state,
    companyDetailsShownForId: getInitialState().companyDetailsShownForId,
  }),
  [actions.expandCompanyIndustries]: (state, action) => ({
    ...state,
    industriesExpandedForIds: append(
      action.payload.companyId,
      state.industriesExpandedForIds,
    ),
  }),
  [actions.collapseCompanyIndustries]: (state, action) => ({
    ...state,
    industriesExpandedForIds: without(
      [action.payload.companyId],
      state.industriesExpandedForIds,
    ),
  }),
})
