import React, { useEffect, useState } from 'react'
import * as styles from './Chart.less'
import * as selectors from 'app/litigation/litigations-selectors'
import PropTypes from 'prop-types'
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Button from 'app/common/Button'
import { navigate } from 'app/global/global-actions'
import { useDispatch, useSelector } from 'react-redux'
import urls from 'app/urls'
import GraphLoading from '../GraphLoading'
import classNames from 'classnames'
import { actions } from '..'
import { FILTER_CHECKBOXES, NO_COMPANY_LITIGATION_DATA } from '../litigations-constants'

/**
 * Vertical barchart to visualize the number of stories in each industry.
 *
 * @param sectors An array of sector objects.
 * @param isLoading Flag whether the chart data is still loading.
 * @param showHeader Show chart header.
 * @param hideShowAll Hide the show more button and display all sectors.
 * @param xAxisDomain Override the XAxis domain parameter. Refer to the recharts documentation on more.
 * @returns {JSX.Element}
 */
export default function Chart({ sectors, isLoading, showHeader, hideShowAll, xAxisDomain, selectedCompanies }) {
  const [isBigScreen, setIsBigScreen] = useState(window.matchMedia('(min-width: 1000px)').matches)
  const [showAll, setShowAll] = useState(hideShowAll)
  const MAX_ENTRIES_COLLAPSED = 12
  const dispatch = useDispatch()
  const mainPage = useSelector(selectors.getMainPage)
  const { selectedFilter, selectedCompanyIds, selectedSectors, myCompanies } = mainPage
  const myCompaniesSelected = selectedFilter === FILTER_CHECKBOXES.myCompanies

  useEffect(() => {
    const handler = e => setIsBigScreen(e.matches)
    window.matchMedia('(min-width: 1000px)').addEventListener('change', handler)
  })

  const companiesData = selectedCompanies && selectedCompanies.length > 0 ? selectedCompanies : myCompanies
  const chartData = myCompaniesSelected ? companiesData : sectors
  const sortedChartData = [...chartData].sort((a, b) => {
    if (a.litigationStoryCount < b.litigationStoryCount) {
      return 1
    } else if (a.litigationStoryCount > b.litigationStoryCount) {
      return -1
    }
    return 0
  }).slice(0, showAll ? chartData.length : MAX_ENTRIES_COLLAPSED)

  const handleMyCompanyScenario = (isBarClick, companyId)=> {
    const companiesIndustries = new Set();
    if(isBarClick) {
      const company = myCompanies.find(com => com.id === companyId)
      if (company) {
        dispatch(actions.setSelectedCompanies([companyId]))
        company.industries.forEach(industry => {
            companiesIndustries.add(industry.id)
        })
      }
    }
    else {
      dispatch(actions.setSelectedCompanies(selectedCompanyIds))
      selectedCompanies.forEach(company => {
        company.industries.forEach(industry => {
          companiesIndustries.add(industry.id)
        })
      })
    }
    dispatch(actions.setSelectedSectors([...companiesIndustries]))
  }

  const handleOnBarClick = (sector) => {
    if (myCompaniesSelected){
      handleMyCompanyScenario(true, sector.id)
    }
    else {
      dispatch(actions.setSelectedSectors([sector.id]))
    }
    dispatch(actions.setIsOnMainPage(false))
  }

  const handleOpenButtonClick = () => {
    if (myCompaniesSelected) {
      handleMyCompanyScenario()
    } 
    dispatch(actions.setIsOnMainPage(false))
  }


  /**
   * This function renders the labels on the bars.
   * The labels are being rendered inside the bar as long as it doesn't get too small.
   * In that case they'll be rendered next to the bar.
   */
  const renderCustomizedLabel = (props) => {
    const {
      x, y, width, height, value,
    } = props

    const fireOffset = width < value.toString().length * 13
    const offset = fireOffset ? -40 : 5
    return (
      <text x={x + width - offset} y={y + height - 7.5}
            style={{
              fill: fireOffset ? '#000' : '#fff', fontWeight: 800, cursor: fireOffset ? 'default' : 'pointer',
              pointerEvents: 'none'
            }}
            textAnchor='end'>
        {value}
      </text>
    )
  }

  const renderChart = () => {
    if (chartData.length > 0) {
      return (
        <ResponsiveContainer
          width={'90%'}
          height={40 * sortedChartData.length + 50}
        >
          <BarChart
            data={sortedChartData}
            margin={{ top: 0, right: 30, left: 0, bottom: 5 }}
            layout='vertical'
          >
            <XAxis
              type='number'
              axisLine={false}
              tickLine={false}
              domain={xAxisDomain !== undefined ? xAxisDomain : [0, 'auto']}
            />
            <YAxis
              dataKey='name'
              type='category'
              axisLine={false}
              tickLine={false}
              width={isBigScreen ? 275 : 195}
              tick={{ fontSize: '16px', cursor: 'pointer' }}
              onClick={(tick) => handleOnBarClick(sortedChartData[tick.index])}
            />
            <CartesianGrid
              horizontal={false}
            />
            <Tooltip
              content={() => null}
              cursor={{ fill: '#E2ECF2' }}
            />
            <Bar
              dataKey='litigationStoryCount'
              fill='#1C6C96'
              barSize={25}
              style={{ cursor: 'pointer' }}
              isAnimationActive={false}
              onClick={handleOnBarClick}
            >
              <LabelList
                dataKey='litigationStoryCount'
                position='insideRight'
                content={renderCustomizedLabel}
                style={{ fill: '#fff', cursor: 'pointer', fontWeight: '800' }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )
    } else {
      return (
        <div className={styles.noMatch}>{NO_COMPANY_LITIGATION_DATA}</div>
      )
    }
  }

  const title = myCompaniesSelected ? 'Company' : 'Industry Sector'
  const showOpenSelectedButton = myCompaniesSelected ? selectedCompanyIds.length > 0 : (selectedSectors.length > 0 && chartData.length > 0)
  return (
    <div className={styles.graphContainer}>
      {showHeader && <>
        <div className={styles.graphHeader}>
          <div className={styles.sectors}>{title}</div>
          <div className={styles.sectorsSize}>Number of Company-Related Litigation Stories</div>
          { showOpenSelectedButton ? <Button
            className={classNames(styles.openSelectedButton)}
            label={'Open Selected'}
            onClick={handleOpenButtonClick}
          /> : null} 
        </div>
        <hr className={styles.separator}/>
      </>}
      <div className={styles.graphBody}>
        <GraphLoading isLoading={isLoading}>
          {renderChart()}
        </GraphLoading>
      </div>
      {(chartData.length > MAX_ENTRIES_COLLAPSED) && !hideShowAll &&
        <div className={styles.collapseButtonContainer}>
          <Button label={showAll ? 'Show less...' : 'Show more...'} className={styles.button} onClick={() => {
            setShowAll(show => !show)
          }}/>
        </div>}
    </div>
  )
}

Chart.propTypes = {
  sectors: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    litigationStoryCount: PropTypes.number,
  })),
  isLoading: PropTypes.bool.isRequired,
  showHeader: PropTypes.bool,
  hideShowAll: PropTypes.bool,
  selectedCompanies: PropTypes.array,
}

Chart.defaultProps = {
  showHeader: true,
  hideShowAll: false,
}
