import {monthDisplayName, monthShortDisplayName} from 'app/strings'

export function matchMonthDisplayName(matchMonth) {
  const [year, monthNumber] = matchMonth.split('-').map(num => parseInt(num))
  return `${monthDisplayName(monthNumber - 1)} ${year}`
}

export function matchMonthShortDisplayName(matchMonth) {
  const [year, monthNumber] = matchMonth.split('-').map(num => parseInt(num))
  return `${monthShortDisplayName(monthNumber - 1)}. ${year}`
}
