import React from 'react'

function CustomTick({ fill, width, height, x, y, stroke, textAnchor, payload }) {
  const convertedDate = new Date(payload.value).toLocaleString('en-us', { month: 'long', year: 'numeric' })
  return (
    <text width={width} height={height} x={x} y={y} stroke={stroke} fill={fill}
          className='recharts-text recharts-cartesian-axis-tick-value' textAnchor={textAnchor}>
      <tspan dy={'1em'}>{convertedDate}</tspan>
    </text>
  )
}

function CustomTickWithCollector(props) {
  const { x, y, payload, tooltipCollector } = props
  tooltipCollector.collect(payload.value, y)
  return (
    <g>
      <text x={x} y={y} fill="#5d6571" textAnchor="end" dy={"0.355em"}>{payload.value}</text>
    </g>
  )
}

export { CustomTick, CustomTickWithCollector }
