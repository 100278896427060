import classNames from 'classnames'
import dateFns from 'date-fns'
import PropTypes from 'prop-types'
import {sort} from 'ramda'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import Columns, {Column} from 'app/common/Columns'
import FactorIcon from 'app/common/FactorIcon'
import {LoadingMessage} from 'app/common/loading-message'
import Link from 'app/common/NavigationLink'
import Section from 'app/common/Section'
import TextSeparator from 'app/common/TextSeparator'
import {ASPECTS, CHART_COLORS, ORDERED_ASPECTS, VALENCES} from 'app/constants'
import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import {Story, Storyline} from 'app/models'
import {getRouterState} from 'app/router/router-selectors'
import routes from 'app/routes'
import {StoryTimeline} from 'app/storylines/story-timeline'
import * as strings from 'app/strings'
import urls from 'app/urls'
import {compare, compareDates, insertBetweenItems} from 'app/utils'

import CompaniesMentioned from './CompaniesMentioned'
import RelatedStorylines from './RelatedStorylines'
import StoryList from './StoryList'
import * as selectors from './storyline-view-selectors'

import * as styles from './StorylineView.less'

const colorForIndex = index => {
  return CHART_COLORS[index % CHART_COLORS.length]
}

function StorylineView({storyline, stories, isPortalPage, isLoading}) {
  if (isLoading) {
    return <LoadingMessage />
  }

  const {company, startDate, endDate} = storyline
  const sortedStories = sort(
    (story1, story2) =>
      compareDates(story1.date, story2.date) ||
      compare(story2.articleCount, story1.articleCount),
    stories,
  )
  let dateRange = strings.storyCount(stories.length)
  if (dateFns.isSameDay(startDate, endDate)) {
    dateRange += ` on ${strings.formatDate(startDate)}`
  } else {
    dateRange += ` from ${strings.formatDateRange(startDate, endDate)}`
  }
  dateRange += ' UTC'

  const colors = sortedStories.reduce(
    ({colorIndex, colors}, story) => {
      if (story.valence === VALENCES.NEUTRAL) {
        colors.push('#666666')
      } else {
        colors.push(colorForIndex(colorIndex))
        colorIndex += 1
      }
      return {colorIndex, colors}
    },
    {colorIndex: 0, colors: []},
  ).colors
  const isSubIndustry = company.entityType === 'INDUSTRY'

  return (
    <div className={classNames(styles.storylineView, 'storyline-view')}>
      <div className={styles.pageContent}>
        <h1 className={styles.head}>
          <span className={styles.headline}>
            <FactorIcon
              factor={storyline.factor}
              className={styles.factorIcon}
            />
            {storyline.summary}
          </span>
        </h1>

        <div className={styles.companyBreadcrumbs}>
          {!isSubIndustry ? (
            <Link
              href={(isPortalPage
                ? urls.companyOverviewPortal
                : urls.companyOverview)(company.id)}
            >
              {company.name}
            </Link>
          ) : (
            company.name
          )}

          <span className={styles.separator} />

          {!isSubIndustry ? (
            <Link
              href={(isPortalPage
                ? urls.companyOverviewPortal
                : urls.companyOverview)(company.id, {factor: storyline.factor})}
            >
              {strings.factorDisplayName(storyline.factor)}
            </Link>
          ) : (
            strings.factorDisplayName(storyline.factor)
          )}

          <span className={styles.separator} />

          {!isSubIndustry ? (
            <Link
              href={(isPortalPage
                ? urls.companyOverviewPortal
                : urls.companyOverview)(company.id, {
                subfactor: storyline.subfactor,
              })}
            >
              {strings.subfactorDisplayName(storyline.subfactor)}
            </Link>
          ) : (
            strings.subfactorDisplayName(storyline.subfactor)
          )}
          <Aspects storyline={storyline} />
        </div>

        <div className={styles.industries}>
          {company.industries.length ? (
            <>
              <span className={styles.label}>Related Industries:</span>
              {insertBetweenItems(
                company.industries.map(industry => (
                  <Link
                    href={urls.industryOverview(industry.id)}
                    className={classNames(styles.industry, 'industry-link')}
                    key={industry.id}
                  >
                    {industry.name}
                  </Link>
                )),
                index => (
                  <TextSeparator key={`separator-${index}`} />
                ),
              )}
            </>
          ) : (
            <span className={styles.label}>
              This storyline is not related to an Insights industry
            </span>
          )}
        </div>

        <div className={styles.timelineContainer}>
          <StoryTimeline
            stories={sortedStories}
            startDate={storyline.startDate}
            endDate={storyline.endDate}
            colors={colors}
          />
        </div>

        <Columns className={styles.mainContent}>
          <Column className={styles.storiesColumn}>
            <h2 className={styles.header}>{dateRange}</h2>

            <StoryList
              stories={sortedStories}
              colors={colors}
              className={styles.stories}
            />
          </Column>

          <Column className={styles.relatedColumn}>
            <Section
              title={`${process.env.PRODUCT_NAME} Companies Mentioned`}
              className={styles.companiesMentioned}
              contentClassName={styles.content}
            >
              <CompaniesMentioned
                storyline={storyline}
                isPortalPage={isPortalPage}
              />
            </Section>

            <Section
              title="Related Storylines"
              className={styles.relatedStorylines}
              contentClassName={styles.content}
            >
              <RelatedStorylines storyline={storyline} />
            </Section>
          </Column>
        </Columns>
      </div>
    </div>
  )
}
StorylineView.propTypes = {
  storyline: PropTypes.object,
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
  isPortalPage: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
}
export default connect(
  createSelector(
    [getEntities, getRouterState, selectors.getStorylineViewState],
    (entities, routerState, {storylineId, storyIds, isLoading}) => {
      const orm = Orm.withEntities(entities)
      const isPortalPage = routerState.route === routes.storylineViewPortal
      return {
        storyline: storylineId && orm.getById(Storyline, storylineId),
        stories: storyIds && orm.getByIds(Story, storyIds),
        isPortalPage,
        isLoading,
      }
    },
  ),
)(StorylineView)

function Aspects({storyline}) {
  const hasAspect = aspect =>
    ({
      [ASPECTS.LITIGATION]: storyline.isLitigation,
      [ASPECTS.OPINION]: storyline.isOpinion,
      [ASPECTS.RUMOR]: storyline.isRumor,
    }[aspect])
  return ORDERED_ASPECTS.filter(hasAspect).map(aspect => (
    <React.Fragment key={aspect}>
      <TextSeparator />
      {strings.aspectDisplayName(aspect)}
    </React.Fragment>
  ))
}
Aspects.propTypes = {
  storyline: PropTypes.object.isRequired,
}
