import React, { useEffect, useMemo, useRef, useState } from 'react'
import { range } from 'ramda'
import PropTypes from 'prop-types'
import { debounce } from 'debounce'
import classNames from 'classnames'
import { go } from 'redux-little-router'
import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'

import Button from 'app/common/Button'
import { Tooltip } from 'app/common/tooltip'
import LoadingSpinner from 'app/common/loading-spinner'
import Table from 'app/common/Table'
import { SUBFACTORS_BY_ID } from 'app/constants'
import * as entitiesSelectors from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import { navigate } from 'app/global/global-actions'
import { LitigationSavedSearch, LitigationNumberSummary } from 'app/models'
import { getRouterState } from 'app/router/router-selectors'
import routes from 'app/routes'
import urls from 'app/urls'
import { subfactorDisplayName } from 'app/strings'
import { useResizeObserver } from 'app/utils/hooks'

import { LitigationLayout, MainLayout } from 'app/litigation/layout'
import * as actions from 'app/litigation/litigations-actions'
import * as selectors from 'app/litigation/litigations-selectors'
import { timeAgo } from 'app/litigation/utils'
import BackArrow from 'app/litigation/icons/BackArrow'
import QuestionMark from 'app/litigation/icons/QuestionMark'
import TrashIcon from 'app/litigation/icons/TrashIcon'
import { DEFAULT_LIMIT } from 'app/litigation/litigations-constants'
import { ConfirmSaveDeleteModal } from 'app/litigation/modals'
import * as styles from 'app/litigation/Loader/Loader.less'

const LOADING_DATA = range(0, 10).map(i => ({ id: i }))

const ASPECT_MAPPING = {
  all: 'All stories',
  rf_and_of: 'Objective Stories',
  rt_and_of: 'Only Rumors',
  rf_and_ot: 'Only Opinions',
  rt_or_ot: 'Rumors and/or Opinions',
}

/**
 * Display a text with text-overflow: ellipsis.
 * If the text overflows, a Tooltip displays the full text.
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
const EllipseWithTooltip = ({ text }) => {
  const ellipseRef = useRef()
  const ResizeObserver = useResizeObserver()
  const [currentDimensions, setCurrentDimensions] = useState({
    offsetWidth: 0,
    scrollWidth: 0,
  })

  useEffect(() => {
    const ro = new ResizeObserver(debounce((entries) => {
      setCurrentDimensions({
        offsetWidth: entries[0].target.offsetWidth,
        scrollWidth: entries[0].target.scrollWidth,
      })
    }, 300))

    ro.observe(ellipseRef.current)

    return () => ro.unobserve(ellipseRef.current)
  }, [])

  return (
    <Tooltip
      label={text}
      direction='bottom'
      className={classNames({
        [styles.showNone]: (currentDimensions.offsetWidth >= currentDimensions.scrollWidth)
      })}
    >
      <div ref={ellipseRef} className={styles.cellParagraph}>
          <span className={classNames(styles.capitalize)}>
            {text}
          </span>
      </div>
    </Tooltip>
  )
}

const _selector = createSelector([
    getRouterState,
    entitiesSelectors.getEntities,
    selectors.getSavedLitigationSearchLoading,
    selectors.getSavedLitigationSearchData,
    selectors.getSavedLitigationSearchError,
    selectors.getSavedLitigationSearchCount,
    selectors.getSavedLitigationSearchRemove,
  ],
  (router, entities, isLoading, data, error, count, removeStatus) => {
    let savedSearches = null
    if (data) {
      const orm = Orm.withEntities(entities)
      savedSearches = orm.getByIds(LitigationSavedSearch, data)
    }

    let previousPage
    if (router && router.previous && [routes.litigation].includes(router.previous.route)) {
      previousPage = () => go(-1)
    } else {
      previousPage = () => navigate(urls.litigation())
    }

    return {
      previousPage,
      isLoading,
      savedSearches,
      error,
      count,
      removeStatus,
    }
  }
)

export default function LoaderView() {
  const {
    previousPage,
    isLoading,
    savedSearches,
    error,
    count,
    removeStatus,
  } = useSelector(_selector)
  const dispatch = useDispatch()

  return <Loader
    previousPage={previousPage}
    items={savedSearches}
    isLoading={isLoading}
    deleteItem={(item) => dispatch(actions.removeLitigationSavedSearch(item))}
    isMoreItemsAvailable={savedSearches ? count > savedSearches.length : false}
    fetchMore={() => dispatch(actions.fetchMoreLitigationSavedSearches(DEFAULT_LIMIT))}
    onSortChange={({
      column,
      direction
    }) => dispatch(actions.setLitigationSavedSearchOrderBy({
      field: column === 'sector' ? 'settings->sector->>name' : column,
      direction,
      skipSnakeCase: true,
    }))}
    removeStatus={removeStatus}
  />
}

function Loader({
  previousPage,
  items,
  isLoading,
  deleteItem,
  isMoreItemsAvailable,
  fetchMore,
  onSortChange,
  removeStatus,
}) {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [currentRowToBeDeleted, setCurrentRowToBeDeleted] = useState(null)
  const dispatch = useDispatch()

  /**
   * Transform the items array to something that can be read without accessing something undefined.
   * This could be much more readable if we had optionalChaining enabled, but it is not ATM.
   *
   * TODO: Refactor this when optionalChaining is enabled in the project.
   */
  const getItems = useMemo(() => {
    return items !== null ? items.map(item => {
      let _item = {}
      _item.name = item.name ? item.name : ''
      _item.id = item.id
      _item.createdAt = item.createdAt
      _item.modifiedAt = item.modifiedAt

      if (item.settings && item.settings.sector && item.settings.sector.filters) {
        const filters = item.settings.sector.filters
        if (filters.factors) {
          _item.factors = filters.factors.filter(factor => SUBFACTORS_BY_ID[factor.id] !== undefined)
            .map(factor => subfactorDisplayName(SUBFACTORS_BY_ID[factor.id]))
        } else {
          _item.factors = []
        }

        if (filters.aspect) {
          _item.aspect = ASPECT_MAPPING[filters.aspect] || ''
        } else {
          _item.aspect = ''
        }

        _item.valences = [
          ...filters.valencePositive ? ['Positive'] : [],
          ...filters.valenceNeutral ? ['Neutral'] : [],
          ...filters.valenceNegative ? ['Negative'] : [],
        ].join(', ')

      } else {
        _item = { ..._item, ...{ factors: [], valences: [], aspects: '' } }
      }

      if (item.settings && item.settings.mainPage) {
        _item.companyCountMinimum = item.settings.mainPage.companyCountMinimum
        _item.companyCountMaximum = item.settings.mainPage.companyCountMaximum
        _item.litigationAffectedCompaniesMinimum = item.settings.mainPage.litigationAffectedCompaniesMinimum
        _item.litigationAffectedCompaniesMaximum = item.settings.mainPage.litigationAffectedCompaniesMaximum
      } else {
        _item = {
          ..._item, ...{
            companyCountMinimum: null,
            companyCountMaximum: null,
            litigationAffectedCompaniesMinimum: null,
            litigationAffectedCompaniesMaximum: null,
          }
        }
      }

      return _item
    }) : null
  }, [items])

  const withLoadingRect = (children) => {
    if (items === null && isLoading) {
      return <div className={styles.loading}/>
    }
    return children
  }

  return (
    <LitigationLayout showButtons={false}>
      <MainLayout className={styles.mainLayout}>
        <ConfirmSaveDeleteModal
          item={currentRowToBeDeleted}
          onClose={() => setDeleteModalVisible(false)}
          onDelete={() => deleteItem(currentRowToBeDeleted)}
          visible={deleteModalVisible}
          confirmationStatus={removeStatus.status}
        />

        <div className={styles.backLink}>
          <a href='#' onClick={() => dispatch(previousPage())}><BackArrow className={styles.backIcon}/> BACK</a>
        </div>

        <Table
          data={getItems !== null ? getItems : LOADING_DATA}
          onSortChange={onSortChange}
        >
          <Table.Column
            name={'name'}
            label={'Name'}
            baseWidth={100}
            isSortable={true}
            cellContents={savedItem => withLoadingRect(
              <div className={classNames(styles.cellParagraph, styles.link)}
                   onClick={() => dispatch(actions.selectLitigationSavedSearch(savedItem.id))}><EllipseWithTooltip text={savedItem.name}/></div>
            )}
          />
          <Table.Column
            name={'subfactors'}
            label={'Subfactor Filter'}
            baseWidth={100}
            cellContents={savedItem => withLoadingRect(<EllipseWithTooltip
              text={savedItem.factors && savedItem.factors.join(', ')}/>)}
          />
          <Table.Column
            name={'companycountrange'}
            label={<>Company Count Range
              <Tooltip
                label={'Filters the Industry Sectors list by the minimum and maximum number of companies ' +
                  'within each industry sector'}
                direction={'left'}
              >
                <QuestionMark className={styles.inlineIcon}/>
              </Tooltip></>}
            baseWidth={100}
            cellContents={savedItem => withLoadingRect(
              <p>{savedItem.companyCountMinimum} - {savedItem.companyCountMaximum}</p>)}
          />
          <Table.Column
            name={'companiestosectorsize'}
            label={(
              <>Litigation-Affected Companies To Sector Size
                <Tooltip
                  label={'Filters the Industry Sectors list by the percentage of companies within the industry ' +
                    'sector affected by litigation stories'}
                  direction={'left'}
                >
                  <QuestionMark className={styles.inlineIcon}/>
                </Tooltip></>
            )}
            baseWidth={100}
            cellContents={savedItem => withLoadingRect(
              <p>{savedItem.litigationAffectedCompaniesMinimum
                && `${savedItem.litigationAffectedCompaniesMinimum}%`} - {savedItem.litigationAffectedCompaniesMaximum
                && `${savedItem.litigationAffectedCompaniesMaximum}%`}</p>
            )}
          />
          <Table.Column
            name={'valences'}
            label={(
              <>Valence Filter
                <Tooltip
                  label={'Filters the companies shown by their positive, neutral, and/or negative litigation stories'}
                  direction={'left'}
                >
                  <QuestionMark className={styles.inlineIcon}/>
                </Tooltip></>
            )}
            baseWidth={100}
            cellContents={savedItem => withLoadingRect(<EllipseWithTooltip text={savedItem.valences}/>)}
          />
          <Table.Column
            name={'aspects'}
            label={(
              <>Aspect Filter
                <Tooltip
                  label={'Filters the companies shown by the selected combination of rumor and/or opinion litigation stories'}
                  direction={'left'}>
                  <QuestionMark className={styles.inlineIcon}/>
                </Tooltip></>
            )}
            baseWidth={100}
            cellContents={savedItem => withLoadingRect(<p>{savedItem.aspect}</p>)}
          />
          <Table.Column
            name={'modified_at'}
            label={'Last Modified'}
            baseWidth={100}
            isSortable={true}
            cellContents={savedItem =>
              withLoadingRect(<p>{savedItem.modifiedAt && timeAgo(new Date(savedItem.modifiedAt))}</p>)}
          />
          <Table.Column
            name={'created_at'}
            label={'Date Created'}
            baseWidth={100}
            isSortable={true}
            cellContents={savedItem =>
              withLoadingRect(<p>{savedItem.createdAt && timeAgo(new Date(savedItem.createdAt))}</p>)}
          />
          <Table.Column
            name={'delete'}
            label={''}
            baseWidth={60}
            growRatio={0}
            shrinkRatio={0}
            cellContents={savedItem =>
              withLoadingRect(
                <TrashIcon
                  onClick={() => {
                    if (savedItem) {
                      setCurrentRowToBeDeleted(savedItem)
                      setDeleteModalVisible(true)
                    }
                  }}
                  className={styles.deleteIcon}
                />
              )}
          />
        </Table>
        {isMoreItemsAvailable &&
          <>
            {isLoading ?
              <LoadingSpinner className={styles.moreLoadingSpinner}/>
              :
              <Button
                label={'Show more...'}
                isPlainText={true}
                className={styles.loadMoreButton}
                onClick={fetchMore}
              />
            }
          </>
        }

      </MainLayout>
    </LitigationLayout>
  )
}

Loader.propTypes = {
  items: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  deleteItem: PropTypes.func.isRequired,
  isMoreItemsAvailable: PropTypes.bool.isRequired,
  fetchMore: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
}
