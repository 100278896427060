import React from 'react'
import Table from 'email-server-common/common/Table'
import Row from 'email-server-common/common/Row'
import Cell from 'email-server-common/common/Cell'
import urls, { USRC_VALUES, withSendGridTracking } from '../../app/urls'
import Link from 'email-server-common/common/Link'
import { colors } from './constants'
import { STATIC_FILES_URL } from '../../app/constants'

export default function Footer({
  rootUrl,
  copyrightHolderName,
  contactAddress,
  contactPhone,
  enableUpdateYourProfile,
}) {
  return (
    <Table
      style={{
        margin: '0 auto',
      }}
      width='600'
      align='center'
    >
      <Row>
        <Cell height='53'/>
      </Row>
      <Row>
        <Cell
          style={{
            textAlign: 'center',
            color: colors.secondaryFontColor,
            fontSize: 12,
            lineHeight: '150%',
          }}
        >
          { enableUpdateYourProfile && (
            <div>
              Does this daily digest reflect your interests?<br/>
              If not: <Link
                href={withSendGridTracking(
                  urls.pyapp.emailSettings({
                    source: USRC_VALUES.ESG_EMAIL,
                    root: rootUrl,
                  }),
                )}
              >
                update your profile
              </Link><br/>
            </div>
          )}
          <div
            style={{
              marginTop: 32,
              marginBottom: 32,
            }}
          >
            © 2022 {copyrightHolderName}<br/>
            {contactAddress}<br/>
            P: +1-{contactPhone}
          </div>
          <div
            style={{
              marginBottom: 50,
            }}
          >
            <img
              src={`${STATIC_FILES_URL}/logos/MZLogo-20230509.png`}
              style={{
                width: '81px',
                display: 'block',
                margin: '0 auto',
              }}
              alt='Manzama Logo'
            />
          </div>
        </Cell>
      </Row>
    </Table>
  )
}
