import PropTypes from 'prop-types'
import React from 'react'

export default function LinkedInIcon({className}) {
  return (
    <svg viewBox="0 0 266.9 266.9" className={className}>
      <path
        fill="#231F20"
        d="M243,196.6c2.4-0.3,3.7-1.6,3.7-3.9c0-2.7-1.7-4.1-5.1-4.1h-5.5v14.4h2.1v-6.3h2.5l0,0.1l4,6.2h2.2
        l-4.2-6.4L243,196.6z M240.6,195.2h-2.4v-4.9h3c1.6,0,3.4,0.3,3.4,2.3C244.6,195,242.8,195.2,240.6,195.2z"
      />
      <path
        fill="#231F20"
        d="M240.8,182.3c-7.6,0-13.6,6-13.6,13.6c0,7.6,6,13.6,13.6,13.6c7.6,0,13.6-6,13.6-13.6
        C254.4,188.3,248.5,182.3,240.8,182.3z M240.8,207.7c-6.5,0-11.6-5.1-11.6-11.8c0-6.7,5.1-11.8,11.6-11.8
        c6.5,0,11.6,5.1,11.6,11.8C252.5,202.6,247.4,207.7,240.8,207.7z"
      />
      <path
        fill="#0077B5"
        d="M203.2,30h-176C18.8,30,12,36.7,12,44.9v176.7c0,8.2,6.8,14.9,15.2,14.9h176c8.4,0,15.3-6.7,15.3-14.9
        V44.9C218.5,36.7,211.6,30,203.2,30z"
      />
      <path
        fill="#FFFFFF"
        d="M42.6,107.4h30.7V206H42.6V107.4z M57.9,58.4c9.8,0,17.8,8,17.8,17.8c0,9.8-8,17.8-17.8,17.8
        c-9.8,0-17.8-8-17.8-17.8C40.2,66.4,48.1,58.4,57.9,58.4"
      />
      <path
        fill="#FFFFFF"
        d="M92.5,107.4h29.4v13.5h0.4c4.1-7.7,14.1-15.9,29-15.9c31,0,36.7,20.4,36.7,46.9v54h-30.6V158
        c0-11.4-0.2-26.1-15.9-26.1c-15.9,0-18.4,12.5-18.4,25.3V206H92.5V107.4z"
      />
    </svg>
  )
}
LinkedInIcon.propTypes = {
  className: PropTypes.string,
}
