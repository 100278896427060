import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import React from 'react'
import {Portal} from 'react-portal'

import Button from 'app/common/Button'
import Modal from 'app/common/Modal'

import {CONFIRMATION_STATUSES} from './my-entity-star-constants'

import * as styles from './EmailSettingsModal.less'

class RemovalConfirmationModal extends React.PureComponent {
  static propTypes = {
    entityName: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
    confirmationStatus: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
  }

  render() {
    const {confirmationStatus, close} = this.props

    return (
      <Portal>
        <Modal onClose={close}>
          {confirmationStatus === CONFIRMATION_STATUSES.CONFIRMED
            ? this.renderConfirmedContents()
            : this.renderConfirmationContents()}
        </Modal>
      </Portal>
    )
  }

  renderConfirmationContents() {
    const {entityName, entityType, confirmationStatus, close} = this.props
    const isConfirming = confirmationStatus === CONFIRMATION_STATUSES.CONFIRMING
    return (
      <React.Fragment>
        <h2 className={styles.confirmationMessage}>
          Are you sure you want to remove {entityName} from your &ldquo;My{' '}
          {entityType === 'company' ? 'Companies' : 'Industries'}
          &rdquo; list?
        </h2>

        <h3 className={styles.question}>
          By removing this {entityType}, you will no longer receive{' '}
          {process.env.PRODUCT_NAME}
          email alerts for {entityName}, and it will no longer show up on your
          Dashboard.
        </h3>

        <div className={styles.buttons}>
          <Button
            label={
              isConfirming
                ? 'Removing...'
                : `Remove This ${
                    entityType === 'company' ? 'Company' : 'Industry'
                  }`
            }
            onClick={bind(this.removeEntity, this)}
            isDestructive
            isDisabled={isConfirming}
            className={styles.button}
          />

          <Button
            label="Cancel"
            onClick={close}
            isPlainText
            className={styles.button}
          />
        </div>
      </React.Fragment>
    )
  }

  renderConfirmedContents() {
    const {entityType} = this.props
    return (
      <h2 className={styles.confirmedMessage}>
        This {entityType} has been removed successfully.
      </h2>
    )
  }

  removeEntity() {
    this.props.onSubmit()
  }
}

export default RemovalConfirmationModal
