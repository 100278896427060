import {union, without} from 'ramda'

import {FACTORS_BY_SUBFACTOR, VALENCES} from 'app/constants'
import {STORY_SORT_OPTIONS} from 'app/reusable/stories/story-reader/story-reader-constants'

export const getInitialSharedState = () => ({
  // A null value means nothing has been selected and we should use the
  // defaults. For factors/subfactors, the value 'all' means the
  // "All Factors/Subfactors" option has been explicitly selected.
  selectedFactor: null,
  selectedSubfactor: null,
  timeFrameDays: null,
  selectedGeography: null,

  shouldShowFiltersSaveModal: false,

  highlightedFactor: null,
  highlightedSubfactor: null,

  storyReader: {
    storyIds: null,
    filters: {
      valences: [VALENCES.POSITIVE, VALENCES.NEUTRAL, VALENCES.NEGATIVE],
      sortOrder: STORY_SORT_OPTIONS.VOLUME,
    },
  },

  healthBadgeTooltip: {
    isVisible: false,
    left: null,
    right: null,
    top: null,
    factor: null,
  },
})

export const getSelectedFactor = action => {
  const selectedSubfactor = getSelectedSubfactor(action)
  return action.payload.query && action.payload.query.factor
    ? action.payload.query.factor
    : selectedSubfactor
    ? FACTORS_BY_SUBFACTOR[selectedSubfactor]
    : undefined
}

export const getSelectedSubfactor = action =>
  action.payload.query ? action.payload.query.subfactor : undefined

export const getSelectedTimeFrame = action => {
  const timeFrameString =
    action.payload.query && action.payload.query['time-frame']
  if (!timeFrameString) {
    return null
  }
  return parseInt(timeFrameString, 10)
}

export const getSelectedGeography = action => {
  const geographyString = action.payload.query && action.payload.query.geography
  if (!geographyString) {
    return null
  }
  return parseInt(geographyString, 10)
}

export const highlightFactorReducer = (state, action) => ({
  ...state,
  highlightedFactor: action.payload,
})
export const clearHighlightedFactorReducer = (state, action) => ({
  ...state,
  highlightedFactor: getInitialSharedState().highlightedFactor,
})

export const highlightSubfactorReducer = (state, action) => ({
  ...state,
  highlightedSubfactor: action.payload,
})
export const clearHighlightedSubfactorReducer = (state, action) => ({
  ...state,
  highlightedSubfactor: getInitialSharedState().highlightedSubfactor,
})

export const showHealthBadgeTooltipReducer = (state, action) => ({
  ...state,
  healthBadgeTooltip: {
    ...state.healthBadgeTooltip,
    isVisible: true,
    left: action.payload.left,
    right: action.payload.right,
    top: action.payload.top,
    factor: action.payload.factor,
    subfactor: action.payload.subfactor,
  },
})

export const hideHealthBadgeTooltipReducer = (state, action) => ({
  ...state,
  healthBadgeTooltip: getInitialSharedState().healthBadgeTooltip,
})

export const setStoryIdsReducer = (state, action) => ({
  ...state,
  storyReader: {
    ...state.storyReader,
    storyIds: action.payload.ids,
  },
})

export const selectValenceReducer = (state, action) => ({
  ...state,
  storyReader: {
    ...state.storyReader,
    filters: {
      ...state.storyReader.filters,
      valences: union(state.storyReader.filters.valences, [action.payload]),
    },
  },
})

export const deselectValenceReducer = (state, action) => ({
  ...state,
  storyReader: {
    ...state.storyReader,
    filters: {
      ...state.storyReader.filters,
      valences: without([action.payload], state.storyReader.filters.valences),
    },
  },
})

export const setStorySortOrderReducer = (state, action) => ({
  ...state,
  storyReader: {
    ...state.storyReader,
    filters: {
      ...state.storyReader.filters,
      sortOrder: action.payload,
    },
  },
})
