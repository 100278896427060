import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './SummaryTable.less'

export default function HealthSummaryTable({items, className}) {
  const sections = items.map(item => (
    <Section head={item.head} body={item.body} key={item.key} />
  ))
  return (
    <div className={classNames(styles.summaryTable, className)}>{sections}</div>
  )
}
HealthSummaryTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
}

function Section({head, body}) {
  return (
    <div className={styles.section}>
      <div className={styles.head}>{head}</div>
      <div className={styles.body}>{body}</div>
    </div>
  )
}
Section.propTypes = {
  head: PropTypes.any.isRequired,
  body: PropTypes.any.isRequired,
}
