import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { CHART_COLORS } from 'app/constants'
import * as styles from './style.less'
import { createPortal } from 'react-dom'
import { CustomTooltip } from './CustomTooltip'
import { tooltipCollector } from './tooltipCollector'
import { CustomTick, CustomTickWithCollector } from './ChartTicks'
import GraphLoading from '../GraphLoading'
import * as graphStyles from './LitigationStoryChart.less'

const colors = CHART_COLORS

/**
 * Display companies on a line chart.
 *
 * @param companies List of company objects.
 * @param companyChartData List of data for the chart.
 * @param isLoading Should the chart show the loading
 * @param widthOverride Overrides the ResponsiveContainer's width.
 * @param heightOverride Overrides the ResponsiveContainer's height.
 */
export default function LitigationStoryChart({
  companies,
  companyChartData,
  isLoading,
  widthOverride,
  heightOverride
}) {
  const tooltipRootRef = useRef(null)
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipEvent, setTooltipEvent] = useState(false)
  const collector = tooltipCollector()
  let tooltipTimeout = null

  const onActiveDotHoverHandle = (active, event, actionCreator) => {
    if (active) {
      if (actionCreator === 'tooltip') {
        if (tooltipTimeout) clearTimeout(tooltipTimeout)
        setShowTooltip(true)
      } else if (actionCreator === 'cell') {
        if (tooltipTimeout) clearTimeout(tooltipTimeout)
        setShowTooltip(true)
        setTooltipEvent(event)
      }
    } else {
      if (actionCreator === 'cell') {
        if (tooltipTimeout) clearTimeout(tooltipTimeout)
        tooltipTimeout = setTimeout(() => {
          setShowTooltip(false)
        }, 300)
      }
      if (actionCreator === 'tooltip') {
        setShowTooltip(false)
      }
    }
  }

  const PortalTooltip = (props) => {
    const { tooltipEvent, ...restProps } = props
    if (tooltipEvent === null) return null
    if (tooltipRootRef === null) return null
    if (!showTooltip) return null

    return createPortal(
      <CustomTooltip
        tooltipCollector={collector}
        companies={companies}
        tooltipEvent={tooltipEvent}
        onActiveDotHoverHandle={onActiveDotHoverHandle}
        {...restProps}
      />,
      tooltipRootRef.current,
    )
  }

  const renderChart = () => {
    if (companies.length > 0) {
      return (
        <div
          id='line-chart'
          className={styles.chartContainer}
          onMouseLeave={() => {
            onActiveDotHoverHandle({}, false, null, 'tooltip')
          }}
          onMouseEnter={() => {
            onActiveDotHoverHandle({}, false, null, 'tooltip')
          }}
          onClick={() => {
            onActiveDotHoverHandle({}, false, null, 'tooltip')
          }}
        >
          <div id='tooltipContainer' ref={tooltipRootRef}/>
          <ResponsiveContainer
            width={widthOverride ? widthOverride : '90%'}
            height={heightOverride ? heightOverride : 700}
          >
            <LineChart
              className={graphStyles.storyChart}
              width={1000}
              height={500}
              data={companyChartData}
              margin={{ top: 35, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid
                strokeDasharray='3 3'
                vertical={false}
              />
              <XAxis
                dataKey='storyWeekDate'
                tickLine={false}
                minTickGap={30}
                tick={<CustomTick/>}
              />
              <YAxis
                tick={<CustomTickWithCollector tooltipCollector={collector}/>}
              />
              <Tooltip
                animationDuration={0}
                content={<PortalTooltip tooltipEvent={tooltipEvent}/>}
              />
              <Legend
                verticalAlign={'top'}
                align={'left'}
                height={36}
                formatter={(value) => (companies.find(company => company.id === value).companyName)}
                wrapperStyle={{
                  marginTop: '-30px',
                  paddingLeft: '50px',
                  height: 'auto',
                }}
              />
              {companies && companies.map((company, index) => (
                <Line
                  key={company.id}
                  type='linear'
                  legendType={'circle'}
                  label={company.companyName}
                  dataKey={company.id}
                  stroke={colors[index % colors.length]}
                  strokeWidth='2'
                  dot={false}
                  activeDot={{
                    r: 6,
                    onMouseEnter: (item, e) => {
                      e.persist()
                      onActiveDotHoverHandle(true, { pageX: e.pageX + 8, pageY: e.pageY }, 'cell')
                    }
                  }}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      )
    } else {
      return (<div className={styles.noMatch}>There are no companies that match your search criteria.</div>)
    }
  }

  return (
    <>
      <GraphLoading isLoading={isLoading}>
        {renderChart()}
      </GraphLoading>
    </>
  )
}

LitigationStoryChart.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
}

LitigationStoryChart.defaultProps = {
  isLoading: false,
}
