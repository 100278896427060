import React from 'react'

export default function DataIcon({ className }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
         className={className}>
      <path d="M3.75 0.75V23.25H20.2508V6.75L14.2505 0.75H3.75Z" stroke="currentColor" strokeWidth="1.5"
            strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.0635 0.75V6.75H19.5637" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"
            strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.0628 10.5L16.4692 13.5L14.0628 16.5M9.93762 10.5L7.53125 13.5L9.93762 16.5" stroke="currentColor"
            strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
