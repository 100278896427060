import {append, without} from 'ramda'
import {handleActions} from 'redux-actions'
import {LOCATION_CHANGED} from 'redux-little-router'

import {SORT_DIRECTIONS} from 'app/constants'

import * as actions from './dashboard-actions'

const getInitialState = () => ({
  topNegativeStoryIds: null,
  topPositiveStoryIds: null,
  healthChangeTable: {
    sort: {
      column: 'healthChanges',
      direction: SORT_DIRECTIONS.DESC,
    },
    expandedHealthChangeCompanyIds: [],
  },
})

export default handleActions(
  {
    [actions.setTopNegativeStoryIds]: (state, action) => ({
      ...state,
      topNegativeStoryIds: action.payload,
    }),
    [actions.setTopPositiveStoryIds]: (state, action) => ({
      ...state,
      topPositiveStoryIds: action.payload,
    }),
    [actions.setHealthChangeTableSort]: (state, action) => ({
      ...state,
      healthChangeTable: {
        ...state.healthChangeTable,
        sort: action.payload,
      },
    }),
    [actions.expandHealthChangesForCompany]: (state, action) => ({
      ...state,
      healthChangeTable: {
        ...state.healthChangeTable,
        expandedHealthChangeCompanyIds: append(
          action.payload,
          state.healthChangeTable.expandedHealthChangeCompanyIds,
        ),
      },
    }),
    [actions.collapseHealthChangesForCompany]: (state, action) => ({
      ...state,
      healthChangeTable: {
        ...state.healthChangeTable,
        expandedHealthChangeCompanyIds: without(
          [action.payload],
          state.healthChangeTable.expandedHealthChangeCompanyIds,
        ),
      },
    }),
    [LOCATION_CHANGED]: (state, action) => ({
      ...state,
      healthChangeTable: {
        ...state.healthChangeTable,
        expandedHealthChangeCompanyIds: getInitialState().healthChangeTable
          .expandedHealthChangeCompanyIds,
      },
    }),
  },
  getInitialState(),
)
