import Promise from 'bluebird'
import is from 'is'
import snakeCase from 'snake-case'
import superagent from 'superagent'

import {API_URL, GRAPHQL_URL} from 'app/constants'
import * as jwt from 'app/jwt'
import changeCaseObject from 'app/utils/change-case-object'

export function apiCall(
  endpoint,
  {method = 'GET', token, query, data, headers = {}} = {},
) {
  const url = `${API_URL}/${endpoint}`
  if (token) {
    headers = {...headers, Authorization: `Bearer ${token}`}
  }
  return request({url, method, headers, query, data})
}

/**
 *
 * @param ResourceClass
 * @param method
 * @param token
 * @param query
 * @param data
 * @param headers
 * @param limit
 * @param offset
 * @param orderBy Accepts an object or an array of object. If the object has the skipSnakeCase field set to true,
 * then no conversion happens on the field. Useful for creating sorting on JSON objects. e.g. settings->sector->>name.
 * @param fields
 * @returns {*}
 */
export function restApiCall(
  ResourceClass,
  {
    method = 'GET',
    token,
    query,
    data,
    headers = {},
    limit,
    offset,
    orderBy,
    fields,
  } = {},
) {
  if (limit) {
    offset = offset || 0
    headers = {
      ...headers,
      'Range-Unit': 'items',
      Range: `${offset}-${offset + limit - 1}`,
    }
  }
  if (orderBy) {
    if (!is.array(orderBy)) {
      orderBy = [orderBy]
    }
    const orderString = orderBy
      .map(item => {
        if (is.string(item)) {
          return snakeCase(item)
        }
        let fieldString = item.skipSnakeCase ? item.field : snakeCase(item.field)
        if (item.direction) {
          fieldString += `.${item.direction}`
        }
        if (item.nulls) {
          if (item.nulls === 'first') {
            fieldString += '.nullsfirst'
          } else if (item.nulls === 'last') {
            fieldString += '.nullslast'
          }
        }
        return fieldString
      })
      .join(',')
    query = {...(query || {}), order: orderString}
  }
  if (fields) {
    query = {...(query || {}), select: fields.map(snakeCase).join(',')}
  }

  return apiCall(ResourceClass.endpoint, {
    method,
    headers,
    query,
    data,
    token,
  }).then(response => {
    const {body} = response
    const resource = new ResourceClass()
    const {entities, result} = resource.normalizedData(body)
    return {
      ...response,
      body,
      entities,
      result,
    }
  })
}

export function graphqlQuery(
  query,
  {token, headers, operationName, variables} = {},
) {
  if (token) {
    headers = {...(headers || {}), Authorization: `Bearer ${token}`}
  }
  return request({
    url: GRAPHQL_URL,
    method: 'POST',
    headers: headers,
    data: {query, operationName, variables},
    shouldChangeCase: false,
  })
}

export function pyappRequest({
  url,
  method,
  token,
  headers,
  query,
  data,
  authType = 'session',
}) {
  if (query) {
    query = changeCaseObject.snakeCase(query)
  }
  if (data) {
    data = changeCaseObject.snakeCase(data)
  }
  let authorization
  if (authType === 'session') {
    const {sessionId} = jwt.decode(token)
    authorization = sessionId ? `SessionID ${sessionId}` : `JWT ${token}`
  } else if (authType === 'jwt') {
    authorization = `JWT ${token}`
  } else {
    throw new Error(`Invalid authentication type "${authType}".`)
  }
  return request({
    url,
    method,
    headers: {
      Authorization: authorization,
      ...headers,
    },
    query,
    data,
  })
}

export function request({
  url,
  method = 'GET',
  headers,
  query,
  data,
  shouldChangeCase = true,
}) {
  let req = superagent(method, url)
  if (headers) {
    Object.entries(headers).forEach(([header, value]) => {
      req.set(header, value)
    })
  }
  if (query) {
    if (shouldChangeCase) {
      req = req.query(changeCaseObject.snakeCase(query))
    } else {
      req = req.query(query)
    }
  }
  if (data) {
    if (shouldChangeCase) {
      req = req.send(changeCaseObject.snakeCase(data))
    } else {
      req = req.send(data)
    }
  }
  // Wrap the request in another Promise so that we can camelCase the response.
  return new Promise((resolve, reject, onCancel) => {
    req.end((error, response) => {
      if (error) {
        reject(error)
      } else {
        const body = shouldChangeCase
          ? changeCaseObject.camelCase(response.body)
          : response.body
        resolve({
          ...response,
          body,
        })
      }
    })
    onCancel(() => {
      req.abort()
    })
  })
}
