import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './styles.less'

/**
 * Standard, generic button component.
 *
 * @param {String} label - The text label
 * @param {Boolean} isDisabled - Whether the button is disabled
 * @param {Boolean} isPrimary - Applies the styling for a primary call to action button, such as "Save"
 * @param {Boolean} isDestructive - Applies the styling for a destructive button, such as "Delete"
 * @param {Boolean} isPlainText - Applies the styling for a plain text button, such as "Cancel"
 */
export default function Button({
  label = '',
  isDisabled = false,
  isPrimary = false,
  isDestructive = false,
  isPlainText = false,
  className,
  ...restProps
}) {
  return (
    <button
      className={classNames(styles.button, className, {
        [styles.primary]: isPrimary,
        [styles.destructive]: isDestructive,
        [styles.plainText]: isPlainText,
      })}
      disabled={isDisabled}
      {...restProps}
    >
      {label}
    </button>
  )
}
Button.propTypes = {
  label: PropTypes.any.isRequired,
  isDisabled: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isDestructive: PropTypes.bool,
  isPlainText: PropTypes.bool,
}
