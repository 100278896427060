import React from 'react'


export default function BinocularIcon({ className }) {
  return (
    <svg viewBox="0 0 24 25" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 16.25V6.6875C10.5 5.94158 10.2037 5.22621 9.67624 4.69876C9.14879 4.17132 8.43342 3.875
        7.6875 3.875C7.16927 3.8822 6.66253 4.02875 6.22045 4.29928C5.77838 4.5698 5.41726 4.95433 5.175
        5.4125L5.0625 5.6375L1.125 14.375M13.5 16.25V6.6875C13.5 5.94158 13.7963 5.22621 14.3238 4.69876C14.8512
        4.17132 15.5666 3.875 16.3125 3.875C16.8307 3.8822 17.3375 4.02875 17.7795 4.29928C18.2216 4.5698 18.5827
        4.95433 18.825 5.4125L18.9375 5.6375L22.875 14.375M10.5 11.675C10.9679 11.4738 11.4719 11.3701 11.9813
        11.3701C12.4906 11.3701 12.9946 11.4738 13.4625 11.675M10.5 7.175C10.9679 6.97381 11.4719 6.87005 11.9813
        6.87005C12.4906 6.87005 12.9946 6.97381 13.4625 7.175H10.5Z"
        stroke="#415464" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M5.625 21.125C8.31739 21.125 10.5 18.9424 10.5 16.25C10.5 13.5576 8.31739 11.375 5.625 11.375C2.93261
        11.375 0.75 13.5576 0.75 16.25C0.75 18.9424 2.93261 21.125 5.625 21.125Z"
        stroke="#415464" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M8.25 16.25C8.25 16.9462 7.97344 17.6139 7.48116 18.1062C6.98887 18.5984 6.32119 18.875 5.625 18.875M21
        16.25C21 16.9462 20.7234 17.6139 20.2312 18.1062C19.7389 18.5984 19.0712 18.875 18.375 18.875"
        stroke="#415464" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M18.375 21.125C21.0674 21.125 23.25 18.9424 23.25 16.25C23.25 13.5576 21.0674 11.375 18.375 11.375C15.6826
        11.375 13.5 13.5576 13.5 16.25C13.5 18.9424 15.6826 21.125 18.375 21.125Z"
        stroke="#415464" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
