import {map} from 'ramda'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import Industry from 'app/models/Industry'
import Story from 'app/models/Story'

import OverviewReport from '../../common/OverviewReport'
import * as actions from './industry-report-actions'
import * as selectors from './industry-report-selectors'

export default connect(
  createSelector(
    [getEntities, selectors.getState],
    (entities, state) => {
      const orm = Orm.withEntities(entities)
      const {
        isLoading,
        industryId,
        storyIdsByFactor,
        storyCount,
        timeFrameDays,
        isDownloadingPdf,
      } = state
      return {
        isLoading: isLoading,
        entity: industryId && orm.getById(Industry, industryId),
        entityTypeName: 'Industry',
        storiesByFactor:
          storyIdsByFactor &&
          map(storyIds => orm.getByIds(Story, storyIds), storyIdsByFactor),
        storyCount,
        timeFrameDays,
        isDownloadingPdf,
      }
    },
  ),
  {
    downloadPdf: actions.startDownloadPdf,
  },
)(OverviewReport)
