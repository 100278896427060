import React from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import {createSelector} from 'reselect'

import {SUPPORT_EMAIL} from 'app/constants'
import Orm from 'app/framework/Orm'
import {Company, Industry} from 'app/models'
import {getEntities} from 'app/framework/entities-selectors'
import {getLogin} from 'app/login/login-selectors'

// import Message from 'app/common/Message'
import ManzamaLogo from 'app/common/logos/manzama-logo'
import LoadingSpinner from 'app/common/loading-spinner'
import InlineSvg from 'app/common/InlineSvg'

import {getState} from './profile-builder-selectors'
import * as profileBuilderActions from './profile-builder-actions'
import Industries from './Industries'
import Companies from './Companies'
import AccountSettings from './AccountSettings'

import checkmarkIconUrl from 'static/images/checkmark.svg'
import nextArrowIconUrl from 'static/images/next-arrow.svg'
import './ProfileBuilder.less'

class ProfileBuilder extends React.PureComponent {
  componentDidMount() {
    if (this.props.currentUser.role) {
      this.props.setRole(this.props.currentUser.role)
    }
    if (this.props.currentUser.locationId) {
      this.props.setLocationId(this.props.currentUser.locationId)
    }
    if (this.props.currentUser.firmLocationId) {
      this.props.setFirmLocationId(this.props.currentUser.firmLocationId)
    }
    if (this.props.currentUser.timezone) {
      this.props.setTimezone(this.props.currentUser.timezone)
    }
  }

  render() {
    const {currentUser, currentStep} = this.props

    const steps = ['Industries', 'Companies', 'Account Settings']

    const stepsContent = steps.map((step, idx) => {
      const stepNumber = idx + 1
      return (
        <li
          key={idx}
          className={classNames({
            active: stepNumber === currentStep,
            accessible: stepNumber < currentStep && currentStep < 5,
          })}
          onClick={() => this.handleStepClick(stepNumber)}
        >
          <div className="step">
            <span
              className={classNames('stepnum', {
                'check-mark': stepNumber < currentStep,
              })}
            >
              {stepNumber < currentStep ? (
                <InlineSvg src={checkmarkIconUrl} />
              ) : (
                stepNumber
              )}
            </span>
            <span>{step}</span>
          </div>
        </li>
      )
    })

    const builderContent = this.getContent()

    const currentStepName = steps[currentStep - 1]

    const companyName =
      process.env.MANZAMA_BRAND === 'diligent'
        ? 'Diligent Corporation'
        : 'Manzama Inc.'

    const currentYear = new Date().getFullYear()

    const spinner = this.props.isLoading && (
      <div className="loading-overlay">
        <LoadingSpinner className="loading-spinner-container" />
      </div>
    )

    return (
      <div id="profile-builder-container">
        {spinner}

        {/*{message}*/}

        <div className="header">
          <ManzamaLogo
            color={ManzamaLogo.COLORS.LIGHT}
            className={classNames('logo', process.env.MANZAMA_BRAND)}
          />
          <a href="#" onClick={() => this.handleSkipClick()}>
            Skip Profile Builder
          </a>
        </div>

        <div className="wrapper">
          <div className="builder-menu">
            <div className="menu-header">
              <p className="group-name">{currentUser.firm.name}</p>
              <p className="member-name">{currentUser.displayName}</p>
            </div>

            <ul className="menu-content">{stepsContent}</ul>
          </div>

          <div className="builder-content">
            <div className="builder-content-header">
              <h4>Step {currentStep}</h4> <h5>{currentStepName}</h5>
            </div>

            {builderContent}

            <div className="builder-content-footer">
              <div>
                &copy; {currentYear} {companyName} |{' '}
                <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank">
                  Help
                </a>
              </div>
              <div
                className="next-button"
                onClick={() => this.handleNextClick()}
              >
                <div>
                  <span>
                    {currentStep === 3
                      ? `START USING ${process.env.BRAND_NAME}`
                      : 'NEXT'}
                  </span>
                </div>

                <div className="next-button-button">
                  <InlineSvg src={nextArrowIconUrl} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getContent() {
    const {currentStep} = this.props
    switch (currentStep) {
      case 1:
        return <Industries />
      case 2:
        return <Companies />
      case 3:
        return <AccountSettings />
    }
  }

  handleSkipClick() {
    this.props.skip(this.props.token)
  }

  handleNextClick() {
    this.props.setValidationError({})
    if (this.props.currentStep === 3) {
      /**
       * timezone and firm location force a selection so don't need validation.
       */
      if (!this.props.password || !this.props.confirmPassword) {
        this.props.setValidationError({
          field: 'password',
          message: 'Passwords are required.',
        })
        return
      }
      if (this.props.password !== this.props.confirmPassword) {
        this.props.setValidationError({
          field: 'password',
          message: 'Passwords must match.',
        })
        return
      }
      if (
        this.props.password.length < 5 ||
        this.props.confirmPassword.length < 5
      ) {
        this.props.setValidationError({
          field: 'password',
          message: 'Passwords must be at least 5 characters long.',
        })
        return
      }
      if (!this.props.role) {
        this.props.setValidationError({
          field: 'role',
          message: 'Role is required.',
        })
        return
      }
      if (!this.props.locationId) {
        this.props.setValidationError({
          field: 'location',
          message: 'Country is required.',
        })
        return
      }
      const searches = this.props.selectedIndustryIds
        .filter(id => !!id)
        .map(id => ({
          value: this.props.orm.getById(Industry, id).savedSearchId,
          category: 'industry',
          isFreeText: typeof id === 'string',
        }))
        .concat(
          this.props.selectedCompanyIds
            .filter(id => !!id)
            .map(id => ({
              value: this.props.orm.getById(Company, id).savedSearchId,
              category: 'client',
              isFreeText: typeof id === 'string',
            })),
          this.props.selectedSuggestedCompanyIds.map(id => ({
            value: this.props.orm.getById(Company, id).savedSearchId,
            category: 'client',
          })),
        )
      this.props.saveUser({
        token: this.props.token,
        data: {
          isProfileBuilder: true,
          password: this.props.password,
          role: this.props.role,
          timezone: this.props.timezone,
          firmLocationId: this.props.firmLocationId,
          locationId: this.props.locationId,
          searches,
        },
      })
      return
    }
    this.props.setCurrentStep(this.props.currentStep + 1)
  }

  handleStepClick(step) {
    if (step < this.props.currentStep || this.props.currentStep === 5) {
      this.props.setCurrentStep(step)
    }
  }
}

export default connect(
  createSelector(
    [getEntities, getState, getLogin],
    (entities, profileBuilder, login) => {
      const orm = Orm.withEntities(entities)

      return {
        currentUser: login.currentUser,
        token: login.token,
        appName: '',
        currentStep: profileBuilder.currentStep,
        isLoading: profileBuilder.isLoading,
        role: profileBuilder.role,
        locationId: profileBuilder.locationId,
        firmLocationId: profileBuilder.firmLocationId,
        timezone: profileBuilder.timezone,
        password: profileBuilder.password,
        confirmPassword: profileBuilder.confirmPassword,
        selectedIndustryIds: profileBuilder.selectedIndustryIds,
        selectedCompanyIds: profileBuilder.selectedCompanyIds,
        selectedSuggestedCompanyIds: profileBuilder.selectedSuggestedCompanyIds,

        orm,
      }
    },
  ),
  {
    setFirmLocationId: profileBuilderActions.setFirmLocationId,
    setTimezone: profileBuilderActions.setTimezone,
    setRole: profileBuilderActions.setRole,
    setLocationId: profileBuilderActions.setLocationId,
    setValidationError: profileBuilderActions.setValidationError,
    setCurrentStep: profileBuilderActions.setCurrentStep,
    saveUser: profileBuilderActions.saveUser,
    skip: profileBuilderActions.skip,
  },
)(ProfileBuilder)
