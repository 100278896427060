import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Button from 'app/common/Button'
import EmailIcon from 'app/social/icons/EmailIcon'

import * as styles from './EmailShareButton.less'

export default function EmailShareButton({
  url,
  subject,
  className,
  isDisabled = false,
}) {
  const encodedUrl = encodeURIComponent(url)
  const encodedSubject = encodeURIComponent(subject)
  const shareUrl = `mailto:?subject=${encodedSubject}&body=${encodedUrl}`
  return (
    <a
      href={shareUrl}
      target="_blank"
      className={classNames(styles.emailShareButton, className)}
    >
      <Button
        label={<EmailShareButtonLabel />}
        isDisabled={isDisabled}
        className={classNames(styles.button, {[styles.disabled]: isDisabled})}
      />
    </a>
  )
}
EmailShareButton.propTypes = {
  url: PropTypes.string.isRequired,
  subject: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
}

const EmailShareButtonLabel = () => (
  <span className={styles.contents}>
    <EmailIcon className={styles.icon} />
    Email
  </span>
)
