import React from 'react'

import Button from 'app/common/Button'
import Link from 'app/common/NavigationLink'
import {FEEDBACK_EMAIL} from 'app/constants'
import urls from 'app/urls'

import * as styles from './PageNotFound.less'
import mainImageUrl from 'static/images/404.svg'
import usaFlagUrl from 'static/images/flags/usa.png'
import canadaFlagUrl from 'static/images/flags/canada.png'
import ukFlagUrl from 'static/images/flags/uk.png'
import euFlagUrl from 'static/images/flags/eu.png'

const PageNotFound = () => (
  <div id="page-not-found" className={styles.page}>
    <div className={styles.columns}>
      <div className={styles.imageColumn}>
        <img className={styles.image} src={mainImageUrl} />
      </div>

      <div className={styles.textColumn}>
        <div className={styles.text}>
          <h1 className={styles.header}>Apologies.</h1>
          <p className={styles.description}>We couldn't find that page.</p>
        </div>

        <div className={styles.buttons}>
          <Link href={urls.companies()}>
            <Button
              label="Take Me to My Companies"
              isPrimary={true}
              className={styles.myCompanies}
            />
          </Link>
          <a href={`mailto:${FEEDBACK_EMAIL}`}>
            <Button
              label="Report an Issue"
              isPlainText={true}
              className={styles.myCompanies}
            />
          </a>
        </div>

        <div className={styles.flags}>
          <div className={styles.country}>
            <img src={usaFlagUrl} className={styles.flag} />
            <img src={canadaFlagUrl} className={styles.flag} />
            <span className={styles.phone}>+1 (541) 701-2267</span>
          </div>

          <div className={styles.country}>
            <img src={ukFlagUrl} className={styles.flag} />
            <img src={euFlagUrl} className={styles.flag} />
            <span className={styles.phone}>+44 (0) 203-282-1633</span>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default PageNotFound
