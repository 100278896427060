import classNames from 'classnames'
import invariant from 'invariant'
import is from 'is'
import PropTypes from 'prop-types'
import React, {PureComponent} from 'react'
import {findDOMNode} from 'react-dom'

import Option from './Option'

import './styles.less'

/*
 * Generic styled dropdown component
 */
export default class Dropdown extends PureComponent {
  static propTypes = {
    // Array of Option objects. Each object has the following shape:
    //   {
    //     label: String,
    //     value: any,
    //     isInvisible: Boolean? = false,
    //     isGroup: Boolean? = false,
    //     isSelectable: Boolean? = true,
    //   }
    //
    // `isSelectable` defaults to `false` if `isGroup` is `true`. Options may
    // also hold any other arbitrary attributes, which will be preserved.
    options: PropTypes.arrayOf(PropTypes.object),

    // An optional function that takes in the currently selected Option and
    // returns a rendering of it. Overrides the default renderer.
    selectedOptionRenderer: PropTypes.func,

    // An optional function that takes in an Option and returns a
    // rendering of it. Overrides the default renderer.
    optionRenderer: PropTypes.func,

    // Called when an option is selected with that Option as an argument
    onSelect: PropTypes.func,

    // Same as above, but is passed the option value
    onChange: PropTypes.func,

    // The starting selected value
    defaultValue: PropTypes.any,

    // The currently selected option value
    value: PropTypes.any,

    isDisabled: PropTypes.bool,
    className: PropTypes.string,
    optionsClassName: PropTypes.string,
  }

  static defaultProps = {
    isDisabled: false,
  }

  get options() {
    if (this.props.options) {
      return this.props.options
    }
    return React.Children.toArray(this.props.children).map(component => {
      if (component.children) {
        return getOptionsFromComponents(component.children)
      }
      return optionFromComponent(component)
    })
  }

  get value() {
    if (is.defined(this.props.value)) {
      return this.props.value
    }
    return this.state.selectedOption.value
  }

  constructor(props) {
    super(props)

    invariant(
      (props.options && props.options.length) || props.children,
      'A Dropdown must have at least one option.',
    )

    let selectedOption
    if (props.value || props.defaultValue) {
      const value = props.value || props.defaultValue
      selectedOption = this.optionFromValue(value)
    }
    if (!selectedOption) {
      selectedOption = props.options
        ? props.options[0].value
        : optionFromComponent(React.Children.toArray(props.children)[0]).value
    }

    this.state = {
      showMenu: false,
      selectedOption,
    }
  }

  // React methods

  render() {
    return (
      <div
        className={classNames('dropdown-component', this.props.className, {
          open: this.state.showMenu,
          disabled: this.props.isDisabled,
        })}
        onClick={this.handleClick}
      >
        <div className="select-button">
          <div className="selected">
            {this.renderSelectedOption(this.optionFromValue(this.value))}
          </div>
          <div className="arrow" />
        </div>
        {this.renderMenu()}
      </div>
    )
  }

  componentWillReceiveProps(props) {
    if (this.state.showMenu && props.isDisabled) {
      this.hideMenu()
    }
  }

  componentWillUnmount() {
    // Remove the event listener if we've added it so that the component can be
    // correctly garbage collected.
    document.removeEventListener('click', this.handleGlobalClick)
  }

  // Render helpers

  renderMenu() {
    if (!this.state.showMenu) return null

    const options = this.options.map((option, idx) =>
      this.renderOption(option, idx),
    )
    return (
      <div className={classNames('options', this.props.optionsClassName)}>
        {options}
      </div>
    )
  }

  renderSelectedOption(option) {
    if (this.props.selectedOptionRenderer) {
      return this.props.selectedOptionRenderer(option)
    }
    return option.label
  }

  renderOption = (option, idx, {isGrouped = false} = {}) => {
    if (option.isInvisible) return null

    const active = option.value === this.value
    const {isGroup} = option
    const isSelectable = is.defined(option.isSelectable)
      ? option.isSelectable
      : !option.isGroup
    const selectOption = () => this.selectOption(option)

    const renderedOption = (
      <div
        className={classNames('option', {
          active,
          group: isGroup,
          grouped: isGrouped,
          selectable: isSelectable,
        })}
        onClick={selectOption}
        key={idx}
      >
        {this.renderOptionContents(option)}
      </div>
    )

    if (isGroup) {
      return (
        <React.Fragment key={`${idx}-group`}>
          {renderedOption}
          <div className="group-holder">
            {option.options.map((option, optionIdx) =>
              this.renderOption(option, `group-${idx}-${optionIdx}`, {
                isGrouped: true,
              }),
            )}
          </div>
        </React.Fragment>
      )
    }

    return renderedOption
  }

  renderOptionContents(option) {
    if (this.props.optionRenderer) {
      return this.props.optionRenderer(option)
    }
    if (option.label) {
      return <div className="title">{option.label}</div>
    } else {
      return <div className="title">&nbsp;</div>
    }
  }

  // State management

  showMenu() {
    this.setState({showMenu: true})
    document.addEventListener('click', this.handleGlobalClick)
  }

  hideMenu() {
    this.setState({showMenu: false})
    document.removeEventListener('click', this.handleGlobalClick)
  }

  selectOption(option) {
    // If it's an unselectable option, don't do anything. Groups default to
    // being unselectable.
    if (
      (is.defined(option.isSelectable) && !option.isSelectable) ||
      (option.isGroup && !option.isSelectable)
    )
      return

    this.setState({
      showMenu: false,
    })

    if (is.undefined(this.props.value)) {
      this.setState({selectedOption: option})
    }

    if (this.props.onSelect) {
      this.props.onSelect(option)
    }
    if (this.props.onChange) {
      this.props.onChange(option.value)
    }
  }

  optionFromValue(value) {
    const findOption = options => {
      for (const option of options) {
        if (option.isGroup) {
          const subOption = findOption(option.options)
          if (subOption) return subOption
        }
        if (option.value === value) {
          return option
        }
      }
      return undefined
    }
    return findOption(this.options) || this.options[0]
  }

  // Event handlers

  handleClick = event => {
    if (this.props.isDisabled) return

    const {target} = event
    const rootNode = findDOMNode(this)
    const optionsNode = rootNode.getElementsByClassName('options')[0]
    // Only close the menu if we clicked the select box, not an option
    // (selecting an option closes the menu by itself)
    if (this.state.showMenu && !optionsNode.contains(target)) {
      this.hideMenu()
    } else if (!this.state.showMenu) {
      this.showMenu()
    }
  }

  handleGlobalClick = e => {
    // Hide the renderMenu if the user clicks outside of it
    let rootNode
    try {
      rootNode = findDOMNode(this)
    } catch (e) {
      // findDOMNode will error if the component has been unmounted, so
      // we just return early if that's the case
      return
    }
    if (!rootNode.contains(e.target)) {
      this.hideMenu()
    }
  }
}

const getOptionsFromComponents = components =>
  React.Children.toArray(components).map(component => {
    if (component.children) {
      return getOptionsFromComponents(component.children)
    }
    return optionFromComponent(component)
  })

const optionFromComponent = component => ({
  label: component.props.label,
  value: component.props.value,
  isInvisible: component.props.isInvisible,
})

Dropdown.Option = Option
export {Option}
