import {gql} from 'app/graphql'
import {graphqlRequest} from 'app/utils/sagas'


export function* fetchLeadershipIndividuals({companyId}) {
  return yield* graphqlRequest(
    gql`
      query($companyId: Int) {
        leadership: leadershipFunc(targetCompanyId: $companyId) {
          companyId
          leadershipData {
            individualName
            roleName
            roleDescription
          }
        }
      }
    `,
    {variables: {companyId}},
  )
}
