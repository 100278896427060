import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as styles from './MultiSelectDropdown.less'
import DropDownChip from './DropDownChip'
import FancyCheckbox from 'app/litigation/Checkbox'

/**
 * Display a multiselect dropdown.
 * @param options The list of options to display in the dropdown.
 * @param selected The current list of selected items.
 * @param toggleOption Called when the selection of an item changes.
 * @param allSelectedLabel Label to display when everything is selected.
 * @param useChips Display each selected option as chips or just display {selectedLabel} ({numberOfSelectedItems}).
 * @param selectedLabel If useChips is False, display this string with the number of selected items.
 * @param className Optional style to apply to the dropdown.
 * @constructor
 */
export default function MultiSelectDropdown({
  options,
  selected,
  toggleOption,
  allSelectedLabel,
  useChips,
  selectedLabel,
  className,
  selectThreshold,
  enableSearch,
  chipScroll
}) {
  const [isOpen, setIsOpen] = useState(false)
  const rootNode = React.useRef(null)
  const [searchInput, setSearchInput] = useState("");
  const [error, setError] = useState({isError: false, msg : ''})

  useEffect(() => {
    return function cleanUp() {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleClickOutside = (event) => {
    if (rootNode.current && !rootNode.current.contains(event.target)) {
      hideMenu()
    }
  }

  const handleClick = () => {
    isOpen ? hideMenu() : showMenu()
  }

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  
  const showMenu = () => {
    setIsOpen(true)
    document.addEventListener('click', handleClickOutside)
  }

  const hideMenu = () => {
    setIsOpen(false)
    document.removeEventListener('click', handleClickOutside)
  }

  const handleToggle = (option) => {
      if (selected.find(o => o.id === option.id)){
        toggleOption(option)
      }
      else{
        if (selected.length < selectThreshold || selectThreshold === "any") {
          setError({isError: false, msg: ""});
          toggleOption(option);
        } else {
          setError({
            isError: true,
            msg: `Maximum ${selectThreshold} options only can be selected`
          });
          setTimeout(() => {
            setError({isError: false, msg: ""});
          }, 3500);
        }
      }
  }

  const renderOption = (option) => {
    const isSelected = selected.find(o => o.id === option.id)
    if ('children' in option) {
      const children = option.children.map(child => renderOption(child))

      return (
        <React.Fragment key={option.id}>
          <li className={styles.multiSelectDropdownOption}>
            <FancyCheckbox
              key={option.id}
              label={option.label}
              onChange={() => handleToggle(option)}
              checked={!!isSelected}
            />
          </li>
          <div className={styles.childOptionContainer}>
            {children}
          </div>
        </React.Fragment>
      )
    }
    return (
      <li key={option.id} className={styles.multiSelectDropdownOption}>
        <FancyCheckbox
          key={option.id}
          label={option.label}
          onChange={() => handleToggle(option)}
          checked={!!isSelected}
        />
      </li>
    )
  }

  const renderOptions = () => {
    const filteredOptions = options.filter(
      (option) =>
        option.label.toLowerCase().includes(searchInput.toLowerCase()) ||
        (option.children &&
          option.children.some((child) =>
            child.label.toLowerCase().includes(searchInput.toLowerCase())
          ))
    )
    return (
      <ul
        className={classNames(styles.multiSelectDropdownOptions, styles.noSelect,
          isOpen ? styles.multiSelectDropdownOptionsOpen : null)}
      >
        {filteredOptions.map(option => renderOption(option))}
        {filteredOptions.length === 0 ? 
          <li className={styles.multiSelectDropdownOption}>
            {'No option found'}
          </li>
        : null}
      </ul>
    )
  }

  function getDisplay() {
    if (useChips) {
      return (
        <div className={classNames(styles.chipContainer, chipScroll ? styles.chipScroll : null)}>
          {selected.map(option => {
            return <DropDownChip label={option.label} key={option.id} onClose={() => handleToggle(option)}/>
          })}
        </div>
      )
    } else {
      return (<div>{selectedLabel} ({selected.length})</div>)
    }
  }

  return (
    <>
    {error.isError && <p className={styles.errorText}>{error.msg}</p>}
    <div
      className={classNames(styles.multiSelectDropdown, styles.noSelect, className, isOpen ? styles.open : null)}
      ref={rootNode}
    >
      <div onClick={handleClick} className={styles.multiSelectDropdownSelected}>
        {selected.length > 0 && getDisplay()}
        {selected.length === 0 && <div>{allSelectedLabel}</div>}
        <div>
          <div className={classNames(styles.arrow)}/>
        </div>
      </div>
      {(isOpen && enableSearch) && (
        <div>
          <input
            type="text"
            placeholder="Search"
            className={classNames(styles.multiSelectDropdownSearchInputValue)}
            value={searchInput}
            onChange={handleSearchInput}
          />
        </div>
      )}
      {renderOptions()}
    </div>
    </>
  )
}

MultiSelectDropdown.defaultProps = {
  allSelectedLabel: 'All',
  useChips: true,
  selectThreshold: 'any',
  enableSearch: false,
  chipScroll: false,
}

MultiSelectDropdown.propTypes = {
  allSelectedLabel: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string.isRequired,
    }))
  })).isRequired,
  selected: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  useChips: PropTypes.bool,
  selectedLabel: PropTypes.string,
  className: PropTypes.string,
  toggleOption: PropTypes.func.isRequired,
  selectThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  enableSearch: PropTypes.bool,
  chipScroll: PropTypes.bool
}
