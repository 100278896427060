import {VALENCES} from 'app/constants'

export const hasHealthData = obj => !!obj.healthData

export const hasHistoricalHealthData = obj =>
  obj.dailyHealthData.some(point => point.healthScore !== null)

export function calculateHealthRange(entity) {
  let {max, min} = entity.dailyHealthData.reduce(
    (range, {healthScore}) => {
      if (range.max === null) {
        range.max = healthScore
      } else {
        range.max = Math.max(range.max, healthScore)
      }

      if (range.min === null) {
        range.min = healthScore
      } else {
        range.min = Math.min(range.min, healthScore)
      }

      return range
    },
    {
      max: null,
      min: null,
    },
  )

  const {healthScore} = entity.healthData
  // Make sure that the range includes the health score.
  max = Math.max(max, healthScore)
  min = Math.min(min, healthScore)

  return {max, min}
}

/**
 * Returns 1, -1, or 0, depending on the valence.
 */
export function signForValence(valence) {
  if (valence === VALENCES.POSITIVE) {
    return 1
  }
  if (valence === VALENCES.NEGATIVE) {
    return -1
  }
  return 0
}
