import {handleActions} from 'redux-actions'

import Orm from 'app/framework/Orm'
import * as models from 'app/models'
import {deepCopy} from 'app/utils'

import * as actions from './entities-actions'

const getInitialState = () => ({indexes: {}})

const getModelByEntityKey = entityKey =>
  Object.values(models).find(m => m.entityKey === entityKey)

const updaterFunction = ormFunc => (state, action) => {
  state = {...state, indexes: deepCopy(state.indexes)}
  const orm = Orm.withEntities(state)
  Object.entries(action.payload).forEach(([entityKey, arg]) => {
    state[entityKey] = deepCopy(state[entityKey])
    ormFunc(orm, getModelByEntityKey(entityKey), arg)
  })
  return state
}

export default handleActions(
  {
    [actions.update]: updaterFunction((orm, Model, changesById) => {
      orm.updateByIds(Model, changesById)
    }),
    [actions.replace]: updaterFunction((orm, Model, changesById) => {
      orm.replaceByIds(Model, changesById)
    }),
    [actions.remove]: updaterFunction((orm, Model, ids) => {
      orm.deleteByIds(Model, ids)
    }),
  },
  getInitialState(),
)
