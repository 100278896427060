import React from 'react'
import EntityCard from './EntityCard'
import Table from '../../email-server-common/common/Table'
import Row from '../../email-server-common/common/Row'
import Cell from '../../email-server-common/common/Cell'
import EntityIcon from './EntityIcon'
import { colors } from './constants'
import NoArticlesSection from './NoArticlesSection'

export default function CompanyEsgSections({
  data,
  rootUrl,
  insightsRootUrl,
  emailType
}) {
  const companiesWithStories = data.companies.filter(company => company.topStories.length > 0)
  return (
    <>
      <Table>
        <Row>
          <Cell height='16'>
            {/* This is here to make Outlook happy, since they're not supporting margins :@ and using conditional
                      rendering with <!-- is a PIA in react.*/}
          </Cell>
        </Row>
      </Table>
      {companiesWithStories.length > 0 && <Table
        style={{
          margin: '0 auto',
          backgroundColor: colors.contentBackgroundColor,
        }}
        width='600'
        align='center'
      >
        <Row>
          <Cell
            style={{
              padding: '24px 0 24px 32px',
            }}>
            <Table>
              <Row>
                <Cell
                  style={{
                    paddingBottom: 10,
                    borderBottom: `1px solid ${colors.borderColor}`,
                    fontFamily: 'Source Sans Pro',
                    fontSize: 24,
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    letterSpacing: 1,
                  }}
                >
                  <EntityIcon entityType='company'
                              style={{ verticalAlign: 'middle', paddingBottom: 5, paddingRight: 5 }}
                  /> Companies
                </Cell>
              </Row>
            </Table>
          </Cell>
        </Row>
        <Row>
          <Cell
            style={{
              padding: '0 0 0 32px',
            }}
          >
            {companiesWithStories.map((company, index) => {
              return <React.Fragment key={company.id}>
                <EntityCard
                  key={company.id}
                  entity={company}
                  userId={data.emailSendDetails.userId}
                  rootUrl={rootUrl}
                  insightsRootUrl={insightsRootUrl}
                  entityType='company'
                  emailType = {emailType}
                />
                {index + 1 < companiesWithStories.length ?
                  (
                    <Table>
                      <Row>
                        <Cell height='32' style={{
                          borderBottom: `1px solid ${colors.borderColor}`
                        }}/>
                      </Row>
                      <Row>
                        <Cell height='32'/>
                      </Row>
                    </Table>
                  ) : (
                    <Table>
                      <Row>
                        <Cell height='32'/>
                      </Row>
                    </Table>
                  )
                }
              </React.Fragment>
            })}
          </Cell>
        </Row>
      </Table>}
      {data.companies.filter(company => company.topStories.length === 0).length > 0 &&
        <NoArticlesSection
          entities={data.companies.filter(company => company.topStories.length === 0)
            .map(company => company.displayName)}
          entityType={'Company'}/>
      }
    </>
  )
}
