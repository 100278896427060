import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {getLogin} from 'app/login/login-selectors'
import TextBox from 'app/common/TextBox'
import Dropdown from 'app/common/Dropdown'
import * as profileBuilderActions from './profile-builder-actions'
import {getState} from './profile-builder-selectors'

import './AccountSettings.less'

class AccountSettings extends Component {
  render() {
    const {user, timezones, roles, locations} = this.props

    const firmLocationOptions = [{value: 0, label: ''}].concat(
      user.firm.locations.map(l => ({value: l.id, label: l.description})),
    )

    const topLocations = [
      locations.find(c => c.name === 'Australia'),
      locations.find(c => c.name === 'Canada'),
      locations.find(c => c.name === 'United Kingdom'),
      locations.find(c => c.name === 'United States'),
      {id: 0, name: '----------'},
    ]

    const locationOptions = topLocations
      .concat(locations)
      .map(c => ({value: c.id, label: c.name}))

    const timezoneOptions = timezones.map(tz => ({value: tz[0], label: tz[1]}))

    const roleOptions = roles
      .map(r => ({value: r[0], label: r[1]}))
      .filter(r => r.value !== 'group')

    return (
      <div
        id="profile-builder-account-settings"
        className="builder-content-body-container"
      >
        <div className="builder-content-body">
          <p>
            Almost done! We just need a little more information and then you'll
            be all set.
          </p>

          <div className="account-settings-row">
            <div className="label">USERNAME</div>
            <div>{user.username}</div>
          </div>

          <div className="account-settings-row">
            <div className="label validated">
              <div>PASSWORD *</div>
              {this.props.validationError.field === 'password' && (
                <div className="validation-message">
                  {this.props.validationError.message}
                </div>
              )}
            </div>
            <div>
              <TextBox
                type="password"
                value={this.props.password || ''}
                className="text-box"
                onChange={evt => this.handlePasswordChange(evt)}
              />
            </div>
          </div>

          <div className="account-settings-row">
            <div className="label">CONFIRM PASSWORD *</div>
            <div>
              <TextBox
                type="password"
                value={this.props.confirmPassword || ''}
                className="text-box"
                onChange={evt => this.handleConfirmPasswordChange(evt)}
              />
            </div>
          </div>

          <div className="account-settings-row">
            <div className="label validated">
              <div>ROLE *</div>
              {this.props.validationError.field === 'role' && (
                <div className="validation-message">
                  {this.props.validationError.message}
                </div>
              )}
            </div>
            <div>
              <Dropdown
                options={roleOptions}
                value={this.props.role || ''}
                onChange={value => this.handleRoleChange(value)}
              />
            </div>
          </div>

          <div className="account-settings-row">
            <div className="label validated">
              <div>COUNTRY *</div>
              {this.props.validationError.field === 'location' && (
                <div className="validation-message">
                  {this.props.validationError.message}
                </div>
              )}
            </div>
            <div>
              <Dropdown
                options={locationOptions}
                value={this.props.locationId}
                onChange={value => this.handleLocationChange(value)}
              />
            </div>
          </div>

          <div className="account-settings-row">
            <div className="label">TIMEZONE *</div>
            <div>
              <Dropdown
                options={timezoneOptions}
                value={this.props.timezone}
                onChange={value => this.handleTimezoneChange(value)}
              />
            </div>
          </div>

          <div className="account-settings-row">
            <div className="label">OFFICE LOCATION</div>
            <div>
              <Dropdown
                options={firmLocationOptions}
                value={this.props.firmLocationId || 0}
                onChange={value => this.handleFirmLocationChange(value)}
              />
            </div>
          </div>

          <div className="account-settings-row">
            <div>* Required Field</div>
          </div>
        </div>
      </div>
    )
  }

  handlePasswordChange(evt) {
    this.props.setPassword(evt.target.value)
  }

  handleConfirmPasswordChange(evt) {
    this.props.setConfirmPassword(evt.target.value)
  }

  handleFirmLocationChange(firmLocationId) {
    this.props.setFirmLocationId(firmLocationId)
  }

  handleTimezoneChange(timezone) {
    this.props.setTimezone(timezone)
  }

  handleRoleChange(role) {
    this.props.setRole(role)
  }

  handleLocationChange(locationId) {
    this.props.setLocationId(locationId)
  }
}

export default connect(
  createSelector(
    [getState, getLogin],
    (profileBuilder, login) => ({
      user: login.currentUser,
      timezone: profileBuilder.timezone,
      role: profileBuilder.role,
      locationId: profileBuilder.locationId,
      firmLocationId: profileBuilder.firmLocationId,
      validationError: profileBuilder.validationError,
      password: profileBuilder.password,
      confirmPassword: profileBuilder.confirmPassword,

      timezones: login.timezones,
      roles: login.roles,
      locations: login.locations,
    }),
  ),
  {
    setPassword: profileBuilderActions.setPassword,
    setConfirmPassword: profileBuilderActions.setConfirmPassword,
    setFirmLocationId: profileBuilderActions.setFirmLocationId,
    setTimezone: profileBuilderActions.setTimezone,
    setRole: profileBuilderActions.setRole,
    setLocationId: profileBuilderActions.setLocationId,
  },
)(AccountSettings)
