import Model from "app/framework/Model";
import { field } from "app/framework/fields"

export default class LitigationStory extends Model {
  static entityKey = "litigationStories";

  static fields = {
    companyId: field(),
    keyArticleId: field(),
    url: field(),
    headline: field(),
  }
}
