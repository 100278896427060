import {useEffect, useState} from 'react'

/**
 * Returns the ResizeObserver object. If it is natively supported, immediately
 * returns the native object; if not, loads the polyfill asynchronously and
 * returns that once it has loaded.
 */
// We use these variables to ensure that we only load the polyfill a maximum of
// once.
let GlobalResizeObserver = window.ResizeObserver || null
const resizeObserverPromise = GlobalResizeObserver
  ? new Promise((resolve, reject) => {
      resolve(GlobalResizeObserver)
    })
  : import(
      /* webpackChunkName: "resize-observer-polyfill" */
      'resize-observer-polyfill'
    ).then(({default: ResizeObserver}) => {
      GlobalResizeObserver = ResizeObserver
      return ResizeObserver
    })
export function useResizeObserver() {
  const [ResizeObserver, setResizeObserver] = useState(
    () => GlobalResizeObserver,
  )
  useEffect(() => {
    if (!ResizeObserver) {
      resizeObserverPromise.then(ResizeObserver => {
        setResizeObserver(() => ResizeObserver)
        GlobalResizeObserver = ResizeObserver
      })
    }
  }, [])
  return ResizeObserver
}
