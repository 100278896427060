const MIN_TICKS_BETWEEN_DATE_LABELS_PER_DAY_COUNT = 5 / 30

/**
 * Takes an array of consecutive dates and returns a copy of the array where
 * dates that are not significant for labeling purposes are replaced with
 * `null`.
 */
export function significantTickLabelDates(dates) {
  const minSpacing = Math.ceil(
    MIN_TICKS_BETWEEN_DATE_LABELS_PER_DAY_COUNT * dates.length,
  )
  return dates.map((date, index) => {
    if (
      index === 0 ||
      index === dates.length - 1 ||
      (index >= minSpacing &&
        index <= dates.length - minSpacing - 1 &&
        [1, 15].includes(date.getDate()))
    ) {
      return date
    }
    return null
  })
}
