import PropTypes from 'prop-types'
import React from 'react'

import Orm from 'app/framework/Orm'
import {Company, Industry} from 'app/models'
import * as resources from 'app/resources'
import {EmailAlertProvider} from 'email-server-common/context'
import Cell from 'email-server-common/common/Cell'
import Table from 'email-server-common/common/Table'
import {backgroundColorLight, textColorNormal} from 'email-server/style/palette'

import CompanySection from './CompanySection'
import IndustrySection from './IndustrySection'
import Footer from './Footer'
import Header from './Header'
import TopSection from './TopSection'
import HtmlReactParser from 'html-react-parser'

const BasicEmailAlert = ({
  user,
  firm,
  companies,
  industries,
  date,
  timeFrameDays,
  productName,
  productLogoUrl,
  rootUrl,
  baseRootUrl,
  copyrightHolderName,
  contactPhone,
  contactAddress,
  shouldShowHealthChanges = false,
  emailAlertPrefix,
  emailAlertSuffix,
}) => (
  <Table>
    <Cell
      style={{
        backgroundColor: 'white',
        color: textColorNormal,
        fontSize: 14,
      }}
    >
      <Header
        user={user}
        firm={firm}
        date={date}
        productName={productName}
        productLogoUrl={productLogoUrl}
        rootUrl={rootUrl}
        emailAlertPrefix={emailAlertPrefix}
        emailAlertSuffix={emailAlertSuffix}
      />
      <Table>
        <Cell style={{backgroundColor: backgroundColorLight}}>
          {companies.length + industries.length > 0 && (
            <TopSection
              companies={companies}
              industries={industries}
              timeFrameDays={timeFrameDays}
              productName={productName}
            />
          )}

          {companies.map(company => (
            <CompanySection
              company={company}
              user={user}
              date={date}
              shouldShowHealthChanges={shouldShowHealthChanges}
              timeFrameDays={timeFrameDays}
              productName={productName}
              rootUrl={rootUrl}
              baseRootUrl={baseRootUrl}
              key={company.id}
            />
          ))}

          {industries.map(industry => (
            <IndustrySection
              industry={industry}
              date={date}
              shouldShowHealthChanges={shouldShowHealthChanges}
              timeFrameDays={timeFrameDays}
              productName={productName}
              rootUrl={rootUrl}
              key={industry.id}
            />
          ))}
          {emailAlertSuffix?
            <Table>
              <Cell
                style={{
                  padding: '20px 20px 20px 20px',
                  backgroundColor: "#F2F2F2",
                  fontSize: "initial"
                }}>
                {HtmlReactParser(emailAlertSuffix)}
              </Cell>
            </Table>:''
          }
          <Footer
            baseRootUrl={baseRootUrl}
            productName={productName}
            productLogoUrl={productLogoUrl}
            copyrightHolderName={copyrightHolderName}
            contactPhone={contactPhone}
            contactAddress={contactAddress}
            year={date.getFullYear()}
            firm={firm}
          />
        </Cell>
      </Table>
    </Cell>
  </Table>
)
BasicEmailAlert.propTypes = {
  user: PropTypes.object.isRequired,
  firm: PropTypes.object.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  date: PropTypes.object.isRequired,
  timeFrameDays: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  productLogoUrl: PropTypes.string.isRequired,
  rootUrl: PropTypes.string.isRequired,
  baseRootUrl: PropTypes.string.isRequired,
  copyrightHolderName: PropTypes.string.isRequired,
  contactPhone: PropTypes.string.isRequired,
  contactAddress: PropTypes.string.isRequired,
  shouldShowHealthChanges: PropTypes.bool,
  emailAlertPrefix: PropTypes.string,
  emailAlertSuffix: PropTypes.string,
}

const getNormalizedData = (resource, model, data) => {
  const normalizedData = new resource().normalizedData(data)
  const orm = Orm.withEntities(normalizedData.entities)
  return orm.getByIds(model, normalizedData.result)
}

/**
 * Wrapper over BasicEmailAlert that transforms the raw message data into
 * something usable by the component.
 */
const BasicEmailAlertWrapper = ({
  data,
  config: {shouldShowHealthChanges, timeFrameDays} = {},
}) => {
  const user = {
    id: data.userId,
    name: data.userFullName,
  }
  const firm = {
    name: data.firmName,
    logoUrl: data.firmLogo,
    ssoLoginUrl: data.ssoLoginUrl,
    ssoUrlDescription: data.ssoUrlDescription,
    enableUpdateYourProfile : data.enableUpdateYourProfile,
  }
  const date = new Date(data.isoEmailDate)

  const companies = getNormalizedData(
    resources.Company,
    Company,
    data.companies,
  )
  const industries = getNormalizedData(
    resources.Industry,
    Industry,
    data.industries || [],
  )

  const productName = data.insightsLabel
  const productLogoUrl = data.insightsLogo
  // The Insights URL passed in from Base may end in a slash, so we need to
  // remove the trailing slash.
  const rootUrl = data.insightsUrl.replace(/\/$/, '')
  const baseRootUrl = data.serverUrl
  const copyrightHolderName = data.copyrightHolderName
  const contactPhone = data.contactPhone
  const contactAddress = data.contactAddress
  const emailAlertPrefix = data.emailProfileAlertPrefix
  const emailAlertSuffix = data.emailProfileAlertSuffix
  return (
    <EmailAlertProvider
      value={{
        firmId: data.firmId,
        ssoLoginUrl: data.ssoLoginUrl,
        ssoUrlDescription: data.ssoUrlDescription,
      }}
    >
      <BasicEmailAlert
        user={user}
        firm={firm}
        companies={companies}
        industries={industries}
        date={date}
        timeFrameDays={timeFrameDays}
        productName={productName}
        productLogoUrl={productLogoUrl}
        rootUrl={rootUrl}
        baseRootUrl={baseRootUrl}
        copyrightHolderName={copyrightHolderName}
        contactPhone={contactPhone}
        contactAddress={contactAddress}
        shouldShowHealthChanges={shouldShowHealthChanges}
        emailAlertPrefix={emailAlertPrefix}
        emailAlertSuffix={emailAlertSuffix}
      />
    </EmailAlertProvider>
  )
}
BasicEmailAlertWrapper.propTypes = {
  data: PropTypes.object.isRequired,
  config: PropTypes.object,
}
export default BasicEmailAlertWrapper
