import classNames from 'classnames'
import is from 'is'
import PropTypes from 'prop-types'
import {addIndex, groupBy, map, pipe, prop, range, toPairs, unnest} from 'ramda'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
} from 'recharts'

import ChartTooltip from 'app/common/charting/ChartTooltip'
import Dropdown from 'app/common/Dropdown'
import HealthScore from 'app/common/HealthScore'
import InputBlock from 'app/common/InputBlock'
import {LoadingMessage} from 'app/common/loading-message'
import Link from 'app/common/NavigationLink'
import Table from 'app/common/Table'
import {Tooltip} from 'app/common/tooltip'
import {CHART_COLORS} from 'app/constants'
import * as strings from 'app/strings'
import MyCompanyStar from 'app/reusable/companies/my-company-star/MyCompanyStar'
import * as routerSelectors from 'app/router/router-selectors'
import routes from 'app/routes'
import urls from 'app/urls'
import {coalesceUndefined} from 'app/utils'
import * as branding from 'branding'

import * as actions from './company-overview-simcirc-actions'
import * as selectors from './company-overview-simcirc-selectors'
import {
  matchMonthDisplayName,
  matchMonthShortDisplayName,
} from './company-overview-simcirc-strings'
import {matchMonthToQuarter} from './company-overview-simcirc-utils'
import {SimCircForecast} from './forecast'
import SimCircStories from './SimCircStories'

import styles from './CompanySimCirc.less'

const mapWithIndex = addIndex(map)

function subtractMonths(matchMonth, months) {
  if (months === 0) {
    return matchMonth
  }
  let [year, month] = matchMonth.split('-').map(val => parseInt(val, 10))
  month -= months
  if (month < 1) {
    year -= 1
    month = 12 + month
  }
  return `${year}-${strings.leftPadZeroes(month, 2)}`
}

/**
 * Calculates the similarity between two scores on a scale of 0 to 10.
 */
function calculateSimilarity(score1, score2) {
  return Math.max(10 - Math.abs(score1 - score2), 0)
}

export function TooltipLineItem({label, score, color}) {
  return (
    <div className={styles.lineItem}>
      <div className={styles.lineColor} style={{backgroundColor: color}} />
      <div className={styles.label}>
        {label}: <HealthScore score={coalesceUndefined(score, null)} />{' '}
      </div>
    </div>
  )
}
TooltipLineItem.propTypes = {
  label: PropTypes.node.isRequired,
  score: PropTypes.number,
  color: PropTypes.string.isRequired,
}

function RadarChartTooltip({dataPoints}) {
  // Turn the data points into pairs grouped by subfactor, like:
  //   [[subfactor, dataPoints], ...]
  const subfactorDataPoints = pipe(
    groupBy(prop('subfactor')),
    toPairs,
  )(dataPoints)
  return (
    <ChartTooltip className={styles.tooltip}>
      {subfactorDataPoints.map(([subfactor, dataPoints]) => (
        <React.Fragment key={subfactor}>
          <div className={styles.header}>
            {strings.subfactorDisplayName(subfactor)} Monthly Health Scores:
          </div>
          {dataPoints.map(
            ({
              company,
              subfactor,
              score,
              color,
              monthsBack,
              matchMonth = null,
            }) => {
              let monthLabel
              if (matchMonth) {
                monthLabel = matchMonthShortDisplayName(
                  subtractMonths(matchMonth, monthsBack),
                )
              } else if (monthsBack === 0) {
                monthLabel = 'This Month'
              } else {
                monthLabel = `${monthsBack} Month${
                  monthsBack > 1 ? 's' : ''
                } Ago`
              }
              return (
                <TooltipLineItem
                  label={
                    <React.Fragment>
                      {company.name} &ndash; {monthLabel}
                    </React.Fragment>
                  }
                  score={score}
                  color={color}
                  key={`${company.id}-${subfactor}-${monthsBack}`}
                />
              )
            },
          )}
        </React.Fragment>
      ))}
    </ChartTooltip>
  )
}
RadarChartTooltip.propTypes = {
  dataPoints: PropTypes.arrayOf(PropTypes.object).isRequired,
  company: PropTypes.object.isRequired,
}

function CompanySimCircCurrent({company}) {
  const matches = useSelector(selectors.getMatches)
  const comparedMatch = useSelector(selectors.getComparedMatch)

  const areStoriesLoading = useSelector(selectors.getAreStoriesLoading)
  const targetStories = useSelector(selectors.getTargetStories)
  const matchStories = useSelector(selectors.getMatchStories)

  const dispatch = useDispatch()
  const setComparedMatchId = id => dispatch(actions.setComparedMatchId(id))

  const matchingSubfactors = useSelector(selectors.getComparedMatchSubfactors)
  const selectedSubfactor = useSelector(selectors.getSelectedSubfactor)
  const selectSubfactor = subfactor => {
    if (subfactor === selectedSubfactor) {
      dispatch(actions.selectSubfactor(null))
    } else {
      dispatch(actions.selectSubfactor(subfactor))
    }
  }

  const data = pipe(
    mapWithIndex((subfactorScores, subfactorIndex) =>
      range(0, 2).map(monthIndex => {
        const {subfactor} = subfactorScores
        const point = {
          subfactorMonth: `${subfactor}:${monthIndex}`,
          [subfactor]: calculateSimilarity(
            subfactorScores.targetHealthScores[monthIndex],
            subfactorScores.matchHealthScores[monthIndex],
          ),
        }
        if (monthIndex === 0) {
          const previousSubfactorIndex =
            subfactorIndex === 0
              ? comparedMatch.monthlySubfactorScores.length - 1
              : subfactorIndex - 1
          const previousSubfactorScores =
            comparedMatch.monthlySubfactorScores[previousSubfactorIndex]
          const previousSubfactor = previousSubfactorScores.subfactor
          point[previousSubfactor] = calculateSimilarity(
            previousSubfactorScores.targetHealthScores[2],
            previousSubfactorScores.matchHealthScores[2],
          )
        }
        return point
      }),
    ),
    unnest,
  )(comparedMatch.monthlySubfactorScores)

  const storyTitleSuffix = selectedSubfactor
    ? ` — ${strings.subfactorDisplayName(selectedSubfactor)}`
    : ''

  const formatSubfactor = (subfactor, info, isInactive) => {

    let displaySubfactor = strings.subfactorDisplayName(subfactor)

    const subfactorWords = displaySubfactor.split(' & ')
    const coordinate = Math.abs(info.payload.coordinate)

    if ((coordinate === 180 || coordinate === 0)
        && subfactorWords.length === 2) {

      const diff = subfactorWords[0].length - subfactorWords[1].length
      displaySubfactor =
        <React.Fragment>
          {subfactorWords.map((w, idx) => {
            let xCoordinate = info.x
            if (Math.abs(diff) < 5 && idx > 0) {
              xCoordinate = xCoordinate + diff
            }
            if (Math.abs(diff) >= 5 && idx === 0 && coordinate === 180) {
              xCoordinate = xCoordinate + diff * 3
            }
            return(
              <tspan
                key={`${coordinate}-${idx}`}
                x={xCoordinate}
                y={info.y + 20 * idx}
              >
                {idx === 0 ? `${w} &` : w}
              </tspan>
            )
          })}
        </React.Fragment>
    }
    return (
      <text
        onClick={() => selectSubfactor(subfactor)}
        textAnchor={info.textAnchor}
        x={info.x}
        y={info.y}
        className={classNames({[styles.inactiveText]: isInactive})}
        style={{cursor: 'pointer'}}
      >
        {displaySubfactor}
      </text>
    )
  }

  return (
    <React.Fragment>
      <h3>Matching companies</h3>

      <p>
        Listed below are the companies that have a similar set of circumstances
        at some historical point in time. You will see the company, health
        score, and date in ranked order of how similar their historical set of
        circumstances are when compared to the current company being viewed.
      </p>

      <Table
        data={matches}
        onRowClick={match => setComparedMatchId(match.id)}
        className={styles.matchesTable}
        bodyClassName={styles.body}
        rowClassName={match =>
          classNames(styles.row, {
            [styles.active]: match.id === comparedMatch.id,
          })
        }
        cellClassName={styles.cell}
      >
        <Table.Column
          name="rank"
          label="Rank"
          baseWidth={80}
          growRatio={0}
          shrinkRatio={0}
          cellContents={match => match.rank}
        />
        <Table.Column
          name="name"
          label="Company Name"
          baseWidth={160}
          growRatio={2}
          shrinkRatio={0}
          cellContents={match => (
            <div className={styles.companyName}>
              <MyCompanyStar company={match.company} />
              <span>{match.company.name}</span>
            </div>
          )}
        />
        <Table.Column
          name="matchMonth"
          label="Matched Month"
          baseWidth={160}
          growRatio={1}
          shrinkRatio={0}
          cellContents={match => (
            <Tooltip
              label={`Click to view historical stories for ${
                match.company.name
              } this quarter.`}
              className={styles.quarterTooltip}
            >
              <Link
                href={urls.companyHistory(match.company.id, {
                  quarter: matchMonthToQuarter(match.matchMonth),
                })}
                onClick={event =>
                  // Make sure that the click doesn't also trigger for the table.
                  event.stopPropagation()
                }
              >
                {matchMonthShortDisplayName(match.matchMonth)}
              </Link>
            </Tooltip>
          )}
        />
      </Table>

      <h3>Trends and stories</h3>

      <p>
        This area goes deeper, showing you the health score trends between the
        target company and your chosen comparison company, while also displaying
        key storylines that created the similar set of circumstances and
        comparable health scores. Select a subfactor to narrow down the stories
        shown.
      </p>

      <InputBlock label="Matching company dates">
        <Dropdown
          options={matches.map(match => ({
            label: `${match.company.name} (${matchMonthShortDisplayName(
              match.matchMonth,
            )})`,
            value: match.id,
          }))}
          value={comparedMatch.id}
          onChange={setComparedMatchId}
          className={classNames(styles.dropdown, styles.matches)}
        />
      </InputBlock>

      <div className={styles.chartContainer}>
        <ResponsiveContainer width={620} minHeight={360} height="100%">
          <RadarChart data={data}>
            <PolarGrid stroke={branding['line-color-light']} />
            <PolarAngleAxis
              dataKey="subfactorMonth"
              tick={info => {
                // We only show the tick label for the middle one (month 1).
                const {value} = info.payload
                if (!value.endsWith(':1')) {
                  return null
                }
                const subfactor = value.split(':')[0]
                const isInactive =
                  selectedSubfactor && subfactor !== selectedSubfactor
                // We override the default rendering so that we can pass `onClick`.
                return (
                  formatSubfactor(subfactor, info, isInactive)
                )
              }}
            />
            {/* This is just here to limit the domain of the chart. */}
            <PolarRadiusAxis domain={[0, 10]} tick={false} axisLine={false} />
            {matchingSubfactors.map((subfactor, index) => (
              <Radar
                name={subfactor}
                dataKey={subfactor}
                fill={CHART_COLORS[index]}
                fillOpacity={
                  !selectedSubfactor || subfactor === selectedSubfactor
                    ? 0.6
                    : 0.2
                }
                activeDot={info => {
                  // Only render active dots for the relevant subfactors.
                  if (is.undefined(info.payload[info.dataKey])) {
                    return null
                  }
                  return (
                    <circle
                      fill={info.fill}
                      stroke={info.stroke}
                      cx={info.cx}
                      cy={info.cy}
                      r={info.r}
                    />
                  )
                }}
                isAnimationActive={false}
                key={subfactor}
              />
            ))}
            <RechartsTooltip
              content={info => {
                if (!info.active) return null
                const subfactorMonth = info.label
                let [subfactor, monthIndex] = subfactorMonth.split(':')
                monthIndex = parseInt(monthIndex, 10)

                function dataPointsForSubfactorMonth(subfactor, monthIndex) {
                  const subfactorIndex = matchingSubfactors.indexOf(subfactor)
                  const scores = comparedMatch.monthlySubfactorScores.find(
                    scores => scores.subfactor === subfactor,
                  )
                  return [true, false].map(isTarget => ({
                    company: isTarget ? company : comparedMatch.company,
                    subfactor,
                    score:
                      scores[`${isTarget ? 'target' : 'match'}HealthScores`][
                        monthIndex
                      ],
                    color: CHART_COLORS[subfactorIndex],
                    monthsBack: 2 - monthIndex,
                    matchMonth: isTarget ? null : comparedMatch.matchMonth,
                  }))
                }

                // Determine which subfactor-months to show and collect all of the
                // relevant data for each combination of company and
                // subfactor-month.
                let tooltipPointsNested = [
                  ...dataPointsForSubfactorMonth(subfactor, monthIndex),
                ]
                const subfactorIndex = matchingSubfactors.indexOf(subfactor)
                if (monthIndex === 0) {
                  // We need the previous subfactor.
                  const prevSubfactorIndex =
                    subfactorIndex === 0
                      ? matchingSubfactors.length - 1
                      : subfactorIndex - 1
                  tooltipPointsNested = [
                    ...tooltipPointsNested,
                    ...dataPointsForSubfactorMonth(
                      matchingSubfactors[prevSubfactorIndex],
                      2,
                    ),
                  ]
                } else if (monthIndex === 2) {
                  // We need the next subfactor.
                  const nextSubfactorIndex =
                    subfactorIndex === matchingSubfactors.length - 1
                      ? 0
                      : subfactorIndex + 1
                  tooltipPointsNested = [
                    ...tooltipPointsNested,
                    ...dataPointsForSubfactorMonth(
                      matchingSubfactors[nextSubfactorIndex],
                      0,
                    ),
                  ]
                }

                const dataPoints = unnest(tooltipPointsNested)
                return (
                  <RadarChartTooltip
                    dataPoints={dataPoints}
                    company={company}
                  />
                )
              }}
            />
          </RadarChart>
        </ResponsiveContainer>

        <div className={styles.chartHelp}>
          <h3>Health Score Similarities</h3>

          <div className={styles.content}>
            <p>
              The chart to the left shows the similarities in health scores
              between <strong>{company.name}</strong> over the last three months
              and <strong>{comparedMatch.company.name}</strong> over the three
              months ending on{' '}
              <strong>{matchMonthDisplayName(comparedMatch.matchMonth)}</strong>{' '}
              across different subfactors.
            </p>

            <p>
              Each colored section in the chart represents a different
              subfactor, and the lines within each section represent the
              similarity in health scores across different months within the
              matching three month period. The section starts on the first month
              of the time frame (two months ago for {company.name} and{' '}
              {matchMonthDisplayName(
                subtractMonths(comparedMatch.matchMonth, 2),
              )}{' '}
              for {comparedMatch.company.name}), moving clockwise towards the
              most recent month (this month for {company.name} vs.{' '}
              {matchMonthDisplayName(comparedMatch.matchMonth)} for{' '}
              {comparedMatch.company.name}).
            </p>

            <p>
              Sections that reach the end of the chart represent completely
              identical health scores for both companies for the respective
              month, whereas shorter sections represent a lower similarity in
              health scores.
            </p>
          </div>
        </div>
      </div>

      <InputBlock label="Most similar subfactors">
        <Dropdown
          options={[
            {
              label: 'All',
              value: null,
            },
            ...matchingSubfactors.map(subfactor => ({
              label: strings.subfactorDisplayName(subfactor),
              value: subfactor,
            })),
          ]}
          value={selectedSubfactor}
          onChange={subfactor => dispatch(actions.selectSubfactor(subfactor))}
          className={styles.dropdown}
        />
      </InputBlock>

      <div className={styles.columns}>
        <div className={styles.column}>
          <SimCircStories
            title={`${company.name} Stories${storyTitleSuffix}`}
            stories={targetStories}
            isLoading={areStoriesLoading}
          />
        </div>

        <div className={styles.column}>
          <SimCircStories
            title={`${comparedMatch.company.name} Stories${storyTitleSuffix}`}
            stories={matchStories}
            isLoading={areStoriesLoading}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
CompanySimCircCurrent.propTypes = {
  company: PropTypes.object.isRequired,
}

export default function CompanySimCirc({company}) {
  const currentRoute = useSelector(routerSelectors.getCurrentRoute)
  const isLoading = useSelector(selectors.getIsLoading)
  return (
    <div className={styles.page}>
      {isLoading ? (
        <LoadingMessage />
      ) : (
        <React.Fragment>
          <div className={styles.pageSelect}>
            <Link
              href={urls.companySimCircForecast(company.id)}
              className={classNames(styles.link, {
                [styles.active]: currentRoute === routes.companySimCircForecast,
              })}
            >
              Forecast
            </Link>
            <Link
              href={urls.companySimCircCurrent(company.id)}
              className={classNames(styles.link, {
                [styles.active]: currentRoute === routes.companySimCircCurrent,
              })}
            >
              Current State
            </Link>
          </div>

          {currentRoute === routes.companySimCircCurrent ? (
            <CompanySimCircCurrent company={company} />
          ) : currentRoute === routes.companySimCircForecast ? (
            <SimCircForecast company={company} />
          ) : (
            <div />
          )}
        </React.Fragment>
      )}
    </div>
  )
}
CompanySimCirc.propTypes = {
  company: PropTypes.object.isRequired,
}
