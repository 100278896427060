import classNames from 'classnames'
import is from 'is'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'

import SizedContainer from 'app/common/SizedContainer'

import * as styles from './LineChart.less'
import {trueRound} from 'app/utils/numbers'

export const getAggregateValue = (aggregator, propName, series) =>
  series.reduce((current, data) => {
    const value = data.reduce((current, point) => {
      if (current === null) {
        return point[propName]
      }
      return aggregator(current, point[propName])
    }, null)
    if (current === null) {
      return value
    }
    return aggregator(current, value)
  }, null)

export default function LineChartContainer({
  series,
  colors,
  minYValue,
  maxYValue,
  minXValue,
  maxXValue,
  shouldShowDots = true,
  className,
  containerClassName,
}) {
  if (is.undefined(minYValue)) {
    minYValue = getAggregateValue(Math.min, 'y', series)
  }
  if (is.undefined(maxYValue)) {
    maxYValue = getAggregateValue(Math.max, 'y', series)
  }
  if (is.undefined(minXValue)) {
    minXValue = getAggregateValue(Math.min, 'x', series)
  }
  if (is.undefined(maxXValue)) {
    maxXValue = getAggregateValue(Math.max, 'x', series)
  }

  // If there is no range, just add 1 to both sides.
  if (minYValue === maxYValue) {
    minYValue = Math.max(minYValue - 1, -10)
    maxYValue = Math.min(maxYValue + 1, 10)
  }

  return (
    <SizedContainer style={{overflow: 'hidden'}} className={containerClassName}>
      <LineChart
        series={series}
        colors={colors}
        minYValue={minYValue}
        maxYValue={maxYValue}
        minXValue={minXValue}
        maxXValue={maxXValue}
        shouldShowDots={shouldShowDots}
        className={className}
      />
    </SizedContainer>
  )
}
LineChartContainer.propTypes = {
  series: PropTypes.arrayOf(PropTypes.array).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  minYValue: PropTypes.number,
  maxYValue: PropTypes.number,
  minXValue: PropTypes.number,
  maxXValue: PropTypes.number,
  shouldShowDots: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
}

function LineChart({
  series,
  colors,
  minYValue,
  maxYValue,
  minXValue,
  maxXValue,
  shouldShowDots,
  width = 0,
  height = 0,
  className,
}) {
  const lines = series.map((data, index) => (
    <ChartLine
      data={data}
      color={colors[index]}
      minYValue={minYValue}
      maxYValue={maxYValue}
      minXValue={minXValue}
      maxXValue={maxXValue}
      chartWidth={width}
      chartHeight={height}
      shouldShowDots={shouldShowDots}
      key={index}
    />
  ))
  const midYRatio = maxYValue / (maxYValue - minYValue)
  const midY = midYRatio * height
  return (
    <svg
      className={classNames(styles.chart, className)}
      width={width}
      height={height}
    >
      <line className={styles.midLine} x1={0} y1={midY} x2={width} y2={midY} />
      {lines}
    </svg>
  )
}
LineChart.propTypes = {
  series: PropTypes.arrayOf(PropTypes.array).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  minYValue: PropTypes.number.isRequired,
  maxYValue: PropTypes.number.isRequired,
  minXValue: PropTypes.number.isRequired,
  maxXValue: PropTypes.number.isRequired,
  shouldShowDots: PropTypes.bool.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
}

function ChartLine({
  data,
  color,
  minYValue,
  maxYValue,
  minXValue,
  maxXValue,
  chartWidth,
  chartHeight,
  shouldShowDots,
}) {
  const paths = []
  const dots = []
  data = R.sortBy(R.prop('x'), data)

  if (data.length >= 2) {
    let prevPoint = null
    let lastX = null

    data.forEach(point => {
      const rangeX = maxXValue - minXValue
      const rangeY = maxYValue - minYValue
      const x = trueRound((chartWidth * (point.x - minXValue)) / rangeX)
      const y = trueRound(((maxYValue - point.y) * chartHeight) / rangeY)

      if (shouldShowDots) {
        dots.push(
          <circle cx={x} cy={y} r={2} style={{fill: color}} key={point.x} />,
        )
      }

      if (lastX !== null && point.x === lastX + 1) {
        paths.push(
          <line
            className={styles.line}
            x1={prevPoint.x}
            y1={prevPoint.y}
            x2={x}
            y2={y}
            style={{stroke: color}}
            key={paths.length}
          />,
        )
      }

      prevPoint = {x, y}
      lastX = point.x
    })
  }

  return (
    <g>
      {paths}
      {dots}
    </g>
  )
}
ChartLine.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  color: PropTypes.string.isRequired,
  minYValue: PropTypes.number.isRequired,
  maxYValue: PropTypes.number.isRequired,
  minXValue: PropTypes.number.isRequired,
  maxXValue: PropTypes.number.isRequired,
  chartWidth: PropTypes.number.isRequired,
  chartHeight: PropTypes.number.isRequired,
  shouldShowDots: PropTypes.bool.isRequired,
}
