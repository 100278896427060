import React from 'react'
import classNames from 'classnames'

import * as styles from './CustomRadioButton.less'
import RadioButton from 'app/common/RadioButton'

export default function CustomRadioButton({ className, ...restProps }) {
  return (
    <div className={classNames(styles.radioButtonContainer, className)}>
      <RadioButton {...restProps}/>
    </div>
  )
}
