import PropTypes from 'prop-types'
import {range} from 'ramda'
import React from 'react'

import * as styles from './XAxisMarkers.less'

export default function XAxisMarkers({
  x,
  y,
  height,
  markerCount,
  markerWidth = 0,
  markerSpacing,
}) {
  const markers = range(0, markerCount).map(index => {
    const markerX =
      x + (markerWidth * (index + 1) - markerWidth / 2) + markerSpacing * index
    return (
      <line
        className={styles.marker}
        x1={markerX}
        y1={y}
        x2={markerX}
        y2={y + height}
        key={index}
      />
    )
  })
  return <g className={styles.xAxisMarkers}>{markers}</g>
}
XAxisMarkers.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  markerCount: PropTypes.number.isRequired,
  markerWidth: PropTypes.number,
  markerSpacing: PropTypes.number.isRequired,
}
