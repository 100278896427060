import React from 'react'
import {connect} from 'react-redux'

import {createSelector} from 'reselect'
import Orm from 'app/framework/Orm'
import {getEntities} from 'app/framework/entities-selectors'
import {Company, Industry} from 'app/models'
import EntityInput from 'app/reusable/entities/entity-input'

import {getState} from './profile-builder-selectors'
import * as profileBuilderActions from './profile-builder-actions'

class Industries extends React.PureComponent {
  render() {
    const numberLabels = [
      'first',
      'second',
      'third',
      'fourth',
      'fifth',
      'sixth',
      'seventh',
      'eighth',
      'ninth',
      'tenth',
    ]

    const sectionContent = (
      <div>
        {[...this.props.selections].map((i, idx) => {
          const numberLabel = numberLabels[idx] || ''
          const selectedValues = i
            ? [
                {
                  value: i.id,
                  label: i.name,
                },
              ]
            : []
          return (
            <div key={idx} className="search-category-item">
              <div className="search-category-item-header">
                {numberLabel.toUpperCase()} INDUSTRY
              </div>
              <EntityInput
                entityType="industry"
                onChange={values => this.handleSearchInputChange(idx, values)}
                optionClassName="profile-builder-entity-option"
                selectedValues={selectedValues}
                maxValues={1}
              />
            </div>
          )
        })}
      </div>
    )

    return (
      <div className="builder-content-body-container">
        <div className="builder-content-body">
          <p>
            {process.env.PRODUCT_NAME} provides an interactive overview of an
            industry's health including top stories for companies within that
            industry. Enter up to three industries that you'd like to track.
            You'll be able to add more industries in the platform.
          </p>

          <div>{sectionContent}</div>
        </div>
      </div>
    )
  }

  handleSearchInputChange(idx, values) {
    // we only allow one value per input
    const id = values.length ? values[0].value : null
    const selections = [...this.props.selectedIndustryIds]
    selections[idx] = id
    this.props.setSelectedIndustryIds(selections)
  }
}

export default connect(
  createSelector(
    [getEntities, getState],
    (entities, profileBuilder) => {
      const orm = Orm.withEntities(entities)

      return {
        selectedIndustryIds: profileBuilder.selectedIndustryIds,

        selections: orm.getByIds(Industry, profileBuilder.selectedIndustryIds),
      }
    },
  ),
  {
    setSelectedIndustryIds: profileBuilderActions.setSelectedIndustryIds,
  },
)(Industries)
