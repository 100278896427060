import {createAction} from 'redux-actions'

const action = name => createAction(`industries/${name}`)

export const setIndustryIds = action('SET_INDUSTRY_IDS')
export const setIsRefreshing = action('SET_IS_REFRESHING')
export const setGeography = action('SET_GEOGRAPHY')
export const setTableSort = action('SET_TABLE_SORT')
export const setIndustryNameFilter = action('SET_INDUSTRY_NAME_FILTER')
export const setCompanyNameFilter = action('SET_COMPANY_NAME_FILTER')
export const setShouldShowOnlyMyIndustries = action(
  'SET_SHOULD_ONLY_SHOW_MY_INDUSTRIES',
)
