import {takeLatest} from 'redux-saga/effects'

import {fetchStories} from 'app/api/api-saga-helpers'

import * as actions from './story-modal-actions'

function* modalShown({payload: {storyId}}) {
  if (storyId) {
    yield* fetchStories({storyIds: [storyId]})
  } else {
    throw new Error(
      'A `storyId` was not given in the payload for action `story-modal-actions/showModal`.',
    )
  }
}

export default function* storylineModalSaga() {
  yield takeLatest(actions.showModal, modalShown)
}
