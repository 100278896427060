import Promise from 'bluebird'
import {render} from 'react-dom'

import 'app/error-tracking'
import 'app/third-party/google-analytics'
import 'app/third-party/pendo'

import 'styles/global.less'

// Configure Bluebird
Promise.config({
  warnings: process.env.NODE_ENV !== 'production',
  longStackTraces: true,
  cancellation: true,
})

function renderApp() {
  const container = document.getElementById('wrapper')
  const {createApp} = require('app')
  const store = require('app/store').default
  const component = createApp(store)
  render(component, container)
}

function init() {
  renderApp()

  if (module.hot) {
    module.hot.accept('app', () => {
      renderApp()
    })
  }
}

init()
