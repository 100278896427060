import classNames from 'classnames'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import {addIndex, map, path, pipe, pluck, prop, reverse, sortBy} from 'ramda'
import React, {PureComponent} from 'react'
import {findDOMNode} from 'react-dom'
import {connect} from 'react-redux'

import {actions, selectors} from 'app/overviews/company'
import CloseButton from 'app/common/CloseButton'
import FactorIcon from 'app/common/FactorIcon'
import InfoTooltip from 'app/common/InfoTooltip'
import NavigationLink from 'app/common/NavigationLink'
import InsightSummary from 'app/common/insight-summary'
import TextSeparator from 'app/common/TextSeparator'
import Orm from 'app/framework/Orm'
import Story from 'app/models/Story'
import * as strings from 'app/strings'
import urls from 'app/urls'

import * as styles from './styles.less'

const STORYLINE_DISPLAY_COUNT = 3

class StoriesTooltip extends PureComponent {
  static propTypes = {
    shouldShow: PropTypes.bool.isRequired,
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
    date: PropTypes.object,
    colors: PropTypes.arrayOf(PropTypes.string),
    stories: PropTypes.arrayOf(PropTypes.object),
    isExpanded: PropTypes.bool,

    // Actions
    showStoriesTooltip: PropTypes.func.isRequired,
    expandStoriesTooltip: PropTypes.func.isRequired,
    hideStoriesTooltip: PropTypes.func.isRequired,
  }

  render() {
    const {
      shouldShow,
      left,
      right,
      top,
      date,
      colors,
      stories,
      isExpanded,

      // Actions
      hideStoriesTooltip,
    } = this.props

    if (!shouldShow) return null

    const mapWithIndex = addIndex(map)
    const dataSorted = pipe(
      mapWithIndex((story, index) => ({story, color: colors[index]})),
      sortBy(path(['story', 'articleCount'])),
      reverse,
    )(stories)
    const storiesSorted = pluck('story', dataSorted)
    const colorsSorted = pluck('color', dataSorted)

    const content = isExpanded ? (
      <StoryTooltipContentExpanded
        stories={storiesSorted}
        colors={colorsSorted}
        date={date}
        hideStoriesTooltip={hideStoriesTooltip}
      />
    ) : (
      <StoryTooltipContent
        stories={storiesSorted}
        colors={colorsSorted}
        date={date}
      />
    )

    return (
      <InfoTooltip
        left={left}
        right={right}
        top={top}
        contentClassName={classNames(styles.storiesTooltip, {
          [styles.expanded]: isExpanded,
        })}
        onClick={bind(this.onClick, this)}
        onMouseEnter={bind(this.onMouseEnter, this)}
        onMouseLeave={bind(this.onMouseLeave, this)}
      >
        {content}
      </InfoTooltip>
    )
  }

  componentWillReceiveProps(nextProps) {
    const {props} = this
    if (nextProps.isExpanded !== props.isExpanded) {
      if (nextProps.isExpanded) {
        document.addEventListener('click', bind(this.handleGlobalClick, this))
      } else {
        document.removeEventListener(
          'click',
          bind(this.handleGlobalClick, this),
        )
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', bind(this.handleGlobalClick, this))
  }

  onClick() {
    if (!this.props.isExpanded) {
      this.props.expandStoriesTooltip()
    }
  }

  onMouseEnter() {
    if (!this.props.isExpanded) {
      const {left, right, top, date, colors, stories} = this.props
      const storyIds = stories.map(prop('id'))
      this.props.showStoriesTooltip({top, left, right, date, colors, storyIds})
    }
  }

  onMouseLeave() {
    if (!this.props.isExpanded) {
      this.props.hideStoriesTooltip()
    }
  }

  handleGlobalClick(event) {
    // Hide the renderMenu if the user clicks outside of it
    let rootNode
    try {
      rootNode = findDOMNode(this)
    } catch (e) {
      // findDOMNode will error if the component has been unmounted, so we just
      // return early if that's the case.
      return
    }
    if (!rootNode.contains(event.target)) {
      this.props.hideStoriesTooltip()
    }
  }
}
export default connect(
  state => {
    const timeline = selectors.getCompanyOverviewTimeline(state)
    const {showStoriesTooltip} = timeline
    const orm = Orm.withEntities(state.entities)
    return {
      shouldShow: showStoriesTooltip.isVisible,
      left: showStoriesTooltip.left,
      right: showStoriesTooltip.right,
      top: showStoriesTooltip.top,
      date: showStoriesTooltip.date,
      colors: showStoriesTooltip.colors,
      stories:
        showStoriesTooltip.storyIds &&
        orm.getByIds(Story, showStoriesTooltip.storyIds),
      isExpanded: showStoriesTooltip.isExpanded,
    }
  },
  {
    showStoriesTooltip: actions.timeline.showStoriesTooltip,
    expandStoriesTooltip: actions.timeline.expandStoriesTooltip,
    hideStoriesTooltip: actions.timeline.hideStoriesTooltip,
  },
)(StoriesTooltip)

function StoryTooltipContent({stories, colors, date}) {
  const displayStories = stories.slice(0, STORYLINE_DISPLAY_COUNT)
  const storySections = displayStories.map((story, index) => (
    <StoryPreview story={story} color={colors[index]} key={story.id} />
  ))
  const seeAllStoriesLink =
    stories.length > STORYLINE_DISPLAY_COUNT ? (
      <a className={styles.moreStories}>Click to see all stories</a>
    ) : null
  return (
    <div>
      <h3>
        {strings.storyCount(stories.length)} on {strings.formatDate(date)} UTC
        <a className={styles.moreInfo}>Click for more info</a>
      </h3>
      <div className={styles.stories}>{storySections}</div>
      {seeAllStoriesLink}
    </div>
  )
}

class StoryTooltipContentExpanded extends React.PureComponent {
  render() {
    const {stories, colors, date, hideStoriesTooltip} = this.props
    const storySections = stories.map((story, index) => (
      <StoryExpanded
        story={story}
        color={colors[index]}
        hideStoriesTooltip={hideStoriesTooltip}
        key={story.id}
      />
    ))
    return (
      <div>
        <h3>
          {strings.storyCount(stories.length)} on {strings.formatDate(date)}
          <CloseButton
            className={styles.closeButton}
            onClick={bind(this.hideTooltip, this)}
          />
        </h3>
        <div className={styles.stories}>{storySections}</div>
      </div>
    )
  }

  hideTooltip() {
    this.props.hideStoriesTooltip()
  }
}

function StoryPreview({story, color}) {
  return (
    <div className={styles.storyContainer}>
      <div className={styles.story} style={{borderLeftColor: color}}>
        <FactorIcon
          factor={story.factor}
          valence={story.valence}
          className={styles.factorIcon}
        />
        <span className={styles.headline}>{story.summary}</span>
      </div>
    </div>
  )
}

class StoryExpanded extends React.PureComponent {
  render() {
    const {story, color} = this.props
    return (
      <NavigationLink
        href={urls.storylineView(story.storylineId)}
        className={styles.storyContainer}
        onClick={bind(this.hideTooltip, this)}
      >
        <div className={styles.story} style={{borderLeftColor: color}}>
          <InsightSummary
            factor={story.factor}
            subfactor={story.subfactor}
            valence={story.valence}
          />

          <h2 className={styles.headline}>{story.summary}</h2>

          <div className={styles.foot}>
            <span className={styles.articleCount}>
              {strings.articleCount(story.articleCount)}
            </span>
            <TextSeparator />
            <span className={styles.viewStoryline}>
              View Storyline for This Story
            </span>
          </div>
        </div>
      </NavigationLink>
    )
  }

  hideTooltip() {
    this.props.hideStoriesTooltip()
  }
}
