import React from 'react'
import Row from 'email-server-common/common/Row'
import Cell from 'email-server-common/common/Cell'
import Table from 'email-server-common/common/Table'
import EsgScore from './EsgScore'
import urls, { toAbsoluteUrl, USRC_VALUES, withSendGridTracking } from '../../app/urls'
import Link from '../../email-server-common/common/Link'
import { ESG_PILLAR_IDS_BY_ESG_PILLAR, ESG_PILLARS } from '../../app/constants'
import EntityIcon from './EntityIcon'
import { colors } from './constants'


export default function EsgEntityOverview({ entityType, emailType, data, rootUrl }) {
  const entityNames = {
    company: 'Companies',
    industry: 'Industries',
  }

  const commonStyles = {
    color: colors.secondaryFontColor,
    fontSize: 12,
    textAlign: 'center',
    paddingBottom: 10,
    borderBottom: `1px solid ${colors.borderColor}`,
  }

  return (
    <Table
      style={{
        borderCollapse: 'collapse',
      }}
    >
      {emailType === 'daily' &&
        <Row
          style={{
            margin: '0 0 15px 0',
          }}
        >
          <Cell width='10'/>
          <Cell style={{ ...commonStyles, textAlign: 'left' }} width='40'>
            <EntityIcon entityType={entityType} width='22'/>
          </Cell>
          <Cell
            width='150'
            style={{
            ...commonStyles,
            color: colors.defaultFontColor,
            width: '30%',
            textAlign: 'left',
            letterSpacing: 1,
            textTransform: 'uppercase',
            fontFamily: 'Source Sans Pro',
            fontWeight: 'bold',
          }}
          >
            {entityNames[entityType]}
          </Cell>
          <Cell style={commonStyles}>{entityType === 'company' ? 'Governance' : ''}</Cell>
          <Cell style={commonStyles}>{entityType === 'company' ? 'Prosperity' : ''}</Cell>
          <Cell style={commonStyles}>{entityType === 'company' ? 'People' : ''}</Cell>
          <Cell style={commonStyles}>{entityType === 'company' ? 'Planet' : ''}</Cell>
          <Cell
            width='135'
            style={{
              color: colors.defaultFontColor,
              fontSize: 12,
              fontWeight: 800,
              width: '23%',
              textAlign: 'center',
              paddingBottom: 10,
              borderBottom: `1px solid ${colors.borderColor}`,
            }}
          >
            {entityType === 'company' ? 'ESG Health Score' : ''}
          </Cell>
          <Cell width='15' style={commonStyles}/>
        </Row>
      }
      {emailType === 'weekly' &&
        <Row>
          <Cell width='10'/>
          <Cell style={{...commonStyles, textAlign: 'left'}} width='40'>
            <EntityIcon entityType={entityType} width='22'/>
          </Cell>
          <Cell
            width='150'
            style={{
              ...commonStyles,
              color: colors.defaultFontColor,
              width: '30%',
              textAlign: 'left',
              letterSpacing: 1,
              textTransform: 'uppercase',
              fontFamily: 'Source Sans Pro',
              fontWeight: 'bold',
            }}
          >
            {entityNames[entityType]}
          </Cell>
          <Cell width={'42'} style={commonStyles}> </Cell>
          <Cell width={'42'} style={commonStyles}> </Cell>
          <Cell
            width='135'
            style={{
              color: colors.defaultFontColor,
              fontSize: 12,
              fontWeight: 800,
              width: '23%',
              textAlign: 'center',
              paddingBottom: 10,
              borderBottom: `1px solid ${colors.borderColor}`,
            }}>ESG Health Score
          </Cell>
          <Cell
            width='135'
            style={{
              color: colors.defaultFontColor,
              fontSize: 12,
              fontWeight: 800,
              width: '23%',
              textAlign: 'center',
              paddingBottom: 10,
              borderBottom: `1px solid ${colors.borderColor}`,
            }}
          >
            ESG Score Range
          </Cell>
          <Cell width='15' style={commonStyles}/>
        </Row>
      }
      {data && data.length > 0 ? EsgEntityOverviewRow({ data, emailType, entityType, rootUrl })
        :
        <Row>
          <Cell colSpan='9' style={{ textAlign: 'center', paddingTop: 20 }}>
            No {entityNames[entityType]} have been saved.
          </Cell>
        </Row>}
    </Table>
  )
}


/**
 * Display a row of data with Industry/Company name and the scores for each pillar and the ESG score for the entity.
 * @param data
 * @param entityType
 * @param rootUrl
 * @param emailType
 */
function EsgEntityOverviewRow({ entityType, emailType, data, rootUrl }) {
  return (
    data.map((entity, index) => {
      const style = {
        textAlign: 'center',
        backgroundColor: index % 2 === 0 ? colors.contentBackgroundColor : colors.stripColor
      }
      const getEsgScore = factorId => {
        return entity.healthBadge.factors.find(factor => factor.factorId === factorId) ?
          <EsgScore score={entity.healthBadge.factors.find(factor => factor.factorId === factorId).healthScore}/> : '-'
      }
      const esgScores = {
        governance: getEsgScore(ESG_PILLAR_IDS_BY_ESG_PILLAR[ESG_PILLARS.GOVERNANCE]),
        prosperity: getEsgScore(ESG_PILLAR_IDS_BY_ESG_PILLAR[ESG_PILLARS.PROSPERITY]),
        people: getEsgScore(ESG_PILLAR_IDS_BY_ESG_PILLAR[ESG_PILLARS.PEOPLE]),
        planet: getEsgScore(ESG_PILLAR_IDS_BY_ESG_PILLAR[ESG_PILLARS.PLANET]),
      }
      const entityLink = entityType === 'company' ?
        withSendGridTracking(
          toAbsoluteUrl(urls.pyapp.esgCompany({
            companyId: entity.userSavedsearchId,
            source: USRC_VALUES.ESG_EMAIL
          }), {
            root: rootUrl,
          }),
        )
        :
        withSendGridTracking(
          toAbsoluteUrl(urls.pyapp.esgIndustry({
            industryId: entity.industryId,
            source: USRC_VALUES.ESG_EMAIL
          }), {
            root: rootUrl,
          }),
        )
      return (
        emailType === 'daily' ?
          <Row
            key={entity.id}
            style={{
              verticalAlign: 'middle',
            }}
            height='48'
          >
            <Cell style={style}/>
            <Cell style={{ ...style, textAlign: 'left' }} colSpan='2'>
              <Link
                href={entityLink}
                target='_blank'
                style={{
                  color: colors.linkColor,
                  fontWeight: 700,
                  verticalAlign: 'middle',
                }}
              >
                {entity.displayName}
              </Link>
            </Cell>
            <Cell style={style}>{esgScores.governance}</Cell>
            <Cell style={style}>{esgScores.prosperity}</Cell>
            <Cell style={style}>{esgScores.people}</Cell>
            <Cell style={style}>{esgScores.planet}</Cell>
            <Cell style={{ ...style, fontSize: 24, textAlign: 'right', paddingRight: 17 }}>
              {entity.healthBadge.healthScore ? <EsgScore score={entity.healthBadge.healthScore}/> : '-'}
            </Cell>
            <Cell width={'19'}/>
          </Row>
        :
        <Row
            key={entity.id}
            style={{
              verticalAlign: 'middle',
            }}
            height='48'
        >
          <Cell style={style}/>
          <Cell style={{ ...style, textAlign: 'left' }} colSpan='2'>
            <Link
              href={entityLink}
              target='_blank'
              style={{
                color: colors.linkColor,
                fontWeight: 700,
                verticalAlign: 'middle',
              }}
            >
              {entity.displayName}
            </Link>
          </Cell>
          <Cell style={{ ...style, fontSize: 24, textAlign: 'right', paddingRight: 17 }}/>
          <Cell style={{ ...style, fontSize: 24, textAlign: 'right', paddingRight: 17 }}/>
          <Cell style={{ ...style, fontSize: 24, textAlign: 'right', paddingRight: 17 }}>
            {entity.healthBadge.healthScore ? <EsgScore score={entity.healthBadge.healthScore}/> : '-'}
          </Cell>
          <Cell style={{...style, fontSize: 24, textAlign: 'right', paddingRight: 17}}>
              {entity.esgMinScore ?  <EsgScore score={entity.esgMinScore}/> : ''}
              {entity.esgMinScore && entity.esgMaxScore ? ' to ' : '-'}
              {entity.esgMaxScore ? <EsgScore score={entity.esgMaxScore}/> : ''}
          </Cell>
          <Cell width={'19'}/>
        </Row>
      )
    })
  )
}
