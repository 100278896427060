import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import moment from 'moment'
import { createSelector } from 'reselect'

import Button from 'app/common/Button'
import ManzamaLogo from 'app/common/logos/manzama-logo'
import * as entitiesSelectors from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'

import { LitigationNumberSummary } from 'app/models'
import Chart from 'app/litigation/Chart'
import * as selectors from 'app/litigation/litigations-selectors'
import * as actions from 'app/litigation/litigations-actions'
import * as styles from './styles.less'
import * as printStyles from './Report.less'
import LoadingSpinner from 'app/common/loading-spinner'
import { FILTER_CHECKBOXES } from './litigations-constants'
import InlineSvg from '../common/InlineSvg/InlineSvg'
import DiligentLogo from './icons/DiligentLogo'


const NUMBER_OF_SECTORS_PER_PAGE = 21

export default function LitigationMonitoringReport() {
  const dispatch = useDispatch()
  const entities = useSelector(entitiesSelectors.getEntities)
  const mainPage = useSelector(selectors.getMainPage)
  const sectorPage = useSelector(selectors.getSectorPage)
  const isDownloadingPdf = useSelector(selectors.isDownloadingPdf)

  const { sectors, selectedSectors: selectedSectorIds, companyCountMinimum, companyCountMaximum,
    litigationAffectedCompaniesMinimum, litigationAffectedCompaniesMaximum,
    selectedFilter, isLoading, allSectors, mySectors, myCompanies, selectedCompanyIds } = mainPage

  const selectedCompanies = myCompanies.filter(com => selectedCompanyIds.includes(com.id))
  const companiesToShow = selectedCompanyIds.length > 0 ? selectedCompanies : myCompanies
  const showCompany = selectedFilter === FILTER_CHECKBOXES.myCompanies

  const orm = Orm.withEntities(entities)
  const sectorEntities = sectors && orm.getByIds(LitigationNumberSummary, sectors).filter(sector => sector !== undefined)
  const selectedSectors = selectedSectorIds && orm.getByIds(LitigationNumberSummary, selectedSectorIds)

  const filteredSectors = showCompany ? companiesToShow
    : sectorEntities.filter(sector =>
    (sector.industrySize >= companyCountMinimum
      && sector.industrySize <= companyCountMaximum
      && sector.litigationCompanyProp >= (litigationAffectedCompaniesMinimum / 100)
      && sector.litigationCompanyProp <= (litigationAffectedCompaniesMaximum / 100)
      && (selectedSectors.length === 0
        || !!selectedSectors.find(selectedSector => selectedSector.id === sector.id))
    ))

  return (
    <SectorLitigationPage
      sectors={sectorEntities}
      selectedCompanies={companiesToShow}
      filteredSectors={filteredSectors}
      isLoading={isLoading}
      isDownloadingPdf={isDownloadingPdf}
      downloadPdf={(payload) => dispatch(actions.startDownloadPdf(payload))}
      showCompany={showCompany}
    />
  )
}

function SectorLitigationPage(
  {
    sectors,
    selectedCompanies,
    filteredSectors,
    isLoading,
    isDownloadingPdf,
    downloadPdf,
    showCompany
  }) {
  const sumStoryCount = useMemo(() => sectors.map(sector => sector.litigationStoryCount).reduce((x, y) => x + y, 0), [sectors])

  const renderChart = () => {
    const segmentedFilteredSectors = []
    const filteredSortedSectors = filteredSectors.sort((a, b) => {
      if (a.litigationStoryCount < b.litigationStoryCount) {
        return 1
      } else if (a.litigationStoryCount > b.litigationStoryCount) {
        return -1
      }
      return 0
    })
    const maxValue = filteredSortedSectors[0] ? filteredSortedSectors[0].litigationStoryCount : 0

    if (filteredSortedSectors && filteredSortedSectors.length > 0) {
      for (let i = 0; i < Math.ceil(filteredSortedSectors.length / NUMBER_OF_SECTORS_PER_PAGE); i++) {
        segmentedFilteredSectors.push(
          filteredSortedSectors.slice(i * NUMBER_OF_SECTORS_PER_PAGE, NUMBER_OF_SECTORS_PER_PAGE + i * NUMBER_OF_SECTORS_PER_PAGE)
        )
      }
    }

    return (
      <>
        {segmentedFilteredSectors.map((segmentedFilteredSector, index) => (
          <tr key={`segment-${segmentedFilteredSector.id}-${index}`}>
            <td>
              <div className={printStyles.contentContainer}>
                <Chart
                  sectors={segmentedFilteredSector}
                  selectedCompanies={selectedCompanies}
                  isLoading={isLoading}
                  showHeader={false}
                  hideShowAll={true}
                  xAxisDomain={[0, maxValue + 100]}
                />
              </div>
            </td>
          </tr>
        ))}
      </>
    )
  }
  const renderIndustryTable = () => {
   return (
    <tr>
    <td>
      <hr className={printStyles.sectionSeparator}/>

      <div className={printStyles.contentContainer}>
        <div className={printStyles.sectionTitle}>
          NUMBER OF COMPANIES PER SECTOR - DATA
        </div>
        <table className={printStyles.dataTable}>
          <thead>
          <tr>
            <th>
              Sector
            </th>
            <th>
              Company Count <sup>1</sup>
            </th>
            <th>
              Companies-to-Sector Size <sup>2</sup>
            </th>
            <th>
              Number of Litigation-Related Stories
            </th>
          </tr>
          </thead>
          <tbody>
          {filteredSectors.map((sector, index) => {
            return (
              <tr key={`sector-${sector.id}-${index}`}>
                <td>{sector.name}</td>
                <td>{sector.litigationCompanyCount.toLocaleString()}</td>
                <td>{Math.round(sector.litigationCompanyProp * 100)}%</td>
                <td>{sector.litigationStoryCount.toLocaleString()}</td>
              </tr>
            )
          })}
          </tbody>
          <tfoot>
          <tr>
            <td>
              <div className={printStyles.tableFooter}/>
            </td>
            <td/>
            <td/>
            <td/>
          </tr>
          </tfoot>
        </table>
        <p className={printStyles.superscript}>
          <span>1</span> The number of companies mentioned in stories regarding litigation.
        </p>
        <p className={printStyles.superscript}>
          <span>2</span> Ratio of litigation-affected companies to all recorded companies in the sector
        </p>
      </div>
    </td>
  </tr>
   ) 
  }
  const renderCompanyTable = () => {
    return (
     <tr>
     <td>
       <hr className={printStyles.sectionSeparator}/>
 
       <div className={printStyles.contentContainer}>
         <div className={printStyles.sectionTitle}>
           NUMBER OF LITIGATION STORIES PER COMPANIES - DATA
         </div>
         <table className={printStyles.dataTable}>
           <thead>
           <tr>
             <th>
             Company
             </th>
             <th>
               Number of Litigation-Related Stories
             </th>
           </tr>
           </thead>
           <tbody>
           {selectedCompanies.map((company, index) => {
             return (
               <tr key={`company-${company.id}-${index}`}>
                 <td>{company.name}</td>
                 <td>{company.litigationStoryCount.toLocaleString()}</td>
               </tr>
             )
           })}
           </tbody>
           <tfoot>
           <tr>
             <td>
               <div className={printStyles.tableFooter}/>
             </td>
             <td/>
             <td/>
             <td/>
           </tr>
           </tfoot>
         </table>
       </div>
     </td>
   </tr>
    ) 
  }

  const entityType = showCompany ? 'Companies' : 'Sectors'
  const pageTitle = `${entityType} Report`
  const docTitle =`${showCompany ? 'Companies' : 'Sectors (Industries)'} and Number of Litigation`
  const docName = `Litigation_${entityType.toLowerCase()}_report_${moment().format('YYYYMMDD_HHmm')}`

  return (
    <>
      {/* Inline these styles, because otherwise they would apply to the entire SPA. */}
      <style>
        {`
        @media print {
          html, body {
            height: 297mm;
            width: 210mm;
            print-color-adjust: exact;
            -webkit-print-color-adjust: exact;
          }
          
          @page {
            margin: 0 0 0 0;        
          }
        }
        `}
      </style>
      <div className={printStyles.topBar}>
        <div className={printStyles.inner}>
          <div className={printStyles.label}>
            {process.env.BRAND_NAME} {process.env.PRODUCT_NAME} {pageTitle}
            {isLoading ? <LoadingSpinner className={printStyles.spinner}/> : null}
          </div>

          <div className={printStyles.buttons}>
            <Button
              label={isDownloadingPdf ? 'Downloading...' : 'Download PDF'}
              isPrimary={true}
              isDisabled={isDownloadingPdf}
              onClick={() => downloadPdf()}
            />
          </div>
        </div>
      </div>
      <div className={printStyles.container}>
        <div className={printStyles.coverContainer}>
          <div className={printStyles.cover}>
            <div className={printStyles.backgroundImage}/>
            <div className={printStyles.header}>
              <DiligentLogo/>
              <div className={printStyles.headerInfo}>
                <p>{docName}</p>
                <p>exported at: {`${moment().format('L')}`}</p>
              </div>
            </div>
            <div className={printStyles.anchorToBottom}>
              <div className={styles.storyCountContainer}>
                <h2>{sumStoryCount.toLocaleString()}</h2>
                <span>TOTAL ARTICLES PROCESSED</span>
              </div>
              <div className={printStyles.spacer}/>
              <h1>{docTitle} <br/>Stories</h1>
            </div>
          </div>
        </div>
        <table className={printStyles.formatTable}>
          <thead>
          <tr>
            <td>
              <div className={printStyles.tableHeader}>
                <DiligentLogo/>
                <div className={printStyles.headerInfo}>
                  <p>{docName}</p>
                  <p>exported at: {`${moment().format('L')}`}</p>
                </div>
              </div>
            </td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td className={printStyles.formatTable}>
              <hr className={printStyles.sectionSeparator}/>
              <div className={classNames(printStyles.contentContainer, printStyles.withoutSideMargins)}>
                <div className={printStyles.sectionTitle}>
                  NUMBER OF COMPANIES PER SECTOR - CHART
                </div>
              </div>
            </td>
          </tr>
          {renderChart()}
          {showCompany ? renderCompanyTable() : renderIndustryTable()}
          </tbody>
        </table>
      </div>
      <div className={printStyles.footerLine}/>
    </>
  )
}
