import React from 'react'
import PropTypes from 'prop-types'

import NumberBox from 'app/common/NumberBox'
import { Tooltip } from 'app/common/tooltip'
import QuestionMark from 'app/litigation/icons/QuestionMark'
import * as styles from './RangeGroup.less'

/**
 * Component which displays a group of elements that allows you to accept value between a range.
 * Supports multiple unit types.
 *
 * @param label The label to display above the input fields.
 * @param tooltip Text to display when hovering over the `i` icon.
 * @param minimumValue The value of the minimum field.
 * @param maximumValue The value of the maximum field.
 * @param minimumValueChanged Function to call when the minimum value changed to a valid value.
 * @param maximumValueChanged Function to call when the maximum value changed to a valid value.
 * @param minimumLimit The minimum allowed value that can be entered in either field.
 * @param maximumLimit The maximum allowed value that can be entered in either field.
 * @param unitType The type of unit to display. Defaults to {@link RangeGroup.UnitTypes.NUMBER}. See {@link RangeGroup.UnitTypes}.
 */
export default function RangeGroup(
  {
    label,
    tooltip,
    minimumValue,
    maximumValue,
    minimumValueChanged,
    maximumValueChanged,
    minimumLimit,
    maximumLimit,
    unitType
  }) {
  const onMinimumValueChanged = (value) => {
    if (value > maximumValue) {
      value = maximumValue
    }
    minimumValueChanged(value)
  }

  const onMaximumValueChanged = (value) => {
    if (value < minimumValue) {
      value = minimumValue
    }
    maximumValueChanged(value)
  }

  return (
    <>
      <label className={styles.label}>{label}
        {tooltip &&
          <Tooltip label={tooltip} direction={'left'}>
            <QuestionMark className={styles.inlineIcon}/>
          </Tooltip>}
      </label>

      <div className={styles.rangeInputContainer}>
        <div className={styles.rangeInput}>
          <label className={styles.rangeInputLabel}>Min</label>
          <NumberBox
            value={minimumValue}
            min={minimumLimit}
            max={Math.min(maximumLimit, maximumValue)}
            onChange={onMinimumValueChanged}
            percentage={unitType === RangeGroup.UnitTypes.PERCENTAGE}
            thousandSeparator={unitType === RangeGroup.UnitTypes.NUMBER}
            className={styles.textBox}
          />
        </div>
        <span className={styles.middleText}>to</span>
        <div className={styles.rangeInput}>
          <label className={styles.rangeInputLabel}>Max</label>
          <NumberBox
            value={maximumValue}
            min={Math.max(minimumLimit, minimumValue)}
            max={maximumLimit}
            onChange={onMaximumValueChanged}
            percentage={unitType === RangeGroup.UnitTypes.PERCENTAGE}
            thousandSeparator={unitType === RangeGroup.UnitTypes.NUMBER}
            className={styles.textBox}
          />
        </div>
      </div>
    </>
  )
}

RangeGroup.UnitTypes = Object.freeze({ NUMBER: 1, PERCENTAGE: 2 })

RangeGroup.propTypes = {
  label: PropTypes.string.isRequired,
  minimumLimit: PropTypes.number.isRequired,
  maximumLimit: PropTypes.number.isRequired,
  unitType: PropTypes.oneOf(Object.values(RangeGroup.UnitTypes)),
}

RangeGroup.defaultProps = {
  unitType: RangeGroup.UnitTypes.NUMBER,
}
