import classNames from 'classnames'
import bind from 'memoize-bind'
import {fromPairs, identity, map, path, pipe, take} from 'ramda'
import React from 'react'

import HealthBar from 'app/common/HealthBar'
import HealthScore from 'app/common/HealthScore'
import Link from 'app/common/NavigationLink'
import {ORDERED_FACTORS, SORT_DIRECTIONS} from 'app/constants'
import MyCompanyStar from 'app/reusable/companies/my-company-star'
import {factorDisplayName} from 'app/strings'
import {hasHealthData} from 'app/utils/health'
import urls from 'app/urls'

import CompanyDetails from './CompanyDetails'

import * as styles from './CompaniesTable.less'

const factorHealthScore = (company, factor) =>
  company.healthData && company.healthData.factors[factor]
    ? company.healthData.factors[factor].healthScore
    : undefined

export const sortByFunctionsByColumn = {
  companyName: path(['company', 'name']),
  health: ({company}) =>
    hasHealthData(company) ? company.healthData.healthScore : undefined,
  volume: ({company}) =>
    company.healthData ? company.healthData.articleVolume : 0,

  // For each factor, sort by its factor health score.
  ...pipe(
    map(factor => [factor, ({company}) => factorHealthScore(company, factor)]),
    fromPairs,
  )(ORDERED_FACTORS),
}

const factorColumn = (factor, index) => ({
  name: factor,
  label: factorDisplayName(factor),
  className: ({company}) =>
    index === 0 && !hasHealthData(company) ? styles.noFactorData : undefined,
  baseWidth: 120,
  shrinkRatio: 0,
  cellContents: ({company}) =>
    hasHealthData(company) ? (
      <HealthScore score={factorHealthScore(company, factor) || 0} />
    ) : (
      <div className={styles.noFactorData}>
        {company.healthData ? 'Insufficient' : 'No'} data to calculate factor
        health scores.
      </div>
    ),
  colSpan: ({company}) =>
    index === 0 && !hasHealthData(company) ? ORDERED_FACTORS.length : 1,
  isSortable: true,
})

export const getColumns = ({
  geography,
  shouldShowCompanyDetails,
  isPortalPage = false,
  onArrowClick,
  expandIndustries,
  collapseIndustries,
}) => [
  {
    name: 'companyName',
    label: 'Company Name',
    baseWidth: 200,
    growRatio: 3,
    shrinkRatio: 0,
    isSortable: true,
    colSpan: obj => (obj.isDetailRow ? 10 : 1),
    cellContents: ({company, isDetailRow, hasDetailsOpen}) =>
      isDetailRow ? (
        <CompanyDetails company={company} />
      ) : (
        <div className={styles.contents}>
          {shouldShowCompanyDetails && (
            <span
              className={classNames(styles.arrow, 'arrow', {
                [styles.open]: hasDetailsOpen,
                [styles.hidden]: !company.healthData,
              })}
              onClick={bind(onArrowClick, null, company.id)}
            />
          )}
          <span className={styles.label}>
            <Link
              href={(isPortalPage
                ? urls.companyOverviewPortal
                : urls.companyOverview)(company.id, {
                geography: geography || undefined,
              })}
            >
              {company.name}
            </Link>
          </span>
          <MyCompanyStar
            company={company}
            className={classNames(styles.star, 'star')}
          />
        </div>
      ),
  },
  {
    name: 'health',
    label: 'Health Score',
    baseWidth: 120,
    shrinkRatio: 0,
    isSortable: true,
    cellContents: ({company}) =>
      hasHealthData(company) ? (
        <span className={styles.healthContainer}>
          <HealthScore
            score={company.healthData.healthScore}
            className={styles.healthScore}
          />
          <HealthBar
            healthData={company.healthData}
            className={styles.healthBar}
          />
        </span>
      ) : (
        <div className={styles.noScore}>No Score</div>
      ),
  },
  {
    name: 'industries',
    label: 'Industries',
    baseWidth: 120,
    growRatio: 2,
    shrinkRatio: 0,
    cellContents: ({company, areIndustriesExpanded}) => {
      const MAX_INDUSTRIES_TO_SHOW = 3
      const renderIndustry = industry => (
        <Link
          href={
            isPortalPage
              ? urls.industryOverviewPortal(industry.id)
              : urls.industryOverview(industry.id)
          }
          className={styles.item}
          key={industry.id}
        >
          {industry.name}
        </Link>
      )
      if (company.industries.length <= MAX_INDUSTRIES_TO_SHOW) {
        return company.industries.map(renderIndustry)
      }
      const renderedIndustries = pipe(
        areIndustriesExpanded ? identity : take(MAX_INDUSTRIES_TO_SHOW),
        map(renderIndustry),
      )(company.industries)
      const link = areIndustriesExpanded ? (
        <a
          onClick={bind(collapseIndustries, null, company.id)}
          className={styles.item}
        >
          ...hide {company.industries.length - MAX_INDUSTRIES_TO_SHOW}{' '}
          industries
        </a>
      ) : (
        <a
          onClick={bind(expandIndustries, null, company.id)}
          className={styles.item}
        >
          ...and {company.industries.length - MAX_INDUSTRIES_TO_SHOW} more
        </a>
      )
      return (
        <React.Fragment>
          {renderedIndustries}
          {link}
        </React.Fragment>
      )
    },
  },
  {
    name: 'volume',
    label: 'Article Volume',
    baseWidth: 90,
    shrinkRatio: 0,
    isSortable: true,
    defaultSortDirection: SORT_DIRECTIONS.DESC,
    cellContents: ({company}) => company.healthData.articleVolume,
  },
  ...ORDERED_FACTORS.map((factor, index) => factorColumn(factor, index)),
]
