import routes from 'app/routes'

export const STATE_PROP_NAME = 'companyOverview'
export const TIMELINE_STATE_PROP_NAME = 'timeline'
export const SIM_CIRC_STATE_PROP_NAME = 'simCirc'
export const TIME_FRAME_HEADER = 'X-Signals-Since-Days'
export const GEOGRAPHY_FIELD_NAME = 'perspectiveId'

export const SORT_OPTIONS = {
  VOLUME: 'volume',
  DATE_DESC: 'date-desc',
  DATE_ASC: 'date-asc',
}
export const STORY_READER_MAX_STORY_COUNT = 20
export const MAX_HISTORICAL_STORY_COUNT = 100
export const QUARTER_QUERY_PARAM = 'quarter'

export const SIM_CIRC_STORY_COUNT = 5

export const OVERVIEW_ROUTES = [
  routes.companyOverview,
  routes.companyOverviewPortal,
]
export const HISTORY_ROUTES = [
  routes.companyHistory,
  routes.companyHistoryPortal,
]
export const SIMCIRC_ROUTES = [
  routes.companySimCirc,
  routes.companySimCircCurrent,
  routes.companySimCircForecast,
]
export const ALL_COMPANY_ROUTES = [
  ...OVERVIEW_ROUTES,
  ...HISTORY_ROUTES,
  ...SIMCIRC_ROUTES,
]
