import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import React from 'react'
import {Portal} from 'react-portal'

import Button from 'app/common/Button'
import Dropdown from 'app/common/Dropdown'
import InputBlock from 'app/common/InputBlock'
import Modal from 'app/common/Modal'
import RadioButton from 'app/common/RadioButton'
import {joinInSentence} from 'app/utils'

import {CONFIRMATION_STATUSES} from './my-entity-star-constants'

import * as styles from './EmailSettingsModal.less'

// TODO: Move this out of `my-entity-star`.
export default class EmailSettingsModal extends React.PureComponent {
  static propTypes = {
    entityNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    entityType: PropTypes.string.isRequired,
    confirmationStatus: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
  }

  state = {
    areAlertsEnabled: true,
    frequency: 'weekly',
  }

  render() {
    const {confirmationStatus, close} = this.props

    return (
      <Portal>
        <Modal onClose={close}>
          {confirmationStatus === CONFIRMATION_STATUSES.CONFIRMED
            ? this.renderConfirmedContents()
            : this.renderConfirmationContents()}
        </Modal>
      </Portal>
    )
  }

  renderConfirmationContents() {
    const {entityNames, entityType, confirmationStatus} = this.props
    const {areAlertsEnabled, frequency} = this.state

    const entityTypeLabelPlural =
      entityType === 'company' ? 'Companies' : 'Industries'
    const joinedEntityNames = joinInSentence(entityNames)
    const entityNameOrCount =
      entityNames.length === 1
        ? entityNames[0]
        : `${entityNames.length} ${entityTypeLabelPlural}`
    const isConfirming = confirmationStatus === CONFIRMATION_STATUSES.CONFIRMING

    return (
      <React.Fragment>
        <h2 className={styles.successMessage}>
          {joinedEntityNames} {entityNames.length === 1 ? 'has' : 'have'}{' '}
          successfully been added to your &ldquo;My {entityTypeLabelPlural}
          &rdquo; list.
        </h2>

        <h3 className={styles.question}>
          Do you want to turn on {process.env.PRODUCT_NAME} Email Alerts for{' '}
          {entityNameOrCount}?
        </h3>

        <form onSubmit={bind(this.onSubmit, this)}>
          <div className={styles.group}>
            <InputBlock
              label={`Yes. I want my ${
                process.env.PRODUCT_NAME
              } Email Alert sent`}
              isInline={true}
            >
              <RadioButton
                checked={areAlertsEnabled}
                onChange={bind(this.enableAlerts, this)}
              />
            </InputBlock>

            <Dropdown
              value={frequency}
              isDisabled={!areAlertsEnabled}
              onChange={bind(this.changeFrequency, this)}
              className={styles.dropdown}
            >
              <Dropdown.Option label="Weekly" value="weekly" />
              <Dropdown.Option label="Daily" value="daily" />
            </Dropdown>
          </div>

          <InputBlock
            label={`No. I will set up my ${
              process.env.PRODUCT_NAME
            } Email Alert settings later.`}
            isInline={true}
          >
            <RadioButton
              checked={!areAlertsEnabled}
              onChange={bind(this.disableAlerts, this)}
            />
          </InputBlock>

          <Button
            label={isConfirming ? 'Saving...' : 'Save Settings'}
            isPrimary
            isDisabled={isConfirming}
            className={styles.saveButton}
          />
        </form>
      </React.Fragment>
    )
  }

  renderConfirmedContents() {
    const {entityType} = this.props
    return (
      <h2 className={styles.confirmedMessage}>
        This {entityType} has been added successfully.
      </h2>
    )
  }

  enableAlerts() {
    this.setState({areAlertsEnabled: true})
  }

  disableAlerts() {
    this.setState({areAlertsEnabled: false})
  }

  changeFrequency(frequency) {
    this.setState({frequency})
  }

  onSubmit(event) {
    event.preventDefault()
    const {areAlertsEnabled, frequency} = this.state
    this.props.onSubmit({frequency: areAlertsEnabled ? frequency : 'none'})
  }
}
