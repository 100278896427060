import {action as baseAction} from '../company-overview-actions'

const action = name => baseAction(`simcirc/${name}`)

export const setIsLoading = action('SET_IS_LOADING')

export const setTargetDate = action('SET_TARGET_DATE')
export const setMatches = action('SET_MATCHES')
export const setComparedMatchId = action('SET_COMPARED_MATCH_ID')
export const selectSubfactor = action('SELECT_SUBFACTOR')

export const setAreStoriesLoading = action('SET_ARE_STORIES_LOADING')
export const setTargetStoryIds = action('SET_TARGET_STORY_IDS')
export const setMatchStoryIds = action('SET_MATCH_STORY_IDS')

// Forecast

export const setPredictions = action('SET_PREDICTIONS')
export const setPredictionFactor = action('SET_PREDICTION_FACTOR')
export const setLeadingHealthScores = action('SET_LEADING_HEALTH_SCORES')
export const setIsForecastTimelineLoading = action(
  'SET_IS_FORECAST_TIMELINE_LOADING',
)
export const setForecastTimeline = action('SET_FORECAST_TIMELINE')
export const openForecastStoriesModal = action('OPEN_FORECAST_STORIES_MODAL')
export const closeForecastStoriesModal = action('CLOSE_FORECAST_STORIES_MODAL')
export const setForecastModalStoryIds = action('SET_FORECAST_MODAL_STORY_IDS')
