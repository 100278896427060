import {all, takeLatest, takeEvery, call, put, delay} from 'redux-saga/effects'

import * as profileBuilderActions from './profile-builder-actions'
import {updateMyProfile} from 'app/login/login-api'
import {fetchCompanies} from 'app/api/api-saga-helpers'
import {SORT_DIRECTIONS} from 'app/constants'

function* handleFetchCompanies(action) {
  const response = yield yield* fetchCompanies({
    industryIds: [action.payload],
    orderBy: [
      {field: 'articleVolume', direction: SORT_DIRECTIONS.DESC, nulls: 'last'},
    ],
    limit: 6,
    excludeIndustries: true,
  })
  yield put(
    profileBuilderActions.setSuggestedCompanyIds({
      industryId: action.payload,
      companyIds: response.result,
    }),
  )
}

function* handleSaveUser(action) {
  yield updateMyProfile(action.payload.token, action.payload.data)
  // if searches were added, the user can now access Insights, otherwise redirect to Base.
  if (action.payload.data.searches.length) {
    window.location.href = '/'
  } else {
    window.location.href = `${process.env.BASE_APP_URL}/profile`
  }
}

function* handleSkip(action) {
  yield updateMyProfile(action.payload, {forceProfileBuilder: false})
  window.location.href = `${process.env.BASE_APP_URL}/profile`
}

export default function*() {
  yield all([
    takeEvery(profileBuilderActions.fetchCompanies, handleFetchCompanies),
    takeLatest(profileBuilderActions.saveUser, handleSaveUser),
    takeLatest(profileBuilderActions.skip, handleSkip),
  ])
}
