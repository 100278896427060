import is from 'is'
import {omit} from 'ramda'
import StackTrace from 'stacktrace-js'

import {pyappRequest} from 'app/api'
import * as jwt from 'app/jwt'
import urls from 'app/urls'
import changeCaseObject from 'app/utils/change-case-object'

const logErrorToPyapp = ({token, data}) => {
  return pyappRequest({
    url: urls.pyapp.api.frontendError(),
    method: 'POST',
    token,
    data: changeCaseObject.snakeCase(data),
    authType: 'jwt',
  })
}

export function logError(error) {
  if (process.env.NODE_ENV === 'production') {
    const token = jwt.getCurrentToken()
    if (!token) return
    StackTrace.fromError(error)
      .then(stackFrames => {
        const data = {
          message: error.message,
          stackFrames: stackFrames.map(frame => frame.toString()),
          url: window.location.href,
        }
        const normalErrorProps = [
          'message',
          'stack',
          'fileName',
          'lineNumber',
          'columnNumber',
        ]
        const additionalErrorData = omit(normalErrorProps, error)
        if (!is.empty(additionalErrorData)) {
          data.additionalErrorData = additionalErrorData
        }
        logErrorToPyapp({token, data})
      })
      .catch(parseError => {
        console.error(parseError)
        const data = {
          message: error.message,
          url: window.location.href,
        }
        logErrorToPyapp({token, data})
      })
  }
}

const oldErrorFunc = window.onerror
window.onerror = (messageOrEvent, source, lineNum, colNum, error) => {
  logError(error)
  if (oldErrorFunc) {
    oldErrorFunc(messageOrEvent, source, lineNum, colNum, error)
  }
}
