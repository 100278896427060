import {createAction as createGenericAction} from 'redux-actions'

const createAction = name => createGenericAction(`STORYLINE_MODAL_${name}`)

export const showModal = createAction('SHOW')
export const hideModal = createAction('HIDE')
export const setIsLoading = createAction('SET_IS_LOADING')
export const selectStory = createAction('SELECT_STORY')
export const deselectStory = createAction('DESELECT_STORY')
export const selectArticle = createAction('SELECT_ARTICLE')
