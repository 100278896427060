import {always} from 'ramda'

import {handleLocalActions} from 'app/utils/redux'

import * as actions from './industries-table-actions'

const getInitialState = () => ({
  detailsModalIndustryId: null,
})

export default handleLocalActions({
  [actions.init]: getInitialState,
  [actions.deinit]: always(undefined),
  [actions.showIndustryDetailsModal]: (state, action) => ({
    ...state,
    detailsModalIndustryId: action.payload.industryId,
  }),
  [actions.hideIndustryDetailsModal]: (state, action) => ({
    ...state,
    detailsModalIndustryId: getInitialState().detailsModalIndustryId,
  }),
})
