import React from 'react'

import Table from '../../email-server-common/common/Table'
import Row from '../../email-server-common/common/Row'
import Cell from '../../email-server-common/common/Cell'
import EsgScore from './EsgScore'
import { ESG_PILLAR_IDS_BY_ESG_PILLAR, ESG_PILLARS } from '../../app/constants'
import { colors } from './constants'
import EntityIcon from './EntityIcon'

export default function EsgPillarScores({ entity }) {

  const commonStyles = {
    color: colors.secondaryFontColor,
    fontSize: 12,
    textAlign: 'left',
    paddingBottom: 10,
    borderBottom: `1px solid ${colors.borderColor}`,
  }

  const getEsgScore = factorId => {
    return entity.healthBadge.factors.find(factor => factor.factorId === factorId) ?
      <EsgScore score={entity.healthBadge.factors.find(factor => factor.factorId === factorId).healthScore} /> : '-'
  }
  const esgScores = {
    governance: getEsgScore(ESG_PILLAR_IDS_BY_ESG_PILLAR[ESG_PILLARS.GOVERNANCE]),
    prosperity: getEsgScore(ESG_PILLAR_IDS_BY_ESG_PILLAR[ESG_PILLARS.PROSPERITY]),
    people: getEsgScore(ESG_PILLAR_IDS_BY_ESG_PILLAR[ESG_PILLARS.PEOPLE]),
    planet: getEsgScore(ESG_PILLAR_IDS_BY_ESG_PILLAR[ESG_PILLARS.PLANET]),
  }

  return (
    <Table width='100%'
      style={{
        borderCollapse: 'collapse',
      }}
    >
      <Row>
        <Cell width='10' />
        <Cell width='20' style={{...commonStyles, textAlign: 'left'}}>
          <EntityIcon entityType={'pillar'} width='22' />
        </Cell>
        <Cell
          width='150'
          style={{
            ...commonStyles,
            color: colors.defaultFontColor,
            letterSpacing: 1,
            textTransform: 'uppercase',
            fontFamily: 'Source Sans Pro',
            fontWeight: 'bold',
          }}
        >
          Pillars
        </Cell>
        <Cell
          width='135'
          style={{
            color: colors.defaultFontColor,
            fontSize: 12,
            fontWeight: 800,
            textAlign: 'right',
            paddingBottom: 10,
            paddingRight: 10,
            borderBottom: `1px solid ${colors.borderColor}`,
          }}>ESG Score
        </Cell>
      </Row>
      <Row style={{ verticalAlign: 'middle' }} height='48'>
        <Cell style={{ backgroundColor: colors.contentBackgroundColor }} />
        <Cell
          style={{
            textAlign: 'left',
            backgroundColor: colors.contentBackgroundColor,
            color: colors.linkColor,
            fontWeight: 700,
            verticalAlign: 'middle',
          }}
          colSpan='2'
        >
          Governance
        </Cell>
        <Cell
          style={{
            textAlign: 'right',
            backgroundColor: colors.contentBackgroundColor,
            paddingRight: '17px'
          }}>
          {esgScores.governance}
        </Cell>
      </Row>
      <Row style={{ verticalAlign: 'middle' }} height='48'>
        <Cell style={{ backgroundColor: colors.stripColor }} />
        <Cell
          style={{
            textAlign: 'left',
            backgroundColor: colors.stripColor,
            color: colors.linkColor,
            fontWeight: 700,
            verticalAlign: 'middle',
          }}
          colSpan='2'
        >
          Prosperity
        </Cell>
        <Cell
          style={{
            textAlign: 'right',
            backgroundColor: colors.stripColor,
            paddingRight: '17px'
          }}>
          {esgScores.prosperity}
        </Cell>
      </Row>
      <Row style={{ verticalAlign: 'middle' }} height='48'>
        <Cell style={{ backgroundColor: colors.contentBackgroundColor }} />
        <Cell
          style={{
            textAlign: 'left',
            backgroundColor: colors.contentBackgroundColor,
            color: colors.linkColor,
            fontWeight: 700,
            verticalAlign: 'middle',
          }}
          colSpan='2'
        >
          People
        </Cell>
        <Cell
          style={{
            textAlign: 'right',
            backgroundColor: colors.contentBackgroundColor,
            paddingRight: '17px'
          }}>
          {esgScores.people}
        </Cell>
      </Row>
      <Row style={{ verticalAlign: 'middle' }} height='48'>
        <Cell style={{ backgroundColor: colors.stripColor }} />
        <Cell
          style={{
            textAlign: 'left',
            backgroundColor: colors.stripColor,
            color: colors.linkColor,
            fontWeight: 700,
            verticalAlign: 'middle',
          }}
          colSpan='2'
        >
          Planet
        </Cell>
        <Cell
          style={{
            textAlign: 'right',
            backgroundColor: colors.stripColor,
            paddingRight: '17px'
          }}>
          {esgScores.planet}
        </Cell>
      </Row>
      <Row>
        <Cell height='5' colSpan='5' />
      </Row>
    </Table>
  )
}
