import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import AspectIcons from 'app/common/AspectIcons'
import FactorIcon from 'app/common/FactorIcon'
import {ASPECTS, ORDERED_ASPECTS} from 'app/constants'
import * as strings from 'app/strings'

import * as styles from './InsightSummary.less'

export default function InsightSummary({
  valence,
  factor,
  isLitigation = false,
  isOpinion = false,
  isRumor = false,
  className,
}) {
  return (
    <div
      className={classNames(styles.signalSummary, className, 'signal-summary')}
    >
      <div className={styles.container}>
        <FactorIcon
          factor={factor}
          valence={valence}
          className={classNames(styles.factorIcon, 'factor-icon', factor)}
        />
        <AspectIcons
          isLitigation={isLitigation}
          isOpinion={isOpinion}
          isRumor={isRumor}
          className={styles.aspectIcons}
          iconClassName={styles.icon}
        />

        <span className={styles.labels}>
          {strings.factorDisplayName(factor)}
          <Aspects
            isLitigation={isLitigation}
            isOpinion={isOpinion}
            isRumor={isRumor}
          />
        </span>
      </div>
    </div>
  )
}
InsightSummary.propTypes = {
  valence: PropTypes.string.isRequired,
  factor: PropTypes.string.isRequired,
  isLitigation: PropTypes.bool,
  isOpinion: PropTypes.bool,
  isRumor: PropTypes.bool,
  className: PropTypes.string,
}

function Aspects({isLitigation, isOpinion, isRumor}) {
  const isAspectTrue = aspect =>
    ({
      [ASPECTS.LITIGATION]: isLitigation,
      [ASPECTS.OPINION]: isOpinion,
      [ASPECTS.RUMOR]: isRumor,
    }[aspect])
  return ORDERED_ASPECTS.filter(isAspectTrue).map(aspect => (
    <React.Fragment key={aspect}>
      <span className={styles.separator} />
      {strings.aspectDisplayName(aspect)}
    </React.Fragment>
  ))
}
Aspects.propTypes = {
  isLitigation: PropTypes.bool.isRequired,
  isOpinion: PropTypes.bool.isRequired,
  isRumor: PropTypes.bool.isRequired,
}
