import React from 'react'
import classNames from 'classnames'

import * as styles from './CheckBox.less'
import Checkbox from 'app/common/Checkbox'

export default function FancyCheckbox({ label, checked, onChange, className, ...restProps }) {
  return (
    <label className={styles.checkboxContainer}>
      <Checkbox
        isChecked={checked}
        onChange={onChange}
      />
      <span className={classNames(styles.checkmark, className)} {...restProps}/>
      {label}
    </label>
  )
}
