export const colors = {
  defaultFontColor: '#444444',
  positiveFontColor: '#05704B',
  neutralFontColor: '#1E1E1E',
  negativeFontColor: '#AF292E',
  borderColor: '#E6E6E6',
  linkColor: '#1C73A2',
  secondaryFontColor: '#6c6c6c',
  backgroundColor: '#F2F2F2',
  contentBackgroundColor: '#fff',
  stripColor: '#F4F6F8',
}
