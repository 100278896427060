/*
 * Redux-related utilities
 */

import is from 'is'
import {identity, filter, fromPairs, map, mergeAll, pipe, toPairs} from 'ramda'
import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {handleActions} from 'redux-actions'
import {createSelector} from 'reselect'

import {filterKeys} from 'app/utils'

function* indexGenerator(start = 0) {
  let index = start
  while (true) {
    yield index
    index++
  }
}

const indexGenerators = {}
function generateUniqueKeyForName(name) {
  if (!indexGenerators[name]) {
    indexGenerators[name] = indexGenerator(1)
  }
  return indexGenerators[name].next().value.toString()
}

export function localComponent({
  key,
  initAction,
  deinitAction,
  actions = {},
  bindActionsFromProps = [],
  stateSelector = null,
  mapStateToProps = identity,
}) {
  const makeSelector = () => (state, props) => {
    const stateFromSelector = stateSelector(props.local.key, state)
    return {
      ...stateFromSelector,
      // We want props passed in to override any props from the store.
      ...pipe(
        filter(is.defined),
        filterKeys(key => stateFromSelector.hasOwnProperty(key)),
      )(props),
    }
  }
  const makeMapStateToProps = mapStateToProps =>
    createSelector(
      [makeSelector()],
      mapStateToProps,
    )

  return RawComponent => {
    const mapDispatchToProps = (dispatch, props) => {
      const bindLocalAction = action => value =>
        action({key: props.local.key, value})
      return mergeAll([
        bindActionCreators(map(bindLocalAction, actions), dispatch),

        // We want props passed into the component to override the ones
        // generated above.
        pipe(
          toPairs,
          filter(
            ([key, value]) =>
              is.defined(value) &&
              (actions[key] || bindActionsFromProps.includes(key)),
          ),
          map(([key, value]) =>
            bindActionsFromProps.includes(key)
              ? [key, bindLocalAction(value)]
              : [key, value],
          ),
          fromPairs,
        )(props),
      ])
    }
    const WrappedComponent = connect(
      stateSelector ? makeMapStateToProps(mapStateToProps) : undefined,
      mapDispatchToProps,
    )(RawComponent)

    class Local extends React.PureComponent {
      static displayName = `Local(${RawComponent.displayName ||
        RawComponent.name})`

      state = {
        stateKey: undefined,
      }

      componentDidMount() {
        const uniqueKey = generateUniqueKeyForName(key)
        this.setState({stateKey: uniqueKey})
        if (this.props.initAction) {
          this.props.initAction({key: uniqueKey})
        }
      }

      componentWillUnmount() {
        if (this.props.deinitAction) {
          this.props.deinitAction({key: this.state.stateKey})
        }
      }

      render() {
        const {stateKey} = this.state
        if (!stateKey) return null
        const local = {key: stateKey}
        const {initAction, deinitAction, ...restProps} = this.props
        return <WrappedComponent {...restProps} local={local} />
      }
    }

    return connect(
      undefined,
      {initAction, deinitAction},
    )(Local)
  }
}

export function handleLocalActions(handlers) {
  return handleActions(
    map(
      handler => (state, action) => ({
        ...state,
        [action.payload.key]: handler(state[action.payload.key], {
          ...action,
          payload: action.payload.value,
        }),
      }),
      handlers,
    ),
    {},
  )
}
