import dateFns from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'

import LineChart from 'app/common/charts/line-chart'
import {MAX_HEALTH_SCORE, today} from 'app/constants'

const HealthTrend = ({healthData, dailyHealthData, timeFrameDays}) => {
  const data = dailyHealthData.reduce((data, point) => {
    const dayDifference = dateFns.differenceInDays(
      today,
      dateFns.startOfDay(point.date),
    )
    if (dayDifference < timeFrameDays) {
      data.push({
        x: timeFrameDays - dayDifference,
        y: point.healthScore,
      })
    }
    return data
  }, [])
  const {healthScore} = healthData
  // TODO: Replace line color with class names (so that we don't have to store
  // these color values in JS).
  const lineColor =
    healthScore >= 0.5 ? '#1C6C96' : healthScore <= -0.5 ? '#AF3C3C' : '#666666'
  return (
    <LineChart
      series={[data]}
      colors={[lineColor]}
      minYValue={-MAX_HEALTH_SCORE}
      maxYValue={MAX_HEALTH_SCORE}
      minXValue={1}
      maxXValue={timeFrameDays}
      shouldShowDots={false}
    />
  )
}
HealthTrend.propTypes = {
  healthData: PropTypes.object.isRequired,
  dailyHealthData: PropTypes.arrayOf(PropTypes.object).isRequired,
  timeFrameDays: PropTypes.number.isRequired,
}

export default HealthTrend
