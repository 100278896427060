import classNames from 'classnames'
import PropTypes from 'prop-types'
import {prop} from 'ramda'
import React from 'react'
import {connect} from 'react-redux'

import {navigate} from 'app/global/global-actions'
import ScatterPlot from 'app/common/charts/ScatterPlot'
import {MAX_HEALTH_SCORE} from 'app/constants'
import urls from 'app/urls'

import {actions} from './tooltip'

import * as styles from './HealthVolumeMatrix.less'

function HealthVolumeMatrix({
  companies,
  maxVolume,
  width = 0,
  height = 0,
  className = '',

  // Actions
  showTooltip,
  hideTooltip,
  navigate,
}) {
  const scale = x => (Math.pow(x, 0.3) - 1) / (Math.pow(maxVolume, 0.3) - 1)

  const points = companies.filter(prop('healthData')).map(company => ({
    x: scale(company.healthData.articleVolume),
    y: company.healthData.healthScore,
    meta: {
      companyId: company.id,
    },
  }))
  const dotClassName = point =>
    classNames(styles.dot, {
      [styles.positive]: point.y >= 0.5,
      [styles.neutral]: point.y < 0.5 && point.y > -0.5,
      [styles.negative]: point.y <= -0.5,
    })
  const onDotMouseOver =
    showTooltip &&
    ((point, event) => {
      const topOffset = 30
      const el = event.target
      const rect = el.getBoundingClientRect()
      const docElement = document.documentElement
      const top = Math.round(rect.top + docElement.scrollTop) - topOffset
      const left = Math.round(rect.right + docElement.scrollLeft)
      const right = Math.round(rect.left + docElement.scrollLeft)
      showTooltip({top, left, right, companyId: point.meta.companyId})
    })
  const onDotMouseOut = hideTooltip && (() => hideTooltip())
  const onDotClick = point => {
    hideTooltip()
    navigate(urls.companyOverview(point.meta.companyId))
  }
  return (
    <ScatterPlot
      points={points}
      minY={-MAX_HEALTH_SCORE}
      maxY={MAX_HEALTH_SCORE}
      minX={0}
      maxX={scale(maxVolume)}
      isYAxisVisible={false}
      topLeftLabel={'Lower Article Volume, Higher Health Score'}
      bottomLeftLabel={'Lower Article Volume, Lower Health Score'}
      topRightLabel={'Higher Article Volume, Higher Health Score'}
      bottomRightLabel={'Higher Article Volume, Lower Health Score'}
      width={width}
      height={height}
      onDotMouseOver={onDotMouseOver}
      onDotMouseOut={onDotMouseOut}
      onDotClick={onDotClick}
      dotClassName={dotClassName}
      className={classNames(className, styles.healthVolumeMatrix)}
    />
  )
}
HealthVolumeMatrix.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  maxVolume: PropTypes.number.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,

  // Actions
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
  navigate: PropTypes.func,
}

export default connect(
  undefined,
  {
    showTooltip: actions.show,
    hideTooltip: actions.hide,
    navigate,
  },
)(HealthVolumeMatrix)
