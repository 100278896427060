import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects'
import { push } from 'redux-little-router'

import { pyappRequest } from 'app/api'
import { FACTORS_BY_SUBFACTOR } from 'app/constants'
import * as login from 'app/login'
import * as routerSelectors from 'app/router/router-selectors'
import urls from 'app/urls'

import * as actions from './overview-actions'
import {
  FACTOR_QUERY_PARAM,
  GEOGRAPHY_QUERY_PARAM,
  SUBFACTOR_QUERY_PARAM,
  TIME_FRAME_QUERY_PARAM,
} from './overview-constants'

const getDocumentHtml = () => {
  const doctype = new XMLSerializer().serializeToString(document.doctype)
  const docHtml = document.documentElement.outerHTML
    // Strip <script> tags so that Chromium doesn't try to actually execute
    // the page code.
    .replace(/<script(.*?)>(.*?)<\/script>/g, '')
  return `${doctype}${docHtml}`
}

export function* selectFactor(action) {
  yield put(
    push(
      {
        query: {
          [FACTOR_QUERY_PARAM]: action.payload || undefined,
          [SUBFACTOR_QUERY_PARAM]: 'all',
        },
      },
      { persistQuery: true },
    ),
  )
}

export function* selectSubfactor(action) {
  const query = {
    [SUBFACTOR_QUERY_PARAM]: action.payload || undefined,
  }
  const subfactor =
    action.payload && action.payload !== 'all' ? action.payload : null
  if (subfactor) {
    query[FACTOR_QUERY_PARAM] = FACTORS_BY_SUBFACTOR[subfactor]
  }
  yield put(push({ query }, { persistQuery: true }))
}

export function* selectTimeFrame(action) {
  yield put(
    push(
      { query: { [TIME_FRAME_QUERY_PARAM]: action.payload || undefined } },
      { persistQuery: true },
    ),
  )
}

export function* selectGeography(action) {
  yield put(
    push(
      {
        query: {
          [GEOGRAPHY_QUERY_PARAM]:
            action.payload === null ? undefined : action.payload,
        },
      },
      { persistQuery: true },
    ),
  )
}

export function* downloadPdf(finishAction, action) {
  const html = yield call(getDocumentHtml)
  const routerState = yield select(routerSelectors.getRouterState)
  let entityType;
  if (routerState.params.companyId) {
    entityType = 'company'
  } else if (routerState.params.industryId) {
    entityType = 'industry'
  } else if (routerState.params.sectorId) {
    entityType = 'litigation-sector'
  } else {
    entityType = 'litigation-sectors'
  }
  let entityId;
  if (entityType === 'company' || entityType === 'industry') {
    entityId = routerState.params[`${entityType}Id`]
  } else if (entityType === 'litigation-sector') {
    entityId = routerState.params['sectorId']
  } else {
    // The litigation sector list doesn't have any ID, because it is a list...
    // So let's create a random one
    entityId = Math.floor(Math.random() * 10000) + 1
  }
  const token = yield select(login.selectors.getJwt)

  const response = yield pyappRequest({
    url: urls.pyapp.api.reportToPdf(),
    method: 'POST',
    data: { entityId, entityType, html },
    token,
  })
  if (response.body.finished) {
    window.location.href = response.body.url
    return
  }
  while (true) {
    yield delay(1000)
    const response = yield pyappRequest({
      url: urls.pyapp.api.reportToPdfProgress(),
      method: 'GET',
      query: { entityId, entityType },
      token,
    })
    if (response.body.finished) {
      window.location.href = response.body.url
      break
    }
  }
  yield put(finishAction())
}

function* handleSaveFilterDefaults() {
  // Clear the relevant query parameters.
  yield put(
    push(
      {
        query: {
          [FACTOR_QUERY_PARAM]: undefined,
          [SUBFACTOR_QUERY_PARAM]: undefined,
          [TIME_FRAME_QUERY_PARAM]: undefined,
          [GEOGRAPHY_QUERY_PARAM]: undefined,
        },
      },
      { persistQuery: true },
    ),
  )
}

export default function* overviewSaga() {
  yield all([
    takeLatest(actions.selectFactor, selectFactor),
    takeLatest(actions.selectSubfactor, selectSubfactor),
    takeLatest(actions.selectTimeFrame, selectTimeFrame),
    takeLatest(actions.selectGeography, selectGeography),
    takeLatest(login.actions.saveFilterDefaults, handleSaveFilterDefaults),
  ])
}
