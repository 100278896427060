import dateFns from 'date-fns'
import {map, prop} from 'ramda'
import {LOCATION_CHANGED} from 'redux-little-router'
import {all, put, select, takeLatest} from 'redux-saga/effects'

import {fetchIndustries, fetchStories} from 'app/api/api-saga-helpers'
import {
  ORDERED_FACTORS,
  SORT_DIRECTIONS,
  SYSTEM_DATE_FORMAT,
  today,
} from 'app/constants'
import IndustryResource from 'app/resources/Industry'
import StoryResource from 'app/resources/Story'
import routes from 'app/routes'
import {restApiRequest} from 'app/utils/sagas'

import {downloadPdf} from '../../common/overview-saga'
import * as actions from './industry-report-actions'
import * as selectors from './industry-report-selectors'

function* loadStoryCounts(industryId) {
  const timeFrameDays = yield select(selectors.getTimeFrameDays)
  const geography = yield select(selectors.getGeography)
  const startDate = dateFns.subDays(today, timeFrameDays)
  const response = yield yield* restApiRequest(StoryResource, {
    query: {
      perspectiveId: `eq.${geography}`,
      industryIds: `cs.{${industryId}}`,
      storyDate: `gte.${dateFns.format(startDate, SYSTEM_DATE_FORMAT)}`,
    },
    headers: {
      'Range-Unit': 'items',
      Range: '0-0',
      Prefer: 'count=exact',
    },
    fields: ['id'],
  })
  const storyCount = parseInt(
    response.headers['content-range'].split('/')[1],
    10,
  )
  yield put(actions.setStoryCount(storyCount))
}

function* locationChanged() {
  const industryId = yield select(selectors.getIndustryId)
  const timeFrameDays = yield select(selectors.getTimeFrameDays)
  const geography = yield select(selectors.getGeography)
  const fetchIndustryRequest = yield* fetchIndustries({
    industryId,
    timeFrameDays,
    geography,
    fields: [
      ...IndustryResource.requiredFields,
      'companyIds',
      'healthBadge',
      'overallDailyHealthDataPoints',
    ],
  })

  const startDate = dateFns.subDays(today, timeFrameDays)
  const storiesRequests = {}
  for (const factor of ORDERED_FACTORS) {
    storiesRequests[factor] = yield* fetchStories({
      industryId,
      factor,
      startDate,
      geography,
      isLargest: true,
      orderBy: [{field: 'articleCount', direction: SORT_DIRECTIONS.DESC}],
      limit: 5,
      fields: [
        ...StoryResource.requiredFields,
        'storyline',
        'company',
        'topArticle',
      ],
    })
  }
  yield* loadStoryCounts(industryId)

  const storiesResponsesByFactor = yield all(storiesRequests)
  const storyIdsByFactor = map(prop('result'), storiesResponsesByFactor)
  yield put(actions.setStoryIdsByFactor(storyIdsByFactor))

  // Make sure the industry data is finished loading.
  yield fetchIndustryRequest
  yield put(actions.setIsLoading(false))
}

const isCompanyReportLocationChange = action =>
  action.type === LOCATION_CHANGED &&
  action.payload.route === routes.industryReport

export default function* companyReportSaga() {
  yield all([
    takeLatest(isCompanyReportLocationChange, locationChanged),
    takeLatest(
      actions.startDownloadPdf,
      downloadPdf,
      actions.finishDownloadPdf,
    ),
  ])
}
