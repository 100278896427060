import {range} from 'ramda'
import React from 'react'

import CloseButton from 'app/common/CloseButton'
import Modal from 'app/common/Modal'
import {StoryCard, StoryCardLoading} from 'app/reusable/stories/story-card'

import styles from './CompanySimCircForecastStoriesModal.less'

const LOADING_STORY_SKELETON_COUNT = 10

export default function CompanySimCircForecastStoriesModal({
  daysSinceMatch,
  isLoading,
  stories,
  close,
}) {
  const daysSinceMatchLabel =
    daysSinceMatch === 0
      ? 'on Day of Match'
      : daysSinceMatch < 0
      ? `${Math.abs(daysSinceMatch)} Days Before Match`
      : `${daysSinceMatch} Days After Match`
  return (
    <Modal
      onClose={close}
      shouldShowCloseButton={false}
      className={styles.modal}
      contentClassName={styles.modalContent}
    >
      <div className={styles.header}>
        <span>Top Stories {daysSinceMatchLabel}</span>
        <CloseButton onClick={() => close()} className={styles.exit} />
      </div>
      <div className={styles.content}>
        {isLoading ? (
          range(0, LOADING_STORY_SKELETON_COUNT).map(index => (
            <StoryCardLoading
              shouldShowCompanyName={false}
              className={styles.story}
              key={index}
            />
          ))
        ) : stories.length ? (
          stories.map(story => (
            <StoryCard
              story={story}
              shouldShowCompanyName={false}
              className={styles.story}
              key={story.id}
            />
          ))
        ) : (
          <div className={styles.noStories}>
            There were no stories on this day.
          </div>
        )}
      </div>
    </Modal>
  )
}
