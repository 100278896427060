import {always} from 'ramda'

import {handleLocalActions} from 'app/utils/redux'

import * as actions from './subfactor-health-badge-actions'

const getInitialState = () => ({
  highlightedSubfactor: null,
  tooltip: {
    isVisible: false,
    left: null,
    right: null,
    top: null,
    factor: null,
  },
})

export default handleLocalActions({
  [actions.init]: getInitialState,
  [actions.deinit]: always(undefined),
  [actions.highlightSubfactor]: (state, action) => ({
    ...state,
    highlightedSubfactor: action.payload,
  }),
  [actions.clearHighlightedSubfactor]: (state, action) => ({
    ...state,
    highlightedSubfactor: getInitialState().highlightedSubfactor,
  }),
  [actions.showTooltip]: (state, action) => ({
    ...state,
    tooltip: {
      isVisible: true,
      left: action.payload.left,
      right: action.payload.right,
      top: action.payload.top,
      subfactor: action.payload.subfactor,
    },
  }),
  [actions.hideTooltip]: (state, action) => ({
    ...state,
    tooltip: getInitialState().tooltip,
  }),
})
