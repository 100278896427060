import dateFns from 'date-fns'
import is from 'is'
import PropTypes from 'prop-types'
import React from 'react'

import {USER_DATE_FORMAT} from 'app/constants'
import urls, {toAbsoluteUrl, withSendGridTracking} from 'app/urls'
import {getExcerpt} from 'app/utils/article'
import Cell from 'email-server-common/common/Cell'
import Link from 'email-server-common/common/Link'
import PaddedBlock from 'email-server-common/common/PaddedBlock'
import Row from 'email-server-common/common/Row'
import Table from 'email-server-common/common/Table'
import {paddingAmount, spacingAmount} from 'email-server/style/constants'
import {
  backgroundColorDark,
  lineColorLight,
  textColorLight,
  textColorVeryLight,
} from 'email-server/style/palette'

import HealthScoreSection from './shared/HealthScoreSection'
import StorySummaryBlock from './shared/StorySummaryBlock'
import Section from './Section'

const NoNewStories = ({company, productName}) => (
  <PaddedBlock
    vertical={spacingAmount * 1.5}
    horizontal={spacingAmount}
    style={{textAlign: 'center', fontSize: 18}}
  >
    No New {productName} Stories for {company.name}
  </PaddedBlock>
)
NoNewStories.propTypes = {
  company: PropTypes.object.isRequired,
  productName: PropTypes.string.isRequired,
}

const StorySection = ({
  company,
  story,
  user,
  productName,
  rootUrl,
  baseRootUrl,
  isLast = false,
}) => {
  const article = story.topArticle
  const textSpacingAmount = paddingAmount / 2

  return (
    <PaddedBlock horizontal={paddingAmount}>
      <Table>
        <Cell
          style={{
            borderBottom: isLast ? 'none' : `1px solid ${lineColorLight}`,
            paddingTop: spacingAmount,
            paddingBottom: spacingAmount,
          }}
        >
          <PaddedBlock bottom={textSpacingAmount}>
            <StorySummaryBlock story={story} />
          </PaddedBlock>

          <Table>
            <Link
              href={withSendGridTracking(
                urls.pyapp.article({
                  contentDirectorId: article.contentDirectorId,
                  userId: user.id,
                  source: 'signals_email',
                  root: baseRootUrl,
                  savedsearchId: company.savedsearchId,
                }),
              )}
            >
              View Article
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link
              href={withSendGridTracking(
                toAbsoluteUrl(
                  urls.interimPage({
                    contentDirectorId: article.contentDirectorId,
                    savedSearchId: company.savedSearchId,
                  }),
                  {root: rootUrl},
                ),
              )}
            >
              View {productName} Article Context
            </Link>
          </Table>

          <PaddedBlock vertical={textSpacingAmount} style={{fontSize: 18}}>
            {article.headline}
          </PaddedBlock>

          <PaddedBlock bottom={textSpacingAmount}>
            {getExcerpt(article.description)}
          </PaddedBlock>

          <PaddedBlock
            bottom={textSpacingAmount}
            style={{color: textColorLight}}
          >
            {dateFns.format(article.date, USER_DATE_FORMAT)} via{' '}
            {article.feedName}
          </PaddedBlock>

          {story.articleCount > 1 && (
            <Table style={{color: textColorLight}}>
              From a story with {story.articleCount - 1} other article
              {story.articleCount - 1 > 1 && 's'}
            </Table>
          )}
        </Cell>
      </Table>
    </PaddedBlock>
  )
}
StorySection.propTypes = {
  company: PropTypes.object.isRequired,
  story: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  productName: PropTypes.string.isRequired,
  rootUrl: PropTypes.string.isRequired,
  baseRootUrl: PropTypes.string.isRequired,
  isLast: PropTypes.bool,
}

const CompanySection = ({
  company,
  user,
  date,
  timeFrameDays,
  productName,
  rootUrl,
  baseRootUrl,
  shouldShowHealthChanges = false,
}) => {
  return (
    <Section>
      <a name={`company${company.id}`} />

      <Table>
        {/* Header */}
        <Row>
          <Cell
            style={{
              backgroundColor: backgroundColorDark,
              color: textColorVeryLight,
              padding: paddingAmount,
              fontSize: 16,
            }}
          >
            <Table>
              <Cell align="left">
                <span style={{color: textColorVeryLight, fontWeight: 'bold'}}>
                  {company.name}
                </span>
              </Cell>
              <Cell align="right" style={{textAlign: 'right'}}>
                <Link
                  href={withSendGridTracking(
                    toAbsoluteUrl(urls.companyOverview(company.id), {
                      root: rootUrl,
                    }),
                  )}
                  style={{
                    color: textColorVeryLight,
                    textDecoration: 'underline',
                  }}
                >
                  View {company.name} Company Overview
                </Link>
              </Cell>
            </Table>
          </Cell>
        </Row>

        {/* Health score */}
        <Row>
          <HealthScoreSection
            entity={company}
            entityType="company"
            date={date}
            timeFrameDays={timeFrameDays}
            productName={productName}
            rootUrl={rootUrl}
            shouldShowHealthChanges={shouldShowHealthChanges}
          />
        </Row>

        {/* Stories */}
        <Row>
          {company.topStories.map((story, index) => (
            <StorySection
              company={company}
              story={story}
              user={user}
              productName={productName}
              rootUrl={rootUrl}
              baseRootUrl={baseRootUrl}
              isLast={index === company.topStories.length - 1}
              key={story.id}
            />
          ))}
          {is.array.empty(company.topStories) && (
            <NoNewStories company={company} productName={productName} />
          )}
        </Row>

        {/* Footer */}
        <Row>
          <PaddedBlock
            padding={spacingAmount}
            style={{
              textAlign: 'center',
              borderTop: `1px solid ${lineColorLight}`,
            }}
          >
            <Link
              href={withSendGridTracking(
                toAbsoluteUrl(urls.companyOverview(company.id), {
                  root: rootUrl,
                }),
              )}
            >
              View {company.name} Company Overview
            </Link>
          </PaddedBlock>
        </Row>
      </Table>
    </Section>
  )
}
CompanySection.propTypes = {
  company: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  timeFrameDays: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  rootUrl: PropTypes.string.isRequired,
  baseRootUrl: PropTypes.string.isRequired,
  shouldShowHealthChanges: PropTypes.bool,
}
export default CompanySection
