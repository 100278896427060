import classNames from 'classnames'
import PropTypes from 'prop-types'
import {prop} from 'ramda'
import React from 'react'
import {connect} from 'react-redux'

import Modal from 'app/common/Modal'
import Orm from 'app/framework/Orm'
import {Company, Industry} from 'app/models'
import EmailSettingsModal from 'app/reusable/entities/my-entity-star/EmailSettingsModal'
import {CONFIRMATION_STATUSES} from 'app/reusable/entities/my-entity-star/my-entity-star-constants'

import * as actions from './add-entities-modal-actions'
import {STEP} from './add-entities-modal-constants'
import * as selectors from './add-entities-modal-selectors'
import AddEntitiesModalFailure from './AddEntitiesModalFailure'
import AddEntitiesModalSearch from './AddEntitiesModalSearch'
import AddEntitiesModalSuccess from './AddEntitiesModalSuccess'

import * as styles from './AddEntitiesModal.less'

function AddEntitiesModal({
  isVisible,
  entityType,
  isLoading,
  step,
  error,
  selectedEntities,

  // Actions
  hideModal,
  setSelectedEntityIds,
  addEntitiesRequest,
  confirmEmailSettings,
}) {
  if (!isVisible) return null

  const selectedSearchIds = selectedEntities.map(prop('savedSearchId'))

  if (step === STEP.EMAIL_SETTINGS) {
    return (
      <EmailSettingsModal
        entityNames={selectedEntities.map(prop('name'))}
        entityType={entityType}
        confirmationStatus={
          isLoading
            ? CONFIRMATION_STATUSES.CONFIRMING
            : CONFIRMATION_STATUSES.UNCONFIRMED
        }
        onSubmit={({frequency}) =>
          confirmEmailSettings({
            frequency,
            savedSearchIds: selectedSearchIds,
          })
        }
        close={hideModal}
      />
    )
  }

  const onChange = values => setSelectedEntityIds(values.map(prop('value')))
  const onSubmit = () =>
    addEntitiesRequest({ids: selectedSearchIds, entityType})

  const content = error ? (
    <AddEntitiesModalFailure
      entities={selectedEntities}
      entityType={entityType}
      error={error}
    />
  ) : step === STEP.SEARCH ? (
    <AddEntitiesModalSearch
      isLoading={isLoading}
      canSubmit={selectedEntities.length > 0}
      entityType={entityType}
      onChange={onChange}
      onSubmit={onSubmit}
    />
  ) : step === STEP.EMAIL_SETTINGS ? (
    <EmailSettingsModal
      entityNames={selectedEntities.map(prop('name'))}
      entityType={entityType}
      confirmationStatus={
        isLoading
          ? CONFIRMATION_STATUSES.CONFIRMING
          : CONFIRMATION_STATUSES.UNCONFIRMED
      }
      onSubmit={({frequency}) =>
        confirmEmailSettings({frequency, savedSearchIds: selectedSearchIds})
      }
      close={hideModal}
    />
  ) : (
    <AddEntitiesModalSuccess
      entities={selectedEntities}
      entityType={entityType}
    />
  )

  return (
    <Modal
      className={classNames(styles.addCompaniesModal, {
        [styles.finished]: step === STEP.FINISHED,
      })}
      contentClassName={styles.modalContent}
      onClose={hideModal}
    >
      {content}
    </Modal>
  )
}
AddEntitiesModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  entityType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  step: PropTypes.string.isRequired,
  error: PropTypes.object,
  selectedEntities: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,

  // Actions
  hideModal: PropTypes.func.isRequired,
  setSelectedEntityIds: PropTypes.func.isRequired,
  addEntitiesRequest: PropTypes.func.isRequired,
  confirmEmailSettings: PropTypes.func.isRequired,
}
export default connect(
  state => {
    const data = selectors.getAddCompaniesModal(state)
    const orm = Orm.withEntities(state.entities)
    const Model = data.entityType === 'company' ? Company : Industry
    return {
      isVisible: data.isVisible,
      entityType: data.entityType,
      isLoading: data.isLoading,
      step: data.step,
      error: data.error,
      selectedEntities: orm.getByIds(Model, data.selectedEntityIds),
    }
  },
  {
    hideModal: actions.hide,
    setSelectedEntityIds: actions.setSelectedEntityIds,
    addEntitiesRequest: actions.addEntities,
    confirmEmailSettings: actions.confirmEmailSettings,
  },
)(AddEntitiesModal)
