import className from 'classnames'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'

import SizedContainer from 'app/common/SizedContainer'
import XAxisMarkers from 'app/common/charting/XAxisMarkers'
import {VALENCES} from 'app/constants'

import * as styles from './styles.less'

export default function EventTimelineChart(props) {
  return (
    <SizedContainer className="storyline">
      <EventTimelineChartUnsized {...props} />
    </SizedContainer>
  )
}

class EventTimelineChartUnsized extends React.PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.number.isRequired,
          group: PropTypes.any.isRequired,
        }),
      ),
    ).isRequired,
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
      }),
    ).isRequired,
    startDate: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
    highlightedDay: PropTypes.number,
    highlightedGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
    onHighlightDay: PropTypes.func.isRequired,
    onClearHighlightedDay: PropTypes.func.isRequired,
  }

  render() {
    const {
      items,
      groups,
      startDate,
      width = 0,
      height = 0,
      highlightedDay,
      highlightedGroups,
      onHighlightDay,
      onClearHighlightedDay,
    } = this.props

    // Layout config
    const xAxisMarkersHeight = 10
    const yAxisWidth = 32
    const middleLineWidth = 2
    const gapWidthRatio = 2 // The ratio of gap width to bar width

    // Layout calculations
    const realHeight = height - xAxisMarkersHeight
    const offsetX = yAxisWidth
    const realWidth = width - offsetX
    const middleLineY = realHeight / 2
    const maxBarHeight = realHeight / 2 - middleLineWidth / 2

    const mapWithIndex = R.addIndex(R.map)
    const maxValue = R.pipe(
      R.map(
        R.pipe(
          R.map(R.prop('value')),
          R.reduce(
            (acc, value) => {
              if (value > 0) {
                acc = {...acc, positive: acc.positive + value}
              } else {
                acc = {...acc, negative: acc.negative + Math.abs(value)}
              }
              return acc
            },
            {positive: 0, negative: 0},
          ),
          sumValues => Math.max(sumValues.positive, sumValues.negative),
        ),
      ),
      R.reduce(R.max, 0),
    )(items)

    // Bar generation
    const barWidth =
      realWidth / (items.length * (1 + gapWidthRatio) - gapWidthRatio)
    const dayGroups = items.map((itemsForDay, dayIndex) => {
      const x = Math.round(barWidth * (1 + gapWidthRatio) * dayIndex)

      const barsWithValence = valence => {
        let accumulatedHeight = 0

        const items = itemsForDay.filter(item =>
          valence === VALENCES.POSITIVE ? item.value > 0 : item.value < 0,
        )
        return R.pipe(
          R.sortBy(
            R.pipe(
              R.prop('value'),
              Math.abs,
            ),
          ),
          R.reverse,
          mapWithIndex((item, itemIndex) => {
            const {value, group: itemGroup} = item
            const barHeight = Math.round(
              Math.abs((value / maxValue) * maxBarHeight),
            )
            const y =
              Math.round(
                valence === VALENCES.POSITIVE
                  ? middleLineY - barHeight - middleLineWidth / 2
                  : middleLineY + middleLineWidth / 2,
              ) +
              (valence === VALENCES.POSITIVE
                ? -accumulatedHeight
                : accumulatedHeight)
            accumulatedHeight += barHeight
            const color = groups.find(group => group.id == itemGroup).color
            const isSelected =
              (!highlightedDay || dayIndex === highlightedDay) &&
              (!highlightedGroups.length ||
                highlightedGroups.includes(itemGroup))
            return (
              <rect
                className={className(styles.bar, {
                  [styles.selected]: isSelected,
                  [styles.faded]:
                    (highlightedDay || highlightedGroups.length) && !isSelected,
                })}
                style={{fill: color}}
                x={offsetX + x}
                y={y}
                width={Math.round(barWidth)}
                height={barHeight}
                key={itemIndex}
              />
            )
          }),
        )(items)
      }

      const positiveBars = barsWithValence(VALENCES.POSITIVE)
      const negativeBars = barsWithValence(VALENCES.NEGATIVE)

      return (
        <g
          onMouseEnter={bind(this.onHighlightDay, this, dayIndex)}
          onMouseLeave={bind(this.onClearHighlightedDay, this)}
          key={dayIndex}
        >
          {positiveBars}
          {negativeBars}
        </g>
      )
    })

    return (
      <svg className={styles.chart} width={realWidth} height={height}>
        <YAxis x={0} y={0} width={yAxisWidth} height={height} />

        <g className={styles.bars}>{dayGroups}</g>

        <line
          className={styles.middleLine}
          x1={offsetX}
          y1={middleLineY}
          x2={width}
          y2={middleLineY}
          strokeWidth={middleLineWidth}
        />

        <rect
          className={styles.bottomLine}
          x={offsetX}
          y={height - 1}
          width={realWidth}
          height={1}
        />

        <XAxisMarkers
          x={offsetX}
          y={height - xAxisMarkersHeight}
          height={xAxisMarkersHeight}
          markerCount={items.length}
          markerWidth={barWidth}
          markerSpacing={barWidth * gapWidthRatio}
        />
      </svg>
    )
  }

  onHighlightDay(dayIndex, event) {
    this.props.onHighlightDay(dayIndex, event)
  }

  onClearHighlightedDay() {
    this.props.onClearHighlightedDay()
  }
}

function YAxis({x, y, width, height}) {
  const offsetY = 5
  const labelX = x + width / 2
  const labelY = y + height / 2
  const lineWidth = 1
  return (
    <g className={styles.yAxis}>
      <rect
        className={styles.line}
        x={x + width - lineWidth}
        y={y}
        width={lineWidth}
        height={height}
      />
      <text
        className={styles.label}
        x={labelX}
        y={labelY + offsetY}
        transform={`rotate(-90 ${labelX},${labelY})`}
      >
        Article Volume by Valence
      </text>
    </g>
  )
}
YAxis.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
