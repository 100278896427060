import {createAction} from 'redux-actions'

const action = baseName => createAction(`industry-overview/${baseName}`)

export const setIsLoading = action('SET_IS_LOADING')
export const setAllCompanyIds = action('SET_ALL_COMPANY_IDS')
export const setSubIndustryIds = action('SET_SUB_INDUSTRY_IDS')
export const setSidebarCompanyIds = action('SET_SIDEBAR_COMPANY_IDS')
export const setTotalCompanyCount = action('SET_TOTAL_COMPANY_COUNT')
export const setStoryIds = action('SET_STORY_IDS')

export const selectValence = action('SELECT_VALENCE')
export const deselectValence = action('DESELECT_VALENCE')
export const setStorySortOrder = action('SET_STORY_SORT_ORDER')
export const setShowOnlyMyCompanies = action('SET_SHOW_ONLY_MY_COMPANIES')
export const selectCompany = action('SELECT_COMPANY')
export const clearSelectedCompany = action('CLEAR_SELECTED_COMPANY')
export const setShowOnlyIndustryNews = action('SET_SHOW_ONLY_INDUSTRY_NEWS')

export const showFiltersSaveModal = action('SHOW_FILTERS_SAVE_MODAL')
export const hideFiltersSaveModal = action('HIDE_FILTERS_SAVE_MODAL')

export const highlightFactor = action('HIGHLIGHT_FACTOR')
export const clearHighlightedFactor = action('CLEAR_HIGHLIGHTED_FACTOR')
export const highlightSubfactor = action('HIGHLIGHT_SUBFACTOR')
export const clearHighlightedSubfactor = action('CLEAR_HIGHLIGHTED_SUBFACTOR')
export const showHealthBadgeTooltip = action('SHOW_HEALTH_BADGE_TOOLTIP')
export const hideHealthBadgeTooltip = action('HIDE_HEALTH_BADGE_TOOLTIP')
