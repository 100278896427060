import dateFns from 'date-fns'
import {LOCATION_CHANGED} from 'redux-little-router'
import {put, takeLatest} from 'redux-saga/effects'

import {fetchMyCompanies, fetchStories} from 'app/api/api-saga-helpers'
import {
  IGNORED_SUBFACTORS,
  SORT_DIRECTIONS,
  today,
  VALENCES,
} from 'app/constants'
import {Company as CompanyResource, Story as StoryResource} from 'app/resources'
import routes from 'app/routes'

import * as actions from './dashboard-actions'
import {MAX_TOP_STORIES_COUNT} from './dashboard-constants'

function* locationChanged(action) {
  const myCompaniesResponse = yield yield* fetchMyCompanies({
    fields: [
      ...CompanyResource.requiredFields,
      'industries',
      'healthBadge',
      'overallDailyHealthDataPoints',
      'categoryDailyHealthData',
    ],
  })
  const myCompanyIds = myCompaniesResponse.result
  const startDate = dateFns.subDays(today, 1)

  if (myCompanyIds.length) {
    const fetchStoriesOptions = {
      startDate,
      companyIds: myCompanyIds,
      isMostRecent: true,
      ignoreSubfactors: IGNORED_SUBFACTORS,
      orderBy: [
        {field: 'articleCount', direction: SORT_DIRECTIONS.DESC},
        {field: 'storyDate', direction: SORT_DIRECTIONS.DESC},
      ],
      limit: MAX_TOP_STORIES_COUNT,
      fields: [
        ...StoryResource.requiredFields,
        'storyline',
        'companyId',
        'topArticle',
      ],
    }
    const negativeRequest = yield* fetchStories({
      ...fetchStoriesOptions,
      valence: VALENCES.NEGATIVE,
    })
    const positiveRequest = yield* fetchStories({
      ...fetchStoriesOptions,
      valence: VALENCES.POSITIVE,
    })
    const negativeStoryIds = (yield negativeRequest).result
    const positiveStoryIds = (yield positiveRequest).result
    // TODO: Handle failure
    yield put(actions.setTopNegativeStoryIds(negativeStoryIds))
    yield put(actions.setTopPositiveStoryIds(positiveStoryIds))
  } else {
    // If there are no companies, there can't be any stories.
    yield put(actions.setTopNegativeStoryIds([]))
    yield put(actions.setTopPositiveStoryIds([]))
  }
}

const isDashboardLocationChange = action =>
  action.type == LOCATION_CHANGED && action.payload.route === routes.dashboard

export default function* companyOverviewSaga() {
  yield takeLatest(isDashboardLocationChange, locationChanged)
}
