import {paddingAmount} from './constants'
import {backgroundColorLight, lineColorLight} from './palette'

export const tableCellStyle = {
  paddingTop: paddingAmount / 2,
  paddingBottom: paddingAmount / 2,
  paddingLeft: paddingAmount,
  paddingRight: paddingAmount,
  borderBottom: `1px solid ${lineColorLight}`,
  fontSize: 16,
}
export const tableHeaderCellStyle = {
  ...tableCellStyle,
  borderTop: tableCellStyle.borderBottom,
  backgroundColor: backgroundColorLight,
  fontSize: 14,
}
