import Model from "../framework/Model";
import { field } from "../framework/fields";

export default class LitigationTimeSeries extends Model {
  static entityKey = "litigationTimeSeries";

  static fields = {
    id: field(),
    companyName: field(),
    companyRank: field(),
    companyWeeklyLitigationCounts: field(),
  };
}
