import {always, assoc, assocPath} from 'ramda'

import {handleLocalActions} from 'app/utils/redux'

import * as actions from './my-entity-star-actions'
import {CONFIRMATION_STATUSES} from './my-entity-star-constants'

export const getInitialState = () => ({
  isLoading: false,
  emailSettingsModal: {
    isVisible: false,
    confirmationStatus: CONFIRMATION_STATUSES.UNCONFIRMED,
  },
  removalConfirmationModal: {
    isVisible: false,
    confirmationStatus: CONFIRMATION_STATUSES.UNCONFIRMED,
  },
})

export default handleLocalActions({
  [actions.init]: getInitialState,
  [actions.deinit]: always(undefined),
  [actions.setIsLoading]: (state, action) => ({
    ...state,
    isLoading: action.payload,
  }),

  [actions.showEmailSettingsModal]: assoc('emailSettingsModal', {
    ...getInitialState().emailSettingsModal,
    isVisible: true,
  }),
  [actions.hideEmailSettingsModal]: assoc(
    'emailSettingsModal',
    getInitialState().emailSettingsModal,
  ),
  [actions.setEmailSettingsConfirming]: assocPath(
    ['emailSettingsModal', 'confirmationStatus'],
    CONFIRMATION_STATUSES.CONFIRMING,
  ),
  [actions.setEmailSettingsConfirmed]: assocPath(
    ['emailSettingsModal', 'confirmationStatus'],
    CONFIRMATION_STATUSES.CONFIRMED,
  ),

  [actions.showRemovalConfirmationModal]: assoc('removalConfirmationModal', {
    ...getInitialState().removalConfirmationModal,
    isVisible: true,
  }),
  [actions.hideRemovalConfirmationModal]: assoc(
    'removalConfirmationModal',
    getInitialState().removalConfirmationModal,
  ),
  [actions.setRemovalConfirming]: assocPath(
    ['removalConfirmationModal', 'confirmationStatus'],
    CONFIRMATION_STATUSES.CONFIRMING,
  ),
  [actions.setRemovalConfirmed]: assocPath(
    ['removalConfirmationModal', 'confirmationStatus'],
    CONFIRMATION_STATUSES.CONFIRMED,
  ),
})
