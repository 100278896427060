import React from 'react'

export default function TrashIcon({ className, ...restProps }) {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M9.20869 1.70869C9.34231 1.57507 9.52353 1.5 9.7125 1.5H14.2875C14.3811 1.5 14.4737 1.51843 14.5602
            1.55424C14.6466 1.59004 14.7252 1.64252 14.7913 1.70869C14.8575 1.77485 14.91 1.85339 14.9458
            1.93984C14.9816 2.02628 15 2.11893 15 2.2125V3H9V2.2125C9 2.02353 9.07507 1.84231 9.20869 1.70869ZM16.5
            2.2125V3H19.6807H19.6929H21.75C22.1642 3 22.5 3.33579 22.5 3.75C22.5 4.16421 22.1642 4.5 21.75
            4.5H20.3927L19.3495 21.4443C19.3318 22.1162 19.0571 22.756 18.5815 23.2315C18.102 23.7111 17.4554
            23.9864 16.7774 23.9999L16.7625 24H7.2375L7.22265 23.9999C6.54458 23.9864 5.89803 23.7111 5.41848
            23.2315C4.94291 22.756 4.66818 22.1162 4.65054 21.4443L3.60726 4.5H2.25C1.83579 4.5 1.5 4.16421 1.5
            3.75C1.5 3.33579 1.83579 3 2.25 3H4.30709H4.31933H7.5V2.2125C7.5 1.62571 7.7331 1.06295 8.14803
            0.648026C8.56295 0.233102 9.12571 0 9.7125 0H14.2875C14.578 0 14.8658 0.0572279 15.1342 0.168417C15.4026
            0.279605 15.6465 0.442577 15.852 0.648026C16.0574 0.853476 16.2204 1.09738 16.3316 1.36581C16.4428 1.63424
            16.5 1.92195 16.5 2.2125ZM15.75 4.5H18.8899L17.8514 21.3664C17.8508 21.3768 17.8504 21.3872 17.8501
            21.3976C17.8444 21.6882 17.7264 21.9653 17.5209 22.1709C17.3169 22.3749 17.0424 22.4926 16.7541
            22.5H7.24588C6.95764 22.4926 6.68313 22.3749 6.47914 22.1709C6.27361 21.9653 6.15561 21.6882 6.14985
            21.3976C6.14965 21.3872 6.14922 21.3768 6.14858 21.3664L5.1101 4.5H8.25H15.75ZM8.99885 6.7084C8.97587
            6.29482 8.62197 5.97818 8.2084 6.00115C7.79482 6.02413 7.47818 6.37803 7.50115 6.7916L8.25115
            20.2916C8.27413 20.7052 8.62803 21.0218 9.0416 20.9988C9.45518 20.9759 9.77182 20.622 9.74885
            20.2084L8.99885 6.7084ZM16.4988 6.7916C16.5218 6.37803 16.2052 6.02413 15.7916 6.00115C15.378
            5.97818 15.0241 6.29482 15.0012 6.7084L14.2512 20.2084C14.2282 20.622 14.5448 20.9759 14.9584
            20.9988C15.372 21.0218 15.7259 20.7052 15.7488 20.2916L16.4988 6.7916ZM12.75 6.75C12.75 6.33579
            12.4142 6 12 6C11.5858 6 11.25 6.33579 11.25 6.75V20.25C11.25 20.6642 11.5858 21 12 21C12.4142
            21 12.75 20.6642 12.75 20.25V6.75Z"
            fill="#1E1E1E"/>
    </svg>
  )
}
