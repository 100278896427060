import {createAction} from 'redux-actions'

const PREFIX = 'DASHBOARD'
const action = name => createAction(`${PREFIX}_${name}`)

export const setTopNegativeStoryIds = action('SET_TOP_NEGATIVE_STORY_IDS')
export const setTopPositiveStoryIds = action('SET_TOP_POSITIVE_STORY_IDS')
export const setHealthChangeTableSort = action('SET_HEALTH_CHANGE_TABLE_SORT')
export const expandHealthChangesForCompany = action(
  'EXPAND_HEALTH_CHANGES_FOR_COMPANY',
)
export const collapseHealthChangesForCompany = action(
  'COLLAPSE_HEALTH_CHANGES_FOR_COMPANY',
)
