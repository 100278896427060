import {handleActions} from 'redux-actions'

import {ENTITY_TYPES} from 'app/constants'
import {deepCopy} from 'app/utils'

import * as actions from './login-actions'

const getInitialState = () => ({
  currentUser: null,
  flaggedArticleCount: 0,
  token: null,
  isLoading: false,
  defaultTimezone: null,
  timezones: [],
  locations: [],
  roles: [],
  adminHash: null,

  filterDefaults: {
    companies: {
      all: {
        factor: null,
        subfactor: null,
        timeFrameDays: 30,
        geography: 0,
      },
      byId: {},
    },
    industries: {
      all: {
        factor: null,
        subfactor: null,
        timeFrameDays: 90,
        geography: 0,
      },
      byId: {},
    },
  },
})

export default handleActions(
  {
    [actions.setToken]: (state, action) => ({
      ...state,
      token: action.payload,
    }),
    [actions.setProfileData]: (state, action) => {
      const initialState = getInitialState()
      const {filterDefaults} = action.payload
      return {
        ...state,
        currentUser: action.payload.user,
        flaggedArticleCount: action.payload.flaggedArticleCount,
        defaultTimezone: action.payload.defaultTimezone,
        timezones: action.payload.timezones,
        locations: action.payload.locations,
        roles: action.payload.roles,
        adminHash: action.payload.adminHash,
        filterDefaults: {
          companies: {
            all: {
              ...filterDefaults.companies.all,
              timeFrameDays:
                filterDefaults.companies.all.timeFrameDays ||
                initialState.filterDefaults.companies.all.timeFrameDays,
              geography:
                filterDefaults.companies.all.geography === null
                  ? initialState.filterDefaults.companies.all.geography
                  : filterDefaults.companies.all.geography,
            },
            byId: filterDefaults.companies.byId,
          },
          industries: {
            all: {
              ...filterDefaults.industries.all,
              timeFrameDays:
                filterDefaults.industries.all.timeFrameDays ||
                initialState.filterDefaults.industries.all.timeFrameDays,
              geography:
                filterDefaults.industries.all.geography === null
                  ? initialState.filterDefaults.industries.all.geography
                  : filterDefaults.industries.all.geography,
            },
            byId: filterDefaults.industries.byId,
          },
        },
      }
    },
    [actions.saveFilterDefaults]: (state, action) => {
      const {
        entityType,
        entityId,
        factor,
        subfactor,
        timeFrameDays,
        geography,
      } = action.payload
      const newState = {
        ...state,
        filterDefaults: deepCopy(state.filterDefaults),
      }
      const newFilterDefaults = {
        factor,
        subfactor,
        timeFrameDays,
        geography,
      }
      if (entityType === ENTITY_TYPES.COMPANY) {
        if (entityId) {
          newState.filterDefaults.companies.byId[entityId] = newFilterDefaults
        } else {
          newState.filterDefaults.companies.all = newFilterDefaults
        }
      } else if (entityType === ENTITY_TYPES.INDUSTRY) {
        if (entityId) {
          newState.filterDefaults.industries.byId[entityId] = newFilterDefaults
        } else {
          newState.filterDefaults.industries.all = newFilterDefaults
        }
      } else {
        throw new Error(`Invalid entityType '${entityType}'.`)
      }
      return newState
    },
    [actions.updateflaggedArticleCount]: (state, action) => ({
      ...state,
      flaggedArticleCount: action.payload,
    }),
    [actions.clearToken]: getInitialState,
  },
  getInitialState(),
)
