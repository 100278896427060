import classNames from 'classnames'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import React from 'react'

import Button from 'app/common/Button'
import InputBlock from 'app/common/InputBlock'
import RadioButton from 'app/common/RadioButton'
import SizedContainer from 'app/common/SizedContainer'
import TextBox from 'app/common/TextBox'
import CompaniesTable from 'app/reusable/companies/companies-table'
import HealthVolumeMatrix from 'app/health/health-volume-matrix'
import GeographyDropdown from 'app/reusable/geography/geography-dropdown'
import {localComponent} from 'app/utils/redux'

import * as actions from './companies-display-actions'
import * as constants from './companies-display-constants'
import * as selectors from './companies-display-selectors'

import * as styles from './CompaniesDisplay.less'

const companyArticleCount = company =>
  company.healthData ? company.healthData.articleVolume : 0

function CompaniesDisplay({
  companies,
  matrixCompanies = companies,
  myCompanyIds,

  tableSort,
  nameFilter,
  letterFilter,
  geography = 0,
  shouldShowOnlyMyCompanies,
  shouldAutoSort = true,
  shouldHandleFilters = true,
  isPortalPage = false,

  currentPage,
  pageCount,

  isLoading = false,

  onSort,
  onNameFilterChange,
  onLetterFilterChange,
  onGeographyChange,
  onMyCompaniesFilterChange,
  onPageChange,
}) {
  if (shouldHandleFilters) {
    if (nameFilter) {
      companies = companies.filter(company =>
        company.name.toLowerCase().startsWith(nameFilter.toLowerCase()),
      )
    }

    if (letterFilter) {
      companies = companies.filter(company =>
        company.name.toLowerCase().startsWith(letterFilter.toLowerCase()),
      )
    }

    if (shouldShowOnlyMyCompanies) {
      companies = companies.filter(company => myCompanyIds.includes(company.id))
    }
  }

  let maxVolume = matrixCompanies.reduce(
    (acc, company) => Math.max(acc, companyArticleCount(company)),
    0,
  )

  return (
    <div className={styles.companiesDisplay}>
      <div className={classNames(styles.matrix, 'health-volume-matrix')}>
        <SizedContainer>
          <HealthVolumeMatrix
            companies={matrixCompanies}
            maxVolume={maxVolume}
            className={styles.chart}
          />
        </SizedContainer>
      </div>

      <div className={styles.myCompaniesFilters}>
        <InputBlock
          label="Show only My Companies"
          isInline={true}
          className={classNames(styles.filter)}
        >
          <RadioButton
            name="show-only-my-companies"
            isChecked={shouldShowOnlyMyCompanies}
            onChange={bind(onMyCompaniesFilterChange, null, true)}
          />
        </InputBlock>

        <InputBlock
          label="Show All Companies"
          isInline={true}
          className={classNames(styles.filter)}
        >
          <RadioButton
            name="show-only-my-companies"
            isChecked={!shouldShowOnlyMyCompanies}
            onChange={bind(onMyCompaniesFilterChange, null, false)}
          />
        </InputBlock>
      </div>

      <div className={styles.filters}>
        <div className={styles.normal}>
          <InputBlock
            label="Filter by Company Name"
            className={classNames(styles.filter, styles.companyName)}
          >
            <TextBox
              value={nameFilter}
              onChange={e => onNameFilterChange(e.target.value)}
            />
          </InputBlock>

          {onGeographyChange && (
            <InputBlock
              label="Geography"
              className={classNames(styles.filter, styles.geography)}
            >
              <GeographyDropdown
                selectedGeography={geography}
                onChange={onGeographyChange}
                className={styles.dropdown}
              />
            </InputBlock>
          )}
        </div>

        <div
          className={styles.letters}
          style={{visibility: shouldShowOnlyMyCompanies ? 'hidden' : 'visible'}}
        >
          View:{' '}
          {'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map(letter => (
            <Button
              label={letter}
              onClick={bind(
                onLetterFilterChange,
                null,
                letterFilter === letter ? '' : letter,
              )}
              isPlainText={letter !== letterFilter}
              className={styles.letter}
              key={letter}
            />
          ))}
        </div>
      </div>

      <CompaniesTable
        isHeadSticky={true}
        companies={companies}
        geography={geography}
        currentSort={tableSort}
        setSort={onSort}
        shouldAutoSort={shouldAutoSort}
        isPortalPage={isPortalPage}
        currentPage={currentPage}
        pageCount={pageCount}
        onPageChange={onPageChange}
        isLoading={isLoading}
      />
    </div>
  )
}
CompaniesDisplay.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  matrixCompanies: PropTypes.arrayOf(PropTypes.object),
  myCompanyIds: PropTypes.arrayOf(PropTypes.number).isRequired,

  tableSort: PropTypes.object,
  nameFilter: PropTypes.string.isRequired,
  letterFilter: PropTypes.string.isRequired,
  geography: PropTypes.number,
  shouldShowOnlyMyCompanies: PropTypes.bool.isRequired,
  shouldAutoSort: PropTypes.bool,
  shouldHandleFilters: PropTypes.bool,
  isPortalPage: PropTypes.bool,

  currentPage: PropTypes.number,
  pageCount: PropTypes.number,

  isLoading: PropTypes.bool,

  // Actions
  onSort: PropTypes.func,
  onNameFilterChange: PropTypes.func,
  onLetterFilterChange: PropTypes.func,
  onGeographyChange: PropTypes.func,
  onMyCompaniesFilterChange: PropTypes.func,
  onPageChange: PropTypes.func,
}

export default localComponent({
  key: constants.STATE_PROP,
  initAction: actions.init,
  deinitAction: actions.deinit,
  actions: {
    onNameFilterChange: actions.setNameFilter,
    onLetterFilterChange: actions.setLetterFilter,
    onMyCompaniesFilterChange: actions.setMyCompaniesFilter,
  },
  stateSelector: selectors.getByKey,
})(CompaniesDisplay)
