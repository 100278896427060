import PropTypes from 'prop-types'
import React from 'react'

export default function FacebookIcon({className}) {
  return (
    <svg viewBox="0 0 266.9 266.9" className={className}>
      <path
        fill="#3b5998"
        d="M252.2,266.9c8.1,0,14.7-6.6,14.7-14.7V14.7c0-8.1-6.6-14.7-14.7-14.7H14.7C6.6,0,0,6.6,0,14.7
        v237.4c0,8.1,6.6,14.7,14.7,14.7H252.2z"
      />
      <path
        fill="#FFFFFF"
        d="M184.2,266.9V163.5h34.7l5.2-40.3h-39.9V97.5c0-11.7,3.2-19.6,20-19.6l21.3,0v-36
        c-3.7-0.5-16.4-1.6-31.1-1.6c-30.8,0-51.8,18.8-51.8,53.2v29.7h-34.8v40.3h34.8v103.4H184.2z"
      />
    </svg>
  )
}
FacebookIcon.propTypes = {
  className: PropTypes.string,
}
