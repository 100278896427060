import classNames from 'classnames'
import React, {useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as chartActions from 'app/common/charts/charts-actions'
import * as chartSelectors from 'app/common/charts/charts-selectors'
import ExportChart from 'app/common/charts/export-chart'

import * as styles from './styles.less'

export default function Section({
  className,
  headerClassName,
  contentClassName,
  children,
  title,
  chartKey,
}) {
  const dispatch = useDispatch()
  const activeSection = useRef(null)
  const askForSectionRef = useSelector(chartSelectors.getAskForSectionRef)

  useEffect(() => {
    if (
      askForSectionRef &&
      askForSectionRef.chartKey &&
      activeSection &&
      activeSection.current &&
      askForSectionRef.chartKey === chartKey
    ) {
      const section = activeSection.current
      dispatch(chartActions.setSectionRef({[chartKey]: section}))
    }
  })

  const exportButtons = chartKey ? <ExportChart chartKey={chartKey} /> : null

  return (
    <div
      className={classNames(styles.section, className)}
      ref={section => (activeSection.current = section)}
    >
      <div className={classNames(styles.header, headerClassName)}>
        {title}
        {exportButtons}
      </div>

      <div className={classNames(styles.content, contentClassName)}>
        {children}
      </div>
    </div>
  )
}
