import {handleActions} from 'redux-actions'
import {LOCATION_CHANGED} from 'redux-little-router'

import {SORT_DIRECTIONS} from 'app/constants'
import routes from 'app/routes'

import * as actions from './companies-page-actions'
import {PAGE_SIZE} from 'app/companies-page/companies-page-constants'

const getInitialState = () => ({
  companyIds: null,
  tableSort: {column: 'health', direction: SORT_DIRECTIONS.ASC},
  currentPage: 1,
  totalCompanyCount: null,
  nameFilter: '',
  letterFilter: '',
  geography: 0,
  shouldShowOnlyMyCompanies: true,
  isLoading: false,
})

export default handleActions(
  {
    [LOCATION_CHANGED]: (state, action) =>
      action.payload.route === routes.companies ? state : getInitialState(),
    [actions.setCompanyIds]: (state, action) => ({
      ...state,
      companyIds: action.payload,
    }),
    [actions.setTotalCompanyCount]: (state, action) => ({
      ...state,
      totalCompanyCount: action.payload,

      // Make sure that we don't set an invalid page number.
      currentPage: Math.max(
        Math.min(state.currentPage, Math.ceil(action.payload / PAGE_SIZE)),
        1,
      ),
    }),
    [actions.setSort]: (state, action) => ({
      ...state,
      tableSort: action.payload,
    }),
    [actions.setNameFilter]: (state, action) => ({
      ...state,
      nameFilter: action.payload,
    }),
    [actions.setLetterFilter]: (state, action) => ({
      ...state,
      letterFilter: action.payload,
    }),
    [actions.setGeography]: (state, action) => ({
      ...state,
      geography: action.payload,
    }),
    [actions.setMyCompaniesFilter]: (state, action) => ({
      ...state,
      shouldShowOnlyMyCompanies: action.payload,
    }),
    [actions.setPage]: (state, action) => ({
      ...state,
      currentPage: Math.max(action.payload, 1),
    }),
    [actions.setIsLoading]: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
  },
  getInitialState(),
)
