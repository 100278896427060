import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import domtoimage from 'dom-to-image-more'
import saveFile from 'save-as-file'
import moment from 'moment'

import Modal from 'app/common/Modal'
import RadioButton from 'app/common/RadioButton'
import Button from 'app/common/Button'
import { arrayToCsv, downloadCsv } from 'app/common/exporting'

import * as styles from 'app/litigation/modals/Modals.less'
import * as actions from 'app/litigation/litigations-actions'
import DataIcon from 'app/litigation/icons/DataIcon'
import PdfIcon from 'app/litigation/icons/PdfIcon'
import CustomRadioButton from 'app/litigation/CustomRadioButton/CustomRadioButton'

const OUTPUTS = {
  CSV: {
    id: 'csv',
    name: 'CSV',
    icon: null,
    extension: 'csv',
    description: 'Only exports raw data',
  },
  PNG: {
    id: 'png',
    name: 'PNG',
    icon: null,
    exporterFunction: domtoimage.toPng,
    extension: 'png',
    description: 'Chart with transparency',
  },
  JPG: {
    id: 'jpeg',
    name: 'JPEG',
    icon: null,
    exporterFunction: domtoimage.toJpeg,
    extension: 'jpeg',
    description: 'Chart with white background',
  },
  SVG: {
    id: 'svg',
    name: 'SVG',
    icon: null,
    exporterFunction: domtoimage.toSvg,
    extension: 'svg',
    description: 'Chart in a vector format',
  },
}

const exportTypes = {
  DATA: { id: 'data', buttonText: 'Export Data', icon: <DataIcon className={styles.icon}/> },
  REPORT: { id: 'report', buttonText: 'Generate Report', icon: <PdfIcon className={styles.icon}/> },
}

const ExportBox = ({
  outputType,
  isChecked,
  onChange,
}) => {
  return (
    <div className={classNames(styles.dataExportBox, { [styles.checked]: isChecked })} onClick={onChange}>
      <div>
        <CustomRadioButton
          className={classNames(styles.radioButton, { [styles.checked]: isChecked })}
          id={outputType.id} isChecked={isChecked} onChange={onChange}
        />
        <label htmlFor={outputType.id} className={classNames({ [styles.checked]: isChecked })}>{outputType.name}</label>
      </div>
      <p>{outputType.description}</p>
    </div>
  )
}

const ExportData = ({
  selectedOutput,
  setSelectedOutput,
}) => {
  return (
    <div className={styles.exportContainer}>
      <h3>Select how you want to export it:</h3>
      <div className={styles.dataExportTypeContainer}>
        {Object.keys(OUTPUTS).map((output, index) => {
          return (
            <ExportBox
              key={`box-${index}`}
              outputType={OUTPUTS[output]}
              isChecked={selectedOutput === OUTPUTS[output].id}
              onChange={() => setSelectedOutput(OUTPUTS[output].id)}
            />
          )
        })}
      </div>
    </div>
  )
}

const ExportPdf = () => {
  return (
    <div className={classNames(styles.exportContainer, styles.report)}>
      <PdfIcon className={styles.bigIcon}/>
      <p>You will be redirected to a new tab where you can download <br/>the report as a PDF document, including the
        charts and data.</p>
    </div>
  )
}

export default function ExportModal({ visible, onClose, outputFileName, csvData, chartRef }) {
  const dispatch = useDispatch()
  const [selectedExportType, setSelectedExportType] = useState(exportTypes.DATA)
  const [selectedOutput, setSelectedOutput] = useState(OUTPUTS.CSV.id)
  const [error, setError] = useState(null)
  const [isConfirming, setIsConfirming] = useState(false)

  const clearErrors = () => {
    setError(null)
  }

  const _onClose = (...args) => {
    setSelectedOutput(OUTPUTS.CSV.id)
    clearErrors()
    onClose(...args)
  }

  const onSave = () => {
    clearErrors()
    setIsConfirming(true)
    const outputFilePrefix = `${outputFileName}-${moment().format('YYYY-MM-DD')}`
    try {
      if (selectedOutput === OUTPUTS.CSV.id) {
        const data = arrayToCsv(csvData)
        downloadCsv(`${outputFilePrefix}.${OUTPUTS.CSV.extension}`, data)
      } else if ([OUTPUTS.JPG.id, OUTPUTS.PNG.id, OUTPUTS.SVG.id].includes(selectedOutput)) {
        if (chartRef.current) {
          const output = Object.values(OUTPUTS).find(_output => _output.id === selectedOutput)
          // We need to override the font family for the image export, because Lato is a non-standard font
          // family, that we pull from Google Fonts. However, the image export cannot include this font family in the
          // output styles without some magic that is more complicated than just applying system standard fonts
          // to the charts while we take a snapshot of the elements. To prevent flickering in the background,
          // clone the element and wrap it in a div, which has the correct font families applied.
          const wrapper = document.createElement('div')
          const clone = chartRef.current.cloneNode(true)
          wrapper.classList.add('font-override-for-export-modal')
          wrapper.appendChild(clone)
          document.body.appendChild(wrapper) // needed so css actually applies to the elements.
          output.exporterFunction(
            wrapper,
            {
              bgcolor: '#fff',
              width: chartRef.current.getBoundingClientRect().width,
              height: chartRef.current.getBoundingClientRect().height,
              filter: node => node.nodeName !== 'BUTTON'
            }
          ).then(dataUrl => {
            saveFile(dataUrl, `${outputFilePrefix}.${output.extension}`)
          }).catch(() => {
            setError('Error while exporting chart.')
          }).finally(() => {
            clone.remove()
            wrapper.remove()
          })
        } else {
          setError('Cannot find chart on the page.')
        }
      } else {
        setError('This output type is not yet supported.')
      }
    } catch (e) {
      setError('Unknown error, please try again later.')
    }

    setIsConfirming(false)
  }

  const onExportButtonClick = () => {
    if (selectedExportType === exportTypes.DATA) {
      onSave()
    } else if (selectedExportType === exportTypes.REPORT) {
      dispatch(actions.openSectorListReport())
    }
  }

  return (
    <>
      {visible &&
        <Modal
          onClose={_onClose}
          shouldShowCloseButton={true}
          className={classNames(styles.modal, styles.exportMd)}
          contentClassName={styles.noPadding}
        >
          <h2>Export data, charts or generate complete report</h2>
          <hr/>

          {error &&
            <div className={styles.errorBox}>{error}</div>
          }

          <div className={styles.exportRibbon}>
            {Object.keys(exportTypes).map(exportType => {
              return (
                <React.Fragment key={exportTypes[exportType].id}>
                  <RadioButton
                    id={exportTypes[exportType].id}
                    isChecked={selectedExportType.id === exportTypes[exportType].id}
                    onChange={() => setSelectedExportType(exportTypes[exportType])}
                  />
                  <label
                    htmlFor={exportTypes[exportType].id}>
                    {exportTypes[exportType].icon} {exportTypes[exportType].buttonText}
                  </label>
                </React.Fragment>
              )
            })}
          </div>

          {selectedExportType === exportTypes.DATA &&
            <ExportData
              selectedOutput={selectedOutput}
              setSelectedOutput={setSelectedOutput}
            />}

          {selectedExportType === exportTypes.REPORT &&
            <ExportPdf/>
          }

          <hr/>
          <div className={classNames(styles.buttons, styles.export)}>
            <Button
              label='Close'
              onClick={_onClose}
              isPlainText
              className={styles.button}
            />

            <Button
              label={
                selectedExportType === exportTypes.DATA ? (isConfirming ? 'Exporting...' : 'Export') : ('Generate')
              }
              onClick={onExportButtonClick}
              isDisabled={isConfirming}
            />
          </div>
        </Modal>}
    </>
  )
}
