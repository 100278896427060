import PropTypes from 'prop-types'
import React from 'react'

import InfoTooltip from 'app/common/InfoTooltip'
import FactorHealthCard from 'app/reusable/health/factor-health-card'

const FactorHealthBadgeTooltip = ({left, right, top, healthData, factor}) => (
  <InfoTooltip left={left} right={right} top={top}>
    <FactorHealthCard healthData={healthData} factor={factor} />
  </InfoTooltip>
)
FactorHealthBadgeTooltip.propTypes = {
  left: PropTypes.number,
  right: PropTypes.number,
  top: PropTypes.number,
  healthData: PropTypes.object.isRequired,
  factor: PropTypes.string.isRequired,
}
export default FactorHealthBadgeTooltip
