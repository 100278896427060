import PropTypes from 'prop-types'
import React, {PureComponent} from 'react'

import {getColumnsFromChildren, getSortedData} from 'app/common/Table'

import PaginatedTable from './PaginatedTable'

export default class AutoPaginatedTable extends PureComponent {
  static propTypes = {
    pageSize: PropTypes.number,
    onPageChange: PropTypes.func,
    // Also takes any props that Table takes
  }

  static defaultProps = {
    pageSize: 20,
    onPageChange() {},
  }

  state = {
    currentPage: 1,
    sort: undefined,
  }

  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      sort: props.defaultSort || {},
    }
  }

  render() {
    const {children, pageSize, onPageChange, data, ...restProps} = this.props
    const {currentPage} = this.state
    const sort = this.props.sort || this.state.sort
    const pageCount = Math.ceil(data.length / pageSize)
    const realCurrentPage = currentPage >= pageCount ? pageCount : currentPage

    const column = getColumnsFromChildren(children).find(
      column => column.name === sort.column,
    )
    const sortedData =
      !this.props.ignoreSort && column
        ? getSortedData(data, column, sort.direction)
        : data

    const startIndex = (realCurrentPage - 1) * pageSize
    const endIndex = startIndex + pageSize
    const pageData = sortedData.slice(startIndex, endIndex)

    return (
      <PaginatedTable
        data={pageData}
        pageCount={pageCount}
        currentPage={realCurrentPage}
        onPageChange={this.changePage.bind(this)}
        onSortChange={this.sortChanged.bind(this)}
        {...restProps}
      >
        {children}
      </PaginatedTable>
    )
  }

  changePage(page) {
    this.setState({currentPage: page})
    this.props.onPageChange(page)
  }

  sortChanged({column, direction}) {
    this.setState({sort: {column, direction}})
  }
}
