import classNames from 'classnames'
import is from 'is'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'

import InsightsLogo from 'app/common/logos/insights-logo'
import ArticleLink from 'app/common/ArticleLink'
import Columns, {Column} from 'app/common/Columns'
import {LoadingMessage} from 'app/common/loading-message'
import HealthRangeComparison from 'app/reusable/health/health-range-comparison'
import Link from 'app/common/NavigationLink'
import FactorIcon from 'app/common/FactorIcon'
import VolumeSummary from 'app/common/VolumeSummary'
import Orm from 'app/framework/Orm'
import HealthScoreTrend from 'app/health/HealthScoreTrend'
import {Article, Company} from 'app/models'
import {factorDisplayName, formatDate, viaSource} from 'app/strings'
import urls from 'app/urls'

import StoryTimeline from 'app/storylines/story-timeline'

import {MIN_TIMELINE_DAYS} from './interim-page-constants'

import * as styles from './InterimPage.less'

function InterimPage({article, company}) {
  if (is.undefined(article) || is.undefined(company)) {
    return <LoadingMessage />
  }

  const {factor, story} = article
  const storyline = story && story.storyline

  const timelineSection = story && (
    <Section
      title={`${factorDisplayName(factor)} Factor Story Timeline for “${
        storyline.summary
      }”`}
      contentClassName={styles.eventTimeline}
    >
      <StoryTimeline
        stories={storyline.stories}
        startDate={storyline.startDate}
        endDate={storyline.endDate}
        factor={factor}
      />
    </Section>
  )

  return (
    <div className={styles.page}>
      <Header factor={factor} />

      <div className={styles.content}>
        <ArticleSummary article={article} />

        <Columns>
          <Column>
            <Section
              title="Health Factor Comparison"
              className={styles.healthFactorComparison}
            >
              <HealthRangeComparison
                healthData={company.healthData}
                factor={factor}
              />
            </Section>
          </Column>

          <Column>
            <Section
              title={`${factorDisplayName(factor)} 30 Day Summary`}
              contentClassName={styles.factorSummary}
            >
              <VolumeSummary company={company} factor={factor} />
            </Section>
          </Column>
        </Columns>

        <Section
          title="Health Factor Trend"
          contentClassName={styles.healthFactorTrend}
        >
          <HealthScoreTrend
            dailyHealthData={company.dailyHealthData}
            factorDailyHealthData={company.factorDailyHealthData}
            subfactorDailyHealthData={company.subfactorDailyHealthData}
            factor={factor}
            timeFrameDays={MIN_TIMELINE_DAYS}
          />
        </Section>

        {timelineSection}
      </div>
    </div>
  )
}
InterimPage.propTypes = {
  article: PropTypes.object,
  company: PropTypes.object,
}
export default connect(state => {
  const orm = Orm.withEntities(state.entities)
  const {articleId, companyId} = state.interimPage
  const article = articleId && orm.getById(Article, articleId)
  const company = companyId && orm.getById(Company, companyId)
  return {article, company}
})(InterimPage)

function Header({factor}) {
  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <div className={styles.logoSection}>
          <InsightsLogo
            className={classNames(
              styles.logo,
              styles[process.env.MANZAMA_BRAND],
            )}
            color={InsightsLogo.COLORS.LIGHT}
          />
        </div>

        <div className={styles.factorSection}>
          <FactorIcon factor={factor} className={styles.icon} />
          <span className={styles.label}>{factorDisplayName(factor)}</span>
        </div>
      </div>
    </div>
  )
}
Header.propTypes = {
  factor: PropTypes.string.isRequired,
}

function ArticleSummary({article}) {
  const {story} = article
  const storyline = story && story.storyline
  const storylineTeaser = storyline ? (
    <h3 className={styles.storylineTeaser}>
      This article is from the storyline &ldquo;
      {storyline.summary}
      .&rdquo; &nbsp;
      <Link href={urls.storylineView(storyline.id)} className={styles.link}>
        Click here to view in {process.env.BRAND_NAME}{' '}
        {process.env.PRODUCT_NAME}.
      </Link>
    </h3>
  ) : (
    <h3 className={styles.storylineTeaser}>
      <Link
        href={urls.companyOverview(article.companyId)}
        className={styles.link}
      >
        Click here to view the {article.company.name} Company Overview in
        {process.env.BRAND_NAME} {process.env.PRODUCT_NAME}.
      </Link>
    </h3>
  )

  return (
    <div className={styles.article}>
      <h1 className={styles.head}>
        <ArticleLink
          article={article}
          shouldTargetNewWindow={true}
          className={styles.headline}
        >
          {article.headline}
        </ArticleLink>

        <Link
          href={urls.companyOverview(story.company.id)}
          className={styles.overviewLink}
        >
          View {story.company.name} Company Overview
        </Link>
      </h1>
      <h3 className={styles.subHeadline}>
        <span className={styles.date}>{formatDate(article.date)}</span>
        &nbsp;
        {viaSource(article)}
      </h3>
      <p className={styles.excerpt}>{article.description}</p>

      {storylineTeaser}
    </div>
  )
}

function Section({title, children, className, contentClassName}) {
  return (
    <div className={classNames(styles.section, className)}>
      <div className={styles.title}>{title}</div>
      <div className={classNames(styles.sectionContent, contentClassName)}>
        {children}
      </div>
    </div>
  )
}
