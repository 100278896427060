import React from 'react'

import Table from '../../email-server-common/common/Table'
import Row from '../../email-server-common/common/Row'
import Cell from '../../email-server-common/common/Cell'
import EsgScore from './EsgScore'
import { ESG_PILLAR_IDS_BY_ESG_PILLAR, ESG_PILLARS } from '../../app/constants'
import { colors } from './constants'
import { STATIC_FILES_URL } from '../../app/constants'
import { trueRound } from '../../app/utils/numbers'

export default function EsgScoreStrip({ entity, emailType }) {
  const style = {
    padding: '0 10px',
    textAlign: 'center',
  }

  const getEsgScore = factorId => {
    return entity.healthBadge.factors.find(factor => factor.factorId === factorId) ?
      <EsgScore score={entity.healthBadge.factors.find(factor => factor.factorId === factorId).healthScore}/> : '-'
  }
  const esgScores = {
    governance: getEsgScore(ESG_PILLAR_IDS_BY_ESG_PILLAR[ESG_PILLARS.GOVERNANCE]),
    prosperity: getEsgScore(ESG_PILLAR_IDS_BY_ESG_PILLAR[ESG_PILLARS.PROSPERITY]),
    people: getEsgScore(ESG_PILLAR_IDS_BY_ESG_PILLAR[ESG_PILLARS.PEOPLE]),
    planet: getEsgScore(ESG_PILLAR_IDS_BY_ESG_PILLAR[ESG_PILLARS.PLANET]),
  }

  return (
    <Table style={{ backgroundColor: colors.stripColor }}>
      <Row><Cell height='5' colSpan='5'/></Row>
      {emailType === 'daily' ?
        <Row>
          <Cell style={style}>Governance</Cell>
          <Cell style={style}>Prosperity</Cell>
          <Cell style={style}>People</Cell>
          <Cell style={style}>Planet</Cell>
          <Cell style={style}>ESG Health Score</Cell>
        </Row>
        :
        <Row>
          <Cell style={{ padding: '0 30px', textAlign: 'center'}}>Overall ESG Score</Cell>
          <Cell style={style}>ESG Health Score change this week</Cell>
        </Row>
      }
      {emailType === 'daily' ?
        <Row>
          <Cell style={style}>{esgScores.governance}</Cell>
          <Cell style={style}>{esgScores.prosperity}</Cell>
          <Cell style={style}>{esgScores.people}</Cell>
          <Cell style={style}>{esgScores.planet}</Cell>
          <Cell style={style}><EsgScore score={entity.healthBadge.healthScore}/></Cell>
        </Row>
        :
        <Row>
          <Cell style={{ padding: '0 30px', textAlign: 'center', fontSize: 20, fontWeight: 'bold'}}>
            <EsgScore score={entity.healthBadge.healthScore}/>
          </Cell>
          { trueRound(entity.healthScoreChange) === 0 ?
            <Cell style={{padding: '0 10px', textAlign: 'center', fontWeight: 'bold'}}>No change</Cell>
            :
            <Cell style={{ padding: '0 10px', textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>
              {trueRound(entity.healthScoreChange) > 0 ?
                <>
                  <img src={`${STATIC_FILES_URL}/imgs/up-arrow-icon.jpg`} alt='Entity Icon' width='10'/>
                  <EsgScore score={entity.healthScoreChange} signed={false}/>
                </>
                :
                <>
                  <img src={`${STATIC_FILES_URL}/imgs/down-arrow-icon.jpg`} alt='Entity Icon' width='10'/>
                  <EsgScore score={entity.healthScoreChange} signed={false}/>
                </>
              }
            </Cell>
          }
        </Row>
      }
      <Row><Cell height='5' colSpan='5'/></Row>
    </Table>
  )
}
