import {identity} from 'ramda'
import React from 'react'

import Cell from 'email-server-common/common/Cell'
import Row from 'email-server-common/common/Row'

const Table = ({children, ...props}) => {
  if (!children) return null

  const directChildren = React.Children.map(children, identity)
  const hasType = (type, child) =>
    // Also check array elements if this is an array.
    Array.isArray(child)
      ? child.every(grandChild => hasType(type, grandChild))
      : child.type === type
  const makeTypeChecker = type => child => hasType(type, child)
  const isRow = makeTypeChecker(Row)
  const isCell = makeTypeChecker(Cell)

  // Every child is not a Row, so figure out how to wrap them properly.
  if (!directChildren.every(isRow)) {
    // Every child is a Cell, so wrap the whole thing in a Row.
    if (directChildren.every(isCell)) {
      children = <Row>{children}</Row>
    } else if (directChildren.some(isRow) || directChildren.some(isCell)) {
      // Only some children are Rows or Cells, which isn't allowed.
      throw new Error(
        'Children of Table should either be all Rows, all Cells, or all something else.',
      )
    } else {
      // There are no Rows or Cells here, so wrap the children appropriately.
      children = (
        <Row>
          <Cell>{children}</Cell>
        </Row>
      )
    }
  }

  return (
    <table border={0} cellPadding={0} cellSpacing={0} width="100%" {...props}>
      {children}
    </table>
  )
}
export default Table
