import classNames from 'classnames'
import invariant from 'invariant'
import PropTypes from 'prop-types'
import React from 'react'

import {ASPECTS} from 'app/constants'

import * as styles from './styles.less'
import litigationIconUrl from 'static/images/aspect-icons/litigation.svg'
import opinionIconUrl from 'static/images/aspect-icons/opinion.svg'
import rumorIconUrl from 'static/images/aspect-icons/rumor.svg'

export default function AspectIcon({aspect, className, ...restProps}) {
  const iconUrl = {
    [ASPECTS.LITIGATION]: litigationIconUrl,
    [ASPECTS.OPINION]: opinionIconUrl,
    [ASPECTS.RUMOR]: rumorIconUrl,
  }[aspect]
  invariant(iconUrl, `AspectIcon was given an invalid aspect '${aspect}.`)
  return (
    <span className={classNames(styles.icon, className)} {...restProps}>
      <img src={iconUrl} />
    </span>
  )
}
AspectIcon.propTypes = {
  aspect: PropTypes.oneOf(Object.values(ASPECTS)).isRequired,
  className: PropTypes.string,
}
