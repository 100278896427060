import {all, put, takeLatest} from 'redux-saga/effects'

import * as actions from './storyline-modal-actions'
import {fetchStories, fetchStoryline} from 'app/api/api-saga-helpers'

function* modalShown({payload: {storylineId, storyId}}) {
  if (storyId) {
    yield put(actions.setIsLoading(true))
    yield yield* fetchStories({storyIds: [storyId]})
    yield put(actions.setIsLoading(false))
  } else if (storylineId) {
    yield put(actions.setIsLoading(true))
    yield yield* fetchStoryline({id: storylineId})
    yield put(actions.setIsLoading(false))
  } else {
    throw new Error(
      'Neither a `storylineId` nor a `storyId` was given in the payload for action `storyline-modal-actions/showModal`.',
    )
  }
}

function* storySelected(action) {
  yield put(actions.setIsLoading(true))
  yield* fetchStories({storyIds: [action.payload]})
  yield put(actions.setIsLoading(false))
}

export default function* storylineModalSaga() {
  yield all([
    takeLatest(actions.showModal, modalShown),
    takeLatest(actions.selectStory, storySelected),
  ])
}
