// These values should match what is in palette.less.

export const textColorVeryLight = '#E8E9E5'
export const textColorLight = '#888888'
export const textColorNormal = '#444444'

export const backgroundColorLight = '#F2F2F2'
export const backgroundColorDark = '#222222'

export const lineColorLight = '#E2E2E2'

export const mainColor = '#035C8B'

export const healthColorNegative = '#AF3C3C'
export const healthColorNeutral = '#666666'
export const healthColorPositive = '#1C6C96'
