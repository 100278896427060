import React, { useState } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import Button from 'app/common/Button'
import Modal from 'app/common/Modal'
import TextBox from 'app/common/TextBox'
import InputBlock from 'app/common/InputBlock'

import * as constants from 'app/litigation/litigations-constants'
import * as actions from 'app/litigation/litigations-actions'
import * as selectors from 'app/litigation/litigations-selectors'
import * as styles from 'app/litigation/modals/Modals.less'

export default function SaveLitigationSearchModal({ visible, onClose }) {
  const [savedSearchName, setSavedSearchName] = useState('')
  const dispatch = useDispatch()
  const confirmationStatus = useSelector(selectors.getAddLitigationSearchStatus)
  const error = useSelector(selectors.getAddLitigationSearchError)
  const onSave = name => dispatch(actions.saveLitigationSearch(name))
  const isConfirming = confirmationStatus === constants.ADD_REMOVE_LITIGATION_SEARCH_STATES.IN_PROGRESS
  const isSuccessful = confirmationStatus === constants.ADD_REMOVE_LITIGATION_SEARCH_STATES.SUCCESS
  const isError = confirmationStatus === constants.ADD_REMOVE_LITIGATION_SEARCH_STATES.ERROR

  const _onClose = (...args) => {
    dispatch(actions.resetSaveLitigationSearch())
    setSavedSearchName('')
    onClose(...args)
  }

  return (
    <>
      {visible &&
        <Modal
          onClose={_onClose}
          shouldShowCloseButton={true}
          className={styles.modal}
        >
          <h2>Save Litigation Search</h2>

          {isSuccessful ?
            <h3>Successfully saved the search.</h3>
            :
            <>
              <InputBlock
                label={'Name'}
                className={styles.inputBlock}
              >
                <TextBox
                  autoFocus={true}
                  value={savedSearchName}
                  onChange={e => setSavedSearchName(e.target.value)}
                  className={classNames(styles.textBox, { [styles.textBoxError]: isError })}
                />
                {isError && <p className={styles.errorText}>{error}</p>}
              </InputBlock>
            </>
          }


          <div className={styles.buttons}>
            <Button
              label='Close'
              onClick={_onClose}
              isPlainText
              className={styles.button}
            />

            {!isSuccessful &&
              <Button
                label={isConfirming ? 'Saving...' : 'Save'}
                onClick={() => onSave(savedSearchName)}
                isDisabled={isConfirming}
              />}
          </div>
        </Modal>
      }
    </>
  )
};

