import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import * as login from 'app/login'
import urls from 'app/urls'

function ArticleLink({
  article,
  currentUserId,
  shouldTargetNewWindow = false,
  children,
  id,
  className,
}) {
  const url =
    process.env.NODE_ENV === 'production' && !article.isOld()
      ? urls.pyapp.article({
          contentDirectorId: article.contentDirectorId,
          userId: currentUserId,
        })
      : article.url
  return (
    <a
      href={url}
      id={id}
      className={className}
      target={shouldTargetNewWindow ? '_blank' : undefined}
    >
      {children}
    </a>
  )
}
ArticleLink.propTypes = {
  article: PropTypes.object.isRequired,
  currentUserId: PropTypes.number.isRequired,
  shouldTargetNewWindow: PropTypes.bool,
}

export default connect(
  createSelector(
    [login.selectors.getUserId],
    currentUserId => ({
      currentUserId,
    }),
  ),
)(ArticleLink)
