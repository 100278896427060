import {handleActions} from 'redux-actions'
import {LOCATION_CHANGED} from 'redux-little-router'

import routes from 'app/routes'

import * as actions from './interim-page-actions'

const getInitialState = () => ({
  articleId: undefined,
  companyId: undefined,

  timeline: {
    highlightedDay: undefined,
    highlightedGroups: [],

    showStoriesTooltip: {
      isVisible: false,
      left: undefined,
      right: undefined,
      top: undefined,
      date: undefined,
      colors: undefined,
      storyIds: undefined,
      isExpanded: undefined,
    },
  },
})

export default handleActions(
  {
    [LOCATION_CHANGED]: (state, action) =>
      action.payload.route !== routes.interimPage ? getInitialState() : state,
    [actions.setArticleId]: (state, action) => ({
      ...state,
      articleId: action.payload,
    }),
    [actions.setCompanyId]: (state, action) => ({
      ...state,
      companyId: action.payload,
    }),
  },
  getInitialState(),
)
