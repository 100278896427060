import React from 'react'

import * as styles from './DropDownChip.less'
import CrossIcon from 'app/litigation/icons/CrossIcon'

export default function DropDownChip({ label, onClose }) {

  const handleOnClick = (e) => {
    e.stopPropagation()
    onClose()
  }

  return (
    <div className={styles.chip}>
      {label}
      <CrossIcon className={styles.icon} onClick={handleOnClick}/>
    </div>
  )
}
