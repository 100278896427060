import React from 'react'
import Header from './Header'
import Table from 'email-server-common/common/Table'
import Row from 'email-server-common/common/Row'
import Cell from 'email-server-common/common/Cell'
import CompanyEsgSections from './CompanyEsgSections'
import Footer from './Footer'
import IndustryEsgSections from './IndustryEsgSections'
import { colors } from './constants'
import HtmlReactParser from 'html-react-parser'

export default function EsgEmailAlert({ data, config }) {
  const rootUrl = data.serverUrl
  // Replace the trailing / so absoluteUrl() works with paths
  const insightsRootUrl = data.insightsUrl.replace(/\/$/, '')
  const emailType = config.timeFrameDays === 7 ? 'weekly' : 'daily'
  const suffixText = HtmlReactParser(data.emailProfileAlertSuffix)
  return (
    <div>
      <style>
        {`
          body { background-color: ${colors.backgroundColor}; }
        `}
      </style>
      <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Source+Sans+Pro'/>
      <Table>
        <Row>
          <Cell
            align='center'
          >
            <Header
              data={data}
              rootUrl={rootUrl}
              emailType={emailType}
            />
            <CompanyEsgSections
              data={data}
              rootUrl={rootUrl}
              insightsRootUrl={insightsRootUrl}
              emailType={emailType}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <IndustryEsgSections
              data={data}
              rootUrl={rootUrl}
              insightsRootUrl={insightsRootUrl}
              emailType={emailType}
            />
          </Cell>
        </Row>
        {suffixText ?
          <Row>
            <Cell>
              <Table
                style={{
                  width: '600px',
                  margin: '0 auto',
                  backgroundColor: colors.contentBackgroundColor
                }}
                width='600'
                align='center'
              >
                <Row>
                  <Cell
                    style={{
                      padding: '10px 0 10px 32px',
                    }}>
                    {suffixText}
                  </Cell>
                </Row>

              </Table>
            </Cell>
          </Row> : ''
        }
        <Row>
          <Cell>
            <Footer
              rootUrl={rootUrl}
              copyrightHolderName={data.copyrightHolderName}
              contactAddress={data.contactAddress}
              contactPhone={data.contactPhone}
              enableUpdateYourProfile={data.enableUpdateYourProfile}
            />
          </Cell>
        </Row>
      </Table>
    </div>
  )
}
