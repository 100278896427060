import { handleActions } from 'redux-actions'
import { LOCATION_CHANGED } from 'redux-little-router'

import routes from 'app/routes'

import * as actions from './litigations-actions'
import { ADD_REMOVE_LITIGATION_SEARCH_STATES, getInitialFilterState, FILTER_CHECKBOXES } from './litigations-constants'

const getInitialState = () => ({
  isLoading: true,
  isOnMainPage: true,
  mainPage: {
    isLoading: true,
    sectors: [],
    allSectors: [],
    mySectors: [],
    myCompanies: [],
    selectedCompanyIds: [],
    selectedSectors: [],
    companyCountMinimum: 0,
    companyCountMaximum: 10000,
    litigationAffectedCompaniesMinimum: 0,
    litigationAffectedCompaniesMaximum: 100,
    selectedFilter: FILTER_CHECKBOXES.allIndustries,
  },
  sector: {
    isLoading: true,
    id: null,
    companyTimeSeries: [],
    selectedCompanyTimeSeries: [],
    filters: getInitialFilterState(),
    stories: {
      count: 0,
      offset: 0,
      infiniteScrollLoading: false,
      data: null,
      error: false,
    },
  },
  save: {
    isLoading: true,
    currentLoaded: null,
    count: 0,
    offset: 0,
    data: null,
    error: false,
    orderBy: null,
    remove: {
      item: null,
      status: ADD_REMOVE_LITIGATION_SEARCH_STATES.UNDEFINED,
    },
    add: {
      status: ADD_REMOVE_LITIGATION_SEARCH_STATES.UNDEFINED,
      error: null,
    }
  },
  isPdfDownloading: false,
})

export default handleActions(
  {
    [actions.setSectors]: (state, action) => ({
      ...state,
      mainPage: { ...state.mainPage, sectors: action.payload }
    }),
    [actions.setSelectedSectors]: (state, action) => ({
      ...state,
      mainPage: { ...state.mainPage, selectedSectors: action.payload },
    }),
    [actions.setCompanyCountMinimum]: (state, action) => ({
      ...state,
      mainPage: { ...state.mainPage, companyCountMinimum: action.payload },
    }),
    [actions.setCompanyCountMaximum]: (state, action) => ({
      ...state,
      mainPage: { ...state.mainPage, companyCountMaximum: action.payload },
    }),
    [actions.setLitigationAffectedCompaniesMinimum]: (state, action) => ({
      ...state,
      mainPage: { ...state.mainPage, litigationAffectedCompaniesMinimum: action.payload },
    }),
    [actions.setLitigationAffectedCompaniesMaximum]: (state, action) => ({
      ...state,
      mainPage: { ...state.mainPage, litigationAffectedCompaniesMaximum: action.payload },
    }),
    [actions.setMainPageLoading]: (state, action) => ({
      ...state,
      mainPage: { ...state.mainPage, isLoading: action.payload },
    }),
    [actions.setSelectedSectorId]: (state, action) => ({
      ...state,
      sector: { ...state.sector, id: action.payload },
    }),
    [actions.setCompanyTimeSeries]: (state, action) => ({
      ...state,
      sector: { ...state.sector, companyTimeSeries: action.payload },
    }),
    [actions.setCompanyFilters]: (state, action) => ({
      ...state,
      sector: {
        ...state.sector,
        filters: { ...state.sector.filters, ...action.payload },
        stories: { ...state.sector.stories, offset: 0 }
      },
    }),
    [actions.setCompanyFiltersWithoutFetching]: (state, action) => ({
      ...state,
      sector: {
        ...state.sector,
        filters: { ...state.sector.filters, ...action.payload },
        stories: { ...state.sector.stories, offset: 0 }
      },
    }),
    [actions.setSectorLoading]: (state, action) => ({
      ...state,
      sector: { ...state.sector, isLoading: action.payload },
    }),
    [actions.setStories]: (state, action) => ({
      ...state,
      sector: { ...state.sector, stories: { ...state.sector.stories, ...action.payload } },
    }),
    [actions.setStoryFetchError]: (state, action) => ({
      ...state,
      sector: { ...state.sector, stories: { ...state.sector.stories, error: action.payload } },
    }),
    [actions.setStoryCount]: (state, action) => ({
      ...state,
      sector: { ...state.sector, stories: { ...state.sector.stories, count: action.payload } },
    }),
    [actions.fetchMoreStories]: (state) => ({
      ...state,
      sector: {
        ...state.sector,
         stories: {
          ...state.sector.stories,
          infiniteScrollLoading: true,
          offset: state.sector.stories.offset += 20
        }
      },
    }),
    [actions.fetchMoreStoriesSuccessful]: (state, action) => ({
      ...state,
      sector: {
        ...state.sector,
        stories: {
          ...state.sector.stories,
          data: [
            ...(state.sector.stories.data || []),
            ...action.payload,
          ],
          infiniteScrollLoading: false,
        }
      },
    }),
    [actions.fetchIndustriesAndCompanies]: (state) => ({
      ...state,
      mainPage: { ...state.mainPage, isLoading: true },
    }),
    [actions.fetchCompaniesForIndustries]: (state) => ({
      ...state,
      sector: { ...state.sector, isLoading: true },
    }),
    [actions.fetchLitigationSavedSearches]: (state) => ({
      ...state,
      save: { ...state.save, isLoading: true, offset: 0, },
    }),
    [actions.fetchLitigationSavedSearchesSuccessful]: (state, action) => ({
      ...state,
      save: { ...state.save, isLoading: false, error: false, data: action.payload.data, count: action.payload.count, },
    }),
    [actions.fetchMoreLitigationSavedSearches]: (state, action) => ({
      ...state,
      save: { ...state.save, isLoading: true, offset: state.save.offset + action.payload, },
    }),
    [actions.fetchMoreLitigationSavedSearchesSuccessful]: (state, action) => ({
      ...state,
      save: {
        ...state.save,
        isLoading: false,
        error: false,
        data: [...state.save.data, ...action.payload.data],
      },
    }),
    [actions.fetchLitigationSavedSearchesError]: (state, action) => ({
      ...state,
      save: {
        ...state.save,
        isLoading: false,
        error: 'error',
      },
    }),

    [actions.resetRemoveLitigationSavedSearch]: (state) => ({
      ...state,
      save: { ...state.save, remove: { item: null, status: ADD_REMOVE_LITIGATION_SEARCH_STATES.UNDEFINED } },
    }),
    [actions.removeLitigationSavedSearch]: (state, action) => ({
      ...state,
      save: {
        ...state.save,
        remove: { item: action.payload, status: ADD_REMOVE_LITIGATION_SEARCH_STATES.IN_PROGRESS }
      },
    }),
    [actions.removeLitigationSavedSearchSuccessful]: (state) => ({
      ...state,
      save: { ...state.save, remove: { status: ADD_REMOVE_LITIGATION_SEARCH_STATES.SUCCESS } },
    }),
    [actions.removeLitigationSavedSearchError]: (state) => ({
      ...state,
      save: { ...state.save, remove: { status: ADD_REMOVE_LITIGATION_SEARCH_STATES.ERROR } },
    }),
    [actions.setLitigationSavedSearchOrderBy]: (state, action) => ({
      ...state,
      save: { ...state.save, orderBy: action.payload, offset: 0, count: 0, },
    }),

    [actions.selectLitigationSavedSearch]: (state, action) => ({
      ...state,
      save: { ...state.save, currentLoaded: action.payload },
    }),

    [actions.saveLitigationSearch]: (state) => ({
      ...state,
      save: { ...state.save, add: { status: ADD_REMOVE_LITIGATION_SEARCH_STATES.IN_PROGRESS } },
    }),
    [actions.saveLitigationSearchSuccessful]: (state) => ({
      ...state,
      save: { ...state.save, add: { status: ADD_REMOVE_LITIGATION_SEARCH_STATES.SUCCESS } },
    }),
    [actions.saveLitigationSearchError]: (state, action) => ({
      ...state,
      save: { ...state.save, add: { status: ADD_REMOVE_LITIGATION_SEARCH_STATES.ERROR, error: action.payload } },
    }),
    [actions.resetSaveLitigationSearch]: (state) => ({
      ...state,
      save: { ...state.save, add: { status: ADD_REMOVE_LITIGATION_SEARCH_STATES.UNDEFINED, error: null } },
    }),
    [actions.startDownloadPdf]: (state) => ({
      ...state,
      isDownloadingPdf: true,
    }),
    [actions.finishDownloadPdf]: (state) => ({
      ...state,
      isDownloadingPdf: false,
    }),
    [actions.setSelectedFilter]: (state, action) => ({
      ...state,
      mainPage: { ...state.mainPage,
        selectedFilter: action.payload, 
        sectors: action.payload === FILTER_CHECKBOXES.myIndustries ? state.mainPage.mySectors : state.mainPage.allSectors
      },
    }),
    [actions.setAllSectors]: (state, action) => ({
      ...state,
      mainPage: { ...state.mainPage, allSectors: action.payload },
    }),
    [actions.setMySectors]: (state, action) => ({
      ...state,
       mainPage: { ...state.mainPage, mySectors: action.payload },
    }),
    [actions.setIsOnMainPage]: (state, action) => ({
      ...state, isOnMainPage: action.payload 
    }),
    [actions.setSelectedCompanies]: (state, action) => ({
      ...state,
        sector: { ...state.sector, 
          selectedCompanyTimeSeries: action.payload,
          stories: {
            ...state.sector.stories,
            data: null,
            infiniteScrollLoading: false,
          } 
        } 
    }),
    [actions.setMyCompanies]: (state, action) => ({
      ...state,
      mainPage: { ...state.mainPage, myCompanies: action.payload },
    }),
    [actions.setSelectedMyCompanyIds]: (state, action) => ({
      ...state,
      mainPage: { ...state.mainPage, selectedCompanyIds: action.payload },
    }),
  },
  getInitialState(),
)
