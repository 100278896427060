import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {PureComponent} from 'react'

import * as styles from './styles.less'

/**
 * Represents a form section consisting of a label and an input. Meant to be
 * used in place of <label> HTML elements.
 *
 * @param {String} label - The text label
 * @param {Boolean} isInline - Whether the input is an inline element (like a
 *                             checkbox)
 */
export default function InputBlock({
  label,
  isInline,
  children,
  className,
  ...restProps
}) {
  return (
    <label
      className={classNames(styles.inputBlock, className, {
        [styles.inline]: isInline,
      })}
      {...restProps}
    >
      {isInline && children}
      <span className={styles.label}>{label}</span>
      {!isInline && children}
    </label>
  )
}
InputBlock.propTypes = {
  label: PropTypes.node.isRequired,
  isInline: PropTypes.bool,
  className: PropTypes.string,
}

export function TextValue({text}) {
  return <span className={styles.value}>{text}</span>
}
InputBlock.TextValue = TextValue
