import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {getMyCompanyIds} from 'app/global/global-selectors'
import MyEntityStar from 'app/reusable/entities/my-entity-star'

import * as actions from './my-company-star-actions'

const MyCompanyStar = ({
  company,
  myCompanyIds,

  addCompany,
  removeCompany,

  className,
}) => (
  <MyEntityStar
    entity={company}
    entityType="company"
    myEntityIds={myCompanyIds}
    addEntity={addCompany}
    removeEntity={removeCompany}
    className={className}
  />
)
MyCompanyStar.propTypes = {
  company: PropTypes.object.isRequired,
  myCompanyIds: PropTypes.arrayOf(PropTypes.number),

  addCompany: PropTypes.func.isRequired,
  removeCompany: PropTypes.func.isRequired,

  className: PropTypes.string,
}

export default connect(
  createSelector(
    [getMyCompanyIds],
    myCompanyIds => ({myCompanyIds}),
  ),
  {
    addCompany: actions.addCompany,
    removeCompany: actions.removeCompany,
  },
)(MyCompanyStar)
