import React from 'react'
import PropTypes from 'prop-types'

export default function TrendDirectionIcon({ className }) {
  return (
    <svg viewBox="0 0 9 6" className={className} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.71679 0.486589C4.11715 -0.0177356 4.88285 -0.0177351 5.28321 0.486589L7.9757
        3.87824C8.49596 4.5336 8.02925 5.5 7.19249 5.5H1.80751C0.970755 5.5 0.504042 4.5336
        1.0243 3.87824L3.71679 0.486589Z"
        fill="currentColor"/>
    </svg>
  )
}

TrendDirectionIcon.propTypes = {
  classname: PropTypes.string,
}
