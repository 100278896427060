import Model from "../framework/Model";
import { field } from "../framework/fields";

export default class LitigationNumberSummary extends Model {
  static entityKey = 'litigationNumberSummary'

  static fields = {
    name: field(),
    litigationStoryCount: field(),
    industrySize: field(),
    litigationCompanyCount: field(),
    litigationCompanyProp: field(),
    litigationCountDelta: field(),
    litigationCountPercent: field(),
  }
}
