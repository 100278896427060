import PropTypes from 'prop-types'
import React from 'react'

import Cell from 'email-server-common/common/Cell'
import PaddedBlock from 'email-server-common/common/PaddedBlock'
import Table from 'email-server-common/common/Table'
import {spacingAmount} from 'email-server/style/constants'
import {lineColorLight} from 'email-server/style/palette'

const Section = ({children}) => (
  <PaddedBlock top={spacingAmount} horizontal={spacingAmount}>
    <Table>
      <Cell
        style={{
          border: `1px solid ${lineColorLight}`,
          backgroundColor: 'white',
        }}
      >
        {children}
      </Cell>
    </Table>
  </PaddedBlock>
)
Section.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Section
