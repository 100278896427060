import UrlPattern from 'url-pattern'

export const toUrl = (route, options = {}) => {
  let url = new UrlPattern(route).stringify(options)
  if (!url.endsWith('/')) {
    url += '/'
  }
  return url
}

export const routeFromUrl = url =>
  Object.values(routes).find(route => new UrlPattern(route).match(url))

const routes = {
  home: '/',
  profileBuilder: '/profile-builder',
  logout: '/logout',
  dashboard: '/dashboard',
  industries: '/industries',
  companies: '/companies',
  companyOverview: '/companies/:companyId',
  companyHistory: '/companies/:companyId/history',
  companyOverviewPortal: '/companies-portal/:companyId',
  companyHistoryPortal: '/companies-portal/:companyId/history',
  companyOverviewPortalStandalone: '/companies-portal',
  companySimCirc: '/companies/:companyId/simcirc',
  companySimCircCurrent: '/companies/:companyId/simcirc/current',
  companySimCircForecast: '/companies/:companyId/simcirc/forecast',
  companyReport: '/companies/:companyId/report',
  industryOverview: '/industries/:industryId',
  industryOverviewCompanies: '/industries/:industryId/companies',
  industryOverviewPortal: '/industries-portal/:industryId',
  industryOverviewPortalCompanies: '/industries-portal/:industryId/companies',
  industryReport: '/industries/:industryId/report',
  storylineView: '/storylines/:storylineId',
  storylineViewPortal: '/storylines-portal/:storylineId',
  interimPage: '/article-summary/:ids',
  litigation: '/litigation',
  litigationLoadView: '/litigation/load',
  litigationMonitoringReport: '/litigation/report/main/:saveId',
  litigationSectorReport: '/litigation/report/sector/:saveId',

  noAccess: '/no-access',
}
if (process.env.USE_EMAIL_TEST_PAGES) {
  routes.emailTestDaily = '/email-test/daily'
  routes.emailTestWeekly = '/email-test/weekly'
  routes.esgEmailTestDaily = '/email-test-esg/daily'
  routes.esgEmailTestWeekly = '/email-test-esg/weekly'
}
export default routes

export const WINDOW_TITLES_BY_ROUTE = {
  [routes.home]: 'Dashboard',
  [routes.profileBuilder]: 'Profile Builder',
  [routes.logout]: 'Logout',
  [routes.dashboard]: 'Dashboard',
  [routes.industries]: 'Industries',
  [routes.companies]: 'Companies',
  [routes.companyOverview]: 'Company Overview',
  [routes.companyHistory]: 'Company History',
  [routes.companyOverviewPortal]: 'Company Overview Portal',
  [routes.companyHistoryPortal]: 'Company History Portal',
  [routes.companyOverviewPortalStandalone]: 'Companies Portal',
  [routes.companySimCirc]: 'Company SimCirc',
  [routes.companySimCircCurrent]: 'Company SimCirc Current',
  [routes.companySimCircForecast]: 'Company SimCirc Forecast',
  [routes.companyReport]: 'Company Report',
  [routes.industryOverview]: 'Industry Overview',
  [routes.industryOverviewCompanies]: 'Industry Overview Companies',
  [routes.industryOverviewPortal]: 'Industry Overview Portal',
  [routes.industryOverviewPortalCompanies]:
    'Industry Overview Companies Portal',
  [routes.industryReport]: 'Industry Report',
  [routes.storylineView]: 'Storyline',
  [routes.storylineViewPortal]: 'Storyline Portal',
  [routes.interimPage]: 'Article Summary',
  [routes.litigation]: 'Litigation Monitoring',
  [routes.noAccess]: 'Access Denied',
}
