if (process.env.NODE_ENV === 'production' && process.env.MANZAMA_BRAND === 'manzama') {
  document.addEventListener('DOMContentLoaded', () => {
    const script = document.createElement('script')
    script.src = "https://www.googletagmanager.com/gtag/js?id=UA-19176479-7"
    document.head.appendChild(script)

    window.dataLayer = window.dataLayer || []
    function gtag(){dataLayer.push(arguments)}
    window.gtag = gtag
    gtag('js', new Date())
    gtag('config', 'UA-19176479-7')
  })
}

export function updateGoogleAnalyticsUserId(userId) {
  if (typeof gtag === 'undefined') return
  gtag('set', {'user_id': userId})
}
