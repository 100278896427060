import dateFns from 'date-fns'
import {length, map, pipe, prop, uniq} from 'ramda'
import {LOCATION_CHANGED} from 'redux-little-router'
import {all, put, select, takeLatest} from 'redux-saga/effects'

import {fetchCompanies, fetchStories} from 'app/api/api-saga-helpers'
import {
  ORDERED_FACTORS,
  SORT_DIRECTIONS,
  SYSTEM_DATE_FORMAT,
  today,
} from 'app/constants'
import CompanyResource from 'app/resources/Company'
import StoryResource from 'app/resources/Story'
import routes from 'app/routes'
import {restApiRequest} from 'app/utils/sagas'

import {downloadPdf} from '../../common/overview-saga'
import * as actions from './company-report-actions'
import * as selectors from './company-report-selectors'

function* loadStoryAndStorylineCounts(companyId) {
  const timeFrameDays = yield select(selectors.getTimeFrameDays)
  const geography = yield select(selectors.getGeography)
  const startDate = dateFns.subDays(today, timeFrameDays)
  const response = yield yield* restApiRequest(StoryResource, {
    query: {
      perspectiveId: `eq.${geography}`,
      companyId: `eq.${companyId}`,
      storyDate: `gte.${dateFns.format(startDate, SYSTEM_DATE_FORMAT)}`,
    },
    fields: ['storylineId'],
  })
  const storyCount = response.body.length
  const storylineCount = pipe(
    map(prop('storylineId')),
    uniq,
    length,
  )(response.body)
  yield put(
    actions.setCounts({stories: storyCount, storylines: storylineCount}),
  )
}

function* locationChanged() {
  const companyId = yield select(selectors.getCompanyId)
  const timeFrameDays = yield select(selectors.getTimeFrameDays)
  const geography = yield select(selectors.getGeography)
  const fetchCompanyRequest = yield* fetchCompanies({
    companyId,
    timeFrameDays,
    geography,
    fields: [
      ...CompanyResource.requiredFields,
      'healthBadge',
      'overallDailyHealthDataPoints',
    ],
  })

  const startDate = dateFns.subDays(today, timeFrameDays)
  const storiesRequests = {}
  for (const factor of ORDERED_FACTORS) {
    storiesRequests[factor] = yield* fetchStories({
      companyId,
      factor,
      startDate,
      geography,
      isLargest: true,
      orderBy: [{field: 'articleCount', direction: SORT_DIRECTIONS.DESC}],
      limit: 5,
      fields: [
        ...StoryResource.requiredFields,
        'storyline',
        'companyId',
        'topArticle',
      ],
    })
  }

  yield* loadStoryAndStorylineCounts(companyId)

  // Wait for stories to load before proceeding.
  const storiesResponsesByFactor = yield all(storiesRequests)
  yield fetchCompanyRequest

  const storyIdsByFactor = map(prop('result'), storiesResponsesByFactor)
  yield put(actions.setStoryIdsByFactor(storyIdsByFactor))

  yield put(actions.setIsLoading(false))
}

const isCompanyReportLocationChange = action =>
  action.type === LOCATION_CHANGED &&
  action.payload.route === routes.companyReport

export default function* companyReportSaga() {
  yield all([
    takeLatest(isCompanyReportLocationChange, locationChanged),
    takeLatest(
      actions.startDownloadPdf,
      downloadPdf,
      actions.finishDownloadPdf,
    ),
  ])
}
