export function field({required = true} = {}) {
  return {
    __isRequired: required,
  }
}

export function foreignKey(model, opts = {}) {
  return {
    ...field(opts),
    __foreignKey: model,
  }
}

/*
 * Used if the other model has a foreignKey to this model
 */
export function hasOne(model, opts = {foreignKeyField: undefined}) {
  return {
    ...field(opts),
    __hasOne: model,
    __foreignKeyField: opts.foreignKeyField,
  }
}

export function hasMany(model, opts = {foreignKeyField: undefined}) {
  return {
    ...field(opts),
    __hasMany: model,
    __foreignKeyField: opts.foreignKeyField,
  }
}

export function arrayOf(itemField) {
  return {
    ...itemField,
    __isArray: true,
  }
}
