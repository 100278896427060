import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import moment from 'moment'

import Button from 'app/common/Button'
import Orm from 'app/framework/Orm'
import urls from 'app/urls'
import * as entitiesSelectors from 'app/framework/entities-selectors'
import { getRouterState } from 'app/router/router-selectors'
import { LitigationNumberSummary, LitigationTimeSeries, Story } from 'app/models'
import { factorDisplayName, subfactorDisplayName } from 'app/strings'
import TabbedContainer from 'app/common/tabbed-container'
import { Tooltip } from 'app/common/tooltip'
import RadioButton from 'app/common/RadioButton'
import LoadingSpinner from 'app/common/loading-spinner'

import {
  FACTOR_IDS_BY_FACTOR,
  FACTORS_BY_SUBFACTOR,
  ORDERED_SUBFACTORS_BY_FACTOR,
  SUBFACTOR_IDS_BY_SUBFACTOR,
  SUBFACTORS,
  SUBFACTORS_BY_ID
} from 'app/constants'

import * as commonStyles from './styles.less'
import * as styles from './LitigationSector.less'
import { LitigationLayout, MainLayout, Segment, SidebarLayout } from 'app/litigation/layout'
import MultiSelectDropdown from 'app/litigation/MultiSelectDropdown'
import RangeGroup from 'app/litigation/RangeGroup'
import FancyCheckbox from 'app/litigation/Checkbox'
import * as selectors from 'app/litigation/litigations-selectors'
import * as actions from 'app/litigation/litigations-actions'
import { ASPECT_FILTER_TYPE, getInitialFilterState } from 'app/litigation/litigations-constants'
import { abbreviateNumber, arrayUniqueById } from 'app/litigation/utils'
import GrowthBadge from 'app/litigation/GrowthBadge'
import BackArrow from 'app/litigation/icons/BackArrow'
import QuestionMark from 'app/litigation/icons/QuestionMark'
import LitigationStoryChart from 'app/litigation/LitigationStoryChart'
import SectorStories from 'app/litigation/SectorStories'
import { ENABLE_SEARCH_LIMIT, DROPDOWN_SETTINGS } from 'app/litigation/litigations-constants'
import { FILTER_CHECKBOXES, NO_COMPANY_LITIGATION_DATA } from './litigations-constants'

export default function LitigationSector() {
  const dispatch = useDispatch()
  const isFirstLoad = useRef(null)
  const entities = useSelector(entitiesSelectors.getEntities)
  const mainPage = useSelector(selectors.getMainPage)
  const sectorPage = useSelector(selectors.getSectorPage)
  const { isLoading, filters, companyTimeSeries, selectedCompanyTimeSeries: selectedCompanyIds,
    stories: { data: storyIds, error: storyFetchError } } = sectorPage
  const { sectors, selectedSectors: selectedSectorIds, myCompanies, selectedFilter } = mainPage

  const orm = Orm.withEntities(entities)
  const sectorEntities = sectors && orm.getByIds(LitigationNumberSummary, sectors)
  const selectedSectors = selectedSectorIds && orm.getByIds(LitigationNumberSummary, selectedSectorIds)

  const companies = orm.getByIds(LitigationTimeSeries, companyTimeSeries).sort((a, b) => a.companyName.localeCompare(b.companyName))
  const filteredSelectedCompanies = entities.litigationTimeSeries && companyTimeSeries.length > 0 ? orm.getByIds(LitigationTimeSeries, selectedCompanyIds) : []
  const updatedCompanyTimeSeries = [...companyTimeSeries]

  // to find and add those selected companies which are not found in API
  if(selectedFilter === FILTER_CHECKBOXES.myCompanies && filteredSelectedCompanies.length > 0)
  {
    selectedCompanyIds.map(companyId => {
      if(!filteredSelectedCompanies.some(fsc=> fsc && fsc.id === companyId)) {
        const filteredCompanies = myCompanies.filter(com => com.id === companyId)
        if(filteredCompanies.length === 0) {
          return
        }
        const companyDetails = filteredCompanies[0]
        const company = {
          id: companyId,
          companyName: companyDetails.displayName,
          companyRank: updatedCompanyTimeSeries.length + 1,
          companyWeeklyLitigationCounts: []
        }
        filteredSelectedCompanies.push(company)
        companies.push(company)
        updatedCompanyTimeSeries.push(companyId)
      }
    })
  }
  const selectedCompanies = filteredSelectedCompanies.filter(company => company != undefined).sort((a, b) => a.companyName.localeCompare(b.companyName))


  const stories = storyIds ? orm.getByIds(Story, storyIds) : null
  const companyChartData = []
  for (const company of selectedCompanies) {
    for (const [index, count] of company.companyWeeklyLitigationCounts.entries()) {
      if (companyChartData[index] === undefined) {
        companyChartData.push({
          storyWeekDate: count.storyWeekDate,
          headlines: {
            [company.id]: count.leadingHeadline,
          },
          [company.id]: count.companyWeeklyLitigationCounts,
        })
      } else {
        companyChartData[index][company.id] = count.companyWeeklyLitigationCounts
        companyChartData[index].headlines[company.id] = count.leadingHeadline
      }
    }
  }

  const setSelectedCompanies = (companies) => {
    dispatch(actions.setSelectedCompanies(companies))
  }

  const setSelectedSectors = (sectors) => {
    dispatch(actions.setSelectedSectors(sectors))
    dispatch(actions.fetchCompaniesForIndustries(sectors))
  }

  const navigateBack = () => {
    dispatch(actions.setIsOnMainPage(true))
    //clear page state
    dispatch(actions.setSelectedCompanies([]))
    dispatch(actions.setCompanyTimeSeries([]))
  }

  const resetFilters = () => {
    dispatch(actions.setCompanyFilters(getInitialFilterState()))
    setSelectedCompanies([])
    setSelectedSectors([])
  }

  useEffect(() => {
    isFirstLoad.current = true
    dispatch(actions.fetchCompaniesForIndustries(selectedSectorIds))
  }, [])

  useEffect(() => {
    if (selectedCompanyIds.length === 0 && updatedCompanyTimeSeries.length > 0 && isFirstLoad.current) {
      isFirstLoad.current = false
      setSelectedCompanies(updatedCompanyTimeSeries.slice(0, 10))
    }
    else if (selectedCompanyIds.length > 0 && updatedCompanyTimeSeries.length > 0) {
      // check and remove companies which does not come under selected industries
      const updatedSelectedCompanyIds = selectedCompanyIds.filter(id => updatedCompanyTimeSeries.includes(id))
      if (updatedSelectedCompanyIds !== selectedCompanyIds) {
        setSelectedCompanies(updatedSelectedCompanyIds)
      }
    }
  }, [companyTimeSeries])

  return (
    <SectorView
      selectedSectors={selectedSectors}
      selectedSectorIds={selectedSectorIds}
      sectors={sectorEntities}
      setSelectedSectors={setSelectedSectors}
      filteredCompanies={companies}
      selectedCompanies={selectedCompanies}
      selectedCompanyIds={selectedCompanyIds}
      setSelectedCompanies={setSelectedCompanies}
      companyChartData={companyChartData}
      filters={filters}
      setFilters={(f) => dispatch(actions.setCompanyFilters(f))}
      resetFilters={resetFilters}
      isLoading={isLoading}
      stories={stories}
      storyFetchError={storyFetchError}
      navigateBack={navigateBack}
    />
  )
}

function SectorView({
  selectedSectors = [],
  selectedSectorIds = [],
  sectors = [],
  setSelectedSectors,
  filteredCompanies = [],
  selectedCompanies = [],
  selectedCompanyIds = [],
  setSelectedCompanies,
  companyChartData = [],
  filters,
  setFilters,
  resetFilters,
  isLoading,
  stories,
  storyFetchError,
  navigateBack
}) {
  const outputFileName = `litigation-company-report`
  const aspect = filters.aspect
  const handleFilterChange = (name, value) => setFilters({ ...filters, [name]: value })
  const [activeTab, setActiveTab] = useState('graph')
  const [chartRef, setChartRef] = useState(null)
  const csvData = useMemo(() => {
    const _csvData = companyChartData.map(({ storyWeekDate, headlines, ...companyScores }) => {
      return [
        moment(storyWeekDate).year(),
        moment(storyWeekDate).week(),
        `"${moment(storyWeekDate).weekday(1).format('LL')} - ${moment(storyWeekDate).weekday(7).format('LL')}"`,
        ...selectedCompanies.map(company => companyScores[company.id] || 0),
      ]
    })
    _csvData.unshift(['Year', 'Week', 'Date', ...selectedCompanies.map(company => `"${company.companyName}"`)])
    return _csvData
  }, [companyChartData])

  const multiSelectOptions = Object.keys(ORDERED_SUBFACTORS_BY_FACTOR).map(factor => {
    return {
      id: 'factor' + FACTOR_IDS_BY_FACTOR[factor], // Prefix the ID with "factor", because Factor and SubFactor IDs do overlap
      label: factorDisplayName(factor),
      children: ORDERED_SUBFACTORS_BY_FACTOR[factor]
        .filter(f => ![SUBFACTORS.LITIGATION, SUBFACTORS.RUMOR, SUBFACTORS.OPINION].includes(f))
        .map(subfactor => {
          return {
            id: SUBFACTOR_IDS_BY_SUBFACTOR[subfactor],
            label: subfactorDisplayName(subfactor),
          }
        })
    }
  })

  /**
   * Handles the factor toggle. If all children are selected, then remove all children along with the option.
   * Otherwise, select the parent and all the unselected children.
   *
   * @param option
   */
  const handleFactorOnToggle = (option) => {
    if (filters.factors.map(factor => factor.id).includes(option.id)) {
      const factors = filters.factors.filter(o => !([option, ...option.children].map(f => f.id).includes(o.id)))
      setFilters({ ...filters, factors: factors })
    } else {
      setFilters({ ...filters, factors: arrayUniqueById([...filters.factors, option, ...option.children]) })
    }
  }

  /**
   * Handles subfactors toggle. If the subfactor gets selected and the siblings are already selected, then the
   * prent gets selected too.
   *
   * @param option
   */
  const handleSubFactorOnToggle = (option) => {
    if (filters.factors.find(o => o.id === option.id)) {
      // Remove the option along with its parent
      const factors = filters.factors.filter(o => ![option.id,
      'factor' + FACTOR_IDS_BY_FACTOR[FACTORS_BY_SUBFACTOR[SUBFACTORS_BY_ID[option.id]]]].includes(o.id))
      setFilters({ ...filters, factors: factors })
    } else {
      const parent = multiSelectOptions
        .find(o => o.id === 'factor' + FACTOR_IDS_BY_FACTOR[FACTORS_BY_SUBFACTOR[SUBFACTORS_BY_ID[option.id]]])
      const siblingIds = parent.children.filter(child => child.id !== option.id).map(child => child.id)

      if (siblingIds.every(id => filters.factors.map(f => f.id).includes(id))) {
        // If all siblings are already selected, add the parent too
        setFilters({ ...filters, factors: arrayUniqueById([...filters.factors, option, parent]) })
      } else {
        setFilters({ ...filters, factors: arrayUniqueById([...filters.factors, option]) })
      }
    }
  }

  const handleOnToggle = (option) => {
    if (multiSelectOptions.map(o => o.id).includes(option.id)) {
      handleFactorOnToggle(option)
    } else {
      handleSubFactorOnToggle(option)
    }
  }

  const handleOnIndustriesToggle = (option) => {
    if (selectedSectorIds.find(id => id === option.id)) {
      setSelectedSectors(selectedSectorIds.filter(id => id !== option.id))
    } else {
      setSelectedSectors([...selectedSectorIds, option.id])
    }
  }

  const handleOnCompanyToggle = (option) => {
    if (selectedCompanyIds.find(id => id === option.id)) {
      setSelectedCompanies(selectedCompanyIds.filter(id => id !== option.id))
    } else {
      setSelectedCompanies([...selectedCompanyIds, option.id])
    }
  }

  const haveChartData = !isLoading ? selectedCompanies.some(company => company.companyWeeklyLitigationCounts.length > 0) : true

  function renderCompanyTab() {
    return (
      <div className={styles.tabContent}>
        <div className={commonStyles.mainHeader}>
          <div className={commonStyles.description}>
            <h1 className={commonStyles.descriptionTitle}>Litigation Story Counts For Top Companies</h1>
            <p className={commonStyles.subtitle}>These are the companies with their litigation numbers based on the selected Industry Sectors. The top 10 companies by story count are shown by default.</p>
          </div>
        </div>
        <div ref={newRef => setChartRef(newRef)}>
          { haveChartData ? 
            <LitigationStoryChart companies={selectedCompanies} companyChartData={companyChartData} isLoading={isLoading} />
            : <div className={styles.noMatch}>{NO_COMPANY_LITIGATION_DATA}</div>
          }
        </div>
      </div>
    )
  }

  function renderStoryTab() {
    return (
      <div className={styles.tabContent}>
        <SectorStories stories={stories} storyFetchError={storyFetchError} />
      </div>
    )
  }

  function sortByName(a, b) {
    return ((a.name || a.companyName) > (b.name || b.companyName)) ? 1 : -1
  }

  return (
    <LitigationLayout
      outputFileName={outputFileName}
      csvData={csvData}
      chartRef={activeTab === 'graph' ? { current: chartRef } : null}
    >
      <SidebarLayout>
        <Segment>
          <div onClick={navigateBack} className={styles.backLink}>
            <BackArrow className={styles.backIcon} />
            <Button
              label='BACK'
              isPlainText={true}
              onClick={navigateBack}
              className={commonStyles.resetButton}
            />
            {isLoading ? <LoadingSpinner className={styles.spinner} /> : null}
          </div>

          <label className={classNames(commonStyles.dropdownLabel, commonStyles.marginTopDefault)}>Filter by Subfactor(s)</label>
          <MultiSelectDropdown
            options={multiSelectOptions}
            selected={filters.factors}
            toggleOption={handleOnToggle}
            allSelectedLabel={'All Subfactors'}
            useChips={false}
            selectedLabel={'Subfactors'}
            className={classNames(commonStyles.dropdown, commonStyles.marginBottomDefault)}
          />

          <label className={classNames(commonStyles.dropdownLabel, commonStyles.marginTopDefault)}>Filter by Industry Sectors</label>
          <MultiSelectDropdown
            options={[...sectors].sort(sortByName).map(sector => {
              return {
                id: sector.id,
                label: sector.name,
              }
            })}
            selected={selectedSectors.map(sector => {
              return {
                id: sector.id,
                label: sector.name,
              }
            })}
            allSelectedLabel={'Please select'}
            toggleOption={handleOnIndustriesToggle}
            className={classNames(commonStyles.dropdown, commonStyles.marginBottomDefault)}
            selectThreshold={DROPDOWN_SETTINGS.industriesThreshold}
            enableSearch={sectors.length > ENABLE_SEARCH_LIMIT}
          />

          <label className={classNames(commonStyles.dropdownLabel, commonStyles.marginTopDefault)}>Companies within Industry Sectors</label>
          <MultiSelectDropdown
            options={[...filteredCompanies].sort(sortByName).map(company => {
              return {
                id: company.id,
                label: company.companyName,
              }
            })}
            selected={selectedCompanies.map(company => {
              return {
                id: company.id,
                label: company.companyName,
              }
            })}
            allSelectedLabel={'Please select'}
            toggleOption={handleOnCompanyToggle}
            className={classNames(commonStyles.dropdown, commonStyles.marginBottomDefault)}
            selectThreshold={DROPDOWN_SETTINGS.companiesThreshold}
            enableSearch={filteredCompanies.length > ENABLE_SEARCH_LIMIT}
          />

          <RangeGroup
            label={'Stories Volume Range'}
            tooltip={'Filters the companies shown by the minimum and maximum number of litigation stories reported'}
            minimumValue={filters.storyCountMin}
            maximumValue={filters.storyCountMax}
            minimumValueChanged={(value) => handleFilterChange('storyCountMin', value)}
            maximumValueChanged={(value) => handleFilterChange('storyCountMax', value)}
            minimumLimit={0}
            maximumLimit={10000}
          />

          <div className={styles.selectionContainer}>
            <div className={styles.selection}>
              <h3>
                Valence
                <Tooltip
                  label={'Filters the companies shown by their positive, neutral, and/or negative litigation stories'}
                  direction={'left'}
                >
                  <QuestionMark className={styles.inlineIcon} />
                </Tooltip>
              </h3>
              <div className={styles.checkBoxes}>
                <FancyCheckbox
                  label={'Positive'}
                  checked={filters.valencePositive}
                  onChange={() => handleFilterChange('valencePositive', !filters.valencePositive)}
                />
                <FancyCheckbox
                  label={'Neutral'}
                  checked={filters.valenceNeutral}
                  onChange={() => handleFilterChange('valenceNeutral', !filters.valenceNeutral)}
                />
                <FancyCheckbox
                  label={'Negative'}
                  checked={filters.valenceNegative}
                  onChange={() => handleFilterChange('valenceNegative', !filters.valenceNegative)}
                />
              </div>
            </div>
            <div className={styles.selection}>
              <h3>
                Aspect
                <Tooltip
                  label={'Filters the companies shown by the selected combination of rumor and/or opinion litigation stories'}
                  direction={'left'}
                >
                  <QuestionMark className={styles.inlineIcon} />
                </Tooltip>
              </h3>
              <label>
                <RadioButton
                  id={'all-stories'}
                  onChange={() => {
                    setFilters({ ...filters, aspect: ASPECT_FILTER_TYPE.ALL })
                  }}
                  isChecked={aspect === ASPECT_FILTER_TYPE.ALL}
                />
                <div className={styles.options}>
                  <span className={styles.optionTitle}>All stories</span>
                  <span className={styles.optionSubtitle}>
                    Regardless of all aspects (<span>Rumor</span>, <span>Opinion</span> or neither)
                  </span>
                </div>
              </label>
              <hr className={classNames(commonStyles.separator, styles.separator)} />
              <label>
                <RadioButton
                  id={'objective-stories'}
                  onChange={() => {
                    setFilters({ ...filters, aspect: ASPECT_FILTER_TYPE.OBJECTIVE })
                  }}
                  isChecked={aspect === ASPECT_FILTER_TYPE.OBJECTIVE}
                />
                <div className={styles.options}>
                  <span className={styles.optionTitle}>Objective articles</span>
                  <span className={styles.optionSubtitle}>No <span>Rumors</span> nor <span>Opinions</span> listed</span>
                </div>
              </label>
              <hr className={classNames(commonStyles.separator, styles.separator)} />
              <label>
                <RadioButton
                  id={'subjective-stories'}
                  onChange={() => {
                    setFilters({ ...filters, aspect: ASPECT_FILTER_TYPE.RUMOR_OR_OPINION })
                  }}
                  isChecked={ASPECT_FILTER_TYPE.RUMOR_OR_OPINION === aspect}
                />
                <div className={styles.options}>
                  <span className={styles.optionTitle}>Subjective articles</span>
                  <span className={styles.optionSubtitle}>
                    Only <span>Rumors</span> OR <span>Opinions</span> listed
                  </span>
                </div>
              </label>
            </div>
          </div>

          <div className={commonStyles.resetButtonContainer}>
            <Button
              label='Reset All Filters'
              isPlainText={true}
              onClick={() => {
                resetFilters()
              }}
              className={commonStyles.resetButton}
            />
          </div>
        </Segment>
      </SidebarLayout>
      <MainLayout className={classNames(styles.mainContainer, styles.noPadding)}>
        <TabbedContainer
          onChange={setActiveTab}
          defaultTab={'graph'}
          activeTab={activeTab}
          className={styles.tabRow}
        >
          <TabbedContainer.Tab
            id={'graph'}
            label={'Litigation Stories By Company'}
            render={() => renderCompanyTab()}
            className={styles.tab}
            activeClassName={styles.active}
          />
          <TabbedContainer.Tab
            id={'stories'}
            label={'Stories'}
            render={() => renderStoryTab()}
            className={styles.tab}
            activeClassName={styles.active}
          />
        </TabbedContainer>
      </MainLayout>
    </LitigationLayout>
  )
}
