import dateFns from 'date-fns'
import {last, nth, takeLastWhile} from 'ramda'

import {IGNORED_SUBFACTORS, today} from 'app/constants'

import {SIGNIFICANT_HEALTH_CHANGE_THRESHOLD} from './dashboard-constants'

export function calculateRecentSubfactorScoreChanges(
  company,
  {timeFrameDays} = {},
) {
  return Object.keys(company.subfactorDailyHealthData)
    .filter(subfactor => !IGNORED_SUBFACTORS.includes(subfactor))
    .reduce((changes, subfactor) => {
      const change = calculateRecentScoreChange(company, {
        subfactor,
        timeFrameDays,
      })
      if (change) {
        changes.push({
          subfactor,
          change,
        })
      }
      return changes
    }, [])
}

export function calculateRecentScoreChange(
  company,
  {factor, subfactor, timeFrameDays = 1} = {},
) {
  if (
    (subfactor && !company.subfactorDailyHealthData[subfactor]) ||
    (factor && !company.factorDailyHealthData[factor]) ||
    !company.dailyHealthData
  ) {
    return null
  }
  const healthData = subfactor
    ? company.subfactorDailyHealthData[subfactor]
    : factor
    ? company.factorDailyHealthData[factor]
    : company.dailyHealthData
  // The `+ 1` is so we go back far enough to have a meaningful amount of data.
  // Otherwise, with a time frame of 1, you might not have any data to compare
  // to at all if you look early enough in the day.
  const earliestDay = dateFns.subDays(today, timeFrameDays + 1)
  const recentHealthData = takeLastWhile(
    ({date}) => !dateFns.isBefore(date, earliestDay),
    healthData,
  )
  if (recentHealthData.length < 2) {
    return null
  }

  const latestPoint = last(recentHealthData)
  const latestHealthScore = latestPoint.healthScore
  const earliestHealthScore = recentHealthData[0].healthScore

  const change = latestHealthScore - earliestHealthScore
  // If the change doesn't match an increase in volume of the same type, ignore
  // it. We may need to look back an extra day if we don't have enough data for
  // today's date.
  const latestChangePoint =
    // If we only have two points, this won't work.
    recentHealthData.length > 2 &&
    latestPoint.positiveChange === null &&
    latestPoint.negativeChange === null
      ? nth(-2, recentHealthData)
      : latestPoint
  const latestPositiveChange = latestChangePoint.positiveChange || 0
  const latestNegativeChange = latestChangePoint.negativeChange || 0
  if (
    (change > 0 && latestPositiveChange <= 0) ||
    (change < 0 && latestNegativeChange <= 0)
  ) {
    return null
  }

  return change
}

export function isHealthChangeSignificant(healthChange) {
  return Math.abs(healthChange) >= SIGNIFICANT_HEALTH_CHANGE_THRESHOLD
}

export function sumAbsoluteHealthChanges(company) {
  return company.dailyHealthData.reduce(
    (sum, {healthScore}) => sum + Math.abs(healthScore),
    0,
  )
}
