import classNames from 'classnames'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import HealthScore from 'app/common/HealthScore'
import Modal from 'app/common/Modal'
import Table from 'app/common/Table'
import * as overviewActions from 'app/overviews/common/overview-actions'
import {ORDERED_SUBFACTORS_BY_FACTOR} from 'app/constants'
import * as strings from 'app/strings'

import * as actions from './factor-details-modal-actions'
import {getFactorDetailsModal} from './factor-details-modal-selectors'

import * as styles from './FactorDetailsModal.less'

class FactorDetailsModal extends React.PureComponent {
  static propTypes = {
    isVisible: PropTypes.bool.isRequired,
    factor: PropTypes.string,
    subfactor: PropTypes.string,

    // Actions
    hide: PropTypes.func.isRequired,
  }

  render() {
    const {
      isVisible,
      factor,
      subfactor,
      subfactorsHealthData,
      factorHealthData,

      // Actions
      hide,
    } = this.props

    if (!isVisible) return null

    const factorData = this.props.factorHealthData || {
      healthScore: null,
      positiveVolume: 0,
      negativeVolume: 0,
      neutralVolume: 0,
      articleVolume: 0,
    }

    const subfactors = subfactor
      ? [subfactor]
      : ORDERED_SUBFACTORS_BY_FACTOR[factor]
    const subfactorData = subfactors.map(subfactor => {
      const healthData = subfactorsHealthData[subfactor] || {
        healthScore: null,
        positiveVolume: 0,
        neutralVolume: 0,
        negativeVolume: 0,
        articleVolume: 0,
      }
      return {
        id: subfactor,
        healthScore: healthData.healthScore,
        positiveVolume: healthData.positiveVolume,
        neutralVolume: healthData.neutralVolume,
        negativeVolume: healthData.negativeVolume,
        totalVolume: healthData.articleVolume,
      }
    })
    if (!subfactor) {
      subfactorData.push({
        id: null,
        healthScore: factorData.healthScore,
        positiveVolume: factorData.positiveVolume,
        neutralVolume: factorData.neutralVolume,
        negativeVolume: factorData.negativeVolume,
        totalVolume: factorData.articleVolume,
      })
    }

    return (
      <Modal onClose={hide} className={styles.modal}>
        <h1>{strings.factorDisplayName(factor)}</h1>
        <a
          onClick={bind(this.filterByFactor, this)}
          className={styles.filterByFactor}
        >
          Filter by {strings.factorDisplayName(factor)}
        </a>

        <Table
          data={subfactorData}
          className={styles.table}
          rowClassName={({id: subfactor}) => (subfactor ? '' : styles.totals)}
        >
          <Table.Column
            name="name"
            label="Subfactor Name"
            className={styles.nameCell}
            baseWidth={200}
            growRatio={2}
            shrinkRatio={2}
            cellContents={({id: subfactor}) => (
              <React.Fragment>
                <div className={styles.name}>
                  {subfactor
                    ? strings.subfactorDisplayName(subfactor)
                    : `Total: ${strings.factorDisplayName(factor)}`}
                </div>
                <a
                  onClick={
                    subfactor
                      ? bind(this.filterBySubfactor, this, subfactor)
                      : bind(this.filterByFactor, this)
                  }
                  className={styles.filterBySubfactor}
                >
                  Filter by{' '}
                  {subfactor
                    ? strings.subfactorDisplayName(subfactor)
                    : strings.factorDisplayName(factor)}
                </a>
              </React.Fragment>
            )}
          />
          <Table.Column
            name="healthScore"
            label="Health Score"
            className={styles.numberCell}
            baseWidth={130}
            growRatio={0}
            shrinkRatio={1}
            cellContents={({healthScore}) => (
              <HealthScore score={healthScore} className={styles.number} />
            )}
          />
          <Table.Column
            name="positiveVolume"
            label="Positive Articles"
            className={styles.numberCell}
            baseWidth={150}
            growRatio={0}
            shrinkRatio={1}
            cellContents={({positiveVolume}) => (
              <span className={classNames(styles.number, styles.positive)}>
                {positiveVolume}
              </span>
            )}
          />
          <Table.Column
            name="neutralVolume"
            label="Neutral Articles"
            className={styles.numberCell}
            baseWidth={150}
            growRatio={0}
            shrinkRatio={1}
            cellContents={({neutralVolume}) => (
              <span className={classNames(styles.number, styles.neutral)}>
                {neutralVolume}
              </span>
            )}
          />
          <Table.Column
            name="negativeVolume"
            label="Negative Articles"
            className={styles.numberCell}
            baseWidth={160}
            growRatio={0}
            shrinkRatio={1}
            cellContents={({negativeVolume}) => (
              <span className={classNames(styles.number, styles.negative)}>
                {negativeVolume}
              </span>
            )}
          />
          <Table.Column
            name="totalVolume"
            label="Total Articles"
            className={styles.numberCell}
            baseWidth={130}
            growRatio={0}
            shrinkRatio={1}
            cellContents={({totalVolume}) => (
              <span className={classNames(styles.number)}>{totalVolume}</span>
            )}
          />
        </Table>
      </Modal>
    )
  }

  // Event handlers

  filterByFactor() {
    this.props.selectFactor(this.props.factor)
    this.props.hide()
  }

  filterBySubfactor(subfactor) {
    this.props.selectSubfactor(subfactor)
    this.props.hide()
  }
}

export default connect(
  createSelector(
    [getFactorDetailsModal],
    modalData => ({
      isVisible: modalData.isVisible,
      factor: modalData.factor,
      subfactor: modalData.subfactor,
      subfactorsHealthData: modalData.subfactorsHealthData,
      factorHealthData: modalData.factorHealthData,
    }),
  ),
  {
    hide: actions.hide,
    selectFactor: overviewActions.selectFactor,
    selectSubfactor: overviewActions.selectSubfactor,
  },
)(FactorDetailsModal)
