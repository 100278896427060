import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {CompanyReport} from 'app/overviews/company/report'
import {IndustryReport} from 'app/overviews/industry/report'
import {Dashboard} from 'app/dashboard'
import InterimPage from 'app/interim-page/InterimPage'
import {CompanyOverview} from 'app/overviews/company'
import CompanyOverviewPortal from 'app/overviews/company/portal/CompanyOverviewPortal'
import CompanyOverviewPortalStandalone from 'app/overviews/company/portal/CompanyOverviewPortalStandalone'
import {IndustryOverview} from 'app/overviews/industry'
import IndustryOverviewPortal from 'app/overviews/industry/portal'
import {Industries as IndustriesPage} from 'app/industries'
import {CompaniesPage} from 'app/companies-page'
import * as login from 'app/login'
import ProfileBuilder from 'app/profile-builder/ProfileBuilder'
import StorylineView from 'app/storyline-view/StorylineView'
import {getCurrentRoute} from 'app/router/router-selectors'
import routes from 'app/routes'

import ErrorBoundary from '../ErrorBoundary'
import Footer from '../Footer'
import Header from '../Header'
import ModalsContainer from '../ModalsContainer'
import NoInsightsAccess from '../NoInsightsAccess'
import PageNotFound from '../PageNotFound'
import TooltipsContainer from '../TooltipsContainer'
import {getGlobal} from '../global-selectors'

import styles from './styles.less'
import LitigationMonitoring from "app/litigation";
import LoaderView from "app/litigation/Loader/Loader";
import LitigationMonitoringReport from "../../litigation/LitigationMonitoringReport";
import LitigationSectorReport from "../../litigation/LitigationSectorReport";
import { View as EsgEmailDevView } from '../../../esg-email-server/local-dev'

const withRoute = connect(
  createSelector(
    [getCurrentRoute],
    route => ({route}),
  ),
)

const ContentsContainer = ({isNotFound, currentUser}) => {
  if (!currentUser) {
    return null
  }
  if (isNotFound) {
    return (
      <MainAppWrapper>
        <PageNotFound />
      </MainAppWrapper>
    )
  }
  return <ContentsConnected />
}
ContentsContainer.propTypes = {
  isNotFound: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
}

/**
 * This is the root component of the entire website, as far as pages go.
 * Any pages that don't need the default page "frame" (header, nav, etc) should
 * be rendered here.
 */
const Contents = ({route}) => {
  const routeToContents = {
    [routes.interimPage]: () => <InterimPage />,
    [routes.companyOverviewPortal]: () => <CompanyOverviewPortal />,
    [routes.companyOverviewPortalStandalone]: () => (
      <CompanyOverviewPortalStandalone />
    ),
    [routes.industryOverviewPortal]: () => <IndustryOverviewPortal />,
    [routes.industryOverviewPortalCompanies]: () => <IndustryOverviewPortal />,
    [routes.companyReport]: () => <CompanyReport />,
    [routes.industryReport]: () => <IndustryReport />,
    [routes.profileBuilder]: () => <ProfileBuilder />,
    [routes.storylineViewPortal]: () => <StorylineView />,
    [routes.litigationMonitoringReport]: () => <LitigationMonitoringReport />,
    [routes.litigationSectorReport]: () => <LitigationSectorReport />,
  }

  // For testing email rendering.
  if (process.env.USE_EMAIL_TEST_PAGES) {
    const EmailDevView = require('email-server/local-dev').View
    const EsgEmailDevView = require('esg-email-server/local-dev').View
    routeToContents[routes.emailTestDaily] = () => (
      <EmailDevView component={require('email-server/DailyEmail').default} />
    )
    routeToContents[routes.emailTestWeekly] = () => (
      <EmailDevView component={require('email-server/WeeklyEmail').default} />
    )
    routeToContents[routes.esgEmailTestDaily] = () => (
      <EsgEmailDevView component={require('esg-email-server/EsgDailyEmail').default} />
    )
    routeToContents[routes.esgEmailTestWeekly] = () => (
      <EsgEmailDevView component={require('esg-email-server/EsgWeeklyEmail').default} />
    )
  }

  const contents = routeToContents[route] ? (
    routeToContents[route]()
  ) : (
    <MainAppConnected />
  )
  return (
    <React.Fragment>
      {contents}
      <ModalsContainer />
      <TooltipsContainer />
    </React.Fragment>
  )
}
const ContentsConnected = withRoute(Contents)

const MainAppWrapper = ({children}) => (
  <React.Fragment>
    <div id="head">
      <Header />
    </div>
    <div id="body" className={styles.body}>
      <div className={styles.contents}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
      <Footer />
    </div>
  </React.Fragment>
)
MainAppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

const MainApp = ({route}) => {
  const routeToContents = {
    [routes.dashboard]: () => <Dashboard />,
    [routes.companyOverview]: () => <CompanyOverview />,
    [routes.companyHistory]: () => <CompanyOverview />,
    [routes.companySimCirc]: () => <CompanyOverview />,
    [routes.companySimCircCurrent]: () => <CompanyOverview />,
    [routes.companySimCircForecast]: () => <CompanyOverview />,
    [routes.industries]: () => <IndustriesPage />,
    [routes.industryOverview]: () => <IndustryOverview />,
    [routes.industryOverviewCompanies]: () => <IndustryOverview />,
    [routes.storylineView]: () => <StorylineView />,
    [routes.companies]: () => <CompaniesPage />,
    [routes.litigation]: () => <LitigationMonitoring />,
    [routes.litigationLoadView]: () => <LoaderView/>,
    [routes.logout]: () => (
      <span>Logging out of {process.env.BRAND_NAME}...</span>
    ),
    [routes.home]: () => <div />,
    [routes.noAccess]: () => <NoInsightsAccess />,
  }
  const contents = routeToContents[route] ? (
    routeToContents[route]()
  ) : (
    <PageNotFound />
  )
  return <MainAppWrapper>{contents}</MainAppWrapper>
}
const MainAppConnected = withRoute(MainApp)

export default connect(
  createSelector(
    [getGlobal, login.selectors.getCurrentUser],
    ({isNotFound}, currentUser) => ({
      isNotFound,
      currentUser,
    }),
  ),
)(ContentsContainer)
