import PropTypes from 'prop-types'
import {range} from 'ramda'
import React from 'react'
import {useDispatch} from 'react-redux'

import StorySummaryBlock, {
  StorySummaryBlockLoading,
} from 'app/common/story-summary-block'
import Section from 'app/common/Section'
import {showModal} from 'app/storyline-modal/storyline-modal-actions'
import urls from 'app/urls'

import styles from './CompanySimCirc.less'

export default function SimCircStories({
  title,
  stories,
  isLoading = false,
  skeletonCount = 5,
  noStoriesMessage = 'There are no major stories for the given time frame.',
}) {
  const loadingDummyStories = range(0, skeletonCount).map(id => ({id}))
  const dispatch = useDispatch()
  const showShareModal = storylineId => dispatch(showModal({storylineId}))
  const data = isLoading ? loadingDummyStories : stories
  return (
    <Section
      title={title}
      className={styles.stories}
      headerClassName={styles.header}
      contentClassName={styles.content}
    >
      {data.map(story => (
        <div className={styles.story} key={story.id}>
          {isLoading ? (
            <StorySummaryBlockLoading />
          ) : (
            <StorySummaryBlock
              headline={story.summary}
              factor={story.factor}
              subfactor={story.subfactor}
              articleCount={story.articleCount}
              startDate={story.date}
              valence={story.valence}
              href={urls.storylineView(story.storylineId)}
              onShareClick={() => showShareModal(story.storylineId)}
            />
          )}
        </div>
      ))}

      {!isLoading && !stories.length && (
        <div className={styles.noStories}>{noStoriesMessage}</div>
      )}
    </Section>
  )
}
SimCircStories.propTypes = {
  title: PropTypes.string.isRequired,
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  skeletonCount: PropTypes.number,
  noStoriesMessage: PropTypes.string,
}
