import classNames from 'classnames'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './ChartLegend.less'

export default function ChartLegend({
  groups,
  highlightedGroups = [],
  onHighlightGroup = () => {},
  onClearHighlightedGroup = () => {},
  className,
  ...restProps
}) {
  const groupComponents = groups.map(group => (
    <Group
      group={group}
      isHighlighted={highlightedGroups.includes(group.id)}
      isFaded={
        highlightedGroups.length && !highlightedGroups.includes(group.id)
      }
      onMouseEnter={onHighlightGroup}
      onMouseLeave={onClearHighlightedGroup}
      key={group.id}
    />
  ))

  return (
    <div className={classNames(styles.legend, className)} {...restProps}>
      {groupComponents}
    </div>
  )
}
ChartLegend.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
  highlightedGroups: PropTypes.arrayOf(PropTypes.string),
  onHighlightGroup: PropTypes.func,
  onClearHighlightedGroup: PropTypes.func,
}

class Group extends React.PureComponent {
  static defaultProps = {
    onMouseEnter() {},
    onMouseLeave() {},
  }

  render() {
    const {group, isHighlighted, isFaded} = this.props
    return (
      <span
        className={classNames(styles.group, {
          [styles.highlighted]: isHighlighted,
          [styles.faded]: isFaded,
        })}
        onMouseEnter={bind(this.onMouseEnter, this, group.id)}
        onMouseLeave={bind(this.onMouseLeave, this, group.id)}
      >
        <span
          className={styles.circle}
          style={{backgroundColor: group.color}}
        />
        {group.label}
      </span>
    )
  }

  onMouseEnter(groupId) {
    this.props.onMouseEnter(groupId)
  }

  onMouseLeave(groupId) {
    this.props.onMouseLeave(groupId)
  }
}
