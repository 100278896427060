import classNames from 'classnames'
import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import * as login from 'app/login'
import InsightsLogo from 'app/common/logos/insights-logo'

import * as styles from './NoInsightsAccess.less'
import dashboardPageUrl from 'static/images/dashboard-page.png'
import overviewPageUrl from 'static/images/overview-page.png'

class NoInsightsAccess extends PureComponent {
  activateInsights(event) {
    event.preventDefault()
    this.props.updateMyProfile({
      token: this.props.token,
      data: {isInsightsEnabled: true},
    })
  }

  render() {
    const getAccessContent = this.props.currentUser.firm.isInsightsEnabled ? (
      <span>
        To activate {process.env.PRODUCT_NAME} access, click{' '}
        <a href="#" onClick={this.activateInsights.bind(this)}>
          here
        </a>
        .
      </span>
    ) : (
      <span>
        To request a free 14 day trial of {process.env.BRAND_NAME}{' '}
        {process.env.PRODUCT_NAME} at your firm, click{' '}
        <a href="https://www.diligent.com/industries/advisory-and-legal-research/">here</a>.
      </span>
    )
    return (
      <div className={styles.page}>
        <div className={styles.content}>
          <InsightsLogo className={styles.logo} />

          <p className={styles.text}>
            {process.env.BRAND_NAME} {process.env.PRODUCT_NAME} is the latest
            product from {process.env.BRAND_NAME} which seamlessly highlights
            risk and opportunities around companies and industries for
            professionals.
          </p>
          <p className={styles.text}>{getAccessContent}</p>

          <div>
            <div
              className={classNames(styles.inlineBlock, styles.imageContainer)}
            >
              <h3 className={styles.imageHeader}>
                {process.env.PRODUCT_NAME} Company Overview Page
              </h3>
              <img
                className={styles.image}
                src={overviewPageUrl}
                alt="Overview Page Screenshot"
              />
            </div>

            <div
              className={classNames(styles.inlineBlock, styles.textContainer)}
            >
              The company overview page provides a snapshot of the company’s
              health score, top news for each factor for a company, and an event
              timeline of their news so that you can quickly understand what’s
              going on with a company.
            </div>
          </div>

          <div className={classNames(styles.overlap)}>
            <div
              className={classNames(styles.inlineBlock, styles.textContainer)}
            >
              The {process.env.PRODUCT_NAME} Dashboard can help you quickly
              identify which companies have recently had a health score change
              and provides filter links so you can easily get to the cause of
              the change.
            </div>

            <div
              className={classNames(styles.inlineBlock, styles.imageContainer)}
            >
              <h3 className={styles.imageHeader}>
                {process.env.PRODUCT_NAME} Dashboard
              </h3>
              <img
                className={styles.image}
                src={dashboardPageUrl}
                alt="Dashboard Page Screenshot"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// export default NoInsightsAccess
export default connect(
  createSelector(
    [login.selectors.getCurrentUser, login.selectors.getJwt],
    (currentUser, token) => ({
      currentUser,
      token,
    }),
  ),
  {
    updateMyProfile: login.actions.updateMyProfile,
  },
)(NoInsightsAccess)
