import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import HealthScoreSvg from 'app/common/HealthScoreSvg'
import SizedContainer from 'app/common/SizedContainer'
import {MAX_HEALTH_SCORE, VALENCES} from 'app/constants'

import * as styles from './styles.less'

const SIDE_PADDING = 25
const MARKER_HEIGHT = 20

const HealthRangeComparisonChartContainer = ({points, className}) => (
  <SizedContainer>
    <HealthRangeComparisonChart points={points} className={className} />
  </SizedContainer>
)
HealthRangeComparisonChartContainer.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      healthScore: PropTypes.number.isRequired,
    }),
  ).isRequired,
  className: PropTypes.string,
}
export default HealthRangeComparisonChartContainer

function HealthRangeComparisonChart({
  points,
  className,
  width = 0,
  height = 0,
}) {
  return (
    <div className={classNames(styles.healthRangeComparison, className)}>
      <Chart points={points} width={width} height={height} />
    </div>
  )
}
HealthRangeComparisonChart.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      healthScore: PropTypes.number.isRequired,
    }),
  ).isRequired,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

function Chart({points, width, height}) {
  const midY = height / 2 + 5
  const midX = width / 2
  const leftmostX = SIDE_PADDING
  const rightmostX = width - SIDE_PADDING
  const leftMarkerX = SIDE_PADDING * 2
  const rightMarkerX = width - SIDE_PADDING * 2
  const markerY1 = midY - MARKER_HEIGHT / 2
  const markerY2 = midY + MARKER_HEIGHT / 2
  const markerScoreY = markerY2 + 18
  const markerLabelY = markerScoreY + 15
  const usableWidth = rightMarkerX - leftMarkerX

  const dots = points.map((point, index) => {
    const x = midX + ((point.healthScore / MAX_HEALTH_SCORE) * usableWidth) / 2
    const valence =
      point.healthScore > 0
        ? VALENCES.POSITIVE
        : point.healthScore < 0
        ? VALENCES.NEGATIVE
        : VALENCES.NEUTRAL
    return <ChartDot x={x} y={midY} valence={valence} key={index} />
  })

  const dotScores = points
    .filter(({healthScore}) => ![-10, 0, 10].includes(healthScore))
    .map((point, index) => {
      const x =
        midX + ((point.healthScore / MAX_HEALTH_SCORE) * usableWidth) / 2
      return (
        <ChartDotHealthScore
          healthScore={point.healthScore}
          x={x}
          y={midY + 28}
          key={index}
        />
      )
    })

  const dotLabels = points.map((point, index) => {
    const x = midX + ((point.healthScore / MAX_HEALTH_SCORE) * usableWidth) / 2
    const dy = `-${index}em`
    return (
      <ChartDotLabel
        label={point.label}
        x={x}
        y={midY - 20}
        dy={dy}
        key={index}
      />
    )
  })

  const hasNegative10Point = !!points.find(point => point.healthScore === -10)
  const hasPositive10Point = !!points.find(point => point.healthScore === 10)

  return (
    <svg className={styles.chart} width={width} height={height}>
      <path
        className={styles.midLine}
        d={`M ${leftmostX} ${midY} L ${rightmostX} ${midY}`}
      />

      {/* 0 marker */}
      <path
        className={styles.marker}
        d={`M ${midX} ${markerY1} L ${midX} ${markerY2}`}
      />
      <g className={styles.markerLabel}>
        <text className={styles.score} x={midX} y={markerScoreY}>
          0
        </text>
        <text className={styles.label} x={midX} y={markerLabelY}>
          Typical
        </text>
      </g>

      {/* -10 marker */}
      <path
        className={styles.marker}
        d={`M ${leftMarkerX} ${markerY1} L ${leftMarkerX} ${markerY2}`}
      />
      <g className={styles.markerLabel}>
        <text
          className={classNames(styles.score, {
            [styles.negative]: hasNegative10Point,
          })}
          x={leftMarkerX}
          y={markerScoreY}
        >
          -10
        </text>
        <text className={styles.label} x={leftMarkerX} y={markerLabelY}>
          High Risk
        </text>
      </g>

      {/* +10 marker */}
      <path
        className={styles.marker}
        d={`M ${rightMarkerX} ${markerY1} L ${rightMarkerX} ${markerY2}`}
      />
      <g className={styles.markerLabel}>
        <text
          className={classNames(styles.score, {
            [styles.positive]: hasPositive10Point,
          })}
          x={rightMarkerX}
          y={markerScoreY}
        >
          +10
        </text>
        <text className={styles.label} x={rightMarkerX} y={markerLabelY}>
          Very Healthy
        </text>
      </g>

      {dots}
      {dotScores}
      {dotLabels}
    </svg>
  )
}
Chart.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      healthScore: PropTypes.number.isRequired,
    }),
  ).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

function ChartDot({x, y, valence}) {
  const valenceClassName =
    valence === VALENCES.POSITIVE
      ? styles.positive
      : valence === VALENCES.NEGATIVE
      ? styles.negative
      : styles.neutral
  return (
    <circle
      className={classNames(styles.dot, valenceClassName)}
      cx={x}
      cy={y}
      r={6}
    />
  )
}
ChartDot.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  valence: PropTypes.string.isRequired,
}

function ChartDotHealthScore({healthScore, x, y}) {
  return (
    <HealthScoreSvg
      score={healthScore}
      className={styles.dotLabel}
      x={x}
      y={y}
    />
  )
}
ChartDotHealthScore.propTypes = {
  healthScore: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
}

function ChartDotLabel({label, x, y, dy}) {
  return (
    <text className={styles.dotLabel} x={x} y={y} dy={dy}>
      {label}
    </text>
  )
}
ChartDotLabel.propTypes = {
  label: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  dy: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
