import React from 'react'
import {Provider} from 'react-redux'

import ContentsContainer from 'app/global/ContentsContainer'
import ErrorBoundary from 'app/global/ErrorBoundary'

const App = () => {
  return (
    <ErrorBoundary>
      <ContentsContainer />
    </ErrorBoundary>
  )
}

export function createApp(store) {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}
