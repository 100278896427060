if (process.env.MANZAMA_BRAND === 'diligent') {
  // Official Diligent colors
  const redBright = '#EE213E'
  const redMedium = '#CF2026'
  const redMuted = '#BB2025'
  const redDark = '#921A1D'
  const grayVeryLight = '#A4BCC4'
  const grayLight = '#768693'
  const grayMedium = '#415464'
  const grayDark = '#131E29'

  const linkColor = '#1C73A2'
  const activeColor = '#44ACE2'
  const activeColorHover = '#378DB9'
  const textColorGeneral = '#444444'

  module.exports = {
    // Primary colors
    'main-color': grayMedium,
    'main-color-active': grayLight,
    'main-color-dark': grayMedium,
    'main-hover-color': '#E2ECF2',
    'main-hover-color-dark': '#76A7C2',

    'background-color-light': '#F2F2F2',
    'background-color-dark': grayDark,
    'background-color-soft': '#E2ECF2',

    'line-color-very-light': '#F2F2F2',
    'line-color-light': '#E2E2E2',
    'line-color-medium': '#B1B1B1',
    'line-color-dark': '#535353',

    'active-color': activeColor,
    'active-color-hover': activeColorHover,

    'link-color': linkColor,
    'link-color-hover': activeColorHover,

    // Default color for text
    'text-color-general': textColorGeneral,
    'text-color-very-light': '#E8E9E5',
    'text-color-light': '#888888',
    'text-color-medium': '#666666',
    'text-color-dark': textColorGeneral,
  }
}
// Default is Manzama
else {
  const mainColor = '#035C8B'
  const mainColorActive = '#0074b4'
  const activeColor = '#00AEC6'
  const textColorGeneral = '#444444'

  module.exports = {
    // Primary colors
    'main-color': mainColor,
    'main-color-active': mainColorActive,
    'main-color-dark': '#013653',
    'main-hover-color': '#E2ECF2',
    'main-hover-color-dark': '#76A7C2',

    'background-color-light': '#F2F2F2',
    'background-color-dark': '#222222',
    'background-color-soft': '#E2ECF2',

    'line-color-very-light': '#F2F2F2',
    'line-color-light': '#E2E2E2',
    'line-color-medium': '#B1B1B1',
    'line-color-dark': '#535353',

    'active-color': activeColor,
    'active-color-hover': '#006471',

    'link-color': mainColor,
    'link-color-hover': mainColorActive,

    // Default color for text
    'text-color-general': textColorGeneral,
    'text-color-very-light': '#E8E9E5',
    'text-color-light': '#888888',
    'text-color-medium': '#666666',
    'text-color-dark': textColorGeneral,
  }
}
