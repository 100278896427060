function tooltipCollector() {
  const _collection = []
  let _min = { y: 0, value: 0 }
  let _max = { y: 1, value: 1 }

  function _setMaxAndMin() {
    const ys = _collection.map(obj => obj.y)
    const maxY = Math.max(...ys)
    const maxYIndex = ys.indexOf(maxY)
    _max = _collection[maxYIndex]
    const minY = Math.min(...ys)
    const minYIndex = ys.indexOf(minY)
    _min = _collection[minYIndex]
  }

  return {
    collect: (value, y) => {
      _collection.push({ value, y })
      _setMaxAndMin(_collection)
    },
    maxAndMin: () => {
      return {
        max: { ..._max },
        min: { ..._min },
      }
    },
  }
}

export { tooltipCollector }
