import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import React from 'react'

import FactorHealthBadge from 'app/reusable/health/health-badge/factor/FactorHealthBadge'
import SubfactorHealthBadge from 'app/reusable/health/health-badge/subfactor/SubfactorHealthBadge'

import * as styles from './OverviewHealth.less'

export default class OverviewHealth extends React.PureComponent {
  render() {
    const {healthData, selectedFactor, selectedSubfactor} = this.props

    let healthBadge
    if (selectedFactor) {
      healthBadge = (
        <SubfactorHealthBadge
          healthData={healthData}
          factor={selectedFactor}
          selectedSubfactor={selectedSubfactor}
          onSubfactorClick={bind(this.onSubfactorClick, this)}
          className={styles.healthBadge}
        />
      )
    } else {
      healthBadge = (
        <FactorHealthBadge
          healthData={healthData}
          onFactorClick={bind(this.onFactorClick, this)}
          className={styles.healthBadge}
        />
      )
    }

    return <div className={styles.overviewHealth}>{healthBadge}</div>
  }

  onFactorClick(factor) {
    if (this.props.selectFactor) {
      this.props.selectFactor(factor)
    }
  }

  onSubfactorClick(subfactor) {
    if (this.props.selectSubfactor) {
      this.props.selectSubfactor(subfactor)
    }
  }
}
OverviewHealth.propTypes = {
  healthData: PropTypes.object.isRequired,

  selectedFactor: PropTypes.string,
  selectedSubfactor: PropTypes.string,

  // Actions
  selectFactor: PropTypes.func,
  selectSubfactor: PropTypes.func,
}
