import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './AddEntitiesModalSearch.less'

export default function AddEntitiesModalSuccess({entities, entityType}) {
  const entityLabel = entityType === 'company' ? 'company' : 'industry'
  const entityLabelPlural =
    entityType === 'company' ? 'companies' : 'industries'
  return (
    <div>
      <h2 className={styles.confirmedMessage}>
        {entities.length === 1
          ? `This ${entityLabel} has`
          : `These ${entityLabelPlural} have`}{' '}
        been added successfully.
      </h2>
    </div>
  )
}
AddEntitiesModalSuccess.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.object).isRequired,
  entityType: PropTypes.string.isRequired,
}
