import React from 'react'

export default function CrossIcon({ className, ...restProps }) {
  return (
    <svg viewBox="0 0 7 8" fill="none" className={className} {...restProps} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M0.219455 0.719646C0.512362 0.426766 0.987236 0.426787 1.28012 0.719694L3.49966 2.93944L5.71918
            0.71992C6.01208 0.427027 6.48695 0.427027 6.77984 0.71992C7.07274 1.01281 7.07274 1.48769 6.77984
            1.78058L4.56027 4.00015L6.77987 6.21994C7.07275 6.51285 7.07273 6.98772 6.77982 7.2806C6.48691 7.57348
            6.01204 7.57346 5.71916 7.28055L3.49961 5.06081L1.27984 7.28058C0.986948 7.57347 0.512075 7.57347 0.219181
            7.28058C-0.0737116 6.98768 -0.0737115 6.51281 0.219182 6.21992L2.439 4.0001L0.219408 1.78031C-0.0734724
            1.4874 -0.073451 1.01253 0.219455 0.719646Z"
            fill="currentColor"/>
    </svg>
  )
}
