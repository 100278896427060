import classNames from 'classnames'
import PropTypes from 'prop-types'
import {map, pipe, prop, sortBy, take} from 'ramda'
import React from 'react'
import {connect} from 'react-redux'

import StorySummaryBlock from 'app/common/story-summary-block'
import {showModal} from 'app/storyline-modal/storyline-modal-actions'
import urls from 'app/urls'

import * as styles from './styles.less'

export default function RelatedStorylines({storyline}) {
  if (!storyline.relatedStorylines.length) {
    return (
      <div className={styles.noRelatedStorylines}>
        This storyline has no related storylines.
      </div>
    )
  }
  const storylineItems = pipe(
    sortBy(prop('distance')),
    take(10),
    map(relatedStoryline => (
      <RelatedStorylineBlockConnected
        storyline={relatedStoryline.toStoryline}
        key={relatedStoryline.id}
      />
    )),
  )(storyline.relatedStorylines)

  return <div>{storylineItems}</div>
}
RelatedStorylines.propTypes = {
  storyline: PropTypes.object.isRequired,
}

function RelatedStorylineBlock({storyline, showShareModalForStorylineId}) {
  return (
    <div className={classNames(styles.relatedStoryline, 'related-storyline')}>
      <StorySummaryBlock
        headline={storyline.summary}
        valence={storyline.valence}
        factor={storyline.factor}
        subfactor={storyline.subfactor}
        isLitigation={storyline.isLitigation}
        isOpinion={storyline.isOpinion}
        isRumor={storyline.isRumor}
        articleCount={storyline.articleCount}
        startDate={storyline.startDate}
        endDate={storyline.endDate}
        href={urls.storylineView(storyline.id)}
        onShareClick={() => showShareModalForStorylineId(storyline.id)}
      />
    </div>
  )
}
RelatedStorylineBlock.propTypes = {
  storyline: PropTypes.object.isRequired,

  // Actions
  showShareModalForStorylineId: PropTypes.func.isRequired,
}
const RelatedStorylineBlockConnected = connect(
  undefined,
  {
    showShareModalForStorylineId: storylineId => showModal({storylineId}),
  },
)(RelatedStorylineBlock)
