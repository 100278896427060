import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import ManzamaLogo from 'app/common/logos/manzama-logo'
import Link from 'app/common/NavigationLink'
import * as login from 'app/login'
import urls from 'app/urls'

import MasqueradeBar from './MasqueradeBar'
import SubNav from './SubNav'
import { Tooltip } from 'app/common/tooltip'
import InlineSvg from 'app/common/InlineSvg'

import styles from './styles.less'
import { MENU_ICONS, MAX_BADGE_COUNT, screenWidthForHamburgerMenu, screenWidthForFullHamburgerMenu } from '../../constants'
import _ from 'lodash'
import useWindowSize from '../../common/custom-hooks/useWindowSize'
import ResponsiveMenu from './ResponsiveMenu'

const doesUrlMatch = url => window.location.pathname === url
const iconStyle = { height: "22px", width: "26px"}
const IconLink = ({url, label, icon, badgeCount, onClick,
   matchingUrls = [], noTooltip = false, highlight = false,
   matchURL = doesUrlMatch}) => {
  matchingUrls = [...matchingUrls, url]
  const active = matchingUrls.some(url => matchURL(url))
  const getUpdatedBadgeCount = (badgeCount) => Number(badgeCount) > MAX_BADGE_COUNT ? '99+' : badgeCount
  return (
    <Tooltip label={label} disabled={noTooltip}>
      <div
        className={classNames(
          styles.link,
          {
            [styles.active]: active,
            [styles.highlight]: highlight
          }
        )} >
          <a href={url} onClick={onClick} className={styles.badgeContainer}>
            {icon ? <InlineSvg src={icon} style={iconStyle}/> : label}
            {badgeCount ? <div className={styles.badge}>{getUpdatedBadgeCount(badgeCount)}</div> : null}
          </a>
      </div>
    </Tooltip>
  )
}
Link.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
}

const Separator = () => <div className={classNames(styles.link, {[styles.separator]: true})}>
    <InlineSvg src={MENU_ICONS.separator} style={iconStyle} />
  </div>

function Header({currentUser, adminHash, flaggedArticleCount}) {

  const navBar = useRef(null)
  const [isMenuActive, setIsMenuActive] = useState(false) 
  const showMenu = () => setIsMenuActive(true)
  const hideMenu = () => setIsMenuActive(false) 
  
  const [isResponsiveMenuActive, setIsResponsiveMenuActive] = useState(false) 
  const [screenWidth, screenHeight] = useWindowSize();
  const showHalfResponsiveMenu = screenWidth <= screenWidthForHamburgerMenu
  const showFullResponsiveMenu = screenWidth <= screenWidthForFullHamburgerMenu
  const showAdminMenu = !showHalfResponsiveMenu
  const showPrimaryMenu = !showFullResponsiveMenu
  const showHamburgerMenu = showHalfResponsiveMenu
  const hamburgerIconClick = ()=> setIsResponsiveMenuActive(!isResponsiveMenuActive)

  let adminLink
  if (currentUser.isStaff || currentUser.isFirmAdmin) {
    adminLink = <IconLink 
                  url={urls.pyapp.admin()}  
                  icon={MENU_ICONS.admin}
                  label="Admin"
                />
  } else if (currentUser.isGroupManager) {
    adminLink = <IconLink 
                  url={urls.pyapp.admin()}  
                  icon={MENU_ICONS.admin}
                  label="Manage Groups" 
                />
  } else {
    adminLink = null
  }

  const userName = _.startCase(_.camelCase(currentUser.displayName))
  const showOldPublish = currentUser.hasPublishAccess && process.env.MANZAMA_BRAND === 'manzama'
  const showNewPublish = currentUser.hasPublishV3Access
  const publishTooltip = "Publications"

  const oldPublish = <IconLink 
      url={urls.pyapp.publish()} 
      icon={MENU_ICONS.publish}
      label={publishTooltip}
    />

  const matchNewPublishURL = url => window.location.pathname.startsWith(url)
  const newPublish = <IconLink 
      url={urls.pyapp.publishV3()} 
      icon={MENU_ICONS.publish}
      label={publishTooltip}
      matchURL={matchNewPublishURL}
    />

  const allPublishIconLinks = (
    <div onMouseEnter={showMenu} onMouseLeave={hideMenu}>
      <IconLink 
        url={urls.pyapp.publishV3()} 
        matchingUrls={[urls.pyapp.publish()]} 
        icon={MENU_ICONS.publish}
        noTooltip
        label="" 
        highlight={isMenuActive}
        matchURL={matchNewPublishURL}
      />
      <div className={classNames(styles.dropMenuContainer, {[styles.dropMenuActive]: isMenuActive})}>
        <Tooltip label={publishTooltip}>
          <a href={urls.pyapp.publish()} className={styles.dropMenu}>
            Publish
          </a>
        </Tooltip>
        <Tooltip label={`${publishTooltip} NEW`}>
          <a href={urls.pyapp.publishV3()} className={styles.dropMenu}>
            Publish <div className={styles.newTag}>New</div>
          </a>
        </Tooltip>
      </div>
    </div>
  )

  const getPublishLink = () => {
    if (showOldPublish && showNewPublish) {
      return allPublishIconLinks
    }
    else if (showOldPublish){
      return oldPublish
    }
    else if (showNewPublish){
      return newPublish
    }
    else {
      return null
    }
  }

  const logo = <>
          <Link href={urls.home()} className={styles.logoSection}>
              {currentUser.firm.headerLogo ? (
                <img
                  src={currentUser.firm.headerLogo}
                  alt={currentUser.firm.name}
                  className={classNames(styles.logo, styles.firm)}
                />
              ) : (
                <ManzamaLogo
                  color={ManzamaLogo.COLORS.LIGHT}
                  className={classNames(
                    styles.logo,
                    styles[process.env.MANZAMA_BRAND],
                  )}
                />
              )}
            </Link>
        </>

  const primaryMenu = <>
          <div id="primary-menu" className={styles.logoAndProducts}>
            <div className={styles.productLinks}>
              <a href={urls.pyapp.home()} className={styles.link}>
                Base
              </a>

              <Link
                href={urls.home()}
                className={classNames(styles.link, styles.active)}
              >
                {process.env.PRODUCT_NAME}
              </Link>

              {!currentUser.firm.shouldHideMisLink && (
                <a href={urls.pyapp.mis()} className={styles.link}>
                  {process.env.MANZAMA_BRAND === 'diligent'
                    ? 'Intel Now'
                    : 'MIS'}
                </a>
              )}

              <a href={urls.pyapp.esgDashboard()} className={styles.link}>
                ESG Dashboard
              </a>
            </div>
          </div>
        </>

  const adminMenu =  <>
          <div id="admin-menu" className={styles.profileSection}>

            <IconLink 
                url={urls.pyapp.help()} 
                icon={MENU_ICONS.help}
                label="Help" 
            />

            <IconLink
              url={urls.pyapp.flagged()}
              icon={MENU_ICONS.flag}
              label="Flagged Items"
              badgeCount={flaggedArticleCount}
            />

            {getPublishLink()}

            <Separator/>
            {adminLink}

            <IconLink 
              url={urls.pyapp.emailSettings()} 
              icon={MENU_ICONS.emailNotifications}
              label="Email Notifications" 
              />

            <div
              className={classNames(
                styles.link,
                {
                  [styles.active]: isProfileActive
                },
              )}
            >
              <Tooltip label={'My Profile'}>
                <a href={urls.pyapp.profile()} className={styles.userInfo}>
                  <div className={styles.userNameLink}>
                    <div className={styles.userName}>{userName}</div>
                    <div className={styles.firmName}>{currentUser.firm.name}</div> 
                  </div>
                </a>
              </Tooltip>
            </div>

            {/* Left for easy access to Admin Page */}
            {/* {adminHash ? <IconLink 
                      url={urls.djangoAdmin(adminHash)} 
                      label="Admin Console" 
                    />
                : null} */}

            <IconLink 
              url={urls.logout()} 
              icon={MENU_ICONS.logout} 
              label="Sign Out" 
            />

          </div>
        </>

  const hamburgerMenu = <>
        <ResponsiveMenu
            isGroupManager={currentUser.isGroupManager}
            isDiligent={process.env.MANZAMA_BRAND === 'diligent' ? true : false}
            shouldHideMisLink={currentUser.firm.shouldHideMisLink}
            showOldPublish={showOldPublish}
            showNewPublish={showNewPublish}
            flaggedArticleCount={flaggedArticleCount}
            displayName={currentUser.displayName}
            firmName={currentUser.firm.name}
            currentUser={currentUser}
            showPrimaryMenu={showPrimaryMenu}
            showAdminMenu={showAdminMenu}
            hideMenu={()=>setIsResponsiveMenuActive(false)}
      />
      </>

  const navBarYEnd = navBar.current ? (navBar.current.getBoundingClientRect().y + navBar.current.getBoundingClientRect().height) : 60
  const windowHeight = window.innerHeight - navBarYEnd
  const hamburgerIcon = <>
          <div className={classNames(styles.hamburgerIcon, {[styles.active]: isResponsiveMenuActive})}>
            <InlineSvg 
              onClick={hamburgerIconClick}
              src={MENU_ICONS.hamburger}/>
          </div>
          <div id="hamburger-menu" className={classNames(styles.hamburgerMenuContainer, {[styles.active]: isResponsiveMenuActive})}
            style={{top: navBarYEnd, height: windowHeight}}
          >
            <div className={styles.wrapper}>
              <div className={classNames(styles.slider, {[styles.slideLeft]: isResponsiveMenuActive})}>
                {hamburgerMenu}
              </div>
            </div>
          </div>
        </>

  const isProfileActive = window.location.pathname.localeCompare(urls.pyapp.profile()) === 0
  
  return (
    <div>
      {currentUser.isMasquerading && (
        <MasqueradeBar
          userName={currentUser.displayName}
          unmaskUrl={urls.pyapp.unmask()}
        />
      )}

    <div className={styles.header}>
      <div ref={navBar} className={styles.container}>

        {logo}
        {showPrimaryMenu ? primaryMenu : null}
        {showAdminMenu ? adminMenu : null}
        {showHamburgerMenu ? hamburgerIcon : null}
      </div>
    </div>
      {currentUser.isInsightsEnabled && showPrimaryMenu ? <SubNav />: null}
    </div>
  )
}
Header.propTypes = {
  currentUser: PropTypes.object,
  adminHash: PropTypes.string,
  flaggedArticleCount: PropTypes.number.isRequired,
}

export default connect(
  createSelector(
    [
      login.selectors.getCurrentUser,
      login.selectors.getAdminHash,
      login.selectors.getFlaggedArticleCount,
    ],
    (currentUser, adminHash, flaggedArticleCount) => ({
      currentUser,
      adminHash,
      flaggedArticleCount,
    }),
  ),
)(Header)
