import Resource from "../framework/Resource";
import LitigationTimeSeriesModel from "../models/LitigationTimeSeries";

export default class LitigationTimeSeries extends Resource {
  static endpoint = "rpc/litigation_time_series";

  entityItemsForRestItem(restData) {
    return [
      {
        id: restData.id,
        entityKey: LitigationTimeSeriesModel.entityKey,
        data: {
          id: restData.id,
          companyName: restData.companyName,
          companyRank: restData.companyRank,
          companyWeeklyLitigationCounts: restData.companyWeeklyLitigationCounts,
        }
      }
    ]
  }
}
