import React from 'react'
import classNames from 'classnames'
import * as styles from './GrowthBadge.less'
import PropTypes from 'prop-types'
import TrendDirectionIcon from 'app/litigation/icons/TrendDirectionIcon'
import TrendStagnatedIcon from 'app/litigation/icons/TrendStagnatedIcon'

export default function GrowthBadge({ growth, className }) {

  const classes = classNames(
    styles.growthBadge,
    {
      [styles.growthBadgePositive]: growth > 0,
      [styles.growthBadgeNegative]: growth < 0,
    },
    className
  )

  const trendDirectionIconClasses = classNames(
    styles.trendDirectionIcon,
    {
      [styles.trendDirectionIconPositive]: growth > 0,
      [styles.trendDirectionIconNegative]: growth < 0,
      [styles.trendDirectionIconStagnated]: growth === 0,
    }
  )

  // Allow 2 decimal places when x < 10, 1 when 10 <= x < 100 and 0 when x >= 100.
  const numberOfDigits = Math.max(Math.floor(Math.log10(Math.abs(growth))), 0) + 1
  const growthText = growth.toFixed(Math.max(3 - numberOfDigits, 0))

  return (
    <>
      <div className={classes}>
        {growth === 0 ?
          <TrendStagnatedIcon
            className={trendDirectionIconClasses}
          />
          :
          <TrendDirectionIcon
            className={trendDirectionIconClasses}
          />
        } {growthText}%
      </div>
    </>
  )
}

GrowthBadge.propTypes = {
  growth: PropTypes.number.isRequired,
  className: PropTypes.string,
}
