import classNames from 'classnames'
import is from 'is'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import React from 'react'

import FactorIcon from 'app/common/FactorIcon'
import HealthScore from 'app/common/HealthScore'
import HelpIcon from 'app/common/icons/HelpIcon'
import InlineSvg from 'app/common/InlineSvg'
import Link from 'app/common/NavigationLink'
import {Tooltip} from 'app/common/tooltip'
import {
  ORDERED_FACTORS,
  ORDERED_SUBFACTORS_BY_FACTOR,
  VALENCES,
} from 'app/constants'
import {
  factorDisplayName,
  subfactorDisplayName,
  valenceDisplayName,
} from 'app/strings'

import {changePrediction} from './health-factor-summary-utils'
import SummaryTable from './SummaryTable'

import * as styles from './HealthFactorSummary.less'

import arrowUrl from './prediction-arrow.svg'

export default class HealthFactorSummary extends React.PureComponent {
  static propTypes = {
    healthData: PropTypes.object.isRequired,
    factor: PropTypes.string,
    subfactor: PropTypes.string,
    predictedHealthScoreChangesByFactor: PropTypes.object,
    hasConfidenceInPredictionsByFactor: PropTypes.object,
    factorPredictionUrl: PropTypes.func,
    shouldShowHelpIcon: PropTypes.bool,
    shouldShowFilterIcon: PropTypes.bool,
    onHelpIconClick: PropTypes.func,
    factorFilterUrl: PropTypes.func,
    factorTooltip: PropTypes.node,
    subfactorFilterUrl: PropTypes.func,
    subfactorTooltip: PropTypes.node,
  }

  static defaultProps = {
    onHelpIconClick() {},
  }

  render() {
    const {
      healthData,
      factor,
      subfactor,
      predictedHealthScoreChangesByFactor,
      hasConfidenceInPredictionsByFactor,
      factorPredictionUrl,
      shouldShowHelpIcon = false,
      factorFilterUrl,
      factorTooltip,
      subfactorFilterUrl,
      subfactorTooltip,
    } = this.props

    let items
    if (subfactor) {
      const subfactorHealthData = healthData[subfactor] || {
        articleVolume: 0,
        positiveVolume: 0,
        neutralVolume: 0,
        negativeVolume: 0,
      }
      items = [
        {
          head: <Head>{subfactorDisplayName(subfactor)} Health Score</Head>,
          body: (
            <ScoreBody
              score={subfactorHealthData.healthScore}
              isSubfactor={true}
            />
          ),
          key: 'score',
        },
        {
          head: <Head>Total Article Count</Head>,
          body: <Body>{subfactorHealthData.articleVolume}</Body>,
          key: 'volume',
        },
        {
          head: <Head>Positive Articles</Head>,
          body: (
            <Body>
              <span className={styles.positive}>
                {subfactorHealthData.positiveVolume}
              </span>
            </Body>
          ),
          key: 'positive',
        },
        {
          head: <Head>Neutral Articles</Head>,
          body: <Body>{subfactorHealthData.neutralVolume}</Body>,
          key: 'neutral',
        },
        {
          head: <Head>Negative Articles</Head>,
          body: (
            <Body>
              <span className={styles.negative}>
                {subfactorHealthData.negativeVolume}
              </span>
            </Body>
          ),
          key: 'negative',
        },
      ]
    } else if (factor) {
      items = ORDERED_SUBFACTORS_BY_FACTOR[factor].map(subfactor => ({
        head: (
          <Head
            shouldShowHelpIcon={shouldShowHelpIcon}
            onHelpIconClick={bind(
              this.onSubfactorHelpIconClick,
              this,
              subfactor,
            )}
            url={subfactorFilterUrl && subfactorFilterUrl(subfactor)}
            tooltip={subfactorTooltip}
          >
            {subfactorDisplayName(subfactor)}
          </Head>
        ),
        body: (
          <ScoreBody
            score={healthData[subfactor] && healthData[subfactor].healthScore}
            isSubfactor={true}
          />
        ),
        key: subfactor,
      }))
    } else {
      items = ORDERED_FACTORS.map(factor => ({
        head: (
          <Head
            shouldShowHelpIcon={shouldShowHelpIcon}
            onHelpIconClick={bind(this.onFactorHelpIconClick, this, factor)}
            url={factorFilterUrl && factorFilterUrl(factor)}
            tooltip={factorTooltip}
          >
            <FactorIcon factor={factor} className={styles.factorIcon} />
            <span>{factorDisplayName(factor)}</span>
          </Head>
        ),
        body: (
          <ScoreBody
            score={healthData[factor] && healthData[factor].healthScore}
            predictedChange={
              predictedHealthScoreChangesByFactor &&
              predictedHealthScoreChangesByFactor[factor]
            }
            predictedChangeHasConfidence={
              hasConfidenceInPredictionsByFactor &&
              hasConfidenceInPredictionsByFactor[factor]
            }
            predictionLabel={factorDisplayName(factor)}
            predictionUrl={factorPredictionUrl(factor)}
          />
        ),
        key: factor,
      }))
    }

    return <SummaryTable items={items} className="health-factor-summary" />
  }

  onFactorHelpIconClick(factor) {
    this.props.onHelpIconClick({factor})
  }

  onSubfactorHelpIconClick(subfactor) {
    this.props.onHelpIconClick({subfactor})
  }
}

function Body({className, children}) {
  return <div className={classNames(styles.body, className)}>{children}</div>
}
Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

function Head({
  children,
  shouldShowHelpIcon = false,
  onHelpIconClick,
  url,
  tooltip,
}) {
  let name = url ? (
    <Link href={url} className={classNames(styles.name, styles.link)}>
      {children}
    </Link>
  ) : (
    <span className={styles.name}>{children}</span>
  )
  if (tooltip) {
    name = (
      <Tooltip label={tooltip} direction="bottom">
        {name}
      </Tooltip>
    )
  }
  return (
    <div className={styles.header}>
      {name}
      {shouldShowHelpIcon && (
        <HelpIcon
          onClick={() => onHelpIconClick()}
          className={classNames(styles.helpIcon, 'details-icon')}
        />
      )}
    </div>
  )
}
Head.propTypes = {
  children: PropTypes.node.isRequired,
  shouldShowHelpIcon: PropTypes.bool,
  onHelpIconClick: PropTypes.func,
  url: PropTypes.string,
  tooltip: PropTypes.node,
}

function ScoreBody({
  score,
  predictedChange = null,
  predictedChangeHasConfidence = null,
  predictionLabel,
  predictionUrl,
  isSubfactor = false,
}) {
  const scoreDisplay = is.defined(score) ? (
    <React.Fragment>
      <HealthScore score={score} />
      {predictedChange && (
        <ChangePrediction
          score={score}
          change={predictedChange}
          hasConfidence={predictedChangeHasConfidence}
          label={predictionLabel}
          url={predictionUrl}
        />
      )}
    </React.Fragment>
  ) : (
    <div className={styles.notEnoughData}>
      No data in this {isSubfactor ? 'subfactor' : 'factor'} during the last 30
      days
    </div>
  )
  return <Body className="score">{scoreDisplay}</Body>
}
ScoreBody.propTypes = {
  score: PropTypes.number,
  predictedChange: PropTypes.number,
  predictedChangeHasConfidence: PropTypes.bool,
  predictionLabel: PropTypes.string,
  predictionUrl: PropTypes.string,
  isSubfactor: PropTypes.bool,
}

function ChangePrediction({score, change, hasConfidence, label, url}) {
  const {valence, isLarge} = changePrediction({score, change})
  const cssClasses = classNames({
    [styles.positive]: valence === VALENCES.POSITIVE,
    [styles.neutral]: valence === VALENCES.NEUTRAL,
    [styles.negative]: valence === VALENCES.NEGATIVE,
    [styles.large]: isLarge,
  })
  let directionLabel = valenceDisplayName(valence)
  if (isLarge) {
    directionLabel = `Very ${directionLabel}`
  }
  const tooltipLabel = (
    <React.Fragment>
      <div className={styles.head}>
        3-Month Health Forecast:{' '}
        <span className={classNames(cssClasses)}>{directionLabel}</span>
      </div>
      <div>
        This is a forecast of the general direction of this company's {label}{' '}
        health score over the next 3 months. Click to go to the SimCirc page for
        this company for more details.
      </div>
    </React.Fragment>
  )
  return (
    <Tooltip
      label={tooltipLabel}
      className={styles.predictionTooltip}
      containerClassName={styles.prediction}
    >
      <Link href={url}>
        <InlineSvg
          src={arrowUrl}
          className={classNames(styles.arrow, cssClasses,
            {[styles.confidence]: hasConfidence && valence !== VALENCES.NEUTRAL})}
        />
      </Link>
    </Tooltip>
  )
}
ChangePrediction.propTypes = {
  score: PropTypes.number.isRequired,
  change: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}
