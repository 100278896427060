import {createAction} from 'redux-actions'

export const showModal = createAction('SHOW_FLAG_MODAL')
export const hideModal = createAction('HIDE_FLAG_MODAL')
export const fetchFlagCategories = createAction('FETCH_FLAG_CATEGORIES')
export const setFlagCategories = createAction('SET_FLAG_CATEGORIES')
export const changeArticleFlag = createAction('CHANGE_ARTICLE_FLAG')
export const addFlagCategory = createAction('ADD_FLAG_CATEGORY')
export const setAddFlagCategoryForId = createAction(
  'SET_ADD_FLAG_CATEGORY_FOR_ID',
)
export const hideAddFlagCategoryForm = createAction(
  'HIDE_ADD_FLAG_CATEGORY_FORM',
)
export const setIsLoading = createAction('SET_IS_LOADING')
export const setIsAddingFlagCategory = createAction(
  'SET_IS_ADDING_FLAG_CATEGORY',
)
