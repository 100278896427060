import React from 'react'
import {connect} from 'react-redux'

import {createSelector} from 'reselect'
import {getEntities} from 'app/framework/entities-selectors'
import EntityInput from 'app/reusable/entities/entity-input'
import Orm from 'app/framework/Orm'
import {Company, Industry} from 'app/models'

import * as profileBuilderActions from './profile-builder-actions'
import {getState} from './profile-builder-selectors'

import './Companies.less'

class Companies extends React.PureComponent {
  componentDidMount() {
    this.props.setSelectedSuggestedCompanyIds([])
    this.props.selectedIndustryIds
      .filter(id => !!id)
      .forEach(id => {
        if (!(id in this.props.suggestedCompanyIds)) {
          this.props.fetchCompanies(id)
        }
      })
  }

  render() {
    const numberLabels = [
      'first',
      'second',
      'third',
      'fourth',
      'fifth',
      'sixth',
      'seventh',
      'eighth',
      'ninth',
      'tenth',
    ]

    const selectionContent = (
      <div className="builder-content-row">
        {[...this.props.selections].map((c, idx) => {
          const numberLabel = numberLabels[idx] || ''
          const selectedValues = c
            ? [
                {
                  value: c.id,
                  label: c.name,
                },
              ]
            : []
          return (
            <div key={idx} className="search-category-item">
              <div className="search-category-item-header">
                {numberLabel.toUpperCase()} COMPANY
              </div>
              <EntityInput
                entityType="company"
                onChange={values => this.handleSearchInputChange(idx, values)}
                optionClassName="profile-builder-entity-option"
                selectedValues={selectedValues}
                maxValues={1}
              />
            </div>
          )
        })}
      </div>
    )

    const suggestionContent = !!this.props.suggestedCompaniesByIndustry
      .length && (
      <div className="builder-content-row">
        <h5>Suggestions based on Industry Selections</h5>
        <hr />

        {this.props.suggestedCompaniesByIndustry.map(s => {
          return (
            <div key={s.industry.id}>
              <h4>{s.industry.name}</h4>

              <div className="suggested-company-list">
                {s.companies.map(c => {
                  return (
                    <label key={c.id}>
                      <input
                        type="checkbox"
                        checked={this.props.selectedSuggestedCompanyIds.includes(
                          c.id,
                        )}
                        onChange={evt =>
                          this.handleSuggestionCheckboxChange(evt, c.id)
                        }
                      />
                      <span>{c.name}</span>
                    </label>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    )

    return (
      <div
        id="profile-builder-companies"
        className="builder-content-body-container"
      >
        <div className="builder-content-body">
          <p>
            {process.env.PRODUCT_NAME} also provides an interactive overview of
            company health, broken down by the six health factors used for the
            health score calculation. Enter up to three companies, or select
            from the list below, that you'd like to track. You'll be able to add
            more companies in the platform.
          </p>

          {selectionContent}

          {suggestionContent}
        </div>
      </div>
    )
  }

  handleSuggestionCheckboxChange(evt, companyId) {
    let selectedSuggestedCompanyIds = [
      ...this.props.selectedSuggestedCompanyIds,
    ]
    if (evt.target.checked) {
      selectedSuggestedCompanyIds.push(companyId)
    } else {
      selectedSuggestedCompanyIds = selectedSuggestedCompanyIds.filter(
        id => id !== companyId,
      )
    }
    this.props.setSelectedSuggestedCompanyIds(selectedSuggestedCompanyIds)
  }

  handleSearchInputChange(idx, values) {
    // we only allow one value per input
    const id = values.length ? values[0].value : null
    const selections = [...this.props.selectedCompanyIds]
    selections[idx] = id
    this.props.setSelectedCompanyIds(selections)
  }
}

export default connect(
  createSelector(
    [getEntities, getState],
    (entities, profileBuilder) => {
      const orm = Orm.withEntities(entities)

      return {
        selectedCompanyIds: profileBuilder.selectedCompanyIds,
        selectedIndustryIds: profileBuilder.selectedIndustryIds,
        selectedSuggestedCompanyIds: profileBuilder.selectedSuggestedCompanyIds,
        selections: orm.getByIds(Company, profileBuilder.selectedCompanyIds),
        suggestedCompanyIds: profileBuilder.suggestedCompanyIds,
        suggestedCompaniesByIndustry: profileBuilder.selectedIndustryIds
          .filter(id => !!id)
          .map(id => {
            const industry = orm.getById(Industry, id)
            return {
              industry,
              companies:
                id in profileBuilder.suggestedCompanyIds
                  ? orm.getByIds(
                      Company,
                      profileBuilder.suggestedCompanyIds[id],
                    )
                  : [],
            }
          }),
      }
    },
  ),
  {
    setSelectedCompanyIds: profileBuilderActions.setSelectedCompanyIds,
    setSelectedSuggestedCompanyIds:
      profileBuilderActions.setSelectedSuggestedCompanyIds,
    fetchCompanies: profileBuilderActions.fetchCompanies,
  },
)(Companies)
