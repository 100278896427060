import PropTypes from 'prop-types'
import React from 'react'

import {ORDERED_FACTORS} from 'app/constants'
import FactorHealthCard from 'app/reusable/health/factor-health-card'
import urls from 'app/urls'

import * as styles from './CompanyDetails.less'

export default function CompanyDetails({company, geography}) {
  const factorSections = ORDERED_FACTORS.map(factor => (
    <FactorHealthCard
      className={styles.card}
      healthData={company.healthData}
      factor={factor}
      shouldShowFilterIcon={true}
      filterUrl={urls.companyOverview(company.id, {factor, geography})}
      key={factor}
    />
  ))
  return <div className={styles.companyDetails}>{factorSections}</div>
}
CompanyDetails.propTypes = {
  company: PropTypes.object.isRequired,
  geography: PropTypes.number,
}
