import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import SizedContainer from 'app/common/SizedContainer'
import * as strings from 'app/strings'
import {trueRound} from 'app/utils/numbers'

import * as styles from './RangeChart.less'

export default function RangeChartContainer({
  value,
  rangeMinValue,
  rangeMaxValue,
  className,
}) {
  return (
    <SizedContainer>
      <RangeChart
        value={trueRound(value)}
        rangeMinValue={trueRound(rangeMinValue)}
        rangeMaxValue={trueRound(rangeMaxValue)}
        className={className}
      />
    </SizedContainer>
  )
}
RangeChartContainer.propTypes = {
  value: PropTypes.number.isRequired,
  rangeMinValue: PropTypes.number.isRequired,
  rangeMaxValue: PropTypes.number.isRequired,
  className: PropTypes.string,
}

function RangeChart({
  value,
  rangeMinValue,
  rangeMaxValue,
  className,
  width = 0,
  height = 0,
}) {
  const labelSize = 14
  const verticalSpacing = labelSize * 1.5
  const horizontalPadding = labelSize

  return (
    <svg
      className={classNames(styles.chart, className)}
      width={width}
      height={height}
    >
      <Range
        value={value}
        minValue={rangeMinValue}
        maxValue={rangeMaxValue}
        width={width - horizontalPadding * 2}
        height={height - verticalSpacing}
        x={horizontalPadding}
        y={verticalSpacing}
        strokeWidth={2}
      />
      <ScoreLabel score={rangeMinValue} x={horizontalPadding} y={labelSize} />
      <ScoreLabel
        score={rangeMaxValue}
        x={width - horizontalPadding}
        y={labelSize}
      />
    </svg>
  )
}
RangeChart.propTypes = {
  value: PropTypes.number.isRequired,
  rangeMinValue: PropTypes.number.isRequired,
  rangeMaxValue: PropTypes.number.isRequired,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

function Range({value, minValue, maxValue, width, height, x, y}) {
  const rangeLength = maxValue - minValue
  const getXForValue = value => x + (width * (value - minValue)) / rangeLength

  const valueX = getXForValue(value)
  const midLineY = y + height / 2
  const valenceClassName =
    value >= 0.5
      ? styles.positive
      : value <= -0.5
      ? styles.negative
      : styles.neutral

  const zeroLineHeight = height * 0.6
  const zeroLine = minValue < 0 && maxValue > 0 && (
    <line
      className={styles.line}
      x1={getXForValue(0)}
      y1={y + (height - zeroLineHeight) / 2}
      x2={getXForValue(0)}
      y2={y + (height - zeroLineHeight) / 2 + zeroLineHeight}
    />
  )

  return (
    <g className={styles.range}>
      <line
        className={styles.line}
        x1={getXForValue(minValue)}
        y1={y}
        x2={getXForValue(minValue)}
        y2={y + height}
      />
      <line
        className={styles.line}
        x1={getXForValue(maxValue)}
        y1={y}
        x2={getXForValue(maxValue)}
        y2={y + height}
      />
      <line
        className={styles.line}
        x1={x}
        y1={midLineY}
        x2={x + width}
        y2={midLineY}
      />
      {zeroLine}
      <circle
        className={classNames(styles.valueDot, valenceClassName)}
        cx={valueX}
        cy={midLineY}
        r={4}
      />
    </g>
  )
}
Range.propTypes = {
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
}

function ScoreLabel({score, x, y}) {
  return (
    <text className={styles.scoreLabel} x={x} y={y} textAnchor="middle">
      {strings.formatHealthScore(score)}
    </text>
  )
}
ScoreLabel.propTypes = {
  score: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
}
