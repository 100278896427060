import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Button from 'app/common/Button'
import LinkedInIcon from 'app/social/icons/LinkedInIcon'

import * as styles from './LinkedInShareButton.less'

export default function LinkedInShareButton({
  url,
  className,
  isDisabled = false,
}) {
  const encodedUrl = encodeURIComponent(url)
  const shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}`
  return (
    <a
      href={shareUrl}
      target="_blank"
      className={classNames(styles.linkedinShareButton, className)}
    >
      <Button
        label={<LinkedInShareButtonLabel />}
        isDisabled={isDisabled}
        className={classNames(styles.button, {[styles.disabled]: isDisabled})}
      />
    </a>
  )
}
LinkedInShareButton.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
}

const LinkedInShareButtonLabel = () => (
  <span className={styles.contents}>
    <LinkedInIcon className={styles.icon} />
    LinkedIn
  </span>
)
