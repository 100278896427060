import {createAction} from 'redux-actions'

export const setToken = createAction('SET_TOKEN')
export const clearToken = createAction('CLEAR_TOKEN')
export const setProfileData = createAction('SET_PROFILE_DATA')
export const logOut = createAction('LOG_OUT')
export const fetchMyProfile = createAction('FETCH_MY_PROFILE')
export const updateMyProfile = createAction('UPDATE_MY_PROFILE')
export const saveFilterDefaults = createAction('SAVE_FILTER_DEFAULTS')
export const updateflaggedArticleCount = createAction('UPDATE_FLAGGED_ARTICLE_COUNT')
