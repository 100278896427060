const EXCERPT_WORD_COUNT = 40

export const getExcerpt = text => {
  const words = text.split(/\s+/)
  const excerptWords = words.slice(0, EXCERPT_WORD_COUNT)
  let excerpt = excerptWords.join(' ')
  if (words.length > EXCERPT_WORD_COUNT) {
    excerpt += ' ...'
  }
  return excerpt
}
