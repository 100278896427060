import classNames from 'classnames'
import is from 'is'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import {range, sort} from 'ramda'
import React from 'react'

import HealthBar from 'app/common/HealthBar'
import HealthScore from 'app/common/HealthScore'
import Link from 'app/common/NavigationLink'
import MyCompanyStar from 'app/reusable/companies/my-company-star'
import {STORY_SORT_OPTIONS} from 'app/reusable/stories/story-reader/story-reader-constants'
import * as strings from 'app/strings'

import * as styles from './CompaniesSidebar.less'

const articleCount = company =>
  (company.healthData || {articleVolume: 0}).articleVolume

const CompanyItemLoading = () => (
  <div className={classNames(styles.company, styles.loading)}>
    <div className={styles.contents}>
      <div className={styles.star}>
        <div className={styles.myCompanyStarRect} />
      </div>

      <div className={styles.label}>
        <div className={styles.companyNameRect} />
      </div>

      <div className={styles.health}>
        <div className={styles.healthScoreRect} />
        <div className={classNames(styles.bar, styles.healthBarRect)} />
      </div>
    </div>
  </div>
)

class Header extends React.PureComponent {
  static defaultProps = {
    onShowOnlyMyCompaniesChange() {},
  }

  render() {
    const {shouldShowOnlyMyCompanies} = this.props
    return (
      <div className={styles.header}>
        <span className={styles.title}>Companies</span>

        <label className={styles.onlyMyCompanies}>
          <input
            type="checkbox"
            checked={shouldShowOnlyMyCompanies}
            onChange={bind(this.onChange, this)}
            className={styles.checkbox}
          />
          <span className={styles.label}>Show Only My Companies</span>
        </label>
      </div>
    )
  }

  onChange(event) {
    this.props.onShowOnlyMyCompaniesChange(event.target.checked)
  }
}
Header.propTypes = {
  shouldShowOnlyMyCompanies: PropTypes.bool.isRequired,
  onShowOnlyMyCompaniesChange: PropTypes.func,
}

class CompanyItem extends React.PureComponent {
  static propTypes = {
    company: PropTypes.object.isRequired,
    isSelected: PropTypes.bool.isRequired,
    selectedFactor: PropTypes.string,
    selectedSubfactor: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    onDeselect: PropTypes.func.isRequired,
  }

  render() {
    const {company, isSelected, selectedFactor, selectedSubfactor} = this.props

    let specificHealthScore = null
    if (selectedSubfactor) {
      const healthData = company.healthData.subfactors[selectedSubfactor] || {}
      const score = is.number(healthData.healthScore) ? (
        <HealthScore score={healthData.healthScore} />
      ) : (
        'No'
      )
      specificHealthScore = (
        <div className={styles.specificScore}>
          {score} {strings.subfactorDisplayName(selectedSubfactor)} Score
        </div>
      )
    } else if (selectedFactor) {
      const healthData = company.healthData.factors[selectedFactor] || {}
      const score = is.number(healthData.healthScore) ? (
        <HealthScore score={healthData.healthScore} />
      ) : (
        'No'
      )
      specificHealthScore = (
        <div className={styles.specificScore}>
          {score} {strings.factorDisplayName(selectedFactor)} Score
        </div>
      )
    }

    return (
      <div
        className={classNames(styles.company, {[styles.selected]: isSelected})}
        onClick={bind(this.onClick, this, company.id)}
      >
        <div className={styles.contents}>
          <div className={styles.star}>
            <MyCompanyStar company={company} />
          </div>

          <div className={styles.label}>
            {company.name}
            {specificHealthScore}
          </div>

          <div className={styles.health}>
            <span className={styles.score}>
              {is.number(company.healthData.healthScore) ? (
                <HealthScore score={company.healthData.healthScore} />
              ) : (
                'No'
              )}{' '}
              Health Score
            </span>
            {is.number(company.healthData.healthScore) && (
              <HealthBar
                healthData={company.healthData}
                className={styles.bar}
              />
            )}
          </div>
        </div>
      </div>
    )
  }

  onClick(companyId) {
    if (this.props.isSelected) {
      this.props.onDeselect()
    } else {
      this.props.onSelect(companyId)
    }
  }
}

const CompaniesSidebar = ({
  companies,
  myCompanyIds,
  selectedCompanyId,
  totalCompanyCount,
  moreCompaniesUrl,
  sortOrder,
  selectedFactor,
  selectedSubfactor,
  shouldShowOnlyMyCompanies,
  onShowOnlyMyCompaniesChange,
  onSelectCompany,
  onClearSelectedCompany,
  className,
}) => {
  let moreCompaniesCount = 0
  let contents

  if (companies) {
    const compareCompanies =
      sortOrder === STORY_SORT_OPTIONS.VOLUME
        ? (company1, company2) =>
            articleCount(company2) - articleCount(company1)
        : (company1, company2) =>
            company2.lastStoryDate - company1.lastStoryDate
    const sortCompanies = sort((company1, company2) => {
      const company1IsMyCompany = myCompanyIds.includes(company1.id)
      const company2IsMyCompany = myCompanyIds.includes(company2.id)
      if (company1IsMyCompany && !company2IsMyCompany) return -1
      if (!company1IsMyCompany && company2IsMyCompany) return 1
      return compareCompanies(company1, company2)
    })

    let relevantCompanies = companies.filter(company => company.healthData)
    if (shouldShowOnlyMyCompanies) {
      relevantCompanies = relevantCompanies.filter(({id}) =>
        myCompanyIds.includes(id),
      )
    }

    if (totalCompanyCount && totalCompanyCount > relevantCompanies.length) {
      moreCompaniesCount = totalCompanyCount - relevantCompanies.length
    }

    const companiesSorted = sortCompanies(relevantCompanies)

    contents = companiesSorted.map(company => (
      <CompanyItem
        company={company}
        selectedFactor={selectedFactor}
        selectedSubfactor={selectedSubfactor}
        isSelected={company.id === selectedCompanyId}
        onSelect={onSelectCompany}
        onDeselect={onClearSelectedCompany}
        key={company.id}
      />
    ))
  } else {
    contents = range(0, 10).map(i => <CompanyItemLoading key={i} />)
  }

  return (
    <div className={classNames(styles.companiesSidebar, className)}>
      <Header
        shouldShowOnlyMyCompanies={shouldShowOnlyMyCompanies}
        onShowOnlyMyCompaniesChange={onShowOnlyMyCompaniesChange}
      />

      <div
        className={classNames(styles.companies, {
          [styles.hasSelection]: selectedCompanyId,
        })}
      >
        {contents}

        {!!moreCompaniesCount && (
          <Link href={moreCompaniesUrl} className={styles.moreCompanies}>
            ...and {moreCompaniesCount} more{' '}
            {moreCompaniesCount > 1 ? 'companies' : 'company'}
          </Link>
        )}
      </div>
    </div>
  )
}
CompaniesSidebar.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object),
  myCompanyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedCompanyId: PropTypes.number,
  totalCompanyCount: PropTypes.number,
  moreCompaniesUrl: PropTypes.string,
  sortOrder: PropTypes.string.isRequired,
  selectedFactor: PropTypes.string,
  selectedSubfactor: PropTypes.string,
  shouldShowOnlyMyCompanies: PropTypes.bool.isRequired,
  onShowOnlyMyCompaniesChange: PropTypes.func.isRequired,
  onSelectCompany: PropTypes.func.isRequired,
  onClearSelectedCompany: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default CompaniesSidebar
