import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import Button from 'app/common/Button'
import {LoadingMessage} from 'app/common/loading-message'
import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import {Company, Story} from 'app/models'
import MyCompanyStar from 'app/reusable/companies/my-company-star'
import urls from 'app/urls'

import OverviewPage from '../../common/OverviewPage'
import * as actions from '../company-overview-actions'
import {getCompanyOverview} from '../company-overview-selectors'

import * as styles from './CompanyOverviewPortal.less'

const CompanyOverviewPortal = connect(
  createSelector(
    [getEntities, getCompanyOverview],
    (entities, overviewState) => {
      const orm = Orm.withEntities(entities)
      const company =
        overviewState.companyId && orm.getById(Company, overviewState.companyId)
      const {
        healthData,
        dailyHealthData,
        factorDailyHealthData,
        subfactorDailyHealthData,
      } = company || {}

      const selectedFactor =
        overviewState.selectedFactor === 'all'
          ? null
          : overviewState.selectedFactor
      const selectedSubfactor =
        overviewState.selectedSubfactor === 'all'
          ? null
          : overviewState.selectedSubfactor

      return {
        overviewType: 'Company',

        healthData,
        dailyHealthData,
        factorDailyHealthData,
        subfactorDailyHealthData,
        isManzamaLogoVisible: true,

        selectedFactor,
        selectedSubfactor,
        timeFrameDays: overviewState.timeFrameDays || 30,
        selectedGeography: overviewState.selectedGeography || 0,

        stories:
          overviewState.storyReader.storyIds &&
          orm.getByIds(Story, overviewState.storyReader.storyIds),
        selectedValences: overviewState.storyReader.filters.valences,
        storySortOrder: overviewState.storyReader.filters.sortOrder,

        shouldShowCompaniesSidebar: false,

        isPortalPage: true,
      }
    },
  ),
  {
    selectValence: actions.selectValence,
    deselectValence: actions.deselectValence,
    changeStorySortOrder: actions.setStorySortOrder,
    changeShowOnlyMyCompanies: actions.setShowOnlyMyCompanies,
    selectCompany: actions.selectCompany,
  },
)(OverviewPage)
CompanyOverviewPortal.displayName = 'CompanyOverviewPortal'

class CompanyOverviewPortalPage extends React.PureComponent {
  static propTypes = {
    industry: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
  }

  render() {
    const {company, isLoading} = this.props
    const contents = isLoading ? <LoadingMessage /> : <CompanyOverviewPortal />

    return (
      <div className={styles.companyOverview}>
        {company && (
          <div className={styles.header}>
            <MyCompanyStar company={company} className={styles.star} />

            <h1 className={styles.title}>{company.name} Company Overview</h1>

            {company.healthData && (
              <a
                href={urls.companyReport(company.id)}
                target="_blank"
                className="company-report"
              >
                <Button label="Generate Company Report" isPrimary={true} />
              </a>
            )}
          </div>
        )}
        {contents}
      </div>
    )
  }
}

export default connect(
  createSelector(
    [getEntities, getCompanyOverview],
    (entities, overviewState) => {
      const orm = Orm.withEntities(entities)
      const {companyId, isLoading} = overviewState
      const company = companyId && orm.getById(Company, companyId)
      return {
        company,
        isLoading,
      }
    },
  ),
)(CompanyOverviewPortalPage)
