import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import Industry from 'app/models/Industry'
import {getMyCompanyIds} from 'app/global/global-selectors'
import CompaniesDisplay from 'app/reusable/companies/companies-display'

import * as overviewActions from '../../common/overview-actions'
import {getIndustryOverview} from '../industry-overview-selectors'

const IndustryCompanies = connect(
  createSelector(
    [getEntities, getIndustryOverview, getMyCompanyIds],
    (entities, industryState, myCompanyIds) => {
      const orm = Orm.withEntities(entities)
      const {industryId} = industryState
      const industry = industryId && orm.getById(Industry, industryId)
      return {
        companies: industry.companies.filter(
          company => company.entityType !== 'INDUSTRY',
        ),
        myCompanyIds,
        geography: industryState.selectedGeography,
      }
    },
  ),
  {onGeographyChange: overviewActions.selectGeography},
)(CompaniesDisplay)
IndustryCompanies.displayName = 'IndustryCompanies'
export default IndustryCompanies
