import { createAction } from 'redux-actions'

const action = name => createAction(`litigations/${name}`)

export const setIsLoading = action('SET_IS_LOADING')
export const setSectors = action('SET_SECTORS')
export const setSelectedSectors = action('SET_SELECTED_SECTORS')
export const setCompanyCountMinimum = action('SET_COMPANY_COUNT_MINIMUM')
export const setCompanyCountMaximum = action('SET_COMPANY_COUNT_MAXIMUM')
export const setLitigationAffectedCompaniesMinimum = action('SET_LITIGATION_AFFECTED_COMPANIES_MINIMUM')
export const setLitigationAffectedCompaniesMaximum = action('SET_LITIGATION_AFFECTED_COMPANIES_MAXIMUM')
export const setSelectedSectorId = action('SET_SELECTED_SECTOR_ID')
export const setCompanyTimeSeries = action('SET_COMPANY_TIME_SERIES')
export const setCompanyFilters = action('SET_COMPANY_FILTERS')

// Used for the load function, so it doesn't trigger a fetch before setting the selected sector.
export const setCompanyFiltersWithoutFetching = action('SET_COMPANY_FILTERS_WITHOUT_RELOAD')
export const setMainPageLoading = action('SET_MAIN_PAGE_LOADING')
export const setSectorLoading = action('SET_SECTOR_LOADING')
export const setStories = action('SET_STORIES')
export const setStoryFetchError = action('SET_STORY_FETCH_ERROR')
export const setStoryCount = action('SET_STORY_COUNT')
export const fetchMoreStories = action('FETCH_MORE_STORIES')
export const fetchMoreStoriesSuccessful = action('FETCH_MORE_STORIES_SUCCESSFUL')

export const fetchLitigationSavedSearches = action('FETCH_LITIGATION_SAVED_SEARCHES')
export const fetchLitigationSavedSearchesSuccessful = action('FETCH_LITIGATION_SAVED_SEARCHES_SUCCESSFUL')
export const fetchMoreLitigationSavedSearches = action('FETCH_MORE_LITIGATION_SAVED_SEARCHES')
export const fetchMoreLitigationSavedSearchesSuccessful = action('FETCH_MORE_LITIGATION_SAVED_SEARCHES_SUCCESSFUL')
export const fetchLitigationSavedSearchesError = action('FETCH_LITIGATION_SAVED_SEARCHES_ERROR')
export const setLitigationSavedSearchOrderBy = action('SET_LITIGATION_SAVED_SEARCH_ORDER_BY')

export const resetRemoveLitigationSavedSearch = action('RESET_REMOVE_LITIGATION_SAVED_SEARCH')
export const removeLitigationSavedSearch = action('REMOVE_LITIGATION_SAVED_SEARCH')
export const removeLitigationSavedSearchSuccessful = action('REMOVE_LITIGATION_SAVED_SEARCH_SUCCESSFUL')
export const removeLitigationSavedSearchError = action('REMOVE_LITIGATION_SAVED_SEARCH_ERROR')

export const selectLitigationSavedSearch = action('SELECT_LITIGATION_SAVED_SEARCH')

export const saveLitigationSearch = action('SAVE_LITIGATION_SEARCH')
export const saveLitigationSearchSuccessful = action('SAVE_LITIGATION_SEARCH_SUCCESSFUL')
export const saveLitigationSearchError = action('SAVE_LITIGATION_SEARCH_ERROR')
export const resetSaveLitigationSearch = action('RESET_SAVE_LITIGATION_SEARCH')

export const openSectorListReport = action('OPEN_SECTOR_LIST_REPORT')
export const startDownloadPdf = action('START_DOWNLOAD_PDF')
export const finishDownloadPdf = action('FINISH_DOWNLOAD_PDF')

export const setSelectedFilter = action('SET_SHOW_ALL_SECTORS')
export const setAllSectors = action('SET_ALL_SECTORS')
export const setMySectors = action('SET_MY_SECTORS')
export const setIsOnMainPage = action('SET_IS_ON_MAIN_PAGE')
export const setSelectedCompanies = action('SET_SELECTED_COMPANIES')
export const fetchIndustriesAndCompanies = action('FETCH_INDUSTRIES_AND_COMPANIES')
export const fetchCompaniesForIndustries = action('FETCH_COMPANIES_FOR_INDUSTRIES')

export const setMyCompanies = action('SET_MY_COMPANIES')
export const setSelectedMyCompanyIds = action('SET_SELECTED_MY_COMPANY_IDS')
