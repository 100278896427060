import {LOCATION_CHANGED} from 'redux-little-router'
import {all, put, takeLatest} from 'redux-saga/effects'

import {TIME_FRAME_HEADER} from 'app/overviews/company/company-overview-constants'
import {restApiRequest} from 'app/utils/sagas'
import CompanyResource from 'app/resources/Company'
import IndustryResource from 'app/resources/Industry'
import MyAlert from 'app/resources/MyAlert'
import MyIndustryAlert from 'app/resources/MyIndustryAlert'
import routes from 'app/routes'

import * as actions from './email-server-actions'

function* locationChanged(action) {
  yield put(actions.setIsLoading(true))

  const headers = {
    [TIME_FRAME_HEADER]: action.payload.route === routes.esgEmailTestDaily ? 1 : 7,
  }
  const additionalFields = [
    'healthBadge',
    'topStories',
    'overallDailyHealthDataPoints',
  ]
  const myCompaniesRequest = yield* restApiRequest(MyAlert, {
    headers,
    fields: [...CompanyResource.requiredFields, ...additionalFields],
  })
  const myIndustriesRequest = yield* restApiRequest(MyIndustryAlert, {
    headers,
    fields: [...IndustryResource.requiredFields, ...additionalFields],
  })
  const responses = yield all([myCompaniesRequest, myIndustriesRequest])
  const [companies, industries] = responses.map(resp => resp.body)

  yield put(actions.setData({companies, industries}))
  yield put(actions.setIsLoading(false))
}

const isEmailServerPageLocationChangedAction = action =>
  action.type === LOCATION_CHANGED &&
  [routes.emailTestDaily, routes.emailTestWeekly, routes.esgEmailTestDaily, routes.esgEmailTestWeekly]
    .includes(action.payload.route)

export default function* industriesSaga() {
  yield takeLatest(isEmailServerPageLocationChangedAction, locationChanged)
}
