import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import ArticleLink from 'app/common/ArticleLink'
import {LoadingMessage} from 'app/common/loading-message'
import Modal from 'app/common/Modal'
import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import * as login from 'app/login'
import Article from 'app/models/Article'
import * as storyModalActions from 'app/story-modal/story-modal-actions'
import * as strings from 'app/strings'
import {getExcerpt} from 'app/utils/article'
import * as actions from './flag-article-modal-actions'
import * as selectors from './flag-article-modal-selectors'
import * as styles from './FlagArticleModal.less'
import bind from 'memoize-bind'
import {groupBy, flatten, map, pipe, prepend, prop, sortBy} from 'ramda'
import newFolderIconUrl from 'static/images/ico_new_folder.png'
import TextBox from 'app/common/TextBox'
import Button from 'app/common/Button'
import LoadingSpinner from 'app/common/loading-spinner'

class AddFlagCategoryForm extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    addCategoryData: PropTypes.object.isRequired,
    isAddingFlagCategory: PropTypes.bool.isRequired,
    setIsAddingFlagCategory: PropTypes.func.isRequired,
  }
  state = {
    nameValue: '',
  }

  render() {
    const {nameValue} = this.state
    const {isAddingFlagCategory} = this.props
    return (
      <form onSubmit={this.onSubmit} className={styles.addNew}>
        <label>
          <div className={styles.label}>Enter folder name:</div>
          <TextBox
            autoFocus={true}
            onChange={this.onTextChange}
            className={styles.input}
          />
        </label>
        <div className={styles.buttons}>
          <Button
            label={isAddingFlagCategory ? 'Adding...' : 'Add'}
            disabled={!nameValue || isAddingFlagCategory}
            isPrimary={true}
          />
          <Button label="Cancel" isPlainText={true} onClick={this.onCancel} />
        </div>
      </form>
    )
  }

  onTextChange = event => {
    this.setState({nameValue: event.target.value})
  }

  onSubmit = async event => {
    event.preventDefault()
    const {nameValue} = this.state
    if (!nameValue) return
    this.props.setIsAddingFlagCategory(true)
    this.props.onSubmit({
      name: nameValue,
      articleId: this.props.addCategoryData.contentDirectorId,
      feedId: this.props.addCategoryData.feedId,
      categoryId: this.props.addCategoryData.addCategoryForId,
    })
  }

  onCancel = event => {
    event.preventDefault()
    this.props.onCancel()
    this.props.setIsAddingFlagCategory(false)
  }
}

class FlagCategory extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    isUncategorized: PropTypes.bool.isRequired,
    isRoot: PropTypes.bool.isRequired,
    isChecked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onAddChild: PropTypes.func.isRequired,
    isLoadingBool: PropTypes.bool.isRequired,
  }

  render() {
    const {name, isUncategorized, isRoot, isChecked, isLoadingBool} = this.props
    return (
      <label className={classNames(styles.category, {[styles.child]: !isRoot})}>
        <div className={styles.checkboxLabelSection}>
          {isLoadingBool ? (
            <LoadingSpinner className={styles.loading} />
          ) : (
            <input
              type="checkbox"
              checked={isChecked}
              onChange={this.handleChange}
              className={styles.checkbox}
            />
          )}
          <span>{name}</span>
        </div>
        {!isUncategorized && isRoot && (
          <img
            src={newFolderIconUrl}
            alt="New Subfolder"
            onClick={this.handleAddSubfolderClick}
            className={styles.addSubfolder}
          />
        )}
      </label>
    )
  }

  handleChange = event => {
    this.props.onChange(event.target.checked)
  }

  handleAddSubfolderClick = event => {
    event.preventDefault()
    this.props.onAddChild()
  }
}

function FlagArticleModalContents({
  article,
  shouldShowBack,
  hideModal,
  showStoryModal,
  flaggingData,
  changeArticleFlag,
  addFlagCategory,
  setAddFlagCategoryForId,
  addCategoryData,
  hideAddFlagCategoryForm,
  setIsAddingFlagCategory,
  isLoadingArray,
  setIsLoading,
  isAddingFlagCategory,
}) {
  const goBack = () => {
    hideModal()
    showStoryModal({storyId: article.storyId})
  }
  const truncateHeadline =
    article.headline.length > 53
      ? `${article.headline.substring(0, 50)}...`
      : article.headline
  const truncateDesc =
    article.description.length > 130
      ? `${article.description.substring(0, 133)}...`
      : article.description

  const sortByLowercaseName = sortBy(obj => obj.name.toLowerCase())
  const flagCategories = flaggingData.flagCategories

  let flattenedFlagCategories = pipe(
    sortByLowercaseName,
    map(flagCategory => [
      flagCategory,
      ...sortBy(prop('name'))(flagCategory.children),
    ]),
    flatten,
  )(flagCategories)

  const flaggedDocumentIdsByCategoryId = pipe(
    groupBy(flagging => (flagging.category ? flagging.category : null)),
    map(flaggings => flaggings.map(flagging => flagging.director)),
  )(flaggingData.flaggings)

  // Prepend a fake category to represent uncategorized flaggings.
  flattenedFlagCategories = prepend(
    {id: 'uncat', name: 'Uncategorized'},
    flattenedFlagCategories,
  )

  const isFlagCategoryChecked = flagCategoryId =>
    flagCategoryId === 'uncat' &&
    flaggedDocumentIdsByCategoryId[null] !== undefined
      ? flaggedDocumentIdsByCategoryId[null].includes(article.contentDirectorId)
      : flaggedDocumentIdsByCategoryId[flagCategoryId]
      ? flaggedDocumentIdsByCategoryId[flagCategoryId].includes(
          article.contentDirectorId,
        )
      : false

  const showAddFlagCategoryForm = parent => {
    setAddFlagCategoryForId({
      categoryId: parent,
      contentDirectorId: article.contentDirectorId,
      feedId: article.feedId,
    })
  }

  const onFlaggingChange = (flagCategoryId, isChecked) => {
    const categoryId = flagCategoryId === 'uncat' ? null : flagCategoryId
    if (isLoadingArray && isLoadingArray.length > 0) {
      setIsLoading(isLoadingArray.concat(categoryId))
    } else {
      setIsLoading([categoryId])
    }
    changeArticleFlag({
      addFlag: isChecked,
      categoryId: categoryId,
      articleId: article.contentDirectorId,
      feedId: article.feedId,
    })
  }
  const isFlagCategoryLoading = flagCategoryId =>
    isLoadingArray
      ? isLoadingArray.includes(flagCategoryId) ||
        isLoadingArray.includes('uncat')
      : false

  const flagCategoriesUi = pipe(
    map(flagCategory => {
      const elements = [
        <FlagCategory
          name={flagCategory.name}
          isUncategorized={flagCategory.id === 'uncat'}
          isRoot={!flagCategory.parent}
          isChecked={isFlagCategoryChecked(flagCategory.id)}
          onChange={bind(onFlaggingChange, this, flagCategory.id)}
          onAddChild={bind(showAddFlagCategoryForm, this, flagCategory.id)}
          isLoadingBool={isFlagCategoryLoading(flagCategory.id)}
          key={`flag-category-${flagCategory.id}`}
        />,
      ]
      if (
        addCategoryData.addCategoryForId !== null &&
        addCategoryData.addCategoryForId === flagCategory.id
      ) {
        elements.push(
          <AddFlagCategoryForm
            onSubmit={bind(addFlagCategory, this)}
            onCancel={bind(hideAddFlagCategoryForm, this)}
            setIsAddingFlagCategory={bind(setIsAddingFlagCategory, this)}
            addCategoryData={addCategoryData}
            isAddingFlagCategory={isAddingFlagCategory}
            key={`add-${flagCategory.id}`}
          />,
        )
      }
      return elements
    }),
  )(flattenedFlagCategories)

  return (
    <div className={styles.storyContent}>
      <div className={styles.header}>
        <div className={styles.backToStory}>
          {shouldShowBack && (
            <a onClick={() => goBack()}>&lt;&lt; Back to all articles</a>
          )}
        </div>
        <div className={styles.label}>Flag Article</div>
      </div>
      <div className={styles.body}>
        <h2 className={styles.headline}>
          <ArticleLink article={article} shouldTargetNewWindow={true}>
            {truncateHeadline}
          </ArticleLink>
        </h2>
        <div className={styles.excerpt}>{getExcerpt(truncateDesc)}</div>
        <div className={styles.meta}>
          <span className={styles.date}>
            {strings.formatDate(article.date)}{' '}
          </span>
          <span className={styles.source}>{strings.viaSource(article)}</span>
        </div>
      </div>
      <div className={styles.footer}>
        {flagCategoriesUi}
        {addCategoryData.addCategoryForId === 0 ? (
          <AddFlagCategoryForm
            onSubmit={bind(addFlagCategory, this)}
            onCancel={bind(hideAddFlagCategoryForm, this)}
            setIsAddingFlagCategory={bind(setIsAddingFlagCategory, this)}
            addCategoryData={addCategoryData}
            isAddingFlagCategory={isAddingFlagCategory}
          />
        ) : (
          <a
            onClick={bind(showAddFlagCategoryForm, this, 0)}
            className={styles.addNewButton}
          >
            + Create New Folder
          </a>
        )}
      </div>
    </div>
  )
}
FlagArticleModalContents.propTypes = {
  article: PropTypes.object.isRequired,
  shouldShowBack: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  flaggingData: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  showStoryModal: PropTypes.func.isRequired,
  changeArticleFlag: PropTypes.func.isRequired,
  addFlagCategory: PropTypes.func.isRequired,
  setAddFlagCategoryForId: PropTypes.func.isRequired,
  addCategoryData: PropTypes.object.isRequired,
  hideAddFlagCategoryForm: PropTypes.func.isRequired,
  isLoadingArray: PropTypes.array.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setIsAddingFlagCategory: PropTypes.func.isRequired,
  isAddingFlagCategory: PropTypes.bool.isRequired,
}

function FlagArticleModal({
  isVisible,
  article,
  shouldShowBack = false,
  flaggingData,
  userId,
  addCategoryData,
  isLoadingArray,
  isAddingFlagCategory,

  // Actions
  hideModal,
  showStoryModal,
  changeArticleFlag,
  addFlagCategory,
  setAddFlagCategoryForId,
  hideAddFlagCategoryForm,
  setIsAddingFlagCategory,
  setIsLoading,
}) {
  if (!isVisible) return null

  if (!article) {
    return <LoadingMessage />
  }

  return (
    <Modal
      className={classNames(styles.modal, 'flag-article-modal')}
      contentClassName={styles.content}
      onClose={hideModal}
    >
      <FlagArticleModalContents
        article={article}
        shouldShowBack={shouldShowBack}
        userId={userId}
        hideModal={hideModal}
        showStoryModal={showStoryModal}
        flaggingData={flaggingData}
        changeArticleFlag={changeArticleFlag}
        addFlagCategory={addFlagCategory}
        setAddFlagCategoryForId={setAddFlagCategoryForId}
        addCategoryData={addCategoryData}
        hideAddFlagCategoryForm={hideAddFlagCategoryForm}
        setIsAddingFlagCategory={setIsAddingFlagCategory}
        setIsLoading={setIsLoading}
        isLoadingArray={isLoadingArray}
        isAddingFlagCategory={isAddingFlagCategory}
      />
    </Modal>
  )
}
FlagArticleModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  article: PropTypes.object,
  shouldShowBack: PropTypes.bool,
  userId: PropTypes.number.isRequired,
  flaggingData: PropTypes.object.isRequired,
  addCategoryData: PropTypes.object.isRequired,
  isLoadingArray: PropTypes.array.isRequired,
  isAddingFlagCategory: PropTypes.bool.isRequired,

  // Actions
  hideModal: PropTypes.func.isRequired,
  showStoryModal: PropTypes.func.isRequired,
  changeArticleFlag: PropTypes.func.isRequired,
  addFlagCategory: PropTypes.func.isRequired,
  setAddFlagCategoryForId: PropTypes.func.isRequired,
  hideAddFlagCategoryForm: PropTypes.func.isRequired,
  setIsAddingFlagCategory: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
}
export default connect(
  createSelector(
    [getEntities, selectors.getState, login.selectors.getUserId],
    (entities, modalState, userId) => {
      const {
        isVisible,
        articleId,
        shouldShowBack,
        flaggingData,
        addCategoryData,
        isLoadingArray,
        isAddingFlagCategory,
      } = modalState
      const orm = Orm.withEntities(entities)
      return {
        isVisible,
        article: articleId && orm.getById(Article, articleId),
        shouldShowBack,
        userId,
        flaggingData,
        addCategoryData,
        isLoadingArray,
        isAddingFlagCategory,
      }
    },
  ),
  {
    hideModal: actions.hideModal,
    showStoryModal: storyModalActions.showModal,
    changeArticleFlag: actions.changeArticleFlag,
    addFlagCategory: actions.addFlagCategory,
    setAddFlagCategoryForId: actions.setAddFlagCategoryForId,
    hideAddFlagCategoryForm: actions.hideAddFlagCategoryForm,
    setIsLoading: actions.setIsLoading,
    setIsAddingFlagCategory: actions.setIsAddingFlagCategory,
  },
)(FlagArticleModal)
