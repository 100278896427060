import React from 'react'
import * as styles from './styles.less'
import classNames from 'classnames'

const GraphLoading = ({ isLoading, children }) => {

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <i className={classNames('fa fa-bar-chart fa-6', styles.chartIcon)} aria-hidden='true'/>
      </div>
    )
  } else {
    return (
      <>
        {children}
      </>
    )
  }
}

export default GraphLoading
