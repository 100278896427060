import { prop } from 'ramda'
import { LOCATION_CHANGED } from 'redux-little-router'
import { all, call, cancelled, delay, put, select, takeLatest, fork } from 'redux-saga/effects'

import urls from 'app/urls'
import routes from 'app/routes'
import Orm from 'app/framework/Orm'
import { navigate } from 'app/global/global-actions'
import { LitigationNumberSummary, LitigationSavedSearch } from 'app/models'
import { SORT_DIRECTIONS, SUBFACTORS_BY_ID } from 'app/constants'
import { getJwt } from 'app/login/login-selectors'
import { pyappRequest } from 'app/api'
import { downloadPdf } from 'app/overviews/common/overview-saga'
import * as api from 'app/api/api-saga-helpers'
import * as entitiesSelectors from 'app/framework/entities-selectors'

import * as actions from './litigations-actions'
import * as selectors from './litigations-selectors'
import { DEFAULT_LIMIT, FILTER_CHECKBOXES } from './litigations-constants'
import { LocalReportDataHandler } from 'app/litigation/utils'
import { fetchMyCompanies, fetchMyIndustries } from '../api/api-saga-helpers'
import CompanyResource from 'app/resources/Company'

function* fetchSectorsIfNeeded() {
  yield fetchIndustriesAndCompanies()
}

function* locationChanged() {
  yield fetchSectorsIfNeeded()
}

function* litigationLoadLocationChanged() {
  yield put(actions.fetchLitigationSavedSearches())
  yield fetchSectorsIfNeeded() // Needed for the 90 days trend column
}

function* reportLocationChanged() {
  yield locationChanged()


  const router = yield select(prop('router'))
  const queryParams = router.params
  const querySaveId = queryParams && queryParams.saveId

  if (querySaveId) {
    const search = LocalReportDataHandler.loadFromLocalStorage(querySaveId)
    if (search) {
      const sectorIds = search.mainPage.selectedSectors
      const sectorPage = search.sector
      const mainPageFilters = search.mainPage
      const isOnMainPage = search.isOnMainPage
      
      // Main Page Filters
      yield all([
        put(actions.setIsOnMainPage(isOnMainPage)),
        put(actions.setSectors(mainPageFilters.sectors)),
        put(actions.setSelectedSectors(mainPageFilters.selectedSectors)),
        put(actions.setCompanyCountMaximum(mainPageFilters.companyCountMaximum)),
        put(actions.setCompanyCountMinimum(mainPageFilters.companyCountMinimum)),
        put(actions.setLitigationAffectedCompaniesMaximum(mainPageFilters.litigationAffectedCompaniesMaximum)),
        put(actions.setLitigationAffectedCompaniesMinimum(mainPageFilters.litigationAffectedCompaniesMinimum)),
        put(actions.setSelectedFilter(mainPageFilters.selectedFilter)),
        put(actions.setSelectedMyCompanyIds(mainPageFilters.selectedCompanyIds)),
        put(actions.setSelectedCompanies(sectorPage.selectedCompanyTimeSeries)),
        put(actions.setCompanyFilters(sectorPage.filters))  
      ])
    }
  }
}

function* fetchTimeSeries() {
  const sectorIds = yield select(selectors.getSelectedSectors)
  if (sectorIds && sectorIds.length > 0) {
    const filters = yield select(selectors.getCompanyFilters)
    const entities = yield select(entitiesSelectors.getEntities)
    const orm = Orm.withEntities(entities)
    const sectors = orm.getById(LitigationNumberSummary, sectorIds)
    let valences = []
    filters.valencePositive ? valences.push('POS') : null
    filters.valenceNegative ? valences.push('NEG') : null
    filters.valenceNeutral ? valences.push('NEU') : null
    valences = valences.length > 0 ? valences : null
    const response = yield yield* api.fetchLitigationTimeSeries(
      {
        industries: sectorIds,
        storyCountMin: filters.storyCountMin,
        storyCountMax: filters.storyCountMax,
        valences,
        aspect: filters.aspect,
        subfactors: filters.factors.length > 0 ? filters.factors.map(factor => factor.label) : null,
      }
    )
    yield put(actions.setCompanyTimeSeries(response.result))
    yield put(actions.setSectorLoading(false))
  }
}

function* handleCompaniesSelected() {
  const sectorPage = yield select(selectors.getSectorPage)
  const companies = sectorPage.selectedCompanyTimeSeries
  if (companies && companies.length > 0) {
    try {
      const storyIds = yield fetchLitigationStories()
      if (storyIds.length > 0) {
        yield fetchSectorStories(storyIds)
      }
      else {
        yield put(actions.setStories({ data: [] }))
        yield put(actions.setStoryFetchError(false))
  }
    } catch (e) {
      if (yield cancelled()) {
        return
      }
      yield put(actions.setStoryFetchError(true))
    }
  }
  else {
    yield put(actions.setStories({ data: null }))
    yield put(actions.setStoryFetchError(false))
  }
}

function* handleFetchMoreStories() {
  const sectorPage = yield select(selectors.getSectorPage)
  const offset = sectorPage.stories.offset

  try {
    const storyIds = yield fetchLitigationStories(offset)
    if (storyIds.length > 0) {
      yield fetchMoreSectorStories(storyIds)
    }
  } catch (e) {
    if (yield cancelled()) {
      return
    }
    yield put(actions.setStoryFetchError(true))
  }
}

function* fetchLitigationStories(offset = 0) {
  const sectorPage = yield select(selectors.getSectorPage)
  const mainPage = yield select(selectors.getMainPage)
  const sectorIds = mainPage.selectedSectors
  const companies = sectorPage.selectedCompanyTimeSeries
  if (companies && companies.length > 0) {
    const filters = sectorPage.filters
    const valences = [
      ...filters.valencePositive ? ['POS'] : [],
      ...filters.valenceNegative ? ['NEG'] : [],
      ...filters.valenceNeutral ? ['NEU'] : [],
    ]
    const response = yield yield* api.fetchLitigationStories({
      industries: sectorIds,
      companies: companies,
      valences: valences.length > 0 ? valences : null,
      aspect: filters.aspect,
      factors: null,
      subfactors: filters.factors.length > 0
        ? filters.factors
          .filter(factor => Object.keys(SUBFACTORS_BY_ID).includes(factor.id.toString()))
          .map(factor => factor.label)
        : null,
      offset: offset,
    })

    yield put(actions.setStoryCount(response.headers['content-range'].split('/')[1]))

    return response.result
  }
  else
    return []
}

function* fetchSectorStories(storyIds) {
  try {
    const stories = yield yield* api.fetchStories({
      storyIds: storyIds,
      orderBy: [
        { field: 'storyDate', direction: SORT_DIRECTIONS.DESC, nulls: 'last' },
      ],
    })
    yield put(actions.setStories({ data: stories.result }))
  } catch {
    yield put(actions.setStoryFetchError(true))
  }
}

function* fetchMoreSectorStories(storyIds) {
  const stories = yield yield* api.fetchStories({
    storyIds: storyIds,
    orderBy: [
      { field: 'storyDate', direction: SORT_DIRECTIONS.DESC, nulls: 'last' },
    ],
  })
  yield put(actions.fetchMoreStoriesSuccessful(stories.result))
}

function* handleCompanyFilterChange() {
  yield put(actions.setStories({ data: null }))
  yield fetchTimeSeries()

  try {
    const storyIds = yield fetchLitigationStories()
    if (storyIds.length > 0) {
      yield fetchSectorStories(storyIds)
    }
  } catch (e) {
    if (yield cancelled()) {
      return
    }
    yield put(actions.setStoryFetchError(true))
  }
}

function* fetchLitigationSavedSearches({ query = null, limit = DEFAULT_LIMIT, offset = 0 } = {}) {
  const orderBy = yield select(selectors.getSavedLitigationSearchOrderBy)
  const response = yield yield* api.fetchLitigationSavedSearches({ query, limit, offset, orderBy })
  const count = response.headers['content-range'].split('/')[1]
  return {
    count: Number(count),
    data: response.result,
  }
}

function* handleFetchSavedLitigationSearches({ limit = DEFAULT_LIMIT } = {}) {
  try {
    const response = yield call(fetchLitigationSavedSearches, { limit })
    yield put(actions.fetchLitigationSavedSearchesSuccessful(response))
  } catch (e) {
    yield put(actions.fetchLitigationSavedSearchesError(e))
  }
}

function* handleFetchMoreSavedLitigationSearches() {
  try {
    const offset = yield select(selectors.getSavedLitigationSearchOffset)
    const response = yield call(fetchLitigationSavedSearches, { offset })
    yield put(actions.fetchMoreLitigationSavedSearchesSuccessful(response))
  } catch (e) {
    yield put(actions.fetchLitigationSavedSearchesError(e))
  }
}

export function* removeSavedLitigationSearch(_id) {
  if (MOCK_BASE_API) {
    console.log(`Removing saved search with ID ${_id}`)
    return delay(750)
  }
  const token = yield select(getJwt)
  return pyappRequest({
    url: urls.pyapp.api.removeLitigationSavedSearch(),
    method: 'POST',
    token,
    data: {
      id: _id,
    },
  })
}

function* handleRemoveLitigationSavedSearch(action) {
  try {
    yield yield* removeSavedLitigationSearch(action.payload.id)
    const offset = yield select(selectors.getSavedLitigationSearchOffset)
    yield yield* handleFetchSavedLitigationSearches({ limit: DEFAULT_LIMIT + offset })
    yield put(actions.removeLitigationSavedSearchSuccessful())
  } catch (e) {
    yield put(actions.removeLitigationSavedSearchError())
  }
}

function* handleSelectLitigationSavedSearch(action) {
  try {
    const entities = yield select(entitiesSelectors.getEntities)
    const orm = Orm.withEntities(entities)
    const search = orm.getById(LitigationSavedSearch, action.payload)

    const sectorPage = search.settings.sector
    const mainPage = search.settings.mainPage
    const sectorIds = mainPage.selectedSectors
    const isOnMainPage = search.settings.isOnMainPage
    
    // Main Page Filters
    yield all([
      put(actions.setIsOnMainPage(isOnMainPage)),
      put(actions.setSectors(mainPage.sectors)),
      put(actions.setSelectedSectors(sectorIds)),
      put(actions.setCompanyCountMaximum(mainPage.companyCountMaximum)),
      put(actions.setCompanyCountMinimum(mainPage.companyCountMinimum)),
      put(actions.setLitigationAffectedCompaniesMaximum(mainPage.litigationAffectedCompaniesMaximum)),
      put(actions.setLitigationAffectedCompaniesMinimum(mainPage.litigationAffectedCompaniesMinimum)),
      put(actions.setSelectedFilter(mainPage.selectedFilter)),
      put(actions.setSelectedMyCompanyIds(mainPage.selectedCompanyIds)),
      put(actions.setSelectedCompanies(sectorPage.selectedCompanyTimeSeries)),
      put(actions.setCompanyFilters(sectorPage.filters))
    ])

    if (sectorIds && sectorIds.length > 0) {
      // Sector page filters
      yield put(actions.setCompanyFiltersWithoutFetching(sectorPage.filters))
    }

    yield put(navigate(urls.litigation()))

  } catch (e) {
    // What happens when I fail to load the saved search?
    console.log(e)
  }
}

function* handleSetLitigationSavedSearchSort() {
  yield call(handleFetchSavedLitigationSearches)
}

export function* saveSavedLitigationSearch(name, data) {
  if (MOCK_BASE_API) {
    console.log(`Adding saved search with name ${name}`)
    return delay(750)
  }
  const token = yield select(getJwt)
  return pyappRequest({
    url: urls.pyapp.api.saveLitigationSavedSearch(),
    method: 'POST',
    token,
    data,
  })
}

function* getLitigationData(searchName) {
  const isOnMainPage = yield select(selectors.getIsOnMainPage)
  const mainPage = yield select(selectors.getMainPage)
  const sectorPage = yield select(selectors.getSectorPage)
  const { sectors, selectedSectors: sectorIds, litigationAffectedCompaniesMaximum,
    litigationAffectedCompaniesMinimum, companyCountMaximum, companyCountMinimum,
    selectedFilter, selectedCompanyIds } = mainPage
    const { filters, selectedCompanyTimeSeries } = sectorPage

  let id = null
  if (sectorIds && sectorIds.length > 0) {
    id = Date.now().toString(36) + Math.random().toString(36).substring(2)
  } 

  return {
    id,
    name: searchName,
    isOnMainPage,
    sector: {
      selectedCompanyTimeSeries,
      filters,
    },
    mainPage: {
      litigationAffectedCompaniesMaximum,
      litigationAffectedCompaniesMinimum,
      companyCountMaximum,
      companyCountMinimum,
      sectors,
      selectedSectors: sectorIds,
      selectedFilter,
      selectedCompanyIds,
    }
  }
}

function* handleAddLitigationSavedSearch(action) {
  if (action.payload && action.payload.length > 0 && action.payload.length <= 100) {
    try {
      const response = yield call(fetchLitigationSavedSearches, {
        query: {
          name: `eq.{${action.payload}`,
        }
      })
      if (response.count === 0) {
        const data = yield* getLitigationData(action.payload)
        yield yield* saveSavedLitigationSearch(action.payload, data)
        yield put(actions.saveLitigationSearchSuccessful())
      } else {
        yield put(actions.saveLitigationSearchError('Litigation search name already exists.'))
      }
    } catch (e) {
      const errorMessage = e.response.body.error || 'Unknown error'
      yield put(actions.saveLitigationSearchError(errorMessage))
    }
  } else {
    yield put(actions.saveLitigationSearchError('Name should be between 1 and 100 characters long.'))
  }
}

function* handleOpenSectorListReport() {
  let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2)
  const data = yield* getLitigationData(uniqueId)
  if (LocalReportDataHandler.saveToLocalStorage(uniqueId, data)) {
    if (data.isOnMainPage) {
      window.open(urls.litigationMonitoringReportView(uniqueId), '_blank')
    } else {
      window.open(urls.litigationSectorReportView(uniqueId), '_blank')
    }
  }
}

function* fetchIndustriesAndCompanies() {
  const mainPage = yield select(selectors.getMainPage)
  if (mainPage.sectors.length === 0) {
    const allIndustryResponse = yield yield* api.fetchLitigationNumberSummary()
    const myIndustryRequest = yield fetchMyIndustries()
    const myCompanyRequest = yield* fetchMyCompanies({
      fields: [...CompanyResource.requiredFields, 'industries'],
    })
    const myCompanyLitigationRequest = yield* api.fetchMyCompanyLitigation()

    yield put(actions.setAllSectors(allIndustryResponse.result))
    
    yield fork(function*() {
      let myResponse
      try {
        myResponse = yield myIndustryRequest
      } catch (e) {
        return
      }
      let payload = myResponse.result
      if (mainPage.selectedFilter === FILTER_CHECKBOXES.myIndustries) {
        yield put(actions.setSectors(payload))
      }
      else{
        yield put(actions.setSectors(allIndustryResponse.result))
      }
      yield put(actions.setMySectors(payload))
    })

    yield fork(function*() {
      yield put(actions.setMainPageLoading(true))
      let myResponse, data = []
      try {
        myResponse = yield myCompanyRequest
        data = myResponse.body.map(company => {
          company.name = company.displayName
          return company
        })
      } catch (e) {
        return
      }
      yield put(actions.setMyCompanies(data))
      yield put(actions.setMainPageLoading(false))

      yield fork(function*() {
        let myResponse
        try {
          myResponse = yield myCompanyLitigationRequest
          const _mainPage = yield select(selectors.getMainPage)
          myResponse = myResponse.body.map(company => {
            const companyDetails = _mainPage.myCompanies.find(com => com.id === company.companyId)
            companyDetails.litigationStoryCount = company.count
            return companyDetails
          })
        } catch (e) {
          return
        }
          yield put(actions.setMyCompanies(myResponse))
      })
    })
  }
}

function* fetchCompaniesForIndustries(action) {
  const mainPage = yield select(selectors.getMainPage)
  const sectorPage = yield select(selectors.getSectorPage)
  const _sectorIds = action.payload
  const sectorIds = _sectorIds || mainPage.selectedSectors
  let result = []
  if (sectorIds && sectorIds.length > 0) {
    const filters = sectorPage.filters
    // const entities = yield select(entitiesSelectors.getEntities)
    let valences = []
    filters.valencePositive ? valences.push('POS') : null
    filters.valenceNegative ? valences.push('NEG') : null
    filters.valenceNeutral ? valences.push('NEU') : null
    valences = valences.length > 0 ? valences : null
    const response = yield yield* api.fetchLitigationTimeSeries(
      {
        industries: sectorIds,
        storyCountMin: filters.storyCountMin,
        storyCountMax: filters.storyCountMax,
        valences,
        aspect: filters.aspect,
        subfactors: filters.factors.length > 0 ? filters.factors.map(factor => factor.label) : null,
      }
    )
    yield put(actions.setCompanyTimeSeries(response.result))
  }
  else {
    yield put(actions.setCompanyTimeSeries([]))
  }
  yield put(actions.setSectorLoading(false))
}

const isLitigationMainPageSelected = action =>
  action.type === LOCATION_CHANGED && action.payload.route === routes.litigation

const isLitigationMainPageReportSelected = action =>
  action.type === LOCATION_CHANGED && action.payload.route === routes.litigationMonitoringReport

const isLitigationSectorPageReportSelected = action =>
  action.type === LOCATION_CHANGED && action.payload.route === routes.litigationSectorReport

const isLitigationLoadPageSelected = action =>
  action.type === LOCATION_CHANGED && action.payload.route === routes.litigationLoadView

export default function* industriesSaga() {
  yield all([
    takeLatest(isLitigationMainPageSelected, locationChanged),
    takeLatest(isLitigationMainPageReportSelected, reportLocationChanged),
    takeLatest(isLitigationSectorPageReportSelected, reportLocationChanged),
    takeLatest(isLitigationLoadPageSelected, litigationLoadLocationChanged),
    takeLatest(actions.setCompanyFilters, handleCompanyFilterChange),
    takeLatest(actions.fetchMoreStories, handleFetchMoreStories),
    takeLatest(actions.fetchLitigationSavedSearches, handleFetchSavedLitigationSearches),
    takeLatest(actions.fetchMoreLitigationSavedSearches, handleFetchMoreSavedLitigationSearches),
    takeLatest(actions.removeLitigationSavedSearch, handleRemoveLitigationSavedSearch),
    takeLatest(actions.selectLitigationSavedSearch, handleSelectLitigationSavedSearch),
    takeLatest(actions.setLitigationSavedSearchOrderBy, handleSetLitigationSavedSearchSort),
    takeLatest(actions.saveLitigationSearch, handleAddLitigationSavedSearch),
    takeLatest(actions.openSectorListReport, handleOpenSectorListReport),
    takeLatest(actions.fetchIndustriesAndCompanies, fetchIndustriesAndCompanies),
    takeLatest(actions.fetchCompaniesForIndustries, fetchCompaniesForIndustries),
    takeLatest(actions.setSelectedCompanies, handleCompaniesSelected),
    takeLatest(
      actions.startDownloadPdf,
      downloadPdf,
      actions.finishDownloadPdf,
    )
  ])
}
