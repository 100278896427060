import React from 'react'

const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E']

/**
 * Abbreviates numbers using the SI symbols.
 *
 * Solution taken from: https://stackoverflow.com/a/40724354
 *
 * @param number A number you would like to abbreviate.
 * @returns {string|*} An abbreviated representation of the number if applicable.
 */
function abbreviateNumber(number) {

  // what tier? (determines SI symbol)
  let tier = Math.log10(Math.abs(number)) / 3 | 0

  if (tier === 0) return number

  // get suffix and determine scale
  let suffix = SI_SYMBOL[tier]
  let scale = Math.pow(10, tier * 3)

  // scale the number
  let scaled = number / scale

  // format number and add suffix
  return scaled.toFixed(1) + suffix
}

const arrayUniqueById = (array) => [...new Map(array.map(item =>
  [item.id, item])).values()]

/**
 * Taken from https://stackoverflow.com/a/6444043
 * @param hex
 * @param percent
 * @returns {string}
 */
function increaseBrightness(hex, percent) {
  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, '')

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length === 3) {
    hex = hex.replace(/(.)/g, '$1$1')
  }

  let r = parseInt(hex.substr(0, 2), 16),
    g = parseInt(hex.substr(2, 2), 16),
    b = parseInt(hex.substr(4, 2), 16)

  return '#' +
    ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
    ((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
    ((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1)
}

/**
 * Return the time since the param `date` if it is in the last day
 * else it returns a localized date string.
 *
 * If the date is in the future, it returns "Just now".
 *
 * @param date A Date object.
 * @returns {string}
 */
function timeAgo(date) {
  const TIME_CONSTANTS = {
    MINUTE: 60,
    HOUR: 3600,
    DAY: 84600,
  }
  const diff = (new Date() - date) / 1000

  if (diff < TIME_CONSTANTS.MINUTE) {
    return 'Just now'
  } else if (diff < TIME_CONSTANTS.HOUR) {
    const minutes = Math.floor(diff / TIME_CONSTANTS.MINUTE)
    return minutes.toString() + (minutes > 1 ? ' minutes ago' : ' minute ago')
  } else if (diff < TIME_CONSTANTS.DAY) {
    const hours = Math.floor(diff / TIME_CONSTANTS.HOUR)
    return hours.toString() + (hours > 1 ? ' hours ago' : ' hour ago')
  } else {
    return date.toLocaleDateString()
  }
}

const LocalReportDataHandler = {
  /*
   * Serialize report settings to localStorage.
   * Doesn't check for key collisions.
   *
   * @return true if it was successful.
   */
  saveToLocalStorage: (name, value) => {
    let reports = localStorage.getItem('reports')
    if (!reports) {
      reports = JSON.stringify({})
    }

    try {
      const deserializedReports = JSON.parse(reports)
      deserializedReports[name] = value
      localStorage.setItem('reports', JSON.stringify(deserializedReports))
    } catch (e) {
      return false
    }

    return true
  },
  /**
   * Load an item from localStorage.
   *
   * @param name
   * @returns {null|*} null if failed, the object otherwise.
   */
  loadFromLocalStorage: (name) => {
    let reports = localStorage.getItem('reports')
    if (!reports) {
      return null
    }

    try {
      let deserializedReports = JSON.parse(reports)
      return deserializedReports[name]
    } catch {
      return null
    }
  }
}

export { abbreviateNumber, arrayUniqueById, increaseBrightness, timeAgo, LocalReportDataHandler }
