import classNames from 'classnames'
import React from 'react'

import InlineSvg from 'app/common/InlineSvg'

import * as styles from './MagnifyingGlass.less'
import iconUrl from 'static/images/magnifying-glass.svg'

export default function MagnifyingGlass({className, ...restProps}) {
  return (
    <InlineSvg
      src={iconUrl}
      className={classNames(styles.icon, className)}
      {...restProps}
    />
  )
}
