import Resource from "app/framework/Resource";
import MyCompanyLitigationModel from "../models/MyCompanyLitigation";

export default class MyCompanyLitigation extends Resource {
  static endpoint = "my_company_litigation"; 

  entityItemsForRestItem(restData) {
    return [
      {
        id: restData.id, // The ID is the industry's ID. We need an ID field, because of the FE ORM constraints
        entityKey: MyCompanyLitigationModel.entityKey,
        data: {
          id: restData.company_id,
          name: restData.company_name,
          litigationStoryCount: restData.count,
        }
      }
    ];
  }
}
