import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import {Portal} from 'react-portal'
import {connect} from 'react-redux'

import Button from 'app/common/Button'
import Dropdown from 'app/common/Dropdown'
import InputBlock from 'app/common/InputBlock'
import Modal from 'app/common/Modal'
import ManzamaLogo from 'app/common/logos/manzama-logo'
import RadioButton from 'app/common/RadioButton'
import {ORDERED_FACTORS, ORDERED_SUBFACTORS_BY_FACTOR} from 'app/constants'
import GeographyDropdown from 'app/reusable/geography/geography-dropdown'
import * as strings from 'app/strings'

import * as actions from './overview-actions'

import * as styles from './OverviewFilters.less'

const factorOptions = ORDERED_FACTORS.map(factor => ({
  label: strings.factorDisplayName(factor),
  value: factor,
}))
const allFactorOptions = [
  {label: 'All Factors', value: 'all'},
  ...factorOptions,
]

function OverviewFilters({
  selectedFactor,
  selectedSubfactor,
  selectedTimeFrame,
  selectedGeography,
  shouldShowTimeFrame = true,
  filterDefaults,
  onSaveFilterDefaults,

  isReportButtonVisible = false,
  reportButtonUrl,
  reportButtonLabel,

  isManzamaLogoVisible = false,
  className,

  // Actions
  onSelectFactor,
  onSelectSubfactor,
  onSelectTimeFrame,
  onSelectGeography,
}) {
  const canSaveFilters =
    filterDefaults &&
    onSaveFilterDefaults &&
    (selectedFactor !== filterDefaults.factor ||
      selectedSubfactor !== filterDefaults.subfactor ||
      selectedTimeFrame !== filterDefaults.timeFrameDays ||
      selectedGeography !== filterDefaults.geography)

  const factorFilter = (
    <FilterDropdown
      label="Factor"
      className="factor-filter"
      value={selectedFactor || 'all'}
      onChange={factor => {
        if (selectedSubfactor && selectedSubfactor !== 'all') {
          // If a subfactor is selected and it does not belong in the new
          // factor, clear it out.
          onSelectSubfactor('all')
        }
        onSelectFactor(factor)
      }}
      options={allFactorOptions}
    />
  )

  const subfactorOptions = ORDERED_FACTORS.filter(
    factor => !selectedFactor || factor === selectedFactor,
  ).map(factor => ({
    label: strings.factorDisplayName(factor),
    isGroup: true,
    options: ORDERED_SUBFACTORS_BY_FACTOR[factor].map(subfactor => ({
      label: strings.subfactorDisplayName(subfactor),
      value: subfactor,
    })),
  }))
  const allSubfactorOptions = [
    {label: 'All Subfactors', value: 'all'},
    ...subfactorOptions,
  ]
  const subfactorFilter = (
    <FilterDropdown
      label="Subfactor"
      className="subfactor-filter"
      value={selectedSubfactor || 'all'}
      onChange={subfactor => onSelectSubfactor(subfactor)}
      options={allSubfactorOptions}
    />
  )

  const timeFrameFilter = shouldShowTimeFrame && (
    <FilterDropdown
      label="Time Frame"
      className="time-frame-filter"
      value={selectedTimeFrame}
      onChange={timeFrame => onSelectTimeFrame(timeFrame)}
    >
      <Dropdown.Option label="30 Days" value={30} />
      <Dropdown.Option label="90 Days" value={90} />
    </FilterDropdown>
  )
  const reportButton = isReportButtonVisible && (
    <a href={reportButtonUrl} target="_blank" className="generate-report">
      <Button label={reportButtonLabel} isPrimary={true} />
    </a>
  )
  const manzamaDarkLogoVisible = isManzamaLogoVisible && (
    <ManzamaLogo className={styles.logo} />
  )

  const geographyFilter = (
    <GeographyFilter
      selectedGeography={selectedGeography}
      onChange={onSelectGeography}
    />
  )

  const saveFiltersLink = canSaveFilters && (
    <InputBlock label="&nbsp;" className={styles.filter}>
      <Button
        label="Save Filters"
        onClick={() => onSaveFilterDefaults()}
        className={styles.saveFilters}
      />
    </InputBlock>
  )

  return (
    <div className={classNames(styles.filters, className)}>
      {factorFilter}
      {subfactorFilter}
      {timeFrameFilter}
      {geographyFilter}
      {saveFiltersLink}

      <div className={styles.end}>
        {reportButton}
        {manzamaDarkLogoVisible}
      </div>
    </div>
  )
}
OverviewFilters.propTypes = {
  selectedFactor: PropTypes.string,
  selectedSubfactor: PropTypes.string,
  selectedTimeFrame: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedGeography: PropTypes.number,
  shouldShowTimeFrame: PropTypes.bool,
  filterDefaults: PropTypes.shape({
    factor: PropTypes.string,
    subfactor: PropTypes.string,
    timeFrameDays: PropTypes.number,
    geography: PropTypes.number.isRequired,
  }),
  onSaveFilterDefaults: PropTypes.func,

  isReportButtonVisible: PropTypes.bool,
  reportButtonUrl: PropTypes.string,
  reportButtonLabel: PropTypes.string,

  isManzamaLogoVisible: PropTypes.bool,

  className: PropTypes.string,

  // Actions
  onSelectFactor: PropTypes.func.isRequired,
  onSelectSubfactor: PropTypes.func.isRequired,
  onSelectTimeFrame: PropTypes.func,
  onSelectGeography: PropTypes.func.isRequired,
}
export default connect(
  null,
  {
    onSelectFactor: actions.selectFactor,
    onSelectSubfactor: actions.selectSubfactor,
    onSelectTimeFrame: actions.selectTimeFrame,
    onSelectGeography: actions.selectGeography,
  },
)(OverviewFilters)

function CompanyFilter({selectedCompany, myCompanies, onChange}) {
  if (!myCompanies) return null
  let companyOptions = []
  if (!myCompanies.some(company => company.id === selectedCompany.id)) {
    // Start with the current company if it's not already in the list.
    companyOptions.push({
      label: selectedCompany.name,
      value: selectedCompany.id,
    })
  }
  companyOptions = companyOptions.concat(
    myCompanies.map(company => ({
      label: company.name,
      value: company.id,
    })),
  )
  return (
    <FilterDropdown
      label="Company"
      className="company-filter"
      value={selectedCompany.id}
      onChange={onChange}
      options={companyOptions}
    />
  )
}
CompanyFilter.propTypes = {
  selectedCompany: PropTypes.object.isRequired,
  myCompanies: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
}

function GeographyFilter({selectedGeography, onChange}) {
  return (
    <InputBlock label="Geography" className={styles.filter}>
      <GeographyDropdown
        selectedGeography={selectedGeography}
        onChange={onChange}
        className={styles.dropdown}
        optionsClassName={styles.options}
      />
    </InputBlock>
  )
}
GeographyFilter.propTypes = {
  selectedGeography: PropTypes.number.isRequired,
  onChange: PropTypes.func,
}

const FilterDropdown = ({label, className, ...restProps}) => (
  <InputBlock label={label} className={styles.filter}>
    <Dropdown
      className={classNames(styles.dropdown, className)}
      optionsClassName={styles.options}
      {...restProps}
    />
  </InputBlock>
)

export function SaveFiltersModal({entityType, entityName, onSave, onClose}) {
  const [applyToAllEntities, setApplyToAllEntities] = React.useState(false)
  const [isDone, setIsDone] = React.useState(false)

  const entityTypeDisplayNamePlural = strings.entityTypeDisplayName(
    entityType,
    {plural: true},
  )
  const onSaveClick = () => {
    onSave(applyToAllEntities)
    setIsDone(true)
  }

  const contents = isDone ? (
    <>
      <h2>Success!</h2>
      <p>Your new filter defaults have been saved.</p>
    </>
  ) : (
    <>
      <h2>Save Filter Defaults</h2>

      <p>
        You may save the currently selected filters as your new defaults. Would
        you like this change to apply only to this individual {entityType}, or
        to all {entityTypeDisplayNamePlural}?
      </p>

      <div className={styles.choices}>
        <InputBlock
          label={
            <span className={styles.label}>
              Apply these filters <strong>only to {entityName}</strong>.
            </span>
          }
          isInline={true}
        >
          <RadioButton
            isChecked={!applyToAllEntities}
            onChange={() => setApplyToAllEntities(false)}
          />
        </InputBlock>
        <InputBlock
          label={
            <span className={styles.label}>
              Apply these filters{' '}
              <strong>to all {entityTypeDisplayNamePlural}</strong>.
            </span>
          }
          isInline={true}
        >
          <RadioButton
            isChecked={applyToAllEntities}
            onChange={() => setApplyToAllEntities(true)}
          />
        </InputBlock>
      </div>

      <div className={styles.buttons}>
        <Button label="Cancel" isPlainText={true} onClick={() => onClose} />
        <Button label="Save Filters" onClick={onSaveClick} />
      </div>
    </>
  )
  return (
    <Portal>
      <Modal
        onClose={onClose}
        className={styles.saveFiltersModal}
        contentClassName={styles.content}
      >
        {contents}
      </Modal>
    </Portal>
  )
}
SaveFiltersModal.propTypes = {
  entityType: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export function SuccessModal({entityType, onClose}) {
  return (
    <h2 className={styles.successMessage}>
      Your default {entityType} filters have been saved successfully.
    </h2>
  )
}
SuccessModal.propTypes = {
  entityType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}
