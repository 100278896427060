import {handleActions} from 'redux-actions'
import {LOCATION_CHANGED} from 'redux-little-router'

import {lastFinishedQuarter} from 'app/utils/quarters'

import {
  getInitialSharedState,
  getSelectedFactor,
  getSelectedSubfactor,
  getSelectedTimeFrame,
  getSelectedGeography,
  highlightFactorReducer,
  clearHighlightedFactorReducer,
  highlightSubfactorReducer,
  clearHighlightedSubfactorReducer,
  setStoryIdsReducer,
  selectValenceReducer,
  deselectValenceReducer,
  setStorySortOrderReducer,
} from '../common/overview-reducer-helpers'
import * as actions from './company-overview-actions'
import {
  ALL_COMPANY_ROUTES,
  SIM_CIRC_STATE_PROP_NAME,
  TIMELINE_STATE_PROP_NAME,
} from './company-overview-constants'
import simCircReducer, {
  getInitialState as getSimCircInitialState,
} from './simcirc/company-overview-simcirc-reducer'

const getInitialState = () => ({
  ...getInitialSharedState(),
  companyId: null,
  storylineIds: null,
  predictedHealthScoreChangesByFactor: {},
  hasConfidenceInPredictionsByFactor: {},
  leadership: {
    companyId: null,
    leadershipData: [],
  },

  historicalStorylineIds: null,
  selectedQuarter: lastFinishedQuarter().toString(),
  historicalHealthData: null,
  historicalStorylineSearchText: '',
  autoQuarterSearch: false,
  // The shape should be the same as that of `historicalHealthData`. A null
  // value means we should use the values in `historicalHealthData`.
  articleCountsByQuarter: null,

  isLoading: true,
  [TIMELINE_STATE_PROP_NAME]: getInitialTimelineState(),
  [SIM_CIRC_STATE_PROP_NAME]: getSimCircInitialState(),
})

const getInitialTimelineState = () => ({
  highlightedDay: undefined,
  highlightedGroups: [],

  showStoriesTooltip: {
    isVisible: false,
    left: undefined,
    right: undefined,
    top: undefined,
    date: undefined,
    colors: undefined,
    storyIds: undefined,
    isExpanded: undefined,
  },
})

export default function companyOverviewReducer(
  state = getInitialState(),
  action,
) {
  const newState = handleActions(
    {
      [LOCATION_CHANGED]: (state, action) => {
        const initialState = getInitialState()
        if (ALL_COMPANY_ROUTES.includes(action.payload.route)) {
          const companyId = parseInt(action.payload.params.companyId, 10)
          const selectedGeography = getSelectedGeography(action)
          return {
            ...state,
            // Make sure to reset the loading state if we move to another
            //  company's page.
            isLoading: companyId === state.companyId ? state.isLoading : true,
            companyId,
            selectedFactor:
              getSelectedFactor(action) || initialState.selectedFactor,
            selectedSubfactor:
              getSelectedSubfactor(action) || initialState.selectedSubfactor,
            timeFrameDays:
              getSelectedTimeFrame(action) || initialState.timeFrameDays,
            selectedGeography:
              selectedGeography === null
                ? initialState.selectedGeography
                : selectedGeography,
            selectedSort:
              action.payload.query && action.payload.query.sort
                ? action.payload.query.sort
                : initialState.selectedSort,
            selectedQuarter:
              (action.payload.query && action.payload.query.quarter) ||
              initialState.selectedQuarter,
          }
        }
        return initialState
      },
      [actions.setIsLoading]: (state, action) => ({
        ...state,
        isLoading: action.payload,
      }),
      [actions.setStorylineIds]: (state, action) => ({
        ...state,
        storylineIds: action.payload,
      }),
      [actions.showFiltersSaveModal]: (state, action) => ({
        ...state,
        shouldShowFiltersSaveModal: true,
      }),
      [actions.hideFiltersSaveModal]: (state, action) => ({
        ...state,
        shouldShowFiltersSaveModal: false,
      }),
      [actions.setPredictedHealthScoreChangesByFactor]: (state, action) => ({
        ...state,
        predictedHealthScoreChangesByFactor: action.payload,
      }),
      [actions.setHasConfidenceInPredictionsByFactor]: (state, action) => ({
        ...state,
        hasConfidenceInPredictionsByFactor: action.payload,
      }),
      [actions.setLeadership]: (state, action) => ({
        ...state,
        leadership: action.payload,
      }),
      // Story reader

      [actions.setStoryIds]: setStoryIdsReducer,
      [actions.selectValence]: selectValenceReducer,
      [actions.deselectValence]: deselectValenceReducer,
      [actions.setStorySortOrder]: setStorySortOrderReducer,

      [actions.highlightFactor]: highlightFactorReducer,
      [actions.clearHighlightedFactor]: clearHighlightedFactorReducer,
      [actions.highlightSubfactor]: highlightSubfactorReducer,
      [actions.clearHighlightedSubfactor]: clearHighlightedSubfactorReducer,

      // History

      [actions.setHistoricalStorylineIds]: (state, action) => ({
        ...state,
        historicalStorylineIds: action.payload,
      }),
      [actions.selectQuarter]: (state, action) => ({
        ...state,
        selectedQuarter: action.payload.toString(),
      }),
      [actions.setHistoricalHealthData]: (state, action) => ({
        ...state,
        historicalHealthData: action.payload,
      }),
      [actions.setHistoricalStorylineSearchText]: (state, action) => ({
        ...state,
        historicalStorylineSearchText: action.payload,
        // Reset the quarterly article counts if there is no search query.
        articleCountsByQuarter: action.payload
          ? state.articleCountsByQuarter
          : null,
      }),
      [actions.setQuarterlyArticleCounts]: (state, action) => ({
        ...state,
        articleCountsByQuarter: action.payload,
      }),
      [actions.setAutoQuarterSearch]: (state, action) => ({
        ...state,
        autoQuarterSearch: action.payload,
      }),
    },
    getInitialState(),
  )(state, action)
  // TODO: This creates a new state object for every single action; make sure
  //  we're only doing this as necessary.
  return {
    ...newState,
    [TIMELINE_STATE_PROP_NAME]: timelineReducer(
      state[TIMELINE_STATE_PROP_NAME],
      action,
    ),
    [SIM_CIRC_STATE_PROP_NAME]: simCircReducer(
      state[SIM_CIRC_STATE_PROP_NAME],
      action,
    ),
  }
}

const timelineReducer = handleActions(
  {
    [actions.timeline.highlightDay]: (state, action) => ({
      ...state,
      highlightedDay: action.payload,
    }),
    [actions.timeline.clearHighlightedDay]: (state, action) => ({
      ...state,
      highlightedDay: getInitialTimelineState().highlightedDay,
    }),
    [actions.timeline.highlightGroups]: (state, action) => ({
      ...state,
      highlightedGroups: action.payload,
    }),
    [actions.timeline.clearHighlightedGroups]: (state, action) => ({
      ...state,
      highlightedGroups: getInitialTimelineState().highlightedGroups,
    }),
    [actions.timeline.showStoriesTooltip]: (state, action) => ({
      ...state,
      showStoriesTooltip: {
        ...action.payload,
        isVisible: true,
        isExpanded: false,
      },
    }),
    [actions.timeline.expandStoriesTooltip]: (state, action) => ({
      ...state,
      showStoriesTooltip: {
        ...state.showStoriesTooltip,
        isExpanded: true,
      },
    }),
    [actions.timeline.hideStoriesTooltip]: (state, action) => ({
      ...state,
      showStoriesTooltip: getInitialTimelineState().showStoriesTooltip,
    }),
  },
  getInitialTimelineState(),
)
