import invariant from 'invariant'
import is from 'is'
import {range} from 'ramda'

import {today} from 'app/constants'

export class Quarter {
  constructor(year, quarterNum) {
    this.year = year
    this.number = quarterNum
  }

  toString() {
    return `${this.year}-Q${this.number}`
  }
}

/**
 * Parses the given `quarter` string into a Quarter object.
 * @param quarter {string}
 * @return Quarter
 */
export function parseQuarter(quarter) {
  const [year, quarterNum] = quarter.split('-Q').map(part => parseInt(part, 10))
  invariant(
    quarterNum >= 1 && quarterNum <= 4,
    `Invalid quarter number ${quarterNum}.`,
  )
  return new Quarter(year, quarterNum)
}

export function quarterToDateRange(quarter) {
  if (is.string(quarter)) {
    quarter = parseQuarter(quarter)
  }
  const startMonth = (quarter.number - 1) * 3
  const endMonth = quarter.number === 4 ? 0 : startMonth + 3
  const endYear = quarter.number === 4 ? quarter.year + 1 : quarter.year
  return [new Date(quarter.year, startMonth), new Date(endYear, endMonth)]
}

export function quarterFromDate(date) {
  const quarterNum = Math.floor(date.getMonth() / 3) + 1
  return new Quarter(date.getFullYear(), quarterNum)
}

export function prevQuarter(quarter) {
  if (quarter.number === 1) {
    return new Quarter(quarter.year - 1, 4)
  }
  return new Quarter(quarter.year, quarter.number - 1)
}

export function nextQuarter(quarter) {
  if (quarter.number === 4) {
    return new Quarter(quarter.year + 1, 1)
  }
  return new Quarter(quarter.year, quarter.number + 1)
}

export function lastFinishedQuarter() {
  return prevQuarter(quarterFromDate(today))
}

/**
 * Returns an array of quarters ranging from `start` to `end`, inclusive.
 */
export function quarterRange(start, end) {
  if (is.string(start)) {
    start = parseQuarter(start)
  }
  if (is.string(end)) {
    end = parseQuarter(end)
  }
  const quarters = []
  for (const year of range(start.year, end.year + 1)) {
    const startQuarterNum = year === start.year ? start.number : 1
    const endQuarterNum = year === end.year ? end.number : 4
    for (const number of range(startQuarterNum, endQuarterNum + 1)) {
      quarters.push(new Quarter(year, number))
    }
  }
  return quarters
}
