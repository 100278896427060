import PropTypes from 'prop-types'
import React, {PureComponent} from 'react'
import {connect} from 'react-redux'

import {navigate} from 'app/global/global-actions'

class NavigationLink extends PureComponent {
  static propTypes = {
    href: PropTypes.string.isRequired,
  }

  render() {
    const {href, children, navigate, onClick, ...restProps} = this.props
    return (
      <a href={href} onClick={this.onClick.bind(this)} {...restProps}>
        {children}
      </a>
    )
  }

  onClick(event) {
    // First call the prop handler, if one was passed.
    if (this.props.onClick) {
      this.props.onClick(event)
    }

    // Only hijack left clicks so that middle-clicking still behaves normally.
    // Also don't hijack the click if any modifier keys are pressed.
    if (
      event.button === 0 &&
      !(event.metaKey || event.ctrlKey || event.shiftKey)
    ) {
      event.preventDefault()
      this.props.navigate(this.props.href)
    }
  }
}

export default connect(
  undefined,
  {navigate},
)(NavigationLink)
