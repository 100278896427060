import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import * as actions from 'app/common/charts/charts-actions'
import * as globalActions from 'app/global/global-actions'
import * as api from 'app/common/charts/charts-api'
import * as loginSelectors from 'app/login/login-selectors'
import * as selectors from 'app/common/charts/charts-selectors'

function* handleSaveChart(action) {
  yield put(actions.setIsWaiting(true))
  const token = yield select(loginSelectors.getJwt)
  const {chartFile, chartName} = action.payload
  let message = `Success! ${chartName} has been saved and is now available in Publish.`
  const errorMessage = `An error occurred saving ${chartName}, please try again.`
  try {
    const response = yield call(api.saveChart, token, {
      chartFile,
      chartName,
    })
    if (response.statusCode !== 200) {
      message = errorMessage
    }
  } catch (error) {
    yield put(globalActions.setError(error))
    message = errorMessage
  }
  yield put(actions.setShowConfirmationModal({show: true, message}))
  yield put(actions.setIsWaiting(false))
}

function* handleSetSectionRef(action) {
  const askForSectionRef = yield select(selectors.getAskForSectionRef)
  yield put(actions.setAskForSectionRef(askForSectionRef.type))
}

export default function* chartSaga() {
  yield all([
    takeLatest(actions.saveChart, handleSaveChart),
    takeLatest(actions.setSectionRef, handleSetSectionRef),
  ])
}
