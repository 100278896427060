import React from 'react'

import BasicEmailAlert from 'email-server/BasicEmailAlert'

const WeeklyEmail = ({data}) => (
  <BasicEmailAlert
    data={data}
    config={{shouldShowHealthChanges: true, timeFrameDays: 7}}
  />
)
export default WeeklyEmail
