export const STATE_PROP = 'addCompaniesModal'
export const STEP = {
  // The first step where the user searches for entities.
  SEARCH: 'search',

  // Email alert settings for the entities.
  EMAIL_SETTINGS: 'email-settings',

  // Finished, with either success or failure.
  FINISHED: 'finished',
}
