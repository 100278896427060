import {STATIC_FILES_URL} from 'app/constants'

export const sumCompanyHealthScoreChanges = (
  company,
  {factor, subfactor} = {},
) => {
  const healthData = subfactor
    ? company.subfactorDailyHealthData[subfactor]
    : factor
    ? company.factorDailyHealthData[factor]
    : company.dailyHealthData
  return healthData.reduce((acc, val) => acc + val.healthScoreChange, 0)
}

export const hasCompanyHealthScoreChanged = (
  company,
  {factor, subfactor} = {},
) => Math.abs(sumCompanyHealthScoreChanges(company, {factor, subfactor})) >= 1

export const factorIconUrl = ({factor, valence}) => {
  if (valence) {
    return `${STATIC_FILES_URL}/imgs/signals-icons/${factor}-${valence.toLowerCase()}-circle.png`
  }
  return `${STATIC_FILES_URL}/imgs/signals-icons/${factor}.png`
}

export const aspectIconUrl = aspect =>
  `${STATIC_FILES_URL}/imgs/signals-icons/${aspect}.png`
