import {dissoc, map, prop} from 'ramda'
import {LOCATION_CHANGED, replace} from 'redux-little-router'
import {
  all,
  call,
  put,
  putResolve,
  select,
  takeLatest,
} from 'redux-saga/effects'

import {
  PYAPP_LOGIN_REDIRECT_QUERY_PARAM_NAME,
  ROOT_URL,
  FACTORS_BY_ID,
  SUBFACTORS_BY_ID,
} from 'app/constants'
import * as jwt from 'app/jwt'
import routes from 'app/routes'
import {updateGoogleAnalyticsUserId} from 'app/third-party/google-analytics'
import {updatePendoUserId} from 'app/third-party/pendo'
import urls from 'app/urls'

import * as actions from './login-actions'
import * as api from './login-api'
import * as selectors from './login-selectors'

function* initializeSession() {
  const currentToken = yield call(jwt.getCurrentToken)
  const router = yield select(prop('router'))
  const queryParams = router.query
  const queryToken =
    queryParams &&
    queryParams.token &&
    (yield call(window.atob, queryParams.token))

  // Pick the newest token and use that
  const newestToken = [currentToken, queryToken].reduce((newest, token) => {
    // TODO: Check for expiration?
    if (
      !newest ||
      (token && jwt.getExpiration(token) > jwt.getExpiration(newest))
    ) {
      return token
    }
    return newest
  }, null)
  if (newestToken) {
    yield putResolve(actions.setToken(newestToken))
  }

  // We wait to start this until after the token has been set, so that we know
  // for certain if we're logged in or not.
  yield takeLatest(LOCATION_CHANGED, locationChanged)

  // Trigger a location changed event on this page to initialize the current
  // page. If we don't do this, a LOCATION_CHANGED action will never be
  // dispatched for the initial page, which means nothing will load.
  const pathname = router.pathname
  // Remove the token from the URL.
  const newQuery = dissoc('token', queryParams)
  yield put(replace({pathname, query: newQuery}))
}

function* locationChanged(action) {
  yield* validateLocation(action.payload)
}

function* handleLogOut(action) {
  yield putResolve(actions.clearToken())
  if (action.payload && action.payload.shouldLogOutOfPyApp) {
    yield call(logOutOfPyApp)
  } else {
    yield* goToLoginPage()
  }
}

function* handleSetToken(action) {
  const token = action.payload
  yield call(jwt.setCurrentToken, token)
  yield putResolve(actions.fetchMyProfile(token))
}

function* handleClearToken(action) {
  yield call(jwt.clearCurrentToken)
}

function* handleFetchMyProfile({payload: token}) {
  let body
  try {
    const response = yield call(api.fetchMyProfile, token)
    body = response.body
  } catch (error) {
    if (error.status === 401) {
      yield* goToLoginPage()
      return
    }
    throw error
  }
  const {
    user,
    flaggedArticleCount,
    defaultTimezone,
    timezones,
    locations,
    roles,
    adminHash,
  } = body
  const transformFilterDefaults = defaults => ({
    factor: defaults.factorId && FACTORS_BY_ID[defaults.factorId],
    subfactor: defaults.subfactorId && SUBFACTORS_BY_ID[defaults.subfactorId],
    timeFrameDays: defaults.timeFrame,
    geography: defaults.perspective,
  })
  const data = {
    user: {
      id: user.id,
      displayName: user.displayName,
      role: user.role,
      timezone: user.timezone,
      locationId: user.locationId,
      firmLocationId: user.firmLocationId,
      firm: {
        id: user.firm.id,
        name: user.firm.name,
        country: user.firm.country,
        isInsightsEnabled: user.firm.isInsightsEnabled,
        shouldHideMisLink: user.firm.shouldHideMisLink,
        locations: user.firm.locations,
        headerLogo: user.firm.headerLogo,
      },
      isInsightsEnabled: user.isInsightsEnabled,
      isStaff: user.isStaff,
      isGroupManager: user.isGroupManager,
      isFirmAdmin: user.isFirmAdmin,
      hasPublishAccess: user.hasPublishAccess,
      hasPublishV3Access: user.hasPublishv3Access,
      hasSimCircAccess: user.hasSimCircAccess,
      forceProfileBuilder: user.forceProfileBuilder,
      isMasquerading: !!user.isMasquerading,
    },
    flaggedArticleCount,
    defaultTimezone,
    timezones,
    locations,
    roles,
    adminHash,
    filterDefaults: {
      companies: {
        all: transformFilterDefaults(user.filterDefaults.companies.all),
        byId: map(transformFilterDefaults, user.filterDefaults.companies.byId),
      },
      industries: {
        all: transformFilterDefaults(user.filterDefaults.industries.all),
        byId: map(transformFilterDefaults, user.filterDefaults.industries.byId),
      },
    },
  }
  yield putResolve(actions.setProfileData(data))
  yield* validateLocation(yield select(state => state.router))
  yield* updateAnalytics()
}

function* handleUpdateMyProfile(action) {
  const {token, data} = action.payload
  yield call(api.updateMyProfile, token, data)
  yield call(goToLoginPage, urls.home())
}

function* handleSaveFilterDefaults(action) {
  const token = yield select(selectors.getJwt)
  const {
    entityType,
    entityId,
    factor,
    subfactor,
    timeFrameDays,
    geography,
  } = action.payload
  yield call(api.saveFilterDefaults, token, {
    entityType,
    entityId,
    factor,
    subfactor,
    timeFrameDays,
    geography,
  })
}

function* validateLocation(routerData) {
  const isLoggedIn = !!(yield select(selectors.getJwt))
  const currentUser = yield select(selectors.getCurrentUser)

  if (!isLoggedIn) {
    // We're trying to hit a page without logging in. Redirect to the login
    // page.
    yield* goToLoginPage()
  } else if (
    currentUser &&
    currentUser.isInsightsEnabled &&
    currentUser.firm.isInsightsEnabled &&
    currentUser.forceProfileBuilder &&
    routerData.route !== routes.profileBuilder
  ) {
    yield put(replace(urls.profileBuilder()))
  } else if (
    currentUser &&
    currentUser.isInsightsEnabled &&
    currentUser.firm.isInsightsEnabled &&
    routerData.route === routes.noAccess
  ) {
    // We're at the No Access page but we are properly authenticated--redirect
    // to the home page.
    yield put(replace(urls.home()))
  } else if (routerData.route === routes.logout) {
    yield put(actions.logOut({shouldLogOutOfPyApp: true}))
  } else if (
    currentUser &&
    (!currentUser.isInsightsEnabled || !currentUser.firm.isInsightsEnabled) &&
    routerData.route !== routes.noAccess
  ) {
    // User does not have access; redirect to no-access page.
    yield put(replace(urls.noAccess()))
  }
}

function* updateAnalytics() {
  const user = yield select(selectors.getCurrentUser)
  if (!user) return
  const {id, role, displayName, isFirmAdmin, isInsightsEnabled} = user
  const firm = {
    id: user.firm.id,
    name: user.firm.name,
    country: user.firm.country,
    isInsightsEnabled: user.firm.isInsightsEnabled,
  }
  yield call(updateGoogleAnalyticsUserId, id)
  yield call(updatePendoUserId, {
    id,
    name: displayName,
    role,
    isFirmAdmin,
    isInsightsEnabled,
    firm: {
      id: firm.id,
      name: firm.name,
      country: firm.country,
      isInsightsEnabled: firm.isInsightsEnabled,
    },
  })
}

export function* goToLoginPage(redirectTo = null) {
  if (MOCK_BASE_API) return
  if (!redirectTo) {
    let routerData = yield select(prop('router'))
    const isInvalidRoute = route =>
      [routes.logout, routes.noAccess].includes(route)
    if (isInvalidRoute(routerData.route) && routerData.previous) {
      // If the current page is invalid, we try the previous one, if it exists.
      routerData = routerData.previous
    }
    redirectTo = isInvalidRoute(routerData.route) ? '' : routerData.pathname
  }
  const redirectUrl = encodeURIComponent(`${ROOT_URL}${redirectTo}`)
  const loginUrl =
    urls.pyapp.login() +
    `?${PYAPP_LOGIN_REDIRECT_QUERY_PARAM_NAME}=${redirectUrl}`
  window.location.href = loginUrl
}

function logOutOfPyApp() {
  window.location.href = urls.pyapp.logout()
}

export default function* loginSaga() {
  yield all([
    takeLatest(actions.setToken, handleSetToken),
    takeLatest(actions.clearToken, handleClearToken),
    takeLatest(actions.logOut, handleLogOut),
    takeLatest(actions.fetchMyProfile, handleFetchMyProfile),
    takeLatest(actions.updateMyProfile, handleUpdateMyProfile),
    takeLatest(actions.saveFilterDefaults, handleSaveFilterDefaults),
  ])

  yield* initializeSession()
}
