import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import {VALENCES} from 'app/constants'
import {articleCount, formatNumber, valenceDisplayName} from 'app/strings'

import * as styles from './VolumeSummary.less'

export default function VolumeSummary({company, factor}) {
  const health = factor
    ? company.healthData.factors[factor]
    : company.healthData
  const totalVolume = health.articleVolume
  const valencesOrdered = [
    VALENCES.POSITIVE,
    VALENCES.NEUTRAL,
    VALENCES.NEGATIVE,
  ]

  const rows = valencesOrdered.map(valence => {
    const keySuffix =
      valence === VALENCES.POSITIVE
        ? 'positive'
        : valence === VALENCES.NEGATIVE
        ? 'negative'
        : 'neutral'
    const volume = health[`${keySuffix}Volume`]
    return (
      <div className={styles.row} key={valence}>
        <svg className={styles.barContainer}>
          <rect
            className={styles.backgroundBar}
            x={0}
            y={0}
            width="100%"
            height="100%"
          />
          <rect
            className={classNames(styles.bar, styles[keySuffix])}
            x={0}
            y={0}
            width={`${(volume / totalVolume) * 100}%`}
            height="100%"
          />
        </svg>
        <div className={styles.count}>
          {formatNumber(volume)} {valenceDisplayName(valence)}
        </div>
      </div>
    )
  })

  return (
    <div className={styles.volumeSummary}>
      <div className={styles.totalCount}>
        {articleCount(totalVolume, {shouldDisplayTotal: true})}
      </div>

      {rows}
    </div>
  )
}
VolumeSummary.propTypes = {
  company: PropTypes.object.isRequired,
  factor: PropTypes.string,
}
