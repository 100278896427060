import {field} from 'app/framework/fields'
import Model from 'app/framework/Model'

export default class User extends Model {
  static entityKey = 'users'

  static fields = {
    username: field(),
    firstName: field(),
    lastName: field(),
  }

  get displayName() {
    if (this.lastName) {
      return `${this.firstName} ${this.lastName}`
    }
    if (!this.firstName) {
      return this.username
    }
    return this.firstName
  }

  get displayNameLastFirst() {
    if (this.lastName) {
      return `${this.lastName}, ${this.firstName}`
    }
    if (!this.firstName) {
      return this.username
    }
    return this.firstName
  }
}
