import Resource from 'app/framework/Resource'

import Company from './Company'

export default class MyCompany extends Resource {
  static endpoint = 'my_alert'

  entityItemsForRestItem(restData) {
    return new Company().entityItemsForRestItem(restData)
  }
}
