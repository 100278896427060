import is from 'is'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {LoadingMessage} from 'app/common/loading-message'
import {getMyCompanyIds} from 'app/global/global-selectors'

import * as styles from './CompanyOverviewPortalStandalone.less'

function CompanyOverviewPortalStandalone({myCompanyIds}) {
  const message =
    is.undefined(myCompanyIds) || !is.array.empty(myCompanyIds) ? (
      <LoadingMessage />
    ) : (
      `You have not yet started monitoring any companies. To do so, add companies to your ${
        process.env.BRAND_NAME
      } profile.`
    )
  return <div className={styles.page}>{message}</div>
}
CompanyOverviewPortalStandalone.propTypes = {
  myCompanyIds: PropTypes.arrayOf(PropTypes.number),
}
export default connect(
  createSelector(
    [getMyCompanyIds],
    myCompanyIds => ({myCompanyIds}),
  ),
)(CompanyOverviewPortalStandalone)
