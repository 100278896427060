import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import StackTrace from 'stacktrace-js'

import {logError} from 'app/error-tracking'
import {getError} from 'app/global/global-selectors'

import ErrorPage from '../ErrorPage'

import * as styles from './ErrorBoundary.less'

class ErrorBoundary extends PureComponent {
  state = {
    error: null,
    info: null,
    stackFrames: null,
  }

  componentDidUpdate(prevProps) {
    const {error} = this.props
    if (error && error !== prevProps.error) {
      this._buildStackFrames(error).then(stackFrames => {
        this.setState({error: error, stackFrames})
      })
    }
  }

  componentDidCatch(error, info) {
    logError(error)
    this._buildStackFrames(error)
      .then(stackFrames => {
        this.setState({error, info, stackFrames})
      })
      .catch(parseError => {
        this.setState({error, info, stackFrames: null})
      })
  }

  render() {
    if (this.state.error) {
      if (process.env.NODE_ENV === 'production') {
        return <ErrorPage />
      }

      let stackTrace
      if (this.state.stackFrames) {
        const indentedErrorStack = this.state.stackFrames
          .map(stackFrame => `    ${stackFrame.toString()}`)
          .join('\n')
        stackTrace = (
          <React.Fragment>
            <pre>Stack trace:</pre>
            <pre>{indentedErrorStack}</pre>
          </React.Fragment>
        )
      }

      const componentStack = this.state.info && (
        <React.Fragment>
          <pre>&nbsp;</pre>
          <pre>Component stack: {this.state.info.componentStack}</pre>
        </React.Fragment>
      )

      return (
        <div className={styles.errorBoundary}>
          <div className={styles.content}>
            <pre>Error: {this.state.error.message}</pre>
            <pre>&nbsp;</pre>
            {stackTrace}
            {componentStack}
          </div>
        </div>
      )
    }

    return this.props.children
  }

  _buildStackFrames(error) {
    return StackTrace.fromError(error)
  }
}

export default connect(
  createSelector(
    [getError],
    error => ({error}),
  ),
)(ErrorBoundary)
