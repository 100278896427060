import {LOCATION_CHANGED} from 'redux-little-router'
import {all, put, select, takeLatest} from 'redux-saga/effects'

import {fetchCompanies, fetchMyCompanies} from 'app/api/api-saga-helpers'
import {FACTORS, SORT_DIRECTIONS} from 'app/constants'
import {GEOGRAPHY_FIELD_NAME} from 'app/overviews/company/company-overview-constants'
import * as entityActions from 'app/framework/entities-actions'
import {getMyCompanyIds} from 'app/global/global-selectors'
import {Company} from 'app/models'
import CompanyResource from 'app/resources/Company'
import routes from 'app/routes'

import * as actions from './companies-page-actions'
import {PAGE_SIZE} from './companies-page-constants'
import * as selectors from './companies-page-selectors'

export function* locationChanged(action) {
  const isLeavingCompaniesPage = yield select(
    state => state.router.previous.route === routes.companies,
  )
  if (action.payload.route === routes.companies) {
    yield* fetchCurrentCompanies()
  } else if (isLeavingCompaniesPage) {
    // Delete the data for these companies and industries so we're not carrying
    // it around for all eternity.
    const {companyIds} = yield select(selectors.getState)
    if (companyIds) {
      yield put(entityActions.remove({[Company.entityKey]: companyIds}))
    }
  }
}

function* ensureValidPage() {
  const myCompanyIds = yield select(getMyCompanyIds)
  const {
    currentPage,
    totalCompanyCount,
    shouldShowOnlyMyCompanies,
  } = yield select(selectors.getState)
  const companyCount = shouldShowOnlyMyCompanies
    ? myCompanyIds.length
    : totalCompanyCount
  const lowestValidPage = Math.ceil(companyCount / PAGE_SIZE)
  if (currentPage > lowestValidPage) {
    yield put(actions.setPage(lowestValidPage))
  }
}

function* fetchCurrentCompanies() {
  yield put(actions.setIsLoading(true))

  let myCompanyIds = yield select(getMyCompanyIds)
  if (!myCompanyIds) {
    yield yield* fetchMyCompanies({
      fields: [...CompanyResource.requiredFields, 'healthBadge'],
    })
    myCompanyIds = yield select(getMyCompanyIds)
  }

  const {
    tableSort,
    currentPage,
    nameFilter,
    letterFilter,
    geography,
    shouldShowOnlyMyCompanies,
  } = yield select(selectors.getState)

  const apiSortField = {
    companyName: 'displayName',
    health: 'healthScore',
    volume: 'articleVolume',
    [FACTORS.DEALS]: 'dealsHealthScore',
    [FACTORS.EXECS]: 'execsHealthScore',
    [FACTORS.FINANCE]: 'financeHealthScore',
    [FACTORS.GOVERNMENT]: 'governmentHealthScore',
    [FACTORS.PRODUCT]: 'productHealthScore',
    [FACTORS.OPERATIONS]: 'laborHealthScore',
  }[tableSort.column]
  const companyIds = shouldShowOnlyMyCompanies ? myCompanyIds : undefined
  const orderBy = [
    {field: apiSortField, direction: tableSort.direction, nulls: 'last'},
  ]

  // Add a secondary sort on article volume so that scores with low volume show
  // up last.
  if (apiSortField !== 'articleVolume') {
    orderBy.push({
      field: 'articleVolume',
      direction: SORT_DIRECTIONS.DESC,
    })
  }

  const requestOptions = {
    companyIds,
    nameContains: nameFilter,
    nameStartsWith: letterFilter,
    excludeIndustries: true,
    headers: {
      Prefer: 'count=exact',
    },
    query: {
      [GEOGRAPHY_FIELD_NAME]: `eq.${geography}`,
    },
    fields: [...CompanyResource.requiredFields, 'healthBadge', 'industries'],
    limit: PAGE_SIZE,
    offset: PAGE_SIZE * (currentPage - 1),
    orderBy,
  }
  let companiesResponse
  try {
    companiesResponse = yield yield* fetchCompanies(requestOptions)
  } catch (error) {
    if (error.status !== 416) {
      throw error
    }
    // Get the last page of results.
    requestOptions.orderBy[0].direction =
      tableSort.direction === SORT_DIRECTIONS.ASC
        ? SORT_DIRECTIONS.DESC
        : SORT_DIRECTIONS.ASC
    requestOptions.offset = 0
    companiesResponse = yield yield* fetchCompanies(requestOptions)
  }

  const companyCount = parseInt(
    companiesResponse.headers['content-range'].split('/')[1],
    10,
  )
  yield put(actions.setTotalCompanyCount(companyCount))
  yield put(actions.setCompanyIds(companiesResponse.result))

  yield put(actions.setIsLoading(false))
}

const companiesChangeActions = [
  actions.setSort,
  actions.setNameFilter,
  actions.setLetterFilter,
  actions.setGeography,
  actions.setMyCompaniesFilter,
  actions.setPage,
]

export default function* companiesPageSaga() {
  yield all([
    takeLatest(LOCATION_CHANGED, locationChanged),
    takeLatest(actions.setMyCompaniesFilter, ensureValidPage),
    takeLatest(companiesChangeActions, fetchCurrentCompanies),
  ])
}
