import invariant from 'invariant'

import {FACTORS_BY_SUBFACTOR, SUBFACTORS_BY_ID} from 'app/constants'
import {arrayOf, field, foreignKey, hasMany} from 'app/framework/fields'
import Model from 'app/framework/Model'

import Company from './Company'
import Industry from './Industry'
import Storyline from './Storyline'

export default class Story extends Model {
  static entityKey = 'stories'

  static fields = {
    storyline: foreignKey(Storyline, {required: false}),
    company: foreignKey(Company, {required: false}),
    articleCount: field(),
    summary: field(),
    date: field(),
    valence: field(),
    subfactorId: field(),
    isLitigation: field(),
    isRumor: field(),
    isOpinion: field(),
    perspectiveId: field(),
  }

  static indexes = ['storyline', 'company']

  get subfactor() {
    const subfactor = SUBFACTORS_BY_ID[this.subfactorId]
    invariant(
      subfactor,
      `The story with ID ${this.id} has an invalid subfactorId of '${
        this.subfactorId
      }'.`,
    )
    return subfactor
  }

  get factor() {
    const factor = FACTORS_BY_SUBFACTOR[this.subfactor]
    invariant(
      factor,
      `The story with ID ${this.id} has a subfactorId of '${
        this.subfactorId
      }', which does not match any factors.`,
    )
    return factor
  }
}
Company.fields.stories = hasMany(Story, {required: false})
Company.fields.topStories = arrayOf(foreignKey(Story, {required: false}))
Industry.fields.topStories = arrayOf(foreignKey(Story, {required: false}))
Storyline.fields.stories = hasMany(Story, {required: false})
