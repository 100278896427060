import invariant from 'invariant'

/**
 * A subclass of the built-in Map type that sets and returns some default value
 * on a `get` call if the key is not present. Works similarly to Python's
 * defaultdict.
 *
 * Example:
 *
 *   const map = new DefaultMap(() => [])
 *   map.get('a') // []
 *   map.get('b').push(1)
 *   map.get('b') // [1]
 */
export class DefaultMap extends Map {
  constructor(initialValueOrDefaultFactory, defaultFactory) {
    if (initialValueOrDefaultFactory && defaultFactory) {
      super(initialValueOrDefaultFactory)
    } else {
      defaultFactory = initialValueOrDefaultFactory
      super()
    }
    invariant(
      typeof defaultFactory === 'function',
      'Invalid constructor argument to DefaultMap. Expected a factory function.',
    )
    this._defaultFactory = defaultFactory
  }

  get(key) {
    if (!super.has(key)) {
      super.set(key, this._defaultFactory())
    }
    return super.get(key)
  }
}
