import PropTypes from 'prop-types'
import React from 'react'

import InfoTooltip from 'app/common/InfoTooltip'
import SubfactorHealthCard from 'app/reusable/health/subfactor-health-card'

const SubfactorHealthBadgeTooltip = ({
  left,
  right,
  top,
  healthData,
  subfactor,
}) => (
  <InfoTooltip left={left} right={right} top={top}>
    <SubfactorHealthCard healthData={healthData} subfactor={subfactor} />
  </InfoTooltip>
)
SubfactorHealthBadgeTooltip.propTypes = {
  left: PropTypes.number,
  right: PropTypes.number,
  top: PropTypes.number,
  healthData: PropTypes.object.isRequired,
  subfactor: PropTypes.string.isRequired,
}
export default SubfactorHealthBadgeTooltip
