import {applyMiddleware, createStore} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import {routerForBrowser} from 'redux-little-router'
import createSagaMiddleware from 'redux-saga'

import {ROOT_PATH} from 'app/constants'
import {logError} from 'app/error-tracking'
import {setError} from 'app/global/global-actions'

import rootReducer from './root-reducer'
import rootSaga from './root-saga'
import routes from './routes'

// Build a routes object that redux-little-router likes.
const routesObject = Object.entries(routes).reduce((obj, [key, value]) => {
  obj[value] = {}
  return obj
}, {})
const router = routerForBrowser({routes: routesObject, basename: ROOT_PATH})

const createReducer = rootReducer => (state = {}, action) => ({
  ...rootReducer(state, action),
  router: router.reducer(state.router, action),
})

const sagaMiddleware = createSagaMiddleware({
  onError: (error, {sagaStack}) => {
    if (error.status && [401, 403, 416].includes(error.status)) {
      // Ignore 401s, 403s and 416s because they are handled by a saga.
      return
    }
    console.error(error)
    if (sagaStack) {
      console.error(sagaStack)
    }
    store.dispatch(setError(error))
    logError(error)
  },
})
const store = createStore(
  createReducer(rootReducer),
  composeWithDevTools(
    router.enhancer,
    applyMiddleware(router.middleware, sagaMiddleware),
  ),
)
let sagaTask = sagaMiddleware.run(rootSaga)

// Replace reducers via hot module reloading.
if (module.hot) {
  module.hot.accept('app/root-reducer', () => {
    const rootReducer = require('app/root-reducer').default
    store.replaceReducer(createReducer(rootReducer))
  })

  module.hot.accept('app/root-saga', () => {
    const rootSaga = require('app/root-saga').default
    sagaTask.cancel()
    sagaTask.toPromise().then(() => {
      sagaTask = sagaMiddleware.run(rootSaga)
    })
  })
}

export default store
