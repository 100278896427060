import {handleActions} from 'redux-actions'
import {LOCATION_CHANGED} from 'redux-little-router'

import {
  getInitialSharedState,
  getSelectedFactor,
  getSelectedSubfactor,
  getSelectedTimeFrame,
  getSelectedGeography,
  highlightFactorReducer,
  clearHighlightedFactorReducer,
  highlightSubfactorReducer,
  clearHighlightedSubfactorReducer,
  showHealthBadgeTooltipReducer,
  hideHealthBadgeTooltipReducer,
  setStoryIdsReducer,
  selectValenceReducer,
  deselectValenceReducer,
  setStorySortOrderReducer,
} from '../common/overview-reducer-helpers'
import * as actions from './industry-overview-actions'
import {INDUSTRY_OVERVIEW_ROUTES} from './industry-overview-constants'

const getInitialState = () => ({
  ...getInitialSharedState(),
  industryId: null,
  allCompanyIds: [],
  subIndustryIds: [],
  sidebarCompanyIds: [],
  totalCompanyCount: 0,
  isLoading: true,

  // Overview
  shouldShowOnlyMyCompanies: false,
  selectedCompanyId: null,
  shouldShowOnlyIndustryNews: false,
})

export default handleActions(
  {
    [LOCATION_CHANGED]: (state, action) => {
      const initialState = getInitialState()
      if (INDUSTRY_OVERVIEW_ROUTES.includes(action.payload.route)) {
        const selectedGeography = getSelectedGeography(action)
        return {
          ...state,
          industryId: action.payload.params.industryId,
          selectedFactor:
            getSelectedFactor(action) || initialState.selectedFactor,
          selectedSubfactor:
            getSelectedSubfactor(action) || initialState.selectedSubfactor,
          timeFrameDays:
            getSelectedTimeFrame(action) || initialState.timeFrameDays,
          selectedGeography:
            selectedGeography === null
              ? initialState.selectedGeography
              : selectedGeography,

          // Reset these so they don't persist between page transitions.
          highlightedFactor: initialState.highlightedFactor,
          highlightedSubfactor: initialState.highlightedSubfactor,
          healthBadgeTooltip: initialState.healthBadgeTooltip,
        }
      }
      return initialState
    },
    [actions.setIsLoading]: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    [actions.setAllCompanyIds]: (state, action) => ({
      ...state,
      allCompanyIds: action.payload,
    }),
    [actions.setSubIndustryIds]: (state, action) => ({
      ...state,
      subIndustryIds: action.payload,
    }),
    [actions.setSidebarCompanyIds]: (state, action) => ({
      ...state,
      sidebarCompanyIds: action.payload,
    }),
    [actions.setTotalCompanyCount]: (state, action) => ({
      ...state,
      totalCompanyCount: action.payload,
    }),
    [actions.setStoryIds]: setStoryIdsReducer,
    [actions.selectValence]: selectValenceReducer,
    [actions.deselectValence]: deselectValenceReducer,
    [actions.setStorySortOrder]: setStorySortOrderReducer,
    [actions.setShowOnlyMyCompanies]: (state, action) => ({
      ...state,
      shouldShowOnlyMyCompanies: action.payload,
    }),
    [actions.selectCompany]: (state, action) => ({
      ...state,
      selectedCompanyId: action.payload,
    }),
    [actions.clearSelectedCompany]: (state, action) => ({
      ...state,
      selectedCompanyId: null,
    }),
    [actions.setShowOnlyIndustryNews]: (state, action) => ({
      ...state,
      shouldShowOnlyIndustryNews: action.payload,
    }),

    [actions.showFiltersSaveModal]: (state, action) => ({
      ...state,
      shouldShowFiltersSaveModal: true,
    }),
    [actions.hideFiltersSaveModal]: (state, action) => ({
      ...state,
      shouldShowFiltersSaveModal: false,
    }),

    // Common
    [actions.highlightFactor]: highlightFactorReducer,
    [actions.clearHighlightedFactor]: clearHighlightedFactorReducer,
    [actions.highlightSubfactor]: highlightSubfactorReducer,
    [actions.clearHighlightedSubfactor]: clearHighlightedSubfactorReducer,
    [actions.showHealthBadgeTooltip]: showHealthBadgeTooltipReducer,
    [actions.hideHealthBadgeTooltip]: hideHealthBadgeTooltipReducer,
  },
  getInitialState(),
)
