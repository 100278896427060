import classNames from 'classnames'
import dateFns from 'date-fns'
import PropTypes from 'prop-types'
import {map, pipe, reduce, unnest} from 'ramda'
import React, { useEffect } from 'react'

import ArticleLink from 'app/common/ArticleLink'
import Button from 'app/common/Button'
import Columns, {Column} from 'app/common/Columns'
import FactorIcon from 'app/common/FactorIcon'
import HealthScore from 'app/common/HealthScore'
import {LoadingMessage} from 'app/common/loading-message'
import ManzamaLogo from 'app/common/logos/manzama-logo'
import Link from 'app/common/NavigationLink'
import InsightSummary from 'app/common/insight-summary'
import SizedContainer from 'app/common/SizedContainer'
import TextSeparator from 'app/common/TextSeparator'
import {ORDERED_FACTORS, today, VALENCES} from 'app/constants'
import CompanyRangeChart from 'app/reusable/companies/company-range-chart'
import FactorHealthBadge from 'app/reusable/health/health-badge/factor'
import HealthTrend from 'app/reusable/health/health-trend'
import * as strings from 'app/strings'
import urls, {toAbsoluteUrl} from 'app/urls'
import {getExcerpt} from 'app/utils/article'

import * as styles from './OverviewReport.less'

export default function OverviewReport({
  isLoading,
  entity,
  entityTypeName,
  storiesByFactor,
  storyCount,
  storylineCount = null,
  timeFrameDays,

  isDownloadingPdf,
  downloadPdf,
}) {
  return isLoading ? (
    <div className={styles.report}>
      <LoadingMessage />
    </div>
  ) : (
    <OverviewReportContents
      entity={entity}
      entityTypeName={entityTypeName}
      storiesByFactor={storiesByFactor}
      storyCount={storyCount}
      storylineCount={storylineCount}
      timeFrameDays={timeFrameDays}
      isDownloadingPdf={isDownloadingPdf}
      downloadPdf={downloadPdf}
    />
  )
}
OverviewReport.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  entity: PropTypes.object,
  entityTypeName: PropTypes.string.isRequired,
  storiesByFactor: PropTypes.object,
  storyCount: PropTypes.number,
  storylineCount: PropTypes.number,
  timeFrameDays: PropTypes.number.isRequired,

  isDownloadingPdf: PropTypes.bool.isRequired,
  downloadPdf: PropTypes.func.isRequired,
}

function OverviewReportContents({
  entity,
  entityTypeName,
  storiesByFactor,
  storyCount,
  storylineCount,
  timeFrameDays,

  isDownloadingPdf,
  downloadPdf,
}) {
  const startDate = dateFns.subDays(today, timeFrameDays - 1)
  const makeOverflowVisible = () => document.getElementById("report").style.overflowY="visible" 
  const makeOverflowScrollable = () => document.getElementById("report").style.overflowY="auto" 
  const downloadDocument = () => {
    makeOverflowVisible()
    downloadPdf()
    setTimeout(() => {
      makeOverflowScrollable()
    }, 100);
  }
  const printDocument = () => window.print()
  useEffect(() => {
    window.onbeforeprint = makeOverflowVisible
    window.onafterprint = makeOverflowScrollable
    return () => {
      window.onbeforeprint = null
      window.onafterprint = null
    }
  }, [])
  return (
    <>
      <div className={styles.topBar}>
        <div className={styles.inner}>
          <div className={styles.label}>
            {process.env.BRAND_NAME} {process.env.PRODUCT_NAME} {entityTypeName}{' '}
            Report
          </div>

          <div className={styles.buttons}>
            <Button
              label="Print Report"
              isPrimary={true}
              onClick={printDocument}
            />
            <Button
              label={isDownloadingPdf ? 'Downloading...' : 'Download PDF'}
              isPrimary={true}
              isDisabled={isDownloadingPdf}
              onClick={downloadDocument}
            />
          </div>
        </div>
      </div>

      <div id="report" className={styles.reportContainer}>
        <div className={styles.report}>
          <div className={styles.header}>
            <div className={styles.text}>
              <h1 className={styles.title}>
                {entity.name} {entityTypeName} Report
              </h1>
              <h2 className={styles.dateRange}>
                {strings.formatDateRange(startDate, today)}
              </h2>
            </div>

            <ManzamaLogo
              className={classNames(
                styles.logo,
                styles[process.env.MANZAMA_BRAND],
              )}
            />
          </div>

          <div className={styles.body}>
            <Columns className={styles.columns}>
              <Column className={styles.healthBadgeColumn}>
                <Section
                  label={`Current 30-Day ${entityTypeName} Health Score`}
                  className={styles.healthSection}
                >
                  <FactorHealthBadge
                    healthData={entity.healthData}
                    className={styles.healthBadge}
                  />
                </Section>
              </Column>

              <Column className={styles.summaryColumn}>
                <div className={styles.volumeSummary}>
                  <div className={styles.counts}>
                    {entity.companyCount && (
                      <div className={styles.count}>
                        <div className={styles.count}>
                          {strings.formatNumber(entity.companyCount)}
                        </div>
                        <div className={styles.label}>Companies</div>
                      </div>
                    )}

                    <div className={styles.count}>
                      <div className={styles.count}>
                        {strings.formatNumber(entity.healthData.articleVolume)}
                      </div>
                      <div className={styles.label}>
                        {process.env.PRODUCT_NAME} Articles
                      </div>
                    </div>

                    <div className={styles.count}>
                      <div className={styles.count}>
                        {strings.formatNumber(storyCount)}
                      </div>
                      <div className={styles.label}>Stories</div>
                    </div>

                    {storylineCount !== null && (
                      <div className={styles.count}>
                        <div className={styles.count}>
                          {strings.formatNumber(storylineCount)}
                        </div>
                        <div className={styles.label}>Storylines</div>
                      </div>
                    )}
                  </div>

                  <div className={styles.charts}>
                    <Section label={`${timeFrameDays}-Day Range`}>
                      <div className={styles.rangeChart}>
                        <CompanyRangeChart company={entity} />
                      </div>
                    </Section>

                    <Section label={`${timeFrameDays}-Day Trend`}>
                      <div className={styles.chart}>
                        <HealthTrend
                          healthData={entity.healthData}
                          dailyHealthData={entity.dailyHealthData}
                          timeFrameDays={timeFrameDays}
                        />
                      </div>
                    </Section>
                  </div>
                </div>
              </Column>
            </Columns>

            <Section
              label={`${timeFrameDays}-Day ${
                process.env.PRODUCT_NAME
              } Volume Summary`}
            >
              <FactorVolumeSummary entity={entity} />
            </Section>

            <div className={styles.topStories}>
              <TopStories storiesByFactor={storiesByFactor} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
OverviewReportContents.propTypes = {
  entity: PropTypes.object.isRequired,
  entityTypeName: PropTypes.string.isRequired,
  storiesByFactor: PropTypes.object.isRequired,
  storyCount: PropTypes.number.isRequired,
  storylineCount: PropTypes.number,
  timeFrameDays: PropTypes.number.isRequired,

  isDownloadingPdf: PropTypes.bool.isRequired,
  downloadPdf: PropTypes.func.isRequired,
}

function Section({label, className, children}) {
  return (
    <div className={classNames(styles.section, className)}>
      {label && <span className={styles.sectionLabel}>{label}</span>}
      <div className={styles.content}>{children}</div>
    </div>
  )
}
Section.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
}

function FactorVolumeSummary({entity}) {
  const {healthData} = entity

  const maxVolume = pipe(
    map(factor =>
      map(
        valence => (healthData.factors[factor] || {})[`${valence}Volume`] || 0,
        ['positive', 'neutral', 'negative'],
      ),
    ),
    unnest,
    reduce(Math.max, 0),
  )(ORDERED_FACTORS)

  const rows = ORDERED_FACTORS.map(factor => {
    const factorData = healthData.factors[factor]

    const health = factorData ? (
      <div className={styles.health}>
        <HealthScore score={factorData.healthScore} className={styles.score} />
        Factor Health
      </div>
    ) : (
      <div className={classNames(styles.health, styles.noData)}>
        No Articles for This Factor
      </div>
    )

    const bars = [VALENCES.POSITIVE, VALENCES.NEUTRAL, VALENCES.NEGATIVE].map(
      valence => {
        const valenceClassName = {
          [VALENCES.POSITIVE]: 'positive',
          [VALENCES.NEUTRAL]: 'neutral',
          [VALENCES.NEGATIVE]: 'negative',
        }[valence]
        const factorVolume = factorData
          ? factorData[`${valenceClassName}Volume`]
          : 0
        const width = (factorVolume / maxVolume) * 95
        const barLabel =
          factorVolume > 0 ? (
            <text className={styles.barLabel} x={`${width + 1}%`} y="90%">
              {factorVolume}
            </text>
          ) : null
        return (
          <svg className={styles.barContainer} key={valence}>
            <rect
              className={styles.backgroundBar}
              x={0}
              y={0}
              width="100%"
              height="100%"
            />
            <rect
              className={classNames(styles.bar, styles[valenceClassName])}
              x={0}
              y={0}
              width={`${width}%`}
              height="100%"
            />
            {barLabel}
          </svg>
        )
      },
    )

    return (
      <div className={styles.row} key={factor}>
        <div className={styles.factorLabel}>
          <div className={styles.factor}>
            {strings.factorDisplayName(factor)}
          </div>
          {health}
        </div>

        <FactorIcon factor={factor} className={styles.factorIcon} />

        <div className={styles.bars}>{bars}</div>
      </div>
    )
  })

  return <div className={styles.factorVolumeSummary}>{rows}</div>
}

function TopStories({storiesByFactor}) {
  return ORDERED_FACTORS.map(factor => {
    const stories = storiesByFactor[factor]
    if (!stories || !stories.length) return null
    return (
      <TopStoriesForFactor stories={stories} factor={factor} key={factor} />
    )
  })
}
TopStories.propTypes = {
  storiesByFactor: PropTypes.object.isRequired,
}

const TopStoriesForFactor = ({factor, stories}) => (
  <div className={styles.storiesForFactor}>
    <div className={styles.firstStoryContainer}>
      <div className={styles.factorLabel}>
        <FactorIcon factor={factor} className={styles.icon} />
        <span className={styles.factorName}>
          {strings.factorDisplayName(factor)}
        </span>
        <TextSeparator />
        <span className={styles.description}>
          Top {stories.length > 1 && stories.length} {process.env.PRODUCT_NAME}{' '}
          {stories.length > 1 ? 'Stories' : 'Story'} for{' '}
          {strings.factorDisplayName(factor)}
        </span>
      </div>

      {/*
        The first story shares a container with the factor label so that they
        don't get put on separate pages when printing.
      */}
      <StoryCardPrintable story={stories[0]} />
    </div>

    {stories.slice(1).map(story => (
      <StoryCardPrintable story={story} key={story.id} />
    ))}
  </div>
)
TopStoriesForFactor.propTypes = {
  factor: PropTypes.string.isRequired,
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
}

function InsightSummaryPrintable({factor, subfactor, valence, className}) {
  const valenceClassName = {
    [VALENCES.POSITIVE]: 'positive',
    [VALENCES.NEUTRAL]: 'neutral',
    [VALENCES.NEGATIVE]: 'negative',
  }[valence]
  return (
    <div className={styles.signalSummaryContainer}>
      <div className={styles.background}>
        <SizedContainer
          render={(width, height) => (
            <svg width={width} height={height}>
              <rect
                className={styles.backgroundColor}
                x={height / 2}
                y={0}
                width={width - height}
                height={height}
              />
              <circle
                className={classNames(
                  styles.backgroundColor,
                  styles[valenceClassName],
                )}
                cx={height / 2}
                cy={height / 2}
                r={Math.floor(height / 2)}
              />
              <circle
                className={styles.backgroundColor}
                cx={width - height / 2}
                cy={height / 2}
                r={height / 2}
              />
            </svg>
          )}
        />
      </div>
      <InsightSummary
        factor={factor}
        subfactor={subfactor}
        valence={valence}
        className={className}
      />
    </div>
  )
}
InsightSummaryPrintable.propTypes = {
  factor: PropTypes.string.isRequired,
  subfactor: PropTypes.string.isRequired,
  valence: PropTypes.string.isRequired,
  className: PropTypes.string,
}

const StoryCardPrintable = ({story, className}) => {
  const article = story.topArticle
  const {storyline} = story
  const storylineDayCount =
    dateFns.differenceInDays(storyline.endDate, storyline.startDate) + 1
  return (
    <div className={classNames(styles.storyCard, className)}>
      <div className={classNames(styles.companyName, 'company-name')}>
        <span className={styles.text}>{story.company.name}</span>
        <InsightSummaryPrintable
          factor={story.factor}
          subfactor={story.subfactor}
          valence={story.valence}
          className={styles.signalSummary}
        />
      </div>

      <div className={styles.content}>
        <div className={styles.article}>
          <div className={styles.body}>
            <div className={styles.articleSection}>
              <div className={classNames(styles.headline, 'headline')}>
                <ArticleLink article={article} shouldTargetNewWindow={true}>
                  {story.summary}
                </ArticleLink>
              </div>

              <div className={classNames(styles.excerpt, 'excerpt')}>
                {getExcerpt(article.description)}
              </div>
            </div>
          </div>

          <div className={styles.meta}>
            <div className={classNames(styles.date, 'date')}>
              {strings.formatDate(article.date)}{' '}
              {article.feedName && (
                <span className={classNames(styles.feed, 'feed')}>
                  {strings.viaSource(article)}
                </span>
              )}
            </div>

            <div className={styles.articleCount}>
              {story.articleCount} Article
              {story.articleCount > 1 && 's'} in this Story
            </div>
          </div>
        </div>

        <div className={styles.storyline}>
          <div className={classNames(styles.fromStoryline, 'from-storyline')}>
            Part of Storyline:{' '}
            <Link
              // The links have to be absolute for the PDF report.
              href={toAbsoluteUrl(urls.storylineView(story.storylineId))}
              className="storyline-link"
            >
              &ldquo;
              {storyline.summary}
              &rdquo;
            </Link>
          </div>

          <div className={classNames(styles.articleCount, 'article-count')}>
            {strings.articleCount(storyline.articleCount)} over{' '}
            {storylineDayCount} day
            {storylineDayCount > 1 ? 's' : ''}{' '}
            {strings.dateRangeSuffix(storyline.startDate, storyline.endDate)}
          </div>
        </div>
      </div>
    </div>
  )
}
StoryCardPrintable.propTypes = {
  story: PropTypes.object.isRequired,
  className: PropTypes.string,
  companyNameClassName: PropTypes.string,
}
