import Promise from 'bluebird'
import {pyappRequest} from 'app/api'
import urls from 'app/urls'

export function saveChart(token, data) {
  if (MOCK_BASE_API) {
    return new Promise(resolve => resolve({body: {}}))
  }
  return pyappRequest({
    url: urls.pyapp.api.saveChartImage(),
    method: 'PUT',
    data: {
      chartFile: data.chartFile,
      chartName: data.chartName,
    },
    token,
  })
}
