import classNames from 'classnames'
import React from 'react'

import InlineSvg from 'app/common/InlineSvg'

import * as styles from './FilterIcon.less'
import iconUrl from 'static/images/filter.svg'

const FilterIcon = ({className, ...restProps}) => (
  <InlineSvg
    src={iconUrl}
    className={classNames(styles.icon, className)}
    {...restProps}
  />
)
export default FilterIcon
