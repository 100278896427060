import {handleActions} from 'redux-actions'

import * as actions from './add-entities-modal-actions'
import {STEP} from './add-entities-modal-constants'

const getInitialState = () => ({
  isVisible: false,
  entityType: 'company',
  isLoading: false,
  step: STEP.SEARCH,
  error: null,
  selectedEntityIds: [],
})

export default handleActions(
  {
    [actions.show]: (state, action) => ({
      ...getInitialState(),
      isVisible: true,
      entityType: action.payload.entityType,
    }),
    [actions.hide]: getInitialState,
    [actions.setSelectedEntityIds]: (state, action) => ({
      ...state,
      selectedEntityIds: action.payload,
    }),
    [actions.setIsLoading]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [actions.setIsAdded]: (state, action) => ({
      ...state,
      isLoading: false,
      step: STEP.EMAIL_SETTINGS,
    }),
    [actions.setIsFinished]: (state, action) => ({
      ...state,
      isLoading: false,
      step: STEP.FINISHED,
    }),
    [actions.setError]: (state, action) => ({
      ...state,
      isLoading: false,
      step: STEP.FINISHED,
      error: action.payload,
    }),
  },
  getInitialState(),
)
