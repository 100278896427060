import PropTypes from 'prop-types'
import React from 'react'

import {STATIC_FILES_URL} from 'app/constants'
import {calculateHealthRange} from 'app/utils/health'
import Cell from 'email-server-common/common/Cell'
import HealthScore from 'email-server-common/common/HealthScore'
import Link from 'email-server-common/common/Link'
import PaddedBlock from 'email-server-common/common/PaddedBlock'
import Row from 'email-server-common/common/Row'
import Table from 'email-server-common/common/Table'
import {paddingAmount, spacingAmount} from 'email-server/style/constants'
import {lineColorLight} from 'email-server/style/palette'
import {tableCellStyle, tableHeaderCellStyle} from 'email-server/style/styles'
import {healthColorNeutral} from 'email-server/style/palette'

import Section from './Section'

const numericHeaderCellStyle = {
  ...tableHeaderCellStyle,
  textAlign: 'right',
}
const numericCellStyle = {
  ...tableCellStyle,
  textAlign: 'right',
}

const TableOfContentsRow = ({
  entity,
  entityType,
  shouldShowHealthRange,
  key,
}) => {
  const healthData = entity.healthData || {}
  const healthRange = entity.healthData ? calculateHealthRange(entity) : null
  return (
    <Row key={key}>
      <Cell width="50%" style={tableCellStyle}>
        <Link href={`#${entityType}${entity.id}`}>{entity.name}</Link>
      </Cell>
      <Cell style={numericCellStyle}>
        <HealthScore score={healthData.healthScore} />
      </Cell>
      {shouldShowHealthRange && (
        <Cell style={numericCellStyle}>
          {healthRange ? (
            <React.Fragment>
              <HealthScore score={healthRange.min} /> to{' '}
              <HealthScore score={healthRange.max} />
            </React.Fragment>
          ) : (
            <span style={{color: healthColorNeutral}}>--</span>
          )}
        </Cell>
      )}
    </Row>
  )
}
TableOfContentsRow.propTypes = {
  entity: PropTypes.object.isRequired,
  entityType: PropTypes.string.isRequired,
  shouldShowHealthRange: PropTypes.bool.isRequired,
}

const TableOfContentsTable = ({
  entities,
  entityName,
  timeFrameDays,
  shouldShowHealthRange,
}) => (
  <PaddedBlock bottom={spacingAmount}>
    <Table>
      <Row style={{fontWeight: 'bold'}}>
        <Cell style={tableHeaderCellStyle}>{entityName}</Cell>
        <Cell style={numericHeaderCellStyle}>Health Score</Cell>
        {shouldShowHealthRange && (
          <Cell style={numericHeaderCellStyle}>Health Range</Cell>
        )}
      </Row>
      {/*
        Annoyingly, this can't be used as a component because of how Table is
        currently implemented.
      */}
      {entities.map(entity =>
        TableOfContentsRow({
          entity,
          entityType: entityName.toLowerCase(),
          shouldShowHealthRange: shouldShowHealthRange,
          key: entity.id,
        }),
      )}
    </Table>
  </PaddedBlock>
)
TableOfContentsTable.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.object).isRequired,
  entityName: PropTypes.string.isRequired,
  timeFrameDays: PropTypes.number.isRequired,
  shouldShowHealthRange: PropTypes.bool.isRequired,
}

const TableOfContents = ({companies, industries, timeFrameDays}) => {
  const shouldShowHealthRange = timeFrameDays > 1
  return (
    <PaddedBlock padding={spacingAmount}>
      <PaddedBlock bottom={paddingAmount} style={{fontSize: 16}}>
        Table of Contents
      </PaddedBlock>
      {companies.length > 0 && (
        <TableOfContentsTable
          entities={companies}
          entityName="Company"
          timeFrameDays={timeFrameDays}
          shouldShowHealthRange={shouldShowHealthRange}
        />
      )}
      {industries.length > 0 && (
        <TableOfContentsTable
          entities={industries}
          entityName="Industry"
          timeFrameDays={timeFrameDays}
          shouldShowHealthRange={shouldShowHealthRange}
        />
      )}
    </PaddedBlock>
  )
}
TableOfContents.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  industries: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  timeFrameDays: PropTypes.number.isRequired,
}

const LegendFactorIcon = ({factor, title}) => (
  <img
    src={`${STATIC_FILES_URL}/imgs/signals-icons/${factor}-neu.png`}
    width={20}
    title={title}
    alt={title}
  />
)

const Legend = ({productName}) => {
  const imageSpacing = 10
  return (
    <Table>
      <Row>
        <Cell
          style={{
            borderBottom: `1px solid ${lineColorLight}`,
            padding: spacingAmount,
          }}
        >
          {productName} identifies impactful events happening to a company
          within a given factor.
          <PaddedBlock top={paddingAmount}>
            <Table>
              <Cell valign="middle" style={{paddingRight: imageSpacing}}>
                <LegendFactorIcon factor="execs" title="Management" />
              </Cell>
              <Cell valign="middle" style={{paddingRight: imageSpacing}}>
                <LegendFactorIcon factor="finance" title="Finance" />
              </Cell>
              <Cell valign="middle" style={{paddingRight: imageSpacing}}>
                <LegendFactorIcon factor="labor" title="Operations" />
              </Cell>
              <Cell valign="middle" style={{paddingRight: imageSpacing}}>
                <LegendFactorIcon
                  factor="product"
                  title="Products &amp; Services"
                />
              </Cell>
              <Cell valign="middle" style={{paddingRight: imageSpacing}}>
                <LegendFactorIcon
                  factor="deals"
                  title="Partners &amp; Competitors"
                />
              </Cell>
              <Cell valign="middle">
                <LegendFactorIcon factor="government" title="Government" />
              </Cell>
            </Table>
          </PaddedBlock>
        </Cell>
      </Row>

      <Row>
        <Cell style={{padding: spacingAmount}}>
          {/* TODO: Replace this with a dynamic tip somehow? */}
          TIP: Click "View {productName} Context" from any article to see how
          that article relates to its storyline.
        </Cell>
      </Row>
    </Table>
  )
}
Legend.propTypes = {
  productName: PropTypes.string.isRequired,
}

const TopSection = ({companies, industries, timeFrameDays, productName}) => (
  <Section>
    <Table>
      <Cell align="left" valign="top">
        <TableOfContents
          companies={companies}
          industries={industries}
          timeFrameDays={timeFrameDays}
        />
      </Cell>
      <Cell
        align="right"
        valign="top"
        width={210}
        style={{borderLeft: `1px solid ${lineColorLight}`}}
      >
        <Legend productName={productName} />
      </Cell>
    </Table>
  </Section>
)
TopSection.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  industries: PropTypes.arrayOf(PropTypes.object).isRequired,
  timeFrameDays: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
}
export default TopSection
