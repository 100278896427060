import React from 'react'
import { trueRound } from '../../app/utils/numbers'
import { colors } from './constants'

export default function EsgScore({ score, signed = true }) {
  let color
  let signedScore
  if (trueRound(score) === 0) {
    color = colors.defaultFontColor
    signedScore = `${trueRound(score)}`
  } else if (trueRound(score) < 0) {
    color = '#D64933'
    signedScore = `${trueRound(score)}`
  } else {
    color = '#2F3B4B'
    if (signed){
      signedScore = `+${trueRound(score)}`
    }else{
      signedScore = `${trueRound(score)}`
    }
  }
  return (
    <span style={{ color, fontWeight: 900 }}>{signedScore}</span>
  )
}
