import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import React from 'react'
import {filter, map, pipe, prop, reverse, sortBy, values, unnest} from 'ramda'

import {ORDERED_SUBFACTORS_BY_FACTOR} from 'app/constants'
import * as strings from 'app/strings'

import HealthBadge from '../'
import SubfactorHealthBadgeTooltip from './tooltip'
import {localComponent} from 'app/utils/redux'
import * as actions from './subfactor-health-badge-actions'
import * as constants from './subfactor-health-badge-constants'
import * as selectors from './subfactor-health-badge-selectors'

class SubfactorHealthBadge extends React.PureComponent {
  render() {
    const {
      healthData,
      factor,
      selectedSubfactor,
      highlightedSubfactor,

      tooltipInfo,

      className,
      style,
    } = this.props

    const actualHighlightedSubfactor = highlightedSubfactor || selectedSubfactor

    const factorHealthData = healthData.factors[factor] || {}
    const segments = pipe(
      map(isPositive => {
        return pipe(
          values,
          map(subfactor => ({
            id: subfactor,
            label: strings.subfactorDisplayName(subfactor),
            value: healthData.subfactors[subfactor]
              ? isPositive
                ? healthData.subfactors[subfactor].positiveVolume
                : healthData.subfactors[subfactor].negativeVolume
              : 0,
            isPositive,
            isHighlighted: subfactor === actualHighlightedSubfactor,
          })),
          filter(value => value.value > 0),
          sortBy(prop('value')),
          reverse,
        )(ORDERED_SUBFACTORS_BY_FACTOR[factor])
      }),
      unnest,
    )([false, true])
    const valueTotal =
      Math.max(
        factorHealthData.positiveVolume,
        factorHealthData.negativeVolume,
      ) +
      factorHealthData.neutralVolume / 2
    const healthScore = actualHighlightedSubfactor
      ? healthData.subfactors[actualHighlightedSubfactor]
        ? healthData.subfactors[actualHighlightedSubfactor].healthScore
        : 0
      : factorHealthData.healthScore || 0

    const tooltip = tooltipInfo.isVisible && (
      <SubfactorHealthBadgeTooltip
        left={tooltipInfo.left}
        right={tooltipInfo.right}
        top={tooltipInfo.top}
        subfactor={tooltipInfo.subfactor}
        healthData={healthData}
      />
    )

    return (
      <React.Fragment>
        <HealthBadge
          segments={segments}
          valueTotal={valueTotal}
          healthScore={healthScore}
          healthLabel={
            actualHighlightedSubfactor
              ? strings.subfactorDisplayName(actualHighlightedSubfactor)
              : strings.factorDisplayName(factor)
          }
          onSectionMouseOver={bind(this.onSubfactorMouseOver, this)}
          onSectionMouseOut={bind(this.onSubfactorMouseOut, this)}
          onSectionClick={bind(this.onSubfactorClick, this)}
          className={className}
          style={style}
        />

        {tooltip}
      </React.Fragment>
    )
  }

  onSubfactorMouseOver(subfactor, event) {
    this.props.highlightSubfactor(subfactor)
    this.showTooltip({event, subfactor})
    if (this.props.onSubfactorMouseOver) {
      this.props.onSubfactorMouseOver(subfactor)
    }
  }

  onSubfactorMouseOut() {
    this.props.clearHighlightedSubfactor()
    this.props.hideTooltip()
    if (this.props.onSubfactorMouseOut) {
      this.props.onSubfactorMouseOut(factor)
    }
  }

  onSubfactorClick(subfactor) {
    this.props.hideTooltip()
    if (this.props.onSubfactorClick) {
      this.props.onSubfactorClick(subfactor)
    }
  }

  showTooltip({event, subfactor}) {
    const topOffset = 20
    const el = event.target
    const rect = el.getBoundingClientRect()
    const docElement = document.documentElement
    const top = Math.round(rect.top + docElement.scrollTop) - topOffset
    const left = Math.round(rect.right + docElement.scrollLeft) + 20
    const right = Math.round(rect.left + docElement.scrollLeft) - 20
    this.props.showTooltip({
      top,
      left,
      right,
      subfactor,
    })
  }
}
SubfactorHealthBadge.propTypes = {
  healthData: PropTypes.object.isRequired,
  factor: PropTypes.string.isRequired,
  selectedSubfactor: PropTypes.string,
  highlightedSubfactor: PropTypes.string,
  onSubfactorMouseOver: PropTypes.func,
  onSubfactorMouseOut: PropTypes.func,
  onSubfactorClick: PropTypes.func,

  // From state
  tooltipInfo: PropTypes.object.isRequired,

  // Actions
  highlightSubfactor: PropTypes.func.isRequired,
  clearHighlightedSubfactor: PropTypes.func.isRequired,
  showTooltip: PropTypes.func.isRequired,
  hideTooltip: PropTypes.func.isRequired,

  // Element attributes
  className: PropTypes.string,
  style: PropTypes.object,
}

export default localComponent({
  key: constants.STATE_PROP_NAME,
  initAction: actions.init,
  deinitAction: actions.deinit,
  actions: {
    highlightSubfactor: actions.highlightSubfactor,
    clearHighlightedSubfactor: actions.clearHighlightedSubfactor,
    showTooltip: actions.showTooltip,
    hideTooltip: actions.hideTooltip,
  },
  stateSelector: selectors.getStateByKey,
  mapStateToProps: state => ({
    highlightedSubfactor: state.highlightedSubfactor,
    tooltipInfo: state.tooltip,
  }),
})(SubfactorHealthBadge)
