import PropTypes from 'prop-types'
import React from 'react'

import HealthRangeComparisonChartContainer from 'app/reusable/health/health-range-comparison-chart'
import * as strings from 'app/strings'

export default function HealthRangeComparison({
  healthData,
  factor,
  subfactor,
  className,
}) {
  const points = []
  if (healthData && healthData.healthScore !== null) {
    points.push({
      label: 'Overall',
      healthScore: Math.round(healthData.healthScore),
    })

    if (
      factor &&
      healthData.factors[factor] &&
      healthData.factors[factor].healthScore !== null
    ) {
      points.push({
        label: strings.factorDisplayName(factor),
        healthScore: Math.round(healthData.factors[factor].healthScore),
      })
    }
    if (
      subfactor &&
      healthData.subfactors[subfactor] &&
      healthData.subfactors[subfactor].healthScore !== null
    ) {
      points.push({
        label: strings.subfactorDisplayName(subfactor),
        healthScore: Math.round(healthData.subfactors[subfactor].healthScore),
      })
    }
  }
  return (
    <HealthRangeComparisonChartContainer
      points={points}
      className={className}
    />
  )
}
HealthRangeComparison.propTypes = {
  healthData: PropTypes.object.isRequired,
  factor: PropTypes.string,
  subfactor: PropTypes.string,
  className: PropTypes.string,
}
