import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './styles.less'

export default function CloseButton({onClick, className}) {
  return (
    <svg
      className={classNames(styles.closeButton, className)}
      onClick={onClick}
    >
      <line className={styles.x} x1="25%" y1="25%" x2="75%" y2="75%" />
      <line className={styles.x} x1="75%" y1="25%" x2="25%" y2="75%" />
    </svg>
  )
}
CloseButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
}
