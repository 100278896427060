import classNames from 'classnames'
import is from 'is'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import React from 'react'

import Link from 'app/common/NavigationLink'
import { Tooltip } from 'app/common/tooltip'

import * as styles from './TabRow.less'

export class Tab extends React.PureComponent {
  static propTypes = {
    id: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    url: PropTypes.string,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
  }

  static defaultProps = {
    disabled: false,
  }
}

/**
 * Renders a row of selectable tabs. Example:
 *
 *   <TabRow onChange={tabId => tabChanged(tabId)}>
 *     <TabRow.Tab name="tab1" label="First Tab" />
 *     <TabRow.Tab name="tab2" label="Second Tab" />
 *   </TabRow>
 */
export default class TabRow extends React.PureComponent {
  static propTypes = {
    defaultTab: PropTypes.any,
    activeTab: PropTypes.any,
    onChange: PropTypes.func,
    className: PropTypes.string,
    activeClassName: PropTypes.string,
  }

  static Tab = Tab

  constructor(props) {
    super(props)
    this.state = {
      currentTabId: props.activeTabId || props.defaultTab || this.tabs[0].id,
    }
  }

  get tabs() {
    return React.Children.map(
      this.props.children,
      tabComponent =>
        tabComponent && {
          id: tabComponent.props.id,
          label: tabComponent.props.label,
          render: tabComponent.props.render,
          url: tabComponent.props.url,
          disabled: tabComponent.props.disabled,
          tooltip: tabComponent.props.tooltip,
          className: tabComponent.props.className,
          activeClassName: tabComponent.props.activeClassName,
        },
    ).filter(Boolean)
  }

  get activeTabId() {
    return this.props.activeTab || this.state.currentTabId
  }

  render() {
    return (
      <div className={classNames(styles.tabRow, this.props.className)}>
        {this.tabs.map(tab => this.renderTab(tab))}
      </div>
    )
  }

  renderTab(tab) {
    return (
      <Tooltip
        label={tab.tooltip}
        disabled={is.undefined(tab.tooltip)}
        className={styles.tooltip}
        containerClassName={classNames(styles.tab, {
            [styles.disabled]: tab.disabled,
            [styles.active]: this.activeTabId === tab.id,
          },
          {
            [tab.activeClassName]: this.activeTabId === tab.id
          },
          tab.className,
        )}
        key={tab.id}
      >
        {tab.disabled ? (
          tab.label
        ) : (
          tab.url ? (
            <Link
              href={tab.url}
              onClick={bind(this.onTabClick, this, tab)}
              key={tab.id}
            >
              {tab.label}
            </Link>
          ) : (
            <a
              onClick={bind(this.onTabClick, this, tab)}
              key={tab.id}
            >
              {tab.label}
            </a>
          )
        )}
      </Tooltip>
    )
  }

  onTabClick(tab, event) {
    // Only hijack left clicks without any modifier keys pressed, and don't
    // hijack when there is a URL on the tab and no onChange prop was passed.
    if (
      event.button === 0 &&
      !(event.metaKey || event.ctrlKey || event.shiftKey) &&
      (!tab.url || this.props.onChange)
    ) {
      event.preventDefault()
      this.changeTab(tab)
    }
  }

  changeTab(tab) {
    this.setState({ currentTabId: tab.id })
    if (this.props.onChange) {
      this.props.onChange(tab.id)
    }
  }
}
