import classNames from 'classnames'
import {range} from 'ramda'
import React from 'react'

import Table from 'app/common/Table'
import {FACTORS} from 'app/constants'

import {getColumns} from './companies-table-columns'

import * as tableStyles from './CompaniesTable.less'
import * as styles from './CompaniesTableLoading.less'

const ROW_COUNT = 10

const CompaniesTableLoading = () => {
  const columns = getColumns({shouldShowCompanyDetails: false}).map(column => ({
    ...column,
    className: Object.values(FACTORS).includes(column.name)
      ? styles.factor
      : styles[column.name],
    colSpan: 1,
    cellContents:
      column.name === 'industries' ? (
        <React.Fragment>
          <div className={styles.rect} />
          <div className={styles.rect} />
        </React.Fragment>
      ) : (
        <div className={styles.rect} />
      ),
  }))

  return (
    <Table
      data={range(0, ROW_COUNT).map(num => ({id: num}))}
      columns={columns}
      className={classNames(styles.table, tableStyles.table)}
    />
  )
}

export default CompaniesTableLoading
