import dateFns from 'date-fns'
import is from 'is'
import {prop} from 'ramda'
import {LOCATION_CHANGED} from 'redux-little-router'
import {all, put, select, takeLatest} from 'redux-saga/effects'

import {
  fetchCompanies,
  fetchMyCompanies,
  fetchMyIndustries,
  fetchIndustries,
  fetchStories,
} from 'app/api/api-saga-helpers'
import {IGNORED_SUBFACTORS, SORT_DIRECTIONS, today} from 'app/constants'
import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import {actions as globalActions} from 'app/global'
import Industry from 'app/models/Industry'
import {getMyCompanyIds, getMyIndustryIds} from 'app/global/global-selectors'
import CompanyResource from 'app/resources/Company'
import IndustryResource from 'app/resources/Industry'
import StoryResource from 'app/resources/Story'
import {STORY_SORT_OPTIONS} from 'app/reusable/stories/story-reader/story-reader-constants'
import routes from 'app/routes'
import {restApiRequest, waitForProfileData} from 'app/utils/sagas'

import {
  TIME_FRAME_QUERY_PARAM,
  GEOGRAPHY_QUERY_PARAM,
  MAX_SIDEBAR_COMPANIES,
} from '../common/overview-constants'

import * as actions from './industry-overview-actions'
import {
  INDUSTRY_OVERVIEW_ROUTES,
  STORY_READER_MAX_STORY_COUNT,
} from './industry-overview-constants'
import * as selectors from './industry-overview-selectors'

function* fetchSubIndustryIds(industryId) {
  const response = yield yield* restApiRequest(CompanyResource, {
    query: {
      industryIds: `cs.{${industryId}}`,
      entityType: 'eq.INDUSTRY',
      select: 'id',
    },
  })
  return response.body.map(prop('id'))
}

function* locationChanged(action) {
  // Ensure the profile info has been loaded since the user may have customized
  // the default filters.
  yield* waitForProfileData()

  const industryId = yield select(selectors.getIndustryId)
  const shouldLoadIndustry = yield select(state => {
    const orm = Orm.withEntities(getEntities(state))
    const {previous} = state.router
    const {payload} = action

    // If any of these query params have changed, we have to reload the data.
    const volatileQueryParams = [TIME_FRAME_QUERY_PARAM, GEOGRAPHY_QUERY_PARAM]

    return (
      !orm.getById(Industry, industryId) ||
      !(
        [routes.industryOverview, routes.industryOverviewPortal].includes(
          previous.route,
        ) &&
        previous.params.industryId === payload.params.industryId &&
        volatileQueryParams.every(
          param => previous.query[param] === payload.query[param],
        )
      )
    )
  })
  const shouldLoadMyCompanies = !(yield select(getMyCompanyIds))
  const shouldLoadMyIndustries = !(yield select(getMyIndustryIds))
  let fetchMyCompaniesRequest
  let fetchMyIndustriesRequest

  yield put(actions.setIsLoading(true))

  if (shouldLoadMyCompanies) {
    fetchMyCompaniesRequest = yield* fetchMyCompanies({
      fields: [...CompanyResource.requiredFields, 'industries'],
    })
  }
  if (shouldLoadMyIndustries) {
    fetchMyIndustriesRequest = yield* fetchMyIndustries()
  }

  if (shouldLoadIndustry) {
    const timeFrameDays = yield select(selectors.getTimeFrameForCurrentIndustry)
    const geography = yield select(selectors.getGeographyForCurrentIndustry)

    const response = yield yield* fetchIndustries({
      industryId,
      timeFrameDays,
      geography,
      fields: IndustryResource.allFields,
    })
    if (is.array.empty(response.result)) {
      yield put(globalActions.setNotFound())
      yield put(actions.setIsLoading(false))
      return
    }
    yield put(actions.setAllCompanyIds(response.body[0].companyIds))

    const subIndustryIds = yield* fetchSubIndustryIds(industryId)
    yield put(actions.setSubIndustryIds(subIndustryIds))
  }

  if (
    [routes.industryOverview, routes.industryOverviewPortal].includes(
      action.payload.route,
    )
  ) {
    yield yield* refreshData()
  } else {
    const geography = yield select(selectors.getGeographyForCurrentIndustry)
    yield yield* fetchCompanies({
      industryIds: [industryId],
      geography,
      fields: [...CompanyResource.requiredFields, 'healthBadge', 'industries'],
    })
  }

  if (fetchMyCompaniesRequest) {
    yield fetchMyCompaniesRequest
  }
  if (fetchMyIndustriesRequest) {
    yield fetchMyIndustriesRequest
  }
  yield put(actions.setIsLoading(false))
}

function* refreshData(action) {
  yield put(actions.setStoryIds({ids: null}))
  const state = yield select(selectors.getIndustryOverview)
  const industryId = yield select(selectors.getIndustryId)
  const geography = yield select(selectors.getGeographyForCurrentIndustry)
  let onlyIndustryNews = false
  // For these, null means all companies:
  let storyCompanyIds = null
  let sidebarCompanyIds = null

  if (state.selectedCompanyId) {
    storyCompanyIds = [state.selectedCompanyId]
  }
  // This is an `else if` because we want a specific company selection to
  // override the industry news filter.
  else if (state.shouldShowOnlyIndustryNews) {
    onlyIndustryNews = true
  }

  if (state.shouldShowOnlyMyCompanies) {
    const myCompanyIds = yield select(getMyCompanyIds)
    if (!storyCompanyIds) {
      storyCompanyIds = state.allCompanyIds
    }
    storyCompanyIds = storyCompanyIds.filter(id => myCompanyIds.includes(id))
    sidebarCompanyIds = state.allCompanyIds.filter(id =>
      myCompanyIds.includes(id),
    )
  }

  const {valences, sortOrder} = state.storyReader.filters
  const storiesOrderBy = [
    {
      field:
        sortOrder === STORY_SORT_OPTIONS.RECENCY ? 'storyDate' : 'articleCount',
      direction: SORT_DIRECTIONS.DESC,
    },
    {
      field:
        sortOrder === STORY_SORT_OPTIONS.RECENCY ? 'articleCount' : 'storyDate',
      direction: SORT_DIRECTIONS.DESC,
    },
  ]
  const companiesOrderBy = [
    {
      field:
        sortOrder === STORY_SORT_OPTIONS.RECENCY
          ? 'lastStoryDate'
          : 'articleVolume',
      direction: SORT_DIRECTIONS.DESC,
      nulls: 'last',
    },
  ]
  const timeFrameDays = yield select(selectors.getTimeFrameForCurrentIndustry)
  const factor = yield select(selectors.getFactorForCurrentIndustry)
  const subfactor = yield select(selectors.getSubfactorForCurrentIndustry)
  const startDate = dateFns.subDays(today, timeFrameDays)

  const storiesRequest = yield* fetchStories({
    industryId,
    companyIds: storyCompanyIds,
    valences,
    startDate,
    isLargest: sortOrder === STORY_SORT_OPTIONS.VOLUME,
    isMostRecent: sortOrder === STORY_SORT_OPTIONS.RECENCY,
    isIndustry: onlyIndustryNews,
    factor,
    subfactor,
    ignoreSubfactors: IGNORED_SUBFACTORS,
    geography,
    orderBy: storiesOrderBy,
    limit: STORY_READER_MAX_STORY_COUNT,
    fields: [
      ...StoryResource.requiredFields,
      'topArticle',
      'storyline',
      'company',
    ],
  })

  let companiesRequest
  // We don't want to reload companies if they aren't going to change.
  const actionsNotAffectingCompanies = [
    actions.selectValence,
    actions.deselectValence,
    actions.selectCompany,
    actions.clearSelectedCompany,
    actions.setShowOnlyIndustryNews,
  ]
  if (
    !action ||
    !actionsNotAffectingCompanies.map(a => a.toString()).includes(action.type)
  ) {
    yield put(actions.setSidebarCompanyIds(null))

    const options = {
      excludeIndustries: true,
      geography,
      orderBy: companiesOrderBy,
      headers: {
        Prefer: 'count=exact',
      },
      limit: MAX_SIDEBAR_COMPANIES,
      fields: [...CompanyResource.requiredFields, 'healthBadge'],
    }
    if (sidebarCompanyIds) {
      options.companyIds = sidebarCompanyIds
    } else {
      options.industryIds = [industryId]
    }
    companiesRequest = yield* fetchCompanies(options)
  }

  const storiesResponse = yield storiesRequest
  if (companiesRequest) {
    const companiesResponse = yield companiesRequest
    yield put(actions.setSidebarCompanyIds(companiesResponse.result))
    const companyCount = parseInt(
      companiesResponse.headers['content-range'].split('/')[1],
      10,
    )

    // We only need to set the total company count once.
    if (!(yield select(selectors.getTotalCompanyCount))) {
      yield put(actions.setTotalCompanyCount(companyCount))
    }
  }
  yield put(actions.setStoryIds({ids: storiesResponse.result}))
}

const isIndustryOverviewLocationChange = action =>
  action.type === LOCATION_CHANGED &&
  INDUSTRY_OVERVIEW_ROUTES.includes(action.payload.route)

export default function* companyOverviewSaga() {
  yield all([
    takeLatest(isIndustryOverviewLocationChange, locationChanged),
    takeLatest(
      [
        actions.selectValence,
        actions.deselectValence,
        actions.setStorySortOrder,
        actions.setShowOnlyMyCompanies,
        actions.selectCompany,
        actions.clearSelectedCompany,
        actions.setShowOnlyIndustryNews,
      ],
      refreshData,
    ),
  ])
}
