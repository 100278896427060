import Resource from "app/framework/Resource";
import LitigationNumberSummaryModel from "../models/LitigationNumberSummary";

export default class LitigationNumberSummary extends Resource {
  static endpoint = "rpc/litigation_numbers_summary";

  entityItemsForRestItem(restData) {
    return [
      {
        id: restData.id, // The ID is the industry's ID. We need an ID field, because of the FE ORM constraints
        entityKey: LitigationNumberSummaryModel.entityKey,
        data: {
          id: restData.id,
          name: restData.industryName,
          litigationStoryCount: restData.litStoryCount,
          industrySize: restData.industrySize,
          litigationCompanyCount: restData.litCompanyCount,
          litigationCompanyProp: restData.litCompanyProp,
          litigationCountDelta: restData.litCountDelta,
          litigationCountPercent: restData.litCountPercent,
        }
      }
    ];
  }
}
