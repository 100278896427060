import classNames from 'classnames'
import is from 'is'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import {filter, pipe, sort} from 'ramda'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import Checkbox from 'app/common/Checkbox'
import InputBlock from 'app/common/InputBlock'
import {LoadingMessage} from 'app/common/loading-message'
import Table from 'app/common/Table'
import TextBox from 'app/common/TextBox'
import {SORT_DIRECTIONS} from 'app/constants'
import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import Industry from 'app/models/Industry'
import {getMyCompanyIds, getMyIndustryIds} from 'app/global/global-selectors'
import GeographyDropdown from 'app/reusable/geography/geography-dropdown'
import IndustriesTable, {
  sortByFunctionsByColumn,
} from 'app/reusable/industries/industries-table'

import * as actions from './industries-actions'
import * as selectors from './industries-selectors'

import * as styles from './Industries.less'

const NoIndustries = () => (
  <div className={styles.noIndustries}>
    <p className={styles.message}>
      No industries found. Try broadening your search.
    </p>
  </div>
)

class IndustriesPage extends React.PureComponent {
  static propTypes = {
    industries: PropTypes.arrayOf(PropTypes.object),
    isRefreshing: PropTypes.bool.isRequired,
    myIndustryIds: PropTypes.arrayOf(PropTypes.number),
    myCompanyIds: PropTypes.arrayOf(PropTypes.number),
    geography: PropTypes.number.isRequired,
    tableSort: PropTypes.object.isRequired,

    // Filters
    industryNameFilter: PropTypes.string.isRequired,
    shouldShowOnlyMyIndustries: PropTypes.bool.isRequired,

    // Actions
    changeTableSort: PropTypes.func.isRequired,
    changeIndustryNameFilter: PropTypes.func.isRequired,
    changeGeography: PropTypes.func.isRequired,
    changeShouldShowOnlyMyIndustries: PropTypes.func.isRequired,
  }

  render() {
    const {
      industries,
      isRefreshing,
      myIndustryIds,
      myCompanyIds,
      geography,
      tableSort,

      industryNameFilter,
      shouldShowOnlyMyIndustries,

      changeTableSort,
      changeGeography,
    } = this.props

    if (!industries || !myIndustryIds || !myCompanyIds) {
      return <LoadingMessage />
    }

    // Build index for faster lookups.
    const myIndustryIdsSet = new Set(myIndustryIds)

    // We have to do the sorting outside of the table itself so that parents
    // always show up above children.
    const sortByFunc = sortByFunctionsByColumn[tableSort.column]
    const sortIndustries = sort((industry1, industry2) => {
      const val1 = sortByFunc(industry1, myCompanyIds)
      const val2 = sortByFunc(industry2, myCompanyIds)
      // Always put "no data" rows at the end of the table.
      if (is.undefined(val1) && is.undefined(val2)) {
        return 0
      }
      if (is.undefined(val1)) {
        return 1
      }
      if (is.undefined(val2)) {
        return -1
      }

      let val = Table.Column.defaultProps.sortCompareFunction(val1, val2)
      if (tableSort.direction === SORT_DIRECTIONS.DESC) {
        val *= -1
      }
      return val
    })
    const filterIndustries = filter(industry => {
      if (
        industryNameFilter &&
        !industry.name
          .toLowerCase()
          .startsWith(industryNameFilter.toLowerCase())
      ) {
        return false
      }
      if (shouldShowOnlyMyIndustries && !myIndustryIdsSet.has(industry.id)) {
        return false
      }
      return true
    })

    const industriesForTable = pipe(
      filterIndustries,
      sortIndustries,
    )(industries)

    return (
      <div className={styles.page}>
        <div className={styles.filters}>
          <InputBlock
            label="Filter by Industry Name"
            className={classNames(styles.filter, styles.industryName)}
          >
            <TextBox
              value={industryNameFilter}
              onChange={bind(this.onIndustryNameFilterChange, this)}
            />
          </InputBlock>

          <InputBlock
            label="Geography"
            className={classNames(styles.filter, styles.geography)}
          >
            <GeographyDropdown
              selectedGeography={geography}
              onChange={changeGeography}
              className={styles.dropdown}
            />
          </InputBlock>

          <InputBlock
            label="Show only my industries"
            isInline={true}
            className={classNames(styles.filter, styles.myIndustries)}
          >
            <Checkbox
              value={shouldShowOnlyMyIndustries}
              onChange={bind(this.onShouldShowOnlyMyIndustriesChange, this)}
            />
          </InputBlock>
        </div>

        <IndustriesTable
          isHeadSticky={true}
          industries={industriesForTable}
          myCompanyIds={myCompanyIds}
          geography={geography}
          sort={tableSort}
          isLoading={isRefreshing}
          changeSort={changeTableSort}
        />

        {!industriesForTable.length && <NoIndustries />}
      </div>
    )
  }

  onIndustryNameFilterChange(event) {
    this.props.changeIndustryNameFilter(event.target.value)
  }

  onShouldShowOnlyMyIndustriesChange(event) {
    this.props.changeShouldShowOnlyMyIndustries(event.target.checked)
  }
}

export default connect(
  createSelector(
    [getEntities, selectors.getRoot, getMyCompanyIds, getMyIndustryIds],
    (entities, state, myCompanyIds, myIndustryIds) => {
      const {industryIds, isRefreshing, geography, tableSort, filters} = state
      const orm = Orm.withEntities(entities)
      return {
        industries: industryIds && orm.getByIds(Industry, industryIds),
        isRefreshing,
        myIndustryIds,
        myCompanyIds,
        geography,
        tableSort,
        industryNameFilter: filters.industryName,
        shouldShowOnlyMyIndustries: filters.shouldShowOnlyMyIndustries,
      }
    },
  ),
  {
    changeTableSort: actions.setTableSort,
    changeIndustryNameFilter: actions.setIndustryNameFilter,
    changeGeography: actions.setGeography,
    changeShouldShowOnlyMyIndustries: actions.setShouldShowOnlyMyIndustries,
  },
)(IndustriesPage)
