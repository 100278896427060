import Resource from "app/framework/Resource";
import LitigationSavedSearchModel from "../models/LitigationSavedSearch";

export default class LitigationSavedSearch extends Resource {
  static endpoint = "my_litigation_saves";

  entityItemsForRestItem(restData) {
    return [
      {
        id: restData.id,
        entityKey: LitigationSavedSearchModel.entityKey,
        data: {
          id: restData.id,
          name: restData.name,
          settings: restData.settings,
          createdAt: restData.createdAt,
          modifiedAt: restData.modifiedAt,
        }
      }
    ];
  }
}
