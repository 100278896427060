import PropTypes from 'prop-types'
import React, {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import {FACTORS_BY_SUBFACTOR} from 'app/constants'
import HealthFactorSummary from 'app/health/HealthFactorSummary'
import {actions as factorDetailsModalActions} from 'app/overviews/company/factor-details-modal'

export default function CompanyHealthFactorSummary({
  company,
  factor,
  subfactor,
  predictedHealthScoreChangesByFactor,
  hasConfidenceInPredictionsByFactor,
  factorPredictionUrl,
  factorFilterUrl,
  factorTooltip,
  subfactorFilterUrl,
  subfactorTooltip,
}) {
  const dispatch = useDispatch()
  const onHelpIconClick = useCallback(
    ({factor, subfactor}) => {
      dispatch(
        factorDetailsModalActions.show({
          factor: subfactor ? FACTORS_BY_SUBFACTOR[subfactor] : factor,
          subfactor,
          subfactorsHealthData: company.healthData.subfactors,
          factorHealthData: company.healthData.factors[factor],
        }),
      )
    },
    [company, factor, subfactor],
  )
  const healthData = factor
    ? company.healthData.subfactors
    : company.healthData.factors
  return (
    <HealthFactorSummary
      healthData={healthData}
      factor={factor}
      subfactor={subfactor}
      predictedHealthScoreChangesByFactor={predictedHealthScoreChangesByFactor}
      hasConfidenceInPredictionsByFactor={hasConfidenceInPredictionsByFactor}
      factorPredictionUrl={factorPredictionUrl}
      shouldShowHelpIcon={!subfactor}
      shouldShowFilterIcon={!subfactor}
      onHelpIconClick={onHelpIconClick}
      factorFilterUrl={factorFilterUrl}
      factorTooltip={factorTooltip}
      subfactorFilterUrl={subfactorFilterUrl}
      subfactorTooltip={subfactorTooltip}
    />
  )
}
CompanyHealthFactorSummary.propTypes = {
  company: PropTypes.object.isRequired,
  factor: PropTypes.string,
  subfactor: PropTypes.string,
  predictedHealthScoreChangesByFactor: PropTypes.object,
  hasConfidenceInPredictionsByFactor: PropTypes.object,
  factorPredictionUrl: PropTypes.string,
  factorFilterUrl: PropTypes.func,
  factorTooltip: PropTypes.node,
  subfactorFilterUrl: PropTypes.func,
  subfactorTooltip: PropTypes.node,

  // Actions
  showModal: PropTypes.func.isRequired,
  selectFactor: PropTypes.func.isRequired,
  selectSubfactor: PropTypes.func.isRequired,
}
