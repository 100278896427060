import classNames from 'classnames'
import React from 'react'

import styles from './ChartTooltip.less'

export default function ChartTooltip({
  children,
  header = null,
  className,
  contentClassName,
}) {
  if (header) {
    return (
      <div
        className={classNames(styles.tooltip, styles.withSections, className)}
      >
        <div className={styles.header}>{header}</div>
        <div className={classNames(styles.content, contentClassName)}>
          {children}
        </div>
      </div>
    )
  }
  return <div className={classNames(styles.tooltip, className)}>{children}</div>
}
