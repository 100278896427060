import {createAction} from 'redux-actions'

const PREFIX = 'CHARTS'
const action = name => createAction(`${PREFIX}_${name}`)

export const saveChart = action('SAVE_CHART')
export const setSectionRef = action('SET_SECTION_REF')
export const setAskForSectionRef = action('SET_ASK_FOR_SECTION_REF')
export const setIsWaiting = action('SET_IS_WAITING')
export const setShowConfirmationModal = action('SET_SHOW_CONFIRMATION_MODAL')
