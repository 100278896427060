import is from 'is'
import PropTypes from 'prop-types'
import React from 'react'

import Cell from './Cell'
import Table from './Table'

const PaddedBlock = ({
  children,
  padding,
  vertical,
  horizontal,
  top,
  right,
  bottom,
  left,
  ...props
}) => {
  const style = {
    ...(props.style || {}),
    // In order of precedence (highest first):
    //   top/bottom/left/right
    //   vertical/horizontal
    //   padding
    paddingTop: is.defined(top)
      ? top
      : is.defined(vertical)
      ? vertical
      : padding,
    paddingBottom: is.defined(bottom)
      ? bottom
      : is.defined(vertical)
      ? vertical
      : padding,
    paddingLeft: is.defined(left)
      ? left
      : is.defined(horizontal)
      ? horizontal
      : padding,
    paddingRight: is.defined(right)
      ? right
      : is.defined(horizontal)
      ? horizontal
      : padding,
  }
  return (
    <Table>
      <Cell style={style}>{children}</Cell>
    </Table>
  )
}
PaddedBlock.propTypes = {
  children: PropTypes.node,
  padding: PropTypes.number,
  vertical: PropTypes.number,
  horizontal: PropTypes.number,
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
}
export default PaddedBlock
