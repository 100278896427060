import {handleActions} from 'redux-actions'
import {LOCATION_CHANGED} from 'redux-little-router'

import {SORT_DIRECTIONS} from 'app/constants'
import routes from 'app/routes'

import * as actions from './industries-actions'

const getInitialState = () => ({
  industryIds: null,
  isRefreshing: false,
  tableSort: {column: 'name', direction: SORT_DIRECTIONS.ASC},
  geography: 0,
  filters: {
    industryName: '',
    companyName: '',
    shouldShowOnlyMyIndustries: false,
  },
})

export default handleActions(
  {
    [LOCATION_CHANGED]: (state, action) =>
      action.payload.route === routes.industries ? state : getInitialState(),

    [actions.setIndustryIds]: (state, action) => ({
      ...state,
      industryIds: action.payload,
    }),
    [actions.setGeography]: (state, action) => ({
      ...state,
      geography: action.payload,
    }),
    [actions.setIsRefreshing]: (state, action) => ({
      ...state,
      isRefreshing: action.payload,
    }),

    [actions.setTableSort]: (state, action) => ({
      ...state,
      tableSort: action.payload,
    }),
    [actions.setIndustryNameFilter]: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        industryName: action.payload,
      },
    }),
    [actions.setCompanyNameFilter]: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        companyName: action.payload,
      },
    }),
    [actions.setShouldShowOnlyMyIndustries]: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        shouldShowOnlyMyIndustries: action.payload,
      },
    }),
  },
  getInitialState(),
)
