// The reason we're not using the third-party change-case-object library is this
// long-standing bug:
// https://github.com/BinaryThumb/change-case-object/issues/7

import camelCase from 'camel-case'
import is from 'is'
import snakeCase from 'snake-case'

const makeTransformer = changeCase => {
  const transformObject = obj => {
    if (is.array(obj)) {
      return obj.map(transformObject)
    }
    if (!is.object(obj)) return obj
    return Object.keys(obj).reduce((newObj, key) => {
      const value = obj[key]
      newObj[changeCase(key)] = transformObject(value)
      return newObj
    }, {})
  }
  return transformObject
}

export default {
  camelCase: makeTransformer(camelCase),
  snakeCase: makeTransformer(snakeCase),
}
