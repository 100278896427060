import classNames from 'classnames'
import paramCase from 'param-case'
import * as R from 'ramda'
import React from 'react'
import PropTypes from 'prop-types'

const Cell = ({
  children,
  columnName,
  className,
  baseWidth,
  minWidth,
  maxWidth,
  growRatio,
  shrinkRatio,
  style,
  ...restProps
}) => {
  const styleProp = R.mergeRight(
    {
      flexBasis: baseWidth,
      minWidth,
      maxWidth,
      flexGrow: growRatio,
      flexShrink: shrinkRatio,
    },
    style || {},
  )

  // ms- prefixes for IE 10 support
  styleProp.msFlexPreferredSize = styleProp.flexBasis
  styleProp.msFlexPositive = styleProp.flexGrow
  styleProp.msFlexNegative = styleProp.flexShrink

  return (
    <div
      className={classNames('cell', paramCase(columnName), className)}
      style={styleProp}
      {...restProps}
    >
      {/*
        This div is a hack to get around an IE10 flexbox layout issue
      */}
      <div className="cell-contents">{children}</div>
    </div>
  )
}
Cell.propTypes = {
  columnName: PropTypes.string.isRequired,
  className: PropTypes.string,
  baseWidth: PropTypes.number,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  growRatio: PropTypes.number,
  shrinkRatio: PropTypes.number,
  style: PropTypes.object,
}

export default Cell
