import {takeLatest} from 'redux-saga/effects'

import {fetchArticles} from 'app/api/api-saga-helpers'

import * as actions from './share-article-modal-actions'

function* modalShown({payload: {articleId}}) {
  if (articleId) {
    yield* fetchArticles({articleId})
  } else {
    throw new Error(
      'A `storyId` was not given in the payload for action `story-modal-actions/showModal`.',
    )
  }
}

export default function* storylineModalSaga() {
  yield takeLatest(actions.showModal, modalShown)
}
