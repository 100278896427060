import {createAction} from 'redux-actions'

export const action = baseName => createAction(`company-overview/${baseName}`)
const timelineAction = baseName => action(`TIMELINE_${baseName}`)

export const setIsLoading = action('SET_IS_LOADING')
export const setStorylineIds = action('SET_STORYLINE_IDS')
export const highlightFactor = action('HIGHLIGHT_FACTOR')
export const clearHighlightedFactor = action('CLEAR_HIGHLIGHTED_FACTOR')
export const highlightSubfactor = action('HIGHLIGHT_SUBFACTOR')
export const clearHighlightedSubfactor = action('CLEAR_HIGHLIGHTED_SUBFACTOR')
export const selectCompany = action('SELECT_COMPANY')
export const showFiltersSaveModal = action('SHOW_FILTERS_SAVE_MODAL')
export const hideFiltersSaveModal = action('HIDE_FILTERS_SAVE_MODAL')
export const setPredictedHealthScoreChangesByFactor = action(
  'SET_PREDICTED_HEALTH_SCORES_BY_FACTOR',
)
export const setHasConfidenceInPredictionsByFactor = action(
  'SET_HAS_CONFIDENCE_IN_PREDICTED_HEALTH_SCORES_BY_FACTOR',
)
export const getLeadership = action('GET_LEADERSHIP')
export const setLeadership = action('SET_LEADERSHIP')

// Story reader
export const setStoryIds = action('SET_STORY_IDS')
export const selectValence = action('SELECT_VALENCE')
export const deselectValence = action('DESELECT_VALENCE')
export const setStorySortOrder = action('SET_STORY_SORT_ORDER')
export const setShowOnlyMyCompanies = action('SET_SHOW_ONLY_MY_COMPANIES')

// Timeline

export const timeline = {
  highlightDay: timelineAction('HIGHLIGHT_DAY'),
  clearHighlightedDay: timelineAction('CLEAR_HIGHLIGHTED_DAY'),
  highlightGroups: timelineAction('HIGHLIGHT_GROUPS'),
  clearHighlightedGroups: timelineAction('CLEAR_HIGHLIGHTED_GROUPS'),
  showStoriesTooltip: timelineAction('SHOW_STORIES_TOOLTIP'),
  expandStoriesTooltip: timelineAction('EXPAND_STORIES_TOOLTIP'),
  hideStoriesTooltip: timelineAction('HIDE_STORIES_TOOLTIP'),
}

// History

export const setHistoricalStorylineIds = action('SET_HISTORICAL_STORYLINE_IDS')
export const selectQuarter = action('SELECT_QUARTER')
export const setHistoricalHealthData = action('SET_HISTORICAL_HEALTH_DATA')
export const setHistoricalStorylineSearchText = action(
  'SET_HISTORICAL_STORYLINE_SEARCH_TEXT',
)
export const setQuarterlyArticleCounts = action('SET_QUARTERLY_ARTICLE_COUNTS')
export const setAutoQuarterSearch = action('SET_AUTO_QUARTER_SEARCH')
