import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './StoryCardLoading.less'
import storyCardStyles from './StoryCard.less'

export default function StoryCardLoading({
  shouldShowCompanyName = true,
  className,
}) {
  return (
    <div
      className={classNames(styles.loading, storyCardStyles.story, className)}
    >
      {shouldShowCompanyName && (
        <div className={storyCardStyles.companyName}>
          <div className={styles.companyNameRect} />
        </div>
      )}

      <div className={storyCardStyles.content}>
        <div className={storyCardStyles.article}>
          <div className={storyCardStyles.signalSummary}>
            <div className={styles.signalSummaryRect} />
          </div>

          <div className={storyCardStyles.body}>
            <div className={storyCardStyles.articleSection}>
              <div className={storyCardStyles.headline}>
                <div className={styles.headlineRect} />
              </div>

              <div className={storyCardStyles.excerpt}>
                <div className={styles.rect} />
              </div>
            </div>
          </div>

          <div className={storyCardStyles.meta}>
            <div className={storyCardStyles.date}>
              <div className={styles.dateRect} />
            </div>

            <div className={storyCardStyles.articleCount}>
              <div className={styles.articleCountRect} />
            </div>
          </div>
        </div>

        <div className={storyCardStyles.storyline}>
          <div className={storyCardStyles.fromStoryline}>
            <div className={styles.fromStorylineRect} />
          </div>

          <div className={storyCardStyles.articleCount}>
            <div className={styles.storylineArticleCountRect} />
          </div>
        </div>
      </div>
    </div>
  )
}
StoryCardLoading.propTypes = {
  shouldShowCompanyName: PropTypes.bool,
  className: PropTypes.string,
}
