import {field} from 'app/framework/fields'
import Model from 'app/framework/Model'


export default class MyCompanyLitigation extends Model {
  static entityKey = 'myCompanyLitigation'

  static fields = {
    name: field(),
    id: field(),
    litigationStoryCounts: field(),
  }
}
