import classNames from 'classnames'
import React from 'react'
import InlineSvg from 'app/common/InlineSvg'
import * as styles from './FlagIcon.less'
import flagIconUrl from 'static/images/flag.svg'

export default function FlagIcon({className, isFlagged, ...props}) {
  return (
    <div
      className={classNames(styles.container, className, {
        [styles.flagged]: isFlagged,
      })}
      {...props}
    >
      <InlineSvg src={flagIconUrl} className={styles.icon} />
    </div>
  )
}
