import classNames from 'classnames'
import is from 'is'
import PropTypes from 'prop-types'
import {identity, prop} from 'ramda'
import React from 'react'

import {valueFromMaybeFunction} from 'app/utils'

import * as styles from './ScatterPlot.less'

export default function ScatterPlot({
  points,
  onDotMouseOver,
  onDotMouseOut,
  onDotClick,
  isXAxisVisible = true,
  isYAxisVisible = true,
  topLeftLabel,
  bottomLeftLabel,
  topRightLabel,
  bottomRightLabel,
  maxX,
  maxY,
  minX,
  minY,
  dotClassName,
  width,
  height,
  className = '',
}) {
  // Config
  const dotRadius = 4
  const padding = dotRadius + 2
  const verticalPadding = 20
  const axisWidth = 2

  maxX = is.defined(maxX)
    ? maxX
    : points
        .map(prop('x'))
        .reduce((acc, val) => Math.max(acc, Math.abs(val)), 0)
  maxY = is.defined(maxY)
    ? maxY
    : points
        .map(prop('y'))
        .reduce((acc, val) => Math.max(acc, Math.abs(val)), 0)
  minX = is.defined(minX)
    ? minX
    : points
        .map(prop('x'))
        .reduce((acc, val) => Math.min(acc, Math.abs(val)), 0)
  minY = is.defined(minY)
    ? minY
    : points
        .map(prop('y'))
        .reduce((acc, val) => Math.min(acc, Math.abs(val)), 0)

  const minXAbsolute = Math.abs(minX)
  const maxXAbsolute = Math.abs(maxX)
  const minYAbsolute = Math.abs(minY)
  const maxYAbsolute = Math.abs(maxY)

  // Computed layout values
  const usableWidth = width - padding * 2
  const usableHeight = height - padding * 2 - verticalPadding * 2
  const yAxisX = padding + (-minX / (minXAbsolute + maxXAbsolute)) * usableWidth
  const xAxisY =
    verticalPadding +
    padding +
    usableHeight * (1 - minYAbsolute / (minYAbsolute + maxYAbsolute))

  const dots = points.map((point, index) => {
    const x =
      padding + ((point.x - minX) / (minXAbsolute + maxXAbsolute)) * usableWidth
    const y =
      verticalPadding +
      padding +
      usableHeight -
      ((point.y - minY) / (minYAbsolute + maxYAbsolute)) * usableHeight
    return (
      <circle
        className={classNames(
          styles.dot,
          valueFromMaybeFunction(dotClassName, point),
          {[styles.hoverable]: !!onDotMouseOver},
        )}
        cx={x}
        cy={y}
        r={dotRadius}
        onMouseEnter={onDotMouseOver && (event => onDotMouseOver(point, event))}
        onMouseLeave={onDotMouseOut && (() => onDotMouseOut())}
        onClick={onDotClick && (event => onDotClick(point, event))}
        key={index}
      />
    )
  })

  const xAxis = isXAxisVisible && (
    <rect
      className={styles.axis}
      x={0}
      y={Math.round(xAxisY - axisWidth / 2)}
      width={width}
      height={axisWidth}
    />
  )
  const yAxis = isYAxisVisible && (
    <rect
      className={styles.axis}
      x={Math.round(yAxisX - axisWidth / 2)}
      y={0}
      width={axisWidth}
      height={height}
    />
  )

  const topLeftText = topLeftLabel && (
    <text
      className={classNames(styles.label, styles.topLeft)}
      x={padding}
      y={padding + 10}
    >
      {topLeftLabel}
    </text>
  )
  const bottomLeftText = bottomLeftLabel && (
    <text
      className={classNames(styles.label, styles.bottomLeft)}
      x={padding}
      y={height - padding}
    >
      {bottomLeftLabel}
    </text>
  )
  const topRightText = topRightLabel && (
    <text
      className={classNames(styles.label, styles.topRight)}
      x={width - padding}
      y={padding + 10}
    >
      {topRightLabel}
    </text>
  )
  const bottomRightText = bottomRightLabel && (
    <text
      className={classNames(styles.label, styles.bottomRight)}
      x={width - padding}
      y={height - padding}
    >
      {bottomRightLabel}
    </text>
  )

  return (
    <svg
      className={classNames(styles.scatterPlot, className, {
        [styles.hoverable]: !!onDotMouseOver,
      })}
      width={width}
      height={height}
    >
      {xAxis}
      {yAxis}

      {dots}

      {topLeftText}
      {bottomLeftText}
      {topRightText}
      {bottomRightText}
    </svg>
  )
}
ScatterPlot.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      meta: PropTypes.any,
    }),
  ).isRequired,
  onDotMouseOver: PropTypes.func,
  onDotMouseOut: PropTypes.func,
  onDotClick: PropTypes.func,
  isXAxisVisible: PropTypes.bool,
  isYAxisVisible: PropTypes.bool,
  topLeftLabel: PropTypes.string,
  bottomLeftLabel: PropTypes.string,
  topRightLabel: PropTypes.string,
  bottomRightLabel: PropTypes.string,
  maxX: PropTypes.number,
  maxY: PropTypes.number,
  minX: PropTypes.number,
  minY: PropTypes.number,
  dotClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
}
