import dateFns from 'date-fns'
import is from 'is'
import {curryN, filter, fromPairs, toPairs, pipe} from 'ramda'

export function deepCopy(value) {
  if (is.array(value)) {
    return [...value.map(deepCopy)]
  }
  if (is.date(value)) {
    return new Date(value)
  }
  if (is.object(value)) {
    return Object.entries(value).reduce((obj, [key, value]) => {
      obj[key] = deepCopy(value)
      return obj
    }, {})
  }
  return value
}

/**
 * Wraps the function `func` and returns a new function that takes an event
 * object and first calls `preventDefault()` and `stopPropagation()` on that
 * event. Useful for passing to event handlers to avoid creating a separate
 * function.
 *
 * React example:
 *   <a href="#" onClick={defaultPrevented(actualFunction)}>Click me</a>
 */
export function defaultPrevented(func) {
  return function(e) {
    e.preventDefault()
    e.stopPropagation()
    func(e)
  }
}

/**
 * Takes an array of strings and returns them joined into a single string that
 * makes sense in a sentence. The returned string has one of four formats:
 *
 *   If the array is empty:
 *     ''
 *   If there is one value:
 *     'a'
 *   If there are two values:
 *     'a and b'
 *   If there are three or more values:
 *     'a, b, ..., and c'
 */
export function joinInSentence(strings) {
  if (!strings.length) {
    return ''
  } else if (strings.length === 1) {
    return strings[0]
  } else if (strings.length === 2) {
    return `${strings[0]} and ${strings[1]}`
  } else {
    return `${strings.slice(0, -1).join(', ')}, and ${
      strings[strings.length - 1]
    }`
  }
}

/**
 * Returns a new array based on `array` with `item` inserted in between each
 * element.
 */
export function insertBetweenItems(array, item) {
  const newArray = array.reduce((acc, val) => {
    acc.push(val)
    if (is.function(item)) {
      const index = (acc.length - 1) / 2
      acc.push(item(index))
    } else {
      acc.push(item)
    }
    return acc
  }, [])
  // This will append `item` to the end as well, which we don't want
  newArray.pop()
  return newArray
}

/**
 * A generic comparator, meant to be used as an argument to `.sort()` or similar
 * functions.
 */
export function compare(val1, val2) {
  if (val1 < val2) return -1
  if (val1 > val2) return 1
  return 0
}

export function compareDates(date1, date2) {
  if (dateFns.isBefore(date1, date2)) return -1
  if (dateFns.isAfter(date1, date2)) return 1
  return 0
}

/**
 * If `value` is a function, calls that function with `args` and returns its
 * return value. Otherwise, just returns `value` directly. Useful for values
 * that may or may not be represented as functions.
 */
export function valueFromMaybeFunction(value, ...args) {
  if (typeof value === 'function') {
    return value(...args)
  }
  return value
}

export function adjustDateForUtc(date) {
  // This is a workaround for JavaScript Date objects always being in the local
  // time zone. It works because it compensates by adding the UTC offset to the
  // date.
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )
}

export const filterKeys = curryN(2, (predicate, iterable) =>
  pipe(
    toPairs,
    filter(([key]) => predicate(key)),
    fromPairs,
  )(iterable),
)

/**
 * Returns an empty object. The only real purpose of this is to make the
 * equivalent anonymous function more readable.
 *
 * Example:
 *
 *   new DefaultMap(emptyObject)
 *   // instead of
 *   new DefaultMap(() => ({}))
 */
export function emptyObject() {
  return {}
}

/**
 * Returns `val` if it is defined, or `fallbackVal` otherwise.
 */
export function coalesceUndefined(val, fallbackVal) {
  if (is.defined(val)) {
    return val
  }
  return fallbackVal
}
