import {STATE_PROP_NAME} from './charts-constants'

export const getChartsState = state => state[STATE_PROP_NAME]

export const getSectionRef = state => getChartsState(state).sectionRef
export const getAskForSectionRef = state =>
  getChartsState(state).askForSectionRef
export const getShowConfirmationModal = state =>
  getChartsState(state).showConfirmationModal
export const getIsWaiting = state => getChartsState(state).isWaiting
