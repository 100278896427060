import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Button from 'app/common/Button'
import TwitterIcon from 'app/social/icons/TwitterIcon'

import * as styles from './TwitterShareButton.less'

export default function TwitterShareButton({
  url,
  className,
  isDisabled = false,
}) {
  const encodedUrl = encodeURIComponent(url)
  const shareUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}`
  return (
    <a
      href={shareUrl}
      target="_blank"
      className={classNames(styles.twitterShareButton, className)}
    >
      <Button
        label={<TwitterShareButtonLabel />}
        isDisabled={isDisabled}
        className={classNames(styles.button, {[styles.disabled]: isDisabled})}
      />
    </a>
  )
}
TwitterShareButton.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
}

const TwitterShareButtonLabel = () => (
  <span className={styles.contents}>
    <TwitterIcon className={styles.icon} />
    Twitter
  </span>
)
