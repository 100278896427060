import classNames from 'classnames'
import bind from 'memoize-bind'
import memoize from 'memoize-weak'
import PropTypes from 'prop-types'
import {prop, range, reverse, sortBy} from 'ramda'
import React from 'react'

import Dropdown from 'app/common/Dropdown'
import {VALENCES} from 'app/constants'
import {StoryCard, StoryCardLoading} from 'app/reusable/stories/story-card'
import * as strings from 'app/strings'

import {STORY_SORT_OPTIONS} from './story-reader-constants'

import * as styles from './StoryReader.less'

const SKELETON_STORY_COUNT = 5

const industryNewsFilterChangeHandler = memoize(callback => event =>
  callback(event.target.checked),
)

class ValenceOption extends React.PureComponent {
  static propTypes = {
    valence: PropTypes.string.isRequired,
    isChecked: PropTypes.bool,
    selectValence: PropTypes.func.isRequired,
    deselectValence: PropTypes.func.isRequired,
  }

  render() {
    const {valence, isChecked} = this.props
    return (
      <label className={styles.option}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={bind(this.changeValence, this, valence, isChecked)}
        />
        <span className={styles.label}>
          {strings.valenceDisplayName(valence)}
        </span>
      </label>
    )
  }

  changeValence(valence, isChecked) {
    if (isChecked) {
      this.props.deselectValence(valence)
    } else {
      this.props.selectValence(valence)
    }
  }
}

const NoStories = () => (
  <div className={styles.noStories}>
    No stories to show with the selected filters. Try broadening your search or
    focusing on specific health factors.
  </div>
)

const Stories = ({stories, shouldShowCompanyName, isPortalPage = false}) => {
  let content
  if (!stories) {
    content = range(0, SKELETON_STORY_COUNT).map(index => (
      <StoryCardLoading
        shouldShowCompanyName={false}
        className={styles.story}
        key={index}
      />
    ))
  } else if (!stories.length) {
    content = <NoStories />
  } else {
    content = stories.map(story => (
      <StoryCard
        story={story}
        shouldShowCompanyName={shouldShowCompanyName}
        isPortalPage={isPortalPage}
        className={styles.story}
        companyNameClassName={styles.companyName}
        key={story.id}
      />
    ))
  }
  return <div className={styles.stories}>{content}</div>
}
Stories.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object.isRequired),
  shouldShowCompanyName: PropTypes.bool.isRequired,
  isPortalPage: PropTypes.bool,
}

const StoryReader = ({
  stories,
  selectedValences,
  sortOrder,
  shouldSort = true,
  shouldShowCompanyName = true,
  shouldShowIndustryNewsFilter = false,
  isIndustryNewsFilterChecked = false,
  isPortalPage = false,
  onSelectValence,
  onDeselectValence,
  onChangeSortBy,
  onChangeIndustryNewsFilter,
  className,
}) => {
  const sortByProp =
    sortOrder === STORY_SORT_OPTIONS.VOLUME ? 'articleCount' : 'date'
  const sortedStories = stories
    ? shouldSort
      ? reverse(sortBy(prop(sortByProp), stories))
      : stories
    : null
  return (
    <div className={classNames(styles.storyReader, className)}>
      <div className={styles.filters}>
        <div className={styles.valence}>
          <span className={styles.sectionLabel}>Story Valence</span>

          <ValenceOption
            valence={VALENCES.POSITIVE}
            isChecked={selectedValences.includes(VALENCES.POSITIVE)}
            selectValence={onSelectValence}
            deselectValence={onDeselectValence}
          />
          <ValenceOption
            valence={VALENCES.NEUTRAL}
            isChecked={selectedValences.includes(VALENCES.NEUTRAL)}
            selectValence={onSelectValence}
            deselectValence={onDeselectValence}
          />
          <ValenceOption
            valence={VALENCES.NEGATIVE}
            isChecked={selectedValences.includes(VALENCES.NEGATIVE)}
            selectValence={onSelectValence}
            deselectValence={onDeselectValence}
          />

          {shouldShowIndustryNewsFilter && (
            <label
              className={classNames(styles.option, styles.industryOnlyNews)}
            >
              <span className={styles.label}>Only Show Industry News</span>
              <input
                type="checkbox"
                checked={isIndustryNewsFilterChecked}
                onChange={industryNewsFilterChangeHandler(
                  onChangeIndustryNewsFilter,
                )}
              />
            </label>
          )}
        </div>

        <div className={styles.sort}>
          <span className={styles.sectionLabel}>Sort By</span>

          <Dropdown
            value={sortOrder}
            onChange={onChangeSortBy}
            className={styles.dropdown}
          >
            <Dropdown.Option label="Volume" value={STORY_SORT_OPTIONS.VOLUME} />
            <Dropdown.Option
              label="Recency"
              value={STORY_SORT_OPTIONS.RECENCY}
            />
          </Dropdown>
        </div>
      </div>

      <Stories
        stories={sortedStories}
        shouldShowCompanyName={shouldShowCompanyName}
        isPortalPage={isPortalPage}
      />
    </div>
  )
}
StoryReader.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object),
  selectedValences: PropTypes.arrayOf(PropTypes.string),
  sortOrder: PropTypes.string,
  shouldSort: PropTypes.bool,
  shouldShowCompanyName: PropTypes.bool,
  shouldShowIndustryNewsFilter: PropTypes.bool,
  isIndustryNewsFilterChecked: PropTypes.bool,

  isPortalPage: PropTypes.bool,

  onSelectValence: PropTypes.func.isRequired,
  onDeselectValence: PropTypes.func.isRequired,
  onChangeSortBy: PropTypes.func.isRequired,
  onChangeIndustryNewsFilter: PropTypes.func,

  className: PropTypes.string,
}

export default StoryReader
