import parseDate from 'date-fns/parse'
import {fromPairs, groupBy, map, nth, pipe, prop, whereEq} from 'ramda'

import {FACTORS_BY_ID, SUBFACTORS_BY_ID} from 'app/constants'
import Resource from 'app/framework/Resource'
import * as models from 'app/models'
import StoryResource from 'app/resources/Story'

export default class Industry extends Resource {
  static endpoint = 'industry'

  static requiredFields = ['id', 'displayName', 'savedsearchId']
  static allFields = [
    ...Industry.requiredFields,
    'companyIds',
    'healthBadge',
    'overallDailyHealthDataPoints',
    'factorDailyHealthData',
    'categoryDailyHealthData',
  ]

  entityItemsForRestItem(restData) {
    const data = {
      id: restData.id,
      name: restData.displayName,
      savedSearchId: restData.savedsearchId,
    }

    if (restData.companyIds) {
      data.companyCount = restData.companyIds.length
    }

    // TODO: Generalize all of this between Industry and Company.
    data.healthData = restData.healthBadge && {
      healthScore: restData.healthBadge.healthScore,
      articleVolume: restData.healthBadge.articleVolume,
      positiveVolume: restData.healthBadge.positive,
      neutralVolume: restData.healthBadge.neutral,
      negativeVolume: restData.healthBadge.negative,
      factors: pipe(
        map(factorHealth => [
          FACTORS_BY_ID[factorHealth.factorId],
          {
            articleVolume: factorHealth.volume,
            positiveVolume: factorHealth.positive,
            negativeVolume: factorHealth.negative,
            neutralVolume: factorHealth.neutral,
            healthScore: factorHealth.healthScore,
          },
        ]),
        fromPairs,
      )(restData.healthBadge.factors),
      subfactors: pipe(
        map(subfactorHealth => [
          SUBFACTORS_BY_ID[subfactorHealth.categoryId],
          {
            articleVolume: subfactorHealth.volume,
            positiveVolume: subfactorHealth.positive,
            negativeVolume: subfactorHealth.negative,
            neutralVolume: subfactorHealth.neutral,
            healthScore: subfactorHealth.healthScore,
            positiveChange: subfactorHealth.todaysPositiveChange,
            negativeChange: subfactorHealth.todaysNegativeChange,
          },
        ]),
        fromPairs,
      )(restData.healthBadge.categories),
    }

    const getDataPoints = pipe(
      map(dataPoint => ({
        // Parse the date and ignore fields we don't care about.
        date: parseDate(dataPoint.storyDate),
        healthScore: dataPoint.healthScore,
        healthScoreChange: dataPoint.healthScoreChange,
      })),
    )
    const dailyHealthDataTransformer = type =>
      pipe(
        groupBy(data =>
          type === 'factors'
            ? FACTORS_BY_ID[data.factorId]
            : SUBFACTORS_BY_ID[data.categoryId],
        ),
        map(
          pipe(
            nth(0),
            prop('dataPoints'),
            getDataPoints,
          ),
        ),
      )
    if (restData.overallDailyHealthDataPoints) {
      data.dailyHealthData = getDataPoints(
        restData.overallDailyHealthDataPoints,
      )
    }
    if (restData.factorDailyHealthData) {
      data.factorDailyHealthData = dailyHealthDataTransformer('factors')(
        restData.factorDailyHealthData,
      )
    }
    if (restData.categoryDailyHealthData) {
      data.subfactorDailyHealthData = dailyHealthDataTransformer('categories')(
        restData.categoryDailyHealthData,
      )
    }

    const stories = restData.topStories
      ? new StoryResource().entityItemsForRestData(restData.topStories)
      : []
    data.topStoriesIds = stories
      .filter(whereEq({entityKey: models.Story.entityKey}))
      .map(prop('id'))

    return [
      {
        id: restData.id,
        entityKey: models.Industry.entityKey,
        data,
      },
      ...stories,
    ]
  }
}
