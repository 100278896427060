import {field} from 'app/framework/fields'
import Model from 'app/framework/Model'

export default class Industry extends Model {
  static entityKey = 'industries'

  static fields = {
    name: field(),
    savedSearchId: field({required: false}),
    companyCount: field({required: false}),
    healthData: field({required: false}),
    dailyHealthData: field({required: false}),
    factorDailyHealthData: field({required: false}),
    subfactorDailyHealthData: field({required: false}),
  }
}
