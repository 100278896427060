import PropTypes from 'prop-types'
import React, {useContext} from 'react'

import {SENDGRID_TRACKING_PLACEHOLDER} from 'app/constants'
import urls from 'app/urls'
import {EmailAlertContext} from 'email-server-common/context'
import {mainColor} from 'email-server/style/palette'

const Link = ({href, children, style, noSsoRedirect = false, ...props}) => {
  const {firmId, ssoLoginUrl} = useContext(EmailAlertContext)
  if (firmId && ssoLoginUrl && !noSsoRedirect) {
    href = urls.pyapp
      .ssoRedirect({url: href, firmId})
      .replace(
        encodeURIComponent(SENDGRID_TRACKING_PLACEHOLDER),
        SENDGRID_TRACKING_PLACEHOLDER,
      )
  }
  const combinedStyle = {
    color: mainColor,
    textDecoration: 'none',
    ...style,
  }
  return (
    <a href={href} style={combinedStyle} {...props}>
      {children}
    </a>
  )
}
Link.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
  noSsoRedirect: PropTypes.bool,
}
export default Link
