import React from 'react'
import Table from 'email-server-common/common/Table'
import Row from 'email-server-common/common/Row'
import Cell from 'email-server-common/common/Cell'
import EsgEntityOverview from './EsgEntityOverview'
import PropTypes from 'prop-types'
import dateFns from 'date-fns'
import { STATIC_FILES_URL, USER_DATE_FORMAT } from '../../app/constants'
import { colors } from './constants'
import HtmlReactParser from 'html-react-parser'


/**
 * The header portion of the email.
 * This contains the logo, the target of the email, date and the company/industry ESG table.
 * @param data An object, which has a companies and industries attribute that contains the ESG scores for these
 *             entities.
 * @param rootUrl
 * @param emailType The type of the email, whether it is daily or weekly.
 */
export default function Header({ data, rootUrl, emailType='daily' }) {
  const titles = {
    'daily': 'ESG Daily Digest',
    'weekly': 'ESG Weekly Digest',
  }
  const prefixText = HtmlReactParser(data.emailProfileAlertPrefix)
  
  return (
    <Table
      style={{
        width: '600px',
        margin: '0 auto',
        marginTop: '53px',
        backgroundColor: colors.contentBackgroundColor
      }}
      width='600'
      align='center'
    >
      <Row>
        <Cell
          style={{
            padding: '40px 0 0px 32px',
          }}
        >
          <img
            src={data.firmLogo}
            style={{
              width: '81px',
              display: 'block'
            }}
            alt='Firm Logo'
            width={81}
          />
          <br/>
          <span
            style={{
              fontSize: 40,
              fontWeight: 900,
              margin: 0,
              lineHeight: 'normal',
            }}
          >{titles[emailType]}
          </span>
          <p
            style={{
              fontFamily: 'Source Sans Pro',
              textTransform: 'uppercase',
              fontSize: 12,
              paddingTop: 5,
              margin: 0,
              lineHeight: 'normal',
            }}
          >Prepared for {data.userFullName}<br/>{dateFns.format(new Date(data.isoEmailDate), USER_DATE_FORMAT)}
          </p>
        </Cell>
      </Row>
      <Row>
        <Cell>
          <Table>
            <Row>
              <Cell
                style={{
                  textAlign: 'right',
                  fontWeight: 700,
                  fontSize: 10,
                }}>
                <img src={`${STATIC_FILES_URL}/imgs/Info-circle.png`}
                     alt='ESG Health Score based on the last 365 days'
                     style={{verticalAlign: 'middle', paddingBottom: 3}}/>&nbsp;&nbsp;All ESG Score are based on the last 365 days
              </Cell>
              <Cell width='19'/>
            </Row>
            {
              prefixText ? <Row>
                <Cell
                  style={{
                    padding: '0px 0 20px 32px',
                  }}>
                  {prefixText}
                </Cell>
              </Row> : ""
            }
            <Row>
              <Cell height='19'/>
            </Row>
          </Table>

        </Cell>
      </Row>
      <Row>
        <Cell
          style={{
            padding: '0 0 40px 22px',
          }}
        >
          <EsgEntityOverview entityType='company' emailType={emailType} data={data.companies} rootUrl={rootUrl}/>
          <Table>
            <Row>
              <Cell height='42'/>
            </Row>
          </Table>
          <EsgEntityOverview entityType='industry' emailType={emailType} data={data.industries}/>
        </Cell>
      </Row>
    </Table>
  )
}

Header.propTypes = {
  data: PropTypes.object.isRequired,
}
