import {handleActions} from 'redux-actions'
import * as profileBuilderActions from './profile-builder-actions'

const getInitialState = () => ({
  isLoading: false,

  validationError: {
    field: null,
    message: null,
  },

  currentStep: 1,

  // these can also contain free-text values
  selectedIndustryIds: [null, null, null],
  selectedCompanyIds: [null, null, null],

  // key / value = industry id / array of company ids
  suggestedCompanyIds: {},

  selectedSuggestedCompanyIds: [],

  password: null,
  confirmPassword: null,
  firmLocationId: 0,
  timezone: null,
  role: null,
  locationId: 0,
})

export default handleActions(
  {
    [profileBuilderActions.setValidationError]: (state, action) => ({
      ...state,
      validationError: action.payload,
    }),
    [profileBuilderActions.setCurrentStep]: (state, action) => ({
      ...state,
      currentStep: action.payload,
    }),
    [profileBuilderActions.setSelectedIndustryIds]: (state, action) => ({
      ...state,
      selectedIndustryIds: action.payload,
    }),
    [profileBuilderActions.setSelectedCompanyIds]: (state, action) => ({
      ...state,
      selectedCompanyIds: action.payload,
    }),
    [profileBuilderActions.fetchCompanies]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [profileBuilderActions.setSuggestedCompanyIds]: (state, action) => ({
      ...state,
      isLoading: false,
      suggestedCompanyIds: {
        ...state.suggestedCompanyIds,
        [action.payload.industryId]: action.payload.companyIds,
      },
    }),
    [profileBuilderActions.setSelectedSuggestedCompanyIds]: (
      state,
      action,
    ) => ({
      ...state,
      selectedSuggestedCompanyIds: action.payload,
    }),
    [profileBuilderActions.setPassword]: (state, action) => ({
      ...state,
      password: action.payload,
    }),
    [profileBuilderActions.setConfirmPassword]: (state, action) => ({
      ...state,
      confirmPassword: action.payload,
    }),
    [profileBuilderActions.setLocationId]: (state, action) => ({
      ...state,
      locationId: action.payload,
    }),
    [profileBuilderActions.setFirmLocationId]: (state, action) => ({
      ...state,
      firmLocationId: action.payload,
    }),
    [profileBuilderActions.setTimezone]: (state, action) => ({
      ...state,
      timezone: action.payload,
    }),
    [profileBuilderActions.setRole]: (state, action) => ({
      ...state,
      role: action.payload,
    }),
    [profileBuilderActions.saveUser]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [profileBuilderActions.skip]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
  },
  getInitialState(),
)
