import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {PureComponent} from 'react'

import * as styles from './Checkbox.less'

/**
 * Generic checkbox component. Should replace <input type="checkbox"> elements.
 */
export default class Checkbox extends PureComponent {
  get checked() {
    return this.input.checked
  }

  render() {
    const {isChecked, className, ...restProps} = this.props
    return (
      <input
        type="checkbox"
        className={classNames(styles.checkbox, className)}
        checked={isChecked}
        ref={el => (this.input = el)}
        {...restProps}
      />
    )
  }
}
Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  className: PropTypes.string,
}
