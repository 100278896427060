import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {PureComponent} from 'react'

import * as styles from './styles.less'

/**
 * Generic text box component. Should replace <input type="text"> elements.
 */
export default class TextBox extends PureComponent {
  get value() {
    return this.input.value
  }

  render() {
    const {isPassword, className, ...restProps} = this.props
    return (
      <input
        type={isPassword ? 'password' : 'text'}
        className={classNames(styles.textBox, className)}
        ref={el => (this.input = el)}
        {...restProps}
      />
    )
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.input.focus()
    }
  }
}
TextBox.propTypes = {
  isPassword: PropTypes.bool,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
}
TextBox.defaultProps = {
  isPassword: false,
  autoFocus: false,
}
