import PropTypes from 'prop-types'
import {pipe, prop, reverse, sortBy} from 'ramda'
import React from 'react'

import Link from 'app/common/NavigationLink'
import urls from 'app/urls'

import * as styles from './styles.less'

export default function CompaniesMentioned({storyline, isPortalPage = false}) {
  const companies = pipe(
    sortBy(prop('count')),
    reverse,
  )(storyline.companiesMentioned)
  const companiesMentioned = companies.filter(
    ({id}) => id !== storyline.companyId,
  )
  if (!companiesMentioned.length) {
    return (
      <div className={styles.noCompanies}>
        No {process.env.PRODUCT_NAME}
        -enabled companies are mentioned in this storyline.
      </div>
    )
  }
  const companyItems = companiesMentioned.map(companyInfo => (
    <Link
      href={(isPortalPage ? urls.companyOverviewPortal : urls.companyOverview)(
        companyInfo.id,
      )}
      className={styles.company}
      key={companyInfo.id}
    >
      {companyInfo.name}
    </Link>
  ))

  return <div>{companyItems}</div>
}
CompaniesMentioned.propTypes = {
  storyline: PropTypes.object.isRequired,
  isPortalPage: PropTypes.bool,
}
