import superagent from 'superagent'

/**
 * Loads static assets in a way that's buffered (maximum of one request at a
 * time) and cached for the remainder of the browser session.
 */
export default class ResourceLoader {
  buffer = {}
  cache = {}

  async load(url) {
    if (this.cache[url]) {
      return this.cache[url]
    }
    if (!this.buffer[url]) {
      this.buffer[url] = superagent.get(url)
    }
    const response = await this.buffer[url]
    delete this.buffer[url]
    const body = response.body || response.text
    this.cache[url] = body
    return body
  }
}
