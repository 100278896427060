import classNames from 'classnames'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, {PureComponent} from 'react'

import Button from 'app/common/Button'

import * as styles from './PaginatedTable.less'

export default class PageSelector extends PureComponent {
  static propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
  }

  render() {
    const {currentPage, pageCount} = this.props

    if (pageCount <= 1) return null

    const pagesToShow = R.range(
      Math.max(1, currentPage - 2),
      Math.min(currentPage + 2, pageCount) + 1,
    )
    const isFirstPage = currentPage === 1
    const isLastPage = currentPage === pageCount
    return (
      <div className={styles.pageSelector}>
        {!isFirstPage && (
          <SelectorButton
            label="&lt;"
            isPlainText
            onClick={() => this.props.onPageChange(currentPage - 1)}
          />
        )}

        {pagesToShow[0] !== 1 && (
          <span>
            <PageButton number={1} onClick={() => this.props.onPageChange(1)} />
            <Ellipses />
          </span>
        )}

        {pagesToShow.map(num => (
          <PageButton
            number={num}
            isActive={num === currentPage}
            onClick={() => this.props.onPageChange(num)}
            key={num}
          />
        ))}

        {pagesToShow[pagesToShow.length - 1] !== pageCount && (
          <span>
            <Ellipses />
            <PageButton
              number={pageCount}
              onClick={() => this.props.onPageChange(pageCount)}
            />
          </span>
        )}

        {!isLastPage && (
          <SelectorButton
            label="&gt;"
            isPlainText
            onClick={() => this.props.onPageChange(currentPage + 1)}
          />
        )}
      </div>
    )
  }
}

const PageButton = ({number, isActive = false, ...restProps}) => {
  return (
    <SelectorButton label={number} isPlainText={!isActive} {...restProps} />
  )
}

const SelectorButton = ({className, ...restProps}) => (
  <Button className={classNames(className, styles.button)} {...restProps} />
)

const Ellipses = () => <span style={{margin: '0 0.25em'}}>...</span>
