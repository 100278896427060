
import React from 'react'
import InlineSvg from '../../common/InlineSvg/InlineSvg'

const DiligentLogo = () => {
    const logoUrl = require(`static/images/${
        process.env.MANZAMA_BRAND
      }-logotype-dark.svg`)
    return <InlineSvg
        src={logoUrl}
        style={{ width: "155px", height: "50px" }}
    />
}

export default DiligentLogo
