import dateFns from 'date-fns'
import is from 'is'
import PropTypes from 'prop-types'
import React from 'react'

import urls, {
  toAbsoluteUrl,
  withQueryParams,
  withSendGridTracking,
} from 'app/urls'
import Cell from 'email-server-common/common/Cell'
import HealthScore from 'email-server-common/common/HealthScore'
import PaddedBlock from 'email-server-common/common/PaddedBlock'
import Table from 'email-server-common/common/Table'
import Row from 'email-server-common/common/Row'
import {paddingAmount, spacingAmount} from 'email-server/style/constants'
import {
  healthColorNegative,
  healthColorNeutral,
  healthColorPositive,
  lineColorLight,
} from 'email-server/style/palette'
import {
  hasCompanyHealthScoreChanged,
  sumCompanyHealthScoreChanges,
} from 'email-server/utils'
import {tableCellStyle, tableHeaderCellStyle} from 'email-server/style/styles'
import {ORDERED_FACTORS} from 'app/constants'
import * as strings from 'app/strings'
import Link from 'email-server-common/common/Link'

const ArticleVolumes = ({entity, entityType, productName, rootUrl}) => {
  const cellStyle = {
    ...tableCellStyle,
    paddingTop: paddingAmount,
    paddingBottom: paddingAmount,
  }
  const numericHeaderCellStyle = {
    ...tableHeaderCellStyle,
    textAlign: 'right',
  }
  const numericCellStyle = {
    ...cellStyle,
    textAlign: 'right',
  }
  const overviewPageUrl =
    entityType === 'company' ? urls.companyOverview : urls.industryOverview
  return (
    <Table>
      <Row>
        <Cell
          style={{
            fontSize: 16,
            fontWeight: 'bold',
            paddingBottom: paddingAmount,
          }}
        >
          {productName} Health Scores per Factor This Week
        </Cell>
      </Row>

      <Row>
        <Cell>
          <Table>
            <Row style={{fontWeight: 'bold'}}>
              <Cell style={tableHeaderCellStyle}>Factor</Cell>
              <Cell style={numericHeaderCellStyle}>Health Score</Cell>
            </Row>
            {ORDERED_FACTORS.map(factor => {
              const healthData = entity.healthData.factors[factor] || {}
              const {healthScore} = healthData
              const factorName = strings.factorDisplayName(factor)
              return (
                <Row key={factor}>
                  <Cell style={cellStyle}>
                    <Link
                      href={withSendGridTracking(
                        toAbsoluteUrl(
                          withQueryParams(overviewPageUrl(entity.id), {
                            factor,
                          }),
                          {root: rootUrl},
                        ),
                      )}
                      title={`View ${entity.name} ${factorName} Overview`}
                    >
                      {factorName}
                    </Link>
                  </Cell>
                  <Cell style={numericCellStyle}>
                    {is.number(healthScore) ? (
                      <HealthScore score={healthScore} />
                    ) : (
                      <span style={{color: healthColorNeutral}}>--</span>
                    )}
                  </Cell>
                </Row>
              )
            })}
          </Table>
        </Cell>
      </Row>
    </Table>
  )
}
ArticleVolumes.propTypes = {
  entity: PropTypes.object.isRequired,
  entityType: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  rootUrl: PropTypes.string.isRequired,
}

const ArticlesHeader = ({entity, date, timeFrameDays, productName}) => {
  const hasMultipleStories = entity.topStories.length > 1
  const startDate = dateFns.subDays(date, timeFrameDays)
  const endDate = dateFns.subDays(date, 1)
  return (
    <PaddedBlock
      bottom={paddingAmount / 2}
      style={{
        borderBottom: `1px solid ${lineColorLight}`,
        fontSize: 18,
        fontWeight: 'bold',
      }}
    >
      Top {hasMultipleStories && entity.topStories.length} {productName}{' '}
      {hasMultipleStories ? 'Stories' : 'Story'} for{' '}
      {timeFrameDays === 7 && 'the week of'}{' '}
      {strings.formatDateRange(startDate, endDate)} UTC
    </PaddedBlock>
  )
}
ArticlesHeader.propTypes = {
  entity: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  timeFrameDays: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
}

const HealthScoreSection = ({
  entity,
  entityType,
  date,
  timeFrameDays,
  productName,
  rootUrl,
  shouldShowHealthChanges = false,
}) => {
  const {healthScore} = entity.healthData
  const hasHealthChanged = hasCompanyHealthScoreChanged(entity)
  const healthChange = Math.round(sumCompanyHealthScoreChanges(entity))

  return (
    <PaddedBlock horizontal={paddingAmount}>
      <PaddedBlock top={paddingAmount}>
        <Table>
          <Row>
            <Cell style={{fontSize: 18, paddingBottom: paddingAmount / 2}}>
              Health Score over 30 Days:{' '}
              <HealthScore score={healthScore} style={{fontWeight: 'bold'}} />
            </Cell>
          </Row>

          {shouldShowHealthChanges && (
            <Row>
              {hasHealthChanged ? (
                <span>
                  TOTAL HEALTH CHANGE -{' '}
                  <span
                    style={{
                      color:
                        healthChange > 0
                          ? healthColorPositive
                          : healthColorNegative,
                    }}
                  >
                    {healthChange > 0 ? 'increased' : 'decreased'} by{' '}
                    {Math.abs(healthChange).toFixed(0)} point
                    {Math.abs(healthChange) > 1 && 's'} this week
                  </span>
                </span>
              ) : (
                'NO HEALTH CHANGE THIS WEEK'
              )}
            </Row>
          )}
        </Table>
      </PaddedBlock>

      {shouldShowHealthChanges && entity.healthData && (
        <PaddedBlock top={spacingAmount}>
          <ArticleVolumes
            entity={entity}
            entityType={entityType}
            timeFrameDays={timeFrameDays}
            productName={productName}
            rootUrl={rootUrl}
          />
        </PaddedBlock>
      )}

      <PaddedBlock top={spacingAmount}>
        <ArticlesHeader
          entity={entity}
          date={date}
          timeFrameDays={timeFrameDays}
          productName={productName}
        />
      </PaddedBlock>
    </PaddedBlock>
  )
}
HealthScoreSection.propTypes = {
  entity: PropTypes.object.isRequired,
  entityType: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
  timeFrameDays: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  rootUrl: PropTypes.string.isRequired,
  shouldShowHealthChanges: PropTypes.bool,
}

export default HealthScoreSection
