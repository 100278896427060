import dateFns from 'date-fns'
import invariant from 'invariant'

import {trueRound} from 'app/utils/numbers'

import {
  ASPECTS,
  ENTITY_TYPES,
  GEOGRAPHIES,
  SUBFACTORS,
  FACTORS,
  USER_DATE_FORMAT,
  USER_DATE_FORMAT_NO_YEAR,
  SYSTEM_DATE_FORMAT,
  VALENCES,
} from 'app/constants'

export function entityTypeDisplayName(
  entityType,
  {plural = false, titleCase = false} = {},
) {
  let string
  if (plural) {
    string = {
      [ENTITY_TYPES.COMPANY]: 'companies',
      [ENTITY_TYPES.INDUSTRY]: 'industries',
    }[entityType]
  } else {
    string = {
      [ENTITY_TYPES.COMPANY]: 'company',
      [ENTITY_TYPES.INDUSTRY]: 'industry',
    }[entityType]
  }
  invariant(string, `'${entityType}' is not a valid entity type.`)
  if (titleCase) {
    return string[0].toUpperCase + string.slice(1)
  }
  return string
}

export function valenceDisplayName(valence) {
  const string = {
    [VALENCES.POSITIVE]: 'Positive',
    [VALENCES.NEGATIVE]: 'Negative',
    [VALENCES.NEUTRAL]: 'Neutral',
  }[valence]
  invariant(string, `'${valence}' is not a valid valence.`)
  return string
}

export function factorDisplayName(factor) {
  const string = {
    [FACTORS.DEALS]: 'Partners & Competitors',
    [FACTORS.EXECS]: 'Management',
    [FACTORS.GOVERNMENT]: 'Government',
    [FACTORS.FINANCE]: 'Financials',
    [FACTORS.OPERATIONS]: 'Operations',
    [FACTORS.PRODUCT]: 'Products & Services',
  }[factor]
  invariant(string, `'${factor}' is not a valid factor.`)
  return string
}

export function subfactorDisplayName(subfactor) {
  const string = {
    [SUBFACTORS.ANALYST]: 'Analyst',
    [SUBFACTORS.ATTACK_DISASTER]: 'Attacks & Disasters',
    [SUBFACTORS.BANKRUPTCY]: 'Bankruptcy',
    [SUBFACTORS.COMPETITION]: 'Competition',
    [SUBFACTORS.CRIME_WHITE_COLLAR]: 'Misconduct',
    [SUBFACTORS.CYBER]: 'Cyber Issues',
    [SUBFACTORS.EXECUTIVES]: 'Executives',
    [SUBFACTORS.EXECUTIVE_MOVEMENT]: 'Executive Movements',
    [SUBFACTORS.EXPAND_CONTRACT]: 'Expansion & Contraction',
    [SUBFACTORS.FINANCES]: 'Financials',
    [SUBFACTORS.INSIDER_STOCK]: 'Insider Transactions',
    [SUBFACTORS.IP]: 'Intellectual Property',
    [SUBFACTORS.LABOR]: 'Employees',
    [SUBFACTORS.MERGERS_AND_ACQUISITIONS]: 'Mergers & Acquisitions',
    [SUBFACTORS.PARTNERSHIP]: 'Deals',
    [SUBFACTORS.POLITICS]: 'Politics',
    [SUBFACTORS.PRODUCT]: 'Product',
    [SUBFACTORS.PUBLICITY]: 'Public Sentiment',
    [SUBFACTORS.RECALL]: 'Product Liability',
    [SUBFACTORS.REGULATION]: 'Regulations',
    [SUBFACTORS.SALES]: 'Sales',
    [SUBFACTORS.SHAREHOLDERS]: 'Shareholders',
    [SUBFACTORS.STOCK]: 'Stock News',
    [SUBFACTORS.SUPPLY_CHAIN]: 'Supply Chain',
    [SUBFACTORS.TAXES]: 'Taxes',
  }[subfactor]
  invariant(string, `'${subfactor}' is not a valid subfactor.`)
  return string
}

export function aspectDisplayName(aspect) {
  const string = {
    [ASPECTS.LITIGATION]: 'Litigation',
    [ASPECTS.OPINION]: 'Opinion',
    [ASPECTS.RUMOR]: 'Rumor',
  }[aspect]
  invariant(string, `'${aspect}' is not a valid aspect.`)
  return string
}

export function geographyDisplayName(geography) {
  const string = {
    [GEOGRAPHIES.ALL]: 'US & Global',
    [GEOGRAPHIES.UK]: 'UK & Ireland',
    [GEOGRAPHIES.CANADA]: 'Canada',
    [GEOGRAPHIES.AUSTRALIA]: 'Australasia',
    [GEOGRAPHIES.EUROPE]: 'Europe',
    [GEOGRAPHIES.ASIA]: 'Asia',
    [GEOGRAPHIES.AFRICA]: 'Africa',
    [GEOGRAPHIES.LATAM]: 'Latin America',
    [GEOGRAPHIES.MIDEAST]: 'Middle East',
  }[geography]
  invariant(string, `'${geography}' is not a valid geography.`)
  return string
}

export function formatDate(date, {showYear = true} = {}) {
  const format = showYear ? USER_DATE_FORMAT : USER_DATE_FORMAT_NO_YEAR
  return dateFns.format(date, format)
}

export function formatSystemDate(date) {
  return dateFns.format(date, SYSTEM_DATE_FORMAT)
}

export function formatDateRange(
  startDate,
  endDate,
  {toSeparator = '-', showRedundantMonths = true, showYear = true} = {},
) {
  const format = date => formatDate(date, {showYear})
  if (dateFns.isSameDay(startDate, endDate)) {
    return format(startDate, {showYear})
  }
  if (!showRedundantMonths && dateFns.isSameMonth(startDate, endDate)) {
    const yearPart = showYear ? `, ${dateFns.format(endDate, 'YYYY')}` : ''
    return `${formatDate(startDate, {
      showYear: false,
    })} ${toSeparator} ${dateFns.format(endDate, 'D')}${yearPart}`
  }
  if (dateFns.isSameYear(startDate, endDate)) {
    return `${formatDate(startDate, {showYear: false})} ${toSeparator} ${format(
      endDate,
      {showYear},
    )}`
  }
  return `${format(startDate)} ${toSeparator} ${format(endDate)}`
}

export function dateFromMonthString(monthString) {
  const [year, month] = monthString.split('-')
  return new Date(year, month - 1)
}

export function monthDisplayName(monthIndex) {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][monthIndex]
}

export function monthShortDisplayName(monthIndex) {
  return monthDisplayName(monthIndex).slice(0, 3)
}

export function formatNumber(number) {
  if (window.Intl && window.Intl.NumberFormat) {
    return new Intl.NumberFormat().format(number)
  }
  // If the browser doesn't support the international number format API (IE10),
  // just show the number as-is.
  return number
}

export function formatHealthScore(score) {
  const sign = score > 0 ? '+' : ''
  return `${sign}${trueRound(score)}`
}

export function articleCount(count, {shouldDisplayTotal = false} = {}) {
  let suffix = 'Article'
  if (shouldDisplayTotal) {
    suffix = 'Total ' + suffix
  }
  if (count === 1) {
    return `${formatNumber(count)} ${suffix}`
  }
  return `${formatNumber(count)} ${suffix}s`
}

export function storyCount(count) {
  if (count === 1) {
    return `${count} Story`
  }
  return `${count} Stories`
}

export function storylineCount(count) {
  if (count === 1) {
    return `${count} Storyline`
  }
  return `${count} Storylines`
}

export function dateRangeSuffix(startDate, endDate) {
  const range = dateFns.isSameDay(startDate, endDate)
    ? `on ${formatDate(startDate)}`
    : `from ${formatDateRange(startDate, endDate, {toSeparator: 'to'})}`
  return `${range} UTC`
}

export function viaSource(article) {
  if (!article.feedName) return
  return `via ${article.feedName}`
}

export function leftPadZeroes(number, digits) {
  return ('0'.repeat(digits) + number).slice(-digits)
}
