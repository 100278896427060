import classNames from 'classnames'
import React from 'react'

import InsightsLogo from 'app/common/logos/insights-logo'
import {SUPPORT_EMAIL} from 'app/constants'

import * as styles from './ErrorPage.less'
import mainImageUrl from 'static/images/manzama-error.png'

const ErrorPage = () => (
  <div id="error-page" className={styles.page}>
    <div className={styles.columns}>
      <div className={styles.imageColumn}>
        <img className={styles.image} src={mainImageUrl} alt="Error Image" />
      </div>

      <div className={styles.textColumn}>
        <InsightsLogo
          className={classNames(styles.logo, styles[process.env.MANZAMA_BRAND])}
        />

        <div className={styles.text}>
          <p className={styles.sorry}>Something went wrong.</p>
          <p>
            {process.env.BRAND_NAME} {process.env.PRODUCT_NAME} experienced an
            unexpected error, and our team has been notified. In the meantime,
            please <a href="">refresh the page</a> and try again. If the problem
            persists, please contact{' '}
            <a href={`mailto:${SUPPORT_EMAIL}`}>support</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default ErrorPage
