import {withQueryParams} from 'app/urls'

import {STATE_PROP_NAME} from './router-constants'

export const getRouterState = state => state[STATE_PROP_NAME]
export const getCurrentRoute = state => getRouterState(state).route
export const getCurrentUrl = state => {
  const routerState = getRouterState(state)
  return withQueryParams(routerState.pathname, routerState.query)
}
