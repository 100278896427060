import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Button from 'app/common/Button'
import FacebookIcon from 'app/social/icons/FacebookIcon'

import * as styles from './FacebookShareButton.less'

export default function FacebookShareButton({
  url,
  className,
  isDisabled = false,
}) {
  const encodedUrl = encodeURIComponent(url)
  const shareUrl = `https://www.facebook.com/sharer.php?u=${encodedUrl}`
  return (
    <a
      href={shareUrl}
      target="_blank"
      className={classNames(styles.facebookShareButton, className)}
    >
      <Button
        label={<FacebookShareButtonLabel />}
        isDisabled={isDisabled}
        className={classNames(styles.button, {[styles.disabled]: isDisabled})}
      />
    </a>
  )
}
FacebookShareButton.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
}

const FacebookShareButtonLabel = () => (
  <span className={styles.contents}>
    <FacebookIcon className={styles.icon} />
    Facebook
  </span>
)
