import PropTypes from 'prop-types'
import {merge} from 'ramda'
import React from 'react'
import {connect} from 'react-redux'

import ChartLegend from 'app/common/charting/ChartLegend'
import * as actions from 'app/overviews/company/company-overview-actions'

function EventTimelineLegend({
  highlightGroups,
  clearHighlightedGroups,
  ...restProps
}) {
  return (
    <ChartLegend
      onHighlightGroup={groupId => highlightGroups([groupId])}
      onClearHighlightedGroup={clearHighlightedGroups}
      {...restProps}
    />
  )
}
EventTimelineLegend.propTypes = merge(ChartLegend.propTypes, {
  highlightGroups: PropTypes.func.isRequired,
  clearHighlightedGroups: PropTypes.func.isRequired,
})
// TODO: Stop using the company overview state and use our own local state
// instead.
export default connect(
  undefined,
  {
    highlightGroups: actions.timeline.highlightGroups,
    clearHighlightedGroups: actions.timeline.clearHighlightedGroups,
  },
)(EventTimelineLegend)
