import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import TrendingIcon from 'app/litigation/icons/TrendingIcon'
import Dropdown from 'app/common/Dropdown'
import NavigationLink from 'app/common/NavigationLink'
import urls from 'app/urls'
import GrowthBadge from 'app/litigation/GrowthBadge'
import SeeMoreIcon from 'app/litigation/icons/SeeMoreIcon'
import { abbreviateNumber } from 'app/litigation/utils'
import * as styles from './TrendingSectorSorter.less'
import * as actions from 'app/litigation/litigations-actions'
import QuestionMark from 'app/litigation/icons/QuestionMark'
import { Tooltip } from 'app/common/tooltip'
import { useDispatch } from 'react-redux'
import { FILTER_CHECKBOXES } from '../litigations-constants'

const trendingOptions = [
  {
    label: 'Relative sector growth',
    value: 'relative-sector-growth',
    sortFunction(a, b) {
      if (a.litigationCountPercent > b.litigationCountPercent) {
        return -1
      } else if (a.litigationCountPercent < b.litigationCountPercent) {
        return 1
      }
      return 0
    },
  },
  {
    label: 'Absolute sector growth',
    value: 'absolute-sector-growth',
    sortFunction(a, b) {
      if (a.litigationCountDelta > b.litigationCountDelta) {
        return -1
      } else if (a.litigationCountDelta < b.litigationCountDelta) {
        return 1
      }
      return 0
    },
  },
]

/**
 * Display top sectors with a selectable sorting method.
 *
 * @param sectors The list of sectors.
 * @param maxNumberOfSectors Number of sectors to display.
 * @returns {JSX.Element}
 */
export default function TrendingSectorSorter({ sectors, maxNumberOfSectors }) {
  const dispatch = useDispatch()

  const [trendingSelection, setTrendingSelection] = useState(trendingOptions[0])
  const sortedSectors = useMemo(
    () => [...sectors].sort(trendingSelection.sortFunction), [sectors, trendingSelection])

  const handleOnClick = (sector) => {
    const sectors = [sector.id]
    dispatch(actions.setIsOnMainPage(false))
    dispatch(actions.setSelectedSectors(sectors))
    dispatch(actions.fetchCompaniesForIndustries(sectors))
    // clear my selected companies and select ALL INDUSTRIES
    dispatch(actions.setSelectedMyCompanyIds([]))
    dispatch(actions.setSelectedCompanies([]))
    dispatch(actions.setSelectedFilter(FILTER_CHECKBOXES.allIndustries))
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Trending Last 90 days
        <Tooltip
          label={'Relative Sector Growth shows the number of companies growing within a sector in proportion to ' +
            'all the companies growing within the sector.  Absolute Sector Growth shows the number of companies growing ' +
            'within a sector in proportion to all the companies growing within all the sectors'} direction={'left'}
        >
          <QuestionMark className={styles.inlineIcon}/>
        </Tooltip>
        <TrendingIcon className={styles.trendingIcon}/>
      </h3>
      <Dropdown
        options={trendingOptions}
        value={trendingSelection.value}
        onSelect={setTrendingSelection}
        className={styles.dropdown}
      />
      <div className={styles.industryLinks}>
        {sortedSectors.slice(0, maxNumberOfSectors).map(sector => {
          return (
            <div key={sector.id} onClick={()=> handleOnClick(sector)} className={styles.industryLink}>
              <div className={styles.verticalCenter}>
                {sector.name} ({abbreviateNumber(sector.litigationCountDelta ? sector.litigationCountDelta : 0)})
              </div>
              <div className={styles.verticalCenter}>
                <GrowthBadge growth={(sector.litigationCountPercent ? sector.litigationCountPercent : 0) * 100}/>
                <SeeMoreIcon className={styles.arrow}/>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

TrendingSectorSorter.propTypes = {
  sectors: PropTypes.array.isRequired,
  maxNumberOfSectors: PropTypes.number,
}

TrendingSectorSorter.defaultProps = {
  maxNumberOfSectors: 3,
}
