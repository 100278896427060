import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import InlineSvg from 'app/common/InlineSvg'

import * as styles from './StarIcon.less'
import starIconUrl from 'static/images/star.svg'

const StarIcon = ({className, isHighlighted = false, ...restProps}) => (
  <InlineSvg
    src={starIconUrl}
    className={classNames(styles.icon, className, {
      [styles.highlighted]: isHighlighted,
    })}
    {...restProps}
  />
)
StarIcon.propTypes = {
  className: PropTypes.string,
  isHighlighted: PropTypes.bool,
}

export default StarIcon
