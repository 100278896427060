import classNames from 'classnames'
import dateFns from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'

import ArticleLink from 'app/common/ArticleLink'
import ShareIcon from 'app/common/icons/share'
import FlagIcon from 'app/common/icons/FlagIcon'
import Link from 'app/common/NavigationLink'
import InsightSummary from 'app/common/insight-summary'
import * as shareArticleModal from 'app/share-article-modal'
import * as flagArticleModal from 'app/flag-article-modal'
import * as storyModal from 'app/story-modal'
import * as strings from 'app/strings'
import urls from 'app/urls'
import {getExcerpt} from 'app/utils/article'

import * as styles from './StoryCard.less'
import {createSelector} from 'reselect'
import * as flaggingSelectors from 'app/flag-article-modal/flag-article-modal-selectors'
import {prop} from 'ramda'

const StoryCard = ({
  story,
  shouldShowCompanyName = true,
  shouldShowStoryline = true,
  isPortalPage = false,
  className,
  companyNameClassName,
  shareStory,
  shareArticle,
  flagArticle,
  flaggingDirectorIds,
}) => {
  const article = story.topArticle
  const {storyline} = story
  const storylineDayCount =
    dateFns.differenceInDays(storyline.endDate, storyline.startDate) + 1
  return (
    <div className={classNames(styles.story, className)}>
      {shouldShowCompanyName &&
        (story.company.entityType === 'INDUSTRY' ? (
          <span
            className={classNames(
              styles.companyName,
              companyNameClassName,
              'company-name',
            )}
          >
            {story.company.name}
          </span>
        ) : (
          <Link
            href={
              isPortalPage
                ? urls.companyOverviewPortal(story.company.id)
                : urls.companyOverview(story.company.id)
            }
            className={classNames(
              styles.companyName,
              companyNameClassName,
              'company-name',
            )}
          >
            {story.company.name}
          </Link>
        ))}

      <div className={styles.content}>
        <div className={styles.article}>
          <InsightSummary
            factor={story.factor}
            subfactor={story.subfactor}
            valence={story.valence}
            isOpinion={story.isOpinion}
            isRumor={story.isRumor}
            isLitigation={story.isLitigation}
            className={styles.signalSummary}
          />

          <div className={styles.body}>
            <div className={styles.articleSection}>
              <div className={classNames(styles.headline, 'headline')}>
                <ArticleLink article={article} shouldTargetNewWindow={true}>
                  {story.summary}
                </ArticleLink>
              </div>

              <div className={classNames(styles.excerpt, 'excerpt')}>
                {getExcerpt(article.description)}
              </div>
            </div>

            <div className={styles.shareFlagContainer}>
              <div className={styles.shareSection}>
                <ShareIcon
                  onClick={() => shareArticle(article.id)}
                  className="share-icon"
                />
              </div>
              {!article.isOld() && (
                <div className={styles.flagSection}>
                  <FlagIcon
                    onClick={() => flagArticle(article.id)}
                    className="flag-icon"
                    isFlagged={flaggingDirectorIds.includes(
                      article.contentDirectorId,
                    )}
                  />
                </div>
              )}
            </div>
          </div>

          <div className={styles.meta}>
            <div className={classNames(styles.date, 'date')}>
              {strings.formatDate(story.date)}{' '}
              {article.feedName && (
                <span className={classNames(styles.feed, 'feed')}>
                  {strings.viaSource(article)}
                </span>
              )}
            </div>

            <div className={styles.articleCount}>
              <a onClick={() => shareStory(story.id)} className="article-count">
                {story.articleCount} Article
                {story.articleCount > 1 && 's'} in this Story
              </a>
            </div>
          </div>
        </div>

        {shouldShowStoryline && (
          <div className={styles.storyline}>
            <div className={classNames(styles.fromStoryline, 'from-storyline')}>
              Part of Storyline:{' '}
              <Link
                href={
                  isPortalPage
                    ? urls.storylineViewPortal(story.storylineId)
                    : urls.storylineView(story.storylineId)
                }
                className="storyline-link"
              >
                &ldquo;
                {storyline.summary}
                &rdquo;
              </Link>
            </div>

            <div className={classNames(styles.articleCount, 'article-count')}>
              {strings.articleCount(storyline.articleCount)} over{' '}
              {storylineDayCount} day
              {storylineDayCount > 1 ? 's' : ''}{' '}
              {strings.dateRangeSuffix(storyline.startDate, storyline.endDate)}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
StoryCard.propTypes = {
  story: PropTypes.object.isRequired,
  shouldShowCompanyName: PropTypes.bool,
  shouldShowStoryline: PropTypes.bool,
  isPortalPage: PropTypes.bool,
  className: PropTypes.string,
  companyNameClassName: PropTypes.string,
  flaggingDirectorIds: PropTypes.array.isRequired,

  // Actions
  shareStory: PropTypes.func.isRequired,
  shareArticle: PropTypes.func.isRequired,
  flagArticle: PropTypes.func.isRequired,
}
export default connect(
  createSelector(
    [flaggingSelectors.getFlaggings],
    flaggings => {
      return {
        flaggingDirectorIds: flaggings.map(prop('director')),
      }
    },
  ),
  {
    shareStory: storyId => storyModal.actions.showModal({storyId}),
    shareArticle: articleId => shareArticleModal.actions.showModal({articleId}),
    flagArticle: articleId => flagArticleModal.actions.showModal({articleId}),
  },
)(StoryCard)
