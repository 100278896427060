import * as jwt from 'jsonwebtoken'

import {JWT_LOCAL_STORAGE_KEY} from 'app/constants'
import localStorage from 'app/localStorage'
import changeCaseObject from 'app/utils/change-case-object'

export function getCurrentToken() {
  const token = localStorage.get(JWT_LOCAL_STORAGE_KEY)
  if (MOCK_BASE_API) {
    const token = selfSignToken()
    setCurrentToken(token)
    return token
  }
  return token
}

export function setCurrentToken(token) {
  return localStorage.set(JWT_LOCAL_STORAGE_KEY, token)
}

export function clearCurrentToken() {
  return localStorage.remove(JWT_LOCAL_STORAGE_KEY)
}

export function decode(token) {
  return changeCaseObject.camelCase(jwt.decode(token))
}

export function getExpiration(token) {
  return decode(token).exp
}

export function selfSignToken() {
  if (!MOCK_BASE_API) {
    throw new Error('selfSignToken should not be called in production.')
  }
  // We're self-signing the JWT for development.
  return jwt.sign(
    {
      user_id: 0, // We need a user ID that's clearly not real.
      role: 'signals',
    },
    process.env.DEV_JWT_SECRET,
    {expiresIn: 60 * 60 * 24 * 7}, // One week
  )
}
