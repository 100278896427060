import is from 'is'
import {LOCATION_CHANGED} from 'redux-little-router'
import {put, select, takeLatest} from 'redux-saga/effects'

import {
  fetchArticles,
  fetchCompanies,
  fetchStories,
} from 'app/api/api-saga-helpers'
import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import {actions as globalActions} from 'app/global'
import Article from 'app/models/Article'
import StoryResource from 'app/resources/Story'
import routes from 'app/routes'
import {waitForProfileData} from 'app/utils/sagas'

import * as actions from './interim-page-actions'

function* locationChanged(action) {
  // If we're not logged in, wait until we are.
  yield* waitForProfileData()

  const {ids} = action.payload.params
  const [contentDirectorId, savedSearchId] = ids
    .split('-')
    .map(id => parseInt(id, 10))

  const companyResponse = yield yield* fetchCompanies({
    savedSearchId: savedSearchId,
  })
  // TODO: Handle failure.
  if (is.array.empty(companyResponse.result)) {
    yield put(globalActions.setNotFound())
    return
  }

  const companyId = companyResponse.result[0]
  yield put(actions.setCompanyId(companyId))

  const articlesResponse = yield yield* fetchArticles({
    contentDirectorId: contentDirectorId,
    companyId: companyId,
  })
  // TODO: Handle failure.
  if (is.array.empty(articlesResponse.result)) {
    yield put(globalActions.setNotFound())
    return
  }
  const articleId = articlesResponse.result[0]

  const article = yield select(state => {
    const orm = Orm.withEntities(getEntities(state))
    return orm.getById(Article, articleId)
  })
  const {storyId} = article

  yield yield* fetchStories({
    storyIds: [storyId],
    fields: [
      ...StoryResource.requiredFields,
      'storylineId',
      'storyline',
      'companyId',
    ],
  })

  // We have to be careful not to set the articleId until we have the story
  // data; otherwise we'll have a storyId that points to a Story that hasn't
  // been loaded yet.
  yield put(actions.setArticleId(articleId))
}

const isInterimPageLocationChangedAction = action =>
  action.type === LOCATION_CHANGED &&
  action.payload.route === routes.interimPage

export default function* interimPageSaga() {
  yield takeLatest(isInterimPageLocationChangedAction, locationChanged)
}
