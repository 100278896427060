import dateFns from 'date-fns'
import invariant from 'invariant'

import {FACTORS_BY_SUBFACTOR, now, SUBFACTORS_BY_ID} from 'app/constants'
import {field, foreignKey, hasMany} from 'app/framework/fields'
import Model from 'app/framework/Model'

import Company from './Company'
import Story from './Story'

/**
 * The minimum age for an article, in days, to be considered too old to use the
 * Manzama article URL or enable flagging. Note that this is slightly lower than
 * the number of days for which we keep articles in Base (227) to give us some
 * leeway.
 */
const OLD_ARTICLE_AGE = 220

export default class Article extends Model {
  static entityKey = 'articles'

  static fields = {
    company: foreignKey(Company, {required: false}),
    story: foreignKey(Story, {required: false}),
    contentDirectorId: field(),
    headline: field(),
    description: field(),
    url: field(),
    date: field(),
    valence: field(),
    subfactorId: field(),
    // TODO: Split the feed data out into its own model.
    feedName: field({required: false}),
    feedId: field({required: false}),
    perspectiveId: field(),
  }

  static indexes = ['story']

  get subfactor() {
    const subfactor = SUBFACTORS_BY_ID[this.subfactorId]
    invariant(
      subfactor,
      `The story with ID ${this.id} has an invalid subfactorId of '${
        this.subfactorId
      }'.`,
    )
    return subfactor
  }

  get factor() {
    const factor = FACTORS_BY_SUBFACTOR[this.subfactor]
    invariant(
      factor,
      `The story with ID ${this.id} has a subfactorId of '${
        this.subfactorId
      }', which does not match any factors.`,
    )
    return factor
  }

  /**
   * Whether this article is too old to exist in Base.
   * @return {boolean}
   */
  isOld() {
    return dateFns.isBefore(this.date, dateFns.subDays(now, OLD_ARTICLE_AGE))
  }
}
Story.fields.articles = hasMany(Article, {required: false})
Story.fields.topArticle = foreignKey(Article, {required: false})
