import classNames from 'classnames'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import React from 'react'
import {Portal} from 'react-portal'

import Modal from 'app/common/Modal'
import {Tooltip} from 'app/common/tooltip'

import styles from './CglyticsTeaser.less'
import backgroundImageUrl from './cglytics-teaser.png'

function CglyticsTeaserModal({close}) {
  return (
    <Portal>
      <Modal
        onClose={close}
        className={styles.modal}
        contentClassName={styles.content}
      >
        <a href="mailto:support@manzama.com">
          <img
            src={backgroundImageUrl}
            alt="CGLytics &ndash; Governance Analytics"
            className={styles.image}
          />

          <button className={styles.button}>Click Here to Learn More</button>
        </a>
      </Modal>
    </Portal>
  )
}
CglyticsTeaserModal.propTypes = {
  close: PropTypes.func.isRequired,
}

export default class CglyticsTeaser extends React.Component {
  state = {
    isModalOpen: false,
  }

  render() {
    return (
      <React.Fragment>
        <Tooltip label="Governance Analytics — Click to learn more">
          <div
            className={classNames(styles.teaserButton, this.props.className)}
            onClick={bind(this.openModal, this)}
          />
        </Tooltip>

        {this.state.isModalOpen && (
          <CglyticsTeaserModal close={bind(this.closeModal, this)} />
        )}
      </React.Fragment>
    )
  }

  openModal() {
    this.setState({isModalOpen: true})
  }

  closeModal() {
    this.setState({isModalOpen: false})
  }
}
