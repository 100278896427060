import {handleActions} from 'redux-actions'
import * as actions from './charts-actions'

const getInitialState = () => ({
  sectionRef: {},
  askForSectionRef: null,
  isWaiting: false,
  showConfirmationModal: null,
})

export default handleActions(
  {
    [actions.setAskForSectionRef]: (state, action) => ({
      ...state,
      askForSectionRef: action.payload,
    }),
    [actions.setSectionRef]: (state, action) => ({
      ...state,
      sectionRef: action.payload,
    }),
    [actions.setIsWaiting]: (state, action) => ({
      ...state,
      isWaiting: action.payload,
    }),
    [actions.setShowConfirmationModal]: (state, action) => ({
      ...state,
      showConfirmationModal: action.payload,
    }),
  },
  getInitialState(),
)
