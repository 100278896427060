import classNames from 'classnames'
import PropTypes from 'prop-types'
import {prop, sortBy} from 'ramda'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import Button from 'app/common/Button'
import Columns, {Column} from 'app/common/Columns'
import InlineSvg from 'app/common/InlineSvg'
import {LoadingMessage} from 'app/common/loading-message'
import Link from 'app/common/NavigationLink'
import Section from 'app/common/Section'
import TabRow from 'app/common/tab-row'
import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import * as login from 'app/login'
import HealthRangeComparison from 'app/reusable/health/health-range-comparison'
import HealthScoreTrend from 'app/health/HealthScoreTrend'
import TextSeparator from 'app/common/TextSeparator'
import CompanyHealthFactorSummary from 'app/companies/CompanyHealthFactorSummary'
import {CORPORATE_ENTITY_TYPE, ENTITY_TYPES} from 'app/constants'
import * as loginSelectors from 'app/login/login-selectors'
import {Company, Story, Storyline} from 'app/models'
import * as strings from 'app/strings'
import urls, {withQueryParams} from 'app/urls'
import {insertBetweenItems} from 'app/utils'
import {hasHistoricalHealthData} from 'app/utils/health'
import CglyticsTeaser from 'app/reusable/companies/cglytics-teaser'
import MyCompanyStar from 'app/reusable/companies/my-company-star'
import StoryReader from 'app/reusable/stories/story-reader'
import {getCurrentRoute, getCurrentUrl} from 'app/router/router-selectors'
import {isVowel} from 'app/utils/strings'
import routes from 'app/routes'

import * as overviewActions from '../common/overview-actions'
import * as overviewConstants from '../common/overview-constants'
import Filters, {SaveFiltersModal} from '../common/OverviewFilters'
import OverviewHealth from '../common/OverviewHealth'
import * as actions from './company-overview-actions'
import * as constants from './company-overview-constants'
import * as selectors from './company-overview-selectors'
import CompanyHistory from 'app/overviews/company/history/CompanyHistory'
import NoDataPage from './CompanyOverviewNoData'
import CompanyOverviewTimeline from './CompanyOverviewTimeline'
import {SimCirc} from './simcirc'
import SaveConfirmationModal from 'app/common/charts/export-chart/SaveConfirmationModal'

import * as styles from './CompanyOverview.less'
import warningIconUrl from 'static/images/warning.svg'
import Table from 'app/common/Table'

function CompanyOverview({
  company,
  storylines,
  predictedHealthScoreChangesByFactor,
  hasConfidenceInPredictionsByFactor,
  selectedFactor,
  selectedSubfactor,
  timeFrameDays,
  selectedGeography,
  filterDefaults,
  saveFilterDefaults,
  shouldShowFiltersSaveModal,
  showFiltersSaveModal,
  hideFiltersSaveModal,
  isLoading,

  activeTab,
  isPortalPage,

  stories,
  selectedValences,
  selectValence,
  deselectValence,
  storySortOrder,
  changeStorySortOrder,

  selectFactor,
  selectSubfactor,

  selectedQuarter,
  historicalStorylines,
  selectQuarter,
  historicalHealthData,
  historicalStorylineSearchText,
  autoQuarterSearch,
  setHistoricalStorylineSearchText,
  setAutoQuarterSearch,
  quarterlyArticleCounts,

  leadership,
  hasSimCircAccess,

  currentUrl,
}) {
  const onSaveFilterDefaults = applyToAllCompanies => {
    saveFilterDefaults({
      companyId: applyToAllCompanies ? null : company.id,
      factor: selectedFactor,
      subfactor: selectedSubfactor,
      timeFrameDays,
      geography: selectedGeography,
    })
  }

  let contents
  if (isLoading) {
    contents = <LoadingMessage />
  } else {
    const sortedIndustries = sortBy(prop('name'), company.industries)
    contents = (
      <div className={styles.pageContent}>
        <div className={styles.header}>
          <MyCompanyStar company={company} className={styles.star} />

          <h1 className={styles.title}>
            {company.name} &mdash;{' '}
            {activeTab === 'overview'
              ? 'Company Overview'
              : activeTab === 'simcirc'
              ? 'Similar Circumstances'
              : 'Company History'}
            <CglyticsTeaser className={styles.cglyticsTeaser} />
          </h1>

          {company.healthData && activeTab === 'overview' && (
            <a
              href={urls.companyReport(company.id, {
                timeFrameDays,
                geography: selectedGeography,
              })}
              target="_blank"
              className="company-report"
            >
              <Button label="Generate Company Report" isPrimary={true} />
            </a>
          )}
        </div>

        <h2 className={styles.industries}>
          {sortedIndustries.length ? (
            <React.Fragment>
              <span className={styles.label}>
                {sortedIndustries.length > 1 ? 'Industries' : 'Industry'}:
              </span>
              {insertBetweenItems(
                sortedIndustries.map(industry => (
                  <Link
                    href={urls.industryOverview(industry.id)}
                    className={classNames(styles.industry, 'industry-link')}
                    key={industry.id}
                  >
                    {industry.name}
                  </Link>
                )),
                index => (
                  <TextSeparator key={`separator-${index}`} />
                ),
              )}
            </React.Fragment>
          ) : (
            <span className={styles.label}>
              This company is currently not assigned to a
              {isVowel(process.env.PRODUCT_NAME[0]) && 'n'}{' '}
              {process.env.PRODUCT_NAME} industry
            </span>
          )}
        </h2>

        <TabRow activeTab={activeTab}>
          <TabRow.Tab
            id="overview"
            label="Overview"
            url={
              isPortalPage
                ? urls.companyOverviewPortal(company.id)
                : urls.companyOverview(company.id)
            }
          />
          <TabRow.Tab
            id="history"
            label="History"
            url={urls.companyHistory(company.id)}
          />
          {hasSimCircAccess && (
            <TabRow.Tab
              id="simcirc"
              label="SimCirc"
              url={urls.companySimCirc(company.id)}
              disabled={!company.hasSimCircData}
              tooltip={
                company.hasSimCircData
                  ? undefined
                  : 'This company does not have enough data to calculate similar circumstances.'
              }
            />
          )}
        </TabRow>

        {activeTab === 'overview' ? (
          <div className={styles.overviewContents}>
            <div className={styles.filtersBar}>
              <Filters
                selectedFactor={selectedFactor}
                selectedSubfactor={selectedSubfactor}
                selectedTimeFrame={timeFrameDays}
                selectedGeography={selectedGeography}
                filterDefaults={filterDefaults}
                onSaveFilterDefaults={onSaveFilterDefaults}
                className={styles.filters}
              />

              {company.entityType !== CORPORATE_ENTITY_TYPE && (
                <div className={styles.entityTypeWarning}>
                  <InlineSvg src={warningIconUrl} className={styles.icon} />
                  This entity is not optimized for {
                    process.env.PRODUCT_NAME
                  }{' '}
                  corporate analysis.
                </div>
              )}
            </div>
            <OverviewContents
              company={company}
              storylines={storylines}
              predictedHealthScoreChangesByFactor={
                predictedHealthScoreChangesByFactor
              }
              hasConfidenceInPredictionsByFactor={
                hasConfidenceInPredictionsByFactor
              }
              selectedFactor={selectedFactor}
              selectedSubfactor={selectedSubfactor}
              timeFrameDays={timeFrameDays}
              selectedGeography={selectedGeography}
              filterDefaults={filterDefaults}
              onSaveFilterDefaults={showFiltersSaveModal}
              selectFactor={selectFactor}
              selectSubfactor={selectSubfactor}
              stories={stories}
              selectedValences={selectedValences}
              selectValence={selectValence}
              deselectValence={deselectValence}
              storySortOrder={storySortOrder}
              changeStorySortOrder={changeStorySortOrder}
              currentUrl={currentUrl}
              leadership={leadership}
              setHistoricalStorylineSearchText={setHistoricalStorylineSearchText}
              setAutoQuarterSearch={setAutoQuarterSearch}
            />
          </div>
        ) : activeTab === 'history' ? (
          <CompanyHistory
            company={company}
            healthData={historicalHealthData}
            storylines={historicalStorylines}
            searchText={historicalStorylineSearchText}
            autoQuarterSearch={autoQuarterSearch}
            quarterlyArticleCounts={quarterlyArticleCounts}
            selectedQuarter={selectedQuarter}
            selectQuarter={selectQuarter}
            setStorylineSearchText={setHistoricalStorylineSearchText}
          />
        ) : (
          <SimCirc company={company} />
        )}
      </div>
    )
  }

  return (
    <div className="company-overview">
      {contents}
      {shouldShowFiltersSaveModal && (
        <SaveFiltersModal
          entityType={ENTITY_TYPES.COMPANY}
          entityName={company.name}
          onSave={onSaveFilterDefaults}
          onClose={hideFiltersSaveModal}
        />
      )}
    </div>
  )
}
CompanyOverview.propTypes = {
  company: PropTypes.object,
  storylines: PropTypes.arrayOf(PropTypes.object),
  predictedHealthScoreChangesByFactor: PropTypes.object,
  hasConfidenceInPredictionsByFactor: PropTypes.object,
  selectedFactor: PropTypes.string,
  selectedSubfactor: PropTypes.string,
  timeFrameDays: PropTypes.number,
  selectedGeography: PropTypes.number.isRequired,
  filterDefaults: PropTypes.object.isRequired,
  saveFilterDefaults: PropTypes.func.isRequired,
  showFiltersSaveModal: PropTypes.func.isRequired,
  hideFiltersSaveModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,

  activeTab: PropTypes.string.isRequired,
  isPortalPage: PropTypes.bool.isRequired,

  stories: PropTypes.arrayOf(PropTypes.object),
  selectedValences: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectValence: PropTypes.func.isRequired,
  deselectValence: PropTypes.func.isRequired,
  storySortOrder: PropTypes.string.isRequired,
  changeStorySortOrder: PropTypes.func.isRequired,

  selectFactor: PropTypes.func.isRequired,
  selectSubfactor: PropTypes.func.isRequired,

  selectedQuarter: PropTypes.object.isRequired,
  historicalStorylines: PropTypes.arrayOf(PropTypes.object),
  selectQuarter: PropTypes.func.isRequired,
  historicalHealthData: PropTypes.arrayOf(PropTypes.object),
  historicalStorylineSearchText: PropTypes.string,
  autoQuarterSearch: PropTypes.bool,
  setHistoricalStorylineSearchText: PropTypes.func.isRequired,
  setAutoQuarterSearch: PropTypes.func.isRequired,
  quarterlyArticleCounts: PropTypes.arrayOf(PropTypes.object),

  leadership: PropTypes.object,
  hasSimCircAccess: PropTypes.bool.isRequired,
}

function OverviewContents({
  company,
  storylines,
  predictedHealthScoreChangesByFactor,
  hasConfidenceInPredictionsByFactor,
  selectedFactor,
  selectedSubfactor,
  timeFrameDays,
  filterDefaults,

  stories,
  selectedValences,
  selectValence,
  deselectValence,
  storySortOrder,
  changeStorySortOrder,

  selectFactor,
  selectSubfactor,

  currentUrl,
  leadership,
  setHistoricalStorylineSearchText,
  setAutoQuarterSearch
}) {
  if (!company || !company.dailyHealthData) {
    return <LoadingMessage />
  }

  const isLawFirm = company.entityType === 'LAW_FIRM'
  if (!hasHistoricalHealthData(company) || isLawFirm) {
    return (
      <NoDataPage
        entityType="company"
        isLawFirm={isLawFirm}
        shouldShow90DayFilter={timeFrameDays === 30}
        shouldShowExpandFiltersMessage={
          selectedFactor !== filterDefaults.factor ||
          selectedSubfactor !== filterDefaults.subfactor
        }
      />
    )
  }

  const healthBadgeTitle = `${
    selectedSubfactor ? 'Subfactor' : selectedFactor ? 'Factor' : 'Overall'
  } Health - Current 30-Day Health Score`
  const recentStoriesTitle =
    selectedSubfactor || selectedFactor
      ? `Recent ${
          selectedSubfactor
            ? strings.subfactorDisplayName(selectedSubfactor)
            : strings.factorDisplayName(selectedFactor)
        } Storylines`
      : 'Recent Storylines by Factor'
  const healthSummaryTitle = `Health Factors - Current 30-Day ${
    selectedFactor
      ? strings.factorDisplayName(selectedFactor) +
        (selectedSubfactor
          ? ` - ${strings.subfactorDisplayName(selectedSubfactor)}`
          : '')
      : ''
  } Health Score`

  const trendChartKey = `health-score-trend-chart::'CompanyOverview'-${
    company.id
  }-${timeFrameDays}`
  const storyChartKey = `story-timeline-chart::'CompanyOverview'-${
    company.id
  }-${timeFrameDays}`
  const healthBadgeChartKey = `health-badge-chart::'CompanyOverview'-${
    company.id
  }-${timeFrameDays}`
  const HealthFactorsKey = `health-factors::'CompanyOverview'-${
    company.id
  }-${timeFrameDays}`

  const leadershipData = leadership && leadership.companyId
    ? leadership.leadershipData.map(ld => {
      const role = ld.roleDescription
        ? `${ld.roleName} (${ld.roleDescription})`
        : ld.roleName
      return ({
        individualName: ld.individualName,
        roleName: role,
      })
    })
    : null

  const handleLeaderOnClick = (searchHistoryName) => {
    setHistoricalStorylineSearchText(searchHistoryName)
    setAutoQuarterSearch(true)
  }

  const leaderLink = (leader) => {
    const names = leader.individualName.split(',')
    const firstName = names.length === 2
      ? names[1].replace(' ', '')
      : null
    const lastName = firstName
      ? names[0].replace(' ', '')
      : null
    const leaderName = firstName
      ? `${firstName} ${lastName}`
      : leader.individualName
    const searchHistoryName = lastName
      ? lastName
      : leaderName

    return (
      <Link
        href={urls.companyHistory(company.id)}
        onClick={() => handleLeaderOnClick(searchHistoryName)}
        key={leader.individualName}
      >
        <span className={'leadership-cell-content'}>
          {leaderName}
        </span>
      </Link>
    )
  }

  return (
    <React.Fragment>
      <Columns>
        <Column>
          <Section
            className={styles.health}
            contentClassName={styles.content}
            title={healthBadgeTitle}
            chartKey={healthBadgeChartKey}
          >
            <div className={styles.healthBadge}>
              <OverviewHealth
                healthData={company.healthData}
                selectedFactor={selectedFactor}
                selectedSubfactor={selectedSubfactor}
                selectFactor={selectFactor}
                selectSubfactor={selectSubfactor}
              />
            </div>

            {company.healthData.healthScore !== null && (
              <div className={styles.healthRangeComparison}>
                <HealthRangeComparison
                  healthData={company.healthData}
                  factor={selectedFactor}
                  subfactor={selectedSubfactor}
                />
              </div>
            )}
          </Section>
        </Column>

        <Column>
          <Section
            className={styles.storyReaderSection}
            contentClassName={styles.content}
            title={recentStoriesTitle}
          >
            <StoryReader
              stories={stories}
              shouldShowCompanyName={false}
              selectedValences={selectedValences}
              sortOrder={storySortOrder}
              shouldSort={false}
              onSelectValence={selectValence}
              onDeselectValence={deselectValence}
              onChangeSortBy={changeStorySortOrder}
              className={styles.storyReader}
            />
          </Section>
        </Column>
      </Columns>

      <Section
        className={styles.healthFactorSummary}
        contentClassName={styles.content}
        title={healthSummaryTitle}
        chartKey={HealthFactorsKey}
      >
        <CompanyHealthFactorSummary
          company={company}
          factor={selectedFactor}
          subfactor={selectedSubfactor}
          predictedHealthScoreChangesByFactor={
            predictedHealthScoreChangesByFactor
          }
          hasConfidenceInPredictionsByFactor={
            hasConfidenceInPredictionsByFactor
          }
          factorPredictionUrl={factor =>
            urls.companySimCircForecast(company.id, {factor})
          }
          factorFilterUrl={factor =>
            withQueryParams(currentUrl, {
              [overviewConstants.FACTOR_QUERY_PARAM]: factor,
            })
          }
          factorTooltip="Click to filter by this factor"
          subfactorFilterUrl={subfactor =>
            withQueryParams(currentUrl, {
              [overviewConstants.SUBFACTOR_QUERY_PARAM]: subfactor,
            })
          }
          subfactorTooltip="Click to filter by this subfactor"
        />
      </Section>

      <Section
        className={styles.healthTrend}
        contentClassName={styles.content}
        title={`Health Score Trend Over ${timeFrameDays} Days`}
        chartKey={trendChartKey}
      >
        <HealthScoreTrend
          dailyHealthData={company.dailyHealthData}
          factorDailyHealthData={company.factorDailyHealthData}
          subfactorDailyHealthData={company.subfactorDailyHealthData}
          factor={selectedFactor}
          subfactor={selectedSubfactor}
          timeFrameDays={timeFrameDays}
        />
      </Section>

      <Section
        className={styles.timeline}
        contentClassName={styles.content}
        title="Story Timeline"
        chartKey={storyChartKey}
      >
        <CompanyOverviewTimeline
          storylines={storylines}
          selectedFactor={selectedFactor}
          selectedSubfactor={selectedSubfactor}
          timeFrameDays={timeFrameDays}
        />
      </Section>

      {leadershipData &&
        <Section
          className={styles.leadershipSection}
          contentClassName={styles.content}
          title={'Leadership'}
        >
          <div className={styles.leadership}>
            <Table
              data={leadershipData}
              defaultSort={{column: 'individualName', direction: 'asc'}}
              baseWidth={'100%'}
            >
              <Table.Column
                className={'leadership-cell'}
                name="individualName"
                label="Name"
                baseWidth={'30%'}
                isSortable={true}
                growRatio={0}
                shrinkRatio={1}
                cellContents={leader => leaderLink(leader)}
              />
              <Table.Column
                className={'leadership-cell'}
                name="roleName"
                label="Role"
                isSortable={true}
                baseWidth={'70%'}
                growRatio={0}
                shrinkRatio={1}
                cellContents={leader => {
                  return <span className={'leadership-cell-content'}>{leader.roleName}</span>
                }}
              />
            </Table>
          </div>
        </Section>
      }

      <SaveConfirmationModal />
    </React.Fragment>
  )
}
OverviewContents.propTypes = {
  company: PropTypes.object.isRequired,
  storylines: PropTypes.arrayOf(PropTypes.object),
  predictedHealthScoreChangesByFactor: PropTypes.object,
  hasConfidenceInPredictionsByFactor: PropTypes.object,
  selectedFactor: PropTypes.string,
  selectedSubfactor: PropTypes.string,
  timeFrameDays: PropTypes.number.isRequired,
  selectedGeography: PropTypes.number.isRequired,
  filterDefaults: PropTypes.object.isRequired,
  onSaveFilterDefaults: PropTypes.func.isRequired,

  stories: PropTypes.arrayOf(PropTypes.object),
  selectedValences: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectValence: PropTypes.func.isRequired,
  deselectValence: PropTypes.func.isRequired,
  storySortOrder: PropTypes.string.isRequired,
  changeStorySortOrder: PropTypes.func.isRequired,

  selectFactor: PropTypes.func.isRequired,
  selectSubfactor: PropTypes.func.isRequired,

  currentUrl: PropTypes.string.isRequired,
  leadership: PropTypes.object,
  setHistoricalStorylineSearchText: PropTypes.func,
  setAutoQuarterSearch: PropTypes.func,
}

export default connect(
  () =>
    createSelector(
      [
        getEntities,
        selectors.getCompanyOverview,
        selectors.getFactorForCurrentCompany,
        selectors.getSubfactorForCurrentCompany,
        selectors.getTimeFrameForCurrentCompany,
        selectors.getGeographyForCurrentCompany,
        selectors.getFilterDefaultsForCurrentCompany,
        selectors.getHistoricalStorylineIds,
        selectors.getSelectedQuarter,
        selectors.getHistoricalHealthData,
        selectors.getHistoricalStorylineSearchText,
        selectors.getAutoQuarterSearch,
        selectors.getQuarterlyArticleCounts,
        selectors.getLeadershipData,
        loginSelectors.getHasSimCircAccess,
        getCurrentRoute,
        getCurrentUrl,
      ],
      (
        entities,
        state,
        selectedFactor,
        selectedSubfactor,
        timeFrameDays,
        selectedGeography,
        filterDefaults,
        historicalStorylineIds,
        selectedQuarter,
        historicalHealthData,
        historicalStorylineSearchText,
        autoQuarterSearch,
        quarterlyArticleCounts,
        leadership,
        hasSimCircAccess,
        currentRoute,
        currentUrl,
      ) => {
        const orm = Orm.withEntities(entities)
        const {storyReader} = state
        const activeTab = constants.OVERVIEW_ROUTES.includes(currentRoute)
          ? 'overview'
          : constants.HISTORY_ROUTES.includes(currentRoute)
          ? 'history'
          : 'simcirc'
        const isPortalPage = currentRoute === routes.companyOverviewPortal
        return {
          company: state.companyId && orm.getById(Company, state.companyId),
          storylines:
            state.storylineIds && orm.getByIds(Storyline, state.storylineIds),
          predictedHealthScoreChangesByFactor:
            state.predictedHealthScoreChangesByFactor,
          hasConfidenceInPredictionsByFactor:
            state.hasConfidenceInPredictionsByFactor,
          selectedFactor,
          selectedSubfactor,
          timeFrameDays,
          selectedGeography,
          filterDefaults,
          shouldShowFiltersSaveModal: state.shouldShowFiltersSaveModal,
          isLoading: state.isLoading,

          activeTab,
          isPortalPage,

          stories:
            storyReader.storyIds && orm.getByIds(Story, storyReader.storyIds),
          selectedValences: storyReader.filters.valences,
          storySortOrder: storyReader.filters.sortOrder,

          // Historical view

          selectedQuarter,
          historicalStorylines:
            historicalStorylineIds &&
            orm.getByIds(Storyline, historicalStorylineIds),
          historicalHealthData,
          historicalStorylineSearchText,
          autoQuarterSearch,
          quarterlyArticleCounts,

          leadership,
          hasSimCircAccess,

          currentUrl,
        }
      },
    ),
  {
    selectFactor: overviewActions.selectFactor,
    selectSubfactor: overviewActions.selectSubfactor,
    selectValence: actions.selectValence,
    deselectValence: actions.deselectValence,
    changeStorySortOrder: actions.setStorySortOrder,
    saveFilterDefaults: filters =>
      login.actions.saveFilterDefaults({
        entityType: ENTITY_TYPES.COMPANY,
        entityId: filters.companyId,
        ...filters,
      }),
    showFiltersSaveModal: actions.showFiltersSaveModal,
    hideFiltersSaveModal: actions.hideFiltersSaveModal,
    selectQuarter: actions.selectQuarter,
    setHistoricalStorylineSearchText: actions.setHistoricalStorylineSearchText,
    setAutoQuarterSearch: actions.setAutoQuarterSearch,
  },
)(CompanyOverview)
