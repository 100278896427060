import PropTypes from 'prop-types'
import React from 'react'

import StoryCard from 'app/reusable/stories/story-card'

import * as styles from './styles.less'

const StoryList = ({stories, colors, className}) => (
  <div className={className}>
    {stories.map((story, index) => (
      <Story story={story} color={colors[index]} key={story.id} />
    ))}
  </div>
)
StoryList.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
}

const Story = ({story, color}) => (
  <div className={styles.story} style={{borderLeftColor: color}}>
    <StoryCard
      story={story}
      shouldShowCompanyName={false}
      shouldShowStoryline={false}
      className={styles.card}
    />
  </div>
)
Story.propTypes = {
  story: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
}

export default StoryList
