import React from 'react'

import {FactorDetailsModal} from 'app/overviews/company/factor-details-modal'
import {AddEntitiesModal} from 'app/reusable/entities/add-modal'
import {ShareArticleModal} from 'app/share-article-modal'
import {FlagArticleModal} from 'app/flag-article-modal'
import {StoryModal} from 'app/story-modal'
import {StorylineModal} from 'app/storyline-modal'

// This is really just a host for all modals. Each modal is responsible for its
// own rendering.
export default function ModalsContainer() {
  return (
    <div>
      <AddEntitiesModal />
      <FactorDetailsModal />
      <ShareArticleModal />
      <FlagArticleModal />
      <StoryModal />
      <StorylineModal />
    </div>
  )
}
