import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import ArticleLink from 'app/common/ArticleLink'
import {LoadingMessage} from 'app/common/loading-message'
import Modal from 'app/common/Modal'
import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import * as login from 'app/login'
import Article from 'app/models/Article'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedInShareButton,
  TwitterShareButton,
} from 'app/social/share-buttons'
import * as storyModalActions from 'app/story-modal/story-modal-actions'
import * as strings from 'app/strings'
import urls from 'app/urls'
import {getExcerpt} from 'app/utils/article'

import * as actions from './share-article-modal-actions'
import * as selectors from './share-article-modal-selectors'

import * as styles from './ShareArticleModal.less'

function ShareArticleModalContents({
  article,
  shouldShowBack,
  userId,
  hideModal,
  showStoryModal,
}) {
  const url = urls.pyapp.article({
    contentDirectorId: article.contentDirectorId,
    userId,
  })
  const goBack = () => {
    hideModal()
    showStoryModal({storyId: article.storyId})
  }
  return (
    <div className={styles.storyContent}>
      <div className={styles.header}>
        <div className={styles.backToStory}>
          {shouldShowBack && (
            <a onClick={() => goBack()}>&lt;&lt; Back to all articles</a>
          )}
        </div>

        <div className={styles.label}>Share Article</div>
      </div>

      <div className={styles.body}>
        <h2 className={styles.headline}>
          <ArticleLink article={article} shouldTargetNewWindow={true}>
            {article.headline}
          </ArticleLink>
        </h2>

        <div className={styles.excerpt}>{getExcerpt(article.description)}</div>

        <div className={styles.meta}>
          <span className={styles.date}>
            {strings.formatDate(article.date)}{' '}
          </span>
          <span className={styles.source}>{strings.viaSource(article)}</span>
        </div>
      </div>

      <div className={styles.footer}>
        <FacebookShareButton
          url={url}
          className={classNames(styles.shareButton, 'share-button-facebook')}
        />
        <LinkedInShareButton
          url={url}
          className={classNames(styles.shareButton, 'share-button-linkedin')}
        />
        <TwitterShareButton
          url={url}
          className={classNames(styles.shareButton, 'share-button-twitter')}
        />
        <EmailShareButton
          url={url}
          subject={article.headline}
          className={classNames(styles.shareButton, 'share-button-email')}
        />
      </div>
    </div>
  )
}
ShareArticleModalContents.propTypes = {
  article: PropTypes.object.isRequired,
  shouldShowBack: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,

  hideModal: PropTypes.func.isRequired,
  showStoryModal: PropTypes.func.isRequired,
}

function ShareArticleModal({
  isVisible,
  article,
  shouldShowBack = false,
  userId,

  // Actions
  hideModal,
  showStoryModal,
}) {
  if (!isVisible) return null

  if (!article) {
    return <LoadingMessage />
  }

  return (
    <Modal
      className={classNames(styles.modal, 'share-article-modal')}
      contentClassName={styles.content}
      onClose={hideModal}
    >
      <ShareArticleModalContents
        article={article}
        shouldShowBack={shouldShowBack}
        userId={userId}
        hideModal={hideModal}
        showStoryModal={showStoryModal}
      />
    </Modal>
  )
}
ShareArticleModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  article: PropTypes.object,
  shouldShowBack: PropTypes.bool,
  userId: PropTypes.number.isRequired,

  // Actions
  hideModal: PropTypes.func.isRequired,
  showStoryModal: PropTypes.func.isRequired,
}
export default connect(
  createSelector(
    [getEntities, selectors.getState, login.selectors.getUserId],
    (entities, modalState, userId) => {
      const {isVisible, articleId, shouldShowBack} = modalState
      const orm = Orm.withEntities(entities)
      return {
        isVisible,
        article: articleId && orm.getById(Article, articleId),
        shouldShowBack,
        userId,
      }
    },
  ),
  {
    hideModal: actions.hideModal,
    showStoryModal: storyModalActions.showModal,
  },
)(ShareArticleModal)
