import classNames from 'classnames'
import React from 'react'

import Column from './Column'

import * as styles from './styles.less'

export default function Columns({children, className}) {
  return <div className={classNames(styles.columns, className)}>{children}</div>
}
Columns.Column = Column

export {Column}
