import Model from "../framework/Model";
import { field } from "../framework/fields";

export default class LitigationSavedSearch extends Model {
  static entityKey = 'litigationSavedSearch'

  static fields = {
    name: field(),
    settings: field(),
    createdAt: field(),
    modifiedAt: field(),
  }
}
