import React from 'react'

import TextSeparator from 'app/common/TextSeparator'

import styles from './MasqueradeBar.less'

export default function MasqueradeBar({userName, unmaskUrl}) {
  return (
    <div className={styles.masqueradeBar}>
      Logged in as {userName}
      <TextSeparator />
      <a className={styles.link} href={unmaskUrl}>
        Return to my profile
      </a>
    </div>
  )
}
