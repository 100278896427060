import {field, foreignKey, hasMany} from 'app/framework/fields'
import Model from 'app/framework/Model'

import Storyline from './Storyline'

export default class RelatedStoryline extends Model {
  static entityKey = 'relatedStorylines'

  static fields = {
    fromStoryline: foreignKey(Storyline),
    toStoryline: foreignKey(Storyline),
    distance: field(),
  }

  static indexes = ['fromStoryline', 'toStoryline']
}

Storyline.fields.relatedStorylines = hasMany(RelatedStoryline, {
  required: false,
  foreignKeyField: 'fromStoryline',
})
