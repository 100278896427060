import {delay, put, select, takeEvery} from 'redux-saga/effects'

import {pyappRequest} from 'app/api'
import {getJwt} from 'app/login/login-selectors'
import urls from 'app/urls'

import * as actions from './my-entity-star-actions'

export function* addSavedSearch(savedSearchId) {
  if (MOCK_BASE_API) {
    console.log(`Adding saved search with ID ${savedSearchId}`)
    return delay(750)
  }
  const token = yield select(getJwt)
  return pyappRequest({
    url: urls.pyapp.api.addSavedSearches(),
    method: 'PUT',
    token,
    data: {
      ids: [savedSearchId],
      emailAlerts: false,
      allowDuplicates: false,
    },
  })
}

export function* removeSavedSearch(savedSearchId) {
  if (MOCK_BASE_API) {
    console.log(`Removing saved search with ID ${savedSearchId}`)
    return delay(750)
  }
  const token = yield select(getJwt)
  return pyappRequest({
    url: urls.pyapp.api.removeSavedSearches(),
    method: 'PUT',
    token,
    data: {
      ids: [savedSearchId],
    },
  })
}

export function* updateSavedSearchEmailSettings({savedSearchIds, frequency}) {
  const token = yield select(getJwt)
  if (MOCK_BASE_API) {
    console.log(
      `Changing email settings for saved search with IDs [${savedSearchIds.join(
        ', ',
      )}]`,
    )
    yield delay(750)
  } else {
    yield pyappRequest({
      url: urls.pyapp.api.editSavedSearchEmailSettings(),
      method: 'PUT',
      token,
      data: {
        ids: savedSearchIds,
        frequency,
      },
    })
  }
}

function* handleConfirmEmailSettings(action) {
  const {savedSearchId, frequency} = action.payload.value
  const {key} = action.payload
  yield put(actions.setEmailSettingsConfirming({key}))
  yield* updateSavedSearchEmailSettings({
    savedSearchIds: [savedSearchId],
    frequency,
  })
  yield put(actions.setEmailSettingsConfirmed({key}))
}

export default function* myEntityStarSaga() {
  yield takeEvery(actions.confirmEmailSettings, handleConfirmEmailSettings)
}
