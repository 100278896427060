import Resource from 'app/framework/Resource'

import Industry from './Industry'

export default class MyIndustry extends Resource {
  static endpoint = 'my_industry'

  entityItemsForRestItem(restData) {
    return new Industry().entityItemsForRestItem(restData)
  }
}
