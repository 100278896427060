import is from 'is'
import {filter, join, last, map, pipe, toPairs, zip} from 'ramda'

export function gql(stringParts, ...expressions) {
  const pairs = zip(stringParts, expressions)
  const outputParts = []
  for (const [string, expression] of pairs) {
    if (is.defined(expression)) {
      outputParts.push(string)
      outputParts.push(graphqlLiteral(expression))
    }
  }
  outputParts.push(last(stringParts))
  return outputParts.join('')
}

export function graphqlLiteral(value) {
  if (is.array(value)) {
    return `[${value.map(graphqlLiteral).join(', ')}]`
  }
  if (is.object(value)) {
    const keyValueStrings = toPairs(value)
      .filter(([key, value]) => is.defined(value))
      .map(([key, value]) => {
        value = graphqlLiteral(value)
        return `${key}: ${value}`
      })
    return `{${keyValueStrings.join(', ')}}`
  }
  if (is.string(value)) {
    return `"${value.replace(/(["\\])/g, '\\$1')}"`
  }
  if (is.function(value)) {
    return value()
  }
  if (is.date(value)) {
    return `"${value.toISOString()}"`
  }
  if (value === null) {
    return 'null'
  }
  return value.toString()
}

export function args(argsObj) {
  return () =>
    pipe(
      toPairs,
      filter(([key, value]) => is.defined(value)),
      map(([key, value]) => `${key}: ${graphqlLiteral(value)}`),
      join(', '),
    )(argsObj)
}
