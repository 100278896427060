import classNames from 'classnames'
import dateFns from 'date-fns'
import PropTypes from 'prop-types'
import {map, pipe, prop, sort} from 'ramda'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import ArticleLink from 'app/common/ArticleLink'
import Modal from 'app/common/Modal'
import Link from 'app/common/NavigationLink'
import InsightSummary from 'app/common/insight-summary'
import {LoadingMessage} from 'app/common/loading-message'
import ShareIcon from 'app/common/icons/share'
import FlagIcon from 'app/common/icons/FlagIcon'
import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import Story from 'app/models/Story'
import * as shareArticleModal from 'app/share-article-modal'
import * as flagArticleModal from 'app/flag-article-modal'
import * as strings from 'app/strings'
import urls from 'app/urls'
import {getExcerpt} from 'app/utils/article'

import * as actions from './story-modal-actions'
import * as selectors from './story-modal-selectors'

import * as styles from './StoryModal.less'
import * as flaggingSelectors from 'app/flag-article-modal/flag-article-modal-selectors'

function StoryModal({
  isVisible,
  story,
  hideModal,
  shareArticle,
  flagArticle,
  flaggingDirectorIds,
}) {
  if (!isVisible) return null

  if (!story) {
    return <LoadingMessage />
  }

  return (
    <Modal
      className={classNames(styles.modal, 'story-modal')}
      contentClassName={styles.content}
      onClose={hideModal}
    >
      <StoryModalContents
        story={story}
        hideModal={hideModal}
        shareArticle={shareArticle}
        flagArticle={flagArticle}
        flaggingDirectorIds={flaggingDirectorIds}
      />
    </Modal>
  )
}
StoryModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  story: PropTypes.object,
  flaggingDirectorIds: PropTypes.array.isRequired,

  // Actions
  hideModal: PropTypes.func.isRequired,
  shareArticle: PropTypes.func.isRequired,
  flagArticle: PropTypes.func.isRequired,
}
export default connect(
  createSelector(
    [getEntities, flaggingSelectors.getFlaggings, selectors.getState],
    (entities, flaggings, modalState) => {
      const {isVisible, storyId} = modalState
      const orm = Orm.withEntities(entities)
      return {
        isVisible,
        story: storyId && orm.getById(Story, storyId),
        flaggingDirectorIds: flaggings.map(prop('director')),
      }
    },
  ),
  {
    hideModal: actions.hideModal,
    shareArticle: shareArticleModal.actions.showModal,
    flagArticle: flagArticleModal.actions.showModal,
  },
)(StoryModal)

function StoryModalContents({
  story,
  hideModal,
  shareArticle,
  flagArticle,
  flaggingDirectorIds,
}) {
  const {storyline} = story
  return (
    <div className={styles.storyContent}>
      <div className={styles.header}>
        <InsightSummary
          valence={story.valence}
          factor={story.factor}
          subfactor={story.subfactor}
          className={styles.signalSummary}
        />
        <h2 className={styles.headline}>{story.summary}</h2>
        <div className={styles.inThisStory}>
          This Story Consists of {strings.articleCount(story.articleCount)} on{' '}
          {strings.formatDate(story.date)}
        </div>
      </div>

      <div className={styles.listContainer}>
        <ArticleList
          story={story}
          hideModal={hideModal}
          shareArticle={shareArticle}
          flagArticle={flagArticle}
          flaggingDirectorIds={flaggingDirectorIds}
        />
      </div>

      <div className={styles.footer}>
        <div className={styles.partOfStoryline}>
          This Story is Part of the Storyline:{' '}
          <Link
            href={urls.storylineView(story.storylineId)}
            onClick={() => hideModal()}
          >
            &ldquo;
            {story.storyline.summary}
            &rdquo;
          </Link>
        </div>

        <div className={styles.articleCount}>
          This Storyline Consists of{' '}
          {strings.articleCount(storyline.articleCount)}{' '}
          {strings.dateRangeSuffix(storyline.startDate, storyline.endDate)}{' '}
        </div>
      </div>
    </div>
  )
}
StoryModalContents.propTypes = {
  story: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  shareArticle: PropTypes.func.isRequired,
  flagArticle: PropTypes.func.isRequired,
  flaggingDirectorIds: PropTypes.array.isRequired,
}

function ArticleList({
  story,
  hideModal,
  shareArticle,
  flagArticle,
  flaggingDirectorIds,
}) {
  const articleList = pipe(
    sort((article1, article2) =>
      dateFns.isBefore(article1.date, article2.date)
        ? 1
        : dateFns.isAfter(article1.date, article2.date)
        ? -1
        : 0,
    ),
    map(article => (
      <ArticleListItem
        article={article}
        hideModal={hideModal}
        shareArticle={shareArticle}
        flagArticle={flagArticle}
        flaggingDirectorIds={flaggingDirectorIds}
        key={article.id}
      />
    )),
  )(story.articles)
  return <div className={styles.list}>{articleList}</div>
}
ArticleList.propTypes = {
  story: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  shareArticle: PropTypes.func.isRequired,
  flagArticle: PropTypes.func.isRequired,
  flaggingDirectorIds: PropTypes.array.isRequired,
}

function ArticleListItem({
  article,
  hideModal,
  shareArticle,
  flagArticle,
  flaggingDirectorIds,
}) {
  const share = () => {
    hideModal()
    shareArticle({articleId: article.id, shouldShowBack: true})
  }
  const flag = () => {
    hideModal()
    flagArticle({articleId: article.id, shouldShowBack: true})
  }
  return (
    <div className={classNames(styles.article, 'article')}>
      <div className={styles.articleSection}>
        <div className={styles.headline}>
          <ArticleLink article={article} shouldTargetNewWindow={true}>
            {article.headline}
          </ArticleLink>
        </div>

        <div className={styles.excerpt}>{getExcerpt(article.description)}</div>

        <div className={styles.meta}>
          <span className={styles.date}>
            {strings.formatDate(article.date)}{' '}
          </span>
          <span className={styles.source}>{strings.viaSource(article)}</span>
        </div>
      </div>

      <div className={styles.shareFlagContainer}>
        <div className={styles.shareSection}>
          <ShareIcon onClick={() => share()} className={styles.icon} />
        </div>
        {!article.isOld() && (
          <div className={styles.flagSection}>
            <FlagIcon
              onClick={() => flag()}
              className={styles.icon}
              isFlagged={flaggingDirectorIds.includes(article.contentDirectorId)}
            />
          </div>
        )}
      </div>
    </div>
  )
}
ArticleListItem.propTypes = {
  article: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  shareArticle: PropTypes.func.isRequired,
  flagArticle: PropTypes.func.isRequired,
  flaggingDirectorIds: PropTypes.array.isRequired,
}
