import classNames from 'classnames'
import React from 'react'

import InlineSvg from 'app/common/InlineSvg'

import * as styles from './ShareIcon.less'
import shareIconUrl from 'static/images/share.svg'

export default function ShareIcon({className, ...props}) {
  return (
    <div className={classNames(styles.container, className)} {...props}>
      <InlineSvg src={shareIconUrl} className={styles.icon} />
    </div>
  )
}
