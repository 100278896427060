import PropTypes from 'prop-types'
import React from 'react'

import Dropdown from 'app/common/Dropdown'
import {GEOGRAPHIES_ORDERED} from 'app/constants'
import * as strings from 'app/strings'

const GeographyDropdown = ({
  selectedGeography,
  onChange,
  className,
  optionsClassName,
}) => (
  <Dropdown
    value={selectedGeography}
    onChange={onChange}
    options={GEOGRAPHIES_ORDERED.map(geography => ({
      label: strings.geographyDisplayName(geography),
      value: geography,
    }))}
    className={className}
    optionsClassName={optionsClassName}
  />
)
GeographyDropdown.propTypes = {
  selectedGeography: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  optionsClassName: PropTypes.string,
}

export default GeographyDropdown
