import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import HealthScore from 'app/common/HealthScore'
import InfoTooltip from 'app/common/InfoTooltip'
import Link from 'app/common/NavigationLink'
import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import Company from 'app/models/Company'
import * as strings from 'app/strings'
import urls from 'app/urls'

import * as actions from './health-volume-matrix-tooltip-actions'
import {getHealthVolumeMatrixState} from './health-volume-matrix-selectors'

import * as styles from './HealthVolumeMatrixTooltip.less'

function HealthVolumeMatrixTooltip({
  isVisible,
  left,
  right,
  top,
  company,
  showTooltip,
  hideTooltip,
}) {
  if (!isVisible) return null

  const healthScore = company.healthData.healthScore
  const valenceClassName =
    healthScore >= 0.5
      ? styles.positive
      : healthScore <= -0.5
      ? styles.negative
      : styles.neutral
  const articleCount = company.healthData.articleVolume

  return (
    <InfoTooltip
      left={left}
      right={right}
      top={top}
      contentClassName={styles.healthVolumeMatrixTooltip}
      onMouseEnter={() =>
        showTooltip({left, right, top, companyId: company.id})
      }
      onMouseLeave={() => hideTooltip()}
    >
      <Link
        href={urls.companyOverview(company.id)}
        style={{display: 'block', color: 'inherit'}}
        onClick={() => hideTooltip()}
      >
        <div className={styles.header}>{company.name}</div>

        <div className={styles.content}>
          <div className={classNames(styles.healthScore, valenceClassName)}>
            <HealthScore score={healthScore} className={styles.healthScore} />
            &nbsp;Health Score
          </div>

          <div className={styles.articleCount}>
            {strings.articleCount(articleCount, {shouldDisplayTotal: true})}
          </div>
        </div>
      </Link>
    </InfoTooltip>
  )
}
HealthVolumeMatrixTooltip.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  left: PropTypes.number,
  right: PropTypes.number,
  top: PropTypes.number,
  company: PropTypes.object,

  // Actions
  showTooltip: PropTypes.func.isRequired,
  hideTooltip: PropTypes.func.isRequired,
}
export default connect(
  createSelector(
    [getHealthVolumeMatrixState, getEntities],
    (state, entities) => {
      if (!state.isVisible) return {isVisible: false}
      const orm = Orm.withEntities(entities)
      return {
        isVisible: true,
        left: state.left,
        right: state.right,
        top: state.top,
        company: orm.getById(Company, state.companyId),
      }
    },
  ),
  {
    showTooltip: actions.show,
    hideTooltip: actions.hide,
  },
)(HealthVolumeMatrixTooltip)
