import PropTypes from 'prop-types'
import React from 'react'

const Cell = ({children, valign, ...props}) => (
  <td valign={valign} {...props}>
    {children || null}
  </td>
)
Cell.propTypes = {
  valign: PropTypes.string,
  children: PropTypes.node,
}
export default Cell
