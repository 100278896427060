import IndustriesTable, {sortByFunctionsByColumn} from './IndustriesTable'
import * as actions from './industries-table-actions'
import * as constants from './industries-table-constants'
import reducer from './industries-table-reducer'
import * as selectors from './industries-table-selectors'

export {
  IndustriesTable,
  sortByFunctionsByColumn,
  actions,
  constants,
  reducer,
  selectors,
}
export default IndustriesTable
