import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Cell from './Cell'
import {valueFromMaybeFunction} from './utils'

function Row({item, columns, className, cellClassName, onClick}) {
  // For cells with colSpan > 1, ignore this many cells after
  let cellsToIgnore = 0

  const cells = columns.map((column, index) => {
    if (cellsToIgnore > 0) {
      cellsToIgnore--
      return null
    }

    let sizeInfo = {
      baseWidth: column.baseWidth,
      minWidth: column.minWidth,
      maxWidth: column.maxWidth,
      growRatio: column.growRatio,
      shrinkRatio: column.shrinkRatio,
    }

    const colSpan = valueFromMaybeFunction(column.colSpan, item, column)
    if (colSpan > 1) {
      cellsToIgnore = colSpan - 1

      // Calculate the new size information for the cell
      const columnsSpanned = columns.slice(index, index + colSpan)

      const attrs = [
        'baseWidth',
        'minWidth',
        'maxWidth',
        'growRatio',
        'shrinkRatio',
      ]
      attrs.forEach(attr => {
        // Sum
        sizeInfo[attr] = columnsSpanned.reduce((sum, column) => {
          // Ignore undefined sizes
          if (column[attr] === null || column[attr] === undefined) return sum
          return (sum || 0) + column[attr]
        }, null)
      })
    }

    return (
      <Cell
        columnName={column.name}
        className={classNames(
          valueFromMaybeFunction(column.className, item, column),
          cellClassName,
        )}
        baseWidth={sizeInfo.baseWidth}
        minWidth={sizeInfo.minWidth}
        maxWidth={sizeInfo.maxWidth}
        growRatio={sizeInfo.growRatio}
        shrinkRatio={sizeInfo.shrinkRatio}
        key={column.name}
      >
        {valueFromMaybeFunction(column.cellContents, item, column)}
      </Cell>
    )
  })

  return (
    <div
      onClick={onClick && (() => onClick(item))}
      className={classNames('row', className)}
    >
      {cells}
    </div>
  )
}
Row.propTypes = {
  item: PropTypes.any.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  onClick: PropTypes.func,
}
export default Row
