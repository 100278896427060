import is from 'is'
import PropTypes from 'prop-types'
import {prop} from 'ramda'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {LoadingMessage} from 'app/common/loading-message'
import * as entitiesSelectors from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import * as global from 'app/global'
import Company from 'app/models/Company'
import CompaniesDisplay from 'app/reusable/companies/companies-display'

import * as actions from './companies-page-actions'
import {PAGE_SIZE} from './companies-page-constants'
import * as selectors from './companies-page-selectors'

import * as styles from './CompaniesPage.less'

const NoCompanies = () => (
  <div className={styles.noCompanies}>
    <p className={styles.message}>
      No companies found. Try broadening your search.
    </p>
  </div>
)

function CompaniesPage({
  // From state
  companies,
  myCompanies,
  tableSort,
  nameFilter,
  letterFilter,
  currentPage,
  totalCompanyCount,
  shouldShowOnlyMyCompanies,
  geography,
  isLoading,

  // Actions
  setSort,
  setNameFilter,
  setLetterFilter,
  setGeography,
  setMyCompaniesFilter,
  setPage,
}) {
  if (!companies || !myCompanies || is.undefined(totalCompanyCount)) {
    return <LoadingMessage />
  }

  return (
    <React.Fragment>
      <CompaniesDisplay
        companies={companies}
        matrixCompanies={myCompanies}
        myCompanyIds={myCompanies.map(prop('id'))}
        tableSort={tableSort}
        nameFilter={nameFilter}
        letterFilter={letterFilter}
        geography={geography}
        shouldShowOnlyMyCompanies={shouldShowOnlyMyCompanies}
        shouldShowMyCompaniesInMatrix={true}
        shouldAutoSort={false}
        shouldHandleFilters={false}
        currentPage={currentPage}
        pageCount={Math.ceil(totalCompanyCount / PAGE_SIZE)}
        isLoading={isLoading}
        onPageChange={setPage}
        onSort={setSort}
        onNameFilterChange={setNameFilter}
        onLetterFilterChange={setLetterFilter}
        onGeographyChange={setGeography}
        onMyCompaniesFilterChange={setMyCompaniesFilter}
      />
      {companies && !companies.length && <NoCompanies />}
    </React.Fragment>
  )
}
CompaniesPage.propTypes = {
  // From state
  companies: PropTypes.arrayOf(PropTypes.object),
  myCompanies: PropTypes.arrayOf(PropTypes.object),
  tableSort: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalCompanyCount: PropTypes.number,
  nameFilter: PropTypes.string.isRequired,
  letterFilter: PropTypes.string.isRequired,
  geography: PropTypes.number.isRequired,
  shouldShowOnlyMyCompanies: PropTypes.bool.isRequired,

  // Actions
  setSort: PropTypes.func.isRequired,
  setNameFilter: PropTypes.func.isRequired,
  setLetterFilter: PropTypes.func.isRequired,
  setGeography: PropTypes.func.isRequired,
  setMyCompaniesFilter: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
}

export default connect(
  createSelector(
    [
      entitiesSelectors.getEntities,
      selectors.getState,
      global.selectors.getMyCompanyIds,
    ],
    (entities, state, myCompanyIds) => {
      const {
        companyIds,
        tableSort,
        currentPage,
        totalCompanyCount,
        nameFilter,
        letterFilter,
        geography,
        shouldShowOnlyMyCompanies,
        isLoading,
      } = state
      const orm = Orm.withEntities(entities)
      return {
        companies: companyIds && orm.getByIds(Company, companyIds),
        myCompanies: myCompanyIds && orm.getByIds(Company, myCompanyIds),
        tableSort,
        currentPage,
        totalCompanyCount,
        nameFilter,
        letterFilter,
        geography,
        shouldShowOnlyMyCompanies,
        isLoading,
      }
    },
  ),
  {
    setSort: actions.setSort,
    setNameFilter: actions.setNameFilter,
    setLetterFilter: actions.setLetterFilter,
    setGeography: actions.setGeography,
    setMyCompaniesFilter: actions.setMyCompaniesFilter,
    setPage: actions.setPage,
  },
)(CompaniesPage)
