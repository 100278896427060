import React from 'react'
import classNames from 'classnames'
import * as styles from './MainLayout.less'

export default function MainLayout({ children, className, ...restProps }) {
  return (
    <div className={classNames(styles.mainContainer, className)} {...restProps}>
      {children}
    </div>
  )
}
