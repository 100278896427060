import {STATE_PROP} from './login-constants'

export const getLogin = state => state[STATE_PROP]
export const getJwt = state => getLogin(state).token
export const getCurrentUser = state => getLogin(state).currentUser
export const getIsLoaded = state => !!getCurrentUser(state)
export const getIsStaff = state => getCurrentUser(state).isStaff
export const getUserId = state => (getCurrentUser(state) || {}).id
export const getFlaggedArticleCount = state =>
  getLogin(state).flaggedArticleCount
export const getAdminHash = state => getLogin(state).adminHash
export const getHasSimCircAccess = state => {
  if (!getIsLoaded(state)) {
    return false
  }
  return getIsStaff(state) || getCurrentUser(state).hasSimCircAccess
}

/**
 * Gets the global filter defaults for all companies.
 */
export const getAllCompanyFilterDefaults = state =>
  getLogin(state).filterDefaults.companies.all
/**
 * Gets the filter defaults for a specific company, or the global defaults if
 * none have been specified for the company.
 */
export const getFilterDefaultsForCompanyId = (state, companyId) =>
  (companyId && getLogin(state).filterDefaults.companies.byId[companyId]) ||
  getAllCompanyFilterDefaults(state)
/**
 * Gets the global filter defaults for all industries.
 */
export const getAllIndustryFilterDefaults = state =>
  getLogin(state).filterDefaults.industries.all
/**
 * Gets the filter defaults for a specific industry, or the global defaults if
 * none have been specified for the industry.
 */
export const getFilterDefaultsForIndustryId = (state, industryId) =>
  (industryId && getLogin(state).filterDefaults.industries.byId[industryId]) ||
  getAllIndustryFilterDefaults(state)
