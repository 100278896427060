import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import StoryCard from 'app/reusable/stories/story-card'

import * as styles from './Dashboard.less'

const NoStories = ({valenceLabel}) => (
  <div className={styles.noStories}>
    There Are No {valenceLabel} Stories Today for Your Companies
  </div>
)
NoStories.propTypes = {
  valenceLabel: PropTypes.string.isRequired,
}

const DashboardTopStories = ({stories, valenceLabel}) => {
  return (
    <div className={classNames(styles.topStories, 'top-stories')}>
      {stories.map(story => (
        <StoryCard
          story={story}
          className={classNames(styles.story, 'story')}
          key={story.id}
        />
      ))}
      {!stories.length && <NoStories valenceLabel={valenceLabel} />}
    </div>
  )
}
DashboardTopStories.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  valenceLabel: PropTypes.string.isRequired,
}
export default DashboardTopStories
