import PropTypes from 'prop-types'
import React, {PureComponent} from 'react'

import Table from 'app/common/Table'

import PageSelector from './PageSelector'

import * as styles from './PaginatedTable.less'

export default class PaginatedTable extends PureComponent {
  static propTypes = {
    pageCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    // Also takes any props that Table takes
  }

  render() {
    const {
      children,
      pageCount,
      currentPage,
      onPageChange,
      ...restProps
    } = this.props

    return (
      <div className={styles.paginatedTableContainer}>
        <Table {...restProps}>{children}</Table>
        <PageSelector
          currentPage={currentPage}
          pageCount={pageCount}
          onPageChange={onPageChange}
        />
      </div>
    )
  }
}
