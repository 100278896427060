import {handleActions} from 'redux-actions'
import * as actions from './flag-article-modal-actions'

const getInitialState = () => ({
  isVisible: false,
  isLoadingArray: [],
  isAddingFlagCategory: false,
  articleId: null,
  shouldShowBack: false,
  flaggingData: {
    flagCategories: [],
    flaggings: [],
  },
  addCategoryData: {
    addCategoryForId: null,
    contentDirectorId: null,
    feedId: null,
    showAddForm: false,
  },
})

export default handleActions(
  {
    [actions.showModal]: (state, action) => ({
      ...state,
      isVisible: true,
      articleId: action.payload.articleId,
      shouldShowBack: action.payload.shouldShowBack,
    }),
    [actions.hideModal]: (state, action) => ({
      ...getInitialState(),
      flaggingData: state.flaggingData,
    }),
    [actions.setFlagCategories]: (state, action) => ({
      ...state,
      flaggingData: action.payload.flaggingData,
    }),
    [actions.setAddFlagCategoryForId]: (state, action) => ({
      ...state,
      addCategoryData: {
        ...state.addCategoryData,
        addCategoryForId: action.payload.categoryId,
        contentDirectorId: action.payload.contentDirectorId,
        feedId: action.payload.feedId,
        showAddForm: true,
      },
    }),
    [actions.hideAddFlagCategoryForm]: (state, action) => ({
      ...state,
      addCategoryData: {
        ...state.addCategoryData,
        addCategoryForId: null,
        contentDirectorId: null,
        feedId: null,
        showAddForm: false,
      },
    }),
    [actions.setIsLoading]: (state, action) => ({
      ...state,
      isLoadingArray: action.payload,
    }),
    [actions.setIsAddingFlagCategory]: (state, action) => ({
      ...state,
      isAddingFlagCategory: action.payload,
    }),
  },
  getInitialState(),
)
