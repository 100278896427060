import {LOCATION_CHANGED} from 'redux-little-router'
import {all, fork, put, select, takeLatest} from 'redux-saga/effects'

import {
  fetchIndustries,
  fetchMyCompanies,
  fetchMyIndustries,
} from 'app/api/api-saga-helpers'
import {DEFAULT_TIME_FRAME_DAYS} from 'app/constants'
import CompanyResource from 'app/resources/Company'
import IndustryResource from 'app/resources/Industry'
import routes from 'app/routes'

import * as actions from './industries-actions'
import * as selectors from './industries-selectors'

function* locationChanged() {
  yield put(actions.setIndustryIds(null))
  const myCompaniesRequest = yield* fetchMyCompanies({
    fields: [...CompanyResource.requiredFields, 'industries'],
  })
  const myIndustriesRequest = yield* fetchMyIndustries()
  const allIndustriesRequest = yield* fetchAllIndustries()
  yield all([myCompaniesRequest, myIndustriesRequest, allIndustriesRequest])
}

function* fetchAllIndustries() {
  yield put(actions.setIsRefreshing(true))

  const {geography} = yield select(selectors.getRoot)

  const request = yield* fetchIndustries({
    geography,
    timeFrameDays: DEFAULT_TIME_FRAME_DAYS,
    fields: [...IndustryResource.requiredFields, 'companyIds', 'healthBadge'],
  })
  yield fork(function*() {
    let response
    try {
      response = yield request
    } catch (e) {
      return
    }
    yield put(actions.setIsRefreshing(false))
    yield put(actions.setIndustryIds(response.result))
  })
  return request
}

const isIndustriesPageLocationChangedAction = action =>
  action.type === LOCATION_CHANGED && action.payload.route === routes.industries

export default function* industriesSaga() {
  yield all([
    takeLatest(isIndustriesPageLocationChangedAction, locationChanged),
    takeLatest(actions.setGeography, fetchAllIndustries),
  ])
}
