import PropTypes from 'prop-types'
import {filter, pipe, slice, sortBy} from 'ramda'
import React from 'react'

import Link from 'app/common/NavigationLink'
import {CompaniesTable} from 'app/reusable/companies/companies-table'
import urls from 'app/urls'
import {hasHealthData} from 'app/utils/health'

import * as styles from './Dashboard.less'

const DashboardNoChanges = ({myCompanies}) => {
  const myTop3Companies = pipe(
    filter(hasHealthData),
    sortBy(company => company.healthData.healthScore),
    slice(0, 3),
  )(myCompanies)

  return (
    <div className={styles.noHealthChanges}>
      <div className={styles.header}>
        <h2 className={styles.heading}>No Recent Health Factor Changes</h2>

        <div className={styles.description}>
          But here are some of the companies that you monitor that have the
          lowest health scores.
        </div>
      </div>

      <CompaniesTable
        companies={myTop3Companies}
        shouldShowCompanyDetails={false}
        className={styles.table}
      />

      <div className={styles.viewMore}>
        <Link href={urls.companies()}>View All of My Companies &gt;&gt;</Link>
      </div>
    </div>
  )
}
DashboardNoChanges.propTypes = {
  myCompanies: PropTypes.arrayOf(PropTypes.object).isRequired,
}
export default DashboardNoChanges
