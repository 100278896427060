import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Portal} from 'react-portal'

import Modal from 'app/common/Modal'
import * as selectors from 'app/common/charts/charts-selectors'
import * as actions from 'app/common/charts/charts-actions'
import * as styles from './SaveConfirmationModal.less'

export default function SaveConfirmationModal() {
  const showModal = useSelector(selectors.getShowConfirmationModal)
  const dispatch = useDispatch()

  const close = () => {
    dispatch(actions.setShowConfirmationModal(null))
  }

  const modalContent =
    showModal && showModal.show ? (
      <Portal>
        <Modal
          onClose={() => close()}
          className={styles.modal}
          contentClassName={styles.content}
        >
          <div className={styles.message}>
            {showModal ? showModal.message : null}
          </div>
        </Modal>
      </Portal>
    ) : null
  return modalContent
}
