import React from 'react'

import * as strings from 'app/strings'
import {DATA_COUNT_ROTATE_THRESHOLD} from 'app/constants'

/**
 * Overrides the normal x-axis ticks in order to align the labels properly and
 * hide them for all but the passed in values.
 */
export default function XAxisTick({x, y, payload, datesByXValue, style}) {
  if (!datesByXValue[payload.value]) {
    return null
  }
  const label = strings.formatDate(datesByXValue[payload.value], {
    showYear: false,
  })

  const settings = datesByXValue.length > DATA_COUNT_ROTATE_THRESHOLD
    ? {
      x: 0,
      textAnchor: 'end',
      transform: 'rotate(-25)'
      }
    : {
      x: 20,
      dx: payload.value === 0 ? -2 : null,
      textAnchor: payload.value === datesByXValue.length - 1
                    ? 'end'
                    : payload.value === 0
                    ? 'start'
                    : 'middle'
      }

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        y={0}
        dy={16}
        style={style}
        {...settings}
      >
        {label}
      </text>
    </g>
  )
}
