import classNames from 'classnames'
import invariant from 'invariant'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import {mapObjIndexed} from 'ramda'
import React from 'react'

import {SORT_DIRECTIONS} from 'app/constants'

import Cell from './Cell'
import Column from './Column'
import Row from './Row'
import {valueFromMaybeFunction} from './utils'

import './styles.less'

/*
 * Generic data table component
 */
export default class Table extends React.PureComponent {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.array.isRequired,
    defaultSort: PropTypes.object,
    sort: PropTypes.object,
    onSortChange: PropTypes.func,
    className: PropTypes.string,
    headClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    rowClassName: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    cellClassName: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    // Disables the table's own sorting
    ignoreSort: PropTypes.bool,
    // The prop name to use for uniquely identifying rows.
    keyProp: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    onRowClick: PropTypes.func,
    isHeadSticky: PropTypes.bool,
  }

  static defaultProps = {
    ignoreSort: false,
    keyProp: 'id',
    isHeadSticky: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      currentSort: props.sort || props.defaultSort,
      stickingPointForHead: null,
    }
    this.tableHead = React.createRef()
    this.tableBody = React.createRef()
  }

  get columns() {
    const columns =
      this.props.columns || getColumnsFromChildren(this.props.children)
    return columns.map(column => ({...Column.defaultProps, ...column}))
  }

  get sortedData() {
    let {data} = this.props
    if (this.props.ignoreSort) return data

    const {currentSort} = this.state

    if (currentSort) {
      const column = this.columns.find(col => col.name === currentSort.column)
      invariant(
        column,
        `The current sort state of this Table references a column '${
          currentSort.column
        }' that does not exist.`,
      )

      const sortFunction = (a, b) => {
        // The default sort function sorts by the column value
        const val1 = column.sortBy(a, column)
        const val2 = column.sortBy(b, column)
        return column.sortCompareFunction(val1, val2)
      }
      const sortFunctionWithDirection =
        currentSort.direction == 'desc'
          ? // Reverse it if the direction is descending
            (a, b) => sortFunction(b, a)
          : sortFunction
      data = [...data].sort(sortFunctionWithDirection)
    }

    return data
  }

  componentDidMount() {
    if (this.props.isHeadSticky) {
      this.setState({
        ...this.state,
        stickingPointForHead: this.tableHead.current.offsetTop,
      })
      window.addEventListener('scroll', this.handleStickyClasses.bind(this))
    }
  }

  componentWillUnmount() {
    if (this.props.isHeadSticky) {
      window.removeEventListener('scroll', this.handleStickyClasses)
    }
  }

  handleStickyClasses() {
    if (this.tableHead.current) {
      const head = this.tableHead.current
      const body = this.tableBody.current

      if (window.pageYOffset > this.state.stickingPointForHead) {
        head.classList.add('sticky')
        body.classList.add('stickyOffsetForBody')
      } else {
        head.classList.remove('sticky')
        body.classList.remove('stickyOffsetForBody')
      }
    }
  }

  // React methods

  render() {
    return (
      <div
        id={this.props.id}
        className={classNames('data-table', this.props.className)}
      >
        <div
          ref={this.tableHead}
          className={classNames('head', this.props.headClassName)}
        >
          {this.renderHead(this.columns)}
        </div>
        <div
          ref={this.tableBody}
          className={classNames('body', this.props.bodyClassName)}
        >
          {this.renderRows(this.sortedData)}
        </div>
      </div>
    )
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sort && nextProps.sort !== this.state.currentSort) {
      this.setState({currentSort: nextProps.sort})
    }
  }

  // Render helpers

  renderHead(columns) {
    const cells = columns.map(this.renderHeadColumn.bind(this))
    return (
      <div className={classNames('row', this.props.rowClassName)}>{cells}</div>
    )
  }

  renderHeadColumn(column) {
    const {currentSort} = this.state
    const sortClassNames =
      currentSort && currentSort.column === column.name
        ? {
            sorted: true,
            asc: currentSort.direction == 'asc',
            desc: currentSort.direction == 'desc',
          }
        : {}
    return (
      <Cell
        columnName={column.name}
        className={classNames(
          sortClassNames,
          {sortable: column.isSortable},
          this.props.cellClassName,
        )}
        baseWidth={column.baseWidth}
        minWidth={column.minWidth}
        maxWidth={column.maxWidth}
        growRatio={column.growRatio}
        shrinkRatio={column.shrinkRatio}
        onClick={bind(this.onClickHeaderCell, this, column)}
        key={column.name}
      >
        {column.label}
      </Cell>
    )
  }

  renderRows(data) {
    const {rowClassName, cellClassName, keyProp, onRowClick} = this.props
    return data.map(item => (
      <Row
        columns={this.columns}
        item={item}
        onClick={onRowClick}
        className={valueFromMaybeFunction(rowClassName, item)}
        cellClassName={valueFromMaybeFunction(cellClassName, item)}
        key={typeof keyProp === 'function' ? keyProp(item) : item[keyProp]}
      />
    ))
  }

  // Event handlers

  onClickHeaderCell(column) {
    if (!column.isSortable) return

    let direction
    const {currentSort} = this.state
    if (currentSort && currentSort.column === column.name) {
      direction =
        currentSort.direction === SORT_DIRECTIONS.ASC
          ? SORT_DIRECTIONS.DESC
          : SORT_DIRECTIONS.ASC
    } else {
      direction = column.defaultSortDirection || SORT_DIRECTIONS.ASC
    }

    const newSort = {column: column.name, direction}
    if (this.props.onSortChange) {
      this.props.onSortChange(newSort)
    }
    if (!this.props.sort) {
      // If this table's sorting is managed elsewhere, we don't want to override
      // that.
      this.setState({currentSort: newSort})
    }
  }
}

export const getColumnsFromChildren = children =>
  React.Children.toArray(children).map(child =>
    // This grabs only the props in `Column.propTypes` from the child component.
    mapObjIndexed((_, key) => child.props[key], Column.propTypes),
  )

export const getSortedData = (data, column, sortDirection) => {
  invariant(
    column,
    `The current sort state of this Table references a column '${
      column.name
    }' that does not exist.`,
  )

  const sortFunction = (a, b) => {
    // The default sort function sorts by the column value
    const val1 = column.sortBy(a, column)
    const val2 = column.sortBy(b, column)
    return column.sortCompareFunction(val1, val2)
  }
  const sortFunctionWithDirection =
    sortDirection == 'desc'
      ? // Reverse it if the direction is descending
        (a, b) => sortFunction(b, a)
      : sortFunction
  return [...data].sort(sortFunctionWithDirection)
}

Table.Column = Column
Table.Cell = Cell
export {Column, Cell}
