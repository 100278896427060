import * as loginSelectors from 'app/login/login-selectors'
import {parseQuarter} from 'app/utils/quarters'

import {
  STATE_PROP_NAME,
  TIMELINE_STATE_PROP_NAME,
} from './company-overview-constants'

export const getCompanyOverview = state => state[STATE_PROP_NAME]
export const getIsLoading = state => getCompanyOverview(state).isLoading
export const getCompanyOverviewTimeline = state =>
  getCompanyOverview(state)[TIMELINE_STATE_PROP_NAME]
export const getCompanyId = state => getCompanyOverview(state).companyId
export const getFactor = state => getCompanyOverview(state).selectedFactor
export const getSubfactor = state => {
  const factor = getFactor(state)
  if (factor === 'all') {
    return 'all'
  }
  return getCompanyOverview(state).selectedSubfactor
}
export const getTimeFrame = state => getCompanyOverview(state).timeFrameDays
export const getGeography = state => getCompanyOverview(state).selectedGeography

//
// Per-company filter defaults
//

export const getFilterDefaultsForCurrentCompany = state =>
  loginSelectors.getFilterDefaultsForCompanyId(state, getCompanyId(state))
export const getFactorForCurrentCompany = state => {
  const factor = getFactor(state)
  if (!factor) {
    return getFilterDefaultsForCurrentCompany(state).factor
  }
  if (factor === 'all') {
    return null
  }
  return factor
}
export const getSubfactorForCurrentCompany = state => {
  const factor = getFactorForCurrentCompany(state)
  if (!factor) {
    return null
  }
  const subfactor = getSubfactor(state)
  if (!subfactor) {
    return getFilterDefaultsForCurrentCompany(state).subfactor
  }
  if (subfactor === 'all') {
    return null
  }
  return subfactor
}
export const getTimeFrameForCurrentCompany = state =>
  getTimeFrame(state) || getFilterDefaultsForCurrentCompany(state).timeFrameDays
export const getGeographyForCurrentCompany = state => {
  const geography = getCompanyOverview(state).selectedGeography
  if (geography === null) {
    return getFilterDefaultsForCurrentCompany(state).geography
  }
  return geography
}
export const getLeadershipData = state =>
  getCompanyOverview(state).leadership

// Company history
export const getHistoricalStorylineIds = state =>
  getCompanyOverview(state).historicalStorylineIds
export const getSelectedQuarter = state =>
  parseQuarter(getCompanyOverview(state).selectedQuarter)
export const getHistoricalHealthData = state =>
  getCompanyOverview(state).historicalHealthData
export const getHistoricalStorylineSearchText = state =>
  getCompanyOverview(state).historicalStorylineSearchText
export const getAutoQuarterSearch = state =>
  getCompanyOverview(state).autoQuarterSearch
export const getQuarterlyArticleCounts = state =>
  getCompanyOverview(state).articleCountsByQuarter
export const quarterExists = (state, quarter) =>
  !!(getHistoricalHealthData(state) || []).find(
    data => data.quarter === quarter.toString(),
  )
