import dateFns from 'date-fns'
import is from 'is'
import PropTypes from 'prop-types'
import React from 'react'

import * as strings from 'app/strings'
import urls, {toAbsoluteUrl, withSendGridTracking} from 'app/urls'
import {getExcerpt} from 'app/utils/article'
import Cell from 'email-server-common/common/Cell'
import Link from 'email-server-common/common/Link'
import PaddedBlock from 'email-server-common/common/PaddedBlock'
import Row from 'email-server-common/common/Row'
import Table from 'email-server-common/common/Table'
import {paddingAmount, spacingAmount} from 'email-server/style/constants'
import {
  backgroundColorDark,
  lineColorLight,
  textColorLight,
  textColorVeryLight,
} from 'email-server/style/palette'

import HealthScoreSection from './shared/HealthScoreSection'
import StorySummaryBlock from './shared/StorySummaryBlock'
import Section from './Section'

const NoNewStories = ({industry, productName}) => (
  <PaddedBlock
    vertical={spacingAmount * 1.5}
    horizontal={spacingAmount}
    style={{textAlign: 'center', fontSize: 18}}
  >
    No New {productName} Stories for {industry.name}
  </PaddedBlock>
)
NoNewStories.propTypes = {
  industry: PropTypes.object.isRequired,
  productName: PropTypes.string.isRequired,
}

const StoryCardArticleSection = ({story, rootUrl}) => {
  const article = story.topArticle
  const {storyline} = story
  const storylineDayCount =
    dateFns.differenceInDays(storyline.endDate, storyline.startDate) + 1

  const spacing = 8

  return (
    <Table>
      <Row>
        <Cell style={{paddingBottom: spacing}}>
          <StorySummaryBlock story={story} />
        </Cell>
      </Row>

      <Row>
        <Cell style={{fontSize: 16, paddingBottom: spacing}}>
          <Link
            href={withSendGridTracking(
              toAbsoluteUrl(
                urls.interimPage({
                  contentDirectorId: article.contentDirectorId,
                  savedSearchId: story.company.savedSearchId,
                }),
                {root: rootUrl},
              ),
            )}
          >
            {article.headline}
          </Link>
        </Cell>
      </Row>

      <Row>
        <Cell style={{paddingBottom: spacing}}>
          {getExcerpt(article.description)}
        </Cell>
      </Row>

      <Row>
        <Cell
          style={{
            paddingBottom: spacing * 1.5,
            borderBottom: `1px solid ${lineColorLight}`,
          }}
        >
          <span style={{color: textColorLight}}>
            {strings.formatDate(story.date)}
          </span>
          {article.feed && (
            <span style={{textDecoration: 'italic', color: textColorLight}}>
              {' '}
              via {article.feed.name}
            </span>
          )}
        </Cell>
      </Row>

      <Row>
        <Cell style={{paddingTop: spacing * 1.5, paddingBottom: spacing}}>
          Part of Storyline:{' '}
          <Link
            href={withSendGridTracking(
              toAbsoluteUrl(urls.storylineView(story.storylineId), {
                root: rootUrl,
              }),
            )}
          >
            &ldquo;
            {storyline.summary}
            &rdquo;
          </Link>
        </Cell>
      </Row>

      <Row>
        <Cell style={{paddingBottom: spacing}}>
          <span style={{color: textColorLight}}>
            {storyline.articleCount} Article
            {storyline.articleCount > 1 && 's'} over {storylineDayCount} day
            {storylineDayCount > 1 && 's'}{' '}
            {strings.dateRangeSuffix(storyline.startDate, storyline.endDate)}
          </span>
          {story.articleCount > 1 && (
            <React.Fragment>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              {story.articleCount - 1} More Article
              {story.articleCount > 2 && 's'} in this Story{' '}
              {dateFns.isToday(story.date)
                ? 'Today'
                : `on ${strings.formatDate(story.date)}`}
            </React.Fragment>
          )}
        </Cell>
      </Row>
    </Table>
  )
}
StoryCardArticleSection.propTypes = {
  story: PropTypes.object.isRequired,
  rootUrl: PropTypes.string.isRequired,
}

const StoryCard = ({story, rootUrl}) => (
  <Table style={{borderCollapse: 'separate'}}>
    <Cell style={{border: `1px solid ${lineColorLight}`, borderRadius: 5}}>
      <PaddedBlock
        padding={paddingAmount}
        style={{fontSize: 18, borderBottom: `1px solid ${lineColorLight}`}}
      >
        {story.company.name}
      </PaddedBlock>

      <PaddedBlock padding={paddingAmount}>
        <StoryCardArticleSection story={story} rootUrl={rootUrl} />
      </PaddedBlock>
    </Cell>
  </Table>
)
StoryCard.propTypes = {
  story: PropTypes.object.isRequired,
  rootUrl: PropTypes.string.isRequired,
}

const IndustrySection = ({
  industry,
  date,
  timeFrameDays,
  productName,
  rootUrl,
  shouldShowHealthChanges = false,
}) => {
  return (
    <Section>
      <a name={`industry${industry.id}`} />

      <Table>
        {/* Header */}
        <Row>
          <Cell
            style={{
              backgroundColor: backgroundColorDark,
              color: textColorVeryLight,
              padding: paddingAmount,
              fontSize: 16,
            }}
          >
            <Table>
              <Cell align="left">
                <span style={{color: textColorVeryLight, fontWeight: 'bold'}}>
                  {industry.name}
                </span>
              </Cell>
              <Cell align="right" style={{textAlign: 'right'}}>
                <Link
                  href={withSendGridTracking(
                    toAbsoluteUrl(urls.industryOverview(industry.id), {
                      root: rootUrl,
                    }),
                  )}
                  style={{
                    color: textColorVeryLight,
                    textDecoration: 'underline',
                  }}
                >
                  View {industry.name} Industry Overview
                </Link>
              </Cell>
            </Table>
          </Cell>
        </Row>

        {/* Health score */}
        <Row>
          <HealthScoreSection
            entity={industry}
            entityType="industry"
            date={date}
            timeFrameDays={timeFrameDays}
            productName={productName}
            rootUrl={rootUrl}
            shouldShowHealthChanges={shouldShowHealthChanges}
          />
        </Row>

        {/* Stories */}
        <Row>
          <Cell style={{paddingTop: spacingAmount / 2}}>
            {industry.topStories.map(story => (
              <PaddedBlock padding={paddingAmount} key={story.id}>
                <StoryCard story={story} rootUrl={rootUrl} />
              </PaddedBlock>
            ))}
            {is.array.empty(industry.topStories) && (
              <NoNewStories industry={industry} productName={productName} />
            )}
          </Cell>
        </Row>

        {/* Footer */}
        <Row>
          <PaddedBlock
            padding={spacingAmount}
            style={{
              textAlign: 'center',
              borderTop: `1px solid ${lineColorLight}`,
            }}
          >
            <Link
              href={withSendGridTracking(
                toAbsoluteUrl(urls.industryOverview(industry.id), {
                  root: rootUrl,
                }),
              )}
            >
              View {industry.name} Industry Overview
            </Link>
          </PaddedBlock>
        </Row>
      </Table>
    </Section>
  )
}
IndustrySection.propTypes = {
  industry: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  timeFrameDays: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  rootUrl: PropTypes.string.isRequired,
  shouldShowHealthChanges: PropTypes.bool,
}
export default IndustrySection
