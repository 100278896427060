import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import HealthScore from 'app/common/HealthScore'
import * as strings from 'app/strings'

import * as styles from './SubfactorHealthCard.less'
import {FACTORS_BY_SUBFACTOR, ORDERED_VALENCES, VALENCES} from 'app/constants'

function BreakdownTable({healthData, factor, subfactor}) {
  const subfactorHealth = healthData.subfactors[subfactor] || {
    positiveVolume: 0,
    neutralVolume: 0,
    negativeVolume: 0,
  }
  return ORDERED_VALENCES.map(valence => {
    const valenceClassName = {
      [VALENCES.POSITIVE]: 'positive',
      [VALENCES.NEUTRAL]: 'neutral',
      [VALENCES.NEGATIVE]: 'negative',
    }[valence]
    return (
      <div className={classNames(styles.row)} key={valence}>
        <span className={classNames(styles.number, styles[valenceClassName])}>
          {subfactorHealth[`${valenceClassName}Volume`]}
        </span>
        <span className={styles.name}>
          {strings.valenceDisplayName(valence)} Articles
        </span>
      </div>
    )
  })
}
BreakdownTable.propTypes = {
  healthData: PropTypes.object.isRequired,
  factor: PropTypes.string.isRequired,
  subfactor: PropTypes.string.isRequired,
}

const SubfactorHealthCard = ({healthData, subfactor, className}) => {
  const factor = FACTORS_BY_SUBFACTOR[subfactor]
  const factorHealth = healthData.subfactors[subfactor] || {}
  const healthScore = factorHealth.healthScore

  return (
    <div className={classNames(styles.subfactorHealthCard, className)}>
      <div className={styles.header}>
        {strings.subfactorDisplayName(subfactor)}
        <HealthScore score={healthScore} className={styles.healthScore} />
      </div>

      <div className={styles.content}>
        <div className={styles.articleCount}>Article Count</div>

        <BreakdownTable
          healthData={healthData}
          factor={factor}
          subfactor={subfactor}
        />
      </div>
    </div>
  )
}
SubfactorHealthCard.propTypes = {
  healthData: PropTypes.object.isRequired,
  subfactor: PropTypes.string.isRequired,
  className: PropTypes.string,
}
export default SubfactorHealthCard
