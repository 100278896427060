import {always} from 'ramda'

import {handleLocalActions} from 'app/utils/redux'

import * as actions from './companies-display-actions'

const getInitialState = () => ({
  nameFilter: '',
  letterFilter: '',
  shouldShowOnlyMyCompanies: false,
})

export default handleLocalActions({
  [actions.init]: getInitialState,
  [actions.deinit]: always(undefined),
  [actions.setNameFilter]: (state, action) => ({
    ...state,
    nameFilter: action.payload,
  }),
  [actions.setLetterFilter]: (state, action) => ({
    ...state,
    letterFilter: action.payload,
  }),
  [actions.setMyCompaniesFilter]: (state, action) => ({
    ...state,
    shouldShowOnlyMyCompanies: action.payload,
  }),
})
