import classNames from 'classnames'
import is from 'is'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import {reduce, sort} from 'ramda'
import React, {PureComponent} from 'react'

import Table from 'app/common/Table'
import {PaginatedTable, AutoPaginatedTable} from 'app/common/paginated-table'
import {localComponent} from 'app/utils/redux'

import {getColumns, sortByFunctionsByColumn} from './companies-table-columns'

import CompaniesTableLoading from './CompaniesTableLoading'
import * as actions from './companies-table-actions'
import * as constants from './companies-table-constants'
import * as selectors from './companies-table-selectors'

import * as styles from './CompaniesTable.less'

const PAGE_SIZE = 50

const rowClassName = ({isDetailRow, hasDetailsOpen}) =>
  classNames({
    [styles.detailRow]: isDetailRow,
    [styles.withDetailRow]: hasDetailsOpen,
  })

class CompaniesTable extends PureComponent {
  static propTypes = {
    companies: PropTypes.arrayOf(PropTypes.object).isRequired,
    shouldShowCompanyDetails: PropTypes.bool,
    isPortalPage: PropTypes.bool,
    geography: PropTypes.number,
    shouldAutoSort: PropTypes.bool,
    currentPage: PropTypes.number,
    pageCount: PropTypes.number,
    onPageChange: PropTypes.func,
    isLoading: PropTypes.bool,
    isHeadSticky: PropTypes.bool,

    // Connected
    companyDetailsShownForId: PropTypes.number,
    industriesExpandedForIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    currentSort: PropTypes.object.isRequired,
    setSort: PropTypes.func.isRequired,
    showCompanyDetails: PropTypes.func.isRequired,
    hideCompanyDetails: PropTypes.func.isRequired,
    expandCompanyIndustries: PropTypes.func.isRequired,
    collapseCompanyIndustries: PropTypes.func.isRequired,
  }

  static defaultProps = {
    shouldShowCompanyDetails: true,
    isPortalPage: false,
    shouldAutoSort: true,
    isLoading: false,
    isHeadSticky: false,
  }

  render() {
    if (this.props.isLoading) {
      return <CompaniesTableLoading />
    }

    const {
      companies,
      companyDetailsShownForId,
      industriesExpandedForIds,
      shouldShowCompanyDetails,
      isPortalPage,
      geography,
      shouldAutoSort,
      currentSort,
      currentPage,
      pageCount,
      onPageChange,
      isHeadSticky,
    } = this.props

    // We have to do the sorting outside of the table itself so that the details
    // row is guaranteed to be in the right spot.
    const sortBy = sortByFunctionsByColumn[currentSort.column]

    let companyInfos = reduce(
      (accumulatedInfos, company) => {
        const hasDetailsOpen = company.id === companyDetailsShownForId
        const areIndustriesExpanded = industriesExpandedForIds.includes(
          company.id,
        )
        accumulatedInfos.push({
          isDetailRow: false,
          company,
          hasDetailsOpen,
          areIndustriesExpanded,
          id: company.id,
        })
        if (hasDetailsOpen) {
          // This is just a placeholder for the details row.
          accumulatedInfos.push({
            isDetailRow: true,
            company,
            id: `${company.id}-detail`,
          })
        }
        return accumulatedInfos
      },
      [],
      companies,
    )
    if (shouldAutoSort) {
      companyInfos = sort((companyInfo1, companyInfo2) => {
        if (companyInfo1.company.id === companyInfo2.company.id) {
          // One of these rows is the details row.
          if (companyInfo1.hasDetailsOpen) return -1
          if (companyInfo2.hasDetailsOpen) return 1
        }

        const val1 = sortBy(companyInfo1)
        const val2 = sortBy(companyInfo2)
        let val
        // Always put "no data" at the end of the table if we're sorting by
        // health score.
        if (is.undefined(val1) && is.undefined(val2)) {
          val = 0
        } else if (is.undefined(val1)) {
          val = 1
        } else if (is.undefined(val2)) {
          val = -1
        } else {
          val = Table.Column.defaultProps.sortCompareFunction(val1, val2)
          if (currentSort.direction === 'desc') {
            val = val * -1
          }
        }

        return val
      }, companyInfos)
    }

    const columns = getColumns({
      geography,
      shouldShowCompanyDetails,
      isPortalPage,
      onArrowClick: bind(this.onArrowClick, this),
      expandIndustries: bind(this.expandIndustries, this),
      collapseIndustries: bind(this.collapseIndustries, this),
    })
    const className = classNames(styles.table, this.props.className)
    const onSortChange = bind(this.changeSort, this)

    if ([currentPage, pageCount, onPageChange].every(is.defined)) {
      return (
        <PaginatedTable
          columns={columns}
          data={companyInfos}
          currentPage={currentPage}
          pageCount={pageCount}
          onPageChange={onPageChange}
          className={className}
          rowClassName={rowClassName}
          sort={currentSort}
          onSortChange={onSortChange}
          ignoreSort={true}
          isHeadSticky={isHeadSticky}
        />
      )
    }
    return (
      <AutoPaginatedTable
        isHeadSticky={isHeadSticky}
        columns={columns}
        data={companyInfos}
        pageSize={PAGE_SIZE}
        className={className}
        rowClassName={rowClassName}
        sort={currentSort}
        onSortChange={onSortChange}
        ignoreSort={true}
      />
    )
  }

  changeSort(sort) {
    this.props.setSort(sort)
  }

  onArrowClick(companyId) {
    companyId === this.props.companyDetailsShownForId
      ? this.props.hideCompanyDetails()
      : this.props.showCompanyDetails({companyId})
  }

  expandIndustries(companyId) {
    this.props.expandCompanyIndustries({companyId})
  }

  collapseIndustries(companyId) {
    this.props.collapseCompanyIndustries({companyId})
  }
}

export default localComponent({
  key: constants.STATE_PROP_NAME,
  initAction: actions.init,
  deinitAction: actions.deinit,
  actions: {
    setSort: actions.setSort,
    showCompanyDetails: actions.showCompanyDetails,
    hideCompanyDetails: actions.hideCompanyDetails,
    expandCompanyIndustries: actions.expandCompanyIndustries,
    collapseCompanyIndustries: actions.collapseCompanyIndustries,
  },
  stateSelector: selectors.getByKey,
})(CompaniesTable)
