import React from 'react'
import { colors } from './constants'
import Cell from '../../email-server-common/common/Cell'

export default function ArticleChip({ valence = null, label, iconFileName = null, style = {} }) {
  const backgroundColors = {
    null: '#F2F2F2',
    'POS': '#C0DBD1',
    'NEU': '#B3B3B3',
    'NEG': '#EFB5B5',
  }

  const fontColors = {
    null: colors.defaultFontColor,
    'POS': '#05704B',
    'NEU': '#1E1E1E',
    'NEG': '#AF292E',
  }

  return (
    <Cell style={{
      height: 24,
      padding: '0px 12px',
      borderRadius: 12,
      background: backgroundColors[valence],
      color: fontColors[valence],
      fontSize: 12,
      fontWeight: 500,
      verticalAlign: 'middle',
      ...style,
    }}>{iconFileName &&
      <><img height='16' height='16' style={{ verticalAlign: 'bottom', paddingBottom: 2 }} alt='ChipLabel'
           src={iconFileName}/>&nbsp;&nbsp;&nbsp;</>}{label}</Cell>
  )
}
