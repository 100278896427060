import is from 'is'
import {prop} from 'ramda'
import {LOCATION_CHANGED} from 'redux-little-router'
import {put, select, takeLatest} from 'redux-saga/effects'

import {
  fetchCompanies,
  fetchStories,
  fetchStoryline,
} from 'app/api/api-saga-helpers'
import * as globalActions from 'app/global/global-actions'
import * as entityActions from 'app/framework/entities-actions'
import Orm from 'app/framework/Orm'
import {Story, Storyline} from 'app/models'
import {
  Company as CompanyResource,
  Story as StoryResource,
  Storyline as StorylineResource,
} from 'app/resources'
import routes from 'app/routes'

import * as actions from './storyline-view-actions'
import * as selectors from './storyline-view-selectors'

function* locationChanged(action) {
  if (
    [routes.storylineView, routes.storylineViewPortal].includes(
      action.payload.route,
    )
  ) {
    const storylineId = yield select(selectors.getStorylineId)

    const storylineRequest = yield* fetchStoryline({
      id: storylineId,
      fields: [
        ...StorylineResource.requiredFields,
        'company',
        'companiesMentioned',
        'relatedStorylines',
      ],
    })
    const storiesRequest = yield* fetchStories({
      storylineId,
      orderBy: [{field: 'storyDate', direction: 'desc'}],
      fields: [...StoryResource.requiredFields, 'storylineId', 'topArticle'],
    })

    const storylineResponse = yield storylineRequest
    if (
      is.array.empty(storylineResponse.result) ||
      !storylineResponse.body[0].company
    ) {
      yield put(globalActions.setNotFound())
    } else {
      yield yield* fetchCompanies({
        companyId: storylineResponse.body[0].company.id,
        fields: [...CompanyResource.requiredFields, 'industries'],
      })
      const storiesResponse = yield storiesRequest
      yield put(actions.setStoryIds(storiesResponse.result))
    }

    yield put(actions.setIsLoading(false))
  } else if (
    yield select(state => state.router.previous.route === routes.storylineView)
  ) {
    // We're moving away from this page, so let's remove the relevant data so
    // we're not carrying it around forever.
    const orm = Orm.withEntities(yield select(prop('entities')))
    const storylineId = yield select(selectors.getStorylineId)
    const storyline = orm.getById(Storyline, storylineId)
    if (storyline) {
      const storyIds = storyline.stories.map(prop('id'))
      yield put(
        entityActions.remove({
          [Storyline.entityKey]: [storyline.id],
          [Story.entityKey]: storyIds,
        }),
      )
    }
  }
}

export default function* storylineViewSaga() {
  yield takeLatest(LOCATION_CHANGED, locationChanged)
}
