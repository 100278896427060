import PropTypes from 'prop-types'
import React from 'react'

import {trueRound} from 'app/utils/numbers'
import {
  healthColorNegative,
  healthColorNeutral,
  healthColorPositive,
} from 'email-server/style/palette'

const HealthScore = ({score, style = {}}) => {
  const intScore = trueRound(score)
  const combinedStyle = {
    color:
      intScore > 0
        ? healthColorPositive
        : intScore < 0
        ? healthColorNegative
        : healthColorNeutral,
    ...style,
  }
  return (
    <span style={combinedStyle}>
      {intScore > 0 && '+'}
      {trueRound(intScore).toFixed(0)}
    </span>
  )
}
HealthScore.propTypes = {
  score: PropTypes.number.isRequired,
  style: PropTypes.object,
}
export default HealthScore
