import React, { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'

import Button from 'app/common/Button'
import InputBlock from 'app/common/InputBlock'
import LoadingSpinner from 'app/common/loading-spinner'
import * as entitiesSelectors from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import { LitigationNumberSummary } from 'app/models'

import * as styles from './styles.less'
import BinocularIcon from 'app/litigation/icons/BinocularIcon'
import Chart from 'app/litigation/Chart'
import MultiSelectDropdown from 'app/litigation/MultiSelectDropdown'
import RangeGroup from 'app/litigation/RangeGroup'
import TrendingSectorSorter from 'app/litigation/TrendingSectorSorter'
import * as selectors from 'app/litigation/litigations-selectors'
import * as actions from 'app/litigation/litigations-actions'
import { LitigationLayout, MainLayout, Segment, SidebarLayout } from 'app/litigation/layout'
import { ENABLE_SEARCH_LIMIT, DROPDOWN_SETTINGS, FILTER_CHECKBOXES } from 'app/litigation/litigations-constants'

export default function LitigationMonitoring() {
  const dispatch = useDispatch()

  const entities = useSelector(entitiesSelectors.getEntities)
  const mainPage = useSelector(selectors.getMainPage)
  const sectorPage = useSelector(selectors.getSectorPage)

  const { sectors, selectedSectors: selectedSectorIds, companyCountMinimum, companyCountMaximum,
    litigationAffectedCompaniesMinimum, litigationAffectedCompaniesMaximum,
    selectedFilter, isLoading, allSectors, mySectors, myCompanies, selectedCompanyIds } = mainPage

  const selectedCompanies = myCompanies.filter(com => selectedCompanyIds.includes(com.id))

  const orm = Orm.withEntities(entities)
  const sectorEntities = sectors && orm.getByIds(LitigationNumberSummary, sectors).filter(sector => sector !== undefined)
  const selectedSectors = selectedSectorIds && orm.getByIds(LitigationNumberSummary, selectedSectorIds)

  const filteredSectors = selectedFilter === FILTER_CHECKBOXES.myCompanies
    ? sectorEntities.filter(sector => selectedSectors.find(selectedSector => selectedSector.id === sector.id)).filter(sector => sector !== undefined)
    : sectorEntities.filter(sector =>
    (sector.industrySize >= companyCountMinimum
      && sector.industrySize <= companyCountMaximum
      && sector.litigationCompanyProp >= (litigationAffectedCompaniesMinimum / 100)
      && sector.litigationCompanyProp <= (litigationAffectedCompaniesMaximum / 100)
      && (selectedSectors.length === 0
        || !!selectedSectors.find(selectedSector => selectedSector.id === sector.id))
    ))

  const setSelectedSectors = (sectors) => {
    dispatch(actions.setSelectedSectors(sectors))
  }

  return (
    <SelectionPage
      myCompanies={myCompanies}
      selectedCompanies={selectedCompanies}
      setSelectedCompanies={(payload) => dispatch(actions.setSelectedMyCompanyIds(payload))}
      sectors={sectorEntities}
      allSectors={allSectors}
      mySectors={mySectors}
      selectedSectorIds={selectedSectorIds}
      selectedSectors={selectedSectors}
      setSelectedSectors={setSelectedSectors}
      filteredSectors={filteredSectors}
      companyCountMinimum={companyCountMinimum}
      setCompanyCountMinimum={(payload) => dispatch(actions.setCompanyCountMinimum(payload))}
      companyCountMaximum={companyCountMaximum}
      setCompanyCountMaximum={(payload) => dispatch(actions.setCompanyCountMaximum(payload))}
      litigationAffectedCompaniesMinimum={litigationAffectedCompaniesMinimum}
      setLitigationAffectedCompaniesMinimum={(payload) => dispatch(actions.setLitigationAffectedCompaniesMinimum(payload))}
      litigationAffectedCompaniesMaximum={litigationAffectedCompaniesMaximum}
      setLitigationAffectedCompaniesMaximum={(payload) => dispatch(actions.setLitigationAffectedCompaniesMaximum(payload))}
      selectedFilter={selectedFilter}
      setSelectedFilter={(payload) => dispatch(actions.setSelectedFilter(payload))}
      isLoading={isLoading}
    />
  )
}

function SelectionPage(
  {
    selectedCompanies,
    myCompanies,
    setSelectedCompanies,
    sectors,
    allSectors,
    mySectors,
    selectedSectors,
    selectedSectorIds,
    setSelectedSectors,
    filteredSectors,
    companyCountMinimum,
    setCompanyCountMinimum,
    companyCountMaximum,
    setCompanyCountMaximum,
    litigationAffectedCompaniesMinimum,
    setLitigationAffectedCompaniesMinimum,
    litigationAffectedCompaniesMaximum,
    setLitigationAffectedCompaniesMaximum,
    isLoading,
    selectedFilter,
    setSelectedFilter
  }) {
  const [chartRef, setChartRef] = useState(null)
  const sumStoryCount = useMemo(() => sectors.map(sector => sector.litigationStoryCount).reduce((x, y) => x + y, 0), [sectors])
  const sumCompanyStoryCount = useMemo(() => myCompanies.map(company => company.litigationStoryCount).reduce((x, y) => x + y, 0), [myCompanies])
  const industryCsvData = useMemo(() => {
    const _csvData = filteredSectors.map(sector =>
      [
        `"${sector.name}"`,
        sector.litigationStoryCount,
        sector.litigationCompanyCount,
        sector.litigationCompanyProp
      ]
    )
    _csvData.unshift(
      [
        'Sector Name',
        'Number of Litigation Stories',
        'Company Count',
        'Litigation Affected Companies to Sector Size'
      ]
    )
    return _csvData
  }, [filteredSectors])
  const companiesData = selectedCompanies && selectedCompanies.length > 0 ? selectedCompanies : myCompanies
  const companyCsvData = useMemo(() => {
    const _csvData = companiesData.map(company =>
      [
        `"${company.name}"`,
        company.litigationStoryCount,
      ]
    )
    _csvData.unshift(
      [
        'Company Name',
        'Number of Litigation Stories',
      ]
    )
    return _csvData
  }, [selectedCompanies])


  const handleOnToggle = (option) => {
    if (selectedSectorIds.find(id => id === option.id)) {
      setSelectedSectors(selectedSectorIds.filter(id => id !== option.id))
    } else {
      setSelectedSectors([...selectedSectorIds, option.id])
    }
  }

  const handleOnCompanyToggle = (option) => {
    let updatedCompanies = []
    if (selectedCompanies.find(com => com.id === option.id)) {
      updatedCompanies = selectedCompanies.filter(com => com.id !== option.id).map(com => com.id)
    } else {
      updatedCompanies = [...selectedCompanies.map(com => com.id), option.id]
    }

    setSelectedCompanies([...updatedCompanies])
  }

  const handleCheckboxSelected = (value) => {
    setSelectedFilter(value)
    const performCheck = selectedSectors.length > 0 && value !== FILTER_CHECKBOXES.myCompanies && selectedFilter !== FILTER_CHECKBOXES.myCompanies
    if (performCheck) {
      const _sectors = (value === FILTER_CHECKBOXES.myIndustries) ? mySectors : allSectors
      const _selectedSectorIds = selectedSectorIds.filter(id => _sectors.includes(id))
      setSelectedSectors([..._selectedSectorIds])
    }
    else {
      setSelectedCompanies([])
      setSelectedSectors([])
    }
  }

  const resetAllFilters = () => {
    setCompanyCountMinimum(0)
    setCompanyCountMaximum(10000)
    setLitigationAffectedCompaniesMinimum(0)
    setLitigationAffectedCompaniesMaximum(100)
    setSelectedSectors([])
    setSelectedCompanies([])
  }

  function sortByName(a, b) {
    return ((a.name) > (b.name)) ? 1 : -1
  }

  const myCompaniesSelected = selectedFilter === FILTER_CHECKBOXES.myCompanies
  const title = myCompaniesSelected ? 'Company and Number of Litigation Stories' : 'Industry Sectors and Number of Litigation Stories'
  const subTitle = myCompaniesSelected ? 'Select a number of companies to zoom in, analyze trends and read stories.' 
  : 'Select an industry sector to analyze company trends and read/share litigation stories across the companies.'
  const storiesCount = myCompaniesSelected ? sumCompanyStoryCount : sumStoryCount
  const csvData = myCompaniesSelected ? companyCsvData : industryCsvData
  return (
    <LitigationLayout outputFileName={'litigation-sectors-list'} csvData={csvData} chartRef={{ current: chartRef }}>
      <SidebarLayout>
        <Segment>
          <TrendingSectorSorter sectors={sectors} />
        </Segment>

        <hr className={styles.separator} />

        <Segment>
          <h3
            className={classNames(styles.secondaryTitle, styles.findASectorTitle, styles.bold, styles.spaceBetween)}>
            Filter Sectors
            {isLoading ? <LoadingSpinner className={styles.spinner} /> : <BinocularIcon className={styles.inlineIcon} />}

          </h3>

          <div className={styles.checkboxRow}>
            <InputBlock label={FILTER_CHECKBOXES.allIndustries} isInline>
              <input
                type="radio"
                name="industry-type"
                checked={selectedFilter === FILTER_CHECKBOXES.allIndustries}
                onChange={() => handleCheckboxSelected(FILTER_CHECKBOXES.allIndustries)}
                value={FILTER_CHECKBOXES.allIndustries}
              />
            </InputBlock>
            <InputBlock label={FILTER_CHECKBOXES.myIndustries} isInline>
              <input
                type="radio"
                name="industry-type"
                checked={selectedFilter === FILTER_CHECKBOXES.myIndustries}
                onChange={() => handleCheckboxSelected(FILTER_CHECKBOXES.myIndustries)}
                value={FILTER_CHECKBOXES.myIndustries}
              />
            </InputBlock>
            <InputBlock label={FILTER_CHECKBOXES.myCompanies} isInline>
              <input
                type="radio"
                name="industry-type"
                checked={selectedFilter === FILTER_CHECKBOXES.myCompanies}
                onChange={() => handleCheckboxSelected(FILTER_CHECKBOXES.myCompanies)}
                value={FILTER_CHECKBOXES.myCompanies}
              />
            </InputBlock>
          </div>

          {myCompaniesSelected ? <>
            <label className={styles.dropdownLabel}>Filter by Companies</label>
            <MultiSelectDropdown
              options={[...myCompanies].sort(sortByName).map(company => {
                return {
                  id: company.id,
                  label: company.name,
                }
              })}
              selected={selectedCompanies.map(company => {
                return {
                  id: company.id,
                  label: company.name,
                }
              })}
              toggleOption={handleOnCompanyToggle}
              className={classNames(styles.dropdown)}
              selectThreshold={DROPDOWN_SETTINGS.companiesThreshold}
              enableSearch={myCompanies.length > ENABLE_SEARCH_LIMIT}
            />
          </> : <>
            <label className={styles.dropdownLabel}>Filter by Industry Sector</label>
            <MultiSelectDropdown
              options={[...sectors].sort(sortByName).map(sector => {
                return {
                  id: sector.id,
                  label: sector.name,
                }
              })}
              selected={selectedSectors.map(sector => {
                return {
                  id: sector.id,
                  label: sector.name,
                }
              })}
              toggleOption={handleOnToggle}
              className={classNames(styles.dropdown)}
              selectThreshold={DROPDOWN_SETTINGS.industriesThreshold}
              enableSearch={sectors.length > ENABLE_SEARCH_LIMIT}
            />
              
            <RangeGroup
              label={'Company Count Range'}
              tooltip={'Filters the Industry Sectors list by the minimum and maximum number of companies within each industry sector'}
              minimumValue={companyCountMinimum}
              maximumValue={companyCountMaximum}
              minimumValueChanged={setCompanyCountMinimum}
              maximumValueChanged={setCompanyCountMaximum}
              minimumLimit={0}
              maximumLimit={10000}
            />

            <RangeGroup
              label={'Litigation-Affected Companies To Sector Size'}
              tooltip={'Filters the Industry Sectors list by the percentage of companies within the industry sector affected by litigation stories'}
              minimumValue={litigationAffectedCompaniesMinimum}
              maximumValue={litigationAffectedCompaniesMaximum}
              minimumValueChanged={setLitigationAffectedCompaniesMinimum}
              maximumValueChanged={setLitigationAffectedCompaniesMaximum}
              minimumLimit={0}
              maximumLimit={100}
              unitType={RangeGroup.UnitTypes.PERCENTAGE}
            />
          </>}

          <div className={styles.resetButtonContainer}>
            <Button
              label='Reset All Filters'
              isPlainText={true}
              onClick={resetAllFilters}
              className={styles.resetButton}
            />
          </div>

          {myCompaniesSelected ? <div className={styles.emptyDiv}/> :<div/>}
        </Segment>
      </SidebarLayout>
      <MainLayout>
        <div className={styles.mainHeader}>
          <div className={styles.description}>
            <h1 className={styles.descriptionTitle}>{title}</h1>
            <p className={styles.subtitle}>{subTitle}</p>
          </div>
          <div className={styles.storyCountContainer}>
            <h2>{storiesCount.toLocaleString()}</h2>
            <span>TOTAL ARTICLES PROCESSED</span>
          </div>
        </div>
        <div ref={newRef => setChartRef(newRef)}>
          <Chart sectors={filteredSectors} selectedCompanies={selectedCompanies} isLoading={isLoading} />
        </div>
      </MainLayout>
    </LitigationLayout>
  )
}
