import classNames from 'classnames'
import {addDays, isSameDay, subDays} from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'

import {today} from 'app/constants'
import {formatDate} from 'app/strings'

import Chart from './Chart'
import Legend from './EventTimelineLegend'

import * as styles from './styles.less'

export default function EventTimeline({
  items,
  groups,
  startDate,
  shouldShowLegend = true,
  highlightedDay,
  highlightedGroups = [],
  onHighlightDay = () => {},
  onClearHighlightedDay = () => {},
}) {
  const legend = shouldShowLegend ? (
    <Legend
      groups={groups}
      highlightedGroups={highlightedGroups}
      className={styles.legend}
    />
  ) : null

  return (
    <div className={classNames(styles.eventTimeline, 'timeline')}>
      <div className={styles.chartSection}>
        <Chart
          items={items}
          groups={groups}
          startDate={startDate}
          highlightedDay={highlightedDay}
          highlightedGroups={highlightedGroups}
          onHighlightDay={onHighlightDay}
          onClearHighlightedDay={onClearHighlightedDay}
        />
      </div>

      <XAxis startDate={startDate} dayCount={items.length} />

      {legend}
    </div>
  )
}
EventTimeline.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        group: PropTypes.any.isRequired,
      }),
    ),
  ).isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
  startDate: PropTypes.object,
  shouldShowLegend: PropTypes.bool,

  // Props from state
  highlightedDay: PropTypes.number,
  highlightedGroups: PropTypes.arrayOf(PropTypes.any),

  // Actions
  onHighlightDay: PropTypes.func,
  onClearHighlightedDay: PropTypes.func,
}

function XAxis({startDate, dayCount}) {
  // TODO: Add one in the middle
  const startDay = startDate || subDays(today, dayCount - 1)
  const endDay = addDays(startDay, dayCount - 1)
  const startLabel = (
    <span className={styles.label} style={{left: 0}}>
      {formatDate(startDay)}
    </span>
  )
  const endLabel = (
    <span className={styles.label} style={{right: 0}}>
      {formatDate(endDay)}
    </span>
  )
  return (
    <div className={styles.xAxis}>
      {startLabel}
      {endLabel}
    </div>
  )
}
