import Resource from "app/framework/Resource";
import LitigationStoryModel from "app/models/LitigationStory";

export default class LitigationStory extends Resource {
  static endpoint = "rpc/litigation_stories";

  entityItemsForRestItem(restData) {
    return [
      {
        id: restData.id,
        entityKey: LitigationStoryModel.entityKey,
        data: {
          id: restData.id,
          companyId: restData.companyId,
          keyArticleId: restData.keyArticleId,
          url: restData.url,
          headline: restData.headline,
        }
      }
    ]
  }
}
