import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import {trueRound} from 'app/utils/numbers'

import * as styles from './styles.less'

const HealthScore = ({score, className, ...restProps}) => {
  let scoreString, signClassName
  if (score === null) {
    scoreString = '--'
    signClassName = styles.neutral
  } else {
    const intScore = trueRound(score)
    let sign = ''
    if (intScore > 0) {
      signClassName = styles.positive
      sign = '+'
    } else if (intScore < 0) {
      signClassName = styles.negative
    } else {
      signClassName = styles.neutral
    }
    scoreString = `${sign}${intScore}`
  }
  return (
    <span
      className={classNames(styles.healthScore, signClassName, className)}
      {...restProps}
    >
      {scoreString}
    </span>
  )
}
HealthScore.propTypes = {
  score: PropTypes.number,
  className: PropTypes.string,
}
export default HealthScore
