import React from 'react'

import {HealthVolumeMatrixTooltip} from 'app/health/health-volume-matrix/tooltip'
import StoriesTooltip from 'app/storylines/EventTimeline/StoriesTooltip'

export default function TooltipsContainer() {
  return (
    <div id="tooltips">
      <HealthVolumeMatrixTooltip />
      <StoriesTooltip />
    </div>
  )
}
