import React from 'react'

export default function TrendingIcon({ className }) {
  return (
    <svg viewBox="0 0 24 25" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
      <path d="M0.75 8V21.5H23.25V8M0.75 3.5H23.25V8H0.75V3.5Z" stroke="#415464" strokeWidth="1.16129"
            strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.25 14H18.75L16.5 11.75L14.25 16.25L10.5 11L6.75 17.75L5.25 14H3.75" stroke="#415464"
            strokeWidth="1.16129" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M3 6.125C3.20711 6.125 3.375 5.95711 3.375 5.75C3.375 5.54289 3.20711 5.375 3 5.375C2.79289
        5.375 2.625 5.54289 2.625 5.75C2.625 5.95711 2.79289 6.125 3 6.125Z"
        fill="#415464" stroke="#415464" strokeWidth="0.774194" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M5.625 6.125C5.83211 6.125 6 5.95711 6 5.75C6 5.54289 5.83211 5.375 5.625 5.375C5.41789 5.375
        5.25 5.54289 5.25 5.75C5.25 5.95711 5.41789 6.125 5.625 6.125Z"
        fill="#415464" stroke="#415464" strokeWidth="0.774194" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M8.25 6.125C8.45711 6.125 8.625 5.95711 8.625 5.75C8.625 5.54289 8.45711 5.375 8.25 5.375C8.04289
        5.375 7.875 5.54289 7.875 5.75C7.875 5.95711 8.04289 6.125 8.25 6.125Z"
        fill="#415464" stroke="#415464" strokeWidth="0.774194" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
