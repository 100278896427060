import {handleActions} from 'redux-actions'

import * as actions from './health-volume-matrix-tooltip-actions'

const getInitialState = () => ({
  isVisible: false,
  companyId: undefined,
  top: undefined,
  left: undefined,
  right: undefined,
})

export default handleActions(
  {
    [actions.show]: (state, action) => ({
      ...state,
      isVisible: true,
      companyId: action.payload.companyId,
      top: action.payload.top,
      left: action.payload.left,
      right: action.payload.right,
    }),
    [actions.hide]: getInitialState,
  },
  getInitialState(),
)
