import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'

import Columns, {Column} from 'app/common/Columns'
import Section from 'app/common/Section'
import HealthRangeComparison from 'app/reusable/health/health-range-comparison'
import HealthScoreTrend from 'app/health/HealthScoreTrend'
import CompaniesSidebar from 'app/reusable/companies/companies-sidebar'
import StoryReader from 'app/reusable/stories/story-reader'
import * as strings from 'app/strings'
import {hasHistoricalHealthData} from 'app/utils/health'

import * as commonActions from './overview-actions'
import HealthFactorSummary from './HealthFactorSummary'
import OverviewHealth from './OverviewHealth'
import NoDataPage from '../company/CompanyOverviewNoData'
import Filters from './OverviewFilters'
import SaveConfirmationModal from 'app/common/charts/export-chart/SaveConfirmationModal'

import * as styles from './OverviewPage.less'

function OverviewPage({
  overviewType,
  healthData,
  dailyHealthData,
  factorDailyHealthData,
  subfactorDailyHealthData,

  selectedFactor,
  selectedSubfactor,
  timeFrameDays,
  selectedGeography,
  filterDefaults,
  showFiltersSaveModal,
  isManzamaLogoVisible = false,

  stories,
  selectedValences,
  selectValence,
  deselectValence,
  storySortOrder,
  changeStorySortOrder,
  shouldShowIndustryNewsFilter = false,
  isIndustryNewsFilterChecked,
  changeShowOnlyIndustryNews,
  shouldShowStoryCompanyNames = false,

  shouldShowCompaniesSidebar,
  companies,
  totalCompanyCount,
  myCompanyIds,
  moreCompaniesUrl,
  selectedCompanyId,
  shouldShowOnlyMyCompanies,
  changeShowOnlyMyCompanies,
  selectCompany,
  clearSelectedCompany,

  isPortalPage,

  selectFactor,
  selectSubfactor,
  selectTimeFrame,
  selectGeography,
}) {
  const hasData = hasHistoricalHealthData({dailyHealthData})
  const healthBadgeTitle = `${
    selectedSubfactor ? 'Subfactor' : selectedFactor ? 'Factor' : 'Overall'
  } Health - Current 30-Day Health Score`
  const healthSummaryTitle = `Health Factors - Current 30-Day ${
    selectedFactor
      ? strings.factorDisplayName(selectedFactor) +
        (selectedSubfactor
          ? ` - ${strings.subfactorDisplayName(selectedSubfactor)}`
          : '')
      : ''
  } Health Score`

  const healthBadgeChartKey = `health-badge-chart::'OverviewPage'-${timeFrameDays}`
  const trendChartKey = `health-score-trend-chart::'OverviewPage'-${timeFrameDays}`
  const HealthFactorsKey = `health-factors::'OverviewPage'-${timeFrameDays}`

  const contents = !hasData ? (
    <NoDataPage
      entityType={overviewType.toLowerCase()}
      shouldShow90DayFilter={timeFrameDays === 30}
    />
  ) : (
    <React.Fragment>
      <Columns>
        <Column>
          <Section
            className={styles.health}
            contentClassName={styles.content}
            title={healthBadgeTitle}
            chartKey={healthBadgeChartKey}
          >
            <div className={styles.healthBadge}>
              <OverviewHealth
                healthData={healthData}
                selectedFactor={selectedFactor}
                selectedSubfactor={selectedSubfactor}
                selectFactor={selectFactor}
                selectSubfactor={selectSubfactor}
              />
            </div>

            {healthData.healthScore !== null && (
              <div className={styles.healthRangeComparison}>
                <HealthRangeComparison
                  healthData={healthData}
                  factor={selectedFactor}
                  subfactor={selectedSubfactor}
                />
              </div>
            )}
          </Section>
        </Column>

        <Column className={styles.rightTopColumn}>
          <Section
            className={styles.healthTrend}
            contentClassName={styles.content}
            title={`Health Score Trend Over ${timeFrameDays} Days`}
            chartKey={trendChartKey}
          >
            <HealthScoreTrend
              dailyHealthData={dailyHealthData}
              factorDailyHealthData={factorDailyHealthData}
              subfactorDailyHealthData={subfactorDailyHealthData}
              factor={selectedFactor}
              subfactor={selectedSubfactor}
              timeFrameDays={timeFrameDays}
            />
          </Section>

          <Section
            className={styles.healthFactorSummary}
            contentClassName={styles.content}
            title={healthSummaryTitle}
            chartKey={HealthFactorsKey}
          >
            <HealthFactorSummary
              healthData={healthData}
              factor={selectedFactor}
              subfactor={selectedSubfactor}
              selectFactor={selectFactor}
              selectSubfactor={selectSubfactor}
              className={styles.table}
            />
          </Section>
        </Column>
      </Columns>

      <div className={styles.storiesContainer}>
        {shouldShowCompaniesSidebar && (
          <CompaniesSidebar
            companies={companies}
            myCompanyIds={myCompanyIds}
            selectedCompanyId={selectedCompanyId}
            totalCompanyCount={totalCompanyCount}
            moreCompaniesUrl={moreCompaniesUrl}
            sortOrder={storySortOrder}
            selectedFactor={selectedFactor}
            selectedSubfactor={selectedSubfactor}
            shouldShowOnlyMyCompanies={shouldShowOnlyMyCompanies}
            onShowOnlyMyCompaniesChange={changeShowOnlyMyCompanies}
            onSelectCompany={selectCompany}
            onClearSelectedCompany={clearSelectedCompany}
            className={styles.companies}
          />
        )}

        <StoryReader
          stories={stories}
          selectedValences={selectedValences}
          sortOrder={storySortOrder}
          shouldSort={false}
          shouldShowIndustryNewsFilter={shouldShowIndustryNewsFilter}
          isPortalPage={isPortalPage}
          isIndustryNewsFilterChecked={isIndustryNewsFilterChecked}
          shouldShowCompanyName={shouldShowStoryCompanyNames}
          onSelectValence={selectValence}
          onDeselectValence={deselectValence}
          onChangeSortBy={changeStorySortOrder}
          onChangeIndustryNewsFilter={changeShowOnlyIndustryNews}
          className={styles.stories}
        />
      </div>
    </React.Fragment>
  )

  return (
    <div className={classNames(styles.pageContent, 'overview')}>
      <Filters
        selectedFactor={selectedFactor}
        selectedSubfactor={selectedSubfactor}
        selectedTimeFrame={timeFrameDays}
        selectedGeography={selectedGeography}
        filterDefaults={filterDefaults}
        onSaveFilterDefaults={showFiltersSaveModal}
        isManzamaLogoVisible={isManzamaLogoVisible}
        onSelectFactor={selectFactor}
        onSelectSubfactor={selectSubfactor}
        onSelectTimeFrame={selectTimeFrame}
        onSelectGeography={selectGeography}
        className={styles.filters}
      />

      {contents}
      <SaveConfirmationModal />
    </div>
  )
}
OverviewPage.propTypes = {
  overviewType: PropTypes.string.isRequired,

  healthData: PropTypes.object,
  dailyHealthData: PropTypes.arrayOf(PropTypes.object),
  factorDailyHealthData: PropTypes.object,
  subfactorDailyHealthData: PropTypes.object,

  selectedFactor: PropTypes.string,
  selectedSubfactor: PropTypes.string,
  timeFrameDays: PropTypes.number.isRequired,
  selectedGeography: PropTypes.number,
  filterDefaults: PropTypes.shape({
    factor: PropTypes.string,
    subfactor: PropTypes.string,
    timeFrameDays: PropTypes.number.isRequired,
    geography: PropTypes.number.isRequired,
  }),
  showFiltersSaveModal: PropTypes.func,
  isManzamaLogoVisible: PropTypes.bool,

  stories: PropTypes.arrayOf(PropTypes.object),
  selectedValences: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectValence: PropTypes.func.isRequired,
  deselectValence: PropTypes.func.isRequired,
  storySortOrder: PropTypes.string.isRequired,
  changeStorySortOrder: PropTypes.func.isRequired,
  shouldShowIndustryNewsFilter: PropTypes.bool,
  isIndustryNewsFilterChecked: PropTypes.bool,
  changeShowOnlyIndustryNews: PropTypes.func,
  shouldShowStoryCompanyNames: PropTypes.bool,

  shouldShowCompaniesSidebar: PropTypes.bool.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object),
  totalCompanyCount: PropTypes.number,
  myCompanyIds: PropTypes.arrayOf(PropTypes.number),
  moreCompaniesUrl: PropTypes.string,
  selectedCompanyId: PropTypes.number,
  shouldShowOnlyMyCompanies: PropTypes.bool,
  changeShowOnlyMyCompanies: PropTypes.func,
  selectCompany: PropTypes.func,
  clearSelectedCompany: PropTypes.func,

  isPortalPage: PropTypes.bool,

  // Connected actions
  selectFactor: PropTypes.func.isRequired,
  selectSubfactor: PropTypes.func.isRequired,
  selectGeography: PropTypes.func.isRequired,
  selectTimeFrame: PropTypes.func.isRequired,
}

export default connect(
  null,
  {
    selectFactor: commonActions.selectFactor,
    selectSubfactor: commonActions.selectSubfactor,
    selectTimeFrame: commonActions.selectTimeFrame,
    selectGeography: commonActions.selectGeography,
  },
)(OverviewPage)
