import * as loginSelectors from 'app/login/login-selectors'

import {STATE_PROP_NAME} from './industry-overview-constants'

export const getIndustryOverview = state => state[STATE_PROP_NAME]
export const getIndustryId = state => getIndustryOverview(state).industryId
export const getIsLoading = state => getIndustryOverview(state).isLoading
export const getFactor = state => getIndustryOverview(state).selectedFactor
export const getSubfactor = state =>
  getIndustryOverview(state).selectedSubfactor
export const getTimeFrame = state => getIndustryOverview(state).timeFrameDays
export const getTotalCompanyCount = state =>
  getIndustryOverview(state).totalCompanyCount
export const getAllCompanyIds = state =>
  getIndustryOverview(state).allCompanyIds
export const getShouldShowFiltersSaveModal = state =>
  getIndustryOverview(state).shouldShowFiltersSaveModal

//
// Per-industry filter defaults
//

export const getFilterDefaultsForCurrentIndustry = state =>
  loginSelectors.getFilterDefaultsForIndustryId(state, getIndustryId(state))
export const getFactorForCurrentIndustry = state => {
  const factor = getFactor(state)
  if (!factor) {
    return getFilterDefaultsForCurrentIndustry(state).factor
  }
  if (factor === 'all') {
    return null
  }
  return factor
}
export const getSubfactorForCurrentIndustry = state => {
  const factor = getFactorForCurrentIndustry(state)
  if (!factor) {
    return null
  }
  const subfactor = getSubfactor(state)
  if (!subfactor) {
    return getFilterDefaultsForCurrentIndustry(state).subfactor
  }
  if (subfactor === 'all') {
    return null
  }
  return subfactor
}
export const getTimeFrameForCurrentIndustry = state =>
  getTimeFrame(state) ||
  getFilterDefaultsForCurrentIndustry(state).timeFrameDays
export const getGeographyForCurrentIndustry = state => {
  const geography = getIndustryOverview(state).selectedGeography
  if (geography === null) {
    return getFilterDefaultsForCurrentIndustry(state).geography
  }
  return geography
}
