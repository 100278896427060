import {createAction} from 'redux-actions'

const PREFIX = 'SUBFACTOR_HEALTH_BADGE'
const action = name => createAction(`${PREFIX}_${name}`)

export const init = action('INIT')
export const deinit = action('REMOVE')

export const highlightSubfactor = action('HIGHLIGHT_FACTOR')
export const clearHighlightedSubfactor = action('CLEAR_HIGHLIGHTED_FACTOR')
export const showTooltip = action('SHOW_TOOLTIP')
export const hideTooltip = action('HIDE_TOOLTIP')
