import {handleActions} from 'redux-actions'

import * as actions from './email-server-actions'

const getInitialState = () => ({
  isLoading: true,
  data: {
    firmId: 1,
    firmName: 'Test Firm',
    userId: 1,
    userFullName: 'Test User',
    isoEmailDate: new Date().toISOString(),
    serverUrl: process.env.BASE_APP_URL,
    insightsUrl: process.env.ROOT_URL,
    insightsLabel: process.env.PRODUCT_NAME,
    insightsLogo:
      process.env.MANZAMA_BRAND === 'manzama'
        ? 'https://static.manzama.com/imgs/insights-logotype-email-white.png'
        : 'https://static.manzama.com/imgs/diligent-logo-email-white-20230509.png',
    copyrightHolderName: 'Manzama Inc.',
    contactPhone: '541-706-9393',
    contactAddress: '543 NW York Drive, Suite 100, Bend, OR 97703',
    // Uncomment to test SSO redirects.
    // ssoLoginUrl: 'https://diligent.okta.com/',
    // ssoUrlDescription: 'Diligent Okta',
  },
})

export default handleActions(
  {
    [actions.setIsLoading]: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    [actions.setData]: (state, action) => ({
      ...state,
      data: {...state.data, ...action.payload},
    }),
  },
  getInitialState(),
)
