import PropTypes from 'prop-types'
import React from 'react'

/**
 * Generic radio button component. Should replace <input type="radio"> elements.
 */
export default function RadioButton({isChecked, className, ...restProps}) {
  return (
    <input
      type="radio"
      checked={isChecked}
      className={className}
      {...restProps}
    />
  )
}
RadioButton.propTypes = {
  isChecked: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
}
