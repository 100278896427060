import {handleActions} from 'redux-actions'
import {LOCATION_CHANGED} from 'redux-little-router'

import routes from 'app/routes'

import * as actions from './company-overview-simcirc-actions'
import {QUERY_PARAMS} from './company-overview-simcirc-constants'

export const getInitialState = () => ({
  isLoading: true,

  // Current State

  targetDate: new Date(),
  matches: [],
  comparedMatchId: null,
  selectedSubfactor: null,

  areStoriesLoading: true,
  targetStoryIds: [],
  matchStoryIds: [],

  // Forecast

  predictions: [],
  selectedPredictionFactor: null, // null means "overall"
  leadingHealthScoresByFactor: {},
  forecastMatchId: null,

  isForecastTimelineLoading: true,
  // Each item in the array represents a day on the timeline. The shape is:
  //   {
  //     daysSinceMatch,
  //     topStoryIds: [...],
  //     bars: [
  //       {factor, valence, volume},
  //       ...
  //     ],
  //   }
  forecastTimeline: [],
  forecastStoriesModalDaysSinceMatch: null,
  isForecastStoriesModalLoading: true,
  forecastModalStoryIds: [],
})

export default handleActions(
  {
    [LOCATION_CHANGED]: (state, action) => {
      if (action.payload.route === routes.companySimCircCurrent) {
        const comparedMatchId =
          action.payload.query[QUERY_PARAMS.COMPARED_MATCH] || null
        if (state.comparedMatchId !== comparedMatchId) {
          state = {
            ...state,
            comparedMatchId:
              comparedMatchId ||
              (state.matches.length ? state.matches[0].id : null),
          }
        }

        const selectedSubfactor =
          action.payload.query[QUERY_PARAMS.SUBFACTOR] || null
        if (state.selectedSubfactor !== selectedSubfactor) {
          state = {
            ...state,
            selectedSubfactor,
          }
        }
      } else if (action.payload.route === routes.companySimCircForecast) {
        const predictionFactor =
          action.payload.query[QUERY_PARAMS.PREDICTION_FACTOR] || null
        if (state.selectedPredictionFactor !== predictionFactor) {
          state = {
            ...state,
            selectedPredictionFactor: predictionFactor,
          }
        }

        const forecastMatchId =
          action.payload.query[QUERY_PARAMS.COMPARED_MATCH] || null
        if (state.forecastMatchId !== forecastMatchId) {
          state = {
            ...state,
            forecastMatchId,
          }
        }
      }

      // If we're leaving SimCirc, reset the state if it hasn't been already.
      else if (!state.isLoading) {
        return getInitialState()
      }

      return state
    },

    [actions.setIsLoading]: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),

    [actions.setTargetDate]: (state, action) => ({
      ...state,
      targetDate: action.payload,
    }),
    [actions.setMatches]: (state, action) => ({
      ...state,
      matches: action.payload,
      // If we already have a match ID and it's valid, use it; otherwise, select
      // the first one by default.
      comparedMatchId:
        state.comparedMatchId &&
        action.payload.find(match => match.id === state.comparedMatchId)
          ? state.comparedMatchId
          : action.payload[0].id,
    }),

    [actions.setAreStoriesLoading]: (state, action) => ({
      ...state,
      areStoriesLoading: action.payload,
    }),
    [actions.setTargetStoryIds]: (state, action) => ({
      ...state,
      targetStoryIds: action.payload,
    }),
    [actions.setMatchStoryIds]: (state, action) => ({
      ...state,
      matchStoryIds: action.payload,
    }),

    [actions.setPredictions]: (state, action) => ({
      ...state,
      predictions: action.payload,
    }),
    [actions.setLeadingHealthScores]: (state, action) => ({
      ...state,
      leadingHealthScoresByFactor: action.payload,
    }),

    [actions.setIsForecastTimelineLoading]: (state, action) => ({
      ...state,
      isForecastTimelineLoading: action.payload,
    }),
    [actions.setForecastTimeline]: (state, action) => ({
      ...state,
      forecastTimeline: action.payload,
    }),

    [actions.openForecastStoriesModal]: (state, action) => ({
      ...state,
      forecastStoriesModalDaysSinceMatch: action.payload,
      isForecastStoriesModalLoading: true,
      forecastModalStoryIds: [],
    }),
    [actions.closeForecastStoriesModal]: (state, action) => ({
      ...state,
      forecastStoriesModalDaysSinceMatch: null,
    }),
    [actions.setForecastModalStoryIds]: (state, action) => ({
      ...state,
      forecastModalStoryIds: action.payload,
      isForecastStoriesModalLoading: false,
    }),
  },
  getInitialState(),
)
