import {addDays, isSameDay, subDays} from 'date-fns'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {LoadingMessage} from 'app/common/loading-message'
import {
  CHART_COLORS,
  ORDERED_FACTORS,
  ORDERED_SUBFACTORS_BY_FACTOR,
  today,
  VALENCES,
} from 'app/constants'
import EventTimeline from 'app/storylines/EventTimeline'
import * as strings from 'app/strings'

import {timeline} from './company-overview-actions'
import {getCompanyOverviewTimeline} from './company-overview-selectors'

const colorForIndex = index => {
  return CHART_COLORS[index % CHART_COLORS.length]
}

function CompanyOverviewTimeline({
  storylines,

  // From state
  highlightedDay,
  highlightedGroups,
  selectedFactor,
  selectedSubfactor,
  timeFrameDays,

  // Actions
  highlightDay,
  clearHighlightedDay,
  highlightGroups,
  clearHighlightedGroups,
  showStoriesTooltip,
  hideStoriesTooltip,
}) {
  if (!storylines) {
    return <LoadingMessage />
  }

  const stories = R.pipe(
    R.map(R.prop('stories')),
    R.unnest,
    R.filter(
      story =>
        // We don't care about neutral articles.
        story.valence !== VALENCES.NEUTRAL &&
        // If a (sub-)factor is selected, we also want to filter by that.
        ((!selectedFactor && !selectedSubfactor) ||
          (selectedSubfactor && story.subfactor === selectedSubfactor) ||
          (!selectedSubfactor &&
            selectedFactor &&
            story.factor === selectedFactor)),
    ),
  )(storylines)

  const days = R.range(0, timeFrameDays)
  const startDay = subDays(today, timeFrameDays - 1)
  const storyVolume = R.prop('articleCount')
  const mapWithIndex = R.addIndex(R.map)

  const storiesByDay = days.map(day => {
    const date = addDays(startDay, day)
    return stories.filter(story => isSameDay(story.date, date))
  })

  // If we've selected a subfactor, then we're grouping by storyline;
  // if we've selected a factor, we're grouping by subfactor; otherwise, we're
  // grouping by factor.
  const groupPropertyName = selectedSubfactor
    ? 'storylineId'
    : selectedFactor
    ? 'subfactor'
    : 'factor'

  // Each item here represents the sum of article volume for each
  // combination of group and valence.
  const itemsByDay = storiesByDay.map(
    R.pipe(
      R.groupBy(story => `${story[groupPropertyName]}:${story.valence}`),
      R.toPairs,
      R.map(([groupIdValence, stories]) => {
        const [groupId, valence] = groupIdValence.split(':')
        const volume = R.sum(stories.map(storyVolume))
        return {
          // We represent negative valence with negative values
          value: (valence === VALENCES.POSITIVE ? 1 : -1) * volume,
          group: groupId,
        }
      }),
    ),
  )

  // Groups represent the different bar colors in the chart
  let groups
  if (selectedSubfactor) {
    groups = R.pipe(
      R.groupBy(R.prop('storylineId')),
      R.keys,
      R.map(storylineId => storylines.find(sl => sl.id == storylineId)),
      mapWithIndex((storyline, index) => ({
        id: storyline.id.toString(),
        label: storyline.summary,
        color: colorForIndex(index),
      })),
    )(stories)
  } else if (selectedFactor) {
    groups = ORDERED_SUBFACTORS_BY_FACTOR[selectedFactor].map(
      (subfactor, index) => ({
        id: subfactor,
        label: strings.subfactorDisplayName(subfactor),
        color: colorForIndex(index),
      }),
    )
  } else {
    groups = ORDERED_FACTORS.map((factor, index) => ({
      id: factor,
      label: strings.factorDisplayName(factor),
      color: colorForIndex(index),
    }))
  }

  const onHighlightDay = (day, event) => {
    // TODO: Encapsulate this into its own action, and move this logic into a
    // saga.

    highlightDay(day)
    highlightGroups(
      R.pipe(
        R.nth(day),
        R.groupBy(R.prop(groupPropertyName)),
        R.keys,
      )(storiesByDay),
    )

    // Show storylines tooltip
    const topOffset = 40 // Starts this many pixels above the hovered element
    const el = event.target.parentNode
    const rect = el.getBoundingClientRect()
    const docElement = document.documentElement
    const top = Math.round(rect.top + docElement.scrollTop) - topOffset
    const left = Math.floor(rect.right + docElement.scrollLeft)
    const right = Math.floor(rect.left + docElement.scrollLeft)
    const date = addDays(startDay, day)
    const stories = storiesByDay[day]
    const storyIds = stories.map(R.prop('id'))
    const colors = stories.map(
      story =>
        groups.find(group => group.id === story[groupPropertyName].toString())
          .color,
    )
    showStoriesTooltip({top, left, right, date, storyIds, colors})
  }
  const onClearHighlightedDay = () => {
    clearHighlightedDay()
    clearHighlightedGroups()
    hideStoriesTooltip()
  }

  return (
    <EventTimeline
      items={itemsByDay}
      groups={groups}
      highlightedDay={highlightedDay}
      highlightedGroups={highlightedGroups}
      selectedFactor={selectedFactor}
      timeFrameDays={timeFrameDays}
      onHighlightDay={onHighlightDay}
      onClearHighlightedDay={onClearHighlightedDay}
    />
  )
}
CompanyOverviewTimeline.propTypes = {
  storylines: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default connect(
  createSelector(
    [getCompanyOverviewTimeline],
    state => ({
      highlightedDay: state.highlightedDay,
      highlightedGroups: state.highlightedGroups,
    }),
  ),
  {
    highlightDay: timeline.highlightDay,
    clearHighlightedDay: timeline.clearHighlightedDay,
    highlightGroups: timeline.highlightGroups,
    clearHighlightedGroups: timeline.clearHighlightedGroups,
    showStoriesTooltip: timeline.showStoriesTooltip,
    hideStoriesTooltip: timeline.hideStoriesTooltip,
  },
)(CompanyOverviewTimeline)
