import classNames from 'classnames'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import {
  filter,
  flatten,
  identity,
  map,
  path,
  pipe,
  prop,
  reduce,
  reverse,
  sortBy,
} from 'ramda'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import HealthBar from 'app/common/HealthBar'
import HealthScore from 'app/common/HealthScore'
import InlineSvg from 'app/common/InlineSvg'
import Link from 'app/common/NavigationLink'
import {DEFAULT_TIME_FRAME_DAYS, SORT_DIRECTIONS} from 'app/constants'
import * as strings from 'app/strings'
import Table from 'app/common/Table'
import CompanyRangeChart from 'app/reusable/companies/company-range-chart'
import HealthTrend from 'app/reusable/health/health-trend'
import urls from 'app/urls'
import {calculateHealthRange} from 'app/utils/health'

import * as actions from './dashboard-actions'
import {MAX_HEALTH_CHANGES_BEFORE_EXPANDING} from './dashboard-constants'
import * as selectors from './dashboard-selectors'
import {
  calculateRecentSubfactorScoreChanges,
  isHealthChangeSignificant,
  sumAbsoluteHealthChanges,
} from './dashboard-utils'

import * as styles from './DashboardHealthChangesTable.less'
import arrowIconUrl from 'static/images/arrow.svg'
import filterIconUrl from 'static/images/filter.svg'
import warningIconUrl from 'static/images/warning.svg'

// The sort function for each sortable column in the table.
const sortByFunctionsByColumn = {
  companyName: path(['company', 'name']),
  health: ({company}) => company.healthData.healthScore,
  range: ({company}) => {
    const {min, max} = calculateHealthRange(company)
    return max - min
  },
  trend: ({company}) => sumAbsoluteHealthChanges(company),
  // Sort by the largest amount of subfactor health changes.
  healthChanges: ({healthChanges}) => healthChanges.length,
}

const FilterIcon = ({companyId, subfactor}) => {
  return (
    <Link
      href={urls.companyOverview(companyId, {subfactor})}
      className={styles.filter}
      title="Filter by Subfactor"
    >
      <InlineSvg
        src={filterIconUrl}
        className={classNames(styles.icon, 'filter-icon')}
      />
    </Link>
  )
}
FilterIcon.propTypes = {
  companyId: PropTypes.number.isRequired,
  subfactor: PropTypes.string,
}

class DashboardHealthChangesTable extends React.PureComponent {
  static propTypes = {
    companies: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,

    // From state
    currentSort: PropTypes.object.isRequired,

    // Actions
    setSort: PropTypes.func.isRequired,
    expandHealthChanges: PropTypes.func.isRequired,
    collapseHealthChanges: PropTypes.func.isRequired,
  }

  render() {
    const {
      companies,
      currentSort,
      expandedHealthChangeCompanyIds,
      setSort,
    } = this.props

    const rows = pipe(
      map(company => ({
        company,
        healthChanges: pipe(
          calculateRecentSubfactorScoreChanges,
          filter(({change}) => isHealthChangeSignificant(change)),
          sortBy(
            pipe(
              prop('change'),
              Math.abs,
            ),
          ),
          reverse,
        )(company),
      })),

      // We only want companies with health changes.
      filter(({healthChanges}) => healthChanges.length),

      // We have to do sorting here so that related rows stay in the expected
      // order.
      sortBy(sortByFunctionsByColumn[currentSort.column]),
      currentSort.direction === SORT_DIRECTIONS.DESC ? reverse : identity,

      reduce((rows, {company, healthChanges}) => {
        rows.push({
          id: company.id.toString(),
          company,
          healthChange: healthChanges.length === 1 ? healthChanges[0] : null,
          healthChangeCount: healthChanges.length,
          isHealthChangeRow: false,
          isLast: healthChanges.length === 1,
        })
        if (healthChanges.length > 1) {
          const isExpanded = expandedHealthChangeCompanyIds.includes(company.id)
          rows = rows.concat(
            healthChanges
              .slice(
                0,
                isExpanded ? undefined : MAX_HEALTH_CHANGES_BEFORE_EXPANDING,
              )
              .map((healthChange, index) => ({
                id: `${company.id}-${index}`,
                company,
                healthChange,
                healthChangeCount: healthChanges.length,
                isHealthChangeRow: true,
                isLast: index === healthChanges.length - 1 && !isExpanded,
              })),
          )
          if (
            healthChanges.length > MAX_HEALTH_CHANGES_BEFORE_EXPANDING &&
            !isExpanded
          ) {
            // Expand row
            rows.push({
              id: `${company.id}-more`,
              company,
              healthChange: null,
              healthChangeCount: healthChanges.length,
              isHealthChangeRow: true,
              isLast: true,
            })
          } else if (isExpanded) {
            // Collapse row
            rows.push({
              id: `${company.id}-less`,
              company,
              healthChange: null,
              healthChangeCount: healthChanges.length,
              isHealthChangeRow: true,
              isLast: true,
            })
          }
        }
        return rows
      }, []),

      flatten,
    )(companies)

    return (
      <Table
        data={rows}
        className={classNames(styles.table, 'table')}
        rowClassName={({isHealthChangeRow, isLast}) =>
          classNames(styles.row, {
            [styles.first]: !isHealthChangeRow,
            [styles.last]: isLast,
          })
        }
        sort={currentSort}
        onSortChange={sort => setSort(sort)}
        ignoreSort
      >
        <Table.Column
          name="companyName"
          label="Company Name"
          className={styles.companyCell}
          baseWidth={140}
          growRatio={2}
          isSortable
          colSpan={row => (row.isHealthChangeRow ? 4 : 1)}
          cellContents={row => {
            if (row.isHealthChangeRow) {
              return null
            }
            const {company} = row
            return (
              <Link href={urls.companyOverview(company.id)}>
                {company.name}
              </Link>
            )
          }}
        />

        <Table.Column
          name="health"
          label="Company Health Score"
          baseWidth={120}
          className={styles.healthCell}
          isSortable
          cellContents={row => {
            const {company} = row
            return (
              <span className={styles.healthContainer}>
                <HealthScore
                  score={company.healthData.healthScore}
                  className={styles.healthScore}
                />
                <HealthBar
                  healthData={company.healthData}
                  className={styles.healthBar}
                />
              </span>
            )
          }}
        />

        <Table.Column
          name="range"
          label="30 Day Range"
          className={styles.rangeCell}
          baseWidth={120}
          isSortable
          defaultSortDirection={SORT_DIRECTIONS.DESC}
          cellContents={({company}) => <CompanyRangeChart company={company} />}
        />

        <Table.Column
          name="trend"
          label="30 Day Trend"
          className={styles.trendCell}
          baseWidth={120}
          isSortable
          defaultSortDirection={SORT_DIRECTIONS.DESC}
          cellContents={({company}) => (
            <HealthTrend
              dailyHealthData={company.dailyHealthData}
              healthData={company.healthData}
              timeFrameDays={DEFAULT_TIME_FRAME_DAYS}
            />
          )}
        />

        <Table.Column
          name="healthChanges"
          label="Recent Health Score Change"
          className={styles.healthChangeCell}
          baseWidth={220}
          isSortable
          defaultSortDirection={SORT_DIRECTIONS.DESC}
          cellContents={row => {
            if (!row.healthChange) {
              if (!row.isHealthChangeRow) {
                return (
                  <div className={styles.changeCountContainer}>
                    <InlineSvg src={warningIconUrl} className={styles.icon} />
                    <span className={styles.label}>
                      {row.healthChangeCount} Recent Health Changes
                    </span>
                  </div>
                )
              }
              const isExpanded = expandedHealthChangeCompanyIds.includes(
                row.company.id,
              )
              const moreChangeCount =
                row.healthChangeCount - MAX_HEALTH_CHANGES_BEFORE_EXPANDING
              if (isExpanded) {
                return (
                  <a
                    onClick={bind(
                      this.collapseHealthChanges,
                      this,
                      row.company.id,
                    )}
                    className="collapse"
                  >
                    Collapse Subfactor Health Changes
                  </a>
                )
              }
              return (
                <a
                  onClick={bind(this.expandHealthChanges, this, row.company.id)}
                  className="expand"
                >
                  Expand {moreChangeCount} More Subfactor Health Change
                  {moreChangeCount > 1 && 's'}
                </a>
              )
            }
            const {change, subfactor} = row.healthChange
            const valenceClassNames = {
              [styles.positive]: change > 0,
              [styles.negative]: change < 0,
            }
            return (
              <div
                className={classNames(
                  styles.healthChangeContainer,
                  'health-change',
                )}
              >
                <div className={styles.changeContainer}>
                  <div className={styles.subfactor}>
                    {strings.subfactorDisplayName(subfactor)}
                  </div>

                  <div className={styles.healthChange}>
                    <InlineSvg
                      src={arrowIconUrl}
                      className={classNames(styles.arrow, valenceClassNames)}
                    />
                    <span
                      className={classNames(styles.label, valenceClassNames)}
                    >
                      {(change > 0 ? 'Positive' : 'Negative') + ' '}
                      Health Score Change
                    </span>
                  </div>
                </div>
              </div>
            )
          }}
        />

        <Table.Column
          name="filter"
          label=""
          className={styles.filterCell}
          baseWidth={44}
          growRatio={0}
          shrinkRatio={0}
          cellContents={({company, healthChange}) => {
            if (!healthChange) return null
            return (
              <FilterIcon
                companyId={company.id}
                subfactor={healthChange.subfactor}
              />
            )
          }}
        />
      </Table>
    )
  }

  expandHealthChanges(companyId) {
    this.props.expandHealthChanges(companyId)
  }

  collapseHealthChanges(companyId) {
    this.props.collapseHealthChanges(companyId)
  }
}

export default connect(
  createSelector(
    [selectors.getHealthChangeTable],
    state => ({
      currentSort: state.sort,
      expandedHealthChangeCompanyIds: state.expandedHealthChangeCompanyIds,
    }),
  ),
  {
    setSort: actions.setHealthChangeTableSort,
    expandHealthChanges: actions.expandHealthChangesForCompany,
    collapseHealthChanges: actions.collapseHealthChangesForCompany,
  },
)(DashboardHealthChangesTable)
