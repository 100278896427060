import {combineReducers} from 'redux'

import * as addCompaniesModal from 'app/reusable/entities/add-modal'
import * as companiesDisplay from 'app/reusable/companies/companies-display'
import * as companiesTable from 'app/reusable/companies/companies-table'
import * as companiesPage from 'app/companies-page'
import * as companyReport from 'app/overviews/company/report'
import * as companyOverview from 'app/overviews/company'
import * as dashboard from 'app/dashboard'
import * as industries from 'app/industries'
import * as factorDetailsModal from 'app/overviews/company/factor-details-modal'
import * as industryOverview from 'app/overviews/industry'
import * as industryReport from 'app/overviews/industry/report'
import entitiesReducer from 'app/framework/entities-reducer'
import * as global from 'app/global'
import {reducer as healthVolumeMatrixReducer} from 'app/health/health-volume-matrix/tooltip'
import interimPageReducer from 'app/interim-page/interim-page-reducer'
import loginReducer from 'app/login/login-reducer'
import * as myEntityStar from 'app/reusable/entities/my-entity-star'
import * as shareArticleModal from 'app/share-article-modal'
import * as flagArticleModal from 'app/flag-article-modal'
import * as storyModal from 'app/story-modal'
import storylineModalReducer from 'app/storyline-modal/storyline-modal-reducer'
import * as storylineView from 'app/storyline-view'
import * as factorHealthBadge from 'app/reusable/health/health-badge/factor'
import * as subfactorHealthBadge from 'app/reusable/health/health-badge/subfactor'
import * as industriesTable from 'app/reusable/industries/industries-table'
import * as profileBuilder from 'app/profile-builder'
import * as charts from 'app/common/charts'
import * as litigation from 'app/litigation'

const reducers = {
  [addCompaniesModal.constants.STATE_PROP]: addCompaniesModal.reducer,
  [companiesDisplay.constants.STATE_PROP]: companiesDisplay.reducer,
  [companiesTable.constants.STATE_PROP_NAME]: companiesTable.reducer,
  [companiesPage.constants.STATE_PROP_NAME]: companiesPage.reducer,
  [companyOverview.constants.STATE_PROP_NAME]: companyOverview.reducer,
  [factorDetailsModal.constants.STATE_PROP_NAME]: factorDetailsModal.reducer,
  [companyReport.constants.STATE_PROP_NAME]: companyReport.reducer,
  [dashboard.constants.STATE_PROP_NAME]: dashboard.reducer,
  entities: entitiesReducer,
  [industries.constants.STATE_PROP_NAME]: industries.reducer,
  [industriesTable.constants.STATE_PROP_NAME]: industriesTable.reducer,
  [industryOverview.constants.STATE_PROP_NAME]: industryOverview.reducer,
  [industryReport.constants.STATE_PROP_NAME]: industryReport.reducer,
  [global.constants.STATE_PROP]: global.reducer,
  [factorHealthBadge.constants.STATE_PROP_NAME]: factorHealthBadge.reducer,
  healthVolumeMatrix: healthVolumeMatrixReducer,
  interimPage: interimPageReducer,
  login: loginReducer,
  [myEntityStar.constants.STATE_PROP_NAME]: myEntityStar.reducer,
  [profileBuilder.constants.STATE_PROP_NAME]: profileBuilder.reducer,
  [shareArticleModal.constants.STATE_PROP_NAME]: shareArticleModal.reducer,
  [flagArticleModal.constants.STATE_PROP_NAME]: flagArticleModal.reducer,
  [storyModal.constants.STATE_PROP_NAME]: storyModal.reducer,
  storylineModal: storylineModalReducer,
  [storylineView.constants.STATE_PROP_NAME]: storylineView.reducer,
  [subfactorHealthBadge.constants.STATE_PROP_NAME]:
    subfactorHealthBadge.reducer,
  [charts.constants.STATE_PROP_NAME]: charts.reducer,
  [litigation.constants.STATE_PROP_NAME]: litigation.reducer,

  // Placeholder reducer since the actual one is located in store.js.
  router: (state = {}) => state,
}

if (process.env.USE_EMAIL_TEST_PAGES) {
  const {constants, reducer} = require('email-server/local-dev')
  reducers[constants.STATE_PROP_NAME] = reducer
}

if (process.env.USE_EMAIL_TEST_PAGES) {
  const {constants, reducer} = require('esg-email-server/local-dev')
  reducers[constants.STATE_PROP_NAME] = reducer
}

export default combineReducers(reducers)
