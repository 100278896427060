import React, {createContext} from 'react'

export const EmailAlertContext = createContext({
  firmId: null,
  ssoLoginUrl: null,
  ssoUrlDescription: null,
})
EmailAlertContext.displayName = 'EmailAlertContext'
export const {
  Provider: EmailAlertProvider,
  Consumer: EmailAlertConsumer,
} = EmailAlertContext
