import {all, delay, put, select, takeEvery} from 'redux-saga/effects'

import {pyappRequest} from 'app/api'
import {fetchMyCompanies, fetchMyIndustries} from 'app/api/api-saga-helpers'
import {getJwt} from 'app/login/login-selectors'
import urls from 'app/urls'
import {updateSavedSearchEmailSettings} from 'app/reusable/entities/my-entity-star/my-entity-star-saga'

import * as actions from './add-entities-modal-actions'

function* handleAddEntities(action) {
  let req
  if (MOCK_BASE_API) {
    console.log(
      `Adding saved searches with IDs [${action.payload.ids.join(', ')}]`,
    )
    yield put(actions.setIsLoading())
    req = delay(750)
  } else {
    const token = yield select(getJwt)
    req = pyappRequest({
      url: urls.pyapp.api.addSavedSearches(),
      method: 'PUT',
      token,
      data: {
        ids: action.payload.ids,
        emailAlerts: false,
        allowDuplicates: false,
      },
    })
    yield put(actions.setIsLoading())
  }
  try {
    yield req
  } catch (error) {
    yield put(actions.setError(error))
    return
  }
  yield put(actions.setIsAdded())
  if (action.payload.entityType === 'company') {
    yield yield* fetchMyCompanies()
  } else {
    yield yield* fetchMyIndustries()
  }
}

function* handleConfirmEmailSettings(action) {
  const {savedSearchIds, frequency} = action.payload
  yield put(actions.setIsLoading())
  try {
    yield* updateSavedSearchEmailSettings({savedSearchIds, frequency})
    yield put(actions.setIsFinished())
  } catch (error) {
    yield put(actions.setError(error))
  }
}

export default function* addEntitiesModalSaga() {
  yield all([
    takeEvery(actions.addEntities, handleAddEntities),
    takeEvery(actions.confirmEmailSettings, handleConfirmEmailSettings),
  ])
}
