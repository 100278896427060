import PropTypes from 'prop-types'
import React from 'react'

export default function EmailIcon({className}) {
  return (
    <svg viewBox="0 0 29.9 21.9" className={className}>
      <path
        fill="#231F20"
        d="M14.8,13.2c-0.6,0-1.2-0.2-1.7-0.6l-10-8.8C3,3.6,2.9,3.3,3.1,3.1c0.2-0.2,0.5-0.2,0.7,0l10,8.8
        c0.6,0.5,1.5,0.5,2,0l10-8.8c0.2-0.2,0.5-0.2,0.7,0c0.2,0.2,0.2,0.5,0,0.7l-10,8.8C16,13,15.4,13.2,14.8,13.2z"
      />
      <path
        fill="#231F20"
        d="M26.2,19c-0.1,0-0.2,0-0.3-0.1l-6.7-5.4c-0.2-0.2-0.2-0.5-0.1-0.7c0.2-0.2,0.5-0.2,0.7-0.1l6.7,5.4
        c0.2,0.2,0.2,0.5,0.1,0.7C26.5,19,26.3,19,26.2,19z"
      />
      <path
        fill="#231F20"
        d="M3.5,19c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.1-0.5,0.1-0.7l6.7-5.4c0.2-0.2,0.5-0.1,0.7,0.1
        c0.2,0.2,0.1,0.5-0.1,0.7l-6.7,5.4C3.7,19,3.6,19,3.5,19z"
      />
      <path
        fill="#231F20"
        d="M26.5,21.9h-23c-1.9,0-3.4-1.5-3.4-3.3V3.3C0,1.5,1.5,0,3.4,0h23c1.9,0,3.5,1.5,3.5,3.3v15.3
			C29.9,20.4,28.4,21.9,26.5,21.9z M3.4,1C2.1,1,1,2,1,3.3v15.3c0,1.3,1.1,2.3,2.4,2.3h23c1.4,0,2.5-1,2.5-2.3V3.3
			C28.9,2,27.9,1,26.5,1H3.4z"
      />
    </svg>
  )
}
EmailIcon.propTypes = {
  className: PropTypes.string,
}
