import {all, put, select, takeEvery} from 'redux-saga/effects'

import {fetchMyIndustries} from 'app/api/api-saga-helpers'
import {actions as commonActions} from 'app/reusable/entities/my-entity-star'
import {
  addSavedSearch,
  removeSavedSearch,
} from 'app/reusable/entities/my-entity-star/my-entity-star-saga'
import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import Industry from 'app/models/Industry'

import * as actions from './my-industry-star-actions'

function* addIndustry(action) {
  const {
    key,
    value: {id, savedSearchId},
  } = action.payload

  yield put(commonActions.setIsLoading({key: key, value: true}))
  yield yield* addSavedSearch(savedSearchId)
  yield yield* fetchMyIndustries()
  yield put(commonActions.setIsLoading({key: key, value: false}))

  const industry = yield select(state => {
    const orm = Orm.withEntities(getEntities(state))
    return orm.getById(Industry, id)
  })
  yield put(
    commonActions.showEmailSettingsModal({
      key,
      value: {id: savedSearchId, name: industry.name},
    }),
  )
}

function* removeIndustry(action) {
  yield put(commonActions.setRemovalConfirming({key: action.payload.key}))
  yield yield* removeSavedSearch(action.payload.value.savedSearchId)
  yield yield* fetchMyIndustries()
  yield put(commonActions.setRemovalConfirmed({key: action.payload.key}))
}

export default function* myIndustryStarSaga() {
  yield all([
    takeEvery(actions.addIndustry, addIndustry),
    takeEvery(actions.removeIndustry, removeIndustry),
  ])
}
