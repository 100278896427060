import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import classNames from 'classnames'
import moment from 'moment'

import Button from 'app/common/Button'
import * as entitiesSelectors from 'app/framework/entities-selectors'
import ManzamaLogo from 'app/common/logos/manzama-logo'
import { getRouterState } from 'app/router/router-selectors'
import Orm from 'app/framework/Orm'
import { LitigationNumberSummary, LitigationTimeSeries, Story } from 'app/models'

import * as selectors from 'app/litigation/litigations-selectors'
import * as actions from 'app/litigation/litigations-actions'
import { getInitialFilterState } from 'app/litigation/litigations-constants'
import LitigationStoryChart from 'app/litigation/LitigationStoryChart'
import * as printStyles from './Report.less'
import * as styles from './LitigationSector.less'
import LoadingSpinner from 'app/common/loading-spinner'
import { FILTER_CHECKBOXES, NO_COMPANY_LITIGATION_DATA } from './litigations-constants'
import InlineSvg from '../common/InlineSvg/InlineSvg'
import DiligentLogo from './icons/DiligentLogo'


export default function LitigationSectorReport() {
  const dispatch = useDispatch()
  const entities = useSelector(entitiesSelectors.getEntities)
  const mainPage = useSelector(selectors.getMainPage)
  const sectorPage = useSelector(selectors.getSectorPage)
  const isDownloadingPdf = useSelector(selectors.isDownloadingPdf)
  const { isLoading, filters, companyTimeSeries, selectedCompanyTimeSeries: selectedCompanyIds,
    stories: { data: storyIds, error: storyFetchError } } = sectorPage
  const { sectors, selectedSectors: selectedSectorIds, myCompanies, selectedFilter } = mainPage

  const orm = Orm.withEntities(entities)
  const selectedSectors = selectedSectorIds && orm.getByIds(LitigationNumberSummary, selectedSectorIds)
  const filteredSelectedCompanies = entities.litigationTimeSeries && companyTimeSeries.length > 0 ? orm.getByIds(LitigationTimeSeries, selectedCompanyIds) : []
  const updatedCompanyTimeSeries = [...companyTimeSeries]

  // to find and add those selected companies which are not found in API
  if(selectedFilter === FILTER_CHECKBOXES.myCompanies && filteredSelectedCompanies.length > 0)
  {
    selectedCompanyIds.map(companyId => {
      if(!filteredSelectedCompanies.some(fsc=> fsc && fsc.id === companyId)) {
        const filteredCompanies = myCompanies.filter(com => com.id === companyId)
        if(filteredCompanies.length === 0) {
          return
        }
        const companyDetails = filteredCompanies[0]
        const company = {
          id: companyId,
          companyName: companyDetails.displayName,
          companyRank: updatedCompanyTimeSeries.length + 1,
          companyWeeklyLitigationCounts: []
        }
        filteredSelectedCompanies.push(company)
        companies.push(company)
        updatedCompanyTimeSeries.push(companyId)
      }
    })
  }
  const selectedCompanies = filteredSelectedCompanies.filter(company => company != undefined).sort((a, b) => a.companyName.localeCompare(b.companyName))


  const stories = storyIds ? orm.getByIds(Story, storyIds) : null
  const companyChartData = []
  for (const company of selectedCompanies) {
    for (const [index, count] of company.companyWeeklyLitigationCounts.entries()) {
      if (companyChartData[index] === undefined) {
        companyChartData.push({
          storyWeekDate: count.storyWeekDate,
          headlines: {
            [company.id]: count.leadingHeadline,
          },
          [company.id]: count.companyWeeklyLitigationCounts,
        })
      } else {
        companyChartData[index][company.id] = count.companyWeeklyLitigationCounts
        companyChartData[index].headlines[company.id] = count.leadingHeadline
      }
    }
  }

  return (
    <SectorView
      sectors={selectedSectors}
      filteredCompanies={selectedCompanies}
      companyChartData={companyChartData}
      filters={filters}
      setFilters={(f) => dispatch(actions.setCompanyFilters(f))}
      resetFilters={() => dispatch(actions.setCompanyFilters(getInitialFilterState()))}
      isLoading={isLoading}
      stories={stories}
      storyFetchError={storyFetchError}
      isDownloadingPdf={isDownloadingPdf}
      downloadPdf={(payload) => dispatch(actions.startDownloadPdf(payload))}
    />
  )
}

function SectorView({
  sectors,
  filteredCompanies = [],
  companyChartData = [],
  isLoading,
  isDownloadingPdf,
  downloadPdf,
}) {

  const sectorsName = sectors && sectors.length > 0 ? sectors.map(sec=> sec.name || '' ).join(', ') : 'Sector'
  const haveChartData = !isLoading ? filteredCompanies.some(company => company.companyWeeklyLitigationCounts.length > 0) : true
  return (
    <>
      {/* Inline these styles, because otherwise they would apply to the entire SPA. */}
      <style>
        {`
        @media print {
          @page {
            size: landscape;
          }
        }
        
        @page {
          margin: 0 0 0 0;        
        }
        
        @media print {
          html, body {
            height: 210mm;
            width: 297mm;
            print-color-adjust: exact;
            -webkit-print-color-adjust: exact;
          }
        }
        `}
      </style>
      <div className={printStyles.topBar}>
        <div className={classNames(printStyles.inner, printStyles.landscape)}>
          <div className={printStyles.label}>
            {process.env.BRAND_NAME} {process.env.PRODUCT_NAME} Sectors Report
            {isLoading ? <LoadingSpinner className={printStyles.spinner}/> : null}
          </div>

          <div className={printStyles.buttons}>
            <Button
              label={isDownloadingPdf ? 'Downloading...' : 'Download PDF'}
              isPrimary={true}
              isDisabled={isDownloadingPdf}
              onClick={() => downloadPdf()}
            />
          </div>
        </div>
      </div>
      <div className={classNames(printStyles.container, printStyles.landscape)}>
        <div className={classNames(printStyles.coverContainer, printStyles.landscape)}>
          <div className={printStyles.cover}>
            <div className={printStyles.header}>
              <DiligentLogo/>
              <div className={printStyles.headerInfo}>
                <p>Litigation_sector_report_{`${moment().format('YYYYMMDD_HHmm')}`}</p>
                <p>exported at: {`${moment().format('L')}`}</p>
              </div>
            </div>
            <div className={printStyles.anchorToBottom}>
              <div className={printStyles.spacer}/>
              <h1 className={printStyles.normalWeight}>
                <span className={printStyles.bold}>{sectorsName}</span> litigation stories by top
                companies
              </h1>
            </div>
          </div>
        </div>
        <table className={printStyles.formatTable}>
          <thead>
          <tr>
            <td>
              <div className={printStyles.tableHeader}>
                <DiligentLogo/>
                <div className={printStyles.headerInfo}>
                  <p>Litigation_sector_report_{`${moment().format('YYYYMMDD_HHmm')}`}</p>
                  <p>exported at: {`${moment().format('L')}`}</p>
                </div>
              </div>
            </td>
          </tr>
          </thead>
          <tbody className={printStyles.landscape}>
          <tr className={printStyles.breakRow}>
            <td className={printStyles.formatTable}>
              <hr className={printStyles.sectionSeparator}/>
              <div className={classNames(printStyles.contentContainer, printStyles.landscape)}>
                <div className={printStyles.sectionTitle}>
                  LITIGATION STORY COUNTS FOR COMPANIES - these are based on your filtering
                </div>
                {haveChartData ? <LitigationStoryChart
                  companies={filteredCompanies}
                  companyChartData={companyChartData}
                  isLoading={isLoading}
                  widthOverride={'100%'}
                  heightOverride={570}
                /> : <div className={styles.noMatch}>{NO_COMPANY_LITIGATION_DATA}</div>}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <hr className={printStyles.sectionSeparator}/>

              <div className={classNames(printStyles.contentContainer, printStyles.landscape)}>
                <div className={printStyles.sectionTitle}>
                  NUMBER OF STORIES PER COMPANY
                </div>
                <table className={classNames(printStyles.dataTable, printStyles.sector)}>
                  <thead>
                  <tr>
                    <th/>
                    {filteredCompanies && filteredCompanies.map((company, index) => (
                      <th key={`company-${company.id}-${index}`}>{company.companyName}</th>
                    ))}
                  </tr>
                  </thead>
                  <tbody>
                  {companyChartData && companyChartData.map(
                    (data, index) => (
                      <tr key={`data-${index}`}>
                        <td>
                          {moment(data.storyWeekDate).format('YYYY MMM')
                            + ' '
                            + moment(data.storyWeekDate).startOf('week').format('DD')
                            + ' - '
                            + moment(data.storyWeekDate).endOf('week').format('DD')
                            + ' - Week '
                            + moment(data.storyWeekDate).format('ww')
                          }
                        </td>
                        {filteredCompanies.map((company, index) => (
                          <td key={`data-company-${company.id}-${index}`}>
                            {data[company.id]}
                          </td>
                        ))}
                      </tr>
                    )
                  )}
                  </tbody>
                  <tfoot>
                  <tr>
                    <td>
                      <div className={printStyles.tableFooter}/>
                    </td>
                    <td/>
                    <td/>
                    <td/>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className={printStyles.footerLine}/>
    </>
  )
}
