import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import Link from 'app/common/NavigationLink'
import {getCurrentRoute} from 'app/router/router-selectors'
import routes from 'app/routes'
import urls from 'app/urls'

import styles from './styles.less'

function SubNavBar({currentRoute}) {
  const activeStylesForRoute = currentRoute
    ? route => ({
        [styles.active]: currentRoute.startsWith(route),
      })
    : () => ({})

  return (
    <div className={styles.subNav}>
      <Link
        href={urls.dashboard()}
        className={classNames(
          styles.link,
          activeStylesForRoute(routes.dashboard),
        )}
      >
        Dashboard
      </Link>

      <Link
        href={urls.companies()}
        className={classNames(
          styles.link,
          activeStylesForRoute(routes.companies),
        )}
      >
        Companies
      </Link>

      <Link
        href={urls.industries()}
        className={classNames(
          styles.link,
          activeStylesForRoute(routes.industries),
        )}
      >
        Industries
      </Link>

      <Link
        href={urls.litigation()}
        className={classNames(
          styles.link,
          activeStylesForRoute(routes.litigation),
        )}
      >
        Litigation Trends
      </Link>
    </div>
  )
}
SubNavBar.propTypes = {
  currentRoute: PropTypes.string,
}
export default connect(
  createSelector(
    [getCurrentRoute],
    currentRoute => ({ currentRoute }),
  ),
)(SubNavBar)
