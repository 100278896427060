import PropTypes from 'prop-types'
import React from 'react'

import {ASPECTS, ORDERED_ASPECTS} from 'app/constants'
import * as strings from 'app/strings'

import Cell from 'email-server-common/common/Cell'
import Table from 'email-server-common/common/Table'
import {paddingAmount} from 'email-server/style/constants'
import {backgroundColorLight, textColorLight} from 'email-server/style/palette'
import {aspectIconUrl, factorIconUrl} from 'email-server/utils'

const StorySummaryBlock = ({story}) => {
  const article = story.topArticle

  const isAspectTrue = aspect =>
    ({
      [ASPECTS.LITIGATION]: story.isLitigation,
      [ASPECTS.OPINION]: story.isOpinion,
      [ASPECTS.RUMOR]: story.isRumor,
    }[aspect])
  const aspects = ORDERED_ASPECTS.filter(isAspectTrue)

  return (
    <Table width="auto">
      <Cell
        height={20}
        style={{
          backgroundColor: backgroundColorLight,
          borderRadius: 10,
          paddingRight: paddingAmount,
        }}
      >
        <Table>
          <Cell width={26}>
            <img
              src={factorIconUrl({
                factor: article.factor,
                valence: article.valence,
              })}
              height={21}
              width={21}
              style={
                // This is to fix extra vertical spacing (doesn't work
                // in Outlook of course).
                {display: 'block'}
              }
            />
          </Cell>

          {aspects.map(aspect => (
            <Cell width={26} valign="middle" key={aspect}>
              <img
                src={aspectIconUrl(aspect)}
                height={16}
                width={16}
                style={{display: 'block'}}
              />
            </Cell>
          ))}

          <Cell valign="middle" align="left" style={{color: textColorLight}}>
            {strings.factorDisplayName(article.factor)}
            {aspects.map(aspect => (
              <React.Fragment key={aspect}>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                {strings.aspectDisplayName(aspect)}
              </React.Fragment>
            ))}
          </Cell>
        </Table>
      </Cell>
    </Table>
  )
}
StorySummaryBlock.propTypes = {
  story: PropTypes.object.isRequired,
}

export default StorySummaryBlock
