import {map, pipe, unnest, whereEq} from 'ramda'

import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import {Story} from 'app/models'
import {getCurrentRoute} from 'app/router/router-selectors'
import routes from 'app/routes'

import {SIM_CIRC_STATE_PROP_NAME} from '../company-overview-constants'
import {getCompanyOverview} from '../company-overview-selectors'

export const getSimCirc = state =>
  getCompanyOverview(state)[SIM_CIRC_STATE_PROP_NAME]

export const isCurrentStatePage = state =>
  getCurrentRoute(state) === routes.companySimCircCurrent
export const getIsLoading = state => getSimCirc(state).isLoading

export const getTargetDate = state => getSimCirc(state).targetDate
export const getMatches = state => getSimCirc(state).matches
export const getMatchById = (state, id) => getMatches(state).find(whereEq({id}))
export const getComparedMatchId = state => getSimCirc(state).comparedMatchId
export const getComparedMatch = state => {
  const compareId = getComparedMatchId(state)
  return getMatches(state).find(match => match.id === compareId)
}
export const getComparedCompany = state => getComparedMatch(state).company
export const getComparedMatchSubfactors = state =>
  getComparedMatch(state).matchingSubfactors
export const getSelectedSubfactor = state => getSimCirc(state).selectedSubfactor

export const getAreStoriesLoading = state => getSimCirc(state).areStoriesLoading
export const getTargetStoryIds = state => getSimCirc(state).targetStoryIds
export const getTargetStories = state => {
  const ids = getTargetStoryIds(state)
  const entities = getEntities(state)
  const orm = Orm.withEntities(entities)
  return orm.getByIds(Story, ids)
}
export const getMatchStoryIds = state => getSimCirc(state).matchStoryIds
export const getMatchStories = state => {
  const ids = getMatchStoryIds(state)
  const entities = getEntities(state)
  const orm = Orm.withEntities(entities)
  return orm.getByIds(Story, ids)
}

// Forecast

export const isForecastPage = state =>
  getCurrentRoute(state) === routes.companySimCircForecast
export const getPredictions = state => getSimCirc(state).predictions
export const getSelectedPredictionFactor = state =>
  getSimCirc(state).selectedPredictionFactor
/**
 * Same as `getSelectedPredictionFactor` but returns null instead of 'overall'.
 */
export const getSelectedPredictionStoriesFactor = state => {
  const factor = getSelectedPredictionFactor(state)
  if (factor === 'overall') {
    return null
  }
  return factor
}
export const getLeadingHealthScores = state =>
  getSimCirc(state).leadingHealthScoresByFactor
export const getIsForecastTimelineLoading = state =>
  getSimCirc(state).isForecastTimelineLoading
export const getForecastTimeline = state => getSimCirc(state).forecastTimeline
export const getForecastTimelineStories = state => {
  const storyIds = pipe(
    map(point => point.topStoryIds),
    unnest,
  )(getForecastTimeline(state))
  const orm = Orm.withEntities(getEntities(state))
  return orm.getByIds(Story, storyIds)
}
export const getForecastMatchId = state => getSimCirc(state).forecastMatchId
export const getForecastStoriesModalDaysSinceMatch = state =>
  getSimCirc(state).forecastStoriesModalDaysSinceMatch
export const isForecastStoriesModalOpen = state =>
  getForecastStoriesModalDaysSinceMatch(state) !== null
export const isForecastStoriesModalLoading = state =>
  getSimCirc(state).isForecastStoriesModalLoading
export const getForecastModalStoryIds = state =>
  getSimCirc(state).forecastModalStoryIds
export const getForecastModalStories = state => {
  const ids = getForecastModalStoryIds(state)
  const entities = getEntities(state)
  const orm = Orm.withEntities(entities)
  return orm.getByIds(Story, ids)
}
