import {replace} from 'redux-little-router'
import {eventChannel} from 'redux-saga'
import {put, select, spawn, take} from 'redux-saga/effects'

import {apiCall, graphqlQuery, restApiCall} from 'app/api'
import * as entityActions from 'app/framework/entities-actions'
import * as login from 'app/login'
import {goToLoginPage} from 'app/login/login-saga'
import urls from 'app/urls'

export function* apiRequest(endpoint, options = {}) {
  const token = yield select(state => state.login.token)
  options = {...options, token}
  return apiCall(endpoint, options)
}

export function* restApiRequest(ResourceClass, options = {}) {
  const token = yield select(state => state.login.token)
  options = {...options, token}
  return restApiCall(ResourceClass, options)
}

export function* graphqlRequest(query, {operationName, variables} = {}) {
  const token = yield select(state => state.login.token)
  return graphqlQuery(query, {token, operationName, variables})
}

/**
 * Returns a Promise that waits for a response for the given request, then
 * executes `handler` with the error raised (if applicable) an the response.
 * Useful for "chaining" operations onto a request.
 */
export function* withResponseHandler(request, handler) {
  function* handlerSaga(request) {
    let response = null
    let error = null
    try {
      response = yield request
    } catch (e) {
      error = e
    }
    yield* handler(error, response)
    if (error) {
      throw error
    }
    return response
  }

  const task = yield spawn(handlerSaga, request)
  return task.toPromise()
}

function* handleApiErrors(error, response) {
  if (error && error.status) {
    // 401 means the token has expired.
    if (error.status === 401) {
      yield* goToLoginPage()
    } else if (error.status === 403 && process.env.NODE_ENV === 'production') {
      yield put(replace(urls.noAccess()))
    }
  }
}

function* handleApiResponseEntities(error, response) {
  if (error) return
  yield put(entityActions.update(response.entities))
}

export function* genericApiRequest(ResourceClass, options = {}) {
  const request = yield* restApiRequest(ResourceClass, options)
  return yield* withResponseHandler(request, handleApiErrors)
}

export function* genericGraphqlRequest(query) {
  const request = yield* graphqlRequest(query)
  return yield* withResponseHandler(request, handleApiErrors)
}

export function* entitiesApiRequest(ResourceClass, options = {}) {
  const request = yield* genericApiRequest(ResourceClass, options)
  return yield* withResponseHandler(request, handleApiResponseEntities)
}

/**
 * Returns a redux-saga channel that listens for keypress events on the document
 * for any key codes in `keyCodes` and emits them.
 */
export function keyPressChannel(
  keyCodes,
  {repeats = false, globalOnly = true} = {},
) {
  return eventChannel(emit => {
    const listener = event => {
      if (
        // See: https://developer.mozilla.org/en-US/docs/Web/API/Document/keydown_event
        event.isComposing ||
        event.keyCode === 229 ||
        (!repeats && event.repeat) ||
        (globalOnly && event.target !== document.body) ||
        !keyCodes.includes(event.keyCode)
      ) {
        return
      }
      emit(event)
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  })
}

/**
 * If the user profile hasn't been loaded yet, waits for that; otherwise,
 * returns immediately.
 */
export function* waitForProfileData() {
  const isProfileLoaded = yield select(login.selectors.getIsLoaded)
  if (!isProfileLoaded) {
    yield take(login.actions.setProfileData)
  }
}
