import Table from 'email-server-common/common/Table'
import Row from 'email-server-common/common/Row'
import Cell from 'email-server-common/common/Cell'
import Link from 'email-server-common/common/Link'
import urls, { toAbsoluteUrl, USRC_VALUES, withSendGridTracking } from '../../app/urls'
import React from 'react'
import { getExcerpt } from '../../app/utils/article'
import dateFns from 'date-fns'
import { ASPECTS, ORDERED_ASPECTS, USER_DATE_FORMAT } from '../../app/constants'
import EsgScoreStrip from './EsgScoreStrip'
import ArticleChip from './ArticleChip'
import { aspectIconUrl } from '../../email-server/utils'
import { aspectDisplayName } from '../../app/strings'
import { colors } from './constants'
import EsgPillarScores from "./EsgPillarScores";

export default function EntityCard({
  entity,
  userId,
  rootUrl,
  insightsRootUrl,
  entityType,
  emailType
}) {
  if (!['company', 'industry'].includes(entityType)) {
    throw Error('Invalid entityType in EntityCard.')
  }

  return (
    <Table key={entityType}>
      <Row>
        <Cell
          style={{
            color: colors.defaultFontColor,
            fontSize: 24,
            fontWeight: 900
          }}
        >
          {entity.displayName}
        </Cell>

        <Cell
          style={{
            textAlign: 'right'
          }}
        >
          {entityType === 'company' &&
            <Link
              href={withSendGridTracking(
                toAbsoluteUrl(urls.pyapp.esgCompany({
                  companyId: entity.userSavedsearchId,
                  source: USRC_VALUES.ESG_EMAIL
                }), {
                  root: rootUrl
                })
              )}
              target='_blank'
              style={{
                color: colors.linkColor,
                fontSize: 12,
                fontWeight: 600
              }}
            >
              Company Overview
            </Link>}
        </Cell>
        <Cell></Cell>
      </Row>
      <Row>
        <Cell height='8' /><Cell /><Cell />
      </Row>
      <Row>
        <Cell
          style={{
            color: colors.defaultFontColor,
            fontSize: 12
          }}
          colSpan='2'
        >
          <EsgScoreStrip entity={entity} emailType={emailType} />
        </Cell>
        <Cell />
        <Cell />
      </Row>
      <Row>
        <Cell height='20'/><Cell/>
      </Row>
      { emailType === 'weekly' &&
        <Row>
          <Cell colSpan='2'>
            <EsgPillarScores entity={entity}/>
          </Cell>
          <Cell />
          <Cell />
        </Row>
      }
      <Row>
        <Cell
          style={{
            letterSpacing: 1,
            textTransform: 'uppercase',
            fontSize: 12,
            paddingTop: 22,
            paddingBottom: 12,
            borderBottom: `1px solid ${colors.borderColor}`
          }}
          colSpan='2'
        >
          top esg stories @ {dateFns.format(new Date(), USER_DATE_FORMAT)}
        </Cell>
        <Cell />
        <Cell width='32' />
      </Row>
      <Row>
        <Cell height='20' colSpan='3' />
      </Row>
      <Row>
        {entity.topStories.length > 0 ?
          <Cell
            colSpan='2'
          >
            {StorySection({
              stories: entity.topStories,
              userId,
              entitySavedSearchId: entity.savedsearchId,
              entityType,
              rootUrl,
              insightsRootUrl
            })}
          </Cell>
          :
          <Cell colSpan='3' style={{ textAlign: 'center', paddingTop: 0 }}>
            No New ESG Stories for {entity.displayName}
          </Cell>}
      </Row>
    </Table>
  )
}

const StorySection = ({ stories, userId, rootUrl, insightsRootUrl }) => {
  const valenceLabels = {
    'POS': 'Positive',
    'NEU': 'Neutral',
    'NEG': 'Negative'
  }

  return stories.map((story, index) => {
    const isAspectTrue = aspect =>
    ({
      [ASPECTS.LITIGATION]: story.litigation,
      [ASPECTS.OPINION]: story.opinion,
      [ASPECTS.RUMOR]: story.rumor
    }[aspect])
    const aspects = ORDERED_ASPECTS.filter(isAspectTrue)
    return (
      <Table
        key={story.id}
      >
        <Row>
           <Cell height='24' style={{
              paddingBottom: 16
            }}>
            {story.topicTreePath}
           </Cell>
        </Row>
        <Row>
          <Cell
            colSpan='2'
            style={{
              paddingBottom: 16
            }}>
            <Table width='auto'>
              <Row height='24'>
                <Cell height='24'>
                  <ArticleChip label={valenceLabels[story.valence]} valence={story.valence} />
                  <Cell width={'4'} />
                  {aspects.map((aspect, index) => (
                    <React.Fragment key={index}>
                      <ArticleChip label={aspectDisplayName(aspect)} iconFileName={aspectIconUrl(aspect)} />
                      <Cell width={'4'} />
                    </React.Fragment>
                  ))}
                </Cell>
              </Row>
            </Table>
          </Cell>
        </Row>
        <Row>
          <Cell
            style={{
              color: colors.secondaryFontColor,
              fontSize: 12
            }}
          >
            {dateFns.format(story.storyDate, USER_DATE_FORMAT)}
            {/* entityType === 'company' && ` @ THIS DOESN'T EXISTS IN ESG`  TODO: Get the news outlet name */}
          </Cell>
        </Row>
        <Row>
          <Cell
            style={{
              paddingTop: 7
            }}
          >
            <Link
              href={withSendGridTracking(
                urls.pyapp.article({
                  contentDirectorId: story.directorId,
                  userId: userId,
                  source: USRC_VALUES.ESG_EMAIL,
                  root: rootUrl,
                  savedsearchId: story.savedsearchId
                })
              )}
              style={{
                fontSize: 18,
                fontWeight: 800
              }}
              target='_blank'
            >
              {story.headline}
            </Link>
          </Cell>
        </Row>
        <Row>
          <Cell
            style={{
              paddingTop: 8,
              fontSize: 14,
              fontWeight: 500,
              wordBreak: "break-all"
            }}
          >
            {getExcerpt(story.description)}
          </Cell>
        </Row>
        <Row>
          <Cell
            style={{
              paddingTop: 16,
              fontSize: 14,
              fontWeight: 600
            }}
          >
            <Link
              href={withSendGridTracking(
                toAbsoluteUrl(
                  urls.interimPage({
                    contentDirectorId: story.directorId,
                    savedSearchId: story.savedsearchId
                  }),
                  { root: insightsRootUrl }
                )
              )}
              target='_blank'
            >View Article's Context</Link>
          </Cell>
          <Cell />
        </Row>
        {index !== (stories.length - 1) && (
          <Row>
            <Cell
              height='24'
              style={{
                borderBottom: index !== (stories.length - 1) ? `1px solid ${colors.borderColor}` : 'none'
              }}
            ></Cell>
          </Row>
        )}
        {index !== (stories.length - 1) && (
          <Row>
            <Cell height='24'></Cell>
            <Cell />
          </Row>
        )}

      </Table>
    )
  })
}
