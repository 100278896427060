import {createAction} from 'redux-actions'

const action = name => createAction(`companies-page/${name}`)

export const setCompanyIds = action('SET_COMPANY_IDS')
export const setTotalCompanyCount = action('SET_TOTAL_COMPANY_COUNT')
export const setSort = action('SET_SORT')
export const setNameFilter = action('SET_NAME_FILTER')
export const setLetterFilter = action('SET_LETTER_FILTER')
export const setGeography = action('SET_GEOGRAPHY')
export const setMyCompaniesFilter = action('SET_MY_COMPANIES_FILTER')
export const setPage = action('SET_PAGE')
export const setIsLoading = action('SET_IS_LOADING')
