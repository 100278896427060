import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import {filter, map, pipe, prop, reverse, sortBy, values, unnest} from 'ramda'
import React from 'react'

import FactorIcon from 'app/common/FactorIcon'
import {FACTORS} from 'app/constants'
import * as strings from 'app/strings'
import {localComponent} from 'app/utils/redux'

import HealthBadge from '../'
import FactorHealthBadgeTooltip from './tooltip'
import * as actions from './factor-health-badge-actions'
import * as constants from './factor-health-badge-constants'
import * as selectors from './factor-health-badge-selectors'

import * as styles from './FactorHealthBadge.less'

class FactorHealthBadge extends React.PureComponent {
  render() {
    const {
      healthData,
      highlightedFactor,

      tooltipInfo,

      className,
      style,
    } = this.props

    const segments = pipe(
      map(isPositive =>
        pipe(
          values,
          map(factor => ({
            id: factor,
            label: <FactorIcon factor={factor} className={styles.icon} />,
            value: healthData.factors[factor]
              ? isPositive
                ? healthData.factors[factor].positiveVolume
                : healthData.factors[factor].negativeVolume
              : 0,
            isPositive,
            isHighlighted: factor === highlightedFactor,
          })),
          filter(value => value.value > 0),
          sortBy(prop('value')),
          reverse,
        )(FACTORS),
      ),
      unnest,
    )([false, true])
    const valueTotal =
      Math.max(healthData.positiveVolume, healthData.negativeVolume) +
      healthData.neutralVolume / 2
    const healthScore = highlightedFactor
      ? healthData.factors[highlightedFactor]
        ? healthData.factors[highlightedFactor].healthScore
        : 0
      : healthData.healthScore

    const tooltip = tooltipInfo.isVisible && (
      <FactorHealthBadgeTooltip
        left={tooltipInfo.left}
        right={tooltipInfo.right}
        top={tooltipInfo.top}
        factor={tooltipInfo.factor}
        healthData={healthData}
      />
    )

    return (
      <React.Fragment>
        <HealthBadge
          segments={segments}
          valueTotal={valueTotal}
          healthScore={healthScore}
          healthLabel={
            highlightedFactor && strings.factorDisplayName(highlightedFactor)
          }
          onSectionMouseOver={bind(this.onFactorMouseOver, this)}
          onSectionMouseOut={bind(this.onFactorMouseOut, this)}
          onSectionClick={bind(this.onFactorClick, this)}
          className={className}
          style={style}
        />

        {tooltip}
      </React.Fragment>
    )
  }

  onFactorMouseOver(factor, event) {
    this.props.highlightFactor(factor)
    this.showTooltip({event, factor})
    if (this.props.onFactorMouseOver) {
      this.props.onFactorMouseOver(factor)
    }
  }

  onFactorMouseOut() {
    this.props.clearHighlightedFactor()
    this.props.hideTooltip()
    if (this.props.onFactorMouseOut) {
      this.props.onFactorMouseOut(factor)
    }
  }

  onFactorClick(factor) {
    this.props.hideTooltip()
    if (this.props.onFactorClick) {
      this.props.onFactorClick(factor)
    }
  }

  showTooltip({event, factor}) {
    const topOffset = 20
    const el = event.target
    const rect = el.getBoundingClientRect()
    const docElement = document.documentElement
    const top = Math.round(rect.top + docElement.scrollTop) - topOffset
    const left = Math.round(rect.right + docElement.scrollLeft) + 20
    const right = Math.round(rect.left + docElement.scrollLeft) - 20
    this.props.showTooltip({
      top,
      left,
      right,
      factor,
    })
  }
}
FactorHealthBadge.propTypes = {
  healthData: PropTypes.object.isRequired,
  highlightedFactor: PropTypes.string,
  onFactorMouseOver: PropTypes.func,
  onFactorMouseOut: PropTypes.func,
  onFactorClick: PropTypes.func,

  // From state
  tooltipInfo: PropTypes.object.isRequired,

  // Actions
  highlightFactor: PropTypes.func.isRequired,
  clearHighlightedFactor: PropTypes.func.isRequired,
  showTooltip: PropTypes.func.isRequired,
  hideTooltip: PropTypes.func.isRequired,

  // Element attributes
  className: PropTypes.string,
  style: PropTypes.object,
}

export default localComponent({
  key: constants.STATE_PROP_NAME,
  initAction: actions.init,
  deinitAction: actions.deinit,
  actions: {
    highlightFactor: actions.highlightFactor,
    clearHighlightedFactor: actions.clearHighlightedFactor,
    showTooltip: actions.showTooltip,
    hideTooltip: actions.hideTooltip,
  },
  stateSelector: selectors.getStateByKey,
  mapStateToProps: state => ({
    highlightedFactor: state.highlightedFactor,
    tooltipInfo: state.tooltip,
  }),
})(FactorHealthBadge)
