import React from 'react'
import { STATIC_FILES_URL } from '../../app/constants'

export default function EntityIcon({ entityType, ...props }) {
  let iconName
  if(entityType === 'company'){
    iconName = 'companies-icon.png'
  }else if(entityType === 'industry'){
    iconName = 'industries-icon.png'
  }else{
    iconName = 'pillars-icon.png'
  }
  return (
    <img src={`${STATIC_FILES_URL}/imgs/${iconName}`} alt='Entity Icon' {...props}/>
  )
}
