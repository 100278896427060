import classNames from 'classnames'
import React from 'react'

import * as mainStyles from './StorySummaryBlock.less'
import * as loadingStyles from './StorySummaryBlockLoading.less'

export default function StorySummaryBlockLoading() {
  return (
    <div className={mainStyles.storySummaryBlock}>
      <div className={mainStyles.main}>
        <div className={loadingStyles.insightSummary} />

        <h2
          className={classNames(mainStyles.headline, loadingStyles.headline)}
        />

        <div className={mainStyles.foot}>
          <span
            className={classNames(
              mainStyles.articleCount,
              loadingStyles.articleCount,
            )}
          />
          <span className={loadingStyles.separator} />
          <span className={loadingStyles.date} />
        </div>
      </div>
      <div className={mainStyles.shareSection}>
        <span className={loadingStyles.icon} />
      </div>
    </div>
  )
}
