import React, { useState } from 'react'
import classNames from 'classnames'

import * as styles from 'app/litigation/styles.less'
import * as layoutStyles from './LitigationLayout.less'
import Button from 'app/common/Button'
import NavigationLink from 'app/common/NavigationLink'
import urls from 'app/urls'
import { SaveLitigationSearchModal } from 'app/litigation/modals'
import ExportModal from 'app/litigation/modals/ExportModal'

export default function LitigationLayout({
  children,
  showButtons = true,
  outputFileName,
  csvData = [],
  chartRef = null
}) {
  const [saveModalVisible, setSaveModalVisible] = useState(false)
  const [exportModalVisible, setExportModalVisible] = useState(false)

  return (
    <div className={layoutStyles.pageContent}>
      <div className={layoutStyles.header}>
        <h1 className={classNames(styles.title, layoutStyles.betaTitle)}>Litigation News Monitoring</h1>
        {showButtons &&
          <>
            <SaveLitigationSearchModal
              visible={saveModalVisible}
              onClose={() => setSaveModalVisible(false)}
            />
            <ExportModal
              visible={exportModalVisible}
              onClose={() => setExportModalVisible(false)}
              outputFileName={outputFileName}
              csvData={csvData}
              chartRef={chartRef}
            />
            <div className={layoutStyles.buttonContainer}>
              <NavigationLink href={urls.litigationLoadView()}>
                <Button label={'Open Saved Filters Table'} isPlainText={true} className={classNames(layoutStyles.button, layoutStyles.open)}/>
              </NavigationLink>
              <Button
                label={'Export Results'}
                className={classNames(layoutStyles.button, layoutStyles.export)}
                onClick={() => setExportModalVisible(true)}
                isDisabled={chartRef === null || chartRef.current === null}
              />
              <Button
                label={'Save Filters'}
                className={layoutStyles.button}
                onClick={() => setSaveModalVisible(true)}
              />
            </div>
          </>}
      </div>
      <div className={layoutStyles.contentContainer}>
        {children}
      </div>
    </div>
  )
}
