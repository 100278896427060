import {createAction} from 'redux-actions'

const action = name => createAction(`companies-displays/${name}`)

export const init = action('INIT')
export const deinit = action('REMOVE')

export const setNameFilter = action('SET_NAME_FILTER')
export const setLetterFilter = action('SET_LETTER_FILTER')
export const setMyCompaniesFilter = action('SET_MY_COMPANIES_FILTER')
