import { STATE_PROP_NAME } from './litigations-constants'

export const getRoot = state => state[STATE_PROP_NAME]
export const getMainPage = state => getRoot(state).mainPage
export const getSectors = state => getMainPage(state).sectors
export const getSelectedSectors = state => getMainPage(state).selectedSectors
export const getCompanyCountMinimum = state => getMainPage(state).companyCountMinimum
export const getCompanyCountMaximum = state => getMainPage(state).companyCountMaximum
export const getLitigationAffectedCompaniesMinimum = state => getMainPage(state).litigationAffectedCompaniesMinimum
export const getLitigationAffectedCompaniesMaximum = state => getMainPage(state).litigationAffectedCompaniesMaximum
export const getMainPageLoading = state => getMainPage(state).isLoading
export const getSector = state => getRoot(state).sector
export const getCompanyTimeSeries = state => getSector(state).companyTimeSeries
export const getCompanyFilters = state => getSector(state).filters
export const getSectorId = state => getSector(state).id
export const getSectorLoading = state => getSector(state).isLoading
const _getStories = state => getSector(state).stories
export const getStoryCount = state => _getStories(state).count
export const getInfiniteScrollLoading = state => _getStories(state).infiniteScrollLoading
export const getStoryData = state => _getStories(state).data
export const getStoryOffset = state => _getStories(state).offset
export const getStoryFetchError = state => _getStories(state).error

const _getSave = state => getRoot(state).save
export const getSavedLitigationSearchCount = state => _getSave(state).count
export const getSavedLitigationSearchOffset = state => _getSave(state).offset
export const getSavedLitigationSearchLoading = state => _getSave(state).isLoading
export const getSavedLitigationSearchData = state => _getSave(state).data
export const getSavedLitigationSearchError = state => _getSave(state).error
export const getSavedLitigationSearchRemove = state => _getSave(state).remove
export const getCurrentLoadedLitigationSearch = state => _getSave(state).currentLoaded
export const getSavedLitigationSearchOrderBy = state => _getSave(state).orderBy
export const getAddLitigationSearchStatus = state => _getSave(state).add.status
export const getAddLitigationSearchError = state => _getSave(state).add.error

export const isDownloadingPdf = state => getRoot(state).isDownloadingPdf

export const getIsOnMainPage = state => getRoot(state).isOnMainPage
export const getLitigationState =  state => getRoot(state)
export const getSectorPage = state => getRoot(state).sector
export const getSelectedCompanyTimeSeries = state => getSector(state).selectedCompanyTimeSeries
export const getSelectedFilter = state => getMainPage(state).selectedFilter
