import PropTypes from 'prop-types'
import {prop} from 'ramda'
import React from 'react'

import {FEEDBACK_EMAIL} from 'app/constants'
import {joinInSentence} from 'app/utils'

import * as styles from './AddEntitiesModalSearch.less'

export default function AddEntitiesModalFailure({entities, entityType, error}) {
  const entitiesListed = joinInSentence(entities.map(prop('name')))
  const entityLabelPlural =
    entityType === 'company' ? 'Companies' : 'Industries'
  const entityLabelPluralLower = entityLabelPlural.toLowerCase()
  const reason =
    error.status === 403 && error.response.body.error === 'LimitReached' ? (
      `because you have reached the maximum number of ${entityLabelPluralLower} allowed for this account`
    ) : (
      <React.Fragment>
        due to a server error. Please try again in a few minutes. If the problem
        persists, let us know by emailing{' '}
        <a href={`mailto:${FEEDBACK_EMAIL}`}>{FEEDBACK_EMAIL}</a> with the list
        of {entityLabelPluralLower} you were trying to add
      </React.Fragment>
    )
  return (
    <div>
      <h1>Error!</h1>

      <p className={styles.description}>
        {entitiesListed} {entities.length > 1 ? 'were' : 'was'} not added to
        your "My {entityLabelPlural}" list successfully {reason}.
      </p>
    </div>
  )
}
AddEntitiesModalFailure.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.object).isRequired,
  entityType: PropTypes.string.isRequired,
  error: PropTypes.object,
}
