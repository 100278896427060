import {handleActions} from 'redux-actions'

import * as actions from './factor-details-modal-actions'

const getInitialState = () => ({
  isVisible: false,
  factor: undefined,
  subfactor: undefined,
  subfactorsHealthData: undefined,
  factorHealthData: undefined,
})

export default handleActions(
  {
    [actions.show]: (state, action) => ({
      ...state,
      isVisible: true,
      factor: action.payload.factor,
      subfactor: action.payload.subfactor,
      subfactorsHealthData: action.payload.subfactorsHealthData,
      factorHealthData: action.payload.factorHealthData,
    }),
    [actions.hide]: getInitialState,
  },
  getInitialState(),
)
