import PropTypes from 'prop-types'
import React from 'react'

import AspectIcon from 'app/common/AspectIcon'
import {ASPECTS, ORDERED_ASPECTS} from 'app/constants'

/**
 * Renders <AspectIcon>s as needed for each aspect.
 */
export default function AspectIcons({
  isLitigation,
  isOpinion,
  isRumor,
  className,
  iconClassName,
}) {
  if (!isLitigation && !isOpinion && !isRumor) return null
  const isAspectTrue = aspect =>
    ({
      [ASPECTS.LITIGATION]: isLitigation,
      [ASPECTS.OPINION]: isOpinion,
      [ASPECTS.RUMOR]: isRumor,
    }[aspect])
  const aspectIcons = ORDERED_ASPECTS.filter(isAspectTrue).map(aspect => (
    <AspectIcon aspect={aspect} className={iconClassName} key={aspect} />
  ))
  return (
    // `display: flex` mysteriously fixes an issue where this container is
    // larger than it needs to be.
    <span className={className} style={{display: 'flex'}}>
      {aspectIcons}
    </span>
  )
}
AspectIcons.propTypes = {
  isLitigation: PropTypes.bool.isRequired,
  isOpinion: PropTypes.bool.isRequired,
  isRumor: PropTypes.bool.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
}
