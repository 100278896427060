import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import InsightSummary from 'app/common/insight-summary'
import Link from 'app/common/NavigationLink'
import TextSeparator from 'app/common/TextSeparator'
import * as strings from 'app/strings'
import {defaultPrevented} from 'app/utils'

import ShareIcon from './ShareIcon'

import * as styles from './StorySummaryBlock.less'

const StorySummaryBlock = ({
  headline,
  valence,
  factor,
  subfactor,
  isLitigation = false,
  isOpinion = false,
  isRumor = false,
  articleCount,
  startDate,
  endDate,
  href,
  shouldShowShareButton = true,
  onShareClick,
  className,
}) => (
  <div className={classNames(styles.storySummaryBlock, className)}>
    <div className={styles.main}>
      <InsightSummary
        valence={valence}
        factor={factor}
        subfactor={subfactor}
        isLitigation={isLitigation}
        isOpinion={isOpinion}
        isRumor={isRumor}
      />

      <h2 className={styles.headline}>
        <Link href={href} className="story-summary">
          {headline}
        </Link>
      </h2>

      <div className={styles.foot}>
        <span className={styles.articleCount}>
          {strings.articleCount(articleCount)}
        </span>
        <TextSeparator />
        <span>
          {strings.formatDateRange(startDate, endDate || startDate)} UTC
        </span>
      </div>
    </div>
    {shouldShowShareButton && (
      <div className={styles.shareSection}>
        <span
          className={classNames(styles.icon, 'share-icon')}
          onClick={onShareClick && defaultPrevented(() => onShareClick())}
        >
          <ShareIcon />
        </span>
      </div>
    )}
  </div>
)
StorySummaryBlock.propTypes = {
  headline: PropTypes.string.isRequired,
  valence: PropTypes.string.isRequired,
  factor: PropTypes.string.isRequired,
  subfactor: PropTypes.string.isRequired,
  isLitigation: PropTypes.bool,
  isOpinion: PropTypes.bool,
  isRumor: PropTypes.bool,
  articleCount: PropTypes.number.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object,
  shouldShowShareButton: PropTypes.bool,
  onShareClick: PropTypes.func,
  className: PropTypes.string,
}
export default StorySummaryBlock
