import classNames from 'classnames'
import is from 'is'
import PropTypes from 'prop-types'
import {chain, range} from 'ramda'
import React, {useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ErrorBar,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import ChartTooltip from 'app/common/charting/ChartTooltip'
import Dropdown from 'app/common/Dropdown'
import HealthScore from 'app/common/HealthScore'
import InputBlock from 'app/common/InputBlock'
import LoadingSpinner from 'app/common/loading-spinner'
import StorySummaryBlock from 'app/common/story-summary-block'
import {CHART_COLORS, ORDERED_FACTORS, VALENCES} from 'app/constants'
import * as strings from 'app/strings'
import urls from 'app/urls'
import {signForValence} from 'app/utils/health'
import * as branding from 'branding'

import * as actions from '../company-overview-simcirc-actions'
import * as selectors from '../company-overview-simcirc-selectors'
import {matchMonthShortDisplayName} from '../company-overview-simcirc-strings'
import ForecastStoriesModal from './CompanySimCircForecastStoriesModal'

import styles from './CompanySimCircForecast.less'

/**
 * The opacity of lines that are not currently selected.
 */
const FADED_LINE_OPACITY = 0.15

const factorLabel = factor =>
  factor === 'overall' ? 'Overall' : strings.factorDisplayName(factor)
const colorForFactor = factor =>
  CHART_COLORS[ORDERED_FACTORS.indexOf(factor) + 1]

function TooltipStoryBlock({story, color}) {
  return (
    <div className={styles.story}>
      <div className={styles.color} style={{backgroundColor: color}} />
      <StorySummaryBlock
        factor={story.factor}
        subfactor={story.subfactor}
        articleCount={story.articleCount}
        startDate={story.date}
        headline={story.summary}
        valence={story.valence}
        href={urls.storylineView(story.storylineId)}
        shouldShowShareButton={false}
        className={styles.summary}
      />
    </div>
  )
}

export default function CompanySimCircForecast({company}) {
  const selectedFactor = useSelector(selectors.getSelectedPredictionFactor)
  const predictions = useSelector(selectors.getPredictions)
  const leadingScores = useSelector(selectors.getLeadingHealthScores)
  const isForecastTimelineLoading = useSelector(
    selectors.getIsForecastTimelineLoading,
  )
  const forecastTimeline = useSelector(selectors.getForecastTimeline)

  const matches = useSelector(selectors.getMatches)
  const selectedMatchId = useSelector(selectors.getForecastMatchId)
  const matchOptions = [
    {
      label: '--',
      value: null,
    },
    ...matches.map(match => ({
      label: `${match.company.name} (${matchMonthShortDisplayName(
        match.matchMonth,
      )})`,
      value: match.id,
    })),
  ]

  const isForecastStoriesModalOpen = useSelector(
    selectors.isForecastStoriesModalOpen,
  )
  const isForecastStoriesModalLoading = useSelector(
    selectors.isForecastStoriesModalLoading,
  )
  const storiesModalDaysSinceMatch = useSelector(
    selectors.getForecastStoriesModalDaysSinceMatch,
  )
  const modalStories = useSelector(selectors.getForecastModalStories)

  const dispatch = useDispatch()
  const setComparedMatchId = id => dispatch(actions.setComparedMatchId(id))
  const openForecastStoriesModal = daysSinceMatch =>
    dispatch(actions.openForecastStoriesModal(daysSinceMatch))
  const closeForecastStoriesModal = () =>
    dispatch(actions.closeForecastStoriesModal())

  /*
   * This is kind of a hack, but there doesn't appear to be a clear way to tell
   * Recharts to show the pointer cursor when hovering over the inside of the
   * chart itself, as opposed to the axes or labels or something like that. So
   * instead, we keep track of mouse movements ourselves, and let Recharts tell
   * us if we are currently hovering over something with a value.
   *
   * TODO: This is the same hack as on the History page. Consolidate?
   */
  const [shouldShowPointer, setShouldShowPointer] = useState(false)

  const chartData = [
    ...range(0, 3).map(index => {
      const monthsAhead = index - 2
      const point = {
        confidenceInterval: null,
        monthsAhead,
      }
      for (const leadingFactorScore of leadingScores) {
        const factorKey = leadingFactorScore.factor || 'overall'
        const score = leadingFactorScore.healthScores[index]
        point[`score:${factorKey}`] = score
        if (monthsAhead === 0) {
          // To connect the two lines
          point[`predictedScore:${factorKey}`] = score
        }
      }
      return point
    }),
    ...range(0, 3).map(index => {
      const point = {
        monthsAhead: index + 1,
      }
      for (const prediction of predictions) {
        const {
          predictedScore: score,
          confidenceIntervalMin,
          confidenceIntervalMax,
        } = prediction.predictionsByMonth[index]
        const factorKey = prediction.factor || 'overall'
        point[`predictedScore:${factorKey}`] = score
        point[`confidenceInterval:${factorKey}`] = [
          score - confidenceIntervalMin,
          confidenceIntervalMax - score,
        ]
      }
      return point
    }),
  ]

  const timelineChartData = useMemo(() => {
    return forecastTimeline.map(point => {
      const chartPoint = {
        daysSinceMatch: point.daysSinceMatch,
        topStoryIds: point.topStoryIds,
      }
      for (const bar of point.bars) {
        chartPoint[`${bar.factor}:${bar.valence}`] =
          bar.volume * signForValence(bar.valence)
      }
      return chartPoint
    })
  }, [forecastTimeline])
  const timelineStories = useSelector(selectors.getForecastTimelineStories)
  const timelineStoriesById = useMemo(() => {
    const storiesById = {}
    for (const story of timelineStories) {
      storiesById[story.id] = story
    }
    return storiesById
  }, [timelineStories])

  const factors = ['overall', ...ORDERED_FACTORS]
  const factorOptions = [
    {
      label: '--',
      value: null,
    },
    {
      label: 'Overall',
      value: 'overall',
    },
    ...ORDERED_FACTORS.map(factor => ({
      label: strings.factorDisplayName(factor),
      value: factor,
    })),
  ]
  const selectFactor = factor => dispatch(actions.setPredictionFactor(factor))

  // Calculate the domain.
  const {min: minHealthScore, max: maxHealthScore} = chartData.reduce(
    ({min, max}, point) => {
      for (const factor of factors) {
        const factorScores = []
        if (is.defined(point[`score:${factor}`])) {
          factorScores.push(point[`score:${factor}`])
        }
        if (is.defined(point[`predictedScore:${factor}`])) {
          // Account for the confidence interval, if present.
          const score = point[`predictedScore:${factor}`]
          let [minConfidence, maxConfidence] = [score, score]
          if (is.defined(point[`confidenceInterval:${factor}`])) {
            minConfidence = point[`confidenceInterval:${factor}`][0]
            maxConfidence = point[`confidenceInterval:${factor}`][1]
          }
          factorScores.push(score - minConfidence)
          factorScores.push(score + maxConfidence)
        }
        for (const score of factorScores) {
          if (min === null) {
            min = max = score
          } else {
            min = Math.min(min, score)
            max = Math.max(max, score)
          }
        }
      }
      return {min, max}
    },
    {min: null, max: null},
  )
  const domain = [
    // Add some buffer room so that the dots don't go to the very ends of the
    // chart; round to the next integer; and clamp at 10.
    Math.max(-10, Math.floor(minHealthScore - 0.25)),
    Math.min(10, Math.ceil(maxHealthScore + 0.25)),
  ]
  const ticks =
    domain[0] < 0 && domain[1] > 0 ? [domain[0], 0, domain[1]] : [...domain]

  const axisStyle = {fill: branding['text-color-light'], fontSize: 14}

  return (
    <React.Fragment>
      <h3>Projected outlook</h3>

      <p>
        The graph below shows the projected health score that we anticipate and
        the expected confidence interval, showing the possible range that we
        would expect to see for this company. While not quite a prediction, the
        forecast is based upon the historical sets of similar circumstances, as
        often history can be a helpful indicator of a company's future outlook.
        The stories below are the largest contributors to the forecast from any
        of the similar companies during their matching 3-month ranges.
      </p>

      <div className={styles.dropdowns}>
        <InputBlock label="Factor">
          <Dropdown
            options={factorOptions}
            value={selectedFactor}
            onChange={selectFactor}
            className={styles.dropdown}
          />
        </InputBlock>

        <InputBlock label="Matching company date">
          <Dropdown
            options={matchOptions}
            value={selectedMatchId}
            onChange={setComparedMatchId}
            className={styles.dropdown}
          />
        </InputBlock>
      </div>

      <ResponsiveContainer width="100%" height={320}>
        <LineChart data={chartData}>
          <XAxis
            dataKey="monthsAhead"
            tickFormatter={monthsAhead =>
              monthsAhead === 0
                ? 'Now'
                : `${monthsAhead > 0 ? '+' : '-'}${Math.abs(
                    monthsAhead,
                  )} month${Math.abs(monthsAhead) === 1 ? '' : 's'}`
            }
          />
          <YAxis
            domain={domain}
            ticks={ticks}
            tickFormatter={val => (val > 0 ? `+${val}` : val)}
          />
          {/* Zero line */}
          <ReferenceLine y={0} stroke="#999999" strokeWidth={1} />
          {/*
           * We actually need two lines for each factor--one for historical
           * scores and one for predicted scores--so that we can show the
           * predicted scores with a dotted line.
           */}
          {factors.map((factor, index) => (
            <Line
              dataKey={`score:${factor}`}
              stroke={CHART_COLORS[index]}
              strokeWidth={2}
              opacity={
                selectedFactor && factor !== selectedFactor
                  ? FADED_LINE_OPACITY
                  : 1
              }
              dot={{stroke: 'none', fill: CHART_COLORS[index], r: 4}}
              isAnimationActive={false}
              connectNulls={true}
              key={`score:${factor}`}
            />
          ))}
          {factors.map((factor, index) => (
            <Line
              dataKey={`predictedScore:${factor}`}
              stroke={CHART_COLORS[index]}
              strokeWidth={2}
              strokeDasharray="5"
              opacity={
                selectedFactor && factor !== selectedFactor
                  ? FADED_LINE_OPACITY
                  : 1
              }
              dot={{stroke: 'none', fill: CHART_COLORS[index], r: 4}}
              isAnimationActive={false}
              key={`predictedScore:${factor}`}
            >
              {selectedFactor && factor === selectedFactor && (
                <ErrorBar
                  dataKey={`confidenceInterval:${factor}`}
                  stroke={branding['line-color-medium']}
                />
              )}
            </Line>
          ))}
          <Tooltip
            content={info => {
              if (!info.active || !info.payload.length) return null
              const {monthsAhead} = info.payload[0].payload
              let points = info.payload
              // If we have both historical and predicted scores, it means we're
              //  on the current month, which means the values are the same and
              //  we can de-duplicate them.
              if (monthsAhead === 0) {
                points = points.filter(point =>
                  point.dataKey.startsWith('score:'),
                )
              }
              return (
                <ChartTooltip className={styles.tooltip}>
                  <div className={styles.header}>
                    {monthsAhead === 0
                      ? `Current Health Scores`
                      : monthsAhead < 0
                      ? `Health Scores ${Math.abs(monthsAhead)} Month${
                          monthsAhead === -1 ? '' : 's'
                        } Ago`
                      : `Predicted Health Scores in ${monthsAhead} Month${
                          monthsAhead === 1 ? '' : 's'
                        }`}
                  </div>
                  {points.map(point => {
                    const factor = point.dataKey.split(':')[1]
                    const isFaded = selectedFactor && factor !== selectedFactor
                    return (
                      <div
                        className={classNames(styles.lineItem, {
                          [styles.faded]: isFaded,
                        })}
                        key={point.dataKey}
                      >
                        <div
                          className={styles.lineColor}
                          style={{backgroundColor: point.color}}
                        />
                        <div>
                          {factorLabel(factor)}:{' '}
                          <HealthScore score={point.value} />
                        </div>
                      </div>
                    )
                  })}
                </ChartTooltip>
              )
            }}
          />
          <Legend
            verticalAlign="bottom"
            wrapperStyle={{bottom: 0}}
            content={({payload}) => (
              // TODO: Generalize the legend into its own component.
              <ul className={styles.legend}>
                {payload
                  .filter(({value}) => value.startsWith('score:'))
                  .map(({value, color}, index) => {
                    const factor = value.split(':')[1]
                    const isSelected =
                      selectedFactor && factor === selectedFactor
                    const isFaded = selectedFactor && factor !== selectedFactor
                    return (
                      <li
                        onClick={() =>
                          isSelected ? selectFactor(null) : selectFactor(factor)
                        }
                        className={classNames(styles.legendItem, {
                          [styles.faded]: isFaded,
                        })}
                        key={`item-${index}`}
                      >
                        <span
                          style={{backgroundColor: color}}
                          className={styles.dot}
                        />
                        <span>{factorLabel(factor)}</span>
                      </li>
                    )
                  })}
              </ul>
            )}
          />
        </LineChart>
      </ResponsiveContainer>

      <div className={styles.timelineChartContainer}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={timelineChartData}
            stackOffset="sign"
            margin={{top: 5, right: 5, bottom: 0, left: 0}}
            onClick={({activeLabel: daysSinceMatch}) =>
              openForecastStoriesModal(daysSinceMatch)
            }
            onMouseMove={({activeLabel}) => {
              if (is.number(activeLabel)) {
                setShouldShowPointer(true)
              } else {
                setShouldShowPointer(false)
              }
            }}
            cursor={shouldShowPointer ? 'pointer' : undefined}
          >
            <XAxis dataKey="daysSinceMatch" style={axisStyle} />
            <YAxis
              tickFormatter={val => Math.abs(val)}
              label={{
                value: 'Article Volume by Valence',
                angle: -90,
                position: 'insideLeft',
                style: {...axisStyle, textAnchor: 'middle'},
              }}
              style={axisStyle}
            />
            {!isForecastTimelineLoading && !!timelineChartData.length && (
              <Tooltip
                active={true}
                filterNull={false}
                content={info => {
                  if (!info.active) {
                    return null
                  }
                  const daysSinceMatch = info.label
                  const daysSinceMatchLabel =
                    daysSinceMatch === 0
                      ? 'on Day of Match'
                      : `${Math.abs(daysSinceMatch)} Days ${
                          daysSinceMatch > 0 ? 'After' : 'Before'
                        } Match`
                  let storyIds = []
                  if (info.payload.length) {
                    storyIds = info.payload[0].payload.topStoryIds
                  }
                  const stories = storyIds.map(id => timelineStoriesById[id])
                  return (
                    <ChartTooltip
                      header={
                        <div className={styles.header}>
                          <h4>Top 3 Stories {daysSinceMatchLabel}</h4>
                          <h6>Click to show more stories</h6>
                        </div>
                      }
                      className={styles.timelineTooltip}
                      contentClassName={styles.content}
                    >
                      {stories.map(story => (
                        <TooltipStoryBlock
                          story={story}
                          color={colorForFactor(story.factor)}
                          key={story.id}
                        />
                      ))}
                      {!stories.length && (
                        <div>There are no stories to show on this day.</div>
                      )}
                    </ChartTooltip>
                  )
                }}
                cursor={{fill: branding['background-color-light']}}
              />
            )}
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            {/* Zero line */}
            <ReferenceLine
              y={0}
              stroke="#999999"
              strokeWidth={1}
              strokeDasharray="none"
            />
            {chain(
              // We need two elements per factor--one per valence.
              factor => [
                `${factor}:${VALENCES.POSITIVE}`,
                `${factor}:${VALENCES.NEGATIVE}`,
              ],
              ORDERED_FACTORS,
            ).map(key => (
              <Bar
                dataKey={key}
                fill={colorForFactor(key.split(':')[0])}
                stackId="stack"
                isAnimationActive={false}
                className="test"
                key={key}
              />
            ))}
            <Legend
              verticalAlign="bottom"
              wrapperStyle={{bottom: 0}}
              content={() => (
                // TODO: Generalize the legend into its own component.
                <ul className={styles.legend}>
                  {ORDERED_FACTORS.map(factor => {
                    const isSelected =
                      selectedFactor && factor === selectedFactor
                    const isFaded = selectedFactor && factor !== selectedFactor
                    return (
                      <li
                        onClick={() =>
                          isSelected ? selectFactor(null) : selectFactor(factor)
                        }
                        className={classNames(styles.legendItem, {
                          [styles.faded]: isFaded,
                        })}
                        key={factor}
                      >
                        <span
                          style={{backgroundColor: colorForFactor(factor)}}
                          className={styles.dot}
                        />
                        <span>{factorLabel(factor)}</span>
                      </li>
                    )
                  })}
                </ul>
              )}
            />
          </BarChart>
        </ResponsiveContainer>

        {isForecastTimelineLoading && (
          <div className={styles.loading}>
            <LoadingSpinner className={styles.spinner} />
          </div>
        )}
      </div>

      {isForecastStoriesModalOpen && (
        <ForecastStoriesModal
          isLoading={isForecastStoriesModalLoading}
          daysSinceMatch={storiesModalDaysSinceMatch}
          stories={modalStories}
          close={closeForecastStoriesModal}
        />
      )}
    </React.Fragment>
  )
}
CompanySimCircForecast.propTypes = {
  company: PropTypes.object.isRequired,
}
