import classNames from 'classnames'
import dateFns from 'date-fns'
import is from 'is'
import PropTypes from 'prop-types'
import {prop, range} from 'ramda'
import React, {useMemo} from 'react'
import {
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import ChartTooltip from 'app/common/charting/ChartTooltip'
import HealthScore from 'app/common/HealthScore'
import {CHART_COLORS, today} from 'app/constants'
import {XAxisDateTick} from 'app/reusable/charting'
import * as strings from 'app/strings'
import {coalesceUndefined} from 'app/utils'
import {significantTickLabelDates} from 'app/utils/charting'
import * as branding from 'branding'

import * as styles from './HealthScoreTrend.less'

function TooltipLineItem({label, score, volume, color}) {
  let count
  if (volume) {
    count = strings.formatNumber(volume)
  } else {
    count = 'no'
  }
  const volumeText = `${count} article${volume === 1 ? '' : 's'}`
  return (
    <div className={styles.lineItem}>
      <div className={styles.lineColor} style={{backgroundColor: color}} />
      <div className={styles.label}>
        {`${label}: `}
        <HealthScore score={coalesceUndefined(score, null)} />{' '}
        <span className={styles.volume}>({volumeText})</span>
      </div>
    </div>
  )
}
TooltipLineItem.propTypes = {
  label: PropTypes.string.isRequired,
  score: PropTypes.number,
  volume: PropTypes.number,
  color: PropTypes.string.isRequired,
}

// TODO: Combine this with the one in CompanyHistory.jsx? They are very similar.
function HealthTrendTooltip({dataPoint, date, factor, subfactor}) {
  return (
    <ChartTooltip className={styles.tooltip}>
      <div className={styles.header}>{strings.formatDate(date)}</div>

      <TooltipLineItem
        label="Overall"
        score={dataPoint.overall}
        volume={dataPoint['overall:volume']}
        color={CHART_COLORS[0]}
      />
      {factor && (
        <TooltipLineItem
          label={strings.factorDisplayName(factor)}
          score={dataPoint[`factor-${factor}`]}
          volume={dataPoint[`factor-${factor}:volume`]}
          color={CHART_COLORS[1]}
        />
      )}
      {subfactor && (
        <TooltipLineItem
          label={strings.subfactorDisplayName(subfactor)}
          score={dataPoint[`subfactor-${subfactor}`]}
          volume={dataPoint[`subfactor-${subfactor}:volume`]}
          color={CHART_COLORS[2]}
        />
      )}
    </ChartTooltip>
  )
}
HealthTrendTooltip.propTypes = {
  dataPoint: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  factor: PropTypes.string,
  subfactor: PropTypes.string,
}

const HealthScoreTrend = React.memo(function HealthScoreTrend({
  dailyHealthData,
  factorDailyHealthData,
  subfactorDailyHealthData,
  factor,
  subfactor,
  timeFrameDays,
  className,
}) {
  // Create date objects for the entire domain of the chart, both for
  // convenience as well as performance.
  const chartDates = useMemo(() => {
    const startDate = dateFns.subDays(today, timeFrameDays - 1)
    return range(0, timeFrameDays).map(index =>
      dateFns.addDays(startDate, index),
    )
  }, [timeFrameDays])
  const chartDateLabels = significantTickLabelDates(chartDates)

  // Convert the data into a Recharts-friendly array.
  const chartData = useMemo(() => {
    return chartDates.map((date, index) => {
      const matchesDate = point => dateFns.isSameDay(point.date, date)
      const findPointForDate = points => points.find(matchesDate) || {}
      const overall = findPointForDate(dailyHealthData)
      const dataForDate = {
        x: index,
        overall: overall.healthScore,
        'overall:volume': overall.todaysVolume,
      }
      for (const [factor, points] of Object.entries(factorDailyHealthData)) {
        const point = findPointForDate(points)
        dataForDate[`factor-${factor}`] = point.healthScore
        dataForDate[`factor-${factor}:volume`] = point.todaysVolume
      }
      for (const [subfactor, points] of Object.entries(
        subfactorDailyHealthData,
      )) {
        const point = findPointForDate(points)
        dataForDate[`subfactor-${subfactor}`] = point.healthScore
        dataForDate[`subfactor-${subfactor}:volume`] = point.todaysVolume
      }
      return dataForDate
    })
  }, [
    dailyHealthData,
    factorDailyHealthData,
    subfactorDailyHealthData,
    chartDates,
  ])

  // We want to "zoom in" on only the relevant health scores in the y-axis, so
  // let's calculate the domain of the chart based on the visible lines.
  let allHealthScores = dailyHealthData.map(prop('healthScore'))
  if (factor && factorDailyHealthData[factor]) {
    allHealthScores = [
      ...allHealthScores,
      ...factorDailyHealthData[factor].map(prop('healthScore')),
    ]
  }
  if (subfactor && subfactorDailyHealthData[subfactor]) {
    allHealthScores = [
      ...allHealthScores,
      ...subfactorDailyHealthData[subfactor].map(prop('healthScore')),
    ]
  }
  const minHealthScore = Math.min(...allHealthScores)
  const maxHealthScore = Math.max(...allHealthScores)
  const domain = [
    // Add some buffer room so that the dots don't go to the very ends of the
    // chart; round to the next integer; and clamp at 10.
    Math.max(-10, Math.floor(minHealthScore - 0.25)),
    Math.min(10, Math.ceil(maxHealthScore + 0.25)),
  ]
  let ticks = [...domain]
  if (domain[0] < 0 && domain[1] > 0) {
    // Make sure we add a tick for zero, if it's visible.
    ticks = [ticks[0], 0, ticks[1]]
  }

  const axisStyle = {fill: branding['text-color-light'], fontSize: 14}

  return (
    <div
      className={classNames(
        styles.healthScoreTrend,
        className,
        'health-score-trend',
      )}
    >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={chartData}
          margin={{top: 5, right: 5, bottom: 15, left: 0}}
        >
          <XAxis
            dataKey="x"
            interval={0}
            tick={<XAxisDateTick datesByXValue={chartDateLabels} />}
            style={axisStyle}
          />
          <YAxis
            domain={domain}
            ticks={ticks}
            tickFormatter={val => (val > 0 ? `+${val}` : val)}
            style={axisStyle}
          />
          <Legend
            verticalAlign="bottom"
            wrapperStyle={{bottom: 0}}
            content={({payload}) => (
              // TODO: Generalize the legend into its own component.
              <ul className={styles.legend}>
                {payload.map(({value, color}, index) => {
                  let label
                  if (value.startsWith('subfactor-')) {
                    label = strings.subfactorDisplayName(
                      value
                        .split('-')
                        .slice(1)
                        .join('-'),
                    )
                  } else if (value.startsWith('factor-')) {
                    label = strings.factorDisplayName(
                      value
                        .split('-')
                        .slice(1)
                        .join('-'),
                    )
                  } else if (value === 'overall') {
                    label = 'Overall'
                  } else {
                    // This should never happen.
                    throw new Error(`Invalid legend value: '${value}'.`)
                  }
                  label += ' Health Score'
                  return (
                    <li className={styles.legendItem} key={`item-${index}`}>
                      <span
                        style={{backgroundColor: color}}
                        className={styles.dot}
                      />
                      <span>{label}</span>
                    </li>
                  )
                })}
              </ul>
            )}
          />
          <Tooltip
            content={info => {
              const index = info.label
              if (!is.number(index)) return null
              const dataPoint = chartData.find(point => point.x === index)
              const date = chartDates[index]
              return (
                <HealthTrendTooltip
                  dataPoint={dataPoint}
                  date={date}
                  factor={factor}
                  subfactor={subfactor}
                />
              )
            }}
            filterNull={false}
          />
          {/* Zero line */}
          <ReferenceLine
            y={0}
            stroke="#999999"
            strokeWidth={1}
            strokeDasharray="3 3"
          />
          {/* Overall health line */}
          <Line
            dataKey="overall"
            stroke={CHART_COLORS[0]}
            dot={{stroke: 'none', fill: CHART_COLORS[0], r: 2.5}}
            activeDot={{fill: CHART_COLORS[0], r: 5}}
            strokeWidth={2}
            isAnimationActive={false}
          />
          {factor && (
            <Line
              dataKey={`factor-${factor}`}
              stroke={CHART_COLORS[1]}
              dot={{stroke: 'none', fill: CHART_COLORS[1], r: 2.5}}
              activeDot={{fill: CHART_COLORS[1], r: 5}}
              strokeWidth={2}
              isAnimationActive={false}
            />
          )}
          {subfactor && (
            <Line
              dataKey={`subfactor-${subfactor}`}
              stroke={CHART_COLORS[2]}
              dot={{stroke: 'none', fill: CHART_COLORS[2], r: 2.5}}
              activeDot={{fill: CHART_COLORS[2], r: 5}}
              strokeWidth={2}
              isAnimationActive={false}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
})
HealthScoreTrend.propTypes = {
  dailyHealthData: PropTypes.arrayOf(PropTypes.object).isRequired,
  factorDailyHealthData: PropTypes.object.isRequired,
  subfactorDailyHealthData: PropTypes.object.isRequired,
  factor: PropTypes.string,
  subfactor: PropTypes.string,
  timeFrameDays: PropTypes.number.isRequired,
  className: PropTypes.string,
}
export default HealthScoreTrend
