import Promise from 'bluebird'

import {pyappRequest} from 'app/api'
import {FACTOR_IDS_BY_FACTOR, SUBFACTOR_IDS_BY_SUBFACTOR} from 'app/constants'
import urls from 'app/urls'

export function fetchMyProfile(token) {
  if (MOCK_BASE_API) {
    const body = {
      user: {
        id: 0,
        displayName: 'Dev User',
        role: 'admin',
        firm: {
          id: 0,
          name: 'Test Firm',
          country: 'USA',
          isInsightsEnabled: true,
        },
        isStaff: true,
        isGroupManager: true,
        isFirmAdmin: true,
        hasPublishAccess: true,
        hasPublishv3Access: true,
        hasSimCircAccess: true,
        isInsightsEnabled: true,
        isMasquerading: false,
        filterDefaults: {
          companies: {
            byId: {},
            all: {
              factorId: null,
              subfactorId: null,
              timeFrame: 30,
              perspective: 0,
            },
          },
          industries: {
            byId: {},
            all: {
              factorId: null,
              subfactorId: null,
              timeFrame: 90,
              perspective: 0,
            },
          },
        },
      },
      flaggedArticleCount: 1,
    }
    return new Promise(resolve => resolve({body}))
  }
  return pyappRequest({
    url: urls.pyapp.api.profileInfo(),
    method: 'GET',
    token,
  })
}

export function updateMyProfile(token, data) {
  return pyappRequest({
    url: urls.pyapp.api.profileInfo(),
    method: 'POST',
    data,
    token,
  })
}

export function saveFilterDefaults(token, data) {
  if (MOCK_BASE_API) {
    return new Promise(resolve => resolve({body: {}}))
  }
  return pyappRequest({
    url: urls.pyapp.api.saveFilterDefaults(),
    method: 'POST',
    data: {
      entityType: data.entityType,
      entityId: data.entityId,
      factorId:
        data.factor && data.factor !== 'all'
          ? FACTOR_IDS_BY_FACTOR[data.factor]
          : null,
      subfactorId:
        data.subfactor && data.subfactor !== 'all'
          ? SUBFACTOR_IDS_BY_SUBFACTOR[data.subfactor]
          : null,
      timeFrame: data.timeFrameDays,
      perspective: data.geography,
    },
    token,
  })
}
