import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './styles.less'

export default function HealthBar({healthData, className, ...restProps}) {
  return (
    <div className={classNames(styles.healthBar, className)} {...restProps}>
      <Section healthData={healthData} isPositive={false} />
      <Section healthData={healthData} isPositive={true} />
    </div>
  )
}
HealthBar.propTypes = {
  healthData: PropTypes.object.isRequired,
  className: PropTypes.string,
}

function Section({healthData, isPositive}) {
  const baseline =
    Math.max(healthData.positiveVolume, healthData.negativeVolume) +
    healthData.neutralVolume / 2
  const volume = isPositive
    ? healthData.positiveVolume
    : healthData.negativeVolume

  return (
    <div
      className={classNames(
        styles.section,
        isPositive ? styles.positive : styles.negative,
      )}
    >
      <div
        className={styles.bar}
        style={{
          width: `${(volume / baseline) * 100}%`,
        }}
      />
    </div>
  )
}
Section.propTypes = {
  healthData: PropTypes.object.isRequired,
  isPositive: PropTypes.bool.isRequired,
}
