import {takeLatest, put, select, putResolve} from 'redux-saga/effects'
import {fetchArticles} from 'app/api/api-saga-helpers'
import * as actions from './flag-article-modal-actions'
import {getJwt} from 'app/login/login-selectors'
import {
  apiChangeArticleFlag,
  apiFetchFlagCategories,
  apiAddFlagCategory,
} from './flag-article-modal-api'
import * as selectors from './flag-article-modal-selectors'
import { updateflaggedArticleCount } from '../login/login-actions'
import { getFlaggedArticleCount } from '../login/login-selectors'

function* modalShown({payload: {articleId}}) {
  if (articleId) {
    yield put(actions.fetchFlagCategories())
    yield* fetchArticles({articleId})
  } else {
    throw new Error(
      'A `storyId` was not given in the payload for action `story-modal-actions/showModal`.',
    )
  }
}

function* handleApi(apiFunc, payload) {
  const token = yield select(getJwt)
  let body
  try {
    const response = yield apiFunc(token, payload)
    body = response.body
  } catch (error) {
    if (error.status === 401) {
      return
    }
    throw error
  }
  yield putResolve(actions.setFlagCategories(body))
  if (body.flaggingData.updatedCategoryId) {
    const isLoadingArray = yield select(selectors.getIsLoadingArray)
    const newIsLoadingArray = isLoadingArray.filter(
      cat => cat !== body.flaggingData.updatedCategoryId,
    )
    yield put(actions.setIsLoading(newIsLoadingArray))
  }
  yield put(actions.setIsAddingFlagCategory(false))
  yield put(actions.hideAddFlagCategoryForm())
}

function* handleFetchFlagCategories() {
  yield handleApi(apiFetchFlagCategories, null)
}

function* handleChangeArticleFlag(action) {
  const isAddFlag = action.payload.addFlag
  const flaggedArticleCount = yield select(getFlaggedArticleCount)
  if (isAddFlag) {
    yield put(updateflaggedArticleCount(flaggedArticleCount + 1))
  } else {
    yield put(updateflaggedArticleCount(flaggedArticleCount - 1))
  }
  yield handleApi(apiChangeArticleFlag, action.payload)
}

function* handleAddFlagCategory(action) {
  const flaggedArticleCount = yield select(getFlaggedArticleCount)
  yield put(updateflaggedArticleCount(flaggedArticleCount + 1))
  yield handleApi(apiAddFlagCategory, action.payload)
}

export default function* storylineModalSaga() {
  yield takeLatest(actions.showModal, modalShown)
  yield takeLatest(actions.fetchFlagCategories, handleFetchFlagCategories)
  yield takeLatest(actions.changeArticleFlag, handleChangeArticleFlag)
  yield takeLatest(actions.addFlagCategory, handleAddFlagCategory)
}
