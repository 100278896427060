import {pyappRequest} from 'app/api'
import * as jwt from 'app/jwt'
import urls from 'app/urls'

export function logPageVisit(url) {
  const token = jwt.getCurrentToken()
  if (!token) return
  pyappRequest({
    url: urls.pyapp.api.logInteractionUrl(),
    method: 'POST',
    token,
    data: {url},
  })
}
