import {createAction} from 'redux-actions'

const action = name => createAction(`companies-tables/${name}`)

export const init = action('INIT')
export const deinit = action('REMOVE')

export const setSort = action('SET_SORT')
export const showCompanyDetails = action('SHOW_COMPANY_DETAILS')
export const hideCompanyDetails = action('HIDE_COMPANY_DETAILS')
export const expandCompanyIndustries = action('EXPAND_COMPANY_INDUSTRIES')
export const collapseCompanyIndustries = action('CONTRACT_COMPANY_INDUSTRIES')
