import dateFns from 'date-fns'
import is from 'is'
import {
  append,
  fromPairs,
  groupBy,
  last,
  map,
  nth,
  path,
  pipe,
  prop,
  sortBy,
  unnest,
  values,
} from 'ramda'
import {LOCATION_CHANGED, push, replace} from 'redux-little-router'
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'

import {apiCall} from 'app/api'
import {
  fetchCompanies,
  fetchCompanyStorylines,
  fetchMyCompanies,
  fetchMyCompaniesIfNeeded,
  fetchStories,
} from 'app/api/api-saga-helpers'
import {
  FACTORS_BY_ID,
  IGNORED_SUBFACTORS,
  ORDERED_SUBFACTORS_BY_FACTOR,
  SORT_DIRECTIONS,
  SUBFACTOR_IDS_BY_SUBFACTOR,
  SUBFACTORS_BY_ID,
  today,
  VALENCES,
} from 'app/constants'
import * as entityActions from 'app/framework/entities-actions'
import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import {actions as globalActions} from 'app/global'
import {Company} from 'app/models'
import {getMyCompanyIds} from 'app/global/global-selectors'
import {args, gql} from 'app/graphql'
import * as login from 'app/login'
import {
  Company as CompanyResource,
  Story as StoryResource,
  Storyline as StorylineResource,
} from 'app/resources'
import {STORY_SORT_OPTIONS} from 'app/reusable/stories/story-reader/story-reader-constants'
import routes from 'app/routes'
import * as strings from 'app/strings'
import urls from 'app/urls'
import {nextQuarter, prevQuarter, quarterRange} from 'app/utils/quarters'
import {
  genericGraphqlRequest,
  keyPressChannel,
  waitForProfileData,
} from 'app/utils/sagas'

import * as actions from './company-overview-actions'
import {
  ALL_COMPANY_ROUTES,
  HISTORY_ROUTES,
  OVERVIEW_ROUTES,
  SIMCIRC_ROUTES,
  MAX_HISTORICAL_STORY_COUNT,
  QUARTER_QUERY_PARAM,
  STORY_READER_MAX_STORY_COUNT,
  SIM_CIRC_STORY_COUNT,
} from './company-overview-constants'
import * as selectors from './company-overview-selectors'
import {MIN_QUARTER} from './history/company-history-constants'
import * as simCircActions from './simcirc/company-overview-simcirc-actions'
import {
  fetchFactorMaxChangePredictions,
  fetchSimCircData,
  fetchSimCircTimeline,
  fetchSimCircTimelineStories,
} from './simcirc/company-overview-simcirc-api'
import {QUERY_PARAMS as SIM_CIRC_QUERY_PARAMS} from './simcirc/company-overview-simcirc-constants'
import * as simCircSelectors from './simcirc/company-overview-simcirc-selectors'
import {fetchLeadershipIndividuals} from "./company-overview-api"

const LEFT_ARROW_KEY = 37
const RIGHT_ARROW_KEY = 39

const getShouldLoadCompany = (state, action) => {
  const companyId = selectors.getCompanyId(state)
  const orm = Orm.withEntities(getEntities(state))
  const {previous} = state.router
  const {payload} = action

  // If any of these query params have changed, we have to reload the data.
  const volatileQueryParams = ['time-frame', 'geography']

  return (
    !orm.getById(Company, companyId) ||
    !(
      ALL_COMPANY_ROUTES.includes(previous.route) &&
      previous.route === payload.route &&
      previous.params.companyId === payload.params.companyId &&
      volatileQueryParams.every(
        param => previous.query[param] === payload.query[param],
      )
    )
  )
}

function* locationChangedOverview(action) {
  // Ensure the profile info has been loaded since the user may have customized
  // the default filters.
  yield* waitForProfileData()

  const companyId = yield select(selectors.getCompanyId)
  const timeFrameDays = yield select(selectors.getTimeFrameForCurrentCompany)
  const selectedGeography = yield select(
    selectors.getGeographyForCurrentCompany,
  )
  const shouldLoadCompany = yield select(getShouldLoadCompany, action)

  const storiesRequest = yield* fetchCompanyStories()

  if (shouldLoadCompany) {
    const myCompaniesRequest = yield* fetchMyCompaniesIfNeeded()
    let storylinesRequest = null
    let factorPredictionsRequest = null

    if (action.payload.route === routes.companyOverview) {
      storylinesRequest = yield* fetchCompanyStorylines({
        companyId,
        timeFrameDays,
        geography: selectedGeography,
        fields: [...StorylineResource.requiredFields, 'companyId', 'stories'],
      })
    }

    const hasSimCircAccess = yield select(login.selectors.getHasSimCircAccess)
    if (hasSimCircAccess) {
      factorPredictionsRequest = yield* fetchFactorMaxChangePredictions({
        companyId,
      })
    }

    const companyResponse = yield yield* fetchCompanies({
      companyId,
      timeFrameDays,
      geography: selectedGeography,
      fields: CompanyResource.allFields,
    })
    if (is.array.empty(companyResponse.result)) {
      yield put(globalActions.setNotFound())
    }

    yield myCompaniesRequest
    if (storylinesRequest) {
      const response = yield storylinesRequest
      yield put(actions.setStorylineIds(response.result))
    }
    if (factorPredictionsRequest) {
      const predictions = yield factorPredictionsRequest
      if (predictions) {
        const predictionsByFactor = pipe(
          map(factorPrediction => [
            factorPrediction.factor,
            factorPrediction.maxPredictedHealthScoreChange,
          ]),
          fromPairs,
        )(predictions)

        const hasConfidenceInPredictionsByFactor = pipe(
          map(factorPrediction => [
            factorPrediction.factor,
            factorPrediction.hasConfidenceLevelText,
          ]),
          fromPairs,
        )(predictions)

        yield put(
          actions.setPredictedHealthScoreChangesByFactor(predictionsByFactor),
        )
        yield put(
          actions.setHasConfidenceInPredictionsByFactor(hasConfidenceInPredictionsByFactor),
        )
      }
    }
  }

  yield storiesRequest

  yield put(actions.setIsLoading(false))
}

function* locationChangedHistory(action) {
  const companyId = yield select(selectors.getCompanyId)
  const geography = (yield select(selectors.getGeography)) || 0

  // Load stories in the background so that we can show the rest of the
  // page in the meantime.
  yield fork(loadHistoricalStorylines)

  const shouldLoadCompany = yield select(getShouldLoadCompany, action)

  if (shouldLoadCompany) {
    const myCompaniesRequest = yield* fetchMyCompaniesIfNeeded()
    const healthDataRequest = yield* fetchHistoricalData()

    const companyResponse = yield yield* fetchCompanies({
      companyId,
      geography,
      fields: [
        ...CompanyResource.requiredFields,
        'industries',
        'hasSimCircData',
      ],
    })
    if (is.array.empty(companyResponse.result)) {
      yield put(globalActions.setNotFound())
    }

    yield myCompaniesRequest

    const {body: healthData} = yield healthDataRequest
    if (healthData.length) {
      const sortedHealthData = healthData.map(prop('quarter')).sort()
      if (sortedHealthData) {
        // We need to make sure there are no missing quarters in the data, so
        // that the chart doesn't skip empty quarters and properly shows gaps.
        const firstQuarter = sortedHealthData[0]
        const lastQuarter = last(sortedHealthData)
        const allQuarters = quarterRange(firstQuarter, lastQuarter).map(
          quarter => quarter.toString(),
        )

        const dataByQuarter = groupBy(prop('quarter'), healthData)
        const finalData = allQuarters.map(quarter => {
          const points = dataByQuarter[quarter]
          return pipe(
            map(point => {
              // Generate a key based on whether this point is for a factor, a
              // subfactor, or overall health.
              let key
              if (!point.factorId) {
                key = 'overall'
              } else if (point.categoryId) {
                key = `subfactor-${SUBFACTORS_BY_ID[point.categoryId]}`
              } else {
                key = `factor-${FACTORS_BY_ID[point.factorId]}`
              }
              return [
                [key, point.healthScore],
                [`${key}:volume`, point.articleVolume],
              ]
            }),
            unnest,
            append(['quarter', quarter]),
            fromPairs,
          )(points || [])
        })
        yield put(actions.setHistoricalHealthData(finalData))
      } else {
        yield put(actions.setHistoricalHealthData([]))
      }
    }
    // If there is no overall historical health, then we don't need to proceed
    // further. The component will see a `null` value and show a "not enough
    // data" message to the user.
  }

  if (yield select(selectors.getIsLoading)) {
    yield put(actions.setIsLoading(false))
  }

  yield fork(handleArrowKeyNavigation)
}

function* handleGetLeadership(action) {
  const {companyId} = action.payload
  const leadershipResponse = yield yield* fetchLeadershipIndividuals({companyId})
  yield put(actions.setLeadership(leadershipResponse.body.data.leadership))
}

function* locationChangedSimCirc(action) {
  // The base URL just redirects to the default tab.
  if (action.payload.route === routes.companySimCirc) {
    yield put(
      replace(urls.companySimCircForecast(action.payload.params.companyId)),
    )
    return
  }

  // If the user doesn't have access to SimCirc, show the "not found" page.
  //  However, we need to make sure the profile data is loaded first before we
  //  check this.
  yield* waitForProfileData()
  const hasSimCircAccess = yield select(login.selectors.getHasSimCircAccess)
  if (!hasSimCircAccess) {
    yield put(globalActions.setNotFound())
    return
  }

  const myCompaniesRequest = yield* fetchMyCompaniesIfNeeded()

  const companyId = yield select(selectors.getCompanyId)
  const companyRequest = yield* fetchCompanies({
    companyId,
    timeFrameDays: 30,
    geography: 0,
    fields: [...CompanyResource.requiredFields, 'industries', 'hasSimCircData'],
  })

  if (yield select(simCircSelectors.isForecastPage)) {
    yield fork(loadSimCircTimeline)
  }

  const simCircResponse = yield yield* fetchSimCircData({companyId})
  const simCircData = simCircResponse.body.data
  const targetDate = dateFns.parse(simCircData.targetCompanyDate.targetDate)
  yield put(simCircActions.setTargetDate(targetDate))

  const matches = simCircData.targetCompanyDate.matches.map((match, index) => ({
    id: match.id,
    company: match.company,
    rank: index + 1,
    matchMonth: match.matchMonth,
    monthlySubfactorScores: match.monthlySubfactorScores.map(scores => ({
      ...scores,
      subfactor: SUBFACTORS_BY_ID[scores.subfactorId],
    })),
    // Pre-compute this for quicker and easier access.
    matchingSubfactors: match.monthlySubfactorScores.map(
      subfactorScores => SUBFACTORS_BY_ID[subfactorScores.subfactorId],
    ),
  }))
  yield put(simCircActions.setMatches(matches))

  // We have to put the companies into the entity store so that we can show the
  //  "my company" star next to each match.
  const companyEntities = pipe(
    map(match => match.company),
    groupBy(company => company.id),
    // There may be duplicate companies, but the data is going to be the same
    //  for each, so just get the first one.
    map(nth(0)),
  )(matches)
  yield put(entityActions.update({[Company.entityKey]: companyEntities}))

  const predictions = simCircData.targetCompanyDate.predictionDetails.map(
    prediction => ({
      factor: FACTORS_BY_ID[prediction.factorId] || null,
      predictionsByMonth: prediction.predictionsByMonth,
    }),
  )
  yield put(simCircActions.setPredictions(predictions))

  const leadingScores = simCircData.targetCompanyDate.leadingHealthScores.map(
    ({factorId, healthScores}) => ({
      factor: FACTORS_BY_ID[factorId] || null,
      healthScores: healthScores.map(score => score && parseFloat(score)),
    }),
  )
  yield put(simCircActions.setLeadingHealthScores(leadingScores))

  if (yield select(simCircSelectors.isCurrentStatePage)) {
    yield fork(loadSimCircStories)
  }

  const companyResponse = yield companyRequest
  if (is.array.empty(companyResponse.result)) {
    yield put(globalActions.setNotFound())
  }

  yield myCompaniesRequest

  yield put(simCircActions.setIsLoading(false))
  yield put(actions.setIsLoading(false))
}

function* loadSimCircTimeline() {
  yield put(simCircActions.setIsForecastTimelineLoading(true))

  const companyId = yield select(selectors.getCompanyId)
  const factor = yield select(simCircSelectors.getSelectedPredictionFactor)
  const matchId = yield select(simCircSelectors.getForecastMatchId)
  const response = yield yield* fetchSimCircTimeline({
    companyId,
    factor,
    matchId,
  })

  const nodes = response.body.data.targetCompanyDate.storyTimeline.nodes
  const forecastStories = pipe(
    map(point => point.topStories),
    unnest,
  )(nodes)
  yield put(
    entityActions.update(
      new StoryResource().normalizedEntities(forecastStories),
    ),
  )
  const forecastTimeline = nodes.map(point => ({
    daysSinceMatch: point.dayIndex,
    topStoryIds: point.topStories.map(story => story.id),
    bars: point.bars.map(bar => ({
      factor: FACTORS_BY_ID[bar.groupId],
      valence: bar.valence,
      volume: bar.volume,
    })),
  }))
  yield put(simCircActions.setForecastTimeline(forecastTimeline))

  yield put(simCircActions.setIsForecastTimelineLoading(false))
}

function* loadSimCircStories() {
  yield put(simCircActions.setAreStoriesLoading(true))
  const companyId = yield select(selectors.getCompanyId)
  const targetDate = yield select(simCircSelectors.getTargetDate)
  const compareMatchingCompany = yield select(simCircSelectors.getComparedMatch)
  const matchingSubfactors = yield select(
    simCircSelectors.getComparedMatchSubfactors,
  )
  const matchMonthDate = strings.dateFromMonthString(
    compareMatchingCompany.matchMonth,
  )
  const selectedSubfactor = yield select(simCircSelectors.getSelectedSubfactor)
  const subfactors = selectedSubfactor
    ? [selectedSubfactor]
    : matchingSubfactors
  const targetStoriesRequest = yield* fetchSimCircStories({
    companyId,
    targetDate: targetDate,
    subfactors,
  })
  const matchStoriesRequest = yield* fetchSimCircStories({
    companyId: compareMatchingCompany.company.id,
    targetDate: dateFns.addMonths(matchMonthDate, 1),
    subfactors,
  })

  const targetStoriesResponse = yield targetStoriesRequest
  yield put(simCircActions.setTargetStoryIds(targetStoriesResponse.result))
  const matchStoriesResponse = yield matchStoriesRequest
  yield put(simCircActions.setMatchStoryIds(matchStoriesResponse.result))
  yield put(simCircActions.setAreStoriesLoading(false))
}

function* setSelectedSubfactor(action) {
  yield put(
    replace(
      {query: {[SIM_CIRC_QUERY_PARAMS.SUBFACTOR]: action.payload || undefined}},
      {persistQuery: true},
    ),
  )
}

function* setComparedMatchId(action) {
  const query = {
    [SIM_CIRC_QUERY_PARAMS.COMPARED_MATCH]: action.payload || undefined,
  }

  // If a subfactor is selected, make sure that it's valid for the new match;
  // otherwise, reset it.
  const selectedSubfactor = yield select(simCircSelectors.getSelectedSubfactor)
  if (selectedSubfactor) {
    const comparedMatch = yield select(
      simCircSelectors.getMatchById,
      action.payload,
    )
    if (!comparedMatch.matchingSubfactors.includes(selectedSubfactor)) {
      query[SIM_CIRC_QUERY_PARAMS.SUBFACTOR] = undefined
    }
  }

  yield put(replace({query}, {persistQuery: true}))
}

function* setPredictionFactor(action) {
  yield put(
    replace(
      {
        query: {
          [SIM_CIRC_QUERY_PARAMS.PREDICTION_FACTOR]:
            action.payload || undefined,
        },
      },
      {persistQuery: true},
    ),
  )
}

function* fetchSimCircStories({companyId, targetDate, subfactors}) {
  const startDate = dateFns.subMonths(targetDate, 3)
  return yield* fetchStories({
    companyId,
    valences: [VALENCES.POSITIVE, VALENCES.NEGATIVE],
    startDate,
    endDate: targetDate,
    subfactors,
    ignoreSubfactors: IGNORED_SUBFACTORS,
    geography: 0,
    orderBy: {
      field: 'articleCount',
      direction: SORT_DIRECTIONS.DESC,
    },
    limit: SIM_CIRC_STORY_COUNT,
    fields: [
      ...StoryResource.requiredFields,
      'companyId',
      'topArticle',
      'storyline',
    ],
  })
}

function* fetchCompanyStories() {
  yield put(actions.setStoryIds({ids: null}))

  const state = yield select(selectors.getCompanyOverview)
  const {companyId, storyReader} = state
  const factor = yield select(selectors.getFactorForCurrentCompany)
  const subfactor = yield select(selectors.getSubfactorForCurrentCompany)
  const timeFrameDays = yield select(selectors.getTimeFrameForCurrentCompany)
  const geography = yield select(selectors.getGeographyForCurrentCompany)
  const {valences, sortOrder} = storyReader.filters

  const orderBy = [
    {
      field:
        sortOrder === STORY_SORT_OPTIONS.RECENCY ? 'storyDate' : 'articleCount',
      direction: SORT_DIRECTIONS.DESC,
    },
    {
      field:
        sortOrder === STORY_SORT_OPTIONS.RECENCY ? 'articleCount' : 'storyDate',
      direction: SORT_DIRECTIONS.DESC,
    },
  ]
  const startDate = dateFns.subDays(today, timeFrameDays)

  const request = yield* fetchStories({
    companyId,
    valences,
    startDate,
    isLargest: sortOrder === STORY_SORT_OPTIONS.VOLUME,
    isMostRecent: sortOrder === STORY_SORT_OPTIONS.RECENCY,
    factor,
    subfactor,
    ignoreSubfactors: IGNORED_SUBFACTORS,
    geography,
    orderBy,
    limit: STORY_READER_MAX_STORY_COUNT,
    fields: [
      ...StoryResource.requiredFields,
      'companyId',
      'topArticle',
      'storyline',
    ],
  })

  // Fork this so it happens in the background and we aren't blocked on it.
  yield fork(function*() {
    const response = yield request
    yield put(actions.setStoryIds({ids: response.result}))
    yield put(actions.getLeadership({companyId}))
  })

  return request
}

function* fetchHistoricalData() {
  const companyId = yield select(selectors.getCompanyId)
  const geography = (yield select(selectors.getGeography)) || 0
  const token = yield select(login.selectors.getJwt)
  return apiCall('historical_quarterly_health', {
    headers: {Authorization: `Bearer ${token}`},
    query: {
      companyId: `eq.${companyId}`,
      perspectiveId: `eq.${geography}`,
      quarter: `gte.${MIN_QUARTER}`,
    },
  })
}

export function* fetchHistoricalStorylines({
  companyId,
  quarter,
  geography = 0,
  factor,
  subfactor,
  ignoreSubfactors,
  searchText,
}) {
  const ignoreSubfactorIds = (ignoreSubfactors || []).map(
    subfactor => SUBFACTOR_IDS_BY_SUBFACTOR[subfactor],
  )
  let validCategoryIds
  if (subfactor) {
    validCategoryIds = [SUBFACTOR_IDS_BY_SUBFACTOR[subfactor]]
  } else if (factor) {
    const subfactors = ORDERED_SUBFACTORS_BY_FACTOR[factor]
    validCategoryIds = subfactors
      .map(subfactor => SUBFACTOR_IDS_BY_SUBFACTOR[subfactor])
      .filter(id => !ignoreSubfactorIds.includes(id))
  } else {
    const allSubfactorIds = Object.keys(SUBFACTORS_BY_ID)
    validCategoryIds = allSubfactorIds
      .filter(id => !ignoreSubfactorIds.includes(id))
      .map(id => parseInt(id, 10))
  }

  const request = yield* genericGraphqlRequest(
    gql`
      query {
        storylines: searchHistoricalStorylines(${args({
          companyId,
          quarter: quarter.toString(),
          perspectiveId: geography,
          validCategoryIds,
          searchText,
          first: 50,
        })}) {
          nodes {
            ${() => StorylineResource.requiredFields.join(', ')}
            quarter
          }
        }
        
        ${() =>
          searchText
            ? gql`
            counts: articleCountsByQuarter(${args({
              companyId,
              perspectiveId: geography,
              validCategoryIds,
              searchText,
            })}) {
              nodes {
                quarter
                factorId
                categoryId
                articleCount
              }
            }
          `
            : ''}
      }
    `,
  )
  // Update entities in the background.
  yield fork(function*() {
    const response = yield request
    const data = response.body.data.storylines.nodes
    yield put(
      entityActions.update(new StorylineResource().normalizedEntities(data)),
    )
  })
  return request
}

/**
 * Transforms the quarterly article counts from the response to a format
 * resembling that of the historical health data.
 */
function transformQuarterlyArticleCounts(responseData) {
  return pipe(
    groupBy(prop('quarter')),
    values,
    sortBy(prop('quarter')),
    map(quarterValues =>
      quarterValues.reduce((newValues, values) => {
        newValues.quarter = values.quarter
        const key = values.categoryId
          ? `subfactor-${SUBFACTORS_BY_ID[values.categoryId]}`
          : values.factorId
          ? `factor-${FACTORS_BY_ID[values.factorId]}`
          : 'overall'
        newValues[key] = values.articleCount
        return newValues
      }, {}),
    ),
  )(responseData)
}

function* loadHistoricalStorylines() {
  yield put(actions.setHistoricalStorylineIds(null))
  const companyId = yield select(selectors.getCompanyId)
  const geography = (yield select(selectors.getGeography)) || 0
  let factor = yield select(selectors.getFactor)
  if (factor === 'all') {
    factor = null
  }
  let subfactor = yield select(selectors.getSubfactor)
  if (subfactor === 'all') {
    subfactor = null
  }
  const quarter = yield select(selectors.getSelectedQuarter)
  const searchText = yield select(selectors.getHistoricalStorylineSearchText)
  const response = yield yield* fetchHistoricalStorylines({
    companyId,
    ignoreSubfactors: IGNORED_SUBFACTORS,
    quarter,
    geography,
    factor,
    subfactor,
    searchText,
    orderBy: [
      {
        field: 'articleCount',
        direction: SORT_DIRECTIONS.DESC,
      },
      {
        field: 'startDate',
        direction: SORT_DIRECTIONS.DESC,
      },
    ],
    limit: MAX_HISTORICAL_STORY_COUNT,
  })
  if (response.body.data.counts) {
    const articleCountsByQuarter = transformQuarterlyArticleCounts(
      response.body.data.counts.nodes,
    )
    yield put(actions.setQuarterlyArticleCounts(articleCountsByQuarter))
  }
  yield put(
    actions.setHistoricalStorylineIds(
      response.body.data.storylines.nodes.map(storyline => storyline.id),
    ),
  )
}

function* loadSimCircTimelineStories(action) {
  const daysSinceMatch = action.payload
  const companyId = yield select(selectors.getCompanyId)
  const factor = yield select(simCircSelectors.getSelectedPredictionFactor)
  const matchId = yield select(simCircSelectors.getForecastMatchId)
  const response = yield yield* fetchSimCircTimelineStories({
    companyId,
    daysSinceMatch,
    factor,
    matchId,
  })
  const stories = response.body.data.targetCompanyDate.topMatchStories.nodes
  yield put(
    entityActions.update(new StoryResource().normalizedEntities(stories)),
  )
  const storyIds = stories.map(story => story.id)
  yield put(simCircActions.setForecastModalStoryIds(storyIds))
}

/**
 * Allows navigation of previous and next quarters via the arrow keys on the
 * keyboard.
 */
function* handleArrowKeyNavigation() {
  const arrowKeyChannel = yield call(keyPressChannel, [
    LEFT_ARROW_KEY,
    RIGHT_ARROW_KEY,
  ])
  while (true) {
    const event = yield take(arrowKeyChannel)
    const quarter = yield select(selectors.getSelectedQuarter)
    const newQuarter =
      event.keyCode === LEFT_ARROW_KEY
        ? prevQuarter(quarter)
        : nextQuarter(quarter)
    const quarterExists = yield select(selectors.quarterExists, newQuarter)
    if (quarterExists) {
      yield put(actions.selectQuarter(newQuarter))
    }
  }
}

function* bootstrapPortalPage() {
  yield yield* fetchMyCompanies()
  const myCompanyIds = yield select(getMyCompanyIds)

  // If the user doesn't have any companies, we ignore this step and the page
  // shows an error.
  if (is.array.empty(myCompanyIds)) return

  // Get the company with the lowest health score.
  const orm = Orm.withEntities(yield select(getEntities))
  const companies = orm.getByIds(Company, myCompanyIds)
  const companyId = pipe(
    sortBy(path(['healthData', 'healthScore'])),
    nth(0),
    prop('id'),
  )(companies)

  yield put(replace({pathname: urls.companyOverviewPortal(companyId)}))
}

export function* selectQuarter(action) {
  yield put(
    push(
      {query: {[QUARTER_QUERY_PARAM]: action.payload || undefined}},
      {persistQuery: true},
    ),
  )
}

const isCompanyOverviewLocationChange = action =>
  action.type === LOCATION_CHANGED &&
  OVERVIEW_ROUTES.includes(action.payload.route)
const isCompanyHistoryLocationChange = action =>
  action.type === LOCATION_CHANGED &&
  HISTORY_ROUTES.includes(action.payload.route)
const isSimCircLocationChange = action =>
  action.type === LOCATION_CHANGED &&
  SIMCIRC_ROUTES.includes(action.payload.route)

const isCompanyOverviewPortalStandaloneLocationChange = action =>
  action.type === LOCATION_CHANGED &&
  action.payload.route === routes.companyOverviewPortalStandalone

export default function* companyOverviewSaga() {
  yield all([
    // Overview

    takeLatest(isCompanyOverviewLocationChange, locationChangedOverview),
    takeLatest(
      isCompanyOverviewPortalStandaloneLocationChange,
      bootstrapPortalPage,
    ),
    takeLatest(
      [
        actions.selectValence,
        actions.deselectValence,
        actions.setStorySortOrder,
        actions.setShowOnlyMyCompanies,
      ],
      fetchCompanyStories,
    ),
    takeLatest(actions.getLeadership, handleGetLeadership),

    // History

    takeLatest(isCompanyHistoryLocationChange, locationChangedHistory),
    takeLatest(actions.selectQuarter, selectQuarter),
    takeLatest(
      actions.setHistoricalStorylineSearchText,
      loadHistoricalStorylines,
    ),

    // SimCirc

    takeLatest(isSimCircLocationChange, locationChangedSimCirc),
    takeLatest(simCircActions.selectSubfactor, setSelectedSubfactor),
    takeLatest(simCircActions.setComparedMatchId, setComparedMatchId),
    takeLatest(simCircActions.setPredictionFactor, setPredictionFactor),
    takeLatest(
      simCircActions.openForecastStoriesModal,
      loadSimCircTimelineStories,
    ),
  ])
}
