import invariant from 'invariant'
import is from 'is'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import React, {cloneElement, PureComponent} from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export default class SizedContainer extends PureComponent {
  static propTypes = {
    render: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
  }

  state = {
    width: undefined,
    height: undefined,
  }

  container = null
  resizeObserver = new ResizeObserver(bind(this.onResize, this))

  constructor(props) {
    super(props)
    invariant(
      props.render || props.children,
      'You must pass either a child component or a render function to SizedContainer.',
    )
    invariant(
      !(props.render && props.children),
      'You cannot pass both a child component and a render function to SizedContainer.',
    )
  }

  componentDidMount() {
    this.resizeObserver.observe(this.container)
    this.updateSize()
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect()
  }

  render() {
    const {width, height} = this.state
    let children = null
    if (!is.undefined(width) && !is.undefined(height)) {
      if (this.props.children) {
        children = React.Children.map(this.props.children, child =>
          cloneElement(child, {width, height}),
        )
      } else {
        children = this.props.render(width, height)
      }
    }

    return (
      <div
        className={this.props.className}
        style={{
          width: '100%',
          height: '100%',
          flex: '1 1 auto',
          ...(this.props.style || {}),
        }}
        ref={div => (this.container = div)}
      >
        {children}
      </div>
    )
  }

  onResize(entries) {
    const {width, height} = entries[0].contentRect
    this.updateSize({width, height})
  }

  updateSize(size = null) {
    if (!this.container) return
    this.setState(size || this.getSize())
  }

  getSize() {
    return {
      width: this.container.clientWidth,
      height: this.container.clientHeight,
    }
  }
}
