import {createAction} from 'redux-actions'

const prefix = 'PROFILE_BUILDER'
const action = name => createAction(`${prefix}_${name}`)

export const setCurrentStep = action('SET_CURRENT_STEP')
export const setValidationError = action('SET_VALIDATION_ERROR')
export const saveUser = action('SAVE_USER')
export const skip = action('SKIP')
export const fetchCompanies = action('FETCH_COMPANIES')
export const setSuggestedCompanyIds = action('SET_SUGGESTED_COMPANY_IDS')
export const setSelectedIndustryIds = action('SET_SELECTED_INDUSTRY_IDS')
export const setSelectedCompanyIds = action('SET_SELECTED_COMPANY_IDS')
export const setSelectedSuggestedCompanyIds = action(
  'SET_SELECTED_SELECTED_COMPANY_IDS',
)
export const setPassword = action('SET_PASSWORD')
export const setConfirmPassword = action('SET_CONFIRM_PASSWORD')
export const setLocationId = action('SET_LOCATION_ID')
export const setFirmLocationId = action('SET_FIRM_LOCATION_ID')
export const setTimezone = action('SET_TIMEZONE')
export const setRole = action('SET_ROLE')
