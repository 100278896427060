import {map} from 'ramda'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import Company from 'app/models/Company'
import Story from 'app/models/Story'

import OverviewReport from '../../common/OverviewReport'
import * as actions from './company-report-actions'
import * as selectors from './company-report-selectors'

export default connect(
  createSelector(
    [getEntities, selectors.getState],
    (entities, state) => {
      const orm = Orm.withEntities(entities)
      const {
        isLoading,
        companyId,
        storyIdsByFactor,
        storyCount,
        storylineCount,
        timeFrameDays,
        isDownloadingPdf,
      } = state
      return {
        isLoading: isLoading,
        entity: companyId && orm.getById(Company, companyId),
        entityTypeName: 'Company',
        storiesByFactor:
          storyIdsByFactor &&
          map(storyIds => orm.getByIds(Story, storyIds), storyIdsByFactor),
        storyCount,
        storylineCount,
        timeFrameDays,
        isDownloadingPdf,
      }
    },
  ),
  {
    downloadPdf: actions.startDownloadPdf,
  },
)(OverviewReport)
