import {Quarter} from 'app/utils/quarters'

/**
 * Takes a match month string and returns the corresponding Quarter.
 */
export function matchMonthToQuarter(matchMonth) {
  const [year, month] = matchMonth.split('-').map(num => parseInt(num))
  const quarterNum = Math.floor((month - 1) / 3) + 1
  return new Quarter(year, quarterNum)
}
