import classNames from 'classnames'
import PropTypes from 'prop-types'
import {range} from 'ramda'
import React from 'react'

import Table from 'app/common/Table'

import {getColumns} from './industries-table-columns'

import * as tableStyles from './IndustriesTable.less'
import * as styles from './IndustriesTableLoading.less'

const MAX_ROW_COUNT = 20

const IndustriesTableLoading = ({rowCount = MAX_ROW_COUNT}) => {
  rowCount = Math.min(rowCount, MAX_ROW_COUNT)
  const columns = getColumns().map(column => ({
    ...column,
    className: styles[column.name],
    cellContents: <div className={styles.rect} />,
  }))

  return (
    <Table
      data={range(0, rowCount).map(num => ({id: num}))}
      columns={columns}
      className={classNames(styles.table, tableStyles.table)}
    />
  )
}
IndustriesTableLoading.propTypes = {
  rowCount: PropTypes.number,
}

export default IndustriesTableLoading
