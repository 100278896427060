import React from 'react'

export default function QuestionMark({ className }) {
  return (
    <svg viewBox="0 0 460 444" fill="currentColor" className={className} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M459.163 222.01C459.163 344.593 356.559 443.967 229.995 443.967C103.43 443.967 0.828369 344.593
            0.828369 222.01C0.828369 99.4277 103.43 0.0546875 229.995 0.0546875C356.559 0.0546875 459.163 99.4277
            459.163 222.01ZM32.8082 222.007C32.8082 327.486 121.093 412.992 229.998 412.992C338.903 412.992 427.188
            327.486 427.188 222.007C427.188 116.529 338.903 31.022 229.998 31.022C121.093 31.022 32.8082 116.529
            32.8082 222.007ZM257.783 327.94C257.783 340.942 246.901 351.481 233.477 351.481C220.054 351.481 209.172
            340.942 209.172 327.94C209.172 314.94 220.054 304.401 233.477 304.401C246.901 304.401 257.783 314.94
            257.783 327.94ZM233.262 131.208C205.371 131.208 190.068 148.11 190.068 166.707C190.068 175.065 183.072
            181.84 174.443 181.84C165.813 181.84 158.818 175.065 158.818 166.707C158.818 132.215 187.132 100.941
            233.262 100.941C278.361 100.941 301.178 133.995 301.178 166.707C301.178 187.025 292.242 205.186 268.429
            228.243C250.129 245.967 245.623 254.571 245.623 264.045V277.497C245.623 285.855 238.628 292.63 229.998
            292.63C221.369 292.63 214.374 285.855 214.374 277.497V264.045C214.374 245.576 222.642 229.785 246.334
            206.839C264.639 189.114 269.929 178.366 269.929 166.707C269.929 148.743 257.824 131.208 233.262 131.208Z"
            fill="currentColor"/>
    </svg>
  )
}
