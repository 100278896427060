import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import {FACTORS, VALENCES} from 'app/constants'

import * as styles from './styles.less'

export default function FactorIcon({factor, valence, className, ...restProps}) {
  const Icon = {
    [FACTORS.DEALS]: Deals,
    [FACTORS.EXECS]: Execs,
    [FACTORS.FINANCE]: Finance,
    [FACTORS.GOVERNMENT]: Government,
    [FACTORS.OPERATIONS]: Operations,
    [FACTORS.PRODUCT]: Product,
  }[factor]
  return (
    <span
      className={classNames(
        styles.icon,
        styles[factor],
        valence && styles[valence.toLowerCase()],
        className,
      )}
      {...restProps}
    >
      {Icon ? <Icon /> : null}
    </span>
  )
}
FactorIcon.propTypes = {
  factor: PropTypes.oneOf(Object.values(FACTORS)).isRequired,
  valence: PropTypes.oneOf(Object.values(VALENCES)),
}

function Deals() {
  return (
    <svg viewBox="0 -4.5 33 28.5">
      <path
        d="M22.2,15.5c-0.1,0-0.3,0-0.4-0.1l-2.7-2.3c-0.2-0.2-0.3-0.5-0.1-0.8c0.2-0.2,0.5-0.3,0.8-0.1l2.7,2.3
        c0.2,0.2,0.3,0.5,0.1,0.8C22.5,15.4,22.4,15.5,22.2,15.5z"
      />
      <path
        d="M19.7,16.9c-0.1,0-0.2,0-0.3-0.1l-2.2-1.8c-0.2-0.2-0.3-0.5-0.1-0.8c0.2-0.2,0.5-0.3,0.8-0.1l2.2,1.8
        c0.2,0.2,0.3,0.5,0.1,0.8C20,16.8,19.9,16.9,19.7,16.9z"
      />
      <path
        d="M15.7,19.7c-0.4,0-0.9-0.2-1.4-0.6C13.7,18.6,7.8,14,7,11.7c-0.1-0.3,0.1-0.6,0.3-0.7C7.6,10.9,7.9,11,8,11.3
        c0.6,1.7,5.3,5.6,7,7c0.4,0.4,0.9,0.4,1.1,0.1c0.2-0.3,0.3-0.7-0.1-1l-1-1c-0.2-0.2-0.2-0.6,0-0.8c0.2-0.2,0.6-0.2,0.8,0l1,0.9
        c0.9,0.7,0.8,1.9,0.2,2.5C16.7,19.5,16.2,19.7,15.7,19.7z"
      />
      <path
        d="M19,19.3c-0.4,0-0.9-0.2-1.3-0.5L15,16.4c-0.2-0.2-0.3-0.5-0.1-0.8c0.2-0.2,0.5-0.3,0.8-0.1l2.6,2.3
        c0.6,0.5,1,0.1,1.1,0c0.2-0.2,0.3-0.7-0.1-1.1l0.7-0.8l0.7,0.6c0.2,0.2,0.5,0.2,0.7,0.2c0.3-0.1,0.5-0.4,0.6-0.7
        c0-0.3,0-0.5-0.3-0.7l0.7-0.8c0,0,0,0,0,0c0.7,0.6,1.1,0,1.2,0c0.1-0.1,0.5-0.6-0.1-1.1l-6.3-5.1C17,8.4,16.5,8.5,16,8.5
        c-0.4,0.6-1.5,1.8-2.6,2c-0.5,0.1-0.9,0-1.3-0.3c-0.7-0.5-0.9-1.5-0.6-2.6c0.4-1.8,2.1-3.8,4.7-4.2c0.1,0,2-0.5,6.1,1.7l1.4-0.4
        c0.3-0.1,0.6,0.1,0.7,0.4c0.1,0.3-0.1,0.6-0.4,0.7l-1.8,0.5l-0.2-0.1c-3.9-2-5.6-1.7-5.6-1.7c-2.2,0.4-3.5,2-3.9,3.4
        c-0.2,0.7-0.1,1.3,0.2,1.5c0.1,0.1,0.3,0.1,0.4,0.1c0.7-0.1,1.6-1.2,2-1.8l0.2-0.3l0.4,0.1c0.2,0,0.8-0.1,1.4-0.3L17.6,7l6.8,5.5
        c0.9,0.8,0.8,1.9,0.2,2.7c-0.3,0.4-0.8,0.7-1.4,0.7c0,0.1,0,0.2,0,0.3c-0.1,0.7-0.6,1.3-1.3,1.6c-0.4,0.1-0.8,0.1-1.1,0
        c-0.1,0.3-0.2,0.6-0.4,0.8C20,19,19.6,19.3,19,19.3z"
      />
      <path d="M7.2,14.8l-5.7-1.6l3-10.7l5.7,1.6L7.2,14.8z M2.8,12.4l3.6,1l2.4-8.5l-3.6-1L2.8,12.4z" />
      <path d="M26.3,15l-3-10.7L29,2.8l3,10.7L26.3,15z M24.6,5.1l2.4,8.5l3.6-1l-2.4-8.5L24.6,5.1z" />
      <path
        d="M9.3,5.7c-0.1,0-0.2,0-0.3-0.1C8.8,5.5,8.7,5.2,8.8,4.9C9,4.6,9.2,4.2,14.5,4.1C14.8,4,15,4.3,15,4.6
        c0,0.3-0.2,0.6-0.5,0.6c-3.8,0.1-4.7,0.3-4.8,0.4C9.6,5.7,9.4,5.7,9.3,5.7z"
      />
      <path
        d="M25.1,14.2c-0.4,0-0.7-0.1-0.7-0.1c-0.3-0.1-0.5-0.4-0.4-0.7c0.1-0.3,0.4-0.5,0.7-0.4c0,0,0.9,0.2,1.3-0.2
        c0.2-0.2,0.5-0.3,0.8-0.1c0.2,0.2,0.3,0.5,0.1,0.8C26.3,14.1,25.6,14.2,25.1,14.2z"
      />
    </svg>
  )
}

function Execs() {
  return (
    <svg viewBox="0 -0.75 27 26.25">
      <path
        d="M23.9,24.2H3.3c-0.8,0-1.5-0.7-1.5-1.5v-2.8c0-0.6,0.4-1.2,1-1.4c6-2.3,7.2-3.3,7.4-3.6v-0.6
			c-0.7-0.8-1.2-1.8-1.6-2.9c-0.5-0.4-0.8-1-0.8-1.6C7.6,9.2,7.8,8.6,8.1,8.1V5.8c0-3.2,2.1-5.1,5.5-5.1c3.5,0,5.5,1.9,5.5,5.1v2.2
			c0.3,0.5,0.5,1.1,0.4,1.7c-0.1,0.6-0.4,1.2-0.8,1.6c-0.3,1.1-0.9,2.1-1.6,2.9v0.6c0.2,0.3,1.4,1.3,7.4,3.6c0.6,0.2,1,0.8,1,1.4
			v2.8C25.4,23.6,24.7,24.2,23.9,24.2z M13.6,2.2c-2.6,0-4,1.2-4,3.6v2.8L9.4,8.8C9.2,9,9.2,9.3,9.2,9.6c0,0.3,0.2,0.5,0.4,0.7
			l0.2,0.2l0.1,0.3c0.3,1.1,0.8,2,1.5,2.7l0.2,0.2l0,1.6c-0.3,0.8-1.5,2-8.3,4.6l0,2.8l20.6,0l0-2.8l0,0c-6.9-2.6-8.1-3.8-8.3-4.6
			l0-0.2v-1.4l0.2-0.2c0.7-0.7,1.2-1.6,1.5-2.7l0.1-0.3l0.2-0.2c0.2-0.2,0.4-0.4,0.4-0.7c0-0.3,0-0.6-0.2-0.8l-0.2-0.2V5.8
			C17.6,3.5,16.2,2.2,13.6,2.2z"
      />
    </svg>
  )
}

function Finance() {
  return (
    <svg viewBox="0 -0.25 25.4 25.15">
      <path
        d="M12.4,23.7C6.1,23.7,1,18.6,1,12.4C1,6.1,6.1,1,12.4,1s11.4,5.1,11.4,11.4C23.7,18.6,18.6,23.7,12.4,23.7z
     M12.4,2.1C6.7,2.1,2.1,6.7,2.1,12.4c0,5.7,4.6,10.3,10.3,10.3S22.6,18,22.6,12.4C22.6,6.7,18,2.1,12.4,2.1z"
      />
      <path
        d="M12.3,18.3c-1.2,0-2.8-0.7-3.8-1.6c-0.2-0.2-0.3-0.5-0.1-0.8c0.2-0.2,0.5-0.3,0.8-0.1
    c0.9,0.8,2.2,1.3,3.1,1.3c1.4,0,2.5-1,2.5-2.1c0-1-0.8-1.6-2.7-2.2c-1.1-0.4-3.5-1.1-3.5-3.2c0-1.8,1.6-3.2,3.6-3.2
    c1.3,0,2.5,0.6,3.1,1.6c0.2,0.3,0.1,0.6-0.2,0.8C15,9,14.6,8.9,14.5,8.6c-0.4-0.7-1.3-1.1-2.2-1.1c-1.4,0-2.5,1-2.5,2.1
    c0,1,0.8,1.6,2.7,2.2c1.5,0.4,3.5,1.2,3.5,3.2C15.9,16.8,14.3,18.3,12.3,18.3z"
      />
      <path
        d="M12.3,19.8c-0.3,0-0.6-0.2-0.6-0.6V5.5C11.7,5.2,12,5,12.3,5s0.6,0.2,0.6,0.6v13.7
			C12.8,19.5,12.6,19.8,12.3,19.8z"
      />
    </svg>
  )
}

function Government() {
  return (
    <svg viewBox="-1 0 26 27">
      <path
        d="M20.3,25.2c-0.3,0-0.6-0.2-0.6-0.6v-8.4c0-0.3,0.2-0.6,0.6-0.6c0.3,0,0.6,0.2,0.6,0.6v8.4
			C20.8,24.9,20.6,25.2,20.3,25.2z"
      />
      <path
        d="M16.5,25.2c-0.3,0-0.6-0.2-0.6-0.6v-8.4c0-0.3,0.2-0.6,0.6-0.6S17,16,17,16.3v8.4
			C17,24.9,16.8,25.2,16.5,25.2z"
      />
      <path
        d="M12.6,25.2c-0.3,0-0.6-0.2-0.6-0.6v-8.4c0-0.3,0.2-0.6,0.6-0.6s0.6,0.2,0.6,0.6v8.4
			C13.1,24.9,12.9,25.2,12.6,25.2z"
      />
      <path
        d="M8.7,25.2c-0.3,0-0.6-0.2-0.6-0.6v-8.4c0-0.3,0.2-0.6,0.6-0.6s0.6,0.2,0.6,0.6v8.4C9.2,24.9,9,25.2,8.7,25.2z
			"
      />
      <path
        d="M4.5,25.2c-0.3,0-0.6-0.2-0.6-0.6v-8.4c0-0.3,0.2-0.6,0.6-0.6C4.8,15.7,5,16,5,16.3v8.4
    C5,24.9,4.8,25.2,4.5,25.2z"
      />
      <path d="M22.3,15.8H2.8c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6h19.5c0.3,0,0.6,0.2,0.6,0.6S22.6,15.8,22.3,15.8z" />
      <path
        d="M22.4,26.1H2.6c-0.3,0-0.6-0.2-0.6-0.6c0-0.3,0.2-0.6,0.6-0.6h19.8c0.3,0,0.6,0.2,0.6,0.6
			C23,25.9,22.7,26.1,22.4,26.1z"
      />
      <path
        d="M12.5,6.7c-0.3,0-0.6-0.2-0.6-0.6V2.6c0-0.3,0.2-0.6,0.6-0.6s0.6,0.2,0.6,0.6v3.5C13.1,6.4,12.8,6.7,12.5,6.7
			z"
      />
      <path
        d="M20.2,14H4.9v-0.6c0-3.7,3.4-6.7,7.7-6.7s7.7,3,7.7,6.7V14z M6,12.9H19c-0.3-2.8-3.1-5.1-6.5-5.1
			S6.3,10.1,6,12.9z"
      />
    </svg>
  )
}

function Operations() {
  return (
    <svg viewBox="0 0 27 27">
      <path d="M7.3,24.8H2.1L2.6,1.8h3.8L7.3,24.8z M3.2,23.7h2.9L5.4,2.9H3.7L3.2,23.7z" />
      <path d="M13.1,24.8H6.2L5.8,15l7.3-4.3V24.8z M7.2,23.7H12V12.6l-5,3L7.2,23.7z" />
      <path d="M19.1,24.8H12v-10l7.2-4.1V24.8z M13.1,23.7h5V12.6l-5,2.9V23.7z" />
      <path d="M25.1,24.8H18v-10l7.1-4.1V24.8z M19.1,23.7H24V12.6l-4.9,2.9V23.7z" />
      <path d="M9.5,18.3C9.2,18.3,9,18,9,17.7v-1.2C9,16.2,9.2,16,9.5,16s0.6,0.2,0.6,0.6v1.2C10.1,18,9.8,18.3,9.5,18.3z" />
      <path
        d="M15.6,18.3c-0.3,0-0.6-0.2-0.6-0.6v-1.2c0-0.3,0.2-0.6,0.6-0.6s0.6,0.2,0.6,0.6v1.2
			C16.1,18,15.9,18.3,15.6,18.3z"
      />
      <path
        d="M21.6,18.3c-0.3,0-0.6-0.2-0.6-0.6v-1.2c0-0.3,0.2-0.6,0.6-0.6c0.3,0,0.6,0.2,0.6,0.6v1.2
			C22.1,18,21.9,18.3,21.6,18.3z"
      />
    </svg>
  )
}

function Product() {
  return (
    <svg viewBox="-0.5 0 24.5 25">
      <path d="M13.7,14H9.4V8.9l0.9-6.4h2.6l0.9,6.4V14z M10.5,12.9h2.1v-4l-0.7-5.3h-0.7L10.5,9L10.5,12.9z" />
      <path
        d="M21.5,22.4H1.6V8.9c0-0.3,0.2-0.6,0.6-0.6s0.6,0.2,0.6,0.6v12.4h17.7V8.9c0-0.3,0.2-0.6,0.6-0.6
			c0.3,0,0.6,0.2,0.6,0.6V22.4z"
      />
      <path
        d="M21.6,9.4h-8.5c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6h7.1l-1-4.7H3.9L2.8,8.3h7.1c0.3,0,0.6,0.2,0.6,0.6
			s-0.2,0.6-0.6,0.6H1.5L3,2.5h17.1L21.6,9.4z"
      />
      <path d="M6.7,15.9h-2c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6h2c0.3,0,0.6,0.2,0.6,0.6S7,15.9,6.7,15.9z" />
      <path
        d="M9.3,17.9H4.8c-0.3,0-0.6-0.2-0.6-0.6c0-0.3,0.2-0.6,0.6-0.6h4.5c0.3,0,0.6,0.2,0.6,0.6
			C9.8,17.6,9.6,17.9,9.3,17.9z"
      />
      <path
        d="M9.3,19.8H4.8c-0.3,0-0.6-0.2-0.6-0.6c0-0.3,0.2-0.6,0.6-0.6h4.5c0.3,0,0.6,0.2,0.6,0.6
			C9.8,19.6,9.6,19.8,9.3,19.8z"
      />
    </svg>
  )
}
