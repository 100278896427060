import PropTypes from 'prop-types'
import React from 'react'

const ShareIcon = ({className}) => (
  <svg viewBox="0 0 21.5 30.4" className={className}>
    <path
      d="M10.8,18.7c-0.4,0-0.8-0.3-0.8-0.8V0.8C10,0.3,10.3,0,10.8,0s0.8,0.3,0.8,0.8v17.2
      C11.5,18.4,11.2,18.7,10.8,18.7z"
    />
    <path
      d="M14.9,5.6c-0.2,0-0.4-0.1-0.5-0.2l-3.6-3.6L7.1,5.4c-0.3,0.3-0.8,0.3-1.1,0s-0.3-0.8,0-1.1l4.1-4.1
      c0.3-0.3,0.8-0.3,1.1,0l4.1,4.1c0.3,0.3,0.3,0.8,0,1.1C15.3,5.6,15.1,5.6,14.9,5.6z"
    />
    <path
      d="M20.8,30.4h-20c-0.4,0-0.8-0.3-0.8-0.8v-20c0-0.4,0.3-0.8,0.8-0.8h6c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8
      H1.5v18.5H20V10.4h-5.3c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h6c0.4,0,0.8,0.3,0.8,0.8v20C21.5,30.1,21.2,30.4,20.8,30.4z"
    />
  </svg>
)
ShareIcon.propTypes = {
  className: PropTypes.string,
}
export default ShareIcon
