import is from 'is'

import {
  BASE_APP_URL,
  ROOT_URL,
  SENDGRID_TRACKING_PLACEHOLDER,
} from 'app/constants'
import {QUARTER_QUERY_PARAM} from 'app/overviews/company/company-overview-constants'
import {
  GEOGRAPHY_QUERY_PARAM,
  TIME_FRAME_QUERY_PARAM,
} from 'app/overviews/common/overview-constants'
import {QUERY_PARAMS as SIM_CIRC_QUERY_PARAMS} from 'app/overviews/company/simcirc/company-overview-simcirc-constants'
import routes, {toUrl} from 'app/routes'

// Node hides URLSearchParams inside a module, so we have to require it.
const URLSearchParams =
  typeof window !== 'undefined'
    ? window.URLSearchParams
    : require('url').URLSearchParams

export const withQueryParams = (url, params) => {
  const [urlWithoutQuery, searchString] = url.split('?')
  const urlSearchParams = new URLSearchParams(searchString)
  Object.entries(params).forEach(([key, value]) => {
    if (is.defined(value)) {
      urlSearchParams.set(key, value)
    }
  })
  const queryString = Array.from(urlSearchParams).length
    ? '?' + urlSearchParams.toString()
    : ''
  return urlWithoutQuery + queryString
}

export const withSendGridTracking = url =>
  withQueryParams(url, {send_id: SENDGRID_TRACKING_PLACEHOLDER})
    // Unescape the special SendGrid variable.
    .replace(
      encodeURIComponent(SENDGRID_TRACKING_PLACEHOLDER),
      SENDGRID_TRACKING_PLACEHOLDER,
    )

export const toAbsoluteUrl = (url, {root = ROOT_URL} = {}) => {
  try {
    new URL(url)
    // If this is successful, then the URL is already absolute.
    return url
  } catch (e) {
    return root + url
  }
}

export const currentUrl = () => {
  return window.location.pathname + window.location.search
}

export const USRC_VALUES = {
  WEB: 'signals_view',
  EMAIL: 'signals_email',
  ESG_EMAIL: 'esg_email',
}

export default {
  home: () => toUrl(routes.home),
  profileBuilder: () => toUrl(routes.profileBuilder),
  logout: () => toUrl(routes.logout),
  dashboard: () => toUrl(routes.dashboard),
  industries: () => toUrl(routes.industries),
  companies: () => toUrl(routes.companies),
  litigation: () => toUrl(routes.litigation),
  litigationLoadView: () => toUrl(routes.litigationLoadView),
  litigationIndustryView: () => toUrl(routes.litigationIndustryView),
  litigationCompanyView: () => toUrl(routes.litigationCompanyView),

  companyOverview: (companyId, {factor, subfactor, geography} = {}) =>
    withQueryParams(toUrl(routes.companyOverview, {companyId}), {
      factor,
      subfactor,
      geography,
    }),
  companyOverviewPortal: (companyId, {factor, subfactor, geography} = {}) =>
    withQueryParams(toUrl(routes.companyOverviewPortal, {companyId}), {
      factor,
      subfactor,
      geography,
    }),
  companyHistory: (companyId, {factor, subfactor, geography, quarter} = {}) =>
    withQueryParams(toUrl(routes.companyHistory, {companyId}), {
      factor,
      subfactor,
      geography,
      [QUARTER_QUERY_PARAM]: quarter,
    }),
  companyHistoryPortal: (companyId, {factor, subfactor, geography} = {}) =>
    withQueryParams(toUrl(routes.companyHistoryPortal, {companyId}), {
      factor,
      subfactor,
      geography,
    }),
  companySimCirc: companyId => toUrl(routes.companySimCirc, {companyId}),
  companySimCircCurrent: companyId =>
    toUrl(routes.companySimCircCurrent, {companyId}),
  companySimCircForecast: (companyId, {factor} = {}) =>
    withQueryParams(toUrl(routes.companySimCircForecast, {companyId}), {
      [SIM_CIRC_QUERY_PARAMS.PREDICTION_FACTOR]: factor,
    }),
  companyReport: (companyId, {timeFrameDays, geography} = {}) =>
    withQueryParams(toUrl(routes.companyReport, {companyId}), {
      [TIME_FRAME_QUERY_PARAM]: timeFrameDays,
      [GEOGRAPHY_QUERY_PARAM]: geography,
    }),

  industryOverview: (industryId, {factor, subfactor, geography} = {}) =>
    withQueryParams(toUrl(routes.industryOverview, {industryId}), {
      factor,
      subfactor,
      geography,
    }),
  industryOverviewCompanies: industryId =>
    toUrl(routes.industryOverviewCompanies, {industryId}),
  industryOverviewPortal: (industryId, {factor, subfactor, geography} = {}) =>
    withQueryParams(toUrl(routes.industryOverviewPortal, {industryId}), {
      factor,
      subfactor,
      geography,
    }),
  industryOverviewPortalCompanies: industryId =>
    toUrl(routes.industryOverviewPortalCompanies, {industryId}),
  industryReport: (industryId, {timeFrameDays, geography} = {}) =>
    withQueryParams(toUrl(routes.industryReport, {industryId}), {
      [TIME_FRAME_QUERY_PARAM]: timeFrameDays,
      [GEOGRAPHY_QUERY_PARAM]: geography,
    }),

  storylineView: storylineId => toUrl(routes.storylineView, {storylineId}),
  storylineViewPortal: storylineId =>
    toUrl(routes.storylineViewPortal, {storylineId}),
  interimPage: ({contentDirectorId, savedSearchId}) =>
    toUrl(routes.interimPage, {ids: `${contentDirectorId}-${savedSearchId}`}),
  litigationMonitoringReportView: (saveId) =>
    toUrl(toUrl(routes.litigationMonitoringReport, { saveId })),
  litigationSectorReportView: (saveId) =>
  toUrl(toUrl(routes.litigationSectorReport, { saveId })),

  noAccess: () => toUrl(routes.noAccess),
  djangoAdmin: adminHash => `/admin/${adminHash}/`,

  // External URLs

  pyapp: {
    home: ({source = USRC_VALUES.WEB} = {}) =>
      `${BASE_APP_URL}/?usrc=${source}`,
    login: () => `${BASE_APP_URL}/login/`,
    logout: () => `${BASE_APP_URL}/logout/`,
    profile: ({source = USRC_VALUES.WEB} = {}) =>
      `${BASE_APP_URL}/profile/?usrc=${source}`,
    admin: ({source = USRC_VALUES.WEB} = {}) =>
      `${BASE_APP_URL}/profile/firm/accounts/?usrc=${source}`,
    publish: ({source = USRC_VALUES.WEB} = {}) =>
      `${BASE_APP_URL}/reports/builder/?usrc=${source}`,
    publishV3: ({source = USRC_VALUES.WEB} = {}) =>
      `${BASE_APP_URL}/publish/?usrc=${source}`,
    flagged: ({source = USRC_VALUES.WEB} = {}) =>
      `${BASE_APP_URL}/flagged/?usrc=${source}`,
    firmStats: ({source = USRC_VALUES.WEB} = {}) =>
      `${BASE_APP_URL}/firmstats/latest_snapshot/?usrc=${source}`,
    help: ({source = USRC_VALUES.WEB} = {}) =>
      `${BASE_APP_URL}/help/?usrc=${source}`,
    article: ({
      contentDirectorId,
      userId,
      root = BASE_APP_URL,
      source = USRC_VALUES.WEB,
      savedsearchId = null,
    }) => `${root}/article/v/${contentDirectorId}/${userId}?usrc=${source}${savedsearchId ? `&ss_id=${savedsearchId}` : ''}`,
    emailSettings: ({source = USRC_VALUES.WEB, root = BASE_APP_URL} = {}) =>
      `${root}/profile/notifications/?usrc=${source}`,
    mis: ({source = USRC_VALUES.WEB} = {}) =>
      `${BASE_APP_URL}/mis/?usrc=${source}`,
    unmask: ({source = USRC_VALUES.WEB} = {}) =>
      withQueryParams(`${BASE_APP_URL}/masquerade/unmask/`, {
        usrc: source,
        next: '/',
        'next-insights': '',
      }),
    ssoRedirect: ({url, firmId}) =>
      `${BASE_APP_URL}/insights_redirect/?sso_firm_id=${firmId}&url=${encodeURIComponent(
        url,
      )}`,

    api: {
      profileInfo: () => `${BASE_APP_URL}/insights-api/my-profile/`,
      addSavedSearches: () =>
        `${BASE_APP_URL}/insights-api/add-saved-searches/`,
      removeSavedSearches: () =>
        `${BASE_APP_URL}/insights-api/remove-saved-searches/`,
      editSavedSearchEmailSettings: () =>
        `${BASE_APP_URL}/insights-api/edit-saved-search-email-config/`,
      reportToPdf: () => `${BASE_APP_URL}/insights-api/report-to-pdf/`,
      reportToPdfProgress: () =>
        `${BASE_APP_URL}/insights-api/report-to-pdf/progress/`,
      frontendError: () => `${BASE_APP_URL}/api/frontend-error/`,
      logInteractionUrl: () =>
        `${BASE_APP_URL}/insights-api/log-interaction-url/`,
      flaggingData: () => `${BASE_APP_URL}/insights-api/get-flagging-data/`,
      articleFlagAdd: () => `${BASE_APP_URL}/insights-api/add-article-flag/`,
      articleFlagRemove: () =>
        `${BASE_APP_URL}/insights-api/remove-article-flag/`,
      flagCategoryAdd: () => `${BASE_APP_URL}/insights-api/add-flag-category/`,
      saveFilterDefaults: () =>
        `${BASE_APP_URL}/insights-api/save-filter-defaults/`,
      saveChartImage: () => `${BASE_APP_URL}/insights-api/save-chart-image/`,
      saveLitigationSavedSearch: () =>
        `${BASE_APP_URL}/insights-api/save-litigation-search/`,
      removeLitigationSavedSearch: () =>
        `${BASE_APP_URL}/insights-api/delete-litigation-search/`,
    },
    esgDashboard: ({source = USRC_VALUES.WEB} = {}) =>
      `${BASE_APP_URL}/esg/?usrc=${source}`,
    esgCompany: ({source = USRC_VALUES.WEB, companyId} = {}) =>
      withQueryParams(`${BASE_APP_URL}/esg/company/${companyId}/`, {
        usrc: source,
      }),
    esgIndustry: ({source = USRC_VALUES.WEB, industryId} = {}) =>
      withQueryParams(`${BASE_APP_URL}/esg/`, {
        usrc: source,
        industry: industryId,
      }),
  },

  responsiveSubMenuItems: {
    insights: {
      subMenus: [
          {
              title: "Dashboard",
              url: `${toUrl(routes.home)}dashboard/`,
          },
          {
              title: "Companies",
              url: `${toUrl(routes.home)}companies/`,
          },
          {
              title: "Industries",
              url: `${toUrl(routes.home)}industries/`,
          },
          {
              title: "Litigation Trends",
              url: `${toUrl(routes.home)}litigation/`,
          }
      ]
    },
    mis: {
      subMenus: [
        {
          title: "Illuminations",
          url: `${BASE_APP_URL}/mis/illuminations/`
        },
        {
          title: "Premium Reports",
          url: `${BASE_APP_URL}/mis/premium-reports/`
        }
      ]
    },
    esg: {
      subMenus: [
        {
          title: "ESG Industry",
          url: `${BASE_APP_URL}/esg/#esg-industry/`
        },
        {
          title: "ESG Company",
          url: `${BASE_APP_URL}/esg/#esg-company/`
        }
      ]
    },
    oldPublish: {
      title: "Publish",
      url: `${BASE_APP_URL}/reports/builder/`,
    },
    newPublish: {
      title: "Publish",
      url: `${BASE_APP_URL}/publish/`,
      tagText: "NEW",
    }
  }
}
