import React from 'react'
import { useSelector } from 'react-redux'
import LitigationMonitoring from './LitigationMonitoring'
import LitigationSector from './LitigationSector'
import * as actions from './litigations-actions'
import * as constants from './litigations-constants'
import * as selectors from 'app/litigation/litigations-selectors'
import reducer from './litigations-reducer'
import saga from './litigations-saga'

export { actions, constants, reducer, saga }

export default function index() {
    const state = useSelector(selectors.getLitigationState)
    const litigation = state.isOnMainPage ? <LitigationMonitoring /> : <LitigationSector/>

  return litigation
}
