import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {getMyIndustryIds} from 'app/global/global-selectors'
import MyEntityStar from 'app/reusable/entities/my-entity-star'

import * as actions from './my-industry-star-actions'

const MyIndustryStar = ({
  industry,
  myIndustryIds,

  addIndustry,
  removeIndustry,

  className,
}) => (
  <MyEntityStar
    entity={industry}
    entityType="industry"
    myEntityIds={myIndustryIds}
    addEntity={addIndustry}
    removeEntity={removeIndustry}
    className={className}
  />
)
MyIndustryStar.propTypes = {
  industry: PropTypes.object.isRequired,
  myIndustryIds: PropTypes.arrayOf(PropTypes.number),

  addIndustry: PropTypes.func.isRequired,
  removeIndustry: PropTypes.func.isRequired,

  className: PropTypes.string,
}

export default connect(
  createSelector(
    [getMyIndustryIds],
    myIndustryIds => ({myIndustryIds}),
  ),
  {
    addIndustry: actions.addIndustry,
    removeIndustry: actions.removeIndustry,
  },
)(MyIndustryStar)
