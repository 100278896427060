import {FACTORS_BY_SUBFACTOR, SUBFACTORS_BY_ID} from 'app/constants'
import {field, foreignKey} from 'app/framework/fields'
import Model from 'app/framework/Model'

import Company from './Company'

export default class Storyline extends Model {
  static entityKey = 'storylines'

  static fields = {
    company: foreignKey(Company, {required: false}),
    summary: field(),
    startDate: field(),
    endDate: field(),
    valence: field(),
    subfactorId: field(),
    isLitigation: field(),
    isRumor: field(),
    isOpinion: field(),
    articleCount: field(),
    companiesMentioned: field({required: false}),
    perspectiveId: field(),
  }

  static indexes = ['company']

  get subfactor() {
    return SUBFACTORS_BY_ID[this.subfactorId]
  }

  get factor() {
    return FACTORS_BY_SUBFACTOR[this.subfactor]
  }
}
