import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { range } from 'ramda'
import PropTypes from 'prop-types'

import { StoryCard, StoryCardLoading } from 'app/reusable/stories/story-card'
import LoadingSpinner from 'app/common/loading-spinner'
import * as styles from './SectorStories.less'
import * as actions from 'app/litigation/litigations-actions'
import * as selectors from 'app/litigation/litigations-selectors'

const SKELETON_STORY_COUNT = 5
const TRIGGER_PROXIMITY = 500

/**
 * Container for the story cards.
 * It has an infinite scrolling trigger, meaning when you hit the bottom of the page and there are more stories
 * it will automatically load the next batch.
 *
 * @param stories
 * @param storyFetchError
 */
const SectorStories = ({ stories, storyFetchError }) => {
  const isLoading = useSelector(selectors.getInfiniteScrollLoading)
  const storyCount = useSelector(selectors.getStoryCount)
  const fetchNext = !storyFetchError && !isLoading && stories !== null && storyCount > stories.length
  const dispatch = useDispatch()
  const triggerRef = React.useRef(null)
  let shouldTrigger = false

  const scrollHandler = () => {
    if (fetchNext) {
      if (window.scrollY + window.innerHeight + TRIGGER_PROXIMITY >= triggerRef.current.offsetTop) {
        if (!shouldTrigger) {
          shouldTrigger = true
          dispatch(actions.fetchMoreStories())
        }
      } else {
        shouldTrigger = false
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  })

  if (storyFetchError) {
    return <div className={styles.informationContainer}>Error fetching stories.</div>
  }

  if (stories && stories.length === 0) {
    return <div className={styles.informationContainer}>No stories to show.</div>
  }

  let content
  if (!stories) {
    content = range(0, SKELETON_STORY_COUNT).map(index => (
      <StoryCardLoading
        shouldShowCompanyName={false}
        key={index}
      />
    ))
  } else {
    content = stories.map(story => (
      <StoryCard
        story={story}
        shouldShowCompanyName={false}
        isPortalPage={false}
        shouldShowStoryline={true}
        key={story.id}
      />
    ))
  }
  return (
    <div className={styles.stories}>
      {content}
      <div className={styles.triggerContainer} ref={triggerRef}>
        {isLoading &&
          <LoadingSpinner className={styles.spinner}/>}
      </div>
    </div>
  )
}

SectorStories.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object),
  storyFetchError: PropTypes.bool.isRequired,
}

export default React.memo(SectorStories)
