import React from 'react'

export default function TrendStagnatedIcon({ className }) {
  return (
    <svg className={className} viewBox="0 0 9 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 1C0 0.447715 0.447715 0 1 0H8C8.55228 0 9 0.447715 9 1V3C9 3.55228 8.55228 4 8 4H1C0.447715
        4 0 3.55228 0 3V1Z"
        fill="currentColor"/>
    </svg>
  )
}
