import {handleActions} from 'redux-actions'
import {LOCATION_CHANGED} from 'redux-little-router'

import * as actions from './global-actions'

const getInitialState = () => ({
  error: null,
  isNotFound: false,
  myCompanyIds: null,
  myIndustryIds: null,
})

export default handleActions(
  {
    [LOCATION_CHANGED]: (state, action) => ({
      ...state,
      isNotFound: false,
    }),
    [actions.setError]: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    [actions.setNotFound]: (state, action) => ({
      ...state,
      isNotFound: true,
    }),
    [actions.setMyCompanyIds]: (state, action) => ({
      ...state,
      myCompanyIds: action.payload,
    }),
    [actions.setMyIndustryIds]: (state, action) => ({
      ...state,
      myIndustryIds: action.payload,
    }),
  },
  getInitialState(),
)
