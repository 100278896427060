import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import React from 'react'

import TabRow from 'app/common/tab-row'

export class Tab extends React.PureComponent {
  static propTypes = {
    id: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    render: PropTypes.func.isRequired,
    url: PropTypes.string,
  }
}

/**
 * Generic tabbed container class. Example:
 *
 *   <TabbedContainer>
 *     <TabbedContainer.Tab
 *       id="tab1"
 *       label="First Tab"
 *       render={() => <SomeContent />}
 *     />
 *     <TabbedContainer.Tab
 *       id="tab2"
 *       label="Second Tab"
 *       render={() => <SomeOtherContent />}
 *     />
 *   </TabbedContainer>
 */
export default class TabbedContainer extends React.PureComponent {
  static propTypes = {
    defaultTab: PropTypes.any,
    activeTab: PropTypes.any,
    onChange: PropTypes.func,
    className: PropTypes.string,
  }

  static Tab = Tab

  constructor(props) {
    super(props)
    this.state = {
      currentTabId: props.activeTabId || props.defaultTab || this.tabs[0].id,
    }
  }

  get tabs() {
    return React.Children.map(this.props.children, tabComponent => ({
      id: tabComponent.props.id,
      label: tabComponent.props.label,
      render: tabComponent.props.render,
      url: tabComponent.props.url,
    }))
  }

  get activeTabId() {
    return this.props.activeTab || this.state.currentTabId
  }

  render() {
    const content = this.tabs.find(tab => tab.id === this.activeTabId).render()
    return (
      <React.Fragment>
        <TabRow
          activeTab={this.activeTabId}
          onChange={bind(this.changeTab, this)}
          className={this.props.className}
        >
          {this.props.children}
        </TabRow>
        {content}
      </React.Fragment>
    )
  }

  changeTab(tabId) {
    if (this.props.onChange) {
      this.props.onChange(tabId)
    }
    this.setState({currentTabId: tabId})
  }
}
