import parseDate from 'date-fns/parse'

import Resource from 'app/framework/Resource'
import * as models from 'app/models'

import StoryResource from './Story'
import CompanyResource from 'app/resources/Company'

export default class Storyline extends Resource {
  static endpoint = 'storyline'

  static requiredFields = [
    'id',
    'summary',
    'startDate',
    'endDate',
    'valence',
    'categoryId',
    'litigation',
    'rumor',
    'opinion',
    'articleCount',
    'perspectiveId',
  ]
  static allFields = [
    ...Storyline.requiredFields,
    'companyId',
    'company',
    'companiesMentioned',
    'relatedStorylines',
    'stories',
  ]

  entityItemsForRestItem(restData) {
    // If it's an empty storyline, just ignore it.
    if (
      (restData.stories && !restData.stories.length) ||
      !restData.articleCount
    )
      return []

    const storyline = {
      id: restData.id,
      entityKey: models.Storyline.entityKey,
      data: {
        id: restData.id,
        companyId: restData.company ? restData.company.id : restData.companyId,
        summary: restData.summary,
        startDate: parseDate(restData.startDate),
        endDate: parseDate(restData.endDate),
        valence: restData.valence,
        subfactorId: restData.categoryId || restData.subfactorId,
        isLitigation: restData.litigation,
        isRumor: restData.rumor,
        isOpinion: restData.opinion,
        articleCount: restData.articleCount,
        perspectiveId: restData.perspectiveId,
        companiesMentioned:
          restData.companiesMentioned &&
          restData.companiesMentioned.map(companyInfo => ({
            id: companyInfo.companyId,
            name: companyInfo.displayName,
            count: companyInfo.count,
          })),
      },
    }
    const restRelatedStorylines = restData.relatedStorylines || []
    const relatedStorylines = []
    let storylines = []
    restRelatedStorylines.forEach(relatedStoryline => {
      relatedStorylines.push({
        id: relatedStoryline.id,
        entityKey: models.RelatedStoryline.entityKey,
        data: {
          id: relatedStoryline.id,
          fromStorylineId: relatedStoryline.slFromId,
          toStorylineId: relatedStoryline.slToId,
          distance: relatedStoryline.distance,
        },
      })
      storylines = storylines.concat(
        this.entityItemsForRestItem(relatedStoryline.storyline),
      )
    })
    const stories = new StoryResource().entityItemsForRestData(
      (restData.stories || []).filter(story => story.articleCount),
    )

    const companies = new CompanyResource().entityItemsForRestData(
      restData.company || [],
    )

    return [
      storyline,
      ...relatedStorylines,
      ...storylines,
      ...stories,
      ...companies,
    ]
  }
}
