import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {LoadingMessage} from 'app/common/loading-message'

import * as selectors from './email-server-selectors'

function EsgEmailDevView({component, isLoading, data}) {
  if (isLoading) {
    return <LoadingMessage />
  }
  const Component = component
  return <Component data={data} />
}
EsgEmailDevView.propTypes = {
  component: PropTypes.elementType.isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.object,
}

export default connect(
  createSelector(
    [selectors.getEmailServerLocalDevState],
    state => ({
      isLoading: state.isLoading,
      data: state.data,
    }),
  ),
)(EsgEmailDevView)
