import {pyappRequest} from 'app/api'
import urls from 'app/urls'

export function apiFetchFlagCategories(token) {
  if (MOCK_BASE_API) {
    return new Promise(resolve => {
      resolve({
        body: {
          flaggingData: {
            flaggings: [],
            flagCategories: [],
            updatedCategoryId: null,
          },
        },
      })
    })
  }
  return pyappRequest({
    url: urls.pyapp.api.flaggingData(),
    method: 'GET',
    token,
  })
}

export function apiChangeArticleFlag(token, data) {
  const apiUrl = data.addFlag
    ? urls.pyapp.api.articleFlagAdd()
    : urls.pyapp.api.articleFlagRemove()
  return pyappRequest({
    url: apiUrl,
    method: 'POST',
    token,
    data: data,
  })
}

export function apiAddFlagCategory(token, data) {
  return pyappRequest({
    url: urls.pyapp.api.flagCategoryAdd(),
    method: 'POST',
    token,
    data: data,
  })
}
