import classNames from 'classnames'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import React from 'react'

import StarIcon from 'app/common/icons/star'
import LoadingSpinner from 'app/common/loading-spinner/LoadingSpinner'
import {localComponent} from 'app/utils/redux'

import EmailSettingsModal from './EmailSettingsModal'
import RemovalConfirmationModal from './RemovalConfirmationModal'
import MonitorEntityTooltip from './MonitorEntityTooltip'
import * as actions from './my-entity-star-actions'
import * as constants from './my-entity-star-constants'
import * as selectors from './my-entity-star-selectors'

import * as styles from './MyEntityStar.less'

class MyEntityStar extends React.PureComponent {
  static propTypes = {
    entity: PropTypes.object.isRequired,
    entityType: PropTypes.string.isRequired,
    myEntityIds: PropTypes.arrayOf(PropTypes.number),
    isLoading: PropTypes.bool.isRequired,

    addEntity: PropTypes.func.isRequired,
    removeEntity: PropTypes.func.isRequired,

    emailSettingsModalState: PropTypes.object.isRequired,
    showEmailSettingsModal: PropTypes.func.isRequired,
    hideEmailSettingsModal: PropTypes.func.isRequired,
    confirmEmailSettings: PropTypes.func.isRequired,

    removalConfirmationModalState: PropTypes.object.isRequired,
    showRemovalConfirmationModal: PropTypes.func.isRequired,
    hideRemovalConfirmationModal: PropTypes.func.isRequired,

    className: PropTypes.string,
  }

  render() {
    const {
      entity,
      entityType,
      myEntityIds,

      isLoading,

      emailSettingsModalState,
      hideEmailSettingsModal,

      removalConfirmationModalState,
      hideRemovalConfirmationModal,

      className,
    } = this.props
    if (!myEntityIds) return null

    const isMyEntity = myEntityIds.includes(entity.id)

    return (
      <span
        // Stop event propagation so that clicks on the modal don't propagate up
        //  to the container.
        onClick={event => event.stopPropagation()}
        className={classNames(styles.container, className)}
      >
        {isLoading ? (
          <LoadingSpinner className={styles.loadingSpinner} />
        ) : (
          <MonitorEntityTooltip
            entityType={entityType}
            isMonitored={isMyEntity}
          >
            <StarIcon
              isHighlighted={isMyEntity}
              onClick={bind(this.onClick, this)}
              className={styles.star}
            />
          </MonitorEntityTooltip>
        )}

        {emailSettingsModalState.isVisible && (
          <EmailSettingsModal
            entityNames={[entity.name]}
            entityType={entityType}
            areAlertsEnabled={emailSettingsModalState.areAlertsEnabled}
            frequency={emailSettingsModalState.frequency}
            confirmationStatus={emailSettingsModalState.confirmationStatus}
            onSubmit={bind(this.onConfirmEmailSettings, this)}
            close={hideEmailSettingsModal}
          />
        )}

        {removalConfirmationModalState.isVisible && (
          <RemovalConfirmationModal
            entityName={entity.name}
            entityType={entityType}
            confirmationStatus={
              removalConfirmationModalState.confirmationStatus
            }
            onSubmit={bind(this.onConfirmRemoval, this)}
            close={hideRemovalConfirmationModal}
          />
        )}
      </span>
    )
  }

  onClick(event) {
    event.stopPropagation()
    if (this.props.isLoading) return
    const {
      entity,
      myEntityIds,
      addEntity,
      showRemovalConfirmationModal,
    } = this.props
    if (myEntityIds.includes(entity.id)) {
      showRemovalConfirmationModal()
    } else {
      addEntity({id: entity.id, savedSearchId: entity.savedSearchId})
    }
  }

  onConfirmEmailSettings({frequency}) {
    this.props.confirmEmailSettings({
      savedSearchId: this.props.entity.savedSearchId,
      frequency,
    })
  }

  onConfirmRemoval() {
    const {entity} = this.props
    this.props.removeEntity({
      id: entity.id,
      savedSearchId: entity.savedSearchId,
    })
  }
}

const MyEntityStarConnected = localComponent({
  key: constants.STATE_PROP_NAME,
  initAction: actions.init,
  deinitAction: actions.deinit,
  actions: {
    showEmailSettingsModal: actions.showEmailSettingsModal,
    hideEmailSettingsModal: actions.hideEmailSettingsModal,
    confirmEmailSettings: actions.confirmEmailSettings,
    showRemovalConfirmationModal: actions.showRemovalConfirmationModal,
    hideRemovalConfirmationModal: actions.hideRemovalConfirmationModal,
  },
  bindActionsFromProps: ['addEntity', 'removeEntity'],
  stateSelector: selectors.getByKey,
  mapStateToProps: state => ({
    isLoading: state.isLoading,
    emailSettingsModalState: state.emailSettingsModal,
    removalConfirmationModalState: state.removalConfirmationModal,
  }),
})(MyEntityStar)
export default MyEntityStarConnected
