import {arrayOf, field, foreignKey, hasMany} from 'app/framework/fields'
import Model from 'app/framework/Model'

import Industry from './Industry'

export default class Company extends Model {
  static entityKey = 'companies'

  static fields = {
    name: field(),
    savedSearchId: field(),
    industries: arrayOf(foreignKey(Industry, {required: false})),
    healthData: field({required: false}),
    dailyHealthData: field({required: false}),
    factorDailyHealthData: field({required: false}),
    subfactorDailyHealthData: field({required: false}),
    lastStoryDate: field({required: false}),
    entityType: field({required: false}),
    hasSimCircData: field({required: false}),
  }

  static indexes = ['industries']
}
Industry.fields.companies = hasMany(Company, {required: false})
