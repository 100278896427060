import React, { useEffect, useState } from 'react';

import * as styles from './NumberBox.less';
import classNames from "classnames";
import PropTypes from "prop-types";

/**
 * Should replace all number type of inputs.
 * It mimics the behaviour of a number input, however it is a text input with javascript validation.
 *
 * @param value
 * @param onChange The function to call when a value changes to a valid value.
 * @param min The lower limit of the input.
 * @param max The upper limit of the input.
 * @param className Additional classes to add to the element.
 * @param percentage Display a % sign after the number.
 * @param thousandSeparator Add a thousand separator to the number.
 * @param restProps Additional HTML attributes to add to the element. Remember that the input is a `text` type, not number!
 * @returns {JSX.Element}
 */
export default function NumberBox(
  {
    value,
    onChange,
    min,
    max,
    className,
    percentage,
    thousandSeparator,
    ...restProps
  }) {

  const [currentValue, setCurrentValue] = useState(String(value));

  const addThousandSeparator = (value) => {
    return thousandSeparator ? Number(value).toLocaleString() : value;
  }

  const setCurrentValueWrapper = (value) => {
    if (percentage) {
      setCurrentValue(`${value}%`);
    } else {
      setCurrentValue(addThousandSeparator(value));
    }
  }

  /* If the value is being set to something outside this component, then reflect that change. */
  useEffect(() => {
    setCurrentValueWrapper(value);
  }, [value]);

  const handleOnChange = (event) => {
    event.preventDefault();
    let newValue = event.target.value.replaceAll(/[^-\d]/g, '');

    if (newValue === "" || newValue === "-") {
      setCurrentValue(newValue);
      return;
    }

    if (newValue.search(/^-?[\d]*$/) !== -1) {
      setCurrentValue(addThousandSeparator(newValue));
    }
  }

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  }

  const handleOnBlur = (event) => {
    let newValue = event.target.value.replaceAll(/[^-\d]/g, '');

    if (newValue === "" || newValue === "-") {
      setCurrentValueWrapper(value);
      return;
    }

    if (newValue.search(/^-?[\d]*$/) !== -1) {
      newValue = Number(newValue);
      if (newValue > max) {
        newValue = max;
      } else if (newValue < min) {
        newValue = min;
      }
      onChange(newValue);
      setCurrentValueWrapper(newValue);
    }
  }

  const handleOnFocus = (event) => {
    event.preventDefault();
    if (percentage) {
      const newValue = event.target.value.replace(/%/g, '');
      setCurrentValue(newValue);
    }
  }

  return (
    <input
      type="text"
      value={currentValue}
      className={classNames(styles.numberBox, className)}
      onChange={handleOnChange}
      onKeyDown={handleEnterPress}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      {...restProps}
    />
  )
}

NumberBox.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
}

NumberBox.defaultProps = {
  thousandSeparator: false,
}
