import parseDate from 'date-fns/parse'
import * as R from 'ramda'

import {FACTORS_BY_ID, SUBFACTORS_BY_ID} from 'app/constants'
import Resource from 'app/framework/Resource'
import CompanyModel from 'app/models/Company'
import IndustryResource from 'app/resources/Industry'
import StoryModel from 'app/models/Story'
import StoryResource from 'app/resources/Story'

export default class Company extends Resource {
  static endpoint = 'company'

  static requiredFields = ['id', 'displayName', 'savedsearchId', 'entityType']
  static allFields = [
    ...Company.requiredFields,
    'perspectiveId',
    'articleVolume',
    'positive',
    'neutral',
    'negative',
    'healthBadge',
    'industries',
    'overallDailyHealthDataPoints',
    'factorDailyHealthData',
    'categoryDailyHealthData',
    'hasSimCircData',
  ]

  entityItemsForRestItem(restData) {
    const companyData = {
      id: restData.id,
      name:
        restData.entityType === 'INDUSTRY'
          ? `Other ${restData.displayName || restData.name} News`
          : restData.displayName || restData.name,
      savedSearchId: restData.savedsearchId,
      entityType: restData.entityType,
      hasSimCircData: restData.hasSimCircData,
    }

    companyData.healthData = restData.healthBadge && {
      articleVolume: restData.healthBadge.articleVolume,
      positiveVolume: restData.healthBadge.positive,
      negativeVolume: restData.healthBadge.negative,
      neutralVolume: restData.healthBadge.neutral,
      healthScore: restData.healthBadge.healthScore,
      // The key is the factor name, the value is the volume data
      factors: R.pipe(
        R.map(factor => [
          FACTORS_BY_ID[factor.factorId],
          {
            articleVolume: factor.volume,
            positiveVolume: factor.positive,
            negativeVolume: factor.negative,
            neutralVolume: factor.neutral,
            healthScore: factor.healthScore,
          },
        ]),
        R.fromPairs,
      )(restData.healthBadge.factors),
      // The key is the factor name, the value is the volume data
      subfactors: R.pipe(
        R.map(subfactor => [
          SUBFACTORS_BY_ID[subfactor.categoryId],
          {
            articleVolume: subfactor.volume,
            positiveVolume: subfactor.positive,
            negativeVolume: subfactor.negative,
            neutralVolume: subfactor.neutral,
            healthScore: subfactor.healthScore,
          },
        ]),
        R.fromPairs,
      )(restData.healthBadge.categories),
    }

    let industries = []
    if (restData.industries) {
      companyData.industriesIds = restData.industries.map(R.prop('id'))
      industries = new IndustryResource().entityItemsForRestData(
        restData.industries,
      )
    }

    const getDataPoints = R.pipe(
      R.map(dataPoint => ({
        // Parse the date, and ignore fields we don't care about
        date: parseDate(dataPoint.storyDate),
        healthScore: dataPoint.healthScore,
        healthScoreChange: dataPoint.healthScoreChange,
        positiveChange: dataPoint.todaysPositiveChange,
        negativeChange: dataPoint.todaysNegativeChange,
        todaysVolume: dataPoint.todaysVolume,
      })),
    )
    const dailyHealthDataTransformer = type =>
      R.pipe(
        R.groupBy(data =>
          type === 'factors'
            ? FACTORS_BY_ID[data.factorId]
            : SUBFACTORS_BY_ID[data.categoryId],
        ),
        R.map(
          R.pipe(
            R.nth(0),
            R.prop('dataPoints'),
            getDataPoints,
          ),
        ),
      )
    if (restData.overallDailyHealthDataPoints) {
      companyData.dailyHealthData = getDataPoints(
        restData.overallDailyHealthDataPoints,
      )
    }
    if (restData.factorDailyHealthData) {
      companyData.factorDailyHealthData = dailyHealthDataTransformer('factors')(
        restData.factorDailyHealthData,
      )
    }
    if (restData.categoryDailyHealthData) {
      companyData.subfactorDailyHealthData = dailyHealthDataTransformer(
        'categories',
      )(restData.categoryDailyHealthData)
    }

    const stories = restData.topStories
      ? new StoryResource().entityItemsForRestData(restData.topStories)
      : []
    companyData.topStoriesIds = stories
      .filter(R.whereEq({entityKey: StoryModel.entityKey}))
      .map(R.prop('id'))

    if (restData.lastStoryDate) {
      companyData.lastStoryDate = parseDate(restData.lastStoryDate)
    }

    return [
      {
        id: restData.id,
        entityKey: CompanyModel.entityKey,
        data: companyData,
      },
      ...stories,
      ...industries,
    ]
  }
}
