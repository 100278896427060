import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import Button from 'app/common/Button'
import Columns, {Column} from 'app/common/Columns'
import {LoadingMessage} from 'app/common/loading-message'
import Link from 'app/common/NavigationLink'
import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import Company from 'app/models/Company'
import Story from 'app/models/Story'
import * as global from 'app/global'
import {actions as addModalActions} from 'app/reusable/entities/add-modal'
import urls from 'app/urls'

import HealthChangesTable from './DashboardHealthChangesTable'
import NoChanges from './DashboardNoChanges'
import TopStories from './DashboardTopStories'
import * as dashboardSelectors from './dashboard-selectors'
import {
  calculateRecentSubfactorScoreChanges,
  isHealthChangeSignificant,
} from './dashboard-utils'

import * as styles from './Dashboard.less'

const NoCompanies = () => (
  <div className={styles.noCompanies}>
    <p className={styles.message}>
      You aren't monitoring any companies yet. Go to the{' '}
      <Link href={urls.companies()}>Companies</Link> page to start monitoring{' '}
      {process.env.PRODUCT_NAME}-enabled companies.
    </p>
  </div>
)

const MoreCompanies = ({companyCount}) => {
  if (!companyCount) return null
  return (
    <div className={styles.moreCompanies}>
      <div className={styles.label}>
        {companyCount} Other {companyCount > 1 ? 'Companies' : 'Company'} with
        No Health Changes
      </div>

      <div className={styles.viewMore}>
        <Link href={urls.companies()}>View All of My Companies &gt;&gt;</Link>
      </div>
    </div>
  )
}
MoreCompanies.propTypes = {
  companyCount: PropTypes.number.isRequired,
}

const Dashboard = ({
  myCompanies,
  topPositiveStories,
  topNegativeStories,
  showAddModal,
}) => {
  if (!myCompanies || !topPositiveStories || !topNegativeStories) {
    return <LoadingMessage />
  }

  const companiesWithChanges = myCompanies.filter(
    company =>
      company.subfactorDailyHealthData &&
      calculateRecentSubfactorScoreChanges(company).filter(({change}) =>
        isHealthChangeSignificant(change),
      ).length,
  )
  const moreCompaniesCount = myCompanies.length - companiesWithChanges.length

  return (
    <div className={classNames(styles.page, 'dashboard')}>
      <div className={styles.mainHeading}>
        <h1 className={styles.title}>
          Welcome to {process.env.BRAND_NAME} {process.env.PRODUCT_NAME}
        </h1>

        <div className={styles.addButtons}>
          <Button
            label="Add Company"
            onClick={() => showAddModal({entityType: 'company'})}
            className={styles.button}
          />
          <Button
            label="Add Industry"
            onClick={() => showAddModal({entityType: 'industry'})}
            className={styles.button}
          />
        </div>
      </div>

      <h2 className={styles.tableHeading}>
        My Companies - Recent Health Score Changes
      </h2>

      {myCompanies.length ? (
        <React.Fragment>
          {companiesWithChanges.length ? (
            <div className={styles.tableContainer}>
              <HealthChangesTable companies={companiesWithChanges} />
              <MoreCompanies companyCount={moreCompaniesCount} />
            </div>
          ) : (
            <NoChanges myCompanies={myCompanies} />
          )}

          <Columns>
            <Column>
              <h2 className={styles.topStoriesHeading}>
                Today's Top Negative Stories
              </h2>

              <TopStories
                stories={topNegativeStories}
                valenceLabel="Negative"
              />
            </Column>

            <Column>
              <h2 className={styles.topStoriesHeading}>
                Today's Top Positive Stories
              </h2>

              <TopStories
                stories={topPositiveStories}
                valenceLabel="Positive"
              />
            </Column>
          </Columns>
        </React.Fragment>
      ) : (
        <NoCompanies />
      )}
    </div>
  )
}
Dashboard.propTypes = {
  myCompanies: PropTypes.arrayOf(PropTypes.object.isRequired),
  topPositiveStories: PropTypes.arrayOf(PropTypes.object.isRequired),
  topNegativeStories: PropTypes.arrayOf(PropTypes.object.isRequired),
}
export default connect(
  createSelector(
    [
      getEntities,
      global.selectors.getMyCompanyIds,
      dashboardSelectors.getState,
    ],
    (entities, myCompanyIds, dashboard) => {
      const {topNegativeStoryIds, topPositiveStoryIds} = dashboard
      const orm = Orm.withEntities(entities)
      return {
        myCompanies: myCompanyIds && orm.getByIds(Company, myCompanyIds),
        topPositiveStories:
          topPositiveStoryIds && orm.getByIds(Story, topPositiveStoryIds),
        topNegativeStories:
          topNegativeStoryIds && orm.getByIds(Story, topNegativeStoryIds),
      }
    },
  ),
  {
    showAddModal: addModalActions.show,
  },
)(Dashboard)
