import invariant from 'invariant'

export default class Resource {
  constructor() {
    const {endpoint} = this.constructor
    invariant(
      endpoint,
      "A Resource must have a static property 'endpoint' that contains the URL endpoint string (with no slash at the beginning).",
    )
  }

  normalizedData(restData) {
    return {
      entities: this.normalizedEntities(restData),
      result: this.resultForRestData(restData),
    }
  }

  normalizedEntities(restData) {
    const entityItems = this.entityItemsForRestData(restData)
    const entities = {}
    entityItems.forEach(item => {
      const {entityKey, id, data} = item
      if (!entities[entityKey]) {
        entities[entityKey] = {}
      }
      entities[entityKey][id] = data
    })
    return entities
  }

  resultForRestData(restData) {
    if (Array.isArray(restData)) {
      return restData.map(obj => obj.id)
    }
    return restData.id
  }

  entityItemsForRestData(restData) {
    if (!Array.isArray(restData)) {
      restData = [restData]
    }
    return restData.reduce((entities, item) => {
      return entities.concat(this.entityItemsForRestItem(item))
    }, [])
  }

  entityItemsForRestItem(obj) {
    throw new Error(
      'A Resource must implement the entityItemsForRestItem() method.',
    )
  }
}
