import dateFns from 'date-fns'
import {put, select} from 'redux-saga/effects'

import {
  DEFAULT_TIME_FRAME_DAYS,
  ORDERED_SUBFACTORS_BY_FACTOR,
  SUBFACTOR_IDS_BY_SUBFACTOR,
  SYSTEM_DATE_FORMAT,
  today,
  VALENCES,
} from 'app/constants'
import Orm from 'app/framework/Orm'
import {getEntities} from 'app/framework/entities-selectors'
import * as global from 'app/global'
import {getMyCompanyIds} from 'app/global/global-selectors'
import * as resources from 'app/resources'
import {entitiesApiRequest, withResponseHandler} from 'app/utils/sagas'
import {
  GEOGRAPHY_FIELD_NAME,
  TIME_FRAME_HEADER,
} from 'app/overviews/company/company-overview-constants'
import ArticleResource from 'app/resources/Article'
import { Company, Industry, LitigationNumberSummary, 
  LitigationTimeSeries, LitigationStory } from 'app/resources'
import {quarterToDateRange} from 'app/utils/quarters'
import * as flaggingActions from 'app/flag-article-modal/flag-article-modal-actions'
import LitigationSavedSearch from "../resources/LitigationSavedSearch";
import { ASPECT_FILTER_TYPE } from "../litigation/litigations-constants";
import MyCompanyLitigation from '../resources/MyCompanyLitigation'

const escape = val => val.replace(/['"\\\*]/g, '\\$&')
const formatDate = date => dateFns.format(date, SYSTEM_DATE_FORMAT)

export function* fetchMyCompanies({
  fields = Company.requiredFields,
  ...restOptions
} = {}) {
  const query = {
    [GEOGRAPHY_FIELD_NAME]: 'eq.0',
    entityType: 'neq.INDUSTRY',
    ...(restOptions.query || {}),
  }
  const request = yield* entitiesApiRequest(resources.MyCompany, {
    fields,
    query,
    ...restOptions,
  })
  return yield* withResponseHandler(request, function*(error, response) {
    if (error) return
    yield put(global.actions.setMyCompanyIds(response.result))
    yield put(flaggingActions.fetchFlagCategories())
  })
}

export function* fetchMyIndustries({
  fields = Industry.requiredFields,
  ...restOptions
} = {}) {
  const query = {
    [GEOGRAPHY_FIELD_NAME]: 'eq.0',
    ...(restOptions.query || {}),
  }
  const request = yield* entitiesApiRequest(resources.MyIndustry, {
    fields,
    query,
    ...restOptions,
  })
  return yield* withResponseHandler(request, function*(error, response) {
    if (error) return
    yield put(global.actions.setMyIndustryIds(response.result))
  })
}

const getShouldLoadMyCompanies = state => {
  const orm = Orm.withEntities(getEntities(state))
  const companyIds = getMyCompanyIds(state)
  return (
    !companyIds ||
    orm.getByIds(Company, companyIds).length !== companyIds.length
  )
}

/**
 * Fetches the "my companies" data only if it has not already been fetched.
 * Returns the request if one was made, or an empty resolved promise if not.
 */
export function* fetchMyCompaniesIfNeeded(options) {
  const shouldLoadMyCompanies = yield select(getShouldLoadMyCompanies)
  let myCompaniesRequest = new Promise(resolve => resolve())
  if (shouldLoadMyCompanies) {
    myCompaniesRequest = yield* fetchMyCompanies(options)
  }
  return myCompaniesRequest
}

export function* fetchCompanies({
  companyId,
  companyIds,
  excludedCompanyIds,
  industryIds,
  savedSearchId,
  nameContains,
  nameStartsWith,
  entityType,
  excludeIndustries = false,
  timeFrameDays,
  geography = 0,
  fields,
  query,
  ...restOptions
}) {
  query = {
    [GEOGRAPHY_FIELD_NAME]: `eq.${geography}`,
    ...(query || {}),
  }
  const headers = {
    ...(restOptions.headers || {}),
  }

  if (companyId) {
    query.id = `eq.${companyId}`
  }
  if (companyIds) {
    if (excludedCompanyIds) {
      companyIds = difference(companyIds, excludedCompanyIds)
    }
    query.id = `in.(${companyIds.join(',')})`
  } else if (excludedCompanyIds) {
    query.id = `not.in.(${excludedCompanyIds.join(',')})`
  }

  if (industryIds) {
    query.industryIds = `cs.{${industryIds.join(',')}}`
  }

  if (savedSearchId) {
    query.savedsearchId = `eq.${savedSearchId}`
  }
  if (nameContains) {
    nameContains = escape(nameContains)
    query.or = `(display_name.ilike."${nameContains}*",display_name.ilike."* ${nameContains}*")`
  }
  if (nameStartsWith) {
    query.displayName = `ilike.${nameStartsWith}*`
  }
  if (excludeIndustries) {
    query.entityType = 'neq.INDUSTRY'
  } else if (entityType) {
    query.entityType = `eq.${entityType}`
  }
  if (timeFrameDays) {
    headers[TIME_FRAME_HEADER] = timeFrameDays
  }
  return yield* entitiesApiRequest(resources.Company, {
    query,
    headers,
    fields,
    ...restOptions,
  })
}

export function* fetchIndustries({
  industryId,
  timeFrameDays,
  geography = 0,
  fields = resources.Industry.requiredFields,
  ...restOptions
} = {}) {
  const query = {
    [GEOGRAPHY_FIELD_NAME]: `eq.${geography}`,
    ...(restOptions.query || {}),
  }
  const headers = {
    ...(restOptions.headers || {}),
  }
  if (industryId) {
    query.id = `eq.${industryId}`
  }
  if (timeFrameDays) {
    headers[TIME_FRAME_HEADER] = timeFrameDays
  }
  return yield* entitiesApiRequest(resources.Industry, {query, headers, fields})
}

export function* fetchCompanyStorylines({
  companyId,
  timeFrameDays = DEFAULT_TIME_FRAME_DAYS,
  geography = 0,
  ...restOptions
}) {
  const startDate = dateFns.subDays(today, timeFrameDays)
  const query = {
    companyId: `eq.${companyId}`,
    endDate: `gte.${formatDate(startDate)}`,
    perspectiveId: `eq.${geography}`,
  }
  return yield* entitiesApiRequest(resources.Storyline, {
    query,
    ...restOptions,
  })
}

export function* fetchStoryline({id, fields}) {
  return yield* entitiesApiRequest(resources.Storyline, {
    query: {id: `eq.${id}`},
    fields,
  })
}

export function* fetchStories({
  storyIds,
  storylineId,
  companyId,
  companyIds,
  industryId,
  valence,
  valences,
  startDate,
  endDate,
  quarter,
  isLargest,
  isMostRecent,
  isIndustry,
  geography = 0,
  factor,
  subfactor,
  subfactors,
  ignoreSubfactors,
  orderBy,
  limit,
  fields,
}) {
  const query = {}

  // If we're looking for specific stories or a specific storyline, we don't
  // want to filter by geography.
  if (!storyIds && !storylineId) {
    query.perspectiveId = `eq.${geography}`
  }

  if (storylineId) {
    query.storylineId = `eq.${storylineId}`
  }

  if (quarter) {
    const [start, end] = quarterToDateRange(quarter)
    query.and = `(story_date.gte.${formatDate(
      start,
    )},story_date.lt.${formatDate(end)})`
  } else if (startDate && endDate) {
    query.and = `(story_date.gte.${formatDate(
      startDate,
    )},story_date.lt.${formatDate(endDate)})`
  } else if (startDate) {
    query.storyDate = `gte.${formatDate(startDate)}`
  } else if (endDate) {
    query.storyDate = `lt.${formatDate(endDate)}`
  }

  if (isLargest) {
    query.isLargestStory = 'eq.true'
  }
  if (isMostRecent) {
    query.isMostRecentStory = 'eq.true'
  }
  if (isIndustry) {
    query.isIndustryStory = 'eq.true'
  }

  if (valence) {
    query.valence = `eq.${valence}`
  } else if (valences && valences.length !== Object.keys(VALENCES).length) {
    query.valence = `in.(${valences.join(',')})`
  }
  if (subfactors) {
    query.categoryId = `in.(${subfactors
      .map(subfactor => SUBFACTOR_IDS_BY_SUBFACTOR[subfactor])
      .join(',')})`
  } else if (subfactor) {
    query.categoryId = `eq.${SUBFACTOR_IDS_BY_SUBFACTOR[subfactor]}`
  } else if (factor) {
    const subfactors = ORDERED_SUBFACTORS_BY_FACTOR[factor]
    const subfactorIds = subfactors.map(
      subfactor => SUBFACTOR_IDS_BY_SUBFACTOR[subfactor],
    )
    query.categoryId = `in.(${subfactorIds.join(',')})`
  }

  if (storyIds) {
    query.id = `in.(${storyIds.join(',')})`
  } else if (companyId) {
    query.companyId = `eq.${companyId}`
  } else if (companyIds) {
    query.companyId = `in.(${companyIds.join(',')})`
  }

  if (industryId) {
    query.industryIds = `cs.{${industryId}}`
  }

  if (ignoreSubfactors && !subfactor && !subfactors) {
    if (factor) {
      const subfactors = ORDERED_SUBFACTORS_BY_FACTOR[factor]
      const subfactorIds = subfactors
        .filter(subfactor => !ignoreSubfactors.includes(subfactor))
        .map(subfactor => SUBFACTOR_IDS_BY_SUBFACTOR[subfactor])
      query.categoryId = `in.(${subfactorIds.join(',')})`
    } else {
      const subfactorIds = ignoreSubfactors.map(
        subfactor => SUBFACTOR_IDS_BY_SUBFACTOR[subfactor],
      )
      query.categoryId = `not.in.(${subfactorIds.join(',')})`
    }
  }

  return yield* entitiesApiRequest(resources.Story, {
    query,
    orderBy,
    limit,
    fields,
  })
}

export function* fetchArticles({
  articleId,
  companyId,
  contentDirectorId,
  storyIds,
  geography = 0,
  ...restOptions
}) {
  const query = {
    // We always have to pass a perspectiveId, otherwise we will get back a copy
    // of each article for each perspective.
    perspectiveId: `eq.${geography}`,
  }
  if (articleId) {
    query.id = `eq.${articleId}`
  }
  if (companyId) {
    query.companyId = `eq.${companyId}`
  }
  if (contentDirectorId) {
    query.contentdirectorId = `eq.${contentDirectorId}`
  }
  if (storyIds) {
    query.storyId = `in.(${storyIds.join(',')})`
  }
  return yield* entitiesApiRequest(ArticleResource, {
    query,
    ...restOptions,
  })
}

export function* fetchLitigationNumberSummary(industries = null) {
  return yield* entitiesApiRequest(LitigationNumberSummary, {
    method: "POST", data: {
      industries,
      litCompanyCountMin: 0,
      litCompanyCountMax: "+inf",
      litCompanyPropMin: 0,
      litCompanyPropMax: 1
    }
  });
}

export function* fetchLitigationTimeSeries({
  industries = [],
  factors = null,
  subfactors = null,
  valences = null,
  storyCountMin = 0,
  storyCountMax = 10000,
  aspect= ASPECT_FILTER_TYPE.ALL,
  offset = 0,
} = {}) {
  return yield* entitiesApiRequest(LitigationTimeSeries, {
    method: "POST", data: {
      industries,
      factors,
      subfactors,
      valences,
      storyCountMin,
      storyCountMax,
      aspect,
    },
    offset,
  });
}

export function* fetchLitigationStories({
  industries = [],
  companies = null,
  factors = null,
  subfactors = null,
  valences = null,
  aspect = ASPECT_FILTER_TYPE.ALL,
  limit = 20,
  offset = 0,
} = {}) {
  return yield* entitiesApiRequest(LitigationStory, {
    method: "POST", data: {
      industries,
      companies,
      factors,
      subfactors,
      valences,
      aspect,
    },
    limit,
    offset,
    headers: {
      Prefer: 'count=exact',
    }
  });
}

export function* fetchLitigationSavedSearches({
  query = null,
  limit = 10,
  offset = 0,
  orderBy = null,
} = {}) {
  return yield* entitiesApiRequest(LitigationSavedSearch, {
    method: "GET",
    query,
    limit,
    offset,
    orderBy,
    headers: {
      Prefer: 'count=exact',
    },
  });
}

export function* fetchMyCompanyLitigation() {
  return yield* entitiesApiRequest(MyCompanyLitigation, {
    method: "GET"
  });
}
