import classNames from 'classnames'
import is from 'is'
import React from 'react'
import {Portal} from 'react-portal'

import * as styles from './styles.less'

export default function InfoTooltip({
  left,
  right,
  top,
  children,
  className,
  contentClassName,
  ...restProps
}) {
  const style = {top}
  if (is.undefined(right) || left <= window.innerWidth / 2) {
    style.left = left
  } else {
    style.right = document.body.clientWidth - right
  }
  return (
    <Portal>
      <div
        className={classNames(styles.infoTooltip, className)}
        style={style}
        {...restProps}
      >
        <div className={classNames(styles.content, contentClassName)}>
          {children}
        </div>
      </div>
    </Portal>
  )
}
