import {createAction} from 'redux-actions'

const action = name => createAction(`add-companies-modal/${name}`)

export const show = action('SHOW')
export const hide = action('HIDE')
export const setSelectedEntityIds = action('SET_SELECTED_ENTITY_IDS')
export const addEntities = action('ADD_ENTITIES')
export const setIsLoading = action('SET_IS_LOADING')
export const setIsAdded = action('SET_IS_ADDED')
export const setIsFinished = action('SET_IS_FINISHED')
export const setError = action('SET_ERROR')
export const confirmEmailSettings = action('CONFIRM_EMAIL_SETTINGS')
