import React, { useState } from "react";
import classNames from 'classnames'
import "./styles.less";

const ExpandableMenu = ({
  title,
  subTitle,
  subMenus,
  badgeCount,
  tagText,
  leftIconClassName,
  url,
  titleClassName,
  onClick,
  onTitleClick,
  maxSubMenuToShow,
  backgroundColor = "#fff",
  activeBackgroundColor = "#e6e6e6",
  hoverBackgroundColor = "#e6e6e629",
  children,
  isActive = false,
  onNavigation
  }) => {
  const menuHeight = 56;
  const isExpandable = subMenus && subMenus.length > 0 || children;

  const [isExpanded, setIsExpanded] = useState(false);
  const [isMouseHovering, setIsMouseHovering] = useState(false)
  const [showAllSubMenus, setShowAllSubMenus] = useState(false);

  const getSubMenuCount = () => {
    if (isExpandable && subMenus.length > maxSubMenuToShow) {
      return maxSubMenuToShow + 1
    }
    else if (isExpandable && subMenus) {
      subMenus.length
    }
    else {
      return 0
    }
  }
  const getSubMenusContainerHeight = () => {
    if (maxSubMenuToShow) { return `${menuHeight * getSubMenuCount()}px`; }
    else { return null }
  }
  const removeLastSlash = (_url) => _url[_url.length - 1] === '/' ? _url.slice(0, _url.length - 1) : _url
  const getFullURl = () => {
    if (url && url.includes('http')) {
      return removeLastSlash(url)
    }
    else if (url) {
      return window.location.origin + removeLastSlash(url)
    }
    else { return null }
  }
  const getMenuBackground = () => {
    const isLinkActive = url ? removeLastSlash(window.location.href).toLowerCase() == getFullURl().toLowerCase() : null

    if (isExpanded || isActive || isLinkActive) { return activeBackgroundColor }
    else if (isMouseHovering) { return hoverBackgroundColor }
    else { return backgroundColor }
  }
  const getSubMenus = () => {
    if (showAllSubMenus) {
      return subMenus;
    } else {
      return subMenus.slice(0, maxSubMenuToShow);
    }
  };

  const onMenuClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    if (isExpandable) {
      setIsExpanded(!isExpanded);
    } else if (url) {
      onNavigation && onNavigation()
      window.location = getFullURl()
    }
  };
  const onMenuTitleClick = () => {
    if (onTitleClick) { 
      onNavigation && onNavigation()
      onTitleClick()
     }
    else { onMenuClick() }
  }
  const onMenuMouseEnter = () => {
    setIsMouseHovering(true);
  };
  const onMenuMouseLeave = () => {
    setIsMouseHovering(false);
  };

  const shouldShowAllSubMenuComponent =
    isExpandable && subMenus.length > maxSubMenuToShow && !showAllSubMenus;
  
    const showAllSubMenuComponent = (
      subMenus && <ExpandableMenu
      title={`Show All (${subMenus && subMenus.length})`}
      titleClassName='menuShowAll'
      onClick={() => {
        setShowAllSubMenus(true);
      }}
    />
  );

  return (
    <div className="responsiveMenu">
      <div
        className="menu"
        style={{
          background: getMenuBackground(),
          height: `${menuHeight}px`
        }}
        onMouseEnter={onMenuMouseEnter}
        onMouseLeave={onMenuMouseLeave}
        onClick={onMenuClick}
      >
        {leftIconClassName ? <div className="leftIconContainer"><div className={leftIconClassName} /></div> : null}
        <div className="menuLabelContainer" onClick={onMenuTitleClick}>
          <a href={url} className="anchorStyle">
            <div className="menuTagContainer">
              <div className={classNames("menuTitle", titleClassName)}>{title}</div>
              {badgeCount ? <div className="badge"> {badgeCount} </div> : null}
              {tagText ? <div className="tag">{tagText}</div> : null}
            </div>
            {subTitle ? <div className="menuSubtitle">{subTitle}</div> : null}
          </a>
        </div>
        {isExpandable ? (
          <div className="rightIconContainer">
            <div className={`${isExpanded ? "arrow-down" : "arrow-right"}`}></div>
          </div>
        ) : null}
      </div>
      {isExpanded && (isExpandable || children) ? (
        <>
          {children}
          <div
            className="subMenuContainer"
            style={maxSubMenuToShow ? { ...{ height: getSubMenusContainerHeight() } } : null}
          >
            {getSubMenus().map((item, index) => {
              return (
                <ExpandableMenu
                  key={`responsive-menu-${title}-${item.title}-${index}`}
                  title={item.title}
                  subTitle={item.subTitle}
                  badgeCount={item.badgeCount}
                  tagText={item.tagText}
                  leftIcon={item.leftIcon}
                  maxSubMenuToShow={item.maxSubMenuToShow}
                  subMenus={item.subMenus}
                  url={item.url}
                  leftIconClassName={item.leftIconClassName}
                  children={item.children}
                  onClick={item.onClick}
                  onTitleClick={item.onTitleClick}
                  isActive={item.isActive}
                  onNavigation={onNavigation}
                />
              );
            })}
            {shouldShowAllSubMenuComponent ? showAllSubMenuComponent : null}
          </div>
        </>
      ) : null}

    </div>
  );
};

export default ExpandableMenu;
