import React from 'react'
import * as styles from './style.less'

export function CustomTooltip({
  active,
  payload,
  coordinate,
  tooltipCollector,
  tooltipEvent,
  onActiveDotHoverHandle,
  companies,
}) {
  const getCard = (company, nearestPoint) => (
    <div>
      <div className={styles.graphTooltip}>
        <h4>
          <div className={styles.activeDot} style={{ backgroundColor: nearestPoint.color }}/>
          {company.companyName}
        </h4>
        Story count: {nearestPoint.value}<br/>
        {nearestPoint.value > 0 &&
          <>
            Story headlines:<br/>
            {nearestPoint.headline}<br/>
          </>}
      </div>
    </div>
  )

  const listView = (nearestPoints = []) =>
    nearestPoints.map((nearestPoint, index, points) => {
      const company = companies.find(company => company.id === nearestPoint.name)
      return (
        <div
          key={company.id}
          style={{ cursor: 'pointer' }}
        >
          {getCard(company, nearestPoint)}
          {points.length - 1 !== index &&
            <hr className={styles.separator}/>}
        </div>
      )
    })

  if (payload === null) return null
  if (active) {
    const { min, max } = tooltipCollector.maxAndMin()
    const threshold = min.value / 150
    const deltaY = max.y - min.y
    const deltaValue = max.value - min.value
    const cursorValue = min.value - deltaValue * ((min.y - coordinate.y) / deltaY)
    const points = payload.map(p => {
      const { color, stroke, dataKey, fill, name, payload } = p
      return {
        color,
        stroke,
        dataKey,
        fill,
        name,
        value: payload[dataKey],
        headline: payload.headlines[dataKey],
      }
    }).filter(point => point.value > 0)
    const nearestPointIndexes = points.reduce((acc, curr, index) => {
      const deltaValue = Math.abs(curr.value - cursorValue)
      if (acc.length === 0) return (deltaValue < threshold) ? [{ index, deltaValue }] : []
      if (Math.abs(deltaValue - acc[0].deltaValue) < threshold) return acc.concat([{ index, deltaValue }])
      return acc
    }, [])

    if (nearestPointIndexes.length === 0) return null
    const nearestPoints = nearestPointIndexes.map(({ index }) => points[index])
    const offset = nearestPoints.length > 1 ? 180 : 80
    return (
      <div
        className={styles.customTooltipContainer}
        style={{
          top: `${tooltipEvent.pageY - offset}px`,
          left: `${tooltipEvent.pageX}px`,
        }}
        onMouseEnter={() => {
          onActiveDotHoverHandle({}, true, null, 'tooltip')
        }}
        onMouseLeave={() => {
          onActiveDotHoverHandle({}, false, null, 'tooltip')
        }}
        onMouseMove={(e) => e.stopPropagation()}
      >
        {listView(nearestPoints)}
      </div>
    )
  } else {
    return null
  }
}
