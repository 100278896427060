import {all, put, select, takeEvery} from 'redux-saga/effects'

import {fetchMyCompanies} from 'app/api/api-saga-helpers'
import {actions as commonActions} from 'app/reusable/entities/my-entity-star'
import {
  addSavedSearch,
  removeSavedSearch,
} from 'app/reusable/entities/my-entity-star/my-entity-star-saga'
import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import Company from 'app/models/Company'

import * as actions from './my-company-star-actions'

function* addCompany(action) {
  const {
    key,
    value: {id, savedSearchId},
  } = action.payload

  yield put(commonActions.setIsLoading({key: key, value: true}))
  yield yield* addSavedSearch(savedSearchId)
  yield yield* fetchMyCompanies()
  yield put(commonActions.setIsLoading({key: key, value: false}))

  const company = yield select(state => {
    const orm = Orm.withEntities(getEntities(state))
    return orm.getById(Company, id)
  })
  yield put(
    commonActions.showEmailSettingsModal({
      key,
      value: {id: savedSearchId, name: company.name},
    }),
  )
}

function* removeCompany(action) {
  yield put(commonActions.setRemovalConfirming({key: action.payload.key}))
  yield yield* removeSavedSearch(action.payload.value.savedSearchId)
  yield yield* fetchMyCompanies()
  yield put(commonActions.setRemovalConfirmed({key: action.payload.key}))
}

export default function* myCompanyStarSaga() {
  yield all([
    takeEvery(actions.addCompany, addCompany),
    takeEvery(actions.removeCompany, removeCompany),
  ])
}
