import {handleActions} from 'redux-actions'

import * as actions from './share-article-modal-actions'

const getInitialState = () => ({
  isVisible: false,
  isLoading: false,
  articleId: null,
  shouldShowBack: false,
})

export default handleActions(
  {
    [actions.showModal]: (state, action) => ({
      ...state,
      isVisible: true,
      articleId: action.payload.articleId,
      shouldShowBack: action.payload.shouldShowBack,
    }),
    [actions.hideModal]: getInitialState,
  },
  getInitialState(),
)
