import React from 'react'

import {IndustryOverview} from 'app/overviews/industry'

import styles from './IndustryOverviewPortal.less'

// TODO: Do we even need this? Wrapper with custom styling maybe?
const IndustryOverviewPortal = () => (
  <div className={styles.wrapper}>
    <IndustryOverview />
  </div>
)
export default IndustryOverviewPortal
