import Promise from 'bluebird'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {append, fromPairs, map, pipe, prop, remove, uniq, whereEq} from 'ramda'
import React from 'react'
import {connect} from 'react-redux'

import {restApiCall} from 'app/api'
import * as entityActions from 'app/framework/entities-actions'
import {Company, Industry} from 'app/models'
import {getCurrentToken} from 'app/jwt'
import {
  Company as CompanyResource,
  Industry as IndustryResource,
} from 'app/resources'

import * as styles from './EntityInput.less'

/**
 * Returns a promise that resolves to a list of up to 10 companies matching the
 * search term `name`, and excluding any companies with saved search IDs in
 * `excludeIds`.
 */
function fetchCompanyAutocomplete({entityType, name, excludeIds}) {
  const token = getCurrentToken()
  const query = {
    perspectiveId: 'eq.0',
    order: 'display_name.asc',
  }
  if (entityType === 'company') {
    query.entityType = 'neq.INDUSTRY'
  }
  if (excludeIds) {
    query.id = `not.in.(${excludeIds.join(',')})`
  }
  const Model = entityType === 'company' ? Company : Industry
  const Resource = entityType === 'company' ? CompanyResource : IndustryResource
  return Promise.all([
    // The first API call gets all companies where the name starts with the
    // search term exactly; the second one looks for a word that starts with the
    // term. The reason we do this is so that companies that start with the
    // search term show up before those that only have the term somewhere in the
    // name.
    restApiCall(Resource, {
      method: 'GET',
      query: {...query, displayName: `ilike.${name}%`},
      token,
      fields: Resource.requiredFields,
      limit: 10,
    }),
    restApiCall(Resource, {
      method: 'GET',
      query: {...query, displayName: `ilike.% ${name}%`},
      token,
      fields: Resource.requiredFields,
      limit: 10,
    }),
  ]).then(([response1, response2]) => {
    return uniq([
      ...response1.result.map(id => response1.entities[Model.entityKey][id]),
      ...response2.result.map(id => response2.entities[Model.entityKey][id]),
    ])
  })
}

class EntityInput extends React.PureComponent {
  static propTypes = {
    entityType: PropTypes.string,
    onChange: PropTypes.func,
    selectedValues: PropTypes.array,
    maxValues: PropTypes.number,

    // Actions
    updateCompanies: PropTypes.func.isRequired,

    // HTML attributes
    className: PropTypes.string,
    optionClassName: PropTypes.string,
  }

  static defaultProps = {
    entityType: 'company',
  }

  state = {
    textInputValue: '',
    values: this.props.selectedValues || [],

    // The currently highlighted option (either with the mouse or the arrow
    // keys).
    highlightedOption: null,

    // The options to show in the autocomplete menu.
    options: [],

    // Whether the menu can be shown. Note that this won't do anything if
    // `options` is empty.
    canShowMenu: false,

    autocompleteResponse: null,
  }

  input = null
  container = null

  // React methods

  render() {
    const {highlightedOption, textInputValue} = this.state

    const values = this.state.values.map((value, index) => {
      const removeValue = () => this.removeValueAtIndex(index)
      return (
        <SelectedOption option={value} onRemove={removeValue} key={index} />
      )
    })

    const options = this.getVisibleOptions().map(option => {
      const isHighlighted = !!(
        highlightedOption && option.value === highlightedOption.value
      )

      return (
        <InputOption
          option={option}
          input={textInputValue}
          isHighlighted={isHighlighted}
          onClick={this.selectOption.bind(this)}
          onHover={this.highlightOption.bind(this)}
          key={option.value}
          className={this.props.optionClassName}
        />
      )
    })
    const optionsDisplay =
      this.state.options.length && this.state.canShowMenu ? 'block' : 'none'

    const inputWidth = Math.max(5, this.state.textInputValue.length) + 'em'

    return (
      <div
        className={classNames(
          styles.companyInputContainer,
          this.props.className,
        )}
        onKeyDown={this.keyPressed.bind(this)}
        ref={el => (this.container = el)}
      >
        <div
          className={styles.autocompleteInput}
          onClick={() => this.containerClicked()}
        >
          <span className={styles.values}>{values}</span>

          <input
            className={styles.input}
            onChange={() => this.textInputChanged()}
            onFocus={() => this.showMenu()}
            style={{width: inputWidth}}
            ref={ref => (this.input = ref)}
          />

          <div className={styles.options} style={{display: optionsDisplay}}>
            {options}
          </div>
        </div>
      </div>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    // If the selected values have changed, trigger an onChange
    if (this.state.values !== prevState.values && this.props.onChange) {
      this.props.onChange(this.state.values)
    }
  }

  componentWillUnmount() {
    // Make sure this event handler doesn't linger.
    document.removeEventListener('click', this.handleGlobalClick)
  }

  // State management

  getVisibleOptions() {
    return this.state.options.filter(
      option => !this.state.values.map(prop('value')).includes(option.value),
    )
  }

  showMenu() {
    // Note: This does not guarantee that the menu will actually appear! If
    // there are no options, the menu will still not show.
    this.setState({canShowMenu: true})
    document.addEventListener('click', this.handleGlobalClick)
  }

  hideMenu() {
    this.setState({canShowMenu: false})
    document.removeEventListener('click', this.handleGlobalClick)
  }

  selectOption(option) {
    this.setState(state => ({
      values: append(option, state.values),
      textInputValue: '',
      highlightedOption: null,
      options: [],
    }))
    this.input.value = ''
    this.input.focus()
  }

  removeValueAtIndex(index) {
    this.setState(state => ({
      values: remove(index, 1, state.values),
    }))
  }

  highlightOption(option) {
    this.setState({highlightedOption: option})
  }

  // Event handlers

  containerClicked() {
    this.input.focus()
  }

  textInputChanged() {
    const inputValue = this.input.value.trim()
    let request = null
    if (inputValue) {
      request = fetchCompanyAutocomplete({
        entityType: this.props.entityType,
        name: inputValue,
        excludeIds: this.state.values.map(prop('value')),
      }).then(companies => {
        this.autocompleteResponse(companies)
      })
    }
    if (this.state.autocompleteRequest) {
      this.state.autocompleteRequest.cancel()
    }
    this.setState({
      textInputValue: inputValue,
      autocompleteRequest: request,
    })
  }

  autocompleteResponse(companies) {
    this.props.updateCompanies(companies)
    const options = companies.map(company => ({
      label: company.name,
      value: company.id,
    }))
    this.setState(state => ({
      options,
      highlightedOption:
        state.highlightedOption &&
        options.find(whereEq({option: state.highlightedOption.option})),
      autocompleteRequest: null,
    }))
  }

  hasHitMaxValuesLimit() {
    const {maxValues} = this.props
    return maxValues && this.state.values.length === maxValues
  }

  keyPressed(event) {
    const {key} = event
    const {highlightedOption, values} = this.state
    const options = this.getVisibleOptions()

    if (key == 'ArrowUp' || key == 'ArrowDown') {
      const offset = key == 'ArrowUp' ? -1 : 1
      const highlightedIndex = highlightedOption
        ? options.findIndex(option => {
            return option.value === highlightedOption.value
          })
        : -1
      let newIndex = highlightedIndex + offset
      if (newIndex < 0) {
        newIndex = 0
      } else if (newIndex >= options.length) {
        newIndex = options.length - 1
      }
      const newHighlightedOption = options[newIndex]
      this.highlightOption(newHighlightedOption)
    } else if (['Enter', 'Tab', ','].includes(key)) {
      if (highlightedOption) {
        this.selectOption(highlightedOption)
      }
      // We don't want the default behavior of either the Enter (submit), Tab
      // (lose focus), or comma keys.
      event.preventDefault()
    } else if (key == 'Backspace') {
      const {selectionStart, selectionEnd} = this.input
      if (selectionStart === 0 && selectionEnd === 0 && values.length) {
        this.removeValueAtIndex(values.length - 1)
      }
    } else if (this.hasHitMaxValuesLimit()) {
      event.preventDefault()
    }
  }

  handleGlobalClick = event => {
    // Hide the menu if the user clicks outside of it
    const {container} = this
    if (!container) return
    if (!container.contains(event.target)) {
      this.hideMenu()
    }
  }
}

class SelectedOption extends React.PureComponent {
  static propTypes = {
    option: PropTypes.object.isRequired,
    onRemove: PropTypes.func,
  }

  render() {
    const {option} = this.props
    return (
      <span className={classNames(styles.value)}>
        <span className={styles.label}>{option.label}</span>
        <span className={styles.remove} onClick={() => this.remove()}>
          x
        </span>
      </span>
    )
  }

  remove() {
    if (this.props.onRemove) {
      this.props.onRemove(this.props.option)
    }
  }
}

class InputOption extends React.PureComponent {
  static propTypes = {
    input: PropTypes.string.isRequired,
    option: PropTypes.object.isRequired,
    isHighlighted: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    onHover: PropTypes.func,
    className: PropTypes.string,
  }

  render() {
    const {option, isHighlighted, className} = this.props

    return (
      <div
        className={classNames(styles.option, {
          [className]: !isHighlighted,
          [styles.highlighted]: isHighlighted,
        })}
        onClick={e => this.clicked(option, e)}
        onMouseEnter={() => this.hovered(option)}
      >
        <span className={styles.label}>{option.label}</span>
      </div>
    )
  }

  clicked(option, event) {
    event.stopPropagation()
    if (this.props.onClick) {
      // This workaround is due to the fact that this click event fires before
      // the global one does, so by the time the global event handler is hit,
      // the options have disappeared and the menu is always closed. This way,
      // the global handler is guaranteed to fire first.
      window.requestAnimationFrame(() => {
        this.props.onClick(option)
      })
    }
  }

  hovered(option) {
    if (this.props.onHover) {
      this.props.onHover(option)
    }
  }
}

export default connect(
  null,
  (dispatch, props) => ({
    updateCompanies: companies => {
      const companyEntities = pipe(
        map(company => [company.id, company]),
        fromPairs,
      )(companies)
      const Model = props.entityType === 'company' ? Company : Industry
      dispatch(
        entityActions.update({
          [Model.entityKey]: companyEntities,
        }),
      )
    },
  }),
)(EntityInput)
