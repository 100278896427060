import {createAction} from 'redux-actions'

const action = name => createAction(`my-entity-star/${name}`)

export const init = action('INIT')
export const deinit = action('DEINIT')
export const setIsLoading = action('SET_IS_LOADING')

export const showEmailSettingsModal = action('SHOW_EMAIL_SETTINGS_MODAL')
export const hideEmailSettingsModal = action('HIDE_EMAIL_SETTINGS_MODAL')
export const confirmEmailSettings = action('CONFIRM_EMAIL_SETTINGS')
export const setEmailSettingsConfirming = action(
  'SET_EMAIL_SETTINGS_CONFIRMING',
)
export const setEmailSettingsConfirmed = action('SET_EMAIL_SETTINGS_CONFIRMED')

export const showRemovalConfirmationModal = action(
  'SHOW_REMOVAL_CONFIRMATION_MODAL',
)
export const hideRemovalConfirmationModal = action(
  'HIDE_REMOVAL_CONFIRMATION_MODAL',
)
export const setRemovalConfirming = action('SET_REMOVAL_CONFIRMING')
export const setRemovalConfirmed = action('SET_REMOVAL_CONFIRMED')
