import React from "react";
import urls from 'app/urls'
import ExpandableMenu from "../../../common/ExpandableMenu";
import "./styles.less"
import Divider from "../../../common/Divider";

const ResponsiveMenu = ({
    isDiligent,
    shouldHideMisLink,
    showOldPublish,
    showNewPublish,
    flaggedArticleCount,
    displayName,
    firmName,
    currentUser,
    showPrimaryMenu,
    showAdminMenu,
    hideMenu
}) => {
    let adminLink
    let adminLinkLabel
    if (currentUser.isStaff || currentUser.isFirmAdmin) {
        adminLink = urls.pyapp.admin()
        adminLinkLabel = "Admin"
    } else if (currentUser.isGroupManager) {
        adminLink = urls.pyapp.admin()
        adminLinkLabel = "Manage Groups"
    } else {
        adminLink = null
    }

    const insightsLinkLabel = isDiligent ? 'Analytics' : 'Insights'
    const misLinkLabel = isDiligent ? 'Intel Now' : 'MIS'

    let publishLink = null
    if (showOldPublish && showNewPublish) {
        publishLink = null
    }
    else if (showOldPublish) {
        publishLink = urls.pyapp.publish()
    }
    else if (showNewPublish) {
        publishLink = urls.pyapp.publishV3()
    }
    else {
        publishLink = null
    }

    let publishSubMenuItems = []
    if (showOldPublish && showNewPublish) {
        const oldPublish = urls.responsiveSubMenuItems.oldPublish
        const newPublish = urls.responsiveSubMenuItems.newPublish
        publishSubMenuItems = [oldPublish, newPublish]
    }

    const insightsSubMenuItems = urls.responsiveSubMenuItems.insights.subMenus
    const esgSubMenuItems = urls.responsiveSubMenuItems.esg.subMenus
    const misSubMenuItems = urls.responsiveSubMenuItems.mis.subMenus
    const isInsightsActive = true

    return (
        <>
            {
                !showPrimaryMenu ?
                    <>
                        <ExpandableMenu
                            title="Base"
                            titleClassName={"primaryMenuStyle"}
                            url={urls.pyapp.home()} 
                            onNavigation={hideMenu}
                            />
                        <ExpandableMenu
                            title={insightsLinkLabel}
                            titleClassName={"primaryMenuStyle"}
                            url={urls.home()}
                            subMenus={insightsSubMenuItems}
                            isActive={isInsightsActive} 
                            onNavigation={hideMenu}
                        />
                        {!shouldHideMisLink && <ExpandableMenu
                            title={misLinkLabel}
                            titleClassName={"primaryMenuStyle"}
                            subMenus={misSubMenuItems}
                            url={urls.pyapp.mis()} 
                            onNavigation={hideMenu}
                        />}
                        <ExpandableMenu
                            title="ESG dashboard"
                            titleClassName={"primaryMenuStyle"}
                            subMenus={esgSubMenuItems}
                            url={urls.pyapp.esgDashboard()} 
                            onNavigation={hideMenu}
                        />
                        <Divider className={"divider"} />
                    </> : null
            }
            <ExpandableMenu
                title="Help"
                leftIconClassName={'help-icon'}
                url={urls.pyapp.help()} 
                onNavigation={hideMenu}
            />
            <ExpandableMenu
                title="Flagged Items"
                leftIconClassName={'flag-icon'}
                badgeCount={flaggedArticleCount}
                url={urls.pyapp.flagged()} 
                onNavigation={hideMenu}
            />
            <ExpandableMenu
                title="Publications"
                subMenus={publishSubMenuItems}
                leftIconClassName={'publish-icon'}
                url={publishLink} 
                onNavigation={hideMenu}
            />
            {adminLink && <ExpandableMenu
                title={adminLinkLabel}
                leftIconClassName={'admin-icon'}
                url={adminLink} 
                onNavigation={hideMenu}
            />}
            <ExpandableMenu
                title="Email Notifications"
                leftIconClassName={'mail-icon'}
                url={urls.pyapp.emailSettings()} 
                onNavigation={hideMenu}
            />
            <ExpandableMenu
                title={`${_.startCase(_.camelCase(displayName))}`}
                subTitle={firmName}
                leftIconClassName={'user-icon'}
                url={urls.pyapp.profile()} 
                onNavigation={hideMenu}
            />
            <Divider className={"divider"} />
            <ExpandableMenu
                title="Sign Out"
                leftIconClassName={'signout-icon'}
                url={urls.logout()}
                titleClassName={'signout-title'} 
                onNavigation={hideMenu}
            />
            <div className="emptyBottomView" />
        </>
    );
};
export default ResponsiveMenu;
