import classNames from 'classnames'
import React from 'react'

import InlineSvg from 'app/common/InlineSvg'

import * as styles from './HelpIcon.less'
import helpIconUrl from 'static/images/question-mark.svg'

const HelpIcon = ({className, ...restProps}) => (
  <InlineSvg
    src={helpIconUrl}
    className={classNames(styles.icon, className)}
    {...restProps}
  />
)
export default HelpIcon
