import classNames from 'classnames'
import bind from 'memoize-bind'
import {prop} from 'ramda'
import React from 'react'

import HealthBar from 'app/common/HealthBar'
import HealthScore from 'app/common/HealthScore'
import Link from 'app/common/NavigationLink'
import {SORT_DIRECTIONS} from 'app/constants'
import {hasHealthData} from 'app/utils/health'
import urls from 'app/urls'
import MyIndustryStar from 'app/reusable/industries/my-industry-star'

import * as styles from './IndustriesTable.less'

export const getColumns = ({
  geography,
  myCompanyIdsSet = new Set(),
  onDetailsClick,
} = {}) => [
  {
    name: 'name',
    label: 'Industry Name',
    baseWidth: 200,
    growRatio: 2,
    isSortable: true,
    cellContents: industry => (
      <div className={styles.contents}>
        <Link
          href={urls.industryOverview(industry.id, {
            geography: geography || undefined,
          })}
          className={styles.label}
        >
          {industry.name}
        </Link>
        <MyIndustryStar
          industry={industry}
          className={classNames(styles.star, 'star')}
        />
      </div>
    ),
    className: styles.name,
  },
  {
    name: 'health',
    label: 'Health',
    baseWidth: 100,
    maxWidth: 160,
    isSortable: true,
    cellContents: industry =>
      hasHealthData(industry) ? (
        <span className={styles.healthContainer}>
          <HealthScore
            score={industry.healthData.healthScore}
            className={styles.healthScore}
          />
          <HealthBar
            healthData={industry.healthData}
            className={styles.healthBar}
          />
        </span>
      ) : (
        <div className={styles.noScore}>No Score</div>
      ),
    className: styles.health,
  },
  {
    name: 'companyCount',
    label: 'Number of Companies',
    baseWidth: 110,
    growRatio: 0,
    isSortable: true,
    defaultSortDirection: SORT_DIRECTIONS.DESC,
    cellContents: prop('companyCount'),
  },
  {
    name: 'myCompanies',
    label: 'My Companies',
    baseWidth: 150,
    growRatio: 2,
    isSortable: true,
    defaultSortDirection: SORT_DIRECTIONS.DESC,
    cellContents: industry => {
      const filteredCompanies = industry.companies.filter(company =>
        myCompanyIdsSet.has(company.id),
      )
      return filteredCompanies.length
        ? filteredCompanies.map(prop('name')).join(', ')
        : '--'
    },
  },
  {
    name: 'actions',
    label: '',
    baseWidth: 100,
    maxWidth: 130,
    cellContents: industry =>
      hasHealthData(industry) && (
        <a
          onClick={bind(onDetailsClick, null, industry)}
          className={styles.action}
        >
          Industry Details
        </a>
      ),
    className: styles.actions,
  },
]
