const CSV_CONTENT_TYPE = "text/csv;charset=utf-8;";

/**
 * Convert a 2D array to a CSV string.
 * @param data An array of data
 */
function arrayToCsv(data) {
  return data.map(row =>
    row.map(String).join(',')
  ).join('\n');
}

/**
 * Accepts a filename and a CSV contents and initiates a download.
 * @param fileName The name of the file to be saved.
 * @param content The contents of the file.
 */
function downloadCsv(fileName, content) {
  let blob = new Blob([content], { type: CSV_CONTENT_TYPE });
  let url = URL.createObjectURL(blob);

  let anchor = document.createElement('a');
  anchor.href = url;
  anchor.setAttribute('download', fileName);
  anchor.click();
  anchor.remove();
}

export { arrayToCsv, downloadCsv };
