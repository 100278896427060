import parseDate from 'date-fns/parse'
import is from 'is'

import Resource from 'app/framework/Resource'
import StoryModel from 'app/models/Story'
import ArticleResource from 'app/resources/Article'
import CompanyResource from 'app/resources/Company'
import StorylineResource from 'app/resources/Storyline'

export default class Story extends Resource {
  static endpoint = 'story'

  static requiredFields = [
    'id',
    'articleCount',
    'summary',
    'storyDate',
    'valence',
    'categoryId',
    'litigation',
    'rumor',
    'opinion',
    'perspectiveId',
  ]
  static allFields = [
    ...Story.requiredFields,
    'storylineId',
    'storyline',
    'companyId',
    'company',
    'articles',
    'topArticle',
  ]

  entityItemsForRestItem(restData) {
    const storyData = {
      id: restData.id,
      storylineId: restData.storyline
        ? restData.storyline.id
        : restData.storylineId,
      companyId: restData.company ? restData.company.id : restData.companyId,
      articleCount: restData.articleCount,
      summary: restData.summary,
      date: parseDate(restData.storyDate || restData.date),
      valence: restData.valence,
      subfactorId: restData.categoryId || restData.subfactorId,
      isLitigation: restData.litigation,
      isRumor: restData.rumor,
      isOpinion: restData.opinion,
      perspectiveId: restData.perspectiveId,
    }

    const articles = new ArticleResource().entityItemsForRestData([
      ...(restData.articles || []),
      ...(restData.topArticle ? [restData.topArticle] : []),
    ])

    if (restData.topArticle) {
      storyData.topArticleId = restData.topArticle.id
    } else if (restData.articles && !is.array.empty(restData.articles)) {
      // If a top article is not returned, use the first one in the full list.
      storyData.topArticleId = restData.articles[0].id
    }

    const storylines = new StorylineResource().entityItemsForRestData(
      restData.storyline || [],
    )

    const companies = new CompanyResource().entityItemsForRestData(
      restData.company || [],
    )

    const story = {
      id: restData.id,
      entityKey: StoryModel.entityKey,
      data: storyData,
    }

    return [story, ...articles, ...storylines, ...companies]
  }
}
