import classNames from 'classnames'
import dateFns from 'date-fns'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import ArticleLink from 'app/common/ArticleLink'
import {LoadingMessage} from 'app/common/loading-message'
import Modal from 'app/common/Modal'
import RadioButton from 'app/common/RadioButton'
import TextSeparator from 'app/common/TextSeparator'
import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import Article from 'app/models/Article'
import Story from 'app/models/Story'
import Storyline from 'app/models/Storyline'
import EmailShareButton from 'app/social/share-buttons/EmailShareButton'
import FacebookShareButton from 'app/social/share-buttons/FacebookShareButton'
import LinkedInShareButton from 'app/social/share-buttons/LinkedInShareButton'
import TwitterShareButton from 'app/social/share-buttons/TwitterShareButton'
import * as strings from 'app/strings'

import {
  deselectStory,
  hideModal,
  selectArticle,
  selectStory,
} from './storyline-modal-actions'
import {getStorylineModalState} from './storyline-modal-selectors'

import * as styles from './StorylineModal.less'

function StorylineModal({storyline, story, isLoading = false, hideModal}) {
  if (!storyline && !story) return null

  const content = isLoading ? (
    <LoadingMessage />
  ) : story ? (
    <SelectArticle story={story} />
  ) : (
    <SelectStory storyline={storyline} />
  )

  return (
    <Modal
      className={classNames(styles.storylineModal, 'storyline-modal')}
      contentClassName={styles.modalContent}
      onClose={hideModal}
    >
      {content}
    </Modal>
  )
}
StorylineModal.propTypes = {
  storyline: PropTypes.object,
  story: PropTypes.object,
  isLoading: PropTypes.bool,
  hideModal: PropTypes.func.isRequired,
}
export default connect(
  createSelector(
    [getStorylineModalState, getEntities],
    (state, entities) => {
      if (!state.isVisible) return {}
      const {storylineId, storyId, isLoading} = state
      const orm = Orm.withEntities(entities)
      return {
        storyline: storylineId && orm.getById(Storyline, storylineId),
        story: storyId && orm.getById(Story, storyId),
        isLoading,
      }
    },
  ),
  {hideModal},
)(StorylineModal)

function SelectStory({storyline}) {
  return (
    <div className={styles.innerContent}>
      <div className={styles.header}>
        <h4 className={styles.label}>Share Storyline</h4>
        <h2 className={styles.storylineHeadline}>{storyline.summary}</h2>
        <h3 className={styles.instructions}>
          Pick a story from which to share an article.
        </h3>
      </div>

      <div className={styles.listContainer}>
        <StoryList storyline={storyline} />
      </div>
    </div>
  )
}

function StoryList({storyline}) {
  const storyList = R.pipe(
    R.sort((story1, story2) =>
      dateFns.isBefore(story1.date, story2.date)
        ? 1
        : dateFns.isAfter(story1.date, story2.date)
        ? -1
        : 0,
    ),
    R.map(story => <StoryItem story={story} key={story.id} />),
  )(storyline.stories)
  return <div className={styles.storyList}>{storyList}</div>
}

function StoryItemRaw({story, onSelect}) {
  return (
    <div
      className={classNames(styles.story, 'story')}
      onClick={() => onSelect(story.id)}
    >
      <div className={styles.headline}>
        <a>{story.summary}</a>
      </div>

      <div className={styles.subHeadline}>
        <span className={styles.date}>{strings.formatDate(story.date)}</span>
        <TextSeparator />
        <span className={styles.articleCount}>
          {strings.articleCount(story.articleCount)}
        </span>
      </div>
    </div>
  )
}
const StoryItem = connect(
  undefined,
  {onSelect: selectStory},
)(StoryItemRaw)

function SelectArticleRaw({story, selectedArticle, isEntryPoint, onBack}) {
  const storylineSummary = isEntryPoint ? null : (
    <h2 className={styles.storylineHeadline}>{story.storyline.summary}</h2>
  )
  const backButton = isEntryPoint ? null : (
    <div
      className={classNames(styles.backToStories, 'back-to-stories')}
      onClick={() => onBack()}
    >
      <a>&lt;&lt; Back to All Stories</a>
    </div>
  )
  const label = isEntryPoint ? 'Share Story' : 'Share Storyline'
  return (
    <div className={styles.innerContent}>
      <div className={styles.header}>
        <h4 className={styles.label}>{label}</h4>
        {storylineSummary}
        {backButton}
        <h3 className={styles.storyHeadline}>{story.summary}</h3>
        <h3 className={styles.instructions}>
          Select a representative article from the selection below to share out
          for this story.
        </h3>
      </div>

      <div className={styles.listContainer}>
        <ArticleList story={story} />
      </div>

      <div className={styles.footer}>
        <FacebookShareButton
          url={selectedArticle ? selectedArticle.url : ''}
          className={classNames(styles.shareButton, 'share-button-facebook')}
          isDisabled={!selectedArticle}
        />
        <LinkedInShareButton
          url={selectedArticle ? selectedArticle.url : ''}
          className={classNames(styles.shareButton, 'share-button-linkedin')}
          isDisabled={!selectedArticle}
        />
        <TwitterShareButton
          url={selectedArticle ? selectedArticle.url : ''}
          className={classNames(styles.shareButton, 'share-button-twitter')}
          isDisabled={!selectedArticle}
        />
        <EmailShareButton
          url={selectedArticle ? selectedArticle.url : ''}
          subject={selectedArticle ? selectedArticle.headline : ''}
          className={classNames(styles.shareButton, 'share-button-email')}
          isDisabled={!selectedArticle}
        />
      </div>
    </div>
  )
}
const SelectArticle = connect(
  createSelector(
    [getStorylineModalState, getEntities],
    (state, entities) => {
      const orm = Orm.withEntities(entities)
      return {
        isEntryPoint: state.shouldSkipStoryline,
        selectedArticle:
          state.articleId && orm.getById(Article, state.articleId),
      }
    },
  ),
  {onBack: deselectStory},
)(SelectArticleRaw)

function ArticleList({story}) {
  const articleList = R.pipe(
    R.sort((article1, article2) =>
      dateFns.isBefore(article1.date, article2.date)
        ? 1
        : dateFns.isAfter(article1.date, article2.date)
        ? -1
        : 0,
    ),
    R.map(article => <ArticleItem article={article} key={article.id} />),
  )(story.articles)
  return <div className={styles.articleList}>{articleList}</div>
}

function ArticleItemRaw({article, selectedArticleId, selectArticle}) {
  const isSelected = article.id === selectedArticleId
  return (
    <label
      className={classNames(styles.article, {[styles.selected]: isSelected})}
    >
      <div className={styles.radioSection}>
        <RadioButton
          name="article"
          isChecked={isSelected}
          onChange={() => selectArticle(article.id)}
        />
      </div>

      <div className={styles.articleSection}>
        <ArticleLink
          article={article}
          shouldTargetNewWindow={true}
          className={styles.headline}
        >
          {article.headline}
        </ArticleLink>

        <div className={styles.subHeadline}>
          {strings.formatDate(article.date)} {strings.viaSource(article)}
        </div>

        <p className={styles.excerpt}>{article.description}</p>
      </div>
    </label>
  )
}
const ArticleItem = connect(
  createSelector(
    [getStorylineModalState],
    state => ({
      selectedArticleId: state.articleId,
    }),
  ),
  {selectArticle},
)(ArticleItemRaw)
