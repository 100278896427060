import routes from 'app/routes'

export const STATE_PROP_NAME = 'industryOverview'
export const STORY_READER_MAX_STORY_COUNT = 25
export const INDUSTRY_OVERVIEW_ROUTES = [
  routes.industryOverview,
  routes.industryOverviewCompanies,
  routes.industryOverviewPortal,
  routes.industryOverviewPortalCompanies,
]
