import {handleActions} from 'redux-actions'

import * as actions from './storyline-modal-actions'

const getInitialState = () => ({
  isVisible: false,
  isLoading: false,
  shouldSkipStoryline: false,
  storylineId: undefined,
  storyId: undefined,
  articleId: undefined,
})

export default handleActions(
  {
    [actions.showModal]: (state, action) => ({
      ...state,
      isVisible: true,
      storylineId: action.payload.storylineId,
      storyId: action.payload.storyId,
      // If we were passed a storyId, then we should point them directly at the
      // article selection and shouldn't show a "Back to All Stories" link.
      shouldSkipStoryline: !!(state.storyId && !state.storylineId),
    }),
    [actions.hideModal]: getInitialState,
    [actions.setIsLoading]: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    [actions.selectStory]: (state, action) => ({
      ...state,
      storyId: action.payload,
      articleId: getInitialState().articleId,
    }),
    [actions.deselectStory]: (state, action) => ({
      ...state,
      storyId: getInitialState().storyId,
    }),
    [actions.selectArticle]: (state, action) => ({
      ...state,
      articleId: action.payload,
    }),
  },
  getInitialState(),
)
