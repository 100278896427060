import React from 'react'

export default function PdfIcon({ className }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
         className={className}>
      <path d="M3.75 0.75V23.25H20.2491V6.75L14.2494 0.75H3.75Z" stroke="currentColor" strokeWidth="1.5"
            strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 0.75V6.75H19.9997" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"
            strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M10.3413 17.458C11.0613 17.238 11.8423 17.053 12.6693 16.915C13.0253 16.856 13.3893 16.807 13.7613
        16.77C14.0423 17.025 14.3443 17.258 14.6723 17.456C14.9563 17.63 15.2573 17.778 15.5733 17.887L15.5753
        17.888C15.8933 17.992 16.2093 18.075 16.5363 18.129H16.5383C16.7133 18.154 16.8913 18.165 17.0713
        18.158C17.3373 18.148 17.6703 18.077 17.9053 17.911C18.0943 17.776 18.2273 17.584 18.2473 17.324C18.2623
        17.133 18.2203 16.966 18.1383 16.819C17.9973 16.567 17.7253 16.375 17.4053 16.241C16.9883 16.067 16.4923
        15.985 16.2013 15.939C15.8253 15.879 15.4413 15.861 15.0603 15.861C14.7663 15.859 14.4783 15.874 14.1923
        15.89L13.9963 15.905C13.9233 15.827 13.8523 15.748 13.7843 15.667C13.1933 14.967 12.7123 14.177 12.3193
        13.355C12.4203 12.95 12.5013 12.528 12.5513 12.092C12.6523 11.22 12.6793 10.228 12.3533 9.39601C12.2833
        9.22101 12.1553 9.02801 11.9933 8.90401C11.7913 8.75001 11.5453 8.69101 11.2823 8.82301C11.0813 8.92401
        10.9343 9.09401 10.8313 9.30001C10.7013 9.56201 10.6463 9.88401 10.6253 10.142C10.5983 10.473 10.6173
        10.805 10.6733 11.13C10.7283 11.449 10.8153 11.752 10.9123 12.054L11.1723 12.781C11.1153 12.953 11.0553
        13.124 10.9933 13.291C10.8233 13.737 10.6393 14.16 10.4623 14.567L10.1873 15.165C9.91732 15.757 9.62533
        16.339 9.30033 16.902C8.50333 17.189 7.78832 17.52 7.19032 17.905L7.18933 17.906C6.84833 18.126 6.54632
        18.367 6.29532 18.63C6.03032 18.906 5.77532 19.271 5.75132 19.668C5.73632 19.944 5.84932 20.21 6.07432
        20.374C6.34832 20.578 6.70732 20.582 7.02432 20.516C7.89332 20.333 8.57232 19.6 9.14132 18.97C9.50232
        18.57 9.91233 18.065 10.3413 17.458ZM8.49332 18.163L8.36732 18.338C8.00932 18.825 7.61132 19.415 7.01932
        19.632C6.92532 19.666 6.80132 19.705 6.67132 19.697C6.65632 19.696 6.63433 19.691 6.61933 19.687C6.62833
        19.635 6.65832 19.577 6.69432 19.517V19.516C6.75832 19.41 6.83732 19.312 6.92232 19.223C7.12032 19.015
        7.37132 18.814 7.65832 18.628C7.91132 18.464 8.19032 18.309 8.49332 18.163ZM17.1583 17.211C17.0953 17.234
        17.0243 17.258 16.9863 17.264C16.6003 17.325 16.1933 17.275 15.8253 17.151C15.5633 17.063 15.3103 16.943
        15.0703 16.8C15.0213 16.77 14.9723 16.739 14.9243 16.707L15.0533 16.709C15.3243 16.712 15.5943 16.725
        15.8623 16.76H15.8643C16.3043 16.809 16.7923 16.947 17.1583 17.211ZM13.0293 16.009L12.5323 16.081C12.0233
        16.164 11.5313 16.264 11.0583 16.379C11.1363 16.241 11.2033 16.117 11.2323 16.064C11.5223 15.555 11.7723
        15.026 11.9743 14.477C12.2793 15.015 12.6283 15.531 13.0293 16.009Z"
        fill="currentColor"/>
    </svg>
  )
}
