import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './styles.less'

export default function TextSeparator({className}) {
  return <span className={styles.textSeparator} />
}
TextSeparator.propTypes = {
  className: PropTypes.string,
}
