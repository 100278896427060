import {VALENCES} from 'app/constants'

export function changePrediction({score, change}) {
  let absChange = Math.abs(change)
  // Make sure we don't predict the score going past 10.
  if (Math.abs(score + change) > 10) {
    absChange = 10 - Math.abs(score)
    change = Math.sign(change) * absChange
  }
  if (absChange < 1) {
    return {
      valence: VALENCES.NEUTRAL,
      isLarge: false,
    }
  }
  let valence
  let isLarge = absChange >= 3
  if (change > 0) {
    valence = VALENCES.POSITIVE
  } else {
    valence = VALENCES.NEGATIVE
  }
  return {valence, isLarge}
}
