export const STATE_PROP_NAME = 'litigationPage'
export const getInitialFilterState = () => {
  return {
    factors: [],
    storyCountMin: 0,
    storyCountMax: 10000,
    valencePositive: true,
    valenceNegative: true,
    valenceNeutral: true,
    aspect: ASPECT_FILTER_TYPE.ALL,
  }
}
export const DEFAULT_LIMIT = 10;
export const ADD_REMOVE_LITIGATION_SEARCH_STATES = {
  UNDEFINED: null,
  IN_PROGRESS: 1,
  SUCCESS: 2,
  ERROR: 3,
}

export const ASPECT_FILTER_TYPE = {
  ALL: 'all',
  OBJECTIVE: 'rf_and_of',
  ONLY_RUMORS: 'rt_and_of',
  ONLY_OPINIONS: 'rf_and_ot',
  RUMOR_OR_OPINION: 'rt_or_ot',
  NO_RUMOR_OR_OPINION: 'rf_and_of',
}

export const FILTER_CHECKBOXES = {
  allIndustries: 'All Industry Sectors',
  myIndustries: 'My Industry Sectors',
  myCompanies: 'My Companies'
}

export const DROPDOWN_SETTINGS = {
  companiesThreshold: 10,
  industriesThreshold: 4
}

export const ENABLE_SEARCH_LIMIT = 8
export const NO_COMPANY_LITIGATION_DATA = 'There are no litigation stories for your search criteria.'
