import dateFns from 'date-fns'
import PropTypes from 'prop-types'
import {addIndex, fromPairs, map, pipe, range} from 'ramda'
import React, {useMemo} from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import ChartTooltip from 'app/common/charting/ChartTooltip'
import StorySummaryBlock from 'app/common/story-summary-block'
import {CHART_COLORS, today, VALENCES} from 'app/constants'
import {XAxisDateTick} from 'app/reusable/charting'
import {formatDate} from 'app/strings'
import {significantTickLabelDates} from 'app/utils/charting'
import {signForValence} from 'app/utils/health'
import urls from 'app/urls'
import * as branding from 'branding'

import styles from './StoryTimeline.less'

const MIN_TIMELINE_DAYS = 14
const TOOLTIP_STORY_COUNT = 3

const mapWithIndex = addIndex(map)

function TooltipStoryBlock({story, color}) {
  return (
    <div className={styles.story}>
      <div className={styles.color} style={{backgroundColor: color}} />
      <StorySummaryBlock
        factor={story.factor}
        subfactor={story.subfactor}
        articleCount={story.articleCount}
        startDate={story.date}
        headline={story.summary}
        valence={story.valence}
        href={urls.storylineView(story.storylineId)}
        shouldShowShareButton={false}
        className={styles.summary}
      />
    </div>
  )
}

export default function StoryTimeline({
  stories,
  startDate,
  endDate,
  factor,
  colors = CHART_COLORS,
}) {
  let relevantStories = stories.filter(
    story => story.valence !== VALENCES.NEUTRAL,
  )
  if (factor) {
    relevantStories = relevantStories.filter(story => story.factor === factor)
  }

  const daysBetweenEndDateAndToday = dateFns.differenceInCalendarDays(
    today,
    endDate,
  )
  const chartEndDate = daysBetweenEndDateAndToday < 7 ? today : endDate
  const dayDifference = dateFns.differenceInCalendarDays(
    chartEndDate,
    startDate,
  )
  const dayCount = Math.max(dayDifference + 1, MIN_TIMELINE_DAYS)
  const chartStartDate = dateFns.subDays(chartEndDate, dayCount - 1)
  const days = range(0, dayCount)
  const dates = useMemo(
    () => days.map(index => dateFns.addDays(chartStartDate, index)),
    [dayCount, chartStartDate],
  )
  const storiesByDay = days.map(day => {
    const date = dateFns.addDays(chartStartDate, day)
    return relevantStories.filter(story => dateFns.isSameDay(story.date, date))
  })
  const colorsByStoryId = pipe(
    mapWithIndex((story, index) => [story.id, colors[index]]),
    fromPairs,
  )(stories)

  const chartDateLabels = significantTickLabelDates(dates)
  const chartData = storiesByDay.map((stories, dayIndex) => {
    const point = {
      x: dayIndex,
      stories,
    }
    // We need a separate volume for every story.
    for (const story of stories) {
      point[`volume:${story.id}`] =
        story.articleCount * signForValence(story.valence)
    }
    return point
  })

  const axisStyle = {fill: branding['text-color-light'], fontSize: 14}

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={chartData}
        stackOffset="sign"
        margin={{top: 5, right: 5, bottom: 30, left: 0}}
      >
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <XAxis
          dataKey="x"
          interval={0}
          tick={<XAxisDateTick datesByXValue={chartDateLabels} />}
          style={axisStyle}
        />
        <YAxis
          tickFormatter={val => Math.abs(val)}
          label={{
            value: 'Article Volume by Valence',
            angle: -90,
            position: 'insideLeft',
            style: {...axisStyle, textAnchor: 'middle'},
          }}
          style={axisStyle}
        />
        {/* Zero line */}
        <ReferenceLine
          y={0}
          stroke="#999999"
          strokeWidth={1}
          strokeDasharray="none"
        />
        {stories.map((story, index) => (
          <Bar
            dataKey={`volume:${story.id}`}
            fill={colors[index]}
            stackId="stack"
            isAnimationActive={false}
            key={story.id}
          />
        ))}
        <Tooltip
          active={true}
          content={info => {
            if (!info.active) {
              return null
            }
            const dayIndex = info.label
            const stories = storiesByDay[dayIndex]
            const date = dates[dayIndex]
            return (
              <ChartTooltip
                header={`${stories.length} ${
                  stories.length === 1 ? 'Story' : 'Stories'
                } on ${formatDate(date)}`}
                className={styles.tooltip}
                contentClassName={styles.content}
              >
                {stories.slice(0, TOOLTIP_STORY_COUNT).map(story => (
                  <TooltipStoryBlock
                    story={story}
                    color={colorsByStoryId[story.id]}
                    key={story.id}
                  />
                ))}
                {stories.length > TOOLTIP_STORY_COUNT && (
                  <div>
                    ...and {stories.length - TOOLTIP_STORY_COUNT} more{' '}
                    {stories.length - TOOLTIP_STORY_COUNT === 1
                      ? 'story'
                      : 'stories'}
                  </div>
                )}
              </ChartTooltip>
            )
          }}
          cursor={false}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}
StoryTimeline.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  factor: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string),
}
