import PropTypes from 'prop-types'
import React from 'react'

import urls, {withSendGridTracking} from 'app/urls'
import Cell from 'email-server-common/common/Cell'
import Link from 'email-server-common/common/Link'
import PaddedBlock from 'email-server-common/common/PaddedBlock'
import Table from 'email-server-common/common/Table'
import {paddingAmount, spacingAmount} from 'email-server/style/constants'
import {
  backgroundColorDark,
  textColorVeryLight,
} from 'email-server/style/palette'

import Section from './Section'

const phoneDisplay = phone => phone.replace('-', '.')

const Footer = ({
  baseRootUrl,
  productName,
  productLogoUrl,
  year,
  copyrightHolderName,
  contactPhone,
  contactAddress,
  firm,
}) => (
  <React.Fragment>
    <Section>
      { firm.enableUpdateYourProfile && (
        <PaddedBlock padding={paddingAmount}>
          <Link
            href={withSendGridTracking(
              urls.pyapp.emailSettings({
                source: 'signals_email',
                root: baseRootUrl,
              }),
            )}
          >
            Click here to update your settings
          </Link>
        </PaddedBlock>
      )}
      <PaddedBlock
        padding={paddingAmount}
        style={{backgroundColor: backgroundColorDark}}
      >
        <Table>
          <Cell align="left" valign="middle">
            <img src={productLogoUrl} height={28} alt={`${productName} Logo`} />
          </Cell>
          <Cell
            align="right"
            valign="middle"
            style={{textAlign: 'right', color: textColorVeryLight}}
          >
            &copy; {year} {copyrightHolderName}
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link style={{color: textColorVeryLight}}>{contactAddress}</Link>
            &nbsp;&nbsp;|&nbsp;&nbsp; P:{' '}
            <Link
              href={`tel:${contactPhone}`}
              style={{color: textColorVeryLight}}
            >
              {phoneDisplay(contactPhone)}
            </Link>
          </Cell>
        </Table>
      </PaddedBlock>
    </Section>

    <PaddedBlock bottom={spacingAmount} />
  </React.Fragment>
)
Footer.propTypes = {
  baseRootUrl: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  productLogoUrl: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  copyrightHolderName: PropTypes.string.isRequired,
  contactPhone: PropTypes.string.isRequired,
  contactAddress: PropTypes.string.isRequired,
  firm: PropTypes.object.isRequired,
}
export default Footer
