/*
 * The vague idea here is to represent the backend API endpoints via a concept
 * of "resources", and have these resource classes handle mapping between
 * frontend models and the backend, including mapping fields, (de)normalization,
 * etc. There isn't a concrete API here yet, so this is subject to change
 * significantly in the short term.
 */

import Company from './Company'
import HistoricalStoryline from './HistoricalStoryline'
import Industry from './Industry'
import MyCompany from './MyCompany'
import MyIndustry from './MyIndustry'
import Story from './Story'
import Storyline from './Storyline'
import LitigationNumberSummary from "./LitigationNumberSummary";
import LitigationTimeSeries from "./LitigationTimeSeries";
import LitigationStory from "./LitigationStory";
import LitigationSavedSearch from "./LitigationSavedSearch";

export {
  Company,
  HistoricalStoryline,
  Industry,
  MyCompany,
  MyIndustry,
  Story,
  Storyline,
  LitigationNumberSummary,
  LitigationTimeSeries,
  LitigationStory,
  LitigationSavedSearch,
}
