import classNames from 'classnames'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import {filter, pipe, prop, sortBy} from 'ramda'
import React from 'react'
import {connect} from 'react-redux'
import {Portal} from 'react-portal'

import Columns, {Column} from 'app/common/Columns'
import Modal from 'app/common/Modal'
import HealthScore from 'app/common/HealthScore'
import HealthBar from 'app/common/HealthBar'
import Link from 'app/common/NavigationLink'
import {navigate} from 'app/global/global-actions'
import HealthFactorSummary from 'app/overviews/common/HealthFactorSummary'
import urls from 'app/urls'

import * as styles from './IndustryDetailsModal.less'

class IndustryDetailsModal extends React.PureComponent {
  static propTypes = {
    industry: PropTypes.object.isRequired,
    myCompanyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onClose: PropTypes.func.isRequired,
  }

  render() {
    const {industry, myCompanyIds, onClose} = this.props
    const myCompanies = pipe(
      filter(({id}) => myCompanyIds.includes(id)),
      sortBy(prop('name')),
    )(industry.companies)

    return (
      <Portal>
        <Modal onClose={onClose}>
          <h1>
            {industry.name}
            <Link
              href={urls.industryOverview(industry.id)}
              className={styles.overviewLink}
            >
              Go to Industry Overview Page
            </Link>
          </h1>

          <Columns>
            <Column className={styles.healthColumn}>
              <div className={classNames(styles.block, styles.healthScore)}>
                <div className={styles.label}>Industry Health Score</div>
                <div className={styles.content}>
                  <HealthScore
                    score={industry.healthData.healthScore}
                    className={styles.score}
                  />
                  <HealthBar
                    healthData={industry.healthData}
                    className={styles.healthBar}
                  />
                </div>
              </div>

              <div className={classNames(styles.block, styles.factorHealth)}>
                <div className={styles.label}>Health Score by Factor</div>
                <div className={styles.content}>
                  <HealthFactorSummary
                    healthData={industry.healthData}
                    selectFactor={bind(this.selectFactor, this)}
                  />
                </div>
              </div>
            </Column>

            <Column className={styles.companiesColumn}>
              <div className={classNames(styles.block, styles.companyCount)}>
                <div className={styles.label}>
                  There are {industry.companyCount} companies in this Industry.
                </div>
                <Link href={urls.industryOverviewCompanies(industry.id)}>
                  View Companies
                </Link>
              </div>

              <div className={classNames(styles.block, styles.myCompanies)}>
                <div className={styles.label}>
                  My Companies in this Industry:
                </div>

                <ul className={styles.list}>
                  {myCompanies.map(company => (
                    <li className={styles.item} key={company.id}>
                      <Link href={urls.companyOverview(company.id)}>
                        {company.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Column>
          </Columns>
        </Modal>
      </Portal>
    )
  }

  selectFactor(factor) {
    this.props.navigate(urls.industryOverview(this.props.industry.id, {factor}))
  }
}
export default connect(
  null,
  {
    navigate,
  },
)(IndustryDetailsModal)
