import PropTypes from 'prop-types'
import React from 'react'

import Link from 'app/common/NavigationLink'
import {SUPPORT_EMAIL} from 'app/constants'
import urls, {currentUrl, withQueryParams} from 'app/urls'

import {TIME_FRAME_QUERY_PARAM} from '../common/overview-constants'

import * as styles from './CompanyOverviewNoData.less'
import imageUrl from 'static/images/no-data.png'

const NoData = ({
  entityType,
  isLawFirm = false,
  shouldShow90DayFilter = false,
  shouldShowExpandFiltersMessage = false,
}) => {
  const link =
    entityType === 'company' ? (
      <Link href={urls.companies()}>Companies</Link>
    ) : (
      <Link href={urls.industries()}>Industries</Link>
    )
  return (
    <div className={styles.container}>
      <div className={styles.message}>
        <h1>
          {isLawFirm
            ? 'Law firms are not supported'
            : `No data available for this ${entityType}`}
        </h1>

        <p className={styles.description}>
          {isLawFirm ? (
            'Insights works best with company and industry news. Law firms are currently not supported.'
          ) : (
            <>
              {shouldShow90DayFilter ? (
                <React.Fragment>
                  Try expanding your filters or choosing a different{' '}
                  {entityType} from the {link} page.
                </React.Fragment>
              ) : shouldShowExpandFiltersMessage ? (
                <React.Fragment>
                  Try expanding your{' '}
                  <Link
                    href={withQueryParams(currentUrl(), {
                      [TIME_FRAME_QUERY_PARAM]: 90,
                    })}
                  >
                    time frame to 90 days
                  </Link>{' '}
                  or choosing a different {entityType} to analyze.
                </React.Fragment>
              ) : (
                <React.Fragment>
                  Try a different {entityType} from the {link} page.
                </React.Fragment>
              )}{' '}
              If you think there's a problem,{' '}
              <a href={`mailto:${SUPPORT_EMAIL}`}>contact support</a>.
            </>
          )}
        </p>
      </div>

      <img src={imageUrl} className={styles.image} />
    </div>
  )
}
NoData.propTypes = {
  entityType: PropTypes.string.isRequired,
  isLawFirm: PropTypes.bool,
  shouldShow90DayFilter: PropTypes.bool,
  shouldShowExpandFiltersMessage: PropTypes.bool,
}

export default NoData
