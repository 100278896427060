import dateFns from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'

import {USER_DATE_FORMAT} from 'app/constants'
import urls, {toAbsoluteUrl, withSendGridTracking} from 'app/urls'
import Cell from 'email-server-common/common/Cell'
import Link from 'email-server-common/common/Link'
import PaddedBlock from 'email-server-common/common/PaddedBlock'
import Table from 'email-server-common/common/Table'
import {paddingAmount, spacingAmount} from 'email-server/style/constants'
import {backgroundColorDark, textColorLight} from 'email-server/style/palette'
import HtmlReactParser from 'html-react-parser'

const UserStrip = ({
  user,
  firm,
  date,
  productName,
  productLogoUrl,
  rootUrl,
}) => {
  const style = {
    paddingTop: paddingAmount,
    paddingBottom: paddingAmount,
    paddingLeft: spacingAmount,
    paddingRight: spacingAmount,
  }
  return (
    <Table
      style={{backgroundColor: backgroundColorDark, color: textColorLight}}
    >
      <Cell align="left" valign="middle" style={style}>
        Prepared for {user.name} on {dateFns.format(date, USER_DATE_FORMAT)}
      </Cell>
      {firm.ssoLoginUrl && firm.ssoUrlDescription && (
        <Cell align="left" valign="middle" style={style}>
          <Link
            href={firm.ssoLoginUrl}
            noSsoRedirect={true}
            style={{color: 'white'}}
          >
            {firm.ssoUrlDescription}
          </Link>
        </Cell>
      )}
      <Cell align="right" style={{...style, textAlign: 'right'}}>
        <Link
          href={withSendGridTracking(
            toAbsoluteUrl(urls.home(), {root: rootUrl}),
          )}
        >
          <img src={productLogoUrl} height={28} alt={`${productName} Logo`} />
        </Link>
      </Cell>
    </Table>
  )
}
UserStrip.propTypes = {
  user: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  productName: PropTypes.string.isRequired,
  productLogoUrl: PropTypes.string.isRequired,
  rootUrl: PropTypes.string.isRequired,
  emailAlertPrefix:PropTypes.string,
  emailAlertSuffix:PropTypes.string,
}

const Header = ({user, firm, date, productName, productLogoUrl, rootUrl,emailAlertPrefix,emailAlertSuffix}) => (
  <React.Fragment>
    <PaddedBlock padding={spacingAmount}>
      <img
        src={firm.logoUrl}
        style={{maxWidth: '100%', display: 'block'}}
        alt="Firm Logo"
      />
    </PaddedBlock>

    {emailAlertPrefix ?
      <PaddedBlock padding={spacingAmount} style={{
        backgroundColor: "#F2F2F2",
        fontSize: "initial"
      }}>
        {HtmlReactParser(emailAlertPrefix)}
      </PaddedBlock> : ""
    }
    
    <UserStrip
      user={user}
      firm={firm}
      date={date}
      productName={productName}
      productLogoUrl={productLogoUrl}
      rootUrl={rootUrl}
      emailAlertPrefix={emailAlertPrefix}
      emailAlertSuffix={emailAlertSuffix}
    />
  </React.Fragment>
)
Header.propTypes = {
  user: PropTypes.object.isRequired,
  firm: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  productName: PropTypes.string.isRequired,
  productLogoUrl: PropTypes.string.isRequired,
  rootUrl: PropTypes.string.isRequired,
  emailAlertPrefix:PropTypes.string.isRequired,
  emailAlertSuffix:PropTypes.string.isRequired,
}
export default Header
