import {handleActions} from 'redux-actions'
import {LOCATION_CHANGED} from 'redux-little-router'

import {DEFAULT_TIME_FRAME_DAYS} from 'app/constants'
import routes from 'app/routes'

import {
  GEOGRAPHY_QUERY_PARAM,
  TIME_FRAME_QUERY_PARAM,
} from '../../common/overview-constants'
import * as actions from './company-report-actions'

const getInitialState = () => ({
  isLoading: true,
  companyId: null,
  storyIdsByFactor: null,
  storyCount: null,
  storylineCount: null,
  timeFrameDays: DEFAULT_TIME_FRAME_DAYS,
  isDownloadingPdf: false,
  selectedGeography: 0,
})

export default handleActions(
  {
    [LOCATION_CHANGED]: (state, action) =>
      action.payload.route === routes.companyReport
        ? {
            ...getInitialState(),
            companyId: parseInt(action.payload.params.companyId, 10),
            timeFrameDays: parseInt(
              action.payload.query[TIME_FRAME_QUERY_PARAM] ||
                DEFAULT_TIME_FRAME_DAYS,
              10,
            ),
            selectedGeography: parseInt(
              action.payload.query[GEOGRAPHY_QUERY_PARAM] || 0,
              10,
            ),
          }
        : state,
    [actions.setIsLoading]: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    [actions.setStoryIdsByFactor]: (state, action) => ({
      ...state,
      storyIdsByFactor: action.payload,
    }),
    [actions.setCounts]: (state, action) => ({
      ...state,
      storyCount: action.payload.stories,
      storylineCount: action.payload.storylines,
    }),
    [actions.startDownloadPdf]: (state, action) => ({
      ...state,
      isDownloadingPdf: true,
    }),
    [actions.finishDownloadPdf]: (state, action) => ({
      ...state,
      isDownloadingPdf: false,
    }),
  },
  getInitialState(),
)
