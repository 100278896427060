import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import InlineSvg from 'app/common/InlineSvg'

import * as styles from './ManzamaLogo.less'

const logoUrl = require(`static/images/${
  process.env.MANZAMA_BRAND
}-logotype.svg`)

const darkLogoUrl = require(`static/images/${
  process.env.MANZAMA_BRAND
}-logotype-dark.svg`)

export const COLORS = {
  LIGHT: 'light',
  DARK: 'dark',
}

export default function ManzamaLogo({
  color = COLORS.DARK,
  className,
  ...restProps
}) {
  const logo = color === COLORS.DARK ? darkLogoUrl : logoUrl
  return (
    <InlineSvg
      src={logo}
      className={classNames(styles.logo, className)}
      {...restProps}
    />
  )
}
ManzamaLogo.propTypes = {
  color: PropTypes.oneOf(Object.values(COLORS)),
  className: PropTypes.string,
}
ManzamaLogo.COLORS = COLORS
