import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import Button from 'app/common/Button'
import {LoadingMessage} from 'app/common/loading-message'
import TabRow from 'app/common/tab-row'
import {ENTITY_TYPES} from 'app/constants'
import {getEntities} from 'app/framework/entities-selectors'
import Orm from 'app/framework/Orm'
import * as login from 'app/login'
import {Company, Industry, Story} from 'app/models'
import {getMyCompanyIds} from 'app/global/global-selectors'
import MyIndustryStar from 'app/reusable/industries/my-industry-star'
import {getRouterState} from 'app/router/router-selectors'
import routes from 'app/routes'
import urls from 'app/urls'

import {SaveFiltersModal} from '../common/OverviewFilters'
import OverviewPage from '../common/OverviewPage'
import IndustryCompanies from './companies/IndustryCompanies'
import * as actions from './industry-overview-actions'
import * as selectors from './industry-overview-selectors'

import * as styles from './IndustryOverview.less'

const IndustryOverview = connect(
  createSelector(
    [
      getEntities,
      selectors.getIndustryOverview,
      selectors.getFilterDefaultsForCurrentIndustry,
      getMyCompanyIds,
    ],
    (entities, state, filterDefaults, myCompanyIds) => {
      const orm = Orm.withEntities(entities)
      const industry =
        state.industryId && orm.getById(Industry, state.industryId)
      const {
        healthData,
        dailyHealthData,
        factorDailyHealthData,
        subfactorDailyHealthData,
      } = industry || {}

      return {
        overviewType: 'Industry',

        healthData,
        dailyHealthData,
        factorDailyHealthData,
        subfactorDailyHealthData,

        filterDefaults,
        shouldShowFiltersSaveModal: state.shouldShowFiltersSaveModal,

        stories:
          state.storyReader.storyIds &&
          orm.getByIds(Story, state.storyReader.storyIds),
        selectedValences: state.storyReader.filters.valences,
        storySortOrder: state.storyReader.filters.sortOrder,
        shouldShowIndustryNewsFilter: !!state.subIndustryIds.length,
        isIndustryNewsFilterChecked: state.shouldShowOnlyIndustryNews,
        shouldShowStoryCompanyNames: true,

        shouldShowCompaniesSidebar: true,
        companies:
          state.sidebarCompanyIds &&
          orm.getByIds(Company, state.sidebarCompanyIds),
        totalCompanyCount: state.totalCompanyCount,
        myCompanyIds,
        moreCompaniesUrl: urls.industryOverviewCompanies(state.industryId),
        selectedCompanyId: state.selectedCompanyId,
        shouldShowOnlyMyCompanies: state.shouldShowOnlyMyCompanies,
      }
    },
  ),
  {
    selectValence: actions.selectValence,
    deselectValence: actions.deselectValence,
    changeStorySortOrder: actions.setStorySortOrder,
    changeShowOnlyMyCompanies: actions.setShowOnlyMyCompanies,
    selectCompany: actions.selectCompany,
    clearSelectedCompany: actions.clearSelectedCompany,
    changeShowOnlyIndustryNews: actions.setShowOnlyIndustryNews,
    showFiltersSaveModal: actions.showFiltersSaveModal,
  },
)(OverviewPage)
IndustryOverview.displayName = 'IndustryOverview'

class IndustryPage extends React.Component {
  static propTypes = {
    industry: PropTypes.object,
    selectedFactor: PropTypes.string,
    selectedSubfactor: PropTypes.string,
    timeFrameDays: PropTypes.number.isRequired,
    selectedGeography: PropTypes.number.isRequired,
    shouldShowFiltersSaveModal: PropTypes.bool.isRequired,
    saveFilterDefaults: PropTypes.func.isRequired,
    hideFiltersSaveModal: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    currentRoute: PropTypes.string.isRequired,
  }

  render() {
    const {
      industry,
      selectedFactor,
      selectedSubfactor,
      timeFrameDays,
      selectedGeography,
      shouldShowFiltersSaveModal,
      saveFilterDefaults,
      hideFiltersSaveModal,
      isLoading,
      currentRoute,
    } = this.props

    const activeTab = [
      routes.industryOverview,
      routes.industryOverviewPortal,
    ].includes(currentRoute)
      ? 'overview'
      : 'companies'
    const isPortalPage = [
      routes.industryOverviewPortal,
      routes.industryOverviewPortalCompanies,
    ].includes(currentRoute)
    const contents = isLoading ? (
      <LoadingMessage />
    ) : activeTab === 'overview' ? (
      <IndustryOverview
        selectedFactor={selectedFactor}
        selectedSubfactor={selectedSubfactor}
        timeFrameDays={timeFrameDays}
        selectedGeography={selectedGeography}
        isPortalPage={isPortalPage}
      />
    ) : (
      <IndustryCompanies isPortalPage={isPortalPage} />
    )

    const onSaveFilterDefaults = applyToAllIndustries => {
      saveFilterDefaults({
        industryId: applyToAllIndustries ? null : industry.id,
        factor: selectedFactor,
        subfactor: selectedSubfactor,
        timeFrameDays,
        geography: selectedGeography,
      })
    }

    return (
      <div className={styles.industryOverview}>
        {industry && (
          <React.Fragment>
            <div className={styles.header}>
              <MyIndustryStar industry={industry} className={styles.star} />

              <h1 className={styles.title}>
                {industry.name} Industry Overview
              </h1>

              {industry.healthData && (
                <a
                  href={urls.industryReport(industry.id, {
                    timeFrameDays,
                    geography: selectedGeography,
                  })}
                  target="_blank"
                  className="industry-report"
                >
                  <Button label="Generate Industry Report" isPrimary={true} />
                </a>
              )}
            </div>

            <TabRow activeTab={activeTab}>
              <TabRow.Tab
                id="overview"
                label="Overview"
                url={
                  isPortalPage
                    ? urls.industryOverviewPortal(industry.id)
                    : urls.industryOverview(industry.id)
                }
              />
              <TabRow.Tab
                id="companies"
                label="Companies"
                url={
                  isPortalPage
                    ? urls.industryOverviewPortalCompanies(industry.id)
                    : urls.industryOverviewCompanies(industry.id)
                }
              />
            </TabRow>
          </React.Fragment>
        )}
        {contents}
        {shouldShowFiltersSaveModal && (
          <SaveFiltersModal
            entityType={ENTITY_TYPES.INDUSTRY}
            entityName={industry.name}
            onSave={onSaveFilterDefaults}
            onClose={hideFiltersSaveModal}
          />
        )}
      </div>
    )
  }
}

export default connect(
  createSelector(
    [
      getEntities,
      selectors.getIndustryId,
      selectors.getFactorForCurrentIndustry,
      selectors.getSubfactorForCurrentIndustry,
      selectors.getTimeFrameForCurrentIndustry,
      selectors.getGeographyForCurrentIndustry,
      selectors.getShouldShowFiltersSaveModal,
      selectors.getIsLoading,
      getRouterState,
    ],
    (
      entities,
      industryId,
      selectedFactor,
      selectedSubfactor,
      timeFrameDays,
      selectedGeography,
      shouldShowFiltersSaveModal,
      isLoading,
      routerState,
    ) => {
      const orm = Orm.withEntities(entities)
      const {route} = routerState
      const industry = industryId && orm.getById(Industry, industryId)
      return {
        industry,
        selectedFactor,
        selectedSubfactor,
        timeFrameDays,
        selectedGeography,
        shouldShowFiltersSaveModal,
        isLoading,
        currentRoute: route,
      }
    },
  ),
  {
    saveFilterDefaults: filters =>
      login.actions.saveFilterDefaults({
        entityType: ENTITY_TYPES.INDUSTRY,
        entityId: filters.industryId,
        ...filters,
      }),
    hideFiltersSaveModal: actions.hideFiltersSaveModal,
  },
)(IndustryPage)
