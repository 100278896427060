/**
 * If `value` is a function, calls that function with `args` and returns its
 * return value. Otherwise, just returns `value` directly. Useful for values
 * that may or may not be represented as functions.
 */
export function valueFromMaybeFunction(value, ...args) {
  if (typeof value == 'function') {
    return value(...args)
  }
  return value
}
