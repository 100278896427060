import parseDate from 'date-fns/parse'

import Resource from 'app/framework/Resource'
import ArticleModel from 'app/models/Article'

export default class Article extends Resource {
  static endpoint = 'article'

  entityItemsForRestItem(restData) {
    return [
      {
        id: restData.id,
        entityKey: ArticleModel.entityKey,
        data: {
          id: restData.id,
          companyId: restData.companyId,
          storyId: restData.storyId,
          contentDirectorId: restData.contentdirectorId,
          headline: restData.headlineClean || restData.headline,
          description: restData.description,
          url: restData.url,
          date: parseDate(restData.publishedAt),
          valence: restData.valence,
          subfactorId: restData.categoryId || restData.subfactorId,
          feedName: restData.feed ? restData.feed.name : undefined,
          feedId: restData.feed ? restData.feed.feedId : undefined,
          perspectiveId: restData.perspectiveId,
        },
      },
    ]
  }
}
