import React from 'react'

import LoadingSpinner from 'app/common/loading-spinner'

import styles from './LoadingMessage.less'

export default function LoadingMessage() {
  return (
    <div className={styles.loadingMessage}>
      <LoadingSpinner className={styles.spinner} />
      <div className={styles.text}>Loading...</div>
    </div>
  )
}
