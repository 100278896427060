import {handleActions} from 'redux-actions'

import * as actions from './story-modal-actions'

const getInitialState = () => ({
  isVisible: false,
  isLoading: false,
  storyId: null,
})

export default handleActions(
  {
    [actions.showModal]: (state, action) => ({
      ...state,
      isVisible: true,
      storyId: action.payload.storyId,
    }),
    [actions.hideModal]: getInitialState,
  },
  getInitialState(),
)
