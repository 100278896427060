import React from 'react'
import Table from '../../email-server-common/common/Table'
import Row from '../../email-server-common/common/Row'
import Cell from '../../email-server-common/common/Cell'
import { colors } from './constants'

export default function NoArticlesSection({ entities, entityType }) {
  return (
    <>
      <Table
        width={'600'}
      >
        <Row>
          <Cell height={'8'}/>
        </Row>
      </Table>
      <Table
        style={{
          margin: '0 auto',
          backgroundColor: colors.contentBackgroundColor,
        }}
        width='600'
        align='center'
      >
        <Row>
          <Cell
            style={{
              padding: '24px 0 0 32px',
              fontSize: 14,
              fontWeight: 700,
              color: colors.defaultFontColor,
            }}>
            No {entityType} articles for:
          </Cell>
        </Row>
        <Row>
          <Cell
            style={{
              padding: '0 0 24px 32px',
              fontSize: 14,
              fontWeight: 400,
              color: colors.defaultFontColor,
            }}>
            {entities.join(' - ')}
          </Cell>
        </Row>
      </Table>
    </>
  )
}
