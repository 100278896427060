import PropTypes from 'prop-types'
import React from 'react'

import {Tooltip} from 'app/common/tooltip'

export default function MonitorEntityTooltip({
  entityType,
  isMonitored,
  ...restProps
}) {
  const entityTypeName = entityType === 'company' ? 'company' : 'industry'
  return (
    <Tooltip
      label={
        isMonitored
          ? `Click to stop monitoring this ${entityTypeName}`
          : `Click to monitor this ${entityTypeName}`
      }
      {...restProps}
    />
  )
}
MonitorEntityTooltip.propTypes = {
  entityType: PropTypes.string.isRequired,
  isMonitored: PropTypes.bool.isRequired,
  ...Tooltip.propTypes,
}
