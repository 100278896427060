import classNames from 'classnames'
import bind from 'memoize-bind'
import PropTypes from 'prop-types'
import React from 'react'

import FactorIcon from 'app/common/FactorIcon'
import HealthScore from 'app/common/HealthScore'
import {FilterIcon, HelpIcon} from 'app/common/icons'
import {ORDERED_FACTORS, ORDERED_SUBFACTORS_BY_FACTOR} from 'app/constants'
import * as strings from 'app/strings'

import * as styles from './HealthFactorSummary.less'

export default function HealthFactorSummary({
  healthData,
  factor,
  subfactor,
  showFactorInfo,
  selectFactor,
  selectSubfactor,
  className,
}) {
  let rows
  if (subfactor) {
    rows = (
      <SubfactorBreakdown
        subfactor={subfactor}
        healthData={healthData.subfactors[subfactor]}
      />
    )
  } else if (factor) {
    rows = ORDERED_SUBFACTORS_BY_FACTOR[factor].map(subfactor => {
      const factorHealthData = healthData.subfactors[subfactor] || {}
      const {healthScore} = factorHealthData
      return (
        <SubfactorRow
          subfactor={subfactor}
          healthScore={healthScore}
          onFilter={selectSubfactor}
          key={subfactor}
        />
      )
    })
  } else {
    rows = ORDERED_FACTORS.map(factor => {
      const factorHealthData = healthData.factors[factor] || {}
      const {healthScore} = factorHealthData
      return (
        <FactorRow
          factor={factor}
          healthScore={healthScore}
          onInfo={showFactorInfo}
          onFilter={selectFactor}
          key={factor}
        />
      )
    })
  }
  return <div className={classNames(styles.table, className)}>{rows}</div>
}
HealthFactorSummary.propTypes = {
  healthData: PropTypes.object.isRequired,
  factor: PropTypes.string,
  subfactor: PropTypes.string,
  showFactorInfo: PropTypes.func,
  selectFactor: PropTypes.func.isRequired,
  selectSubfactor: PropTypes.func,
  className: PropTypes.string,
}

const FactorRow = ({factor, healthScore, onInfo, onFilter}) => (
  <div className={styles.row}>
    <div className={classNames(styles.cell, styles.factorIcon)}>
      <FactorIcon factor={factor} className={styles.icon} />
    </div>

    <div className={classNames(styles.cell, styles.name)}>
      {strings.factorDisplayName(factor)}
    </div>

    <div className={classNames(styles.cell, styles.healthScore)}>
      {healthScore ? (
        <HealthScore score={healthScore} />
      ) : (
        <span className={styles.noScore}>--</span>
      )}
    </div>

    {onInfo && (
      <div className={classNames(styles.cell, styles.infoIcon)}>
        <HelpIcon
          onClick={bind(onFilter, null, factor)}
          className={classNames(styles.icon, 'help-icon')}
        />
      </div>
    )}

    <div className={classNames(styles.cell, styles.filterIcon)}>
      <FilterIcon
        onClick={bind(onFilter, null, factor)}
        className={classNames(styles.icon, 'filter-icon')}
      />
    </div>
  </div>
)
FactorRow.propTypes = {
  factor: PropTypes.string.isRequired,
  healthScore: PropTypes.number,
  onInfo: PropTypes.func,
  onFilter: PropTypes.func.isRequired,
}

const SubfactorRow = ({subfactor, healthScore, onFilter}) => (
  <div className={styles.row}>
    <div className={classNames(styles.cell, styles.name)}>
      {strings.subfactorDisplayName(subfactor)}
    </div>

    <div className={classNames(styles.cell, styles.healthScore)}>
      {healthScore ? (
        <HealthScore score={healthScore} />
      ) : (
        <span className={styles.noScore}>--</span>
      )}
    </div>

    <div className={classNames(styles.cell, styles.filterIcon)}>
      <FilterIcon
        onClick={bind(onFilter, null, subfactor)}
        className={classNames(styles.icon, 'filter-icon')}
      />
    </div>
  </div>
)
SubfactorRow.propTypes = {
  subfactor: PropTypes.string.isRequired,
  healthScore: PropTypes.number,
  onFilter: PropTypes.func.isRequired,
}

const SubfactorBreakdown = ({subfactor, healthData}) => {
  healthData = healthData || {
    articleVolume: 0,
    positiveVolume: 0,
    neutralVolume: 0,
    negativeVolume: 0,
  }
  return (
    <React.Fragment>
      {/* Heath Score */}
      <div className={styles.row}>
        <div className={classNames(styles.cell, styles.name)}>
          {strings.subfactorDisplayName(subfactor)} Health Score
        </div>

        <div className={classNames(styles.cell, styles.healthScore)}>
          {healthData.healthScore ? (
            <HealthScore score={healthData.healthScore} />
          ) : (
            <span className={styles.noScore}>--</span>
          )}
        </div>
      </div>

      {/* Total Article Count */}
      <div className={styles.row}>
        <div className={classNames(styles.cell, styles.name)}>
          Total Article Count
        </div>

        <div className={classNames(styles.cell, styles.volume)}>
          {healthData.articleVolume}
        </div>
      </div>

      {/* Positive Articles */}
      <div className={styles.row}>
        <div className={classNames(styles.cell, styles.name)}>
          Positive Articles
        </div>

        <div
          className={classNames(styles.cell, styles.volume, styles.positive)}
        >
          {healthData.positiveVolume}
        </div>
      </div>

      {/* Neutral Articles */}
      <div className={styles.row}>
        <div className={classNames(styles.cell, styles.name)}>
          Neutral Articles
        </div>

        <div className={classNames(styles.cell, styles.volume, styles.neutral)}>
          {healthData.neutralVolume}
        </div>
      </div>

      {/* Negative Articles */}
      <div className={styles.row}>
        <div className={classNames(styles.cell, styles.name)}>
          Negative Articles
        </div>

        <div
          className={classNames(styles.cell, styles.volume, styles.negative)}
        >
          {healthData.negativeVolume}
        </div>
      </div>
    </React.Fragment>
  )
}
SubfactorBreakdown.propTypes = {
  subfactor: PropTypes.string.isRequired,
  healthData: PropTypes.object,
}
